import { of as observableOf, Observable, throwError } from "rxjs";
import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { JobOrdersService } from 'src/app/_services/job-orders.service';
import { PermissionService } from 'src/app/auth/permission.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AiService {
  refreshList$: EventEmitter<any>;
  refreshStatus$: EventEmitter<any>;
  refreshGoogleCalender$: EventEmitter<any>;
  refreshMicrosoftCalender$: EventEmitter<any>;
  refreshCalendlyCalender$: EventEmitter<any>;

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private httpOnly: HttpClient,
    private perm: PermissionService,
    private authService: AuthenticationService, 
    private jobOrderService: JobOrdersService  
  ) {
    this.refreshList$ = new EventEmitter();
    this.refreshStatus$ = new EventEmitter();
    this.refreshGoogleCalender$ = new EventEmitter();
    this.refreshMicrosoftCalender$ = new EventEmitter();
    this.refreshCalendlyCalender$ = new EventEmitter();
  }

  

  

  // Method to get job order ID
  getJobOrderId(jobOrderId: string): Observable<any> {
    return this.jobOrderService.getJobOrderProductsById(jobOrderId);
  }

  //**************************************  to get AI summary ************************************/
  public getConnectWithAI( fromDateTime: any, toDateTime: any) {
    let params = new HttpParams();
    // params = params.set("userId", userId);
    //userId: any,
    params = params.set("fromDateTime", fromDateTime);
    params = params.set("toDateTime", toDateTime);
    params = params.set("outputLang", 'en');
    params = params.set("useTrial", 'True'); 
    // params = params.set("limit", '10');
    //changed the base url
    return this.http.get(environment.ai_base_url + '/ai-daily-rundown/planner-daily-rundown', { observe: "response", params: params }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return observableOf(error);
    }),);
  }

  //**************************************  to get AI time summary ************************************
  public getPredictedCompletionTime(tenantId: string, jobOrderId: string): Observable<any> {
    
    const params = new HttpParams()
      .set("TenantId", tenantId)
      .set("JobOrderId", jobOrderId)
  
    console.log('Requesting API with params:', params);  
    return this.http.get(environment.ai_base_url + '/ai-predict-completion-time/invocations', { observe: "response", params: params }).pipe(
      map((response: any) => response),
      catchError(error => observableOf(error))
    );
  }

  


  //**************************************  to get ai job order summary ************************************
  getAIJobOrderSummary(jobOrderId: string,lang: any): Observable<any> {
    const params = new HttpParams()
      .set("jobOrderId", jobOrderId)
      .set("outputLang", lang)
      .set("useTrial", 'True');
  
    console.log('Requesting API with params:', params);  
  
    return this.http.get(environment.ai_base_url + '/ai-daily-rundown/planner-post-job-summary', { params:params }).pipe(
      map((response: any) => response),
      catchError(error => {
        console.error('Error fetching job order summary:', error);
        return [];
      })
    );
  }
  
  
}
