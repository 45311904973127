<div class="custella-modal">
    <form [formGroup]="addTaskFullForm">
        <div class="custella-modal-header align-items-center">
            <div *ngIf="showChubbFormDiv == 0">
                <div class="text-14 font-medium" *ngIf="mode === 'add' || mode === 'add&back'" id="title_TaskNew">
                    <span>{{ translate?.task.sections.sec6.singular }}</span>
                </div>
                <div class="text-14 font-medium" *ngIf="mode === 'edit' || mode === 'edit&back'" id="title_TaskEdit">
                    <span>{{ translate?.task.sections.sec3.singular }}</span>
                </div>
            </div>
            <div *ngIf="showChubbFormDiv == 1">
                <div class="text-14 font-medium" (click)="backTaskPage()" id="link_TaskReqForms">
                    <span>{{ translate?.task.fields.requiredForms.singular }}</span>
                </div>
            </div>
            <i class="pi pi-times" id="btn_TaskClose" (click)="modalRef.hide()" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left"></i>
        </div>

        <div class="">
            <!-- Container -->
            <div class="custella-form-container position-relative d-block w-100 rounded-2 m-0 pt-3" style="height: fit-content;">
                <!-- Container Content -->
                <div [hidden]="showChubbFormDiv == 1">
                    <!-- Task name and Priority level -->
                    <div class="custella-form-container-content m-0">
                        <div class="custella-content-row mb-3 pe-0">
                            <div class="custella-content-row-input col">
                                <span class="custella-content-row-input-title mandatory" id="label_TaskName">{{ translate?.task.fields.taskName.singular }}</span>
                                <input pInputText autocomplete="off" type="text" id="input_TaskNameField" [(ngModel)]="addTask.taskName" formControlName="name" name="name" />
                                <div class="p-error text-10" *ngIf="addTaskFullForm.controls['name'].touched && addTaskFullForm.controls['name'].invalid">
                                    <span *ngIf="addTaskFullForm.controls['name'].errors?.required" id="label_TaskNameReqField">{{ translate?.general.validationFE.requiredField }}</span>
                                </div>
                            </div>

                            <div class="custella-content-row-input me-0">
                                <span class="custella-content-row-input-title" id="label_TaskPriority">{{ translate?.task.fields.priority.singular }}</span>
                                <div class="d-flex">
                                    <p-dropdown [options]="allPriorities" id="picklist_TaskPriorityField"
                                                [(ngModel)]="selectedPriority"
                                                (onChange)="changeTaskPriority($event)"
                                                optionLabel="value" [ngModelOptions]="{ standalone: true }">
                                        <ng-template pTemplate="selectedItem">
                                            <div class="flex d-flex" *ngIf="selectedPriority">
                                                <div class="circle me-2" [ngClass]="selectedPriority.classColor" id="label_TaskSelPriorityColor"></div>
                                            </div>
                                        </ng-template>
                                        <ng-template let-priority pTemplate="item">
                                            <div class="flex d-flex">
                                                <div class="circle me-2" [ngClass]="priority.classColor" id="label_TaskPriorityColor"></div>
                                                <span id="value_TaskPriorityColor"> {{ priority.value }} </span>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                </div>
                            </div>
                        </div>

                        <!-- my focus starts here -->
                        <div class="custella-time-content mt-1">
                            <!-- Time fields -->
                            <div class="custella-content-row pe-0 mb-3">
                                <div class="d-flex col">
                                    <div class="custella-content-row-input col-5">
                                        <span class="custella-content-row-input-title" id="label_TaskDate">{{ translate?.task.fields.date.singular }}</span>
                                        <span class="p-input-icon-right w-100">
                                            <i class="pi pi-calendar" style="z-index: 1;" (click)="openCalendar()"></i>
                                            <p-calendar  #calendar baseZIndex="10000" dateFormat="dd/mm/yy" yearRange="2000:2030" id="picklist_TaskDateField" [(ngModel)]="addTask.date" formControlName="date" name="date" [placeholder]="translate?.general.messages.none" [yearNavigator]="true" appendTo="body"></p-calendar>
                                        </span>
                                        <div class="p-error text-10" *ngIf="addTaskFullForm.controls['date'].touched && addTaskFullForm.controls['date'].invalid">
                                            <span *ngIf="addTaskFullForm.controls['date'].errors?.required" id="label_TaskDateReqField">{{ translate?.general.validationFE.requiredField }}</span>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="!addTask.adhoc">
                                        <div class="custella-content-row-input col me-0">
                                            <span class="custella-content-row-input-title" id="label_TaskStartTime">{{ translate?.task.fields.startTime.singular }}</span>
                                            <span class="p-input-icon-right w-100">
                                                <i class="pi pi-clock" style="z-index: 1;" (click)="openStarTime()"></i>
                                                <p-calendar #starTime id="picklist_TaskStartTimeField" hourFormat="12" [placeholder]="translate?.general.messages.none" [timeOnly]="true" (ngModelChange)="changeEndTime()" [(ngModel)]="addTask.startTime" formControlName="startTime" name="startTime" appendTo="body"></p-calendar>
                                            </span>
                                            <div class="p-error text-10" *ngIf="addTaskFullForm.controls['startTime'].touched && addTaskFullForm.controls['startTime'].invalid">
                                                <span *ngIf="addTaskFullForm.controls['startTime'].errors?.required" id="label_TaskStartTimeReqField">{{ translate?.general.validationFE.requiredField }}</span>
                                            </div>
                                        </div>
                                        <div class="custella-content-row-input mx-2" style="margin-top: 30px;">
                                            <span id="label_TaskToTime">{{ translate?.task.fields.to.singular }}</span>
                                        </div>
                                        <div class="custella-content-row-input col">
                                            <span class="custella-content-row-input-title" id="label_TaskEndTime">{{ translate?.task.fields.endTime.singular }}</span>
                                            <span class="p-input-icon-right w-100">
                                                <i class="pi pi-clock" style="z-index: 1;"  (click)="openEndTime()"></i>
                                                <p-calendar  #endTime hourFormat="12" [placeholder]="translate?.general.messages.none" id="picklist_TaskEndTimeField" [timeOnly]="true" [(ngModel)]="addTask.endTime" formControlName="endTime" name="endTime" appendTo="body"></p-calendar>
                                              
                                            </span>
                                            <!-- condition to hide tooltip when start time is not valid - added conditions -->
                                            <div *ngIf="comingFrom == 'JO' && isPaneVisible && addTask.startTime && mode == 'add' && mode !== 'add&back'" class="custom-pane">
                                                <p>These jobs usually take ~{{displayAITime}}.</p>
                                                <div class="flex-align-center">
                                                    <button class="set-time-button" (click)="setEndTime()">Set end time to 4pm</button>
                                                    <i class="fa-regular fa-xmark close-icon" (click)="closePane()"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <!-- sub focus -->
                                    <ng-container *ngIf="addTask.adhoc">
                                        <div class="custella-content-row-input col">
                                            <span class="custella-content-row-input-title" id="label_TaskDuration">{{ translate?.task.fields.duration.singular }}</span>
                                            <p-dropdown [options]="allDurations" id="picklist_TaskDurationField" optionLabel="label" optionValue="time" [(ngModel)]="addTask.duration" formControlName="duration" name="duration">
                                                <ng-template let-duration pTemplate="item" id="item_TaskDuration">
                                                    <span>{{ duration.label }} {{ duration.timeLabel  }}</span>
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                    </ng-container>
                                </div>

                                <div class="">
                                    <div class="custella-content-row-input me-0">
                                        <span class="custella-content-row-input-title" id="label_TaskLock">{{translate?.task.fields.lock.singular}}  <i class="pi pi-info-circle" id="tooltip_TaskLock" [pTooltip]="translate?.task.tooltips.lockingPuts.singular" tooltipPosition="top" [escape]="false"></i> </span>
                                        <button class="btn-list-icon" id="btn_TaskLock" *ngIf="addTask.fixed" (click)="toggleFixed(false)">
                                            <i class="fa-regular fa-lock"></i>
                                        </button>
                                        <button class="btn-list-icon" id="btn_TaskUnlock" *ngIf="!addTask.fixed" (click)="toggleFixed(true)">
                                            <i class="fa-regular fa-lock-open"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- my focus ends here -->


                        <!-- Customer -->
                        <div class="custella-content-row pe-0 mb-3">
                            <div class="custella-content-row-input col-12">
                                <span class="custella-content-row-input-title" id="label_TaskCust">{{ translate?.customer.label.singular }} </span>
                                <div class="p-inputgroup search">
                                    <span class="p-input-icon-right w-100">
                                        <p-autoComplete
                                            (onSelect)="onSelectValue($event)"
                                            id="picklist_TaskCustField"
                                            field="name"
                                            [suggestions]="customers"
                                            (onBlur)="onvalidCustomer()"  
                                            (completeMethod)="onKeyCustomerInput($event)"
                                            [(ngModel)]="selectedCustomer"
                                            formControlName="customer" 
                                            styleClass="w-100">
                                        </p-autoComplete>
                                        <i *ngIf="addTask.customer" id="btn_TaskCustClear" (click)="clearValue('customer')" class="p-autocomplete-clear-icon pi pi-times"></i>
                                    </span>
                                    <button type="button" pButton icon="fa-regular fa-magnifying-glass" [pTooltip]="translate?.customer.tooltips.customerLookup.singular" tooltipPosition="left" id="btn_TaskCustSearch" (click)="displayCustomerTable()"></button>
                                </div>
                                <footer *ngIf="showCustomerTable" class="custella-form-container-footer hidePopup">
                                    <p-dialog [header]="translate?.customer.sections.sec5.singular" id="title_CustDet" [(visible)]="showCustomerTable" showEffect="fade" [style]="{ width: '70vw' }" [modal]="true" [draggable]="false">
                                        <app-custella-customers (selectCustomerEvent)="onSelectValue($event)" (closeCustomerEvent)="hideCustomerTable()"></app-custella-customers>
                                    </p-dialog>
                                </footer>
                            </div>
                        </div>

                        <!-- Location -->
                        <div class="custella-content-row pe-0 mb-3">
                            <div class="custella-content-row-input col-12">
                                <span class="custella-content-row-input-title" id="label_TaskLoc">{{ translate?.task.fields.location.singular }} </span>
                                <div class="d-flex w-100 col">
                                    <div class="d-flex col">
                                        <div class="col">
                                            <input
                                                pInputText autocomplete="off"
                                                (keyup)="getPlaceApiCall($event)"
                                                (change)="manualSearch($event)"
                                                [ngModelOptions]="{ standalone: true }"
                                                [placeholder]="translate?.general.messages.none"
                                                autocorrect="off"
                                                autocapitalize="off"
                                                spellcheck="off"
                                                type="text"
                                                #homeAddressSearch
                                                [(ngModel)]="addTask.locationFull"
                                                id="input_TaskLocField" />
                                            <div class="p-error text-10" *ngIf="addTaskFullForm.controls['location'].touched && addTaskFullForm.controls['location'].invalid">
                                                <span *ngIf="addTaskFullForm.controls['location'].errors?.required" id="label_TaskLocReqField">{{ translate?.general.validationFE.requiredField }}</span>
                                            </div>
                                        </div>
                                        <button class="btn-list-icon ms-2" (click)="setStartLocation('home')" id="btn_TaskLocHome" [pTooltip]="translate?.task.fields.home.singular" tooltipPosition="top">
                                            <i class="fa-regular fa-house"></i>
                                        </button>
                                        <button class="btn-list-icon ms-2" (click)="setStartLocation('office')" id="btn_TaskLocOffice" [pTooltip]="translate?.task.fields.office.singular" tooltipPosition="top">
                                            <i class="fa-regular fa-briefcase"></i>
                                        </button>
                                        <button class="btn-list-icon ms-2" (click)="setStartLocation('current')" id="btn_TaskLocCurrent" [pTooltip]="translate?.task.fields.currentLocation.singular" tooltipPosition="top">
                                            <i class="fa-regular fa-location-dot"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Team -->
                        <div class="custella-content-row pe-0 mb-3">
                            <div class="custella-content-row-input col-12">
                                <span class="custella-content-row-input-title" id="label_TaskTeam">{{ translate?.task.fields.team.singular }} </span>
                                <p-dropdown [placeholder]="translate?.general.messages.none" name="team" id="picklist_TaskTeamField" formControlName="team" [(ngModel)]="addTask.team.id" [options]="teams" [showClear]="true" optionLabel="name" optionValue="id"> </p-dropdown>
                            </div>
                        </div>
                        <div class="d-flex align-items-center my-3 mr-3 color-primary">
                            <span class="cursor-pointer text-12" (click)="toggleAdvanceOptions = !toggleAdvanceOptions" id="label_TaskTeamAdvOptions">{{ translate?.task.fields.advancedOptions.singular }} </span>
                            <i class="pi pi-angle-down ms-2" *ngIf="!toggleAdvanceOptions" id="btn_TaskTeamAdvOptionsUp" (click)="toggleAdvanceOptions = !toggleAdvanceOptions"></i>
                            <i class="pi pi-angle-up ms-2" *ngIf="toggleAdvanceOptions" id="btn_TaskTeamAdvOptionsDown" (click)="toggleAdvanceOptions = !toggleAdvanceOptions"></i>
                        </div>
                    </div>

                    <!-- show more details -->
                    <div class="custella-form-container-title-two flex-between m-0 py-2" *ngIf="toggleAdvanceOptions">
                        <div class="font-medium text-14" id="title_TaskBuffer">{{ translate?.task.fields.buffer.singular }}
                            <i class="pi pi-info-circle py-1 mx-2" id="tooltip_TaskTimeAdded" [pTooltip]="translate?.task.tooltips.timeAddedBeforeOr.singular"></i>
                        </div>
                    </div>
                    <ng-container *ngIf="toggleAdvanceOptions">
                        <div class="custella-form-container-content">
                            <!-- Buffer -->
                            <div class="custella-content-row">
                                <div class="custella-content-row-input col-6">
                                    <span class="custella-input-box-title" >
                                        <span class="custella-content-row-input-title" id="label_TaskBufferBeforeTask">{{ translate?.task.fields.bufferBeforeTask.singular }}</span>
                                    </span>
                                    <p-dropdown optionValue="value" optionLabel="value" id="picklist_TaskBufferBeforeTaskField" [options]="allBuffers" [(ngModel)]="addTask.startBuffer" formControlName="startBuffer" name="startBuffer">
                                        <ng-template pTemplate="selectedItem">
                                            <span *ngIf="addTask.startBuffer" id="item_TaskBufferBeforeTaskSel">{{ addTask.startBuffer }} {{ getBufferLabel(addTask.startBuffer)  }}</span>
                                        </ng-template>

                                        <ng-template let-buffer pTemplate="item">
                                            <span id="item_TaskBufferBeforeTask">{{ buffer.value }} {{ buffer.label  }}</span>
                                        </ng-template>
                                    </p-dropdown>
                                    <div class="p-error text-10" *ngIf="addTaskFullForm.controls['startBuffer'].touched && addTaskFullForm.controls['startBuffer'].invalid">
                                        <span *ngIf="addTaskFullForm.controls['startBuffer'].errors?.required" id="label_TaskBufferBeforeTaskReqField">{{ translate?.general.validationFE.requiredField }}</span>
                                    </div>
                                </div>

                                <div class="custella-content-row-input col-6">
                                    <span class="custella-content-row-input-title" id="label_TaskBufferAfterTask">{{ translate?.task.fields.bufferAfterTask.singular }}</span>
                                    <p-dropdown optionValue="value" [options]="allBuffers" [(ngModel)]="addTask.endBuffer" formControlName="endBuffer" name="endBuffer">
                                        <ng-template pTemplate="selectedItem">
                                            <span *ngIf="addTask.endBuffer" id="item_TaskBufferAfterTaskSel">{{ addTask.endBuffer }} {{ getBufferLabel(addTask.endBuffer)  }}</span>
                                        </ng-template>
                                        <ng-template let-buffer pTemplate="item">
                                            <span id="item_TaskBufferAfterTask">{{ buffer.value }} {{ buffer.label  }}</span>
                                        </ng-template>
                                    </p-dropdown>
                                    <div class="p-error text-10" *ngIf="addTaskFullForm.controls['endBuffer'].touched && addTaskFullForm.controls['endBuffer'].invalid">
                                        <span *ngIf="addTaskFullForm.controls['endBuffer'].errors?.required" id="label_TaskBufferAfterTaskReqField">{{ translate?.general.validationFE.requiredField }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <div class="custella-form-container-title-two flex-between m-0 py-2" *ngIf="toggleAdvanceOptions">
                        <div class="font-medium text-14" id="title_TaskSkills">{{ translate?.task.fields.skills.singular }}
                            <i class="pi pi-info-circle py-1 mx-2" id="tooltip_TaskSkillsToFind" [pTooltip]="translate?.task.tooltips.skillsUsedToFind.singular"></i>
                        </div>
                    </div>

                    <ng-container *ngIf="toggleAdvanceOptions">
                        <div class="custella-form-container-content">
                            <div class="custella-content-row pe-0 mb-3">
                                <div class="d-flex col-12">
                                    <div class="custella-content-row-input col">
                                        <span class="custella-content-row-input-title" id="label_TaskDept">{{ translate?.task.fields.department.singular }}</span>
                                        <p-dropdown optionLabel="name"   [placeholder]="translate?.general.messages.none"  [options]="departments" name="" [pla] id="picklist_TaskDeptField" [(ngModel)]="selectedDepartment" [ngModelOptions]="{ standalone: true }" (onChange)="getRolesByDepartment(selectedDepartment)">
                                        </p-dropdown>
                                    </div>
                                    <div class="custella-content-row-input col">
                                        <span class="custella-content-row-input-title" id="label_TaskRole">{{ translate?.task.fields.role.singular }}</span>
                                        <p-dropdown optionLabel="name"   [placeholder]="translate?.general.messages.none"  [options]="roles" id="picklist_TaskRoleField" name="" [(ngModel)]="selectedRole" [ngModelOptions]="{ standalone: true }" (onChange)="getLevelsByRole(selectedRole)"> </p-dropdown>
                                    </div>
                                    <div class="custella-content-row-input col">
                                        <span class="custella-content-row-input-title" id="label_TaskLevel">{{ translate?.task.fields.level.singular }}</span>
                                        <p-dropdown optionLabel="name"   [placeholder]="translate?.general.messages.none"  [options]="levels" id="picklist_TaskLevelField" name="" [(ngModel)]="selectedLevel" [ngModelOptions]="{ standalone: true }"> </p-dropdown>
                                    </div>
                                    <div class="custella-content-row-input me-0">
                                        <span class="custella-content-row-input-title" id="label_TaskAdd" translate style="opacity: 0;">{{ translate?.general.buttons.add.singular }}</span>
                                        <button pButton class="ml-2"  (click)="addSkill()" id="btn_TaskAdd" icon="fa-regular fa-plus"></button>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap gap-1">
                                <div class="skills d-flex px-3" *ngFor="let skill of addTask.userDynamicSkillList; let i = index">
                                    <p class="text-1 mb-0 mr-2" id="label_TaskSkillDynamic">{{ skill.label }}</p>
                                    <p class="mb-0 text-3 cursor-pointer font-weight-bold ms-3" id="btn_TaskSkillDynamicRemove" (click)="removeSkill(i)">X</p>
                                </div>
                            </div>
                        </div>
                    </ng-container>
  
                <div class="custella-form-container-title-two flex-between m-0 py-2" *ngIf="toggleAdvanceOptions">
                  <div class="font-medium text-14" id="title_TaskAddDet" >
                      {{ translate?.task.sections.sec1.singular }}
                  </div>
                </div>
                <!-- Description -->
                <ng-container *ngIf="toggleAdvanceOptions">
                  <div class="custella-form-container-content">  
                    <div class="custella-content-row-input mt-2">
                        <span class="custella-content-row-input-title" id="label_TaskDesc" >{{ translate?.task.fields.description.singular }} </span>
                        <textarea pInputTextarea autocomplete="off"id="input_TaskDescField" rows="3" class="mt-2" formControlName="description" [(ngModel)]="addTask.description" name="description" [maxlength]="320000"></textarea>
                      </div>
                  </div>
                  <div class="custella-form-container-content" *ngIf="permissions.siteShow">  
                      <div class="custella-content-row-input mt-2">
                          <span class="custella-content-row-input-title" id="label_TaskSite" >{{ translate?.site.label.singular }} </span>
                          <div class="p-inputgroup search">
                            <span class="p-input-icon-right w-100">
                                <p-autoComplete styleClass="w-100" (onSelect)="onSiteSelect($event)" id="picklist_TaskSiteField" field="siteName" [suggestions]="allSites" (completeMethod)="getSite($event)" [(ngModel)]="selectedSite" [placeholder]="translate?.general.messages.none" [ngModelOptions]="{standalone: true}"> </p-autoComplete>
                                <i *ngIf="selectedSite" (click)="clearValue('site')" id="btn_TaskSiteClear" class="p-autocomplete-clear-icon pi pi-times"></i>
                            </span>
                            <button type="button" pButton icon="fa-regular fa-magnifying-glass" [pTooltip]="translate?.site.tooltips.siteLookup.singular" tooltipPosition="left" id="btn_TaskSiteSearch" (click)="showTable('sites')"></button>
                        </div>
                        <footer *ngIf="showingTable.sites" class="custella-form-container-footer hidePopup">
                            <p-dialog [header]="translate?.site.sections.sec2.singular" id="title_SiteDet" [(visible)]="showingTable.sites" showEffect="fade" [style]="{ width: '70vw' }" [modal]="true" [draggable]="false">
                                <app-custella-sites (itemSelector)="onSiteSelect($event)" (closeView)="closeTable('sites')"></app-custella-sites>

                            </p-dialog>
                        </footer>
                      </div>
                    </div>
                    <div class="custella-form-container-content" *ngIf="permissions.projectShow && addonFeature.addonPROJECT == 'PROJECT'">  
                      <div class="custella-content-row-input mt-2">
                          <span class="custella-content-row-input-title" id="label_TaskProj" >{{ translate?.project.label.singular }} </span>
                          <div class="p-inputgroup search">
                                <span class="p-input-icon-right w-100">
                                    <p-autoComplete styleClass="w-100" (onSelect)="onProjectSelect($event)" field="name" id="picklist_TaskProjField" [suggestions]="allProject" (completeMethod)="getProject($event)" [(ngModel)]="selectedProject" [placeholder]="translate?.general.messages.none" [ngModelOptions]="{standalone: true}"> </p-autoComplete>     
                                    <i *ngIf="selectedProject" (click)="clearValue('project')" id="btn_TaskProjClear" class="p-autocomplete-clear-icon pi pi-times"></i>
                                </span>
                                <button type="button" pButton icon="fa-regular fa-magnifying-glass" [pTooltip]="translate?.project.tooltips.projectLookup.singular" tooltipPosition="left" id="btn_TaskProjSearch" (click)="showTable('project')"></button>
                          </div>
                        <footer *ngIf="showingTable.project" class="custella-form-container-footer hidePopup">
                            <p-dialog [header]="translate?.project.sections.sec3.singular" id="title_ProjDet" [(visible)]="showingTable.project" showEffect="fade" [style]="{ width: '70vw' }" [modal]="true" [draggable]="false">
                                <app-custella-projects (itemSelector)="onProjectSelect($event)" (closeView)="closeTable('project')"></app-custella-projects>

                            </p-dialog>
                        </footer>
                      </div>
                    </div>
                </ng-container>
                </div>
  
                <!-- CHUBB FORMS START -->
                <ng-container *ngIf="chubbAddonFeature == 'CHUBB' && showChubbFormDiv == 1">
                    <!-- <div class="custella-form-container-title flex-between" style="border-top-left-radius: 0; border-top-right-radius: 0;">
                        <div class="custella-form-container-title-subtitle" >
                            {{ "forms" }}
                        </div>
                    </div> -->
  
                    <div class="custella-form-container-content">
                        <div class="custella-content-row">
                            <div class="custella-content-row-input col-12">
                                <div class="custella-content-row">
                                    <div class="custella-content-row-input col-4">
                                        <span class="custella-content-row-input-title" id="label_TaskForms" >{{ translate?.task.fields.form.singular }} </span>
                                        <span class="custella-content-row-input-title" id="label_TaskSerCheck" >{{ translate?.task.fields.serviceChecklist.singular  }} </span>
                                        <span class="custella-content-row-input-title" id="label_TaskCustSerRep" >{{ translate?.task.fields.customerServiceReport.singular }} </span>
                                        <span class="custella-content-row-input-title" id="label_TaskHandCert" >{{translate?.task.fields.handoverCertificate.singular  }} </span>
                                    </div>
  
                                    <div class="custella-content-row-input col-2 d-flex flex-column">
                                        <span class="custella-content-row-input-title font-weight-bold" id="label_TaskFormAvail" >{{ translate?.jobOrder.fields.available.singular }} </span>
                                        <p-checkbox class="mx-2 mb-1" [binary]="true" id="check_TaskSerCheck" [(ngModel)]="addTask.showServiceChecklist" [ngModelOptions]="{ standalone: true }" (onChange)="uncheck('showServiceChecklist')"></p-checkbox>
                                        <p-checkbox class="mx-2 mb-1" [binary]="true" id="check_TaskCustSerRep" [ngModelOptions]="{ standalone: true }" [(ngModel)]="addTask.showCustomerServiceReport" (onChange)="uncheck('customerServiceReportRequired')"></p-checkbox>
                                        <p-checkbox class="mx-2 mb-1" [binary]="true" id="check_TaskHandCert" [ngModelOptions]="{ standalone: true }" [(ngModel)]="addTask.showHandoverCertificate" (onChange)="uncheck('showHandoverCertificate')"></p-checkbox>
                                    </div>

                                    <div class="custella-content-row-input col d-flex flex-column">
                                        <span class="custella-content-row-input-title font-weight-bold" >{{ translate?.task.fields.mandatory.singular }}</span>
                                        <p-checkbox class="mx-2 mb-1" [binary]="true" id="check_TaskSerCheckMandatory" [(ngModel)]="addTask.serviceChecklistRequired" [ngModelOptions]="{ standalone: true }" (onChange)="selectMandatory()"></p-checkbox>
                                        <p-checkbox class="mx-2 mb-1" [binary]="true" id="check_TaskCustSerRepMandatory" [ngModelOptions]="{ standalone: true }" [(ngModel)]="addTask.customerServiceReportRequired" (onChange)="selectMandatory2()"></p-checkbox>
                                        <p-checkbox class="mx-2 mb-1" [binary]="true" id="check_TaskHandCertMandatory" [ngModelOptions]="{ standalone: true }" [(ngModel)]="addTask.handoverCertificateRequired" (onChange)="selectMandatory3()"></p-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <!-- CHUBB FORMS END -->
  
                <div class="custella-form-container-footer mb-0">
                    <div class="d-flex align-items-center justify-content-end">
                        <!-- Checkbox -->
                        <div class="custella-content-row-checkbox me-auto" [hidden]="showChubbFormDiv == 1">
                            <ng-container *ngIf=" comingFrom != 'JO' && (mode == 'add' || mode == 'add&back') ">
                                <div class="p-field-checkbox ms-2">
                                    <p-checkbox
                                        id="check_TaskGenerateJob"
                                        [binary]="true"
                                        [disabled]=" addTask.selectJobOrder && addTask.jobOrder.jobOrderNumber "
                                        class="date-checkbox"
                                        type="checkbox"
                                        [(ngModel)]="addTask.generateJobOrder"
                                        [ngModelOptions]="{ standalone: true }"
                                        (onChange)="setJOFunction()"
                                    ></p-checkbox>
                                </div>
                                <span class="custella-content-row-checkbox-text my-0 pt-1" id="label_TaskGenerateJob">{{ translate?.task.fields.generateJobOrder.singular }}</span>
                            </ng-container>
                        </div>

                        <ng-container *ngIf="showChubbFormDiv == 0">
                            <div class="dropdown mt-1 me-2" *ngIf="mode === 'add' || mode === 'add&back'">
                                <div class="p-component p-dropdown-clearable" data-bs-toggle="dropdown">
                                    <i class="fa-regular fa-circle-info hvr" id="btn_TaskAddDropdown"></i>
                                </div>
                                <ul class="dropdown-menu dropdown-collaps p-0 w-25">
                                    <li class="flex-between border-bottom rounded-2 px-1 py-2">
                                        <div class="ms-2 me-3">
                                            <span class="font-medium font-black text-13" id="label_TaskCreate">{{translate?.general.buttons.create.singular}}</span>
                                            <span class="font-black text-12 d-block" id="value_TaskCreate">{{translate?.task.fields.createTextTask.singular}}</span>
                                        </div>
                                    </li>
                                    <li class="flex-between border-bottom rounded-2 px-1 py-2">
                                        <div class="ms-2 me-3">
                                            <span class="font-medium font-black text-13" id="label_TaskSmartAssign">{{translate?.general.buttons.smartAssign.singular}}</span>
                                            <span class="font-black text-12 d-block" id="value_TaskSmartAssign">{{translate?.task.tooltips.smartAssignTextTask.singular}}</span>
                                        </div>
                                    </li>
                                    <li class="flex-between border-bottom rounded-2 px-1 py-2">
                                        <div class="ms-2 me-3">
                                            <span class="font-medium font-black text-13" id="label_TaskAssign">{{translate?.general.buttons.assign.singular}}</span>
                                            <span class="font-black text-12 d-block" id="value_TaskAssign">{{translate?.task.fields.assignTextTask.singular}}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
  
                              <button
                                class="custella-btn-white ms-2 text-12"
                                [disabled]="submitTouched || addTask.adhoc"
                                type="button"
                                *ngIf="mode === 'add' || mode === 'add&back'"
                                (click)="showChubForm('quick')"
                                id="btn_TaskCreate"
                                translate >
                                {{ translate?.general.buttons.create.singular }}
                            </button>
                            <button
                                class="custella-btn-white ms-2 text-12"
                                [disabled]="submitTouched || addTask.adhoc"
                                type="button"
                                *ngIf="mode === 'edit' || mode === 'edit&back'"
                                (click)="showChubForm('quick')"
                                id="btn_TaskUpdate"
                                translate >
                                {{ translate?.general.buttons.save.singular }}
                            </button>
                        </ng-container>
                        <ng-container *ngIf="allowAssignTask && showChubbFormDiv == 0">
                          <div class="custella-dashboard-top-right">
                              <p-button id="btn_TaskSmartAssign" styleClass="ms-2 h-32 dark" [disabled]="submitTouched" type="button" *ngIf="mode === 'add' || mode === 'add&back'" (onClick)="showChubForm('smart-assign')" [label]="translate?.general.buttons.smartAssign.singular"> </p-button>
                          </div>
  
                          <p-button styleClass="ms-2 dark" id="btn_TaskAssign" [disabled]="submitTouched" type="button" *ngIf="mode === 'add' || mode === 'add&back'" (onClick)="showChubForm('exact-assign')" [label]="translate?.general.buttons.assign.singular"> </p-button>
  
                        </ng-container>
  
                        <ng-container *ngIf="showChubbFormDiv == 1">
                            <p-button styleClass="ms-2 h-32" id="btn_TaskSubmit" [disabled]="submitTouched || addTask.adhoc" type="button" (onClick)="onSubmit(showChubbFormSubmitted)" [label]="translate?.general.buttons.save.singular"></p-button>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </form>
  </div>
  