import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { IServerSideGetRowsParams, SortModelItem } from "ag-grid-community";
import { PermissionService } from "src/app/auth/permission.service";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import * as moment from "moment-timezone";
import { TicketsService } from "src/app/_services/tickets.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { ServerRequest } from "src/app/_models/global.data.model";
import { ConfirmationService } from "primeng/api";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { CustomersService } from "src/app/_services/customers.service";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { DynamicFormService } from "src/app/_services/dynamic-form.service";
import { DynamicTableColumnsService } from "src/app/_services/dynamic-table-column.service";
import { Global } from "src/app/_utilities/global";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { DynamicLabelsService } from "src/app/_services/dynamic-labels.service";
var dateFormateChange: any;
@Component({
  selector: "app-list-ticket-table",
  templateUrl: "./list-ticket-table.component.html",
  styleUrls: ["./list-ticket-table.component.scss"],
  providers: [ConfirmationService],
})
export class ListTicketTableComponent
  extends SubscriptionUtil
  implements OnInit, OnChanges, OnDestroy {
  public ticketQueryForm!: UntypedFormGroup;

  public allTicketStatus: any[] = [];

  public gridOptions: any;
  public gridParams: any;
  public gridApi: any;
  public gridColumnApi: any;
  public columnDefs: any[] = [];
  public defaultColDef: any;
  public frameworkComponents: any;
  public overlayNoRowsTemplate = '<span style="padding: 10px; font-size: 14px;">Record not found</span>';
  public blockLoadDebounceMillis: number = 1000;
  public totalRecord: any;
  public currentRecordNumber: any;
  private parameters: SortModelItem | undefined;
  public ticketList: any;
  public allCustomers: any[] = [];
  public filterView = false;
  public noTickets = false;
  public selectedCustomer: any;
  public create: any;

  @Input() searchValue = "";
  @Input() metaData: any;
  @Output() noDataEvent = new EventEmitter();

  private filterAPILoaded = false;

  public showingTable = {
    customer: false,
  };

  clearAllActive = false;

  deleteHasPushed = false;
  loadTable: number = 0;
  sortBy: any;
  sortOrder: any;
  actionData: any;
  @Input() translate: any;
  tableContent: any;


  constructor(
    private util: UtilServiceService,
    private router: Router,
    private perm: PermissionService,
    private ticketsService: TicketsService,
    private errorUtil: ErrorUtil,
    private spinner: NgxSpinnerService,
    private deleteRecordService: DeleteRecordService,
    private fb: UntypedFormBuilder,
    private customerService: CustomersService,
    private dynamicFormService: DynamicFormService,
    private dynamicTableColumnsService: DynamicTableColumnsService,
    private auth: AuthenticationService,
  ) {
    super();
    this.preInit();
  }

  ngOnInit(): void {
    this.init();

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.searchValue &&
      changes.searchValue.currentValue !== changes.searchValue.previousValue
    ) {
      this.refresh();
    }

    if (
      changes &&
      changes.metaData &&
      changes.metaData.currentValue !== changes.metaData.previousValue
    ) {
      this.refresh();
    }
    /************************** Check translate object changed or not:  Language Translated Dynamically (Preeti) ************************************/
    if (
      changes &&
      changes.translate && changes.translate.previousValue != undefined && Object.keys(changes.translate.previousValue).length !== 0 &&
      changes.translate.previousValue !== changes.translate.currentValue
    ) {
      this.setOnlyColumn();
    }
  }

  /************************** Set Table Header:  Language Translated Dynamically (Preeti) ************************************/
  private setOnlyColumn() {
    // Reset columnDefs to avoid duplication
    this.gridOptions.columnDefs = [];
    console.log(this.tableContent);
    var columnDefs: any = this.gridOptions.columnDefs;
    this.tableContent.tableColumnSetups.forEach((element: any) => {
      this.cellRenderer(element);
      columnDefs.push(element);
      this.gridOptions.api!.setColumnDefs(columnDefs);
    });
    this.actionData.headerName = this.translate?.general.buttons.action.singular;
    this.overlayNoRowsTemplate = "<div class='not-found'><span>" + this.translate.general.messages.noRecordsFound + "</span> </div>";
    columnDefs.push(this.actionData);
    this.gridOptions.api!.setColumnDefs(columnDefs);
    setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 500);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.initForm();

    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    };

    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params: any) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.defaultColDef = {
      enableRowGroup: false, //ag-Grid-Enterprise
      enablePivot: false, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
      flex: 1,
      minWidth: 100,
    };


    this.actionData = {
          headerName: this.translate?.general.buttons.action.singular,
          filter: false,
          sortable: false,
          width: 0,
          headerTooltip: "#",
          headerClass: "marging-auto hide-action-border",
          cellClass: "text-center",
          cellRenderer: "buttonRenderer",
          cellRendererParams: {
            onClick: this.edit.bind(this),
            permissions: {
              update: this.perm.capable("tickets", "update"),
              remove: this.perm.capable("tickets", "remove"),
            },
            hidden: {
              update: !this.perm.capable("tickets", "update"),
              remove: !this.perm.capable("tickets", "remove"),
            },
          },
        }
  }

  /******************************************** Init ******************************************/
  private init() {
    this.create = this.perm.capable("tickets", "create");
    this.getTableColumn()
  }
  /******************************************** Pre Init ******************************************/
  private getTableColumn() {
    this.overlayNoRowsTemplate = "<div class='not-found'><span>" + this.translate.general.messages.noRecordsFound + "</span> </div>";
    // Reset columnDefs to avoid duplication
    this.gridOptions.columnDefs = [];
    this.push(
      this.dynamicFormService.getTableColumn("ticket")
        .subscribe((res: any) => {
          this.tableContent = res;
          this.sortBy = res.sortBy
          this.sortOrder = res.sortOrder

          var columnDefs: any = this.gridOptions.columnDefs;
          res.tableColumnSetups.forEach((element: any) => {
            this.cellRenderer(element);

            //console.log(element)
            columnDefs.push(element);
            this.gridOptions.api!.setColumnDefs(columnDefs);
          });
          this.actionData.headerName = this.translate?.general.buttons.action.singular;
          columnDefs.push(this.actionData);
          this.loadTable = 1;
          this.gridOptions.api!.setColumnDefs(columnDefs);
          this.onGridReady(this.gridParams);
        })
    );


  }
  /************************************************************************************/
  getNestedValue(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }
  /******************************************** Render Cell ******************************************/
  private cellRenderer(element: any) {
    var dateFormat: any = localStorage.getItem("date_format");
    if (element.translatePath) {
      element.headerName = this.getNestedValue(this.translate, element.translatePath);
    }
    element.headerTooltip = "#";
    if (element.field === "ticketNo") {
      element.headerComponentParams = Global.setHeaderComponentParams(element, 'Tbl_HeadTixNo'),
        element.cellRenderer = function (params: any) {
          if (params.data) {
            return Global.setCellRenderer(params, params.data.rowId, params.data.ticketNo)
          }
        };

    }
    else if (element.field === "customerNumber") {
      element.headerComponentParams = Global.setHeaderComponentParams(element, 'Tbl_HeadCusNo'),
        element.cellRenderer = function (params: any) {
          if (params.data) {
            return Global.setCellRenderer(params, params.data.rowId, params.data.customerNumber)
          }
        };

    }
    else if (element.field === "ticketStatus.fieldLabel") {
      element.cellRenderer = function (params: any) {
        let status = "";
        if (params.data && params.data.ticketStatus) {
          const colorCode = params.data.ticketStatus.colorCode ? params.data.ticketStatus.colorCode : "#6366F1";

          status =
            ' <span class="p-mr-2  p-tag p-component" style="background-color:' +
            colorCode +
            "50 !important ;color:" +
            colorCode +
            '"><span class="p-tag-value text-dark">' +
            params.data.ticketStatus.fieldLabel +
            "</span></span>";
        }

        return status;

      }
    }

    else if (element.field === "customer.name") {
      element.cellClass = function (params: any) {
        return ["text-1-5 font-weight-bold table_default_color"];
      };
    } else if (element.dataType == 'date' || element.dataType == "text" || element.dataType == "datetime" || element.dataType == "boolean") {
      element = Global.setColumnByDataType(element, this.auth.getUserTimezone(), dateFormat)
    }


    return element;
  }
  /******************************************** on table ready ******************************************/
  public onGridReady(params: any) {
    if (params != undefined) {
      this.gridParams = params;

      this.gridApi = params.api;
      params.api.sizeColumnsToFit();
      window.addEventListener('resize', () => {
        setTimeout(() => {
          params.api.sizeColumnsToFit();
        });
      });
      var dataSource = {
        getRows: (params: IServerSideGetRowsParams) => {
          if (this.loadTable == 1) { this.getServerSideData(params); }
        },
      };

      this.gridApi.setDatasource(dataSource);

      this.clearAllActive = false;

      setTimeout(() => {
        this.counterCustomer = 0;
      }, 1000);
    }
  }

  /******************************************** get data ******************************************/
  private getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
    // Show spinner when starting data loading
    this.spinner.show();
    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);
    var count: any = serverRequest.startRow;
    let startDateTime = "";
    let endDateTime = "";
    let ticketStatusId = null;
    let customerId = null;
    let searchValue = null;
    const dateStringRange = this.modifyDate(this.dateRange.value);
    if (this.dateRange.value) {
      startDateTime = dateStringRange[0] ?? "";
      endDateTime = dateStringRange[1] ?? "";
    }
    customerId = this.customerId.value ?? null;
    ticketStatusId = this.ticketStatus.value ?? null;
    searchValue = "";
    let siteId = null;
    let projectId = null;

    if (
      this.searchValue &&
      this.searchValue !== ""
    ) {
      searchValue = this.searchValue;
    } else {
      searchValue = "";
    }

    if (
      this.metaData &&
      this.metaData.data &&
      this.metaData.data.from &&
      this.metaData.data.from === "sites"
    ) {
      siteId = this.metaData.data.id;
    }

    if (
      this.metaData &&
      this.metaData.data &&
      this.metaData.data.from &&
      this.metaData.data.from === "projects"
    ) {
      projectId = this.metaData.data.id;
    }


    this.push(
      this.ticketsService
        .getAllTicket(
          startDateTime,
          endDateTime,
          ticketStatusId,
          customerId,
          siteId,
          projectId,
          searchValue,
          serverRequest
        )
        .subscribe(
          (data: any) => {
            this.totalRecord = data.total;
            data.data.forEach((element: any) => {
              count++
              element.rowId = 'Tbl_RecTixNo' + count
              element.rowId2 = 'Tbl_RecTixCust' + count
              element.buttonEdit = 'Btn_TixEdit' + count
              element.buttonDelete = 'Btn_TixDel' + count
            });
            this.setGridData(
              agGridGetRowsParams,
              data.data,
              this.totalRecord,
              data.status
            );

            this.spinner.hide();
          },
          (err) => {
            console.log(err)
            const title = this.errorUtil.processErrorTitle(err.error, this.translate)
            this.errorUtil.setErrorMessage(400, null, title, 'error', 3000);
            this.spinner.hide();
          }
        )
    );
  }

  /******************************************** get params of table ******************************************/
  private getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
      this.parameters = { colId: "id", sort: "desc" };
      agGridRequest.sortModel.push(this.parameters);
    }

    this.currentRecordNumber = agGridRequest.startRow;

    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,
    };
  }

  /******************************************** set data ******************************************/
  private setGridData(
    agGridGetRowsParams: IServerSideGetRowsParams,
    resultItems: any[],
    totalCount: number,
    status: string
  ) {
    this.gridApi.hideOverlay();

    if (status === "NO_DATA") {
      this.noTickets = true;
      this.noDataEvent.emit(this.noTickets);
      agGridGetRowsParams.successCallback([], 0);

      this.gridApi.showNoRowsOverlay();
    } else {
      this.noTickets = false;
      this.noDataEvent.emit(this.noTickets);

      if (resultItems.length === 0) {
        this.gridApi.showNoRowsOverlay();
      }

      agGridGetRowsParams.successCallback(resultItems, totalCount);
      ///CS-4758 this.gridApi.sizeColumnsToFit();
    }
  }

  /******************************************** navigate to ******************************************/
  public viewTickets(e: any) {
    this.util.resetTabView();

    if (e.colDef.field === "ticketNo") {
      this.router.navigate(["/dashboard/tickets/view/" + e.data.id], {
        queryParams: { id: e.data.id },
      });
    } else {
      //  this.router.navigate( ["/dashboard/customers/view/" + e.data.customer.id], { queryParams: { id: e.data.customer.id } }  );
      this.dynamicTableColumnsService.redirectURL(e)
    }


  }

  /******************************************** table context menu row table ******************************************/
  public getContextMenuItems = (params: any) => {
    const col = params.column.colId;
    let url = "";
    let id = 0;
    return this.dynamicTableColumnsService.rightClick(params);
  };

  /******************************************** on action ******************************************/
  public edit(e: any) {
    if (e.action === "edit") {
      this.router.navigate(["/dashboard/tickets/update/" + e.rowData.id], {
        queryParams: { id: e.rowData.id },
      });
    }

    if (e.action === "delete") {
      console.log(e);
      e.rowData.deleteHeader = this.translate.ticket.label.singular
      e.rowData.deleteType = "Ticket";
      e.rowData.message = this.translate?.general.messages.confirmDelete + " " + `${e.rowData.ticketNo} - ${e.rowData.subject}?`;

      if (!this.deleteHasPushed) {
        this.push(
          this.deleteRecordService.getItems(e.rowData).subscribe((data) => {
            // refresh the table

            this.onGridReady(this.gridParams);
          })
        );

        this.deleteHasPushed = true;
      } else {
        this.deleteRecordService.getItems(e.rowData);
      }
    }
  }

  counterCustomer = 0;

  /******************************************** init form ******************************************/
  private initForm() {
    this.ticketQueryForm = this.fb.group({
      selectedDateRange: ["", []],
      selectedTicketStatus: [null, []],
      customerId: [null, []],
      customerName: ["", []],
      searchQuery: ["", []],
      formatedDate: [null, []],
    });

    // search filter
    this.push(
      this.ticketQueryForm.controls.searchQuery.valueChanges.subscribe(
        (data) => {
          if (data.length >= 3) {
            this.onGridReady(this.gridParams);
          }

          if (data.length === 0) {
            this.onGridReady(this.gridParams);
          }
        }
      )
    );

    // search selected date range
    this.push(
      this.ticketQueryForm.controls.selectedDateRange.valueChanges.subscribe(
        (date) => {
          if (date) {
            if (this.clearAllActive === false) {
              if (date && date[0] && date[1]) {
                this.onGridReady(this.gridParams);
              }
            }
          }
        }
      )
    );

    // ticket status filter
    this.push(
      this.ticketQueryForm.controls.selectedTicketStatus.valueChanges.subscribe(
        (data) => {
          console.log(data);
          if (this.clearAllActive === false) {
            this.onGridReady(this.gridParams);
          }
        }
      )
    );

    // customer filter
    this.push(
      this.ticketQueryForm.controls.customerId.valueChanges.subscribe(
        (data) => {
          if (data) {
            this.counterCustomer = 1;
            if (this.clearAllActive === false) {
              if (this.counterCustomer === 0) {
                this.onGridReady(this.gridParams);
              }
            }
          }
        }
      )
    );
  }

  /******************************************** modify date ******************************************/
  private modifyDate(dateArray: Date[]) {
    let date1 = null;
    let date2 = null;

    if (dateArray && dateArray[0]) {
      date1 = dateArray[0] ? moment(dateArray[0]).format("YYYY-MM-DD") : null;
    }

    if (dateArray[1] && dateArray[1]) {
      date2 = dateArray[1] ? moment(dateArray[1]).format("YYYY-MM-DD") : null;
    }

    return [date1, date2];
  }

  get dateRange() {
    return this.ticketQueryForm.controls["selectedDateRange"];
  }

  get dateRangeFormated() {
    return this.ticketQueryForm.controls["formatedDate"];
  }

  get customerId() {
    return this.ticketQueryForm.controls["customerId"];
  }

  get ticketStatus() {
    return this.ticketQueryForm.controls["selectedTicketStatus"];
  }

  /******************************************** clear date ******************************************/
  public clearDate() {
    this.dateRange.setValue("");
  }

  /******************************************** toggle filter ******************************************/
  public toggleFilterView(): void {
    this.filterView = !this.filterView;

    if (this.filterAPILoaded === false) {
      // APIS
      this.push(
        this.ticketsService.getAllTicketStatus("active").subscribe(
          (data) => {
            // const allStatus = [
            //   {
            //     id: null,
            //     fieldLabel: "All",
            //     name: "All",
            //   },
            // ];

            // this.allTicketStatus = [...allStatus, ...data];

            this.allTicketStatus = [...data].sort((elementA: any, elementB: any) => {
              if (elementA?.order > elementB?.order) {
                return 1;
              }

              if (elementA?.order < elementB?.order) {
                return -1;
              }

              return 0;
            });
          },
          (err) => {
            console.log(err)
            const title = this.errorUtil.processErrorTitle(err.error, this.translate)
            this.errorUtil.setErrorMessage(400, null, title, 'error', 3000);
            this.spinner.hide();
          }
        )
      );

      this.filterAPILoaded = true;
    }
  }

  /******************************************** set customer id - filter ******************************************/
  public onChange($event: any) {
    const value = $event.value;
    const id = value ? value : null;

    this.customerId.setValue(id);
  }

  /******************************************** set customer id ******************************************/
  public setCustomerId(customer: any) {
    this.ticketQueryForm.controls["customerId"].setValue(customer.id);
    this.ticketQueryForm.controls["customerName"].setValue(customer.name);

    this.allCustomers = [];
  }

  /******************************************** clear customer ******************************************/
  public clearCustomer() {
    this.ticketQueryForm.controls["customerId"].setValue(null);
    this.ticketQueryForm.controls["customerName"].setValue("");

    this.allCustomers = [];
  }

  /******************************************** on filter customer ******************************************/
  // public onFilter($event: any) {
  //   const filter = $event.filter;

  //   if (filter !== "") {
  //     this.push(
  //       this.customerService
  //         .getAllCustomerByName(filter)
  //         .pipe(take(1))
  //         .subscribe((data: any) => {
  //           this.allCustomers = data;
  //         })
  //     );
  //   }
  // }

  /******************************************** reload data ******************************************/
  public refresh() {

    this.onGridReady(this.gridParams);

  }

  /******************************************** clear all filters ******************************************/
  public clearAll() {
    this.clearAllActive = true;

    this.clearDate();

    this.ticketStatus.setValue(null);

    this.customerId.setValue(null);

    this.selectedCustomer = null;

    this.onGridReady(this.gridParams);
  }

  /******************************************** On Customer Select Value ******************************************/
  public onCustomerSelect(event: any) {
    this.customerId.setValue(event.id);

    this.selectedCustomer = event;

    this.refresh();

    this.closeTable("customer");
  }

  /******************************************** Show Table Dynamic Picklist ******************************************/
  public showTable(what: string) {
    if (what === "customer") {
      this.showingTable.customer = true;
    }
  }

  /******************************************** Close Table Dynamic Picklist ******************************************/
  public closeTable(what: string) {
    if (what === "customer") {
      this.showingTable.customer = false;
    }
  }

  /******************************************** Clear Value Dynamic Picklist ******************************************/
  public clearValue(what: string) {
    if (what === "customer") {
      this.selectedCustomer = null;
      this.customerId.setValue(null);
    }

    this.refresh();
  }

  /******************************************** Get All Customer By Query ******************************************/
  public getCustomer(event: any) {
    const query = event.query.replace(
      /[&\/\\#,+()$~%!.„'":*‚^_¤?<>|@ª{«»§}©®™ ]/g,
      ""
    );

    if (query && query.length > 2) {
      this.push(
        this.customerService
          .getAllCustomerByName(query)
          .subscribe((customers: any) => {
            this.allCustomers = customers;
          })
      );
    }
  }
  /******************************************** Add ALl the Subscription ******************************************/
  push(obs: any) {
    super.push(obs);
  }

}
