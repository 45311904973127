import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { GridOptions, IServerSideGetRowsParams } from "ag-grid-community";
import { PermissionService } from "src/app/auth/permission.service";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { AssetService } from "src/app/_services/asset.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";

import * as moment from "moment-timezone";
import {
  PERSISTANT_ITEM,
  ServerRequest,
} from "src/app/_models/global.data.model";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { NgxSpinnerService } from "ngx-spinner";
import { ErrorUtil } from "src/app/_utilities/error";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { JobOrdersService } from "src/app/_services/job-orders.service";
import { ServiceZoneService } from "src/app/_services/service-zone.service";
import { DynamicFormService } from "src/app/_services/dynamic-form.service";
import { Global } from "src/app/_utilities/global";
import { DynamicTableColumnsService } from "src/app/_services/dynamic-table-column.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
var dateFormateChange: any;
@Component({
  selector: "app-scheduled-maintenance-table",
  templateUrl: "./scheduled-maintenance-table.component.html",
  styleUrls: ["./scheduled-maintenance-table.component.scss"],
})
export class ScheduledMaintenanceTableComponent
  extends SubscriptionUtil
  implements OnInit, OnChanges, OnDestroy {
  @Output() noDataEvent = new EventEmitter();
  @Input() id: any;
  @Input() forWhichModule: any;
  @Output() isFilterClosed = new EventEmitter();

  public isFilterToggleOn = false;
  public totalRecord: any;

  //grid
  gridApi: any;
  columnDefs: any[] = [];
  @Input() searchValue: any;
  pageSize: any = 25;
  public gridOptions!: GridOptions
  frameworkComponents!: { buttonRenderer: any };
  defaultColDef: any;
  overlayNoRowsTemplate: any;
  gridParams: any;
  startFrom: any;
  parameters: any;
  scheduledMaintenance: any;
  noMaintenance: boolean = false;
  filter: any = {
    statuses: null,
    types: "",
    fromDate: "",
    toDate: "",
    serviceZoneId: null,
  };
  dateRange: any;
  selectedRange: any;
  serviceZones: any;
  create: any;
  public allTypes: any;
  public allStatus: any;
  public addonFeatureDYNAMIC: any;
  private filterAPILoaded = false;
  loadTable = 0;
  sortBy: any;
  sortOrder: any;
  dateFormat: any;
  actionData: any;
  @Input() translate: any;
  tableContent: any;
  

  constructor(
    private assetService: AssetService,
    public util: UtilServiceService,
    private router: Router,
    private perm: PermissionService,
    private deleteRecordService: DeleteRecordService,
    private auth: AuthenticationService,
    private spinner: NgxSpinnerService,
    private errorUtil: ErrorUtil,
    private jobOrderService: JobOrdersService,
    private serviceZoneService: ServiceZoneService,
    private dynamicFormService: DynamicFormService,
    private dynamicTableColumnsService: DynamicTableColumnsService,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();
    this.preInit();
  }

  ngOnInit() {
    this.init();
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.searchValue &&
      changes.searchValue.previousValue !== changes.searchValue.currentValue
    ) {

      this.onGridReady(this.gridParams);
    }
    if (
      changes &&
      changes.searchValue &&
      changes.searchValue.previousValue !== changes.searchValue.currentValue &&
      changes.searchValue.currentValue === ""
    ) {

      this.onGridReady(this.gridParams);
    }

    /************************** Check translate object changed or not:  Language Translated Dynamically (Preeti) ************************************/
    if (
      changes &&
      changes.translate && changes.translate.previousValue != undefined && Object.keys(changes.translate.previousValue).length !== 0 &&
      changes.translate.previousValue !== changes.translate.currentValue
    ) {
      this.setOnlyColumn();
    }
  }

  /************************** Set Table Header:  Language Translated Dynamically (Preeti) ************************************/
  private setOnlyColumn() {
    // Reset columnDefs to avoid duplication
    this.gridOptions.columnDefs = [];
    console.log(this.tableContent);
    var columnDefs: any = this.gridOptions.columnDefs;
    this.tableContent.tableColumnSetups.forEach((element: any) => {
      this.cellRenderer(element);
      columnDefs.push(element);
      this.gridOptions.api!.setColumnDefs(columnDefs);
    });
    this.actionData.headerName = this.translate?.general.buttons.action.singular;
    columnDefs.push(this.actionData);
    this.gridOptions.api!.setColumnDefs(columnDefs);
    setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 500);
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  /******************************************** pre init ******************************************/
  private preInit() {
    this.addonFeatureDYNAMIC =
      this.auth.getAddonFeatureLists().addonDYNAMICMAINTENANCE;

    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    };

    this.defaultColDef = {
      enableRowGroup: true, //ag-Grid-Enterprise
      enablePivot: true, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };
    this.gridOptions = {
      cacheBlockSize: 25,
      paginationPageSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.actionData = {
      headerName: this.translate?.general.buttons.action.singular,
      filter: false,
      headerTooltip: "#",
      width: 150,
      sortable: false,
      headerClass: "marging-auto hide-action-border",
      cellStyle: { "text-align": "center" },
      cellRenderer: "buttonRenderer",
      cellRendererParams: {
        onClick: this.edit.bind(this),
        permissions: {
          update: this.perm.capable("scheduled_maintenance", "update"),
          remove: this.perm.capable("scheduled_maintenance", "remove"),
        },
        hidden: {
          update: !this.perm.capable("scheduled_maintenance", "update"),
          remove: !this.perm.capable("scheduled_maintenance", "remove"),
        },
      },
    };
  }

  /******************************************** init ******************************************/
  private init() {
    this.create = this.perm.capable("scheduled_maintenance", "create");
    this.initializeColumns()
    this.allStatus = [
      {
        value: "PENDING",
        label: this.translate?.general.status.pending.singular,
      },
      {
        value: "COMPLETED",
        label: this.translate?.general.status.completed.singular,
      },
      {
        value: "OVER_DUE",
        label: this.translate?.general.status.overdue.singular,
      },
      {
        value: "CANCELLED",
        label: this.translate?.general.status.cancelled.singular,
      },
    ];

    this.allTypes = [
      {
        value: "BREAKDOWN",
        label: this.translate?.general.status.breakDown.singular,
      },
      {
        value: "PREVENTIVE",
        label: this.translate?.general.status.preventive.singular,
      },
      {
        value: "ON_DEMAND",
        label: this.translate?.general.status.onDemand.singular,
      },
      {
        value: "OTHERS",
        label: this.translate?.maintenance.fields.other.singular,
      },
    ];
  }

  /********************************** PreInit *****************************/
  private initializeColumns() {
    this.dateFormat = localStorage.getItem("date_format");
    dateFormateChange = this.auth.getUserTimezone();

    this.dynamicFormService.getTableColumn("maintenance_item").subscribe((res: any) => {
      this.tableContent = res;
      this.sortBy = res.sortBy
      this.sortOrder = res.sortOrder

      var columnDefs: any = []
      res.tableColumnSetups.forEach((element: any) => {
        this.cellRenderer(element);

        columnDefs.push(element);
        this.gridOptions.api!.setColumnDefs(columnDefs);
      });
      this.overlayNoRowsTemplate = "<div class='not-found'><span>" + this.translate?.general.messages.noRecordsFound + "</span> </div>";

      this.actionData.headerName = this.translate?.general.buttons.action.singular;
      columnDefs.push(this.actionData);

      this.loadTable = 1;
      this.gridOptions.api!.setColumnDefs(columnDefs);
      this.onGridReady(this.gridParams);
    });
  }

  /********************************** Render Cell *****************************/
  private cellRenderer(element: any) {
    console.log(element, "===", element.dataType)

    var dateFormat: any = localStorage.getItem("date_format");

    if (element.translatePath) {
      element.headerName = this.languageTranslateService.getNestedValue(this.translate, element.translatePath);
    }
    element.headerTooltip = "#";

    if (element.field === "scheduleNumber") {
      element.headerComponentParams = Global.setHeaderComponentParams(element, 'Tbl_HeadPmNo'),
        element.cellRenderer = function (params: any) {
          if (params.data) {
            return Global.setCellRenderer(params, params.data.rowId, params.data.scheduleNumber)
          }
        };
    }
    else if (element.field === "type") {
      // element.hide= this.addonFeatureDYNAMIC ? true : false,
      element.cellRenderer = (params: any) => {
        if (params.data) {
          if (params.data.type == "BREAKDOWN") {
            return "Break Down";
          } else if (params.data.type == "PREVENTIVE") {
            return "Preventive";
          } else if (params.data.type == "ON_DEMAND") {
            return "On Demand";
          } else if (params.data.type == "OTHERS") {
            return "Others";
          }
        }
      }
    }

    // else if (element.field === "preventativeMaintenanceFrequency.name") {
    //   element.hide= this.addonFeatureDYNAMIC ? true : false
    //  }
    //  else if (element.field === "jobOrderType.name") {
    //   element.hide= this.addonFeatureDYNAMIC ? true : false
    //  }
    else if (element.field === "serviceType") {
      //  element.hide= this.addonFeatureDYNAMIC ? true : false
      element.cellRenderer = (params: any) => {
        if (params.data) {
          if (params.data.serviceType == "MINOR") {
            return "Minor";
          } else if (params.data.serviceType == "MAJOR") {
            return "Major";
          } else {
            return params.data.serviceType;
          }
        }
      }
    }

    /*---------------------Display Status ---------------------*/
    else if (element.field === "status") {
      element.cellRenderer = (params: any) => {
        if (params.data) {
          if (params.data.status == "PENDING") {
            return (
              '<span class="p-tag p-component pending"><span class="p-tag-value text-dark">' +
              this.translate.general.status.pending.singular +
              "</span></span>"
            );
          } else if (params.data.status == "COMPLETED") {
            return (
              '<span class="p-tag p-component complete"><span class="p-tag-value text-dark">' +
              this.translate.general.status.completed.singular +
              "</span></span>"
            );
          } else if (params.data.status == "OVER_DUE") {
            return (
              '<span class="p-tag p-component overdue"><span class="p-tag-value text-dark">' +
              this.translate.general.status.overdue.singular +
              "</span></span>"
            );
          } else if (params.data.status == "CANCELLED") {
            return (
              '<span class="p-tag p-component cancel"><span class="p-tag-value text-dark">' +
              this.translate.general.status.cancelled.singular +
              "</span></span>"
            );
          }
        }
      };
    }
    /*---------------------Display Column Base on Type ---------------------*/
    else if (element.dataType == 'date' || element.dataType == "text" || element.dataType == "time" || element.dataType == "boolean") {
      element = Global.setColumnByDataType(element, this.auth.getUserTimezone(), dateFormat)
    }
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** on grid table ******************************************/
  public onGridReady(params: any) {
    // Show spinner when starting data loading
    this.spinner.show();
    if (params) {
      this.gridParams = params;
      this.gridApi = params.api;
    }
    this.searchValue = this.searchValue ? this.searchValue : "";
    var count: number = 0;

    var dataSource = {
      getRows: (params: any) => {
        if (this.loadTable == 1 && this.translate) {
          this.startFrom = params.startRow + 1;
          const serverParams = this.getRequestParams(params);
          const isDynamic = this.addonFeatureDYNAMIC ? true : false;
          if (!this.id && !this.forWhichModule) {
            this.push(
              this.assetService
                .getScheduledMaintenanceGridAll(
                  this.startFrom,
                  25,
                  this.searchValue,
                  this.filter.statuses,
                  this.filter.types,
                  this.filter.fromDate,
                  this.filter.toDate,
                  this.filter.serviceZoneId,
                  serverParams.sortModel[0].colId,
                  serverParams.sortModel[0].sort,
                  isDynamic
                )
                .subscribe((data: any) => {
                  this.scheduledMaintenance = data.data;
                  this.scheduledMaintenance.forEach((element: any) => {
                    count++
                    element.rowId = 'Tbl_RecPmNo' + count
                    element.buttonEdit = 'Btn_PmEdit' + count
                    element.buttonDelete = 'Btn_PmDel' + count
                  });
                  this.setGridData(
                    params,
                    this.scheduledMaintenance,
                    data.total,
                    data.status
                  );
                  
                  this.spinner.hide();
                })
            );
          }
          (err) => {
            console.log(err)
           const title = this.errorUtil.processErrorTitle(err.error,this.translate)
           this.errorUtil.setErrorMessage(400, null ,  title, 'error',3000);
           this.spinner.hide();
        }
          if (
            this.id &&
            this.forWhichModule === "MaintenanceScheduledMaintenance"
          ) {
            this.push(
              this.assetService
                .getScheduledMaintenanceGrid(
                  this.startFrom,
                  25,
                  this.searchValue,
                  this.id,
                  this.filter.statuses,
                  this.filter.types,
                  this.filter.fromDate,
                  this.filter.toDate,
                  this.filter.serviceZoneId,
                  serverParams.sortModel[0].colId,
                  serverParams.sortModel[0].sort
                )
                .subscribe((data: any) => {
                  this.scheduledMaintenance = data.data;
                  this.scheduledMaintenance.forEach((element: any) => {
                    count++
                    element.rowId = 'Tbl_RecPmNo' + count
                    element.buttonEdit = 'Btn_PmEdit' + count
                    element.buttonDelete = 'Btn_PmDel' + count
                  });
                  this.setGridData(
                    params,
                    this.scheduledMaintenance,
                    data.total,
                    data.status
                  );
                  
                  this.spinner.hide();

                })
            );
          }
          if (this.id && this.forWhichModule === "AssetScheduledMaitenance") {
            this.push(
              this.assetService
                .getScheduledMaintenanceGridAssets(
                  this.startFrom,
                  25,
                  this.searchValue,
                  this.id,
                  this.filter.statuses,
                  this.filter.types,
                  this.filter.fromDate,
                  this.filter.toDate,
                  this.filter.serviceZoneId,
                  serverParams.sortModel[0].colId,
                  serverParams.sortModel[0].sort
                )
                .subscribe((data: any) => {
                  this.scheduledMaintenance = data.data;
                  this.scheduledMaintenance.forEach((element: any) => {
                    count++
                    element.rowId = 'Tbl_RecPmNo' + count
                    element.buttonEdit = 'Btn_PmEdit' + count
                    element.buttonDelete = 'Btn_PmDel' + count
                  });
                  this.setGridData(
                    params,
                    this.scheduledMaintenance,
                    data.total,
                    data.status
                  );
                  
                  this.spinner.hide();
                })
            );
          }
        }
      },
    };

    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
      this.gridApi.setDatasource(dataSource);
    }
  }

  /******************************************** get request params ******************************************/
  private getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
      this.parameters = { colId: this.sortBy, sort: this.sortOrder }

      agGridRequest.sortModel.push(this.parameters);
    }

    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,
    };
  }

  /******************************************** set data to grid ******************************************/
  private setGridData(
    agGridGetRowsParams: IServerSideGetRowsParams,
    resultItems: any[],
    totalCount: number,
    status: string
  ) {

    this.gridApi.hideOverlay();

    this.totalRecord = totalCount;

    this.scheduledMaintenance = resultItems;

    if (status === "NO_DATA") {
      this.noMaintenance = true;
    } else {
      this.noMaintenance = false;
    }

    if (this.scheduledMaintenance.length === 0) {
      this.gridApi.showNoRowsOverlay();
    } else {
      agGridGetRowsParams.successCallback(
        this.scheduledMaintenance,
        totalCount
      );
    }

    this.noDataEvent.emit(this.noMaintenance);


    this.gridApi.sizeColumnsToFit();
  }

  /******************************************** refresh ******************************************/
  public refresh() {
   
      this.onGridReady(this.gridParams);
    
  }

  /******************************************** on edit/delete ******************************************/
  private edit(e: any) {
    if (e.action === "edit") {
      this.util.setPersistantItem(
        PERSISTANT_ITEM.SCHEDULE_MAINTENANCE,
        e.rowData
      );

      this.router.navigate([
        "/dashboard/preventative-maintenance/update",
      ]);
    } else if (e.action === "delete") {
      const item = {
        deleteHeader: this.translate?.preventiveMaintenance.label.singular,
        deleteType: "Preventative Maintenance",
        id: e.rowData.id,
        message: this.translate?.general.messages.confirmDelete + " " + ` ${e.rowData.scheduleNumber}`,
      };

      this.push(
        this.deleteRecordService.getItems(item).subscribe((data) => {
          //debugger
          this.refresh();
        })
      );
    }
  }

  /******************************************** on cell clicked ******************************************/
  public view(e: any) {
    this.util.resetTabView();
    //debugger
    this.dynamicTableColumnsService.redirectURL(e)

  }

  /******************************************** reset filter ******************************************/
  public reset() {
    this.dateRange = undefined;
    this.filter = {
      statuses: null,
      types: "",
      fromDate: "",
      toDate: "",
      serviceZoneId: null,
    };

    this.onGridReady(this.gridParams);
  }

  /******************************************** on date change ******************************************/
  public onDateChange(event: any) {
    if (event && event.length) {
      this.selectedRange = event;
      this.filter.fromDate = moment(this.selectedRange[0]).format("YYYY-MM-DD");
      this.filter.toDate = moment(this.selectedRange[1]).format("YYYY-MM-DD");
    } else {
      this.filter.fromDate = "";
      this.filter.toDate = "";
    }

    if (this.selectedRange[0] && this.selectedRange[1]) {
      this.refresh();
    }
  }

  /******************************************** get context menu ******************************************/
  public redirectToNewTab(data: any) {
    let url = "";

    if (data.field === "scheduleNumber") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(
          ["/dashboard/preventative-maintenance/view/" + data.id],
          { queryParams: { id: data.id } }
        )
      );
    }

    url = url.replace("%23", "#");
    window.open(url, "_blank");
  }

  /******************************************** get context menu ******************************************/
  public getContextMenuItems = (params: any) => {
    const result = [];

    params.node.data.field = params.column.colId;

    if (params.column.colId == "scheduleNumber") {
      result.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(params.node.data),
      });
    }

    return result;
  };

  /******************************************** on toggle state ******************************************/
  public filterToggle() {
    this.isFilterToggleOn = !this.isFilterToggleOn;
    this.isFilterClosed.emit(this.isFilterToggleOn)
    if (this.filterAPILoaded === false) {
      // APIS
      this.push(
        this.serviceZoneService.getAllServiceZones().subscribe((res: any) => {
          this.serviceZones = res.sort((elementA: any, elementB: any) => {
            if (elementA?.order > elementB?.order) {
              return 1;
            }

            if (elementA?.order < elementB?.order) {
              return -1;
            }

            return 0;
          });
        })
      );

      if (this.addonFeatureDYNAMIC) {
        this.jobOrderService.getJobOrderTypes(true).subscribe((resp) => {
          this.allTypes = resp.map((joT: any) => {
            return {
              value: joT.name,
              label: joT.label,
              order: joT.order
            };
          }).sort((elementA: any, elementB: any) => {
            if (elementA?.order > elementB?.order) {
              return 1;
            }

            if (elementA?.order < elementB?.order) {
              return -1;
            }

            return 0;
          });
        });
      }

      this.filterAPILoaded = true;
    }
  }

  /******************************************** return label ******************************************/
  public returnLabel(what: string, value: string) {
    let label = "";

    if (what === "types") {
      label = this.allTypes.find((type) => type.value === value)!.label;
    }

    if (what === "status") {
      label = this.allStatus.find((status) => status.value === value)!.label;
    }

    if (what === "serviceZone") {
      label = this.serviceZones.find((zone: any) => zone.id == value)!.name;
    }

    return label;
  }


  push(obs: any) {
    super.push(obs);
  }
}
