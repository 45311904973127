import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  ColDef,
  GridApi,
  GridOptions,
  GridParams,
  IGetRowsParams,
} from "ag-grid-community";
import { NgxSpinnerService } from "ngx-spinner";
import { PermissionService } from "src/app/auth/permission.service";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import {
  PERSISTANT_ITEM,
  ServerRequest,
} from "src/app/_models/global.data.model";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { ProjectService } from "src/app/_services/project.service";
import { SiteService } from "src/app/_services/site.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { DynamicLabelsService } from "src/app/_services/dynamic-labels.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";

@Component({
  selector: "app-contacts-table",
  templateUrl: "./contacts-table.component.html",
  styleUrls: ["./contacts-table.component.scss"],
})
export class ContactsTableComponent
  extends SubscriptionUtil
  implements OnInit, OnChanges, OnDestroy {
  // table
  public frameworkComponents!: any;
  public gridOptions!: GridOptions;
  public defaultColDef!: ColDef;
  public columnDefs: any[] = [];
  private gridParams!: GridParams;
  private gridApi!: GridApi;
  public overlayNoRowsTemplate =
    '<span style="padding: 10px; font-size: 14px;">Record not found</span>';

  public totalRecords: any;
  public noData = false;

  @Input() searchValue = "";
  @Input() data: any;
  @Output() noDataEvent = new EventEmitter();
  public rowData: any[] = [];
  translate: any;
  loadTable: number;


  constructor(
    private perm: PermissionService,
    private deleteRecordService: DeleteRecordService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private util: UtilServiceService,
    private sitesService: SiteService,
    private errorUtil: ErrorUtil,
    private projectService: ProjectService,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();

    this.preInit();
  }

  ngOnInit(): void {
    this.init();

  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes)
    if (
      changes &&
      changes.searchValue &&
      changes.searchValue.previousValue !== changes.searchValue.currentValue
    ) {
      this.search();
    }

    if (
      changes &&
      changes.searchValue &&
      changes.searchValue.previousValue !== changes.searchValue.currentValue &&
      changes.searchValue.currentValue === ""
    ) {
      this.search();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    };

    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: "clientSide",
      sortingOrder: ["desc", "asc"],
      suppressNoRowsOverlay: true,
      onGridSizeChanged: (params: any) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.defaultColDef = {
      enableRowGroup: false, //ag-Grid-Enterprise
      enablePivot: false, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };

    //this.columnDefs 
  }

  /******************************************** Init ******************************************/
  private init() {
    this.getLanguageData()

  }

  /******************************************** Generate Permissions ******************************************/
  private generatePermissionFor(forWhat: string) {
    const perm = {
      update: false,
      remove: false,
    };

    if (forWhat === "site" || forWhat === "sites") {
      perm.update = this.perm.capable("site", "update");
      perm.remove = this.perm.capable("site", "remove");
    } else if (forWhat === "project" || forWhat === "projects") {
      perm.update = this.perm.capable("project", "update");
      perm.remove = this.perm.capable("project", "remove");
    } else {
      perm.update = this.perm.capable("customers", "update");
      perm.remove = this.perm.capable("customers", "remove");
    }

    return perm;
  }

  /******************************************** Actions ******************************************/
  public edit(e: any) {
    const items = JSON.parse(sessionStorage.getItem("navibar")!) ?? null;
    if (e.action === "edit") {
      this.util.setPersistantItem(PERSISTANT_ITEM.CONTACTS, {
        accessFrom: this.data.accessFrom,
        id: this.data.id,
        label: items[1].label,
      });

      this.router.navigate(
        ["/dashboard/sites/contacts/update/" + e.rowData.id],
        {
          queryParams: { id: e.rowData.id },
        }
      );
    }

    if (e.action === "delete") {
      const item = {
        deleteHeader: this.translate.contacts.label.singular,
        deleteType: "Contact",
        id: e.rowData.id,
        message: this.translate?.general.messages.confirmDelete + " " + `${e.rowData.fullName}?`,
      };

      this.push(
        this.deleteRecordService.getItems(item).subscribe((resp) => {
          // refresh the table

          if (resp.status === 200) {
            this.refresh();
          }
        })
      );
    }
  }

  /******************************************** On Table Ready ******************************************/
  public onGridReady(params: any) {
    if (params != undefined) {
      this.gridParams = params;

      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();

      this.getServerSideData(params);

      this.gridApi.sizeColumnsToFit();
    }
  }

  /******************************************** Get Data ******************************************/
  private getServerSideData(agGridGetRowsParams: IGetRowsParams) {
    this.rowData = [];
    // Show spinner when starting data loading
    this.spinner.show();
    this.gridApi.hideOverlay();

    // const serverRequest = this.getRequestParams(agGridGetRowsParams);
    if (this.data && this.data.accessFrom && this.data.accessFrom === "site" || this.data.accessFrom === "sites") {
      this.push(
        this.sitesService.getSiteContacts(this.data.id).subscribe(
          (resp) => {
            if (resp && resp.length) {
              this.setGridData(
                agGridGetRowsParams,
                resp,
                resp.length,
                "HAS_DATA"
              );
            } else {
              this.setGridData(
                agGridGetRowsParams,
                resp,
                resp.length,
                "NO_DATA"
              );
            }

            this.spinner.hide();
          },
          (err) => {
            console.log(err)
            const title = this.errorUtil.processErrorTitle(err.error, this.translate)
            this.errorUtil.setErrorMessage(400, null, title, 'error', 3000);
            this.spinner.hide();
          }
        )
      );
    }

    if (
      this.data &&
      this.data.accessFrom &&
      this.data.accessFrom === "project" ||
      this.data.accessFrom === "projects"
    ) {
      this.push(
        this.projectService.getProjectContact(this.data.id).subscribe(
          (resp) => {
            if (resp && resp.length) {
              this.setGridData(
                agGridGetRowsParams,
                resp,
                resp.length,
                "HAS_DATA"
              );
            } else {
              this.setGridData(
                agGridGetRowsParams,
                resp,
                resp.length,
                "NO_DATA"
              );
            }

            this.spinner.hide();
          },
          (err) => {
            this.errorUtil.setErrorMessage(err);
            // this is to ensure locks are released and spinner is hidden even on error

            this.spinner.hide();
          }
        )
      );
    }
  }

  /******************************************** Set Data ******************************************/
  private setGridData(
    agGridGetRowsParams: IGetRowsParams,
    resultItems: any[],
    totalCount: number,
    status: string
  ) {
    this.gridApi.hideOverlay();

    this.totalRecords = totalCount;

    if (status === "NO_DATA") {
      this.noData = true;
      this.gridOptions.suppressNoRowsOverlay = false;

      this.gridApi.showNoRowsOverlay();
    } else {
      this.noData = false;

      if (resultItems.length === 0) {
        this.gridOptions.suppressNoRowsOverlay = false;

        this.gridApi.showNoRowsOverlay();
      } else {
        this.gridOptions.suppressNoRowsOverlay = true;

        this.rowData = resultItems;
      }

      ///CS-4758  this.gridApi.sizeColumnsToFit();
    }

    this.noDataEvent.emit(this.noData);
  }

  /******************************************** Refresh Table ******************************************/
  public refresh() {

    this.onGridReady(this.gridParams);

  }

  /******************************************** View Cell ******************************************/
  public viewCell(e: any) {
    if (e.colDef.field === "fullName") {
      const items = JSON.parse(sessionStorage.getItem("navibar")!) ?? null;
      this.util.setPersistantItem(PERSISTANT_ITEM.CONTACTS, {
        id: this.data.id,
        accessFrom: this.data.accessFrom,
        label: items[1].label,
      });

      this.router.navigate(
        [`dashboard/${this.fixPath(this.data.accessFrom)}/contacts/view/` + e.data.id],
        {
          queryParams: { id: e.data.id },
        }
      );
    }

    if (e.colDef.field === "email") {
      const aTag = document.createElement("a") as HTMLAnchorElement;

      aTag.href = "mailto:" + e.data.email;
      aTag.target = "_self";

      if (e.data.email) {
        aTag.click();
      }
    }
  }

  /******************************************** Search List ******************************************/
  public search() {
    if (this.gridApi) {
      console.log(this.searchValue)
      if (this.searchValue) {
        this.gridApi.setQuickFilter(this.searchValue);
        this.totalRecords = this.gridApi.getDisplayedRowCount();
        if (this.totalRecords == 0) {
          this.gridOptions.suppressNoRowsOverlay = false;
          this.gridApi.showNoRowsOverlay();
        } else {
          this.gridOptions.suppressNoRowsOverlay = true;

          this.gridApi.hideOverlay();
        }
      } else {
        this.gridApi.setQuickFilter(this.searchValue);
        this.gridOptions.suppressNoRowsOverlay = true;

        this.gridApi.hideOverlay();
      }
    }
  }

  /******************************************** Table Context Menu ******************************************/
  public getContextMenuItems = (params: any) => {
    const col = params.column.colId;
    let url = "";
    let id = 0;
    let result = [
      {
        name: "Open link in new tab",
        action: () => this.util.openItemToNewTab(url),
      },
    ];

    if (col === "fullName") {
      id = params.node.data.id;
      url = `/dashboard/${this.fixPath(this.data.accessFrom)}/contacts/view/${id}?id=${id}`;
    }

    return result;
  };
  /******************************************** Add ALl the Subscription ******************************************/
  push(obs: any) {
    super.push(obs);
  }

  public fixPath(accessFrom: string) {
    let path = accessFrom;

    if (accessFrom == "site") {
      path = "sites";
    }

    if (accessFrom === "project") {
      path = "projects";
    }

    return path;
  }

  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => {
      if (Object.keys(data).length != 0) {
        this.translate = data
        this.initializeColumns();
      }
    }))
    console.log("translate", this.translate)
    //this.mainLabel = this.translate?.project.label.plural
  }

  initializeColumns() {

    this.columnDefs = [];
    this.overlayNoRowsTemplate = "<div class='not-found'><span>" + this.translate?.general.messages.noRecordsFound + "</span> </div>";

    const newData = [
      {
        headerName: this.translate?.contacts.fields.contactName.singular,
        field: "fullName",
        sort: 'asc',
        width: 250,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return ["table_default_color"];
        },
        cellRenderer: function (params: any) {
          if (params.data) {
            if (params.data.title) {
              return params.data.title + " " + params.data.fullName;
            } else {
              return params.data.fullName;
            }
          }
        },
      },
      {
        headerName: this.translate?.contacts.fields.phone.singular,
        field: "phone",
        width: 250,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data) {
            return params.data.phone;
          }
        },
      },
      {
        headerName: this.translate?.contacts.fields.email.singular,
        field: "email",
        width: 220,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return ["table_default_color"];
        },
        cellRenderer: function (params: any) {
          if (params.data) {
            return params.data.email == null ? "" : params.data.email;
          }
        },
      },
      // {
      //   headerName: "Type",
      //   field: "type",
      //   width: 220,
      //   headerTooltip: "#",
      //   cellRenderer: function (params: any) {
      //     if (params.data) {
      //       return params.data.type
      //         ? params.data.type.charAt(0).toUpperCase() +
      //             params.data.type.slice(1).toLowerCase()
      //         : "";
      //     }
      //   },
      // },
      {
        headerName: this.translate?.contacts.fields.designation.singular,
        field: "designation",
        width: 220,
        headerTooltip: "#",
      },
      {
        headerName: this.translate?.general.buttons.action.singular,
        sortable: false,
        filter: false,
        width: 150,
        headerClass: "marging-auto hide-action-border",
        cellStyle: { "text-align": "center" },
        cellRenderer: "buttonRenderer",
        cellRendererParams: {
          onClick: this.edit.bind(this),
          permissions: {
            update: this.generatePermissionFor(this.data.accessFrom).update,
            remove: this.generatePermissionFor(this.data.accessFrom).remove,
          },
          hidden: {
            update: !this.generatePermissionFor(this.data.accessFrom).update,
            remove: !this.generatePermissionFor(this.data.accessFrom).remove,
          },
        },
      }
    ];

    this.columnDefs = newData;
    this.loadTable = 1;
    this.columnDefs = newData;
    if (this.gridApi) {
      this.gridApi.setColumnDefs(this.columnDefs);
      setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 500);
    }
  }
}
