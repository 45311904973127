import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { PermissionService } from "src/app/auth/permission.service";
import { PERSISTANT_ITEM } from "src/app/_models/global.data.model";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-scheduled-maintenance-details",
  templateUrl: "./scheduled-maintenance-details.component.html",
})
export class ScheduledMaintenanceDetailsComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  @Input() scheduledMaintenance: any;

  public dateFormat!: string;
  public timezone: any;

  public addonFeature: any;

  createJO: any;
  translate: any;

  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private util: UtilServiceService,
    private perm: PermissionService,
    private languageTranslateService: LanguageTranslateService
  ) {
    super()
    
    this.preInit()
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy()
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.addonFeature = this.auth.getAddonFeatureLists();
  }

  /******************************************** init ******************************************/
  private init() {
    this.timezone = this.auth.getUserTimezone();
    this.dateFormat = localStorage.getItem("date_format")!;
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
    this.createJO = this.perm.capable("job_order", "create");
  }

  /******************************************** navigate to ******************************************/
  public navigateTo(where: string, value: any) {
    this.util.resetTabView();

    if (where === "asset") {
      localStorage.setItem("activeIndexAssetView", "0");
      this.router.navigate(["/dashboard/assets/view/" + value], {
        queryParams: { id: value },
      });
    }

    if (where === "sub-maintenace") {
      this.router.navigate(["/dashboard/assets/maintenance/view/" + value], {
        queryParams: { id: value },
      });
    }

    if (where === "viewJO") {
      localStorage.setItem("activeIndexJobOrder","0");
      this.router.navigate(["/dashboard/jobOrders/view/" + value], {
        queryParams: { jobOrderId: value },
      });
    }
  }

  /******************************************** create job order ******************************************/
  public createJobOrder() {
    let customer = { id: "", name: "" };

    if (this.scheduledMaintenance.maintenance) {
      customer = {
        id: this.scheduledMaintenance.maintenance.contract.customerId,
        name: this.scheduledMaintenance.maintenance.contract.customerName,
      };
    }
    let dataObj = {
      scheduledMaintenanceId: parseInt(this.scheduledMaintenance.id),
      type: "Preventative Maintenance",
      scheduledMaintenanceType: this.scheduledMaintenance.type,
      assetName: this.scheduledMaintenance.assets ? this.scheduledMaintenance.assets.name : null ,
      assetId: this.scheduledMaintenance.assets ? this.scheduledMaintenance.assets.id : null ,
      maintenanceDate: this.scheduledMaintenance.scheduledDateTime,
      customer: customer,
    };

    this.util.setPersistantItem(PERSISTANT_ITEM.JOB_ORDER, dataObj);

    this.router.navigate(["/dashboard/jobOrders/create"]);
  }

  push(obs:any) {
    super.push(obs);
  }
}
