import { Component, OnInit, ElementRef, ViewChild, OnDestroy, AfterViewChecked, ChangeDetectorRef, OnChanges, Input, Output, EventEmitter } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { PermissionService } from 'src/app/auth/permission.service';
import { UsersService } from 'src/app/_services/admin/users.service';
import { BreadcrumbService } from 'src/app/_services/breadcrumb.service';
import { DeleteRecordService } from 'src/app/_services/delete-record.service';
import { ModalServiceService } from 'src/app/_services/modal-service.service';
import { TasksService } from 'src/app/_services/tasks.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { environment } from 'src/environments/environment';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ViewCheckinMapComponent } from '../view-checkin-map/view-checkin-map.component';
import { AttachmentService } from 'src/app/_services/attachment.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { CustomersService } from 'src/app/_services/customers.service';

import { DynamicLabelsService } from 'src/app/_services/dynamic-labels.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';

@Component({
  selector: 'app-view-tasks',
  templateUrl: './view-tasks.component.html'
})
export class ViewTasksComponent extends SubscriptionUtil implements OnInit, OnDestroy, AfterViewChecked {
  @Output() closeSlide = new EventEmitter();
  @Input() taskId: any;
  @Input() showClose: any;

  bodyHeight: any;
  @ViewChild('topElement')
  topElement!: ElementRef;
  @ViewChild('bodyElement')
  bodyElement!: ElementRef;


  dateFormat: any;
  requestAssistances: any[] = [];
  photoUrl: any;
  attachmentsArray: any[] = [];
  taskAttachments: any[]=[];
  refresher:Subscription;
  file: any;
  addNotes: any = {};
  timezone: string;
  remove!: boolean;
  update!: boolean;
  create!: boolean;
  create_so!:boolean;
  difference!: string;
  taskNotes: any;
  taskStatus : string = 'Open';
  task: any = {};
  taskActivity : any[]=[];

  modalState : any;
  userView: String = 'Activity';
  config2:any = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'custom-modal-lg'
  };
  selectedComponent : any;
  selectedConfig : any;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-md'
  };
  configSM: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "modal-sm",
  };
  requestAssistanceUser : any = {};
  submitTouched : boolean = false;
  allowAssignTask!: boolean;
  taskJourney: any = {};
  read_Jo!: Boolean;
  read_Customer !: Boolean;
  userMatchForTask: any;
  assistancePerm: any;
  addonFeature: any;
  uploader: any

  html: string = `
      <div>
        <div *ngFor="let skill of task?.userDynamicSkillList;let i = index">
          <div class="rounded-5 bg-purple px-2 py-1 mx-1 d-flex align-items-center">
              <div class="d-flex align-items-center">
                  <span class="text-12 font-white text-nowrap px-1">{{skill.label}}</span>
              </div>
          </div>
        </div>
      </div>`;
  userIds : any[] = [];

  buttonActive:boolean = false;
  buttonStatus!: boolean;
  addMemberButton!: boolean;
  taskStatusList: any;
  editForm:boolean = false;
  public allPriorities: any = [];
  selectedPriority: any;
  timeline: any;
  loginUser: any;

  siteRead: any;
  projectRead: any;
  public addonChecklist: any;
  isSummeryOpen!: boolean;
  isCusSummeryOpen!: boolean;
  customerSummery: any;
  customerId: any;

  @ViewChild("fileUpload", { static: false })
fileUpload!: any;
  translate: any;

  constructor(
    private modalService: BsModalService,
    private router : Router,
    private taskService: TasksService,
    private route: ActivatedRoute,
    private utilService: UtilServiceService,
    private perm: PermissionService,
    public auth: AuthenticationService,
    private custellaModalService : ModalServiceService,
    private attachmentService: AttachmentService,
    private userService: UsersService,
    private spinner: NgxSpinnerService,
    private cdr : ChangeDetectorRef,
    private errorUtil: ErrorUtil,
    private deleteRecordService: DeleteRecordService,
    private customerService: CustomersService,
    private languageTranslateService: LanguageTranslateService,
    ) {
      super();
      this.getLanguageData();
      this.refresher = taskService.refreshList$.subscribe(item => {
        console.log(item)
        if(item == 'Deleted Task'){
          //this.utilService.goBack();
          this.closeModule()
        } else {
          this.loadTask(this.taskId)
        }
      });
      this.timezone = auth.getUserTimezone();
    }

  ngOnInit() {
    this.loginUser = this.auth.getUserId()
    this.addonFeature = this.auth.getAddonFeatureLists();
    this.allowAssignTask = this.perm.capable('allow assign task', 'create');
    this.update = this.perm.capable('tasks', 'update');
    this.remove = this.perm.capable('tasks', 'remove');
    this.create_so=this.perm.capable('sales_order', 'create');
    this.read_Jo=this.perm.capable('job_order', 'read');
    this.read_Customer=this.perm.capable('customers', 'read');
    this.dateFormat = localStorage.getItem('date_format');
    this.allPriorities = this.taskService.getAllTasksPriorities();
    this.siteRead = this.perm.capable('site', 'read');
    this.projectRead = this.perm.capable('project', 'read');

    
    // this.route.queryParams.subscribe(params => {
    //   this.taskId = params['id'];

      this.loadTask(this.taskId);
   // });

    this.userService.getUserById(this.auth.getUserId()).subscribe((res: any) => {
      if(res){
        if(res.imageUrl && res.imageUrl !== ''){
          this.photoUrl = res.imageUrl;
        } else {
          this.photoUrl = 'assets/svg/Avatar.svg';
        }
      }
    });
    this.addonChecklist = this.auth.getAddonFeatureLists().addonChecklist;
  }
  /********************************** Click Cell Event *****************************/
 editTask(e:any,data: any) {
  if (e === 'edit') {
    data.mode= 'edit'
    data.showClose= 'no'
    this.openModal('addEditTask', data)

  }
  else if (e === 'delete') {
   this.validateDeleteTask(data)    

  }
 }

  ngOnDestroy(){
    this.refresher.unsubscribe();
    super.ngOnDestroy()
  }

  viewStockCheck() 
  {
    this.router.navigate(['dashboard/asset/stockCheck'], { queryParams: { id : this.task.stockCheck.id}});
  }

  ngAfterViewChecked(){
    
    //console.log(this.attachmentsArray.length)
    if(this.attachmentsArray.length != 0){
      this.bodyHeight =  window.innerHeight - this.topElement.nativeElement.offsetHeight - 392
    }else{
      this.bodyHeight = window.innerHeight - this.topElement.nativeElement.offsetHeight - 282;
    }
    this.cdr.detectChanges();
    
  }


  addNote(val: any){
    var attachmentsArray: any[] = [];
    if((val && val.length) || this.attachmentsArray.length){
      this.submitTouched = true;
      if(this.attachmentsArray.length){
        this.attachmentsArray.forEach((a)=> {
          var attachment =
          {
            attachmentUrl : a.attachmentUrl,
            fileName: a.fileName,
            contentContentType : a.contentContentType,
            icon: this.attachmentService.getFileicon(a.contentContentType)
          }
          attachmentsArray.push(attachment);
        });
      }

      var formData = {
        note: val,
        task: {
          id: this.task.id
        },
        owner : {
          id : this.auth.getUserId()
        },
        attachments: attachmentsArray
      }
      this.taskService.createTaskNotes(formData).subscribe((res:any)=>{
        //console.log("Response ", res);
        if(res.status === 200 || res.status === 201){
          this.addNotes.note = '';
          this.attachmentsArray = [];
          this.errorUtil.setErrorMessage(200, this.translate?.note.label.singular + " " + this.translate?.general.messages.savedSuccessfully ,  null, 'success',1000);
          this.submitTouched = false;
        }
      },
      (err) => {
        this.spinner.hide()
       const title = this.errorUtil.processErrorTitle(err.error,this.translate)
       this.errorUtil.setErrorMessage(err.status, null ,  title, 'error',3000);
       this.submitTouched = false;
    });
    }
    console.log(attachmentsArray, 'HEHEHE')
  }


  loadTask(id: any){
    this.spinner.show();
    let tntId =this.auth.getTenantId();
    this.taskService.getTaskById(id).subscribe((task:any)=>{
      if(task.status == 200){
        task = task.body;
        task.assignedTo = task.assignedTo ? task.assignedTo : {};
        this.customerId = task.customer ?  task.customer.id : null;
        task.assignedTo.photoUrl = environment.base_url + '/attachments/download/?parentId='+task.assignedTo?.id+'&parentType=USER_PROFILE&tenantId='+tntId;
        this.task = task;
        this.selectedPriority = this.allPriorities.find((prio: any) => prio.value == task.priority);
        // console.log(this.selectedPriority);
        this.userMatchForTask = _.find(this.task.assignedUsersList, (o) => {
          return o.assignedTo.id == this.auth.getUserId();
        })
        if(this.task.taskStatus){
          this.taskStatus = this.task.taskStatus;
        } else if(this.task.taskStatus ==  null){
          this.taskStatus = 'OPEN';
        }
        this.difference = this.utilService.durationFormatter(this.task.startDateTime, this.task.endDateTime);
        this.userIds=[]
        this.task.assignedUsersList.forEach((element: any) => {
          this.userIds.push(element.assignedTo.id)
        });

      if(this.task.team != null){
        this.task.team.teamUserVms.forEach((element: any) => {
          
          if(this.auth.getUserId() == element.userId && element.poistion== 'MANAGER'){
            console.log(this.auth.getUserId()," == ",element.userId," && ",element.poistion)
            this.buttonActive=true
          }
        });
      }
        

        if(this.task.taskOwner?.assignedStatus == 'ACCEPTED' || this.task.assignedBy?.id == this.auth.getUserId()){
         console.log("con 1")
          this.buttonStatus=true
        }else if(this.buttonActive){
          console.log("con 2")
          this.buttonStatus=true
        }else{
          console.log("con 3")
          this.buttonStatus=false
        }

        if(this.task.taskOwner?.assignedTo?.id != this.auth.getUserId() && this.task.assignedBy?.id != this.auth.getUserId()){
          //this.addMemberButton=true
          // console.log(this.task.taskOwner?.assignedTo?.id,"====",this.auth.getUserId(),"====",this.task.assignedBy?.id,"=====",this.auth.getUserId())
          if(this.buttonActive){
            this.addMemberButton=false
            console.log("buttonActive")
            }else{
              this.addMemberButton=true
            }
          }else{
            this.addMemberButton=false
          }


        if(this.auth.getUserId() == this.task.assignedBy?.id){
          this.requestAssistanceUser.phone = this.task.assignedTo.phone;
          this.requestAssistanceUser.email = this.task.assignedTo.email;
          this.requestAssistanceUser.id = this.task.assignedTo.id;
        } else {
          this.requestAssistanceUser.phone = this.task.assignedBy?.phone;
          this.requestAssistanceUser.email = this.task.assignedBy?.email;
          this.requestAssistanceUser.id = this.task.assignedBy?.id;
        }
        // console.log(this.userIds)
        if(this.task.assignedTo?.id)
          this.taskService.getJourneyByTask(id, this.task.assignedTo?.id,this.userIds).subscribe((res:any) => {
            this.taskJourney.totalDistanceTravelled = res.totalDistanceTravelled;
            this.taskJourney.totalTimeSpend = res.totalTimeSpend;
          },(err) => {
            this.spinner.hide()
           const title = this.errorUtil.processErrorTitle(err.error,this.translate)
           this.errorUtil.setErrorMessage(err.status, null ,  title, 'error',3000);
           this.submitTouched = false;
        });

        var permCheck = _.find(this.task.assignedUsersList, (o) => {
          return o.assignedTo.id == this.auth.getUserId();
        })

        this.spinner.hide();
        if(permCheck){
          this.assistancePerm = false;
        } else {
          this.assistancePerm = true;
        }
      }
      else{
        this.errorUtil.setErrorMessage(task.status, null , task.error.title, 'error',3000);
        this.router.navigate(['dashboard/tasks/myTasks']);
      }
      //console.log('Tasks By ID API', task);
    },(err) => {
      this.spinner.hide()
     const title = this.errorUtil.processErrorTitle(err.error,this.translate)
     this.errorUtil.setErrorMessage(err.status, null ,  title, 'error',3000);
     this.submitTouched = false;
  });

    this.taskService.getAllTaskNotesByTask(id).subscribe((taskNotes:any)=>{
      this.taskNotes = taskNotes.reverse();
      console.log(this.taskNotes)
      this.taskAttachments = [];
      for(var i=0;i<taskNotes.length;i++){
        taskNotes[i].calendarTime = moment(taskNotes[i].createdDate).calendar(null, AppComponent.calendarFormat);
        if(taskNotes[i].attachments.length){
          taskNotes[i].attachments.forEach((element: any) => {
            element.icon =  this.attachmentService.getFileicon(element.contentContentType)
            this.taskAttachments.push(element);
          });
        }
      }
      console.log('Task taskAttachments ', this.taskAttachments);
      if(taskNotes?.length != 0){
        setTimeout(() => {
          $("#taskNote").scrollTop( this.bodyHeight);
        }, 1000)
      }
    });

    this.taskService.getAllTaskActivityByTask(id).subscribe((taskActivity:any)=>{
      this.taskActivity = taskActivity;
      for(var i=0;i<taskActivity.length;i++){
        taskActivity[i].calendarTime = moment(taskActivity[i].createdDate);
        taskActivity[i].activityOwner.photoUrl = taskActivity[i].activityOwner.imageUrl;
      }
      this.processHistory(taskActivity,taskActivity);
      console.log('Task Activity By ID API', taskActivity);
    });

    this.taskService.getRequestAssistanceForTask(id).subscribe((requestAssistance : any) => {
      this.requestAssistances = requestAssistance;
      for(var i=0;i<requestAssistance.length;i++){
        requestAssistance[i].calendarTime = moment(requestAssistance[i].createdDateTime).calendar(null, AppComponent.calendarFormat);
      }
      //console.log('Task Req Assistance By ID API', requestAssistance);
    });
  }

 

  openModal(modalView : any, data: any){
    this.custellaModalService.openModal(modalView, data);
  }

  editNotes(){
    this.custellaModalService.openModal("addEditNotes",null);
  }

  reAssignTask(){
    var formData : any = {
      endDateTime: this.task.endDateTime,
      location: this.task.location,
      locationName : this.task.locationName,
      latitude: this.task.latitude,
      longitude: this.task.longitude,
      startDateTime: this.task.startDateTime,
      taskName: this.task.taskName,
      taskStatus : this.task.taskStatus,
      description : this.task.description,
      skills : this.task.skills,
      hasBuffer : this.task.hasBuffer,
      priority : this.task.priority,
      fixed : this.task.fixed,
      requiredSkill : this.task.requiredSkill,
      startBuffer : this.task.startBuffer,
      endBuffer : this.task.endBuffer,
      placeId : this.task.placeId,
      adhoc : this.task.adhoc
    }
    if(this.task.assignedTo){
      formData.assignedTo = {
        id: this.task.assignedTo.id
      }
    }
    if(this.task.id){
      formData.id = this.task.id;
    }
    if(this.task.customer && this.task.customer.id !== ''){
      formData.customer = {id: this.task.customer.id};
    }
    if(this.task.team){
      formData.team = {id : this.task.team.id}
    }
    if(this.task.jobOrder && this.task.jobOrder.id !== ''){
      formData.jobOrder = {id: this.task.jobOrder.id};
    }
    console.log('Create n Assign ', formData);
    this.openModal('assignTask', formData);
  }

  custellaOpenModal(modalView : any, data : any){
    let copy = Object.assign({}, data)
    this.custellaModalService.openModal(modalView, copy);
  }

  showDirections(item: any){
    // this.router.navigate(["/dashboard/tasks/map"], { queryParams: {teamView : false, taskId : item.id, directions : false}});

     var url: any = this.router.serializeUrl( this.router.createUrlTree(["/dashboard/tasks/map"], { queryParams: {teamView : false, taskId : item.id, directions : false}}))
          url = url.replace('%23', '#');
          window.open(url, '_blank');
  }

  showJourney(task: any) {
    this.custellaModalService.openModal("taskJourney", task);
    //this.router.navigate(["/dashboard/tasks/journeys"], {queryParams: {'taskId' : task.id, 'userId' : task.assignedTo.id}});
  }

  changeTableView(val: any){
    this.userView = val;
  
  }

  changeTaskStatus(val: any, user: any){
    this.taskStatus = val;
    console.log(this.taskStatus,"----")
    ////debugger
    this.taskService.editTaskStatus(this.task.id, this.taskStatus, user, this.task).subscribe((res:any)=>{
      //console.log("Response ", res);
      if(_.includes(res.message, 'Task updated with Id :')){
        this.errorUtil.setErrorMessage(200, this.translate?.general.messages.updatedSuccessfully ,  null, 'success',1000);
       
      }
    },
    (err) => {
      this.spinner.hide()
     const title = this.errorUtil.processErrorTitle(err.error,this.translate)
     this.errorUtil.setErrorMessage(err.status, null ,  title, 'error',3000);
     this.submitTouched = false;
  });
  }

  doCheckIn(task: any, user: any) {
    ////debugger
    if(user.assignedStatus == 'ACCEPTED'){
      ////debugger
      this.taskService.checkInTask(task.id, user.assignedTo.id).subscribe((res:any)=>{
        if(res.status === 200 || res.status === 201){
          if(_.includes(res.message, 'Task Check in with Id')){
            this.errorUtil.setErrorMessage(200, this.translate?.task.messages.checkInSuccessfully ,  null, 'success',1000);
          }
        }else{
          const title = this.errorUtil.processErrorTitle(res.error,this.translate)
          this.errorUtil.setErrorMessage(res.status, null ,  title, 'error',3000);
        }
      },
      (err) => {
        this.spinner.hide()
       const title = this.errorUtil.processErrorTitle(err.error,this.translate)
       this.errorUtil.setErrorMessage(err.status, null ,  title, 'error',3000);
       this.submitTouched = false;
    });
    } else {
      ////debugger
      this.errorUtil.setErrorMessage(404, null , this.translate?.task.messages.acceptTaskBeforeCheckIn, 'error',3000);
     
    }
  }
  doCheckOut(task: any, user: any) {
    let selectedConfig = this.configSM;
    this.modalState = {
      mode : 'taskCheckout',
      data : task,
      user : user
    };
    this.selectedComponent = ConfirmationDialogComponent;
    selectedConfig.initialState = this.modalState;
   this.modalService.show(this.selectedComponent, selectedConfig);
  }

 

  viewCustomer(id: any) {
    if(this.read_Customer)
      localStorage.setItem("activeIndexCustomerView", "0");
     // this.router.navigate(['dashboard/customers/' + id], { queryParams: { id: id}});
      var url: any = this.router.serializeUrl( this.router.createUrlTree(['dashboard/customers/view/' + id], { queryParams: { id: id}}))
          url = url.replace('%23', '#');
          window.open(url, '_blank');
  }

  viewSite(siteId: string) {
    if (siteId) {
        const url = this.router.serializeUrl(
            this.router.createUrlTree(['dashboard/sites/view/' + siteId], { queryParams: { id: siteId } })
        );
        window.open(url, '_blank');
    }
}

viewProject(projectId: string) {
    if (projectId) {
        const url = this.router.serializeUrl(
            this.router.createUrlTree(['dashboard/projects/view/' + projectId], { queryParams: { id: projectId } })
        );
        window.open(url, '_blank');
    }
}


  viewChecklist(id: any) {
    if(id){
      localStorage.setItem("activeIndex", '0');
      // this.router.navigate(['dashboard/customers/' + id], { queryParams: { id: id}});
       var url: any = this.router.serializeUrl( this.router.createUrlTree(['dashboard/checklist/view'], { queryParams: { id: id}}))
           url = url.replace('%23', '#');
           window.open(url, '_blank');
    }
    
  }


  goBack(){
    window.history.back();
  }

  public uploadFile($event: any) {
    this.spinner.show();
    this.parse($event.files);
  }

  parse(files: FileList): void {
    this.spinner.show();
    const file: File = files[0] as File;
    var self = this;
    const formData = new FormData();
    formData.append('file', file);
    this.taskService.uploadTaskAttachment(formData).subscribe((res:any)=>{
      var body = JSON.parse(res._body);
      body.fileName = file.name;
     console.log(body, "body ody")
      //console.log("File Upload CSV Res ", body);
      body.icon =  this.attachmentService.getFileicon(body.contentContentType)
      this.attachmentsArray.push(body);
      this.fileUpload.clear();
      this.spinner.hide();
      setTimeout(() => {
        var objDiv = document.getElementById("viewContent");
        
      }, 200);
    }, err => {
      this.fileUpload.clear();
      var body = JSON.parse(err._body);
      this.errorUtil.setErrorMessage(err.status, null, body.title, 'error', 3000);
      this.spinner.hide();
    });
  }
  openFileUploader(){
    // $("#fileUploader").click();
    // console.log("clicked")

    $('#btn_TaskAttachFile').click(function(event) {
      $('#fileUploader').click();
    });
  }

  deleteAttachment(attachment: any){
    this.taskService.deleteTaskAttachment(attachment.id).subscribe((resp:any) => {
      if(resp.status == 200){
        this.attachmentsArray = _.remove(this.attachmentsArray, (o:any) => {
          return o.id !== attachment.id;
        });
      }
    });
  }




  viewJobOrder(custId: any, jobId: any) {
    if(this.read_Jo)
    localStorage.setItem("activeIndexJobOrder","0");
      //this.router.navigate(['dashboard/jobOrders/view/'+ jobId], { queryParams: { customerId : custId, jobOrderId: jobId }});
      localStorage.setItem("activeIndex", '0');
      var url: any = this.router.serializeUrl(this.router.createUrlTree(['dashboard/jobOrders/view/'+ jobId], { queryParams: { customerId : custId, jobOrderId: jobId }}))
          url = url.replace('%23', '#');
          window.open(url, '_blank');

  }

  makeTaskOwner(user: any){
    this.taskService.makeOwnerForTask(this.task.id, user.assignedTo.id).subscribe((res:any) => {
      this.errorUtil.setErrorMessage(200, this.translate?.task.messages.taskOwnerChangedSuccessfully ,  null, 'success',1000);
    })
  }
  /************************************************* Remove User ******************************************/
  removeTaskUser(user: any){
    this.taskService.removerUserFromTask(this.task.id, user.assignedTo.id).subscribe((res:any) => {
      if(res.status == 200 || res.status == 201){
       /// this.modalRef.hide();
        this.errorUtil.setErrorMessage(200, this.translate?.task.messages.userRemovedFromTask  ,  null, 'success',1000);
      }else {
      // this.modalRef.hide();
      this.errorUtil.setErrorMessage(400, null ,  res.error.title, 'error',3000);
      }
    },
    (err) => {
     // this.spinner.hide()
     const title = this.errorUtil.processErrorTitle(err.error,this.translate)
     this.errorUtil.setErrorMessage(err.status, null ,  title, 'error',3000);
     //this.submitTouched = false;
  });
  }
/************************************************* Delete Task ******************************************/
  validateDeleteTask(data: any){

    console.log(data.isTaskJourneyStatusActive)
    if(data.isTaskJourneyStatusActive){
      this.errorUtil.setErrorMessage(404, null ,  this.translate?.task.messages.taskOnJourney , 'error',3000);
    }else{
      data.deleteHeader = this.translate.task.label.singular 
      data.deleteType='Task'
      data.message= this.translate?.general.messages.confirmDelete+" "+data.taskName+"?" 
      console.log(data)
      this.deleteRecordService.getItems(data);  
      
    }
  }

  hazardControlClick(task: any){
    this.custellaModalService.openModal("hazardControl", task);
    // this.router.navigate(['dashboard/tasks/hazardControl'], { queryParams: { id: this.task.take5Form.id}});
   }
 
   hazardReportClick(task: any){
     this.router.navigate(['dashboard/hazardReport'], { queryParams: { id: this.task.iSeeIActReport.id}});
   }
   viewProduct(id: any) {
    localStorage.setItem("activeIndex", '0');
    //  this.router.navigate(['/dashboard/products/view/'+id], { queryParams: { id:id}});
      var url: any = this.router.serializeUrl(this.router.createUrlTree(['/dashboard/products/view/'+id], { queryParams: { id:id}}))
      url = url.replace('%23', '#');
      window.open(url, '_blank');
    }
   
    openNote(){
      this.editForm = !this.editForm;
    }

    messageUser(id: any){
    //this.router.navigate(['dashboard/messages'], { queryParams: { userId : id }});
    var url: any = this.router.serializeUrl(this.router.createUrlTree(['dashboard/messages'], { queryParams: { userId : id }}))
    url = url.replace('%23', '#');
    window.open(url, '_blank');
  }
  emailUser(email: any){
    var url: any = 'mailto:'+email+'?subject=Requesting Assistance for Task'
    window.open(url, "_blank");
  }

  validateDeleteNote(data: any){

      data.deleteType='Task Note'
      data.deleteHeader = this.translate.task.label.singular + " " + this.translate?.task.sections.sec7.singular
      data.message=this.translate?.general.messages.confirmDelete+" ?"
      console.log(data)
      this.deleteRecordService.getItems(data);  
      
    
  }

  openRightNav() {
    this.isSummeryOpen = true;
    if (this.isCusSummeryOpen) {
      this.closeCustomerRightNav();
    }
    const a = document.querySelector('.right-pane-fr-task');
    a!.classList.remove('slide-right');
    a!.classList.add('slide-left-two');
  }

  openRightNavCustomer() {
    this.getCustomerSummery()
    this.isCusSummeryOpen = true;
    if (this.isSummeryOpen) {
      this.closeRightNav();
    }
    const a = document.querySelector('.right-pane-fr-cus-summ-task');
    a!.classList.remove('slide-right');
    a!.classList.add('slide-left-two-cus-summ');
  }

  closeRightNav() {
    const a = document.querySelector('.right-pane-fr-task');

    if(a!.classList.contains('slide-left-two')) {      
      a!.classList.remove('slide-left-two');
      a!.classList.add('slide-right');
    }
    else{
      a!.classList.remove('slide-right');
      a!.classList.add('slide-left-two');
    }
    this.isSummeryOpen = false;
  }

  closeCustomerRightNav() {
    const a = document.querySelector('.right-pane-fr-cus-summ-task');

    if (a!.classList.contains('slide-left-two-cus-summ')) {
      a!.classList.remove('slide-left-two-cus-summ');
      a!.classList.add('slide-right');
    }
    else {
      a!.classList.remove('slide-right');
      a!.classList.add('slide-left-two-cus-summ');
    }
    this.isCusSummeryOpen = false;
  }

  getCustomerSummery()
  { //let date = this.util.timeFormatter(moment("00:00:00", "h:mm A").format("h:mm A"), moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD")).toISOString();
    if(this.customerSummery == null || this.customerSummery == undefined){
    this.spinner.show()
    this.push(
    this.customerService.getCusSummeryById(this.customerId).subscribe((res: any) => {
      // this.spinner.hide();
      this.customerSummery = res.body;
      this.spinner.hide()
    })
    )
  }
  }
  closeModule(){
    this.closeSlide.emit();
  }

    /******************************************** Add ALl the Subscription ******************************************/
    push(obs: any) {
      super.push(obs);
    }
 
  showViewMap(activity : any, task: any){
    if(activity.type == 'TASK_CHECKIN' || activity.type == 'TASK_CHECKOUT'){
      var data = {
        activity : activity,
        task : task
      }
      this.modalState = {
        data : data,
      };

     
      this.configSM.initialState = this.modalState;
     this.modalService.show(ViewCheckinMapComponent, this.configSM);
    }
}
private processHistory(allHistory: never[],allHistory2:any) {
  const finalActivity = [];
  console.log(allHistory,"====",allHistory2)
  if(allHistory.length !=0){
  // loop all history and only store main (unique) date into finalActivtiy main activity
  for (let doa = 0; doa < allHistory2.length; doa++) {
    const index = finalActivity.findIndex(
      (activity) =>
        moment(activity.createdDate).format("MMM d YYYY") ==
        moment(allHistory2[doa].createdDate).format("MMM d YYYY")
    );

    if (index === -1) {
      // the main
      const activity = {
        createdDate: allHistory2[doa].createdDate,
        history: [] ,
      };

      finalActivity.push(activity);
    }
  }
  console.log(finalActivity)
  // loop again activity
  for (let doa = 0; doa < allHistory2.length; doa++) {
    let currDate = moment(allHistory2[doa].createdDate).format("DD/MM/YYYY");
    var data=[]
    // loop again final activity
    for (let fa = 0; fa < finalActivity.length; fa++) {
      // see and match dates
      const previousDate = moment(finalActivity[fa].createdDate).format(
        "DD/MM/YYYY"
      );
      
      var starDate = moment(currDate).startOf('day');
      var endDate  = moment(previousDate).startOf('day');

      var isSame = moment(previousDate).isSame(currDate);
     
      let date1 = new Date(currDate).getTime();
        let date2 = new Date(previousDate).getTime();

        if (date1 < date2) {
          console.log(`${currDate} is less than ${previousDate}`);
        } else if (date1 > date2) {
          console.log(`${currDate} is greater than ${previousDate}`);
        } else {
          console.log(`Both dates are equal`);
          finalActivity[fa].history.push(allHistory[doa]);
        }
        
      // if (starDate == endDate) {
      //   console.log(starDate,"===",endDate)
      //   finalActivity[fa].history.push(allHistory[doa]);
        
      // }

  
    }
  }
  console.log(finalActivity)

  // then sort
  this.timeline = finalActivity.sort((a, b) => {
    return new Date(a.createdDate).getTime() - new Date(b.createdDate as string).getTime();
  });
  console.log(this.timeline)
}
}

getLanguageData() {
  this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
        this.taskStatusList =[  { label: this.translate?.general.status.open.singular, value: 'OPEN' },
          { label: this.translate?.general.status.pending.singular, value: 'PENDING' },
          { label: this.translate?.general.status.inProgress.singular,  value: 'IN_PROGRESS' },
          { label: this.translate?.general.status.completed.singular,  value: 'COMPLETED' },
          { label: this.translate?.general.status.incomplete.singular,  value: 'IN_COMPLETED' }
        ]
      }
    }))
  console.log("translate",this.translate)
}

}

