import { of as observableOf, Observable, throwError } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { JobOrdersService } from 'src/app/_services/job-orders.service';
import { ChecklistService } from 'src/app/_services/checklist.service';
import { PermissionService } from 'src/app/auth/permission.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  Http,
  RequestOptions,
  Headers,
  ResponseContentType,
} from "@angular/http";
@Injectable({
  providedIn: 'root'
})
export class AiService {
  refreshList$: EventEmitter<any>;
  refreshStatus$: EventEmitter<any>;
  refreshGoogleCalender$: EventEmitter<any>;
  refreshMicrosoftCalender$: EventEmitter<any>;
  refreshCalendlyCalender$: EventEmitter<any>;

  private ENDPOINT_AI_FS = "/ai-customer-knowledge-base"; // Not In Use(Checked On 1/oct/2024)

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private httpOnly: Http,
    private perm: PermissionService,
    private authService: AuthenticationService,
    private jobOrderService: JobOrdersService,
    private checklistService: ChecklistService
  ) {
    this.refreshList$ = new EventEmitter();
    this.refreshStatus$ = new EventEmitter();
    this.refreshGoogleCalender$ = new EventEmitter();
    this.refreshMicrosoftCalender$ = new EventEmitter();
    this.refreshCalendlyCalender$ = new EventEmitter();
  }

  // **************************************  Method to get job order ID *************************************/
  getJobOrderId(jobOrderId: string): Observable<any> {
    return this.jobOrderService.getJobOrderProductsById(jobOrderId);
  }
 
 

  // **************************************  Get AI Summary *************************************/
  public getConnectWithAI(fromDateTime: any, toDateTime: any) {
    let params = new HttpParams()
      .set("fromDateTime", fromDateTime)
      .set("toDateTime", toDateTime)
      .set("outputLang", 'en')
      .set("useTrial", 'True');

    return this.http.get(environment.ai_base_url + '/ai-daily-rundown/planner-daily-rundown', {
      observe: "response", params: params
    }).pipe(
      map((resp: any) => resp),
      catchError(error => observableOf(error))
    );
  }

  // **************************************  Get Predicted AI Completion Time *************************************/
  public getPredictedCompletionTime(tenantId: string, jobOrderId: string): Observable<any> {
    const params = new HttpParams()
      .set("TenantId", tenantId)
      .set("JobOrderId", jobOrderId);

    console.log('Requesting API with params:', params);

    return this.http.get(environment.ai_base_url + '/ai-predict-completion-time/invocations', {
      observe: "response", params: params
    }).pipe(
      map((response: any) => response),
      catchError(error => observableOf(error))
    );
  }

  // **************************************  Get AI Job Order Summary *************************************/
  getAIJobOrderSummary(jobOrderId: string, lang: any): Observable<any> {
    const params = new HttpParams()
      .set("jobOrderId", jobOrderId)
      .set("outputLang", lang)
      .set("useTrial", 'True');

    

    return this.http.get(environment.ai_base_url + '/ai-daily-rundown/planner-post-job-summary', {
      params: params
    }).pipe(
      map((response: any) => response),
      catchError(error => {
        console.error('Error fetching job order summary:', error);
        return [];
      })
    );
  }

  // **************************************  Generate AI Checklist *************************************/
  public GenerateAiChecklist(checklistData: any): Observable<any> {
    const url = environment.ai_base_url + '/ai-generate-checklists/generate-checklist';
    
    const formData = new FormData();
    formData.append('userId', checklistData.userId);
    formData.append('checklistDescription', checklistData.checklistDescription);
    formData.append('outputLang', 'en');
  
    if (checklistData.referenceFile) {
      formData.append('file', checklistData.referenceFile, checklistData.referenceFile.name);
    }
  
    const headers = new HttpHeaders();
    headers.delete('Content-Type');
   headers.append('origin', 'http://localhost:4200'); 
    return this.http.post(url, formData, {
      headers: headers,
      observe: 'response'
    }).pipe(
      map((resp: any) => resp),
      catchError((error) => throwError(error))
    );
  }

  //**************************************  Post AI Checklist Questions *************************************/
  public postChecklistQuestions(body: any) {
  
    return this.http.post(environment.ms_base_url + '/v1/tmpl-checklist-questions/bulk', body, { observe: "response" }).pipe(
      map((resp: any) => {
       return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );


  }

  //**************************************  Get AI Organization Details *************************************/
  getAIOrganizationFeatures(tenantId: any): Observable<any> {
   
    return this.http.get(environment.base_url + '/get-org-ai-features/', { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      //console.log(error);
      return throwError(error);
    }),);
  }

  //**************************************  Update AI Organization Features *************************************/
  updateAIOrganizationFeatures(body: any): Observable<any> {
    {
      return this.http.put( environment.base_url+"/update-org-ai-features", body, { observe: 'response' }).pipe(map( (resp:any) => {
        console.log(resp)
        return resp;
      }
    ),catchError( error => {
          return throwError( error );
      }),);
    }
  }
//**************************************  Upload Finley Suggestion Doc *************************************/
  public uploadAttachmentAiDoc(formData:any){
    const headers = new Headers({});
    headers.append("Authorization", "Bearer " + this.auth.getToken());
    let options = new RequestOptions({ headers: headers });
    return this.httpOnly.post(environment.ai_base_url +this.ENDPOINT_AI_FS+ '/upload',formData,  options).pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  //**************************************  Get List of uploded Documents *************************************/
  getListOfUploadedDocuments(tenantId: any): Observable<any> {
   
    return this.http.get(environment.ai_base_url + this.ENDPOINT_AI_FS+'/list-knowledge-base?tenantId='+tenantId, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      //console.log(error);
      return throwError(error);
    }),);
  }
  //**************************************  Delete Attchments *************************************/
  public deleteAIAttchments(tenantId: any ,fileName: any) {
    var params = new HttpParams()
    .set("fileName", fileName)
    .set("tenantId", tenantId)

    return this.http .delete(environment.ai_base_url +this.ENDPOINT_AI_FS+ "/delete", {  observe: "response",params:params }) .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  //**************************************  Get JO => Notes *************************************/
  getSmartSuggestForJo(body:any): Observable<any> {
    
    console.log('Requesting API with params:', body);
    return this.http.post(environment.ai_base_url + this.ENDPOINT_AI_FS+'/generate-resolution-steps',body, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      //console.log(error);
      return throwError(error);
    }),);
  }

   //**************************************  Get List of uploded Documents *************************************/
   getProductsBaseOnJo(tenantId:any, jobOrderId: any): Observable<any> {
    var params = new HttpParams()
    .set("jobOrderId", jobOrderId)
    .set("tenantId", tenantId)
    
    return this.http.get(environment.ai_base_url + this.ENDPOINT_AI_FS+'/get-spare-parts', { observe: 'response',params:params }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      //console.log(error);
      return throwError(error);
    }),);
  }

}
