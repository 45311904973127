<ngx-spinner class="unlock-Spinner" bdColor = "rgb(255 255 255 / 76%)" size = "medium" color = "#6366f1" type = "ball-clip-rotate-multiple" [fullScreen] = "true"><p style="color: white" > </p></ngx-spinner>
<!-- <div class="show-bot" [hidden]="!apidata">
    <button class="toggle-bot">
        <i class="fa-solid fa-message-bot"></i>
        <i class="fa-solid fa-chevron-down"></i>

    </button>

    <div class="chatbot">
        <header>
            <i class="fa-solid fa-sparkles"></i>
            <h3>FieldExpert Insights</h3>
        </header>
        <ul class="chatbox" #chatbox>
            <li class="chat incoming">
                <i class="fa-solid fa-message-bot"></i>
                <div class="chat-message" [innerHTML]="apidata"></div>
            </li>
            <li class="chat outgoing">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li>
        </ul>

        <div class="chat-input">
            <textarea class="chat-textarea" pInputTextarea placeholder="Type a reply..."></textarea>
            <span>
                <i class="fa-solid fa-paper-plane-top"></i>
            </span>
        </div>
    </div>
</div> -->
<div class="layout-config" [ngClass]="{'layout-config-active': active, 'expanded': isExpanded}">
    <div class="layout-config-content-wrapper">
        <a tabindex="0" class="layout-config-button" (click)="toggleConfigurator($event)">
            <i class="fa-duotone fa-solid fa-dolphin"></i>
        </a>
        <!-- Header Section with Icons -->
        <div class="layout-config-header"> 
            <div>
                <i class="fa-duotone fa-solid fa-dolphin fa-xl"></i>
            </div>
            <div class="header-icons">
                <a tabindex="0" class="layout-config-maxmin" (click)="toggleChatboxWidth()">
                    <i [ngClass]="isExpanded ? 'fa-regular fa-arrow-down-left-and-arrow-up-right-to-center' : 'fa-regular fa-arrow-up-right-and-arrow-down-left-from-center'"></i>
                </a>
                <a tabindex="0" class="layout-config-setup">
                    <i class="pi pi-cog"></i>
                </a>
                <a tabindex="0" class="layout-config-close" (click)="hideConfigurator($event)">
                    <i class="pi pi-times"></i>
                </a>
            </div>
        </div>
        <div class="layout-config-content"> 
            <!-- Chatbot Section -->
            <div class="chatbot-container">
                <!-- Spinner shown until data is loaded -->
                <div *ngIf="!isAiDataLoaded" class="chatbot-spinner">
                    <p-spinner></p-spinner>
                </div>

                <!-- Chatbox content shown only after data is loaded -->
                <ul class="chatbox" #chatbox *ngIf="isAiDataLoaded">
                    <li class="chat incoming"> 
                        <div class="chat-message" [innerHTML]="apidata"></div>
                    </li>
                </ul>
            </div>

            <!-- Input area hidden when spinner is active -->
            <!-- <div class="chat-input" *ngIf="isAiDataLoaded">
                <textarea class="chat-textarea" pInputTextarea placeholder="Type a reply..."></textarea>
                <span (click)="handleChat()">
                    <i class="fa-solid fa-paper-plane-top"></i>
                </span>
            </div> -->
        </div>
    </div>
</div>
