<div class="custella-modal">
    <div class="custella-modal-header align-items-center">
        <span class="text-14 font-medium ms-2" id="title_CheckProduct"> {{ translate?.general.buttons.add.singular  }}&nbsp;{{translate?.product.label.plural}} </span>
        <i class="pi pi-times" (click)="modalRef.hide()" [pTooltip]="translate?.general.buttons.close.singular" id="btn_CheckProductClose"></i>
    </div>

    <div class="">
        <!-- Container -->
        <div class="custella-form-container position-relative d-block w-100 rounded-2 m-0">
                <form [formGroup]="addProductForm">
                    <div class="custella-form-container-content p-0 mt-0">
                        <div class="p-datatable p-component px-3">
                            <div class="p-datatable-wrapper">
                                <table role="table" class="p-datatable-table w-100">
                                    <thead class="p-datatable-thead">
                                        <tr>
                                            <th class="col-9 mandatory">{{translate?.product.label.singular}}</th>
                                            <th class="col-2 mandatory" >{{translate?.setup.modules.preventiveParts.fields.quantity.singular}}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody formArrayName="productRows" class="p-element p-datatable-tbody">
                                        <tr class="align-baseline" *ngFor="let row of productRows.controls; let i = index" [formGroupName]="i">
                                            <td class="col-9">
                                                <div class="p-inputgroup search">
                                                   {{productRows.at(index)?.value.product.id}}
                                                    <span class="p-input-icon-right w-100">
                                                        <p-autoComplete styleClass="w-100" 
                                                        
                                                        (onSelect)="selectValueProduct($event,i)"
                                                        field="name"
                                                        formControlName="product"
                                                        [suggestions]="products"
                                                        (onKeyUp)="getProductIdsFirstWay($event, i)">
                                                        </p-autoComplete>
                                                        
                                                        <i *ngIf="productRows.at(i).value.product" (click)="clearProductValue(i)" id="btn_InvProdClear" class="p-autocomplete-clear-icon pi pi-times"></i>
                                                    </span>
                                                    <button type="button" pButton icon="fa-regular fa-magnifying-glass" [pTooltip]="translate?.product.tooltips.productLookup.singular" tooltipPosition="left" id="btn_InvProdSearch" (click)="showProductTable(i)"></button>
                                                </div>
                                                <footer *ngIf="displayProductTable && selectedIndex == i" class="custella-form-container-footer hidePopup">
                                                    <p-dialog [header]="translate?.product.sections.sec4.singular" id="title_ProdDet" [(visible)]="displayProductTable" showEffect="fade" [style]="{width: '70vw'}" [modal]="true" [draggable]="false">
                                                        <app-custella-products (productSelector)="gotSelectedProduct($event, i)"
                                                        (closeProductView)="closeProductTable()"></app-custella-products>
                                                    </p-dialog>
                                                </footer>
                                                <div *ngIf="productRows.at(i).get('product')?.touched && productRows.at(i).get('product')?.invalid">
                                                    <span class="text-10 p-error" 
                                                          *ngIf="productRows.at(i).get('product')?.errors?.required" 
                                                          id="label_InvProdReqField">
                                                        {{ translate?.general.validationFE.requiredField }}
                                                    </span>
                                                </div>
                                            </td>
                                            <td class="col-2">
                                                <!-- <input  autocomplete="off" type="number" class="form-control" formControlName="quantity" min="1"> -->
                                                <input type="number" formControlName="quantity" appNumberOnly pInputText>
                                                <div *ngIf="productRows.at(i).get('quantity')?.touched && productRows.at(i).get('quantity')?.invalid">
                                                    <span class="text-10 p-error" 
                                                          *ngIf="productRows.at(i).get('quantity')?.errors?.required" 
                                                          id="label_InvProdReqField">
                                                        {{ translate?.general.validationFE.requiredField }}
                                                    </span>
                                                    <span class="text-10 p-error" 
                                                          *ngIf="!productRows.at(i).get('quantity')?.errors?.required" 
                                                          id="label_InvProdReqField">
                                                        {{ translate?.general.validationFE.invalidQuantity }}
                                                    </span>
                                                    
                                                </div>
                                            </td>
                                            <td class="" >
                                                <button *ngIf="productRows.length > 1" style="border: none;" (click)="deleteRow(i)" id="btn_InvBinDelRow">
                                                    <img alt="logo" src="../../../../../assets/svg/table/exit_button.svg" />
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="col-9" pInputText autocomplete="off" ><input type="number" class="form-control" disabled></td>
                                            <td class="col-2"scope="row"pInputText autocomplete="off" ><input type="number" class="form-control" disabled></td>
                                            <td class="">
                                                <button style="border: none;" (click)="addRow()" >
                                                    <img alt="logo" src="../../../../../assets/svg/table/add_button.svg" />
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>
           
           
            <div class="custella-form-container-footer mb-0">
                <div class="flex-align-center justify-content-end">
                    <button class="custella-btn-white text-12 ms-2" id="btn_CheckProdCancel" (click)="modalRef.hide()">{{translate?.general.buttons.cancel.singular}}</button>
                    <p-button [label]="buttonLabel" styleClass="ms-2 h-32" id="btn_CheckProdSubmit"  (onClick)="onSubmit()"></p-button>
                </div>
            </div>
        </div>
    </div>
</div>