import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { AuthenticationService } from './auth/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'template';
  menuMode = 'static';
  lightMenu = false;
  inputStyle: any = 'outlined';
  ripple!: boolean;
  isUserLoggedIn = false;
  addonFeature: any;

  /*=====================Validation Variables Start =========================== */
  public static emailPattern = "^[A-Za-z0-9._%+-]+@[a-z0-9.?-]+[\.]+[a-z]{2,4}$";
  public static validQuantityPattern = "^(0|[1-9][0-9]*)$";
  public static validPricePattern = "^(0|[1-9][0-9]*)+(\.[0-9][0-9]?)?$";
  public static phonePattern = "^\\+?[0-9]{8,14}$";
  public static passwordPattern = "^[A-Za-z0-9_@./#&+-]{8,}$";
  public static strongPasswordRegx = "^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\\$%&\\*()\-+=])(?=.{8,}).*$";
  public static fiterConditionPattern = "(\\(\\d\\s(AND|OR|or|and)\\s\\d\\))?\\s?(OR|AND|or|and)?\\s?(\\(\\d\\s(AND|OR|or|and)\\s\\d\\))?\\s?(OR|AND|or|and)?\\s?(\\(\\d\\s(AND|OR|or|and)\\s\\d\\))";
  public static taxConditionPattern = "^[0-9][0-9]?$|^100$";
  public static alphabetPattern = "^[a-zA-Z.\\s*]+$";
  public static numberPattern = "^[0-9]*$";
  public static fourNumberPattern = "[1-9][0-9]{3}";
  public static regex = /^\d+(\.\d{1,2})?$/i;
  public static calendarFormat = {
    lastDay: '[Yesterday]',
    sameDay: '[]',
    nextDay: '[Tomorrow]',
    lastWeek: 'DD/MM/YYYY',
    nextWeek: 'dddd',
    sameElse: 'L'
  };
  /*=====================Validation Variables Ends =========================== */

  constructor(
    private primengConfig: PrimeNGConfig,
    private router: Router,
    private auth: AuthenticationService
  ) {
    this.checkLoginStatus();
    
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.checkLoginStatus();
        
        // Redirect logic for login and dashboard
        if ((event.url === '/' || event.url.indexOf('login') >= 0) && this.auth.getToken()) {
          if (window.innerWidth < 720) {
            this.router.navigate(['/mobile']);
          } else {
            this.router.navigate(['/dashboard/home']);
          }
        }
      }
    });
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.ripple = true;
  }

  // Method to check and update login status
  checkLoginStatus() {
    this.isUserLoggedIn = this.auth.isAuthenticated();
  }
}