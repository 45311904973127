import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AgGridModule } from "ag-grid-angular";
import { CustCustomerComponent } from "./cust-customer/cust-customer.component";
import { CommanDeleteComponent } from "./comman-delete/comman-delete.component";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { InputTextModule } from "primeng/inputtext";
import { CustellaAssetsComponent } from "./custella-assets/custella-assets.component";
import { AssetTableComponent } from "./custella-assets/asset-table/asset-table.component";
import { CustellaProductsComponent } from "./custella-products/custella-products.component";
import { ProductTableComponent } from "./custella-products/product-table/product-table.component";
import { CustellaCustomersComponent } from "./custella-customers/custella-customers.component";
import { CustomerTableComponent } from "src/app/dashboard/shared/customer-table/customer-table.component";
import { SearchGoogleMapComponent } from "./search-google-map/search-google-map.component";
import { GMapModule } from "primeng/gmap";
import { CustellaTicketsComponent } from "./custella-tickets/custella-tickets.component";
import { TicketTableComponent } from "./custella-tickets/ticket-table/ticket-table.component";
import { CustellaSalesOrdersComponent } from "./custella-sales-orders/custella-sales-orders.component";
import { SoTableComponent } from "./custella-sales-orders/so-table/so-table.component";
import { CustellaJobOrdersComponent } from "./custella-job-orders/custella-job-orders.component";
import { JoTableComponent } from "./custella-job-orders/jo-table/jo-table.component";
import { CustellaTransferFromToBinComponent } from "./custella-transfer-from-to-bin/custella-transfer-from-to-bin.component";
import { AccordionModule } from "primeng/accordion";
import { ViewGoogleMapComponent } from "./view-google-map/view-google-map.component";
import { ParentModalComponent } from './parent-modal/parent-modal.component';
import { ConfirmActionComponent } from './confirm-action/confirm-action.component';
import { ViewMapPinComponent } from './view-map-pin/view-map-pin.component';
import { DownloadBarcodeComponent } from './download-barcode/download-barcode.component';
import { CustellaAutocompleteComponent } from "./custella-autocomplete/custella-autocomplete.component";
import { AutoCompleteModule } from "primeng/autocomplete";
import { DialogModule } from 'primeng/dialog';
import { BarcodeGeneratorComponent } from "./barcode-generator/barcode-generator.component";
import { QrGeneratorComponent } from "./qr-generator/qr-generator.component";
import { SearchLocationBinComponent } from "./search-location-bin/search-location-bin.component";
import { SharedModule } from "../shared.module";
import { TooltipModule } from "primeng/tooltip";
import { UserSelectionComponent } from './custella-user-selection/user-selection/user-selection.component';
import { AvatarModule } from "primeng/avatar";
import { CustellaProjectsComponent } from './custella-projects/custella-projects.component';
import { ProjectsTableComponent } from './custella-projects/projects-table/projects-table.component';
import { CustellaSitesComponent } from './custella-sites/custella-sites.component';
import { CustellaSitesTableComponent } from './custella-sites/custella-sites-table/custella-sites-table.component';
import { SearchCustomerContactComponent } from "./search-customer-contact/search-customer-contact.component";
import { CustellaBinCombinationComponent } from "../custella-bin-combination/custella-bin-combination.component";
import { ChecklistProductComponent } from './checklist-product/checklist-product.component';
import { DropdownModule } from "primeng/dropdown";
import { CommonDialogBoxComponent } from './common-dialog-box/common-dialog-box.component';
import { CustellaChecklistComponent } from './custella-checklist/custella-checklist.component';
import { CustellaJoTemplateComponent } from './custella-jo-template/custella-jo-template.component';
import { CustellaMachinesSpecialistComponent } from './custella-machines-specialist/custella-machines-specialist.component';
import { MachinesSpecialistTableComponent } from './custella-machines-specialist/machines-specialist-table/machines-specialist-table.component';
import { InputTextareaModule } from "primeng/inputtextarea";
import { CustellaUsersComponent } from './custella-users/custella-users.component';
import { AllVisitsComponent } from "./all-visits/all-visits.component";
import { CalendarModule } from "primeng/calendar";
import { AllProductivityComponent } from "./all-productivity/all-productivity.component";
import { AllAttendanceComponent } from "./all-attendance/all-attendance.component";
import { AllOvertimeComponent } from "./all-overtime/all-overtime.component";
import { ChecklistJobOrdersComponent } from "src/app/dashboard/checklist/checklist-job-orders/checklist-job-orders.component";
import { ChecklistTasksComponent } from "src/app/dashboard/checklist/checklist-tasks/checklist-tasks.component";
import { ChecklistProductsComponent } from "src/app/dashboard/checklist/checklist-products/checklist-products.component";
import { CustellaSurveyComponent } from './custella-survey/custella-survey.component';
import { SummarySliderComponent } from './summary-slider/summary-slider.component';
import { ModalViewMapComponent } from './modal-view-map/modal-view-map.component';
import { CustomerSummerySliderComponent } from './customer-summery-slider/customer-summery-slider.component';
import { QRCodeModule } from 'angularx-qrcode';
import { UserLookupComponent } from './user-lookup/user-lookup.component';
import { ListJoTemplatesComponent } from './list-jo-templates/list-jo-templates.component';
import { ListTaskTemplatesComponent } from './list-task-templates/list-task-templates.component';
import { CustellaPriceBookComponent } from './custella-price-book/custella-price-book.component';
import { CustellaBinComponent } from './custella-bin/custella-bin.component';
import { SplitButtonModule } from "primeng/splitbutton";
import { SmartSuggestionsComponent } from './smart-suggestions/smart-suggestions.component';
import { ChipModule } from "primeng/chip";
import { CommonProductComponent } from './common-product/common-product.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AgGridModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    InputTextModule,
    GMapModule,
    AccordionModule,
    AutoCompleteModule,
    DialogModule,
    TooltipModule,
    AvatarModule,
    DropdownModule,
    InputTextareaModule,
    CalendarModule,
    QRCodeModule,
    SplitButtonModule,
    ChipModule
  ],
  declarations: [
    CustCustomerComponent,
    CommanDeleteComponent,
    CustellaAssetsComponent,
    AssetTableComponent,
    CustellaProductsComponent,
    ProductTableComponent,
    CustellaCustomersComponent,
    CustomerTableComponent,
    SearchGoogleMapComponent,
    CustellaTicketsComponent,
    TicketTableComponent,
    CustellaSalesOrdersComponent,
    SoTableComponent,
    CustellaJobOrdersComponent,
    JoTableComponent,
    CustellaTransferFromToBinComponent,
    ViewGoogleMapComponent,
    ParentModalComponent,
    ConfirmActionComponent,
    ViewMapPinComponent,
    DownloadBarcodeComponent,
    CustellaAutocompleteComponent,
    BarcodeGeneratorComponent,
    QrGeneratorComponent,
    SearchLocationBinComponent,
    UserSelectionComponent,
    CustellaProjectsComponent,
    ProjectsTableComponent,
    CustellaSitesComponent,
    CustellaSitesTableComponent,
    SearchCustomerContactComponent,
    CustellaBinCombinationComponent,
    ChecklistProductComponent,
    CustellaChecklistComponent,
    CommonDialogBoxComponent,
    CustellaJoTemplateComponent,
    CustellaMachinesSpecialistComponent,
    MachinesSpecialistTableComponent,
    CustellaUsersComponent,
    AllVisitsComponent,
    AllProductivityComponent,
    AllAttendanceComponent,
    AllOvertimeComponent,
    ChecklistJobOrdersComponent,
    ChecklistTasksComponent,
    ChecklistProductsComponent,
    CustellaSurveyComponent,
    ModalViewMapComponent,
    UserLookupComponent,
    ListJoTemplatesComponent,
    ListTaskTemplatesComponent,
    CustellaPriceBookComponent,
    CustellaBinComponent,
    SmartSuggestionsComponent,
    CommonProductComponent,
  ],
  exports: [
      CustCustomerComponent,
      CommanDeleteComponent,
      CustellaAssetsComponent,
      AssetTableComponent,
      CustellaProductsComponent,
      ProductTableComponent,
      CustellaCustomersComponent,
      CustomerTableComponent,
      SearchGoogleMapComponent,
      CustellaTicketsComponent,
      TicketTableComponent,
      CustellaSalesOrdersComponent,
      SoTableComponent,
      CustellaJobOrdersComponent,
      JoTableComponent,
      CustellaTransferFromToBinComponent,
      ViewGoogleMapComponent,
      ConfirmActionComponent,
      ViewMapPinComponent,
      DownloadBarcodeComponent,
      CustellaAutocompleteComponent,
      BarcodeGeneratorComponent,
      QrGeneratorComponent,
      SearchLocationBinComponent,
      UserSelectionComponent,
      CustellaProjectsComponent,
      CustellaSitesComponent,
      SearchCustomerContactComponent,
      CustellaBinCombinationComponent,
      CommonDialogBoxComponent,
      CustellaChecklistComponent,
      CustellaJoTemplateComponent,
      MachinesSpecialistTableComponent,
      CustellaMachinesSpecialistComponent,
      CustellaUsersComponent,
      AllVisitsComponent,
      AllProductivityComponent,
      AllAttendanceComponent,
      AllOvertimeComponent,
      ChecklistJobOrdersComponent,
      ChecklistTasksComponent,
      ChecklistProductsComponent,
      CustellaSurveyComponent,
      ModalViewMapComponent,
      UserLookupComponent,
      ListJoTemplatesComponent,
      ListTaskTemplatesComponent,
      CustellaPriceBookComponent,
      CustellaBinComponent,
      SplitButtonModule
    ],
  providers: [
    CustCustomerComponent,
    CommanDeleteComponent,
    CustellaAssetsComponent,
    AssetTableComponent,
    CustellaProductsComponent,
    ProductTableComponent,
    SearchGoogleMapComponent,
    CustellaTicketsComponent,
    TicketTableComponent,
    CustellaSalesOrdersComponent,
    SoTableComponent,
    CustellaJobOrdersComponent,
    JoTableComponent,
    CustellaTransferFromToBinComponent,
    ViewGoogleMapComponent,
    CustellaAutocompleteComponent,
    SearchLocationBinComponent,
    TooltipModule,
    CustellaProjectsComponent,
    CustellaSitesComponent,
    SearchCustomerContactComponent,
    CustellaBinCombinationComponent,
    CustellaChecklistComponent,
    CommonDialogBoxComponent,
    CustellaJoTemplateComponent,
    MachinesSpecialistTableComponent,
    CustellaMachinesSpecialistComponent,
    CustellaUsersComponent,
    AllVisitsComponent,
    CalendarModule,
    AllProductivityComponent,
    AllAttendanceComponent,
    AllOvertimeComponent,
    ChecklistJobOrdersComponent,
    ChecklistTasksComponent,
    ChecklistProductsComponent,
    CustellaSurveyComponent,
    ListJoTemplatesComponent,
    ListTaskTemplatesComponent
  ],
})
export class CommanComponentModule {}
