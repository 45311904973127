<div class="banner m-0 px-5">
    <button class="close">
        <i class="fa-regular fa-xmark" (click)="modalRef.hide()" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left"></i>
    </button>
    <div class="left-instruction">
        <h4>{{translate?.setup.workflows.sections.sec15.singular}}</h4>
       <div class="content">
            <h6>{{translate?.setup.workflows.sections.sec16.singular}}</h6>
            <div class="steps">
                <span>1. {{translate?.setup.workflows.messages.pickWhatYoureChecking}}</span>
                <span>2. {{translate?.setup.workflows.messages.chooseHowItCompares}}</span>
                <span>3. {{translate?.setup.workflows.messages.enterTheDetailYouWant}}</span>
            </div>
       </div>
       <div class="content">
            <div class="steps-info">
                <i class="fa-solid fa-circle-info fa-lg"></i>
                <span><strong>{{translate?.setup.workflows.sections.sec17.singular}}</strong>&nbsp;&dash;&nbsp;{{translate?.setup.workflows.messages.whatYoureChecking}}</span>
                <span><strong>{{translate?.setup.workflows.sections.sec18.singular}}</strong>&nbsp;&dash;&nbsp;{{translate?.setup.workflows.messages.howItCompares}}</span>
                <span><strong>{{translate?.setup.workflows.sections.sec19.singular}}</strong>&nbsp;&dash;&nbsp;{{translate?.setup.workflows.messages.theDetailYoureLookingFor}}</span>
            </div>
       </div>
    </div>    
    <div class="right-instruction">
        <h5>{{translate?.setup.workflows.sections.sec20.singular}}</h5>
        <div class="content">
             <h6>{{translate?.setup.workflows.sections.sec21.singular}}</h6>
             <div class="steps">
                <div class="sbs">
                    <span><strong class="col-2 text-14">{{"AND"}}</strong></span>
                    <div class="explain">
                        <span>{{translate?.setup.workflows.messages.meansAllConditionsMustBeTrue}}</span>
                        <span>{{translate?.setup.workflows.messages.egBothConditionsTrue}}</span>
                    </div>
                </div>
                <div class="sbs-or">
                    <span><strong class="col-2 text-14">{{"OR"}}</strong></span>
                    <div class="explain">
                        <span>{{translate?.setup.workflows.messages.meansAllConditionsMustBeTrue}}</span>
                        <span>{{translate?.setup.workflows.messages.egEitherConditionsTrue}}</span>
                    </div>
                </div>
             </div>
        </div>
        <div class="content mb-0">
            <h6>{{translate?.setup.workflows.sections.sec22.singular}}</h6>
            <div class="steps">
                <span>{{translate?.setup.workflows.messages.youCanGroupConditions}}&nbsp;
                    <div class="see-example">{{translate?.setup.workflows.messages.seeExample}}
                        <div class="hidden-div">
                            <!-- <h6>{{translate?.setup.workflows.sections.sec22.singular}}</h6> -->
                            <div class="steps">
                                <!-- <span>{{translate?.setup.workflows.messages.youCanGroupConditions}}&nbsp;</span> -->
                                <span><strong>{{translate?.setup.workflows.messages.example}}</strong></span>
                                <span>{{translate?.setup.workflows.messages.andOrConditionExample}}</span>
                                <span>&dash;&nbsp;{{translate?.setup.workflows.messages.eitherAOrBMustBeTrue}}&colon;&nbsp;<strong>{{translate?.setup.workflows.messages.andMustBeTrue}}</strong></span>
                                <span>&dash;&nbsp;{{translate?.setup.workflows.messages.allCDEMustBeTrue}}</span>
                            </div>
                            <div class="steps">
                                <!-- <span>{{translate?.setup.workflows.messages.youCanGroupConditions}}&nbsp;</span> -->
                                <span><strong>{{translate?.setup.workflows.messages.simpleBreakdown}}</strong></span>
                                <span>&dash;&nbsp;{{translate?.setup.workflows.messages.orPartGivesFlexibility}}&nbsp;{{translate?.setup.workflows.messages.onlyOneThingNeedsToHappen}}</span>
                                <span>&dash;&nbsp;{{translate?.setup.workflows.messages.andPartIsStrict}}&nbsp;{{translate?.setup.workflows.messages.everythingInGroupMustHappen}}</span>
                            </div>
                        </div>
                    </div>
                </span>
              
            </div>
       </div>
    </div>
</div>