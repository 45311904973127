import { NgModule } from '@angular/core';
import { NavigationEnd, Router, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '../auth/auth-guard.service';
import { AssetComponent } from './asset/asset.component';
import { DashboardComponent } from './dashboard.component';
import { JobOrdersComponent } from './job-orders/job-orders.component';
import { ProductComponent } from './product/product.component';
import { TicketsComponent } from './tickets/tickets.component';
import { ViewTasksComponent } from './tasks/view-tasks/view-tasks.component';
import { AddEditPartOrderComponent } from './job-orders/add-edit-part-order/add-edit-part-order.component';
import { ViewPartOrderComponent } from './job-orders/view-part-order/view-part-order.component';
import { HelpdeskComponent } from './helpdesk/helpdesk.component';
import { CustomerComponent } from './customer/customer.component';
import { InventoryComponent } from './inventory/inventory.component';
import { SetupComponent } from './setup/setup.component';
import { ListTasksComponent } from './tasks/list-tasks/list-tasks.component';
import { SalesOrderComponent } from './sales-order/sales-order.component';
import { AddEditProductComponent } from './product/add-edit-product/add-edit-product.component';
import { TasksComponent } from './tasks/tasks.component';
import { TeamsComponent } from './teams/teams.component';
import { ViewUserComponent } from './setup/users/view-user/view-user.component';
import { HomeComponent } from './home/home.component';
import { NotFound404Component } from './not-found404/not-found404.component';
import { NoPermissionComponent } from './no-permission/no-permission.component';
import { ProjectComponent } from './project/project.component';
import { SitesComponent } from './sites/sites.component';
import { ActiveReportsComponent } from './active-reports/active-reports.component';
import { NavigationBreadCrumbService } from '../_services/navigation-bread-crumb.service';
import { ChecklistComponent } from './checklist/checklist.component';
import { ViewScheduledMaintenanceComponent } from './asset/maintenance/view-scheduled-maintenance/view-scheduled-maintenance.component';
import { AddEditScheduledMaintenanceComponent } from './asset/maintenance/add-edit-scheduled-maintenance/add-edit-scheduled-maintenance.component';
import { MaintenanceComponent } from './asset/maintenance/maintenance.component';


declare var window: Window;

// const taskRoutes: Routes = [
//   {
//     path: '',
//     redirectTo: 'all',
//     pathMatch: 'full'
//   },
//   {
//     path: 'view/:id',
//     component: ViewTasksComponent,
//     canActivate: [AuthGuardService],
//     data: { module: "tasks", "action": "read" }
//   },
// ];

const partOrderRoutes: Routes = [
  {
    path: '',
    redirectTo: 'all',
    pathMatch: 'full'
  },
  {
    path: 'createPartOrder',
    component: AddEditPartOrderComponent,
    data: {
      module: "part order", "action": "read",
      mode: 'add',
      navigation: {isAddEdit: true}
    }
  },
  {
    path: 'updatePartOrder',
    component: AddEditPartOrderComponent,
    data: {
      module: "part order", "action": "read",
      mode: 'edit',
      navigation: {isAddEdit: true}
    }
  },
  {
    path: 'view/:id',
    component: ViewPartOrderComponent,
    data: {
      module: "part order", "action": "read",
    }
  },
];
const scheduledMaintenanceRoutes: Routes = [
  {
    path: "",
    redirectTo: "list",
    pathMatch: "full",
  },
  {
    path: "list",
    canActivateChild: [AuthGuardService],
    component: MaintenanceComponent,
    data: { module: "scheduled_maintenance", action: "read", navigation: {root: true} },
  },
  {
    path: "create",
    canActivateChild: [AuthGuardService],
    component: AddEditScheduledMaintenanceComponent,
    data: {
      module: "scheduled_maintenance",
      action: "create",
      mode: "add",
      navigation: {isAddEdit: true}
    },
  },
  {
    path: "update",
    canActivateChild: [AuthGuardService],
    component: AddEditScheduledMaintenanceComponent,
    data: {
      module: "scheduled_maintenance",
      action: "update",
      mode: "edit",
      navigation: {isAddEdit: true}
    },
  },
  {
    path: "view/:id",
    canActivateChild: [AuthGuardService],
    component: ViewScheduledMaintenanceComponent,
    data: {
      module: "scheduled_maintenance",
      action: "read",
    },
  },
];

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: 'home',
        component: HomeComponent,
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
        data:{navigation: {root: true}}
      },
     
      {
        path: 'chubb',
        loadChildren: () => import('./home/chubb/chubb-dashboard/chubb-dashboard.module').then(m => m.ChubbDashboardModule)

      },
      {
        path: 'activereports',
        component: ActiveReportsComponent,
        data:{module:"reports","action":"read", navigation: {root: true}}
      },
      {
        path: 'customers',
        component: CustomerComponent,
        loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule)

      },
      {
        path: 'inventory',
        component: InventoryComponent,
        canActivateChild: [AuthGuardService],
        loadChildren: () => import('./inventory/inventory.module').then(inv => inv.InventoryModule)
      },
      {
        path: 'tickets',
        component: TicketsComponent,
        canActivateChild: [AuthGuardService],
        loadChildren: () => import('./tickets/tickets.module').then(mod => mod.TicketsModule)
      },
      
      {
        path: 'helpdesk',
        component: HelpdeskComponent,
        canActivateChild: [AuthGuardService],
        loadChildren: () => import('./helpdesk/helpdesk.module').then(mod => mod.HelpdeskModule),
        data: {navigation: {root: true}}
      },
      {
        path: 'assets',
        component: AssetComponent,
        loadChildren: () => import('./asset/asset.module').then(mod => mod.AssetModule)

      },
      {
        path: 'preventative-maintenance',
        canActivateChild: [AuthGuardService],
        children: scheduledMaintenanceRoutes
      },
      {
        path: "contracts",
        canActivateChild: [AuthGuardService],
        loadChildren: () => import('./contracts/contracts.module').then(m => m.ContractsModule)
      },
      {
        path: 'products',
        component: ProductComponent,
        canActivate: [AuthGuardService],
        data: { module: "products", "action": "read" },
        loadChildren: () => import('./product/product.module').then(m => m.ProductModule)

      },
      {
        path: 'salesOrders',
        component: SalesOrderComponent,
        canActivate: [AuthGuardService],
        data: { module: "sales_order", "action": "read" },
        loadChildren: () => import('./sales-order/sales-order.module').then(inv => inv.SalesOrderModule),
      },
      {
        path: 'jobOrders',
        component: JobOrdersComponent,
        canActivateChild: [AuthGuardService],
        loadChildren: () => import('./job-orders/job-orders.module').then(inv => inv.JobOrdersModule),
      },
     
    
      {
        path: 'tasks',
        component: TasksComponent,
        canActivateChild: [AuthGuardService],
        loadChildren: () => import('./tasks/tasks.module').then(inv => inv.TasksModule),
      },
      {
        path: 'sites',
        component: SitesComponent,
        canActivateChild: [AuthGuardService],
        loadChildren: () => import('./sites/sites.module').then(m => m.SitesModule),
      },
      {
        path: 'projects',
        component: ProjectComponent,
        canActivateChild: [AuthGuardService],
        loadChildren: () => import('./project/project.module').then(m => m.ProjectModule),
      },
      {
        path: 'checklist',
        component: ChecklistComponent,
        canActivateChild: [AuthGuardService],
        loadChildren: () => import('./checklist/checklist.module').then(inv => inv.ChecklistModule),
      },
      // {
      //   path: 'teams',
      //   canActivateChild: [AuthGuardService],
      //   loadChildren: () => import('./teams/teams.module').then(m => m.TeamsModule),
      // },
      /* Machine Specialist */
      {
        path: 'visits',
        canActivateChild: [AuthGuardService],
        loadChildren: () => import('./visits/visits.module').then(m => m.VisitsModule),
      },
      {
        path: 'productivity',
        canActivateChild: [AuthGuardService],
        loadChildren: () => import('./productivity/productivity.module').then(m => m.ProductivityModule),
      },
      {
        path: 'pmv',
        canActivateChild: [AuthGuardService],
        loadChildren: () => import('./pmv/pmv.module').then(m => m.PmvModule),
      },
      
      {
        path: 'attendance',
        canActivateChild: [AuthGuardService],
        loadChildren: () => import('./attendance/attendance.module').then(m => m.AttendanceModule),
      },
      {
        path: 'overtime',
        canActivateChild: [AuthGuardService],
        loadChildren: () => import('./overtime/overtime.module').then(m => m.OvertimeModule),
      },
      {
        path: 'machines',
        //canActivateChild: [AuthGuardService],
        loadChildren: () => import('./machines-specialist/machines-specialist.module').then(m => m.MachinesSpecialistModule),
      },
      /*  */
      {
        path: 'messages',
        canActivateChild: [AuthGuardService],
        loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule),
      },
      {
        path: "partOrder",
        children: partOrderRoutes
      },
      {
        path: 'leave',
        loadChildren: () => import('./leave/leave.module').then(inv => inv.LeaveModule),
      },
      {
        path: 'setup',
        component: SetupComponent,
        loadChildren: () => import('./setup/setup.module').then(inv => inv.SetupModule),
        canActivate: [AuthGuardService],
        data: { module: "setup", "action": "read", navigation: {root: true} }
      },
      {
        path: 'profile',
        component: ViewUserComponent,
        },
        {
          path: "error",
          component: NoPermissionComponent,
          data: {navigation: {root: true} }
        },
        {
          path: "**", pathMatch: 'full',
          component: NotFound404Component,
          data: {navigation: {root: true} }
        }
        
    ]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule {
  constructor(router: Router, private navibar: NavigationBreadCrumbService) {
    
    router.events.subscribe(event => {
      this.navibar.buildNavigation(event);
      
      if (event instanceof NavigationEnd) {
        ////console.log("Appcues change===>>>>");

      }
    });

  }


}
