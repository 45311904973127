import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {LicenseManager} from "ag-grid-enterprise";
//LicenseManager.setLicenseKey("CompanyName=CUSTELLA SDN. BHD.,LicensedApplication=Custella,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-032187,SupportServicesEnd=24_October_2023_[v2]_MTY5ODEwMjAwMDAwMA==f283b7884958c0e13552daf392a7cf97");
LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-065823}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{CUSTELLA_SDN._BHD.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Custella}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Custella}_need_to_be_licensed___{Custella}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{23_October_2025}____[v3]_[01]_MTc2MTE3NDAwMDAwMA==09462452f8e56f05f3af7426a12de16a");

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  //console.log(environment.production,"=====>>>")
if (environment.production) {
  window.console.log = function () { }; // disable any console.log debugging statements in production mode
  }