import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { GetContextMenuItemsParams, GridOptions,IServerSideGetRowsParams, MenuItemDef } from 'ag-grid-community';
import "ag-grid-enterprise"; 
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { PermissionService } from 'src/app/auth/permission.service';
import { ButtonRendererComponent } from 'src/app/renderer/button-renderer.component';
import { TableHeaderToolTipComponent } from 'src/app/shared/table-header-tool-tip/table-header-tool-tip.component';
import { ServerRequest } from 'src/app/_models/global.data.model';
import { DeleteRecordService } from 'src/app/_services/delete-record.service';
import { DynamicFormService } from 'src/app/_services/dynamic-form.service';
import { ProductService } from 'src/app/_services/product.service';
import { SalesOrdersService } from 'src/app/_services/sales-orders.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { DynamicTableColumnsService } from 'src/app/_services/dynamic-table-column.service';
import { Global } from 'src/app/_utilities/global';
var salesOrderStatus:any=[]
var dateFormateChange: any;
var router: Router

@Component({
  selector: 'app-so-table',
  templateUrl: './so-table.component.html',
  styleUrls: ['./so-table.component.scss']
})
export class SoTableComponent extends SubscriptionUtil implements OnInit, OnDestroy, OnChanges {

  @Input() displayAction:any = false
  @Input() searchValue: any = null
  @Input() translate: any ;

  @Input() filterData: any
  @Input() customerId: any = null

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any =[];
  defaultColDef: any;
  frameworkComponents: any;
  gridParams : any;
  startFrom: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions: Partial<GridOptions>;
   overlayNoRowsTemplate: string;
   
   salesOrders: any=[];
   actionData:any;
   totalRecord: any;
   currentRecordNumber: any;
   refresher!: Subscription;
   noCustomers: boolean = false;
   noCustomersIcon: boolean = false;
   dateFormat: any;
  allowViewSalesorderPrice: boolean;
  statuses: any;
  sortBy: any;
  sortOrder: any;
  loadTable: any=0;

  constructor(
    private salesOrdersService: SalesOrdersService,
    private auth: AuthenticationService,
    private dynamicFormService: DynamicFormService,
    private router : Router,
    private errorUtil: ErrorUtil,
    private deleteRecordService: DeleteRecordService,
    private perm: PermissionService,
    private dynamicTableColumnsService: DynamicTableColumnsService
  ) { 

   super();
   this.allowViewSalesorderPrice = this.perm.capable('allow view sales order price', 'create');
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    }
    
    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: 'infinite',
      sortingOrder : ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
        },
        tooltipShowDelay: 100,
      }
      // this.columnDefs =  [
      //   { headerName: 'Sales Order No.', field: 'salesOrderNumber', width: 170, cellClass:'table_default_color', headerTooltip: "#",},
      //   { headerName: 'Order Name', field: 'orderName', width: 210, headerTooltip: "#",},
      //   { headerName: 'Order Date', field: 'orderStartDate', width: 110,
      //   headerTooltip: "#",
      //     valueFormatter: function (params:any) {
      //       if(params.data &&  params.data.dueDate){
      //         var dateFormat:any = localStorage.getItem('date_format')
      //         return moment(params.value).format(dateFormat.toUpperCase());
      //       }

      //     }
      //   },
      //   { headerName: 'Status', field: 'status',width: 130, headerTooltip: "#",
      //     cellRenderer: function (params:any) {
      //       if(params.data){

      //         if(params.data.status){
      //           //var data;
      //           var soData = null;
      //             for(var i=0 ;i < salesOrderStatus.length; i++){
      //               //console.log(params.data.status," == ",salesOrderStatus[i].name)
      //                if(params.data.status == salesOrderStatus[i].name){
      //                 soData = salesOrderStatus[i]
      //                 }
      //             }
      //             console.log(soData)
      //             return '<div class="vehicel-status-ball s-draft mb-1 mr-2" style="background:' +soData.colorCode+ '"></div>' +soData.fieldLabel+ '';

      //            }

      //       }
      //     },
      //   },
      //   { headerName: 'Customer Name', field: 'customer.name',width: 170, cellClass:'table_default_color', headerTooltip: "#",},
      //   {
      //     headerName: 'Total Price', field: 'orderAmount',width: 170,hide: !this.allowViewSalesorderPrice,cellClass: function (params:any) {
      //     return ['text-1-5 py-1 text-right'];
      //   },valueFormatter: this.currencyFormatter,
      //   headerTooltip: "#",
      // }]
      
    this.defaultColDef = {
      enableRowGroup: false,//ag-Grid-Enterprise
      enablePivot: false,//ag-Grid-Enterprise
      enableValue: true,//ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent
    };
   

    
      
  }

  ngOnInit() {
    router = this.router
    if(this.displayAction){
      this.columnDefs.push(this.actionData)
    }

    this.push(
    this.salesOrdersService.getSalesOrderStatuses(true).subscribe((res:any) => {
      this.statuses = res;
      res.forEach((element:any) => {
        salesOrderStatus.push(element)
       })
    }))
    this.preInitTableColumn()
  }
  /********************************** PreInit *****************************/
 private preInitTableColumn() {
  this.overlayNoRowsTemplate = "<div class='not-found'><span>"+this.translate?.general.messages.noRecordsFound+"</span> </div>";
  this.dateFormat = localStorage.getItem("date_format");
  dateFormateChange = this.auth.getUserTimezone();

  this.actionData = { headerName: this.translate?.general.buttons.action.singular, filter: false,sortable: false, width: 150,headerClass: 'marging-auto hide-action-border', cellStyle: { "text-align": "center" }, cellRenderer: 'buttonRenderer',
        cellRendererParams: {
          onClick: this.edit.bind(this),
         permissions:{update:this.perm.capable('sales_order', 'update'),remove:this.perm.capable('sales_order', 'remove')}
        },
        headerTooltip: "#",
      }


  this.dynamicFormService.getTableColumn("sales_order").subscribe((res: any) => {
    this.sortBy = res.sortBy
    this.sortOrder = res.sortOrder
   
    var columnDefs: any = []
    res.tableColumnSetups.forEach((element: any) => {
      this.cellRenderer(element);

      columnDefs.push(element);
      this.gridOptions.api!.setColumnDefs(columnDefs);
    });
    //if (this.displayAction) {
       // this.columnDefs.push(this.actionData);
      
    //}
    this.gridOptions.api!.setColumnDefs(columnDefs);

    this.loadTable = 1;

    this.onGridReady(this.gridParams);
  });
}

getNestedValue(obj: any, path: string): any {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}

  /********************************** Render Cell *****************************/
  private cellRenderer(element: any) {
    console.log(element,"===",element.dataType)
    
    var dateFormat: any = localStorage.getItem("date_format");

    
    element.headerTooltip = "#";
    if(element.translatePath){
      element.headerName = this.getNestedValue(this.translate, element.translatePath);
     } 

    if (element.field === "salesOrderNumber") {
      element.headerComponentParams =  Global.setHeaderComponentParams(element,'Tbl_HeadSaleNo'),
      element.cellRenderer = function (params: any) {
        
        if(params.data){
          
          return  Global.setCellRenderer(params,params.data.rowId, params.data.salesOrderNumber)
        }
      };
    }else if (element.field === "orderAmount") {
      element.valueFormatter = this.currencyFormatter.bind(this)
      //   },
    }else if (element.field === "customer?.name") {
      element.headerComponentParams =  Global.setHeaderComponentParams(element,'Tbl_HeadSaleCustName'),
      element.cellRenderer = function (params: any) {
        if(params.data){
          return  Global.setCellRenderer(params,params.data.rowId2, params.data.customer?.name)
        }
      };
    }else if (element.field == "status") {
      
      element.cellRenderer = function (params: any) {
        
              if (params.data) {
                if (params.data.status) {
                  var soData = null;
                  console.log(salesOrderStatus)
                  
                  for (var i = 0; i < salesOrderStatus.length; i++) {
                    if (params.data.status == salesOrderStatus[i].name) {
                      soData = salesOrderStatus[i];
                    }
                  }
    
                  let status = "";
    
                  if (params.data && soData) {
                    status =
                      ' <span class="p-mr-2  p-tag p-component" style="background-color:' +
                      soData.colorCode +
                      "50 !important ;color:" +
                      soData.colorCode +
                      '"><span class="p-tag-value text-dark">' +
                      soData.fieldLabel +
                      "</span></span>";
                  }
    
                  return status;
                }
              }
            
      };
    }else if (element.field === "warranty") {
      element.cellRenderer = function (params: any) {
        if(params.data){
          return params.data.warranty ? params.data.warranty + " month(s)" : "0 month(s)";
        }
      };  
    }else if (element.dataType == 'date' || element.dataType == 'datetime' || element.dataType == "text" || element.dataType == 'boolean') {
      element = Global.setColumnByDataType(element,this.auth.getUserTimezone(),dateFormat)
     }
  }
  
  currencyFormatter(params: any) {
    var usdFormate = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2
    });
    return usdFormate.format(params.value);
  }
  /********************************** Click Cell Event *****************************/
  edit(e:any) {
    if (e.action === 'edit') {
     
      this.router.navigate(['dashboard/customers/update'], { queryParams: { id: e.rowData.id}});
      }
    else if (e.action === 'delete') {
      e.rowData.deleteHeader = this.translate.product.label.singular
      e.rowData.deleteType='Product'
      e.rowData.message=  this.translate?.general.messages.confimDelete+" "+e.rowData.id+" - "+e.rowData.name+"?" 
      
      console.log(e.rowData)
      this.deleteRecordService.getItems(e.rowData);   
    }
   }
    onRefreshList() {
    this.onGridReady(this.gridParams);
     
  }
/********************************** Search Data **************************/
  quickSearch() {
     this.onGridReady(this.gridParams);
               
   }
   /********************************** View Singal Record **************************/
   onGridReady(params:any) {
    //console.log(params)
    if(params != undefined){
    this.gridParams = params;

   this.gridApi = params.api;
   this.gridApi.sizeColumnsToFit();
    var dataSource = {
      getRows: (params: IServerSideGetRowsParams) => {
        if (this.loadTable === 1) { this.getServerSideData(params);}
      }
     }

    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDatasource(dataSource);
  }
}

  /********************************** Call Customer API to Display data **************************/
   getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
   // this.spinner.show()
    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);
   // //console.log('Making a server request: ', serverRequest);

   console.log(this.customerId, 'CUSTOEMR ID');

   if (!this.customerId) {
    this.gridApi.showNoRowsOverlay();
    return;
   }

   this.gridApi.hideOverlay();
   
    this.push(  
      this.salesOrdersService.getSalesOrderByCustomerV2(this.searchValue, serverRequest, this.customerId) .subscribe((data: any) => {
        console.log(data)
        var newData = data.body.data
        // newData.forEach((element: any) => {
        //     element.salesOrder.name = element.salesOrder.salesOrderNumber
        //     this.salesOrders.push(element.salesOrder);
        //   });
         
      this.salesOrders = data.body.data
      this.totalRecord = data.body.total
      this.setGridData(agGridGetRowsParams, this.salesOrders, data.body.total, data.body.status);
      //this.spinner.hide()
      },
      (err) => {
        //console.log(err,"===>>>")
        this.errorUtil.setErrorMessage(err); 
      
       })
     )
  }
  /********************************** Set Data for Grid Table **************************/
  private setGridData(agGridGetRowsParams: IServerSideGetRowsParams, resultItems: any[], totalCount: number,status:string) {
    console.log(resultItems,"===")
   
    if(status === 'NO_DATA'){
      this.noCustomers = false;
      this.noCustomersIcon = true
      this.gridApi.showNoRowsOverlay();
    }else{
      
      this.noCustomers = true;
      this.noCustomersIcon = false

      if(this.salesOrders.length === 0){
        this.gridApi.showNoRowsOverlay();
       }else{
        agGridGetRowsParams.successCallback(this.salesOrders, totalCount);
       ///CS-4758  this.gridApi.sizeColumnsToFit();
       }

    }
   
  }
/********************************** Request Parameter for api **************************/

  getRequestParams(agGridRequest: any): ServerRequest {
   
     if(agGridRequest.sortModel.length <= 0){
          this.parameters = { colId: this.sortBy, sort: this.sortOrder }
          agGridRequest.sortModel.push(this.parameters);
        }

    //console.log(agGridRequest)
    this.currentRecordNumber = agGridRequest.startRow
    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel
    }
  }
  /********************************** View Singal Record **************************/

  viewAsset(e:any) {
    console.log(e.colDef,"===",this.filterData)
    if(!this.filterData){
      if (e.colDef.field === "runningNo"  && e.colDef.cellClass !== null){
        this.router.navigate(['dashboard/asset/view'], { queryParams: { id : e.data.id,serialNo: null}});
        }
    }else{
      ////debugger
      this.salesOrdersService.setItems(e.data);    
    }
     
   }


 
  getContextMenuItems( params: GetContextMenuItemsParams ): (string | MenuItemDef)[] {
   
    var tabData:any | null = params
    
    if(tabData.column.colId == 'productNumber'){
    var result: (string | MenuItemDef)[] = [
      {
        // custom item
        name: 'Open link in new tab',
        action: () => {
          console.log(tabData)
          var url: any = router.serializeUrl( router.createUrlTree(['/dashboard/asset/view'], { queryParams : {'id' : tabData.id}}))
            url = url.replace('%23', '#');
            window.open(url, '_blank');
        },
        cssClasses: ['redFont', 'bold'],
      }];
       return result;
    }else{
      return []
    }
  }



  
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes,"====")
   //if(changes["searchValue"]!= undefined && changes["assetStatuses"]!= undefined){
    console.log("we are in")
     this.onGridReady(this.gridParams);
  //}
  if (
    changes &&
    changes.translate &&
    changes.translate.previousValue != undefined &&
    Object.keys(changes.translate.previousValue).length !== 0 &&
    changes.translate.previousValue !== changes.translate.currentValue
  ) {
    this.preInitTableColumn();
  }

  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }

 
  push(obs:any) {
    super.push(obs);
  }
}

