import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';

@Component({
  selector: 'app-how-to-email',
  templateUrl: './how-to-email.component.html',
  styleUrls: ['./how-to-email.component.scss']
})
export class HowToEmailComponent extends SubscriptionUtil implements OnInit {
  translate: any;

  constructor(
    public modalRef : BsModalRef,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getLanguageData()
  }

  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
    console.log("translate",this.translate)
    //this.mainLabel = this.translate?.site.label.plural
  }
  push(obs:any) {
    super.push(obs);
  }
}
