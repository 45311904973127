<div class="">
    <div class="custella-modal d-flex flex-column">
        <div class="custella-modal-header">
            <div class="custella-modal-header-text" id="title_SetTemplEmailHeaderFooter">
                <span>{{translate?.setup.templates.email.sections.sec0.singular}}</span>
            </div>
            <i class="pi pi-times pt-1"  [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left"  (click)="modalRef.hide()" id="btn_SetTemplEmailHeaderFooterClose"></i>
        </div>
        <div class="custella-modal-body p-0">
            <div class="custella-form-container box-none m-0">
                <div class="custella-form-container-title-two-grey fst-italic ps-4">
                    <span class="text-12 font-medium" id="subtitle_SetTemplEmailHeaderFooterDesc">{{translate?.attachments.messages.onlyAllowVisualFiles}}&nbsp;</span>
                </div>
                <div class="custella-form-container-content my-3">
                    <div class="custella-content-row">
                        <div class="custella-content-row-input col-12"> 
                            <span class="custella-content-row-input-title mandatory" id="label_SetTemplEmailHeaderFooterImage">{{translate?.setup.templates.email.fields.image.singular}}</span>
                            <!-- <span> -->
                                <p-fileUpload #fileUpload mode="basic" [chooseLabel]="translate?.general.buttons.uploadImage.singular"
                                    [chooseIcon]="'pi pi-upload'" (uploadHandler)="uploadFile($event)" [customUpload]="true" [auto]="true"
                                    [maxFileSize]="100000" [invalidFileSizeMessageSummary]="translate?.attachments.messages.invalidFileSize"
                                    [invalidFileSizeMessageDetail]="translate?.attachments.messages.allowedFileSizeKb" class="modal-upload" [accept]="'.jpeg,.jpg,.png'"
                                    [invalidFileTypeMessageDetail]="translate?.attachments.messages.onlyAllowVisualFiles" id="btn_SetTemplEmailHeaderFooterImage">
                                </p-fileUpload>
                            <!-- </span> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>  
</div>