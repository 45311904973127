<div class="banner m-0 px-5">
    <button class="close">
        <i class="fa-regular fa-xmark" (click)="modalRef.hide()" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left"></i>
    </button>
    <div class="left-instruction">
        <h4>{{translate?.setup.templates.email.sections.sec3.singular}}</h4>
       <div class="content">
            <h6>{{translate?.setup.templates.email.sections.sec2.singular}}</h6>
            <div class="steps">
                <span>1. {{translate?.setup.templates.email.messages.fillInYourEmailDetails}}</span>
                <span>2. {{translate?.setup.templates.email.messages.giveItASubject}}</span>
            </div>
       </div>
       <div class="content mb-0">
            <h6>{{translate?.setup.templates.email.sections.sec6.singular}}</h6>
            <div class="steps">
                <span>3. {{translate?.setup.templates.email.messages.typeOutYourEmailMessage}}</span>
            </div>
       </div>
    </div>    
    <div class="right-instruction">
        <h5>{{translate?.setup.templates.email.sections.sec4.singular}}</h5>
        <div class="content">
             <h6>{{translate?.setup.templates.email.sections.sec7.singular}}</h6>
             <div class="steps">
                 <span><i class="fa-regular fa-sparkles font-orange2"></i> {{translate?.setup.templates.email.messages.addAHeaderFooterImage}}</span>
             </div>
        </div>
        <div class="content mb-0">
             <h6>{{translate?.setup.templates.email.sections.sec8.singular}}</h6>
             <div class="steps">
                 <span><i class="fa-regular fa-sparkles font-orange2"></i> {{translate?.setup.templates.email.messages.copyFieldsToAutoFillWithFieldExData}}</span>
                 <span><i class="fa-regular fa-sparkles font-orange2"></i> {{translate?.setup.templates.email.messages.addAButton}}</span>
                 <span><i class="fa-regular fa-sparkles font-orange2"></i> {{translate?.setup.templates.email.messages.addATable}}</span>
             </div>
        </div>
    </div>
</div>