<ag-grid-angular
#agGrid
class="ag-theme-balham h-100 w-100"

[columnDefs]="columnDefs"
[gridOptions]="gridOptions"
[defaultColDef]="defaultColDef"
[paginationPageSize]="25"
[pagination]="false"
[cacheOverflowSize]="2"
[suppressDragLeaveHidesColumns]="true"
[maxConcurrentDatasourceRequests]="-1"
[allowContextMenuWithControlKey]="false"
[frameworkComponents]="frameworkComponents"
(cellClicked)="viewTasks($event)"
(gridReady)="onGridReadyTask($event)"
[overlayNoRowsTemplate]="overlayNoRowsTemplate"
[getContextMenuItems]="getContextMenuItems"
></ag-grid-angular>
<div class="col-12"><p class="ag-record-row-summary-panel padds text-end" id="label_TaskTableRec">{{totalRecord}}&nbsp;{{translate?.general.messages.records}}</p></div>

<!--=======-=======-=======-=======-=======-=======-=======-======= Accept and Cancel Buttone Action -=======-=======-=======-=======-=======-->
<div class="custella-custom-dialog" >
    <p-confirmDialog #acceptDeclineBinTransfer [style]="{width: '30vw'}" [baseZIndex]="10000" key="confirm2">
        <ng-template pTemplate="header">
            <div class="d-flex align-items-center">
                <img src="assets\svg\dialog\Confirmation-w.svg">
                <div class="text-16 font-medium ms-3" id="label_InvTransferByBin">
                    {{"Remove Spare Parts"}}</div>
            </div>
        </ng-template>
        <p-footer>
            <div class="d-flex align-items-center justify-content-end">
                <button pButton class="custella-btn-white text-12 ms-2" id="btn_InvTransferByBinCancel"
                    (click)="acceptDeclineBinTransfer.reject()">{{this.translate?.general.buttons.no.singular}}</button>
                <button pButton type="button" [label]="this.translate?.general.buttons.yes.singular"
                    id="btn_InvTransferByBinAccept" iconPos="left" class="p-button-danger ms-2 h-32"
                    (click)="acceptDeclineBinTransfer.accept()"></button>
            </div>
        </p-footer>
    </p-confirmDialog>
</div>