import { of as observableOf, Observable, throwError } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import * as moment from "moment-timezone";
import { AuthenticationService } from "../auth/authentication.service";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { Http, RequestOptions, Headers } from "@angular/http";

@Injectable()
export class TasksService {
  public refreshList$: EventEmitter<any>;
  public PoiTask$: EventEmitter<any>;
  public selectTime$: EventEmitter<any>;
  public refresherTaskTemplate$: EventEmitter<any>;
  public refreshTaskTemplateProductList$: EventEmitter<any>;

  private ENDPOINT_TODAYS_TASKS = "/tasks/by-date?userId=";
  private ENDPOINT_TASKS_TO_ACCEPT = "/tasks/task-to-accept";
  //private ENDPOINT_TASKS_BY_CUSTOMER="/tasks/by-customer";
  private ENDPOINT_CREATE_TASK = "/tasks";
  private ENDPOINT_CREATE_TASK2 = "/tasks/multi-user";  // Not In Use(Checked On 7/oct/2024)
  private ENDPOINT_ASSIGN_TASK = "/recommend/user-by-team/assign/v2";
  private ENDPOINT_AUTO_ASSIGN_TEAM_MEMBER = "/tasks/multi-user/auto-assign";
  private ENDPOINT_DELETE_TASK = "/tasks/";
  private ENDPOINT_LIST_TASK = "/tasks";
  //private ENDPOINT_LIST_DOWNLINE_TASK="/tasks/get-all-tasks-downline";
  private ENDPOINT_ACCEPT_TASK = "/accept-task";
  private ENDPOINT_REJECT_TASK = "/reject-task";
  private ENDPOINT_TASK_NOTES_BY_TASK = "/task-notes/by-task";
  private ENDPOINT_TASK_NOTES = "/task-notes";
  private ENDPOINT_UPDATE_TASK_STATUS = "/tasks/update-status?";
  private ENDPOINT_TASK_CHECKIN = "/checkin";
  private ENDPOINT_TASK_CHECKOUT = "/checkout";
  private ENDPOINT_TASK_ACTIVITY_BY_ID = "/task-activities/by-task";
  private ENDPOINT_MY_TASKS = "/tasks/get-all-assigned-to-me";
  private ENDPOINT_BULK_UPDATE = "/tasks/bulk-update";
  private ENDPOINT_OPTIMIZE_ROUTE = "/routes";
  private ENDPOINT_UPLOAD_TASK_ATTACHMENTS =
    "/attachments/upload?parentType=TASK_NOTE";
  private ENDPOINT_DELETE_TASK_ATTACHMENTS = "/attachments/";
  private ENDPOINT_UPLOAD_PAYMENT_ATTACHMENTS =
    "/attachments/payment/upload?parentType=PAYMENT_MODULE ";
  private ENDPOINT_BULK_CLONE_TASKS = "/tasks/bulk-clone";
  private ENDPOINT_ASSIGNED_BY_YOU_TASKS = "/tasks/get-all-assigned-by-me";
  private ENDPOINT_CREATE_REQUEST_ASSITANCE = "/request-assistances";
  private ENDPOINT_JOURNEY_BY_TASK = "/user-location-route-tables/for-task/get-all-routes";
  private ENDPOINT_JOURNEY_TRIPS_BY_ID = "/user-location-route-tables/all-trip-list-by/";
  private ENDPOINT_Take_5_forms = "/take-5-forms/update-review";
  private ENDPOINT_Take_5_forms_delete = "/take-5-forms/";
  private ENDPOINT_Take_5_forms_check_in = "/take-5-forms/allow-task-check-in";
  private ENDPOINT_I_SEE_I_ACT_REPORTS = "/i-see-i-act-reports/";
  private ENDPOINT_EMPLOYEE_V4_BY_MUL_TEAMS="/v4/teams/get-all-team-users";
  private ENDPOINT_TEAMS_V3_WITH_TASKS="/v3/teams/by-user";
  private ENDPOINT_TASK_TEMPLATES_PRODUCT="/v1/tmpl-task-products"
    private ENDPOINT_TASK_TEMPLATES_PRODUCT_BULK="/v1/tmpl-task-products/bulk"

  private ENDPOINT_TASKS_TEMPLATE="/v1/tmpl-tasks";
  refreshListTaskProduct$: EventEmitter<any>;

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private router: Router,
    private httpOnly: Http
  ) {
    this.refreshList$ = new EventEmitter();
    this.refreshListTaskProduct$ = new EventEmitter();
    this.PoiTask$ = new EventEmitter();
    this.selectTime$ = new EventEmitter();
    this.refresherTaskTemplate$ = new EventEmitter();
    this.refreshTaskTemplateProductList$ = new EventEmitter();
  }

  /******************************************** get all tasks priorities ******************************************/
  public getAllTasksPriorities() {
    return [
      {
        color: "#6FBC60",
        value: "Low",
        classColor: "low",
      },
      {
        color: "#E88901",
        value: "Medium",
        classColor: "medium",
      },
      {
        color: "#D34747",
        value: "High",
        classColor: "high",
      },
    ];
  }

  /******************************************** create task ******************************************/
  public createTask(body: any) {


    return this.http
      .post(environment.base_url + this.ENDPOINT_CREATE_TASK, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          if(body.tmplTaskId && body.tmplTaskId != null){
            this.refreshList$.emit(body);
            this.refreshListTaskProduct$.emit("Task Created From Template");
          }
          else{
            this.refreshList$.emit(body);
          }
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return observableOf(error);
        })
      );
  }

  /******************************************** get tasks by id ******************************************/
  public getTaskById(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_DELETE_TASK + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  /******************************************** upload payment attahcment ******************************************/
  public uploadPaymentAttachment(formData: any) {
    const headers = new Headers({});
    headers.append("Authorization", "Bearer " + this.auth.getToken());
    let options = new RequestOptions({ headers: headers });
    return this.httpOnly
      .post(
        environment.base_url + this.ENDPOINT_UPLOAD_PAYMENT_ATTACHMENTS,
        formData,
        options
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  /******************************************** delete task attachments ******************************************/
  public deleteTaskAttachment(id: any) {
    return this.http
      .delete(
        environment.base_url + this.ENDPOINT_DELETE_TASK_ATTACHMENTS + id,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  /******************************************** delete task by id ******************************************/
  public deleteTaskById(data: any, groupDelete: boolean) {
    let params = new HttpParams();
    if (groupDelete === true) {
      params.set("deleteGroup", "true");
    } else {
      params = params.set("deleteGroup", "false");
    }
    if(data.removeProductsLikedToJobOrder){
      params = params.set("removeProductsLikedToJobOrder", "true");
    }



    return this.http.delete(environment.base_url + this.ENDPOINT_DELETE_TASK + data.id, { params: params,observe: "response"})
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit('Deleted Task');
          if(data.removeProductsLikedToJobOrder){
            this.refreshList$.emit('Deleted Task with Products');
          }
          else{
            this.refreshList$.emit('Deleted Task');
          }

          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  /******************************************** edit task ******************************************/
  public editTask(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_CREATE_TASK, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(body);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  /******************************************** create route optimization ******************************************/
  public createRouteOptimization(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_OPTIMIZE_ROUTE, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  /******************************************** assign task ******************************************/
  public assignTask(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_ASSIGN_TASK, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(body);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

    /******************************************** get tasks by jo-id ******************************************/
    public getTasksByJobOrderId(id: any) {
      return this.http
        .get(environment.base_url + '/job-orders/' + id + '/tasks', {
          observe: "response",
        })
        .pipe(
          map((resp: any) => {
            return resp;
          }),
          catchError((error) => {
            //console.log(error);
            return observableOf(error);
          })
        );
    }
  /******************************************** get tasks Accesp and Decline ******************************************/  
    public getTasksToAccept(id: any){
      let params = new HttpParams().set('userId', id);
      return this.http.get( environment.base_url+this.ENDPOINT_TASKS_TO_ACCEPT, { params: params }).pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  public acceptTask(id: any, userId: any, groupTasks: any){
    if(groupTasks == 'true'){
      var params = new HttpParams().set('applyForGroup', 'true');
    } else {
      var params = new HttpParams().set('applyForGroup', 'false');
    }
    return this.http.put( environment.base_url+this.ENDPOINT_DELETE_TASK + id + this.ENDPOINT_ACCEPT_TASK+'?userId='+userId, null, { params: params, observe: 'response' })
    .pipe(
      map((resp: any) => {
        this.refreshList$.emit('Accepted Task');
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
}

  public rejectTask(data: any, userId: any, groupTasks: any){
    if(groupTasks == 'true'){
      var params = new HttpParams().set('applyForGroup', 'true');
    } else {
      var params = new HttpParams().set('applyForGroup', 'false');
    }
    return this.http.put( environment.base_url+this.ENDPOINT_DELETE_TASK + data.id + this.ENDPOINT_REJECT_TASK+'?userId='+userId, data.body, { params: params, observe: 'response' })
    .pipe(
      map((resp: any) => {
        this.refreshList$.emit('Rejected Task');
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
}

/**================================== Inventory setup =>location  =======================*/
public getAllTask(searchValue: any,parameters: any, fromDateTime: any, toDateTime: any, taskStatuses: any){
  let params = new HttpParams();
  params = params.set('isMyTasks', true);
  if(searchValue !== '' && searchValue != null){
    params = params.set('search', searchValue);
  }
  if(fromDateTime !== '' && fromDateTime != null){
    params = params.set('fromDateTime', fromDateTime);
  }
  if(toDateTime !== '' && toDateTime != null){
    params = params.set('toDateTime', toDateTime);
  }
  if(taskStatuses !== '' && taskStatuses != null){
    params = params.set('taskStatuses', taskStatuses);
  }
  console.log(parameters)
  if(parameters)

  params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
  return this.http.get( `${environment.base_url}/v3/tasks`, { observe: 'response',params : params} ).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/**================================== get Staff Task  =======================*/
public getAllStaffTask(searchValue: any,parameters: any, fromDateTime: any, toDateTime: any, taskStatuses: any, isStaffTasks: any,isTeamTask: any, onlineStatus: any,allStaff: any, teamIds: any,checklistRecordId: any, pmvId:any){
  let params = new HttpParams();
  if(isStaffTasks !== '' && isStaffTasks != null){
    params = params.set('isStaffTasks', isStaffTasks);
  }
  if(isTeamTask !== '' && isTeamTask != null){
    params = params.set('isTeamTask', isTeamTask);
  }
  if(searchValue !== '' && searchValue != null){
    params = params.set('search', searchValue);
  }
  if(fromDateTime !== '' && fromDateTime != null){
    params = params.set('fromDateTime', fromDateTime);
  }
  if(toDateTime !== '' && toDateTime != null){
    params = params.set('toDateTime', toDateTime);
  }
  if(taskStatuses !== '' && taskStatuses != null){
    params = params.set('taskStatuses', taskStatuses);
  }
  if(onlineStatus !== '' && onlineStatus != null){
    params = params.set('onlineStatus', onlineStatus);
  }
  if(allStaff !== '' && allStaff != null){
    params = params.set('allStaff', allStaff);
  }
  if(teamIds !== '' && teamIds != null){
    params = params.set('teamIds', teamIds);
  }
  if(checklistRecordId !== '' && checklistRecordId != null){
    params = params.set('checklistRecordId', checklistRecordId);
  }
  if(pmvId !== '' && pmvId != null){
    params = params.set('pmvId', pmvId);
  }
 
  console.log(parameters)
  if(parameters)

  params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
  return this.http.get( `${environment.base_url}/v3/tasks`, { observe: 'response',params : params} ).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/************************************************* Get All Task Noted **********************************************/
public getAllTaskNotesByTask(id : any){
  let params = new HttpParams().set('taskId', id);
  return this.http.get( environment.base_url+this.ENDPOINT_TASK_NOTES_BY_TASK, {params : params}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/************************************************* Create Task Noted **********************************************/
public createTaskNotes(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_TASK_NOTES, body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit(body);
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

/************************************************* delete Task Note**********************************************/
public deleteTaskNotes(id: any){
  return this.http.delete( environment.base_url+this.ENDPOINT_TASK_NOTES+'/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit(resp);
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

/************************************************* Get All Task Activity**********************************************/
public getAllTaskActivityByTask(id: any){
  let params = new HttpParams().set('taskId', id);
  return this.http.get( environment.base_url+this.ENDPOINT_TASK_ACTIVITY_BY_ID, {params : params}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

/************************************************* Get Journey By Task **********************************************/
public getJourneyByTask(taskId : any, userId : any, userIds : any){
  let params = new HttpParams().set('taskId', taskId);
  return this.http.get( environment.base_url+this.ENDPOINT_JOURNEY_BY_TASK, {params : params}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/************************************************* Get Journey Trip By Id **********************************************/
public getJourneyTripsById(id: any){
  return this.http.get( environment.base_url+this.  ENDPOINT_JOURNEY_TRIPS_BY_ID + id).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

public emitSelectedTime(time: any){
  this.selectTime$.emit(time);
}
/************************************************* Get User By Task **********************************************/
public getUsersByTask(taskId: any){
  return this.http.get( environment.base_url+'/tasks/get-assigned-users/'+taskId).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/************************************************* Get Location By Task **********************************************/
public getLocationByTask(userId: any,startDate: any){
  return this.http.get( environment.base_url+'/tasks/get-by-date-assigned-to?userId='+userId+'&startDate='+startDate).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/************************************************* Get Calculate Distance **********************************************/
public getCalculateDistance(parameters: any){
  let params = new HttpParams();
  params = params.set('startLat', parameters.startLocation.coordinate.latitude).set('startLong', parameters.startLocation.coordinate.longitude).set('endLat', parameters.endLocation.coordinate.latitude).set('endLong',parameters.endLocation.coordinate.longitude);
  return this.http.get( environment.base_url+'/user-location-route-tables/get-estimation', {params : params}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

/************************************************* Create Journey **********************************************/
public createJourney(body:any){
  return this.http.post( environment.base_url+'/user-location-route-tables/save', body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit(body);
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/************************************************* Update Journey **********************************************/
public updateJourney(body:any){
  return this.http.post( environment.base_url+'/user-location-route-tables/save', body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit(body);
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/************************************************* delete Journey **********************************************/
public deleteJourney(id: any){
  return this.http.delete( environment.base_url+'/user-location-route-tables/'+id, {observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit('Deleted Journey');
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

public getPolyline(parameters:any){
  let params = new HttpParams();
  params = params.set('origin', parameters.startLocationAddress).set('destination', parameters.endLocationAddress).set('key', 'AIzaSyA-DkL8IklhymoS1gpvJlzbEwV19RqOA2E');
  return this.http.get('https://maps.googleapis.com/maps/api/directions/json', {params : params}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}



public getRequestAssistanceForTask(id : any){
  return this.http.get( environment.base_url+'/task/'+id+this.ENDPOINT_CREATE_REQUEST_ASSITANCE).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);

}

public editTaskStatus(id: any, status: any, user: any, task: any){
  var taskStatus = true;
  if(user){
    taskStatus = false;
  }

  if(!user){
    user = _.find(task.assignedUsersList, (o) => {
      return o.owner;
    })
  }
  return this.http.put( environment.base_url+this.ENDPOINT_UPDATE_TASK_STATUS+"taskId="+id+"&newStatus="+status+'&userId='+user.assignedTo.id+'&isTaskStatus='+taskStatus, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit("Task Status Updated");
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);

}

public hazardDeleteTask(id: any){
  return this.http.delete( environment.base_url+this.ENDPOINT_Take_5_forms_delete+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit("Task Hazard");
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);

}

public hazardReportDeleteTask(id: any){
  return this.http.delete( environment.base_url+this.ENDPOINT_I_SEE_I_ACT_REPORTS+id, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);

}

public hazardCheckInTask(id : any,allowTaskCheckIn : any){
 // {{ip}}/api/take-5-forms/allow-task-check-in?id=4&allowTaskCheckIn=false
  return this.http.put( environment.base_url+this.ENDPOINT_Take_5_forms_check_in +'?id='+id+'&allowTaskCheckIn='+allowTaskCheckIn, null, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);

}
/************************************************* Check In task **********************************************/
public checkInTask(id: any, userId: any){
  return this.http.put( environment.base_url+this.ENDPOINT_DELETE_TASK + id + this.ENDPOINT_TASK_CHECKIN+'?userId='+userId, null, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit('Check In Task');
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);

}
/************************************************* Checkout  task **********************************************/
public checkOutTask(id: any, param: any, userId: any){
  var params = '';
  if(param !== null){
    var params = '?status='+param;
  }
  if(param == 'COMPLETED'){
    params += '&userId='+userId;
  } else {
    params += '?userId='+userId;
  }

  return this.http.put( environment.base_url+this.ENDPOINT_DELETE_TASK + id + this.ENDPOINT_TASK_CHECKOUT+params, {}, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit('Check Out Task');
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);

}
/************************************************* Remove member from the task **********************************************/
public removerUserFromTask(taskId: any, userId: any){
  return this.http.put( `${environment.base_url}/tasks/${taskId}/remove/${userId}`, {}, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit(resp);
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);

}

public makeOwnerForTask(taskId: any, userId: any){
  return this.http.put( `${environment.base_url}/tasks/${taskId}/change-owner/${userId}`, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit(resp);
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);

}
/************************************************* Add member into the task **********************************************/
public addMemberToTask(task: any, user: any){
  return this.http.put( `${environment.base_url}/tasks/${task.id}/add-user/${user.id}`, {}, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit('Refresh');
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);

}

/**================================== Inventory setup =>location  =======================*/
public getTaskByCustomerV2(searchValue: any,parameters: any, customerId: any,jobOrderId: any,siteId: any,projectId: any){
  let params = new HttpParams();
  if(searchValue){
  params = params.set('search', searchValue);
  }
  //params = params.set('origin', 'customer');
  if(customerId){
  params = params.set('customerId', customerId);
  }
  if(jobOrderId){
    params = params.set('jobOrderId', jobOrderId);
  }
  if(siteId){
    params = params.set('siteId', siteId);
  }
  if(projectId){
    params = params.set('projectId', projectId);
  }
  console.log(parameters)
  if(parameters)

  params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
  return this.http.get( `${environment.base_url}/v4-1/tasks/by-module`, { observe: 'response',params : params} ).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/**================================== get All Task Data Base On Date  =======================*/
public getAllMyTasks(id: any,startDate: any,endDate: any){
  let params = new HttpParams().set('userId', id);
  if(startDate)
    params = params.set('startDate', startDate);
  if(endDate)
    params = params.set('endDate', endDate);
  return this.http.get( environment.base_url+this.ENDPOINT_MY_TASKS, {params : params}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/**================================== Task(s) moved   =======================*/
public bulkEditTask(body:any){
  return this.http.put( environment.base_url+this.ENDPOINT_BULK_UPDATE, body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit(body);
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/**================================== Task(s) Clone   =======================*/
public bulkCloneTasks(body :any){
  return this.http.post( environment.base_url+this.ENDPOINT_BULK_CLONE_TASKS, body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit(resp);
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/**============================================== Task(s) : Request Assistance   ===============================================*/
public createRequestAssistanceForTask(body: any){
  return this.http.post( environment.base_url+this.ENDPOINT_CREATE_REQUEST_ASSITANCE, body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit(resp);
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/**============================================== Task(s) : Upload Assistance   ===============================================*/
public uploadTaskAttachment(formData:any){
  const headers = new Headers({});
  headers.append('Authorization', 'Bearer '+this.auth.getToken());
  let options = new RequestOptions( { headers: headers } )
  return this.httpOnly.post(environment.base_url+this.ENDPOINT_UPLOAD_TASK_ATTACHMENTS, formData, options).pipe(map( (resp:any) => {
    this.refreshList$.emit(resp);
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

public editTaskNotes(body:any){
  return this.http.put( environment.base_url+this.ENDPOINT_TASK_NOTES, body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit(body);
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

public getEmployeeByMulTeam(ids:any, leave:any,userId:any,allTeams:any,excludeDrivers:any){
  let params = new HttpParams().set('teamIds', ids);
  if(leave){
    params = params.set('leaveDate', leave);
  }
  if(userId){
    params = params.set('userId', userId);
  }
  params = params.set('allTeams', allTeams);
  params = params.set('excludeDrivers', excludeDrivers);
 

  return this.http.get( environment.base_url+this.ENDPOINT_EMPLOYEE_V4_BY_MUL_TEAMS, { params : params}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

public getTeamsWithTasksV3(id:any, startDate:any, endDate:any,teamIds:any,isAllTeam:any){
  let params = new HttpParams().set('userId', id).set('withTasks', 'true');
  if(endDate){
    params = params.set('startDate', startDate).set('endDate', endDate);
  } else {
    params = params.set('startDate', moment().format('YYYY-MM-DD')).set('endDate', moment().format('YYYY-MM-DD'));
  }
  //if(teamIds){
    params = params.set('teamIds', teamIds);
  //}
    params = params.set('isAllTeam', isAllTeam);
    return this.http.get( environment.base_url+this.ENDPOINT_TEAMS_V3_WITH_TASKS, {params : params}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

public getTodaysTasks(id:any, date:any, timezone:any){
  var finalEndPoint = this.ENDPOINT_TODAYS_TASKS+id+'&date='+date+'&timezone='+timezone;
  return this.http.get( environment.base_url+finalEndPoint).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}


public getTake5ById(id : any){
  return this.http.get( environment.base_url+this.ENDPOINT_Take_5_forms_delete+id, {observe : 'response'}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
public saveNoteTask(id: any,review: any){
  return this.http.put( environment.base_url+this.ENDPOINT_Take_5_forms+'?id='+id+'&review='+review, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
public generateTake5FormPDF(id: any){
  return this.http.post( environment.base_url+'/take-5-forms/generate-pdf?id='+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit(resp);
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
public getActualRouteByDate(id: any, date: any){
  return this.http.get( environment.base_url+'/users/get-all-routes?userId='+id+'&date='+date).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

public getRouteOptimizationByDate(id: any, date: any){
  return this.http.get( environment.base_url+this.ENDPOINT_OPTIMIZE_ROUTE+'/by-user-and-date?userId='+id+'&routeDate='+date).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}


/******************************************** List of job order Templates ******************************************/
public getAllTaskTemplates( search: any, parameters: any, siteId: any,projectId: any,teamId: any, tmplJobOrderId: any,templateStatus: any) {
    return this.getAllTaskTemplates_(search, parameters, siteId,projectId,teamId, tmplJobOrderId, null, {},templateStatus);
}
public getAllTaskTemplates_( search: any, parameters: any, siteId: any,projectId: any,teamId: any, tmplJobOrderId: any, skipTaskListIds: any, other: any,templateStatus: any) {
  let params = new HttpParams();
  if (search !== "" && search != null) {
    params = params.set("search", search);
  }
  if (siteId !== "" && siteId != null) {
    params = params.set("siteId", siteId);
  }

  if (projectId !== "" && projectId != null) {
    params = params.set("projectId", projectId);
  }

  if (teamId !== "" && teamId != null) {
    params = params.set("teamId", teamId);
  }

  if (templateStatus !== "" && templateStatus != null) {
    params = params.set("templateStatus", templateStatus);
  }

  if (tmplJobOrderId !== "" && tmplJobOrderId != null) {
    params = params.set("tmplJobOrderId", tmplJobOrderId);
  }

  if (skipTaskListIds !== "" && skipTaskListIds != null) {
    params = params.set("skipTaskListIds", skipTaskListIds);
  }
  // if (other?.nextParmxxx !== "" && other?.nextParmxxx != null) {
  //   params = params.set("other?.nextParmxxx", other?.nextParmxxx);
  // }

  if (parameters)
    //console.log(parameters)
    params = params
      .set("startFrom", parameters.startRow)
      .set("perPage", "25")
      .set("columnName", parameters.sortModel[0].colId)
      .set("sortOrder", parameters.sortModel[0].sort);

  return this.http .get(environment.ms_base_url + this.ENDPOINT_TASKS_TEMPLATE+'/filter', { observe: "response", params: params}).pipe(map((resp: any) => {
      return resp;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
}
/******************************************** create task Template ******************************************/
public createTaskTemplate(body: any) {
  return this.http.post(environment.ms_base_url + this.ENDPOINT_TASKS_TEMPLATE, body, { observe: "response" }).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
}
/******************************************** Update task Template ******************************************/
public editTaskTemplate(body: any) {
  return this.http.put(environment.ms_base_url + this.ENDPOINT_TASKS_TEMPLATE, body, { observe: "response" }).pipe(
      map((resp: any) => {
       return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
}
/************************************************* Get Task Template By Id **********************************************/
public getTaskTemplate(id: any){
  return this.http.get( environment.ms_base_url+this.ENDPOINT_TASKS_TEMPLATE+'/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

/************************************************* delete Task Template **********************************************/
public deleteTaskTemplate(id: any){
  return this.http.delete( environment.ms_base_url+this.ENDPOINT_TASKS_TEMPLATE+'/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresherTaskTemplate$.emit('Deleted Task Template');
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/******************************************** Add  task into JO Template ******************************************/
public saveTaskToJO(body: any) {
  return this.http.patch(environment.ms_base_url + '/v1/tmpl-job-orders/append-tasks-list', body, { observe: "response" }).pipe(
      map((resp: any) => {
        this.refresherTaskTemplate$.emit(resp);
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
}
/******************************************** Delete task from JOTemplate ******************************************/
public deleteJobOrderTaskTemplate(tmplJobOrderId: any,id: any){
  let params = new HttpParams();
    params = params.set("tmplJobOrderId", tmplJobOrderId);
    params = params.set("taskId", id);
  return this.http.delete( environment.ms_base_url+'/v1/tmpl-tasks-remove', { observe: 'response', params: params }).pipe(map( (resp:any) => {
    this.refresherTaskTemplate$.emit('Deleted JO Task Template'); 
    return resp;
  }
),catchError( error => {
  console.log(error)
  if(error.status == 200){ this.refresherTaskTemplate$.emit('Deleted JO Task Template');}
    return throwError( error );
  }),);
}

  /******************************************** List of job order Templates ******************************************/
  public getAllTaskTemplatesProduct( search: any, parameters: any,tmplTaskId: any) {
    let params = new HttpParams();
    if (search !== "" && search != null) {
      params = params.set("search", search);
    } 
     params = params.set("tmplTaskId", tmplTaskId);
   
    if (parameters)
      //console.log(parameters)
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25")
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);
  
  
    return this.http .get(environment.base_url + this.ENDPOINT_TASK_TEMPLATES_PRODUCT+'/filter', { observe: "response", params: params}).pipe(map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }


    /******************************************** create task Template products ******************************************/
    public createJobOrderTemplateProduct(body: any) {
      return this.http
        .post(environment.base_url + this.ENDPOINT_TASK_TEMPLATES_PRODUCT_BULK, body, { observe: "response" }).pipe( map(
            (resp: any) => {
              this.refreshTaskTemplateProductList$.emit(resp);
              return resp;
            },
            catchError((error) => {
              //console.log(error);
              return throwError(error);
            })
          )
        );
    }

/******************************************** create task Template products ******************************************/
    
    public deleteTaskTemplateProduct(id: any) {
      return this.http.delete(environment.base_url + this.ENDPOINT_TASK_TEMPLATES_PRODUCT+'/'+ id, {observe: "response", }).pipe( map(
        (resp: any) => {
          this.refreshTaskTemplateProductList$.emit("Deleted Task Template Product");
          return resp;
        },
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      )
    );
  }
}
