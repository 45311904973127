import {
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { TasksService } from "src/app/_services/tasks.service";

import * as moment from "moment-timezone";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { CustomersService } from "src/app/_services/customers.service";
import { ErrorUtil } from "src/app/_utilities/error";

import * as _ from "lodash";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { PermissionService } from "src/app/auth/permission.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { TeamService } from "src/app/_services/team.service";
import { DynamicSkillsService } from "src/app/_services/admin/dynamic-skills.service";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ModalServiceService } from "src/app/_services/modal-service.service";
import { SiteService } from "src/app/_services/site.service";
import { ProjectService } from "src/app/_services/project.service";
import { CustomerTaskComponent } from "../../customer/customer-task/customer-task.component";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
import { Calendar } from "primeng/calendar";
import { AiService } from 'src/app/_services/admin/ai.service';
import { ProfilesService } from "src/app/_services/admin/profiles.service";
import { UsersService } from "src/app/_services/admin/users.service";


declare var google: any;

@Component({
  selector: "app-add-edit-task",
  templateUrl: "./add-edit-task.component.html",
  styleUrls: ["./add-edit-task.component.scss"],
})
export class AddEditTaskComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  allowAssignTask!: boolean;
  options!: { fields: string[] };

  data: any;
  dateConfig: any;
  public jobOrderId: any;

  public allPriorities: any = [];
  public addTaskFullForm!: UntypedFormGroup;

  public showCustomerTable = false;

  public selectedPriority: any;
  public selectedCustomer: any;

  public teams: any[] = [];
  public jobOrders: any = [];
  public customers: any[] = [];

  public departments: any[] = [];
  public roles: any[] = [];
  public levels: any[] = [];

  public selectedDepartment: any = null;
  public selectedRole: any = null;
  public selectedLevel: any = null;
  public selectedTeam: any = null;

  public customer: any;

  private formData: any;

  public addTask: any = {
    joName: null,
    customerName: null,
    hasBuffer: true,
    adhoc: false,
    duration: "30",
    jobOrder: { id: null },
    userDynamicSkillList: [],
    showServiceChecklist: false,
    showHandoverCertificate: false,
    showCustomerServiceReport: false,
    serviceChecklistRequired: false,
    customerServiceReportRequired: false,
    handoverCertificateRequired: false,
    team: { id: null },
    date: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    site: { id: null },
    project: { id: null },
    checklistRecordId: null,
    pmvId: null,
    csrLogNo: null,
    csrSalesOrder: null,
  };

  submitTouched: boolean = false;

  timeFirstSet: Boolean = false;
  taskDurationSuggestion: any;

  public mode!: string;

  public allDurations = [
    { time: "30", label: "30", timeLabel: "min" },
    { time: "45", label: "45", timeLabel: "min" },
    { time: "60", label: "60", timeLabel: "min" },
    { time: "90", label: "1.5", timeLabel: "hours" },
    { time: "120", label: "2", timeLabel: "hours" },
    { time: "150", label: "2.5", timeLabel: "hours" },
    { time: "180", label: "3", timeLabel: "hours" },
  ];

  // google map stuff
  private autocomplete: any;
  private autocompleteLsr: any;
  private flag: boolean = true;
  private homePlaceChanged: boolean = false;
  @ViewChild("homeAddressSearch")
  public homeAddressSearchElementRef!: ElementRef;

  timezone!: string;
  public chubbAddonFeature: any;

  comingFrom: string = "Task";

  task: any = {};
  assignMethod: any;

  toggleAdvanceOptions = false;
  addonFeature: any;

  public showChubbFormDiv: any = "0";
  public showChubbFormSubmitted: any;

  public allBuffers = [
    {
      value: 0,
      label: "No Buffer",
    },
    {
      value: 5,
      label: "mins",
    },
    {
      value: 10,
      label: "mins",
    },
    {
      value: 15,
      label: "mins",
    },
    {
      value: 20,
      label: "mins",
    },
    {
      value: 25,
      label: "mins",
    },
    {
      value: 30,
      label: "mins",
    },
  ];

  public allSites: any[] = [];
  public allProject: any[] = [];

  selectedSite: any;
  selectedProject: any;

  public showingTable = {
    sites: false,
    project: false,
  };

  permissions = {
    siteShow: false,
    projectShow: false
  }
  showClose: any;
  mainLabel: any;
  translate: any;

  isPaneVisible: boolean = false;

  @ViewChild('calendar') calendar: Calendar;
  @ViewChild('starTime') starTime: Calendar;
  @ViewChild('endTime') endTime: Calendar;
  taskAITime: any= {
    displayAITime: null,
    digitalTime: null,
    newTime: null
  };
  constructor(
    private tasksService: TasksService,
    private util: UtilServiceService,
    private customersService: CustomersService,
    private errorUtil: ErrorUtil,
    private ngZone: NgZone,
    private auth: AuthenticationService,
    private perm: PermissionService,
    private teamService: TeamService,
    private skillsService: DynamicSkillsService,
    private router: Router,
    public modalRef: BsModalRef,
    private modalService: ModalServiceService,
    private siteService: SiteService,
    private projectService: ProjectService,
    private languageTranslateService: LanguageTranslateService,
    private aiService: AiService,
    private userService: UsersService,
  ) {
    super();
    this.getLanguageData();
    this.preInit();
  }

  ngOnInit(): void {
    this.init();
  }

  /******************************************** pre init ******************************************/
  private preInit() {
    this.addTaskFullForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required]),
      startTime: new UntypedFormControl(null, [Validators.required]),
      endTime: new UntypedFormControl(null, [Validators.required]),
      date: new UntypedFormControl(null, [Validators.required]),
      location: new UntypedFormControl(null),
      customer: new UntypedFormControl(null),
      description: new UntypedFormControl(null),
      buffer: new UntypedFormControl(null),
      lock: new UntypedFormControl(null),
      priority: new UntypedFormControl(null),
      startBuffer: new UntypedFormControl(null),
      endBuffer: new UntypedFormControl(null),
      jobOrder: new UntypedFormControl(null),
      duration: new UntypedFormControl(null),
      team: new UntypedFormControl(null),
      tillDate: new UntypedFormControl(null),
    });
    
  }
  

  private init() {
    const aiFeature = JSON.parse(this.auth.getAIFeature());
    this.taskDurationSuggestion = aiFeature.taskDurationSuggestion ? true : false;
    console.log(aiFeature, "AI Feature", this.taskDurationSuggestion);
    
    this.processPermission();
    console.log(this.data, "=====", this.mode);
    this.addonFeature = this.auth.getAddonFeatureLists();
    this.allPriorities = this.tasksService.getAllTasksPriorities();
    this.selectedPriority = this.allPriorities[0];

    this.allowAssignTask = this.perm.capable("allow assign task", "create");
    this.options = this.util.getAutocompleteOptions();

    this.dateConfig = {
      dateInputFormat: localStorage.getItem("date_format")!.toUpperCase(),
    };

    this.getTeams();
       // Get the user's timezone, this will be useful for displaying time-related information correctly
    this.timezone = this.auth.getUserTimezone();
    this.chubbAddonFeature = this.auth.getAddonFeatureLists().addonCHUBB;

   // debugger
    if (this.data.site) {
      this.selectedSite = this.data.site;
      this.addTask.site.id = this.data.site.id;


      this.addTask.customer = this.data.site.customer.id;
      this.addTask.customerName = this.data.site.customer;

      this.addTaskFullForm.patchValue({ customer: this.data.site.customer.name });

      this.selectedCustomer = this.data.site.customer;
      this.getCustomerDetailById()
      this.showCustomerTable = false;
      this.customers = [];

    }

    if (this.data.project) {
      this.selectedProject = this.data.project;
      this.addTask.project.id = this.data.project.id;
    }

    // to know if open from jo order
    if (this.data.comingFrom !== undefined && this.data.comingFrom == "JO") {
      this.comingFrom = "JO";
    }
    if (this.data.checklistRecordId ) {
      ////debugger
      this.addTask.checklistRecordId = this.data.checklistRecordId;
    }

    if (this.data.pmvId ) {
      this.addTask.pmvId = this.data.pmvId;
    }

    if (this.mode === "edit") {
      this.addTask = this.data;

      this.tasksService.getTaskById(this.data.id).subscribe((task: any) => {
        if (this.data.customer != null) {
          this.addTask.customerName = task.body.customer;
          this.selectedCustomer = task.body.customer;
        }
      });

      if (!this.addTask.team) {
        this.addTask.team = { id: null };
      }

      this.addTask.adhoc = false;
      this.addTask.duration = "30";

      if (!this.addTask.jobOrder) {
        this.addTask.jobOrder = { id: null };
      }

      if (this.addTask.locationName) {
        this.addTask.locationFull =
          this.addTask.locationName + ", " + this.addTask.location;
      } else {
        this.addTask.locationFull = this.addTask.location;
      }

      if (this.addTask.customer) {
        this.addTask.customer = this.addTask.customer.id;
        this.selectedCustomer = this.addTask.customer;
      } else {
        this.addTask.customer = null;
        this.selectedCustomer = null;
      }

      var formatDate = moment
        .tz(this.addTask.startDateTime, this.auth.getUserTimezone())
        .toISOString();

      this.addTask.date = new Date(formatDate);
      this.addTask.startTime = moment.tz(this.addTask.startDateTime, this.auth.getUserTimezone()).format("hh:mm A");

      this.addTask.endTime = moment.tz(this.addTask.endDateTime, this.auth.getUserTimezone()).format("hh:mm A");

      console.log(this.addTask.startTime);
      console.log(this.addTask.endTime);
      var requiredSkill = {general : false, skill1 : false, skill2 : false, skill3 : false};
      if(this.addTask.requiredSkill){
        var splitSkills = this.addTask.requiredSkill.split(',');
        if(splitSkills.length && _.includes(splitSkills, 'GENERAL')){
          requiredSkill.general = true;
        }
        if(splitSkills.length && _.includes(splitSkills, '1')){
          requiredSkill.skill1 = true;
        }
        if(splitSkills.length && _.includes(splitSkills, '2')){
          requiredSkill.skill2 = true;
        }
        if(splitSkills.length && _.includes(splitSkills, '3')){
          requiredSkill.skill3 = true;
        }
      }
      this.addTask.skills = requiredSkill;
    } else if (this.mode === "edit&back" || this.mode == "add&back") {
      this.addTask = this.data;

      if (!this.data.jobOrder) {
        this.addTask.jobOrder = { id: null };
      }
      if (!this.addTask.team) {
        this.addTask.team = { id: null };
      }
      this.validateTimeFields(this.addTask.adhoc);
      if (this.addTask.locationName) {
        this.addTask.locationFull =
          this.addTask.locationName + ", " + this.addTask.location;
      } else {
        this.addTask.locationFull = this.addTask.location;
      }
      if (this.addTask.customer) {
        if (this.addTask.customer.id) {
          this.addTask.customer = this.addTask.customer.id;
        } else {
          this.addTask.customer = this.addTask.customer;
        }
        this.selectedCustomer = this.addTask.customerName;
      } else {
        this.addTask.customer = null;
        this.selectedCustomer = null;
      }

      if (this.addTask.date) {
        this.addTask.date = new Date(this.addTask.date);
      } else {
        this.addTask.date = new Date(this.addTask.startDateTime);
      }

      if (this.addTask.repeatTillDate) {
        this.addTask.repeatTillDate = moment(
          this.addTask.repeatTillDate
        ).format(this.dateConfig.dateInputFormat);
      }

      if (this.addTask.startTime && this.addTask.endTime) {
        var finalStartTime = this.util.timeFormatter( this.addTask.startTime, this.addTask.date );
        var finalEndTime = this.util.timeFormatter( this.addTask.endTime,this.addTask.date );
        this.addTask.startTime = moment(this.addTask.startDateTime)
          .tz(this.auth.getUserTimezone())
          .format("hh:mm A");
        this.addTask.endTime = moment(this.addTask.endDateTime).tz(this.auth.getUserTimezone())
          .format("hh:mm A");
      } else {
        this.addTask.startTime = moment(this.addTask.startDateTime)
          .tz(this.auth.getUserTimezone())
          .format("hh:mm A");
        this.addTask.endTime = moment(this.addTask.endDateTime)
          .tz(this.auth.getUserTimezone())
          .format("hh:mm A");
      }
      var requiredSkill = {general : true, skill1 : false, skill2 : false, skill3 : false};
      if (this.addTask.requiredSkill) {
        var splitSkills = this.addTask.requiredSkill.split(",");
        if (splitSkills.length && _.includes(splitSkills, "GENERAL")) {
          requiredSkill.general = true;
        }
        if (splitSkills.length && _.includes(splitSkills, "1")) {
          requiredSkill.skill1 = true;
        }
        if (splitSkills.length && _.includes(splitSkills, "2")) {
          requiredSkill.skill2 = true;
        }
        if (splitSkills.length && _.includes(splitSkills, "3")) {
          requiredSkill.skill3 = true;
        }
      }
      this.addTask.skills = requiredSkill;
      // if(!this.addTask.hasBuffer){
      //   this.addTask.hasBuffer = false;
      // }
      //console.log("DATA ", this.addTask);
     
    } else if (this.mode === "add") {
      if (this.data.checkListRecordId ) {
        this.addTask.checklistRecordId = this.data.checkListRecordId;
      }
  
      if (this.data.pmvId ) {
        this.addTask.pmvId = this.data.pmvId;
      }
     //this.addTask = Object.assign(this.addTask, this.data);
      this.addTask.date = new Date();
      this.setStartTime();
      this.timeFirstSet = true;
      if (this.data.comingFrom !== undefined && this.data.comingFrom == "JO") {
        this.comingFrom = "JO";
        }
      console.log(this.data.teamId);
      if (this.data.teamId) {
        this.addTask.team = { id: parseInt(this.data.teamId) };
      }

      if (this.data.jobOrder) {
        this.addTask.jobOrder = this.data.jobOrder;
        if (this.addonFeature.addonHILLS == "HILLS") {
          // var taskNameForHill = "";
          // if (this.data.jobOrder.customer != null) {
          //   taskNameForHill = this.data.jobOrder.customer.name;
          // }
          // if (this.data.jobOrder.ward != null) {
          //   taskNameForHill += "-" + this.data.jobOrder.ward;
          // }
          // if (this.data.jobOrder.bed != null) {
          //   taskNameForHill += "-" + this.data.jobOrder.bed;
          // }
          // this.addTask.taskName = taskNameForHill;
          this.addTask.taskName = this.addTask.jobOrder.jobOrderName;
        } else {
          this.addTask.taskName = this.addTask.jobOrder.jobOrderNumber;
        }

        if (
          this.addTask.jobOrder.installationDate &&
          this.addTask.jobOrder.installationDate !== ""
        )
          this.addTask.date = new Date(this.addTask.jobOrder.installationDate);

        if (
          this.addTask.jobOrder.installationTime &&
          this.addTask.jobOrder.installationTime !== ""
        )
          this.addTask.startTime = moment(
            this.addTask.jobOrder.installationTime
          ).format("h:mm A");
        else this.setStartTime();

        if (this.addTask.jobOrder && this.addTask.jobOrder.customer) {
          this.addTask.customer = this.addTask.jobOrder.customer.id;
          this.addTask.customerName = this.addTask.jobOrder.customer;
          this.selectedCustomer = this.addTask.jobOrder.customer.name;
        }
        this.addTask.fixed = false;
        if (this.data.jobOrder.placeId && this.data.jobOrder.placeId !== null) {
          this.addTask.location = "";
          this.addTask.locationFull = "";
          if (this.data.jobOrder.installationStreet)
            this.addTask.location += this.data.jobOrder.installationStreet;
          this.addTask.locationFull += this.data.jobOrder.installationStreet;
          if (this.data.jobOrder.installationCity)
            this.addTask.location += ", " + this.data.jobOrder.installationCity;
          this.addTask.locationFull +=
            ", " + this.data.jobOrder.installationCity;
          if (this.data.jobOrder.installationState)
            this.addTask.location +=
              ", " + this.data.jobOrder.installationState;
          this.addTask.locationFull +=
            ", " + this.data.jobOrder.installationState;
          if (this.data.jobOrder.installationPostalCode)
            this.addTask.location +=
              ", " + this.data.jobOrder.installationPostalCode;
          this.addTask.locationFull +=
            ", " + this.data.jobOrder.installationPostalCode;
          if (this.data.jobOrder.installationCountry)
            this.addTask.location +=
              ", " + this.data.jobOrder.installationCountry;
          this.addTask.locationFull +=
            ", " + this.data.jobOrder.installationCountry;
          this.addTask.placeId = this.data.jobOrder.placeId;
          /******  Preeti Comment out this code as it is not required due to calling many time (23 Aug 2024) */
          // var geocoder = new google.maps.Geocoder();
          // geocoder.geocode(
          //   { placeId: this.addTask.placeId },
          //   (results: any, status: any) => {
          //     if (status == google.maps.GeocoderStatus.OK) {
                this.addTask.latitude = this.data.jobOrder.installationLatitude;
                this.addTask.longitude = this.data.jobOrder.installationLongitude;
          //     }
          //   }
          // );
           /******  Preeti Comment out this code as it is not required due to calling many time */
        } else {
          if (this.data.jobOrder.customer != null) {
            // if (
            //   this.data.jobOrder.customer.billingLatitude !== null &&
            //   this.data.jobOrder.customer.billingLongitude !== null &&
            //   this.data.jobOrder.customer.billingLatitude != "" &&
            //   this.data.jobOrder.customer.billingLongitude != ""
            // ) {
              this.addTask.location = "";
              this.addTask.locationFull = "";
              this.addTask.customer =  this.data.jobOrder.customer.id
              
              this.getCustomerDetailById()
              // if (this.data.jobOrder.customer.billingStreet != "") {
              //   this.addTask.location +=
              //     this.data.jobOrder.customer.billingStreet;
              //   this.addTask.locationFull +=
              //     this.data.jobOrder.customer.billingStreet;
              // }
              // if (this.data.jobOrder.customer.billingCity != "") {
              //   this.addTask.location +=
              //     ", " + this.data.jobOrder.customer.billingCity;
              //   this.addTask.locationFull +=
              //     ", " + this.data.jobOrder.customer.billingCity;
              // }
              // if (this.data.jobOrder.customer.billingState != "") {
              //   this.addTask.location +=
              //     ", " + this.data.jobOrder.customer.billingState;
              //   this.addTask.locationFull +=
              //     ", " + this.data.jobOrder.customer.billingState;
              // }
              // if (this.data.jobOrder.customer.billingPostcode != "") {
              //   this.addTask.location +=
              //     ", " + this.data.jobOrder.customer.billingPostcode;
              //   this.addTask.locationFull +=
              //     ", " + this.data.jobOrder.customer.billingPostcode;
              // }
              // if (this.data.jobOrder.customer.billingCountry != "") {
              //   this.addTask.location +=
              //     ", " + this.data.jobOrder.customer.billingCountry;
              //   this.addTask.locationFull +=
              //     ", " + this.data.jobOrder.customer.billingCountry;
              // }
              // this.addTask.latitude =
              //   this.data.jobOrder.customer.billingLatitude;
              // this.addTask.longitude =
              //   this.data.jobOrder.customer.billingLongitude;
           // }
          }

          console.log(this.addTask, "=======");
        }
      }
      if (this.addTask.jobOrder && this.addTask.jobOrder.customer) {
        this.addTask.customer = this.addTask.jobOrder.customer.id;
        this.addTask.customerName = this.addTask.jobOrder.customer;
        this.selectedCustomer = this.addTask.jobOrder.customer;
      }

      if (this.data.customerId && this.data.customerId !== "") {
        console.log(this.data);
        this.addTask.customer = this.data.id;
        this.addTask.customerName = this.data;
        this.selectedCustomer = this.data;
        console.log(this.selectedCustomer);
              
        this.getCustomerDetailById()
      
      }
      var requiredSkill = {general : true, skill1 : false, skill2 : false, skill3 : false};
      if (this.addTask.requiredSkill) {
        let splitSkills = this.addTask.requiredSkill.split(",");
        if (splitSkills.length && _.includes(splitSkills, "GENERAL")) {
          requiredSkill.general = true;
        }
        if (splitSkills.length && _.includes(splitSkills, "1")) {
          requiredSkill.skill1 = true;
        }
        if (splitSkills.length && _.includes(splitSkills, "2")) {
          requiredSkill.skill2 = true;
        }
        if (splitSkills.length && _.includes(splitSkills, "3")) {
          requiredSkill.skill3 = true;
        }
      }
      this.addTask.skills = requiredSkill;

      if (!this.addTask.jobOrder.team && this.comingFrom == "JO") {
        this.addTask.team = { id: null };
      }

      this.addTask.priority = this.allPriorities.find(
        (prio: any) => prio.value == "Medium"
      ).value;

      this.selectedPriority = this.allPriorities.find(
        (prio: any) => prio.value == "Medium"
      );
      console.log(this.addTask, "==^^^^^^===", this.selectedPriority);
      let buffer = JSON.parse(this.auth.getUserBuffer()!);
      let startBuffer = buffer.start;
      let endBuffer = buffer.end;
      this.addTask.startBuffer = startBuffer;
      this.addTask.endBuffer = endBuffer;
      this.validateTimeFields(false);

      if (this.addTask.generateJobOrder) {
        console.log(
          "+++++++++++++++++++++",
          this.addTask.jobOrder.jobOrderNumber
        );
        this.addTask.selectJobOrder = false;
        this.addTaskFullForm.controls.jobOrder.disable();
      }

      this.changeEndTime();
    }

    if(this.mode == "add" && this.data.taskTemplateId)
    {
      this.gettaskTemplate(this.data.taskTemplateId)
    }

    if (this.assignMethod) {
      this.task.assignMethod = this.assignMethod;
    }

    // null select
    const nullSelect = [
      {
        id: null,
        name: "Select",
        priority: "null",
      },
    ];

    // get all departments
    this.skillsService.getAllDepartments().subscribe((res: any) => {
      this.departments = res;
    });

    // get all roles
    this.skillsService.getAllCategories().subscribe((res: any) => {
      this.roles = res;
    });

    this.setJOFunction();
    this.selectJOFunction();

    setTimeout(() => {
      this.timeFirstSet = true;
    }, 500);
  }

  private processPermission() {
    if (this.mode === 'add') {
      this.permissions.siteShow = this.perm.capable('site', 'create');
      this.permissions.projectShow = this.perm.capable('project', 'create');
    }


    if (this.mode === 'edit') {
      this.permissions.siteShow = this.perm.capable('site', 'update');
      this.permissions.projectShow = this.perm.capable('project', 'update');
    }
  }

  /******************************************** on change task priority ******************************************/
  public changeTaskPriority($event: any) {
    this.addTask.priority = $event.value.value;
  }
  /******************************************** update validation time fields ******************************************/
  public validateTimeFields(val: any) {
    this.addTask.adhoc = val;
    console.log(this.addTask.adhoc, "====");
    if (this.addTask.adhoc) {
      this.addTaskFullForm.controls["startTime"].clearValidators();
      this.addTaskFullForm.controls["endTime"].clearValidators();
      this.addTaskFullForm.controls["startTime"].updateValueAndValidity();
      this.addTaskFullForm.controls["endTime"].updateValueAndValidity();
    } else {
      this.addTaskFullForm.controls["startTime"].setValidators([
        Validators.required,
      ]);
      this.addTaskFullForm.controls["endTime"].setValidators([
        Validators.required,
      ]);
      this.addTaskFullForm.controls["startTime"].updateValueAndValidity();
      this.addTaskFullForm.controls["endTime"].updateValueAndValidity();
    }
  }

  /******************************************** lock time ******************************************/
  public toggleFixed(val: any) {
    this.addTask.fixed = val;
  }

  /******************************************** change end time when start time adjusted ******************************************/
  public changeEndTime() {
    if (this.timeFirstSet && !this.addTask.adhoc && !this.data.taskTemplateId) {
      if (this.mode == "add" || this.mode == "add&back") {
        setTimeout(() => {
          const start = moment(this.addTask.startTime, "h:mm A").format("H:mm");
          const endTime = moment(
            moment().format("YYYY-MM-DD") + " " + start
          ).add(60, "minutes");
          this.addTask.endTime = endTime.toDate();
        }, 500);
      } else {
        setTimeout(() => {
          const difference = this.util.durationFormatter(
            this.addTask.startDateTime,
            this.addTask.endDateTime
          );
          const differenceSplit = difference.split(" ");
          if (differenceSplit.length == 2) {
            if (differenceSplit[1] == "hour" || differenceSplit[1] == "hours") {
              var hrs: any = differenceSplit[0];
              var mins: any = 0;
            } else {
              var hrs: any = 0;
              var mins: any = differenceSplit[0];
            }
          } else {
            var hrs: any = differenceSplit[0];
            var mins: any = differenceSplit[2];
          }
          const start = moment(this.addTask.startTime, "h:mm A").format("H:mm");
          let endTime = moment(moment().format("YYYY-MM-DD") + " " + start).add(
            { hours: hrs, minutes: mins }
          );
          this.addTask.endTime = endTime.toDate();
        }, 500);
      }
    }
  }

  /******************************************** get customer data table ******************************************/
  public onKeyCustomerInput(event: any) {
    let k = event.keyCode;

    let query = event.query as string;

    if (k == 8) {
      this.clearValue("customer");
    } else {
      if (query && query.length > 2) {
        this.customersService.getAllCustomerByName(query).subscribe(
          (data: any) => {
            this.customers = data;
          },
          (error) => this.errorUtil.setErrorMessage(error)
        );
      }
    }
  }

  /******************************************** clear customer ******************************************/
  public clearValue(what: string) {
    if (what === "customer") {
      this.addTask.customer = null;
      this.addTask.customerName = null;
      this.selectedCustomer = null;
      this.customers = [];
      this.addTask.latitude = null;
      this.addTask.longitude = null;
      this.addTask.location = null;
      this.addTask.locationFull = null;

    }

    if (what === "site") {
      this.selectedSite = null;
      this.addTask.site.id = null;
    }

    if (what === "project") {
      this.selectedProject = null;
      this.addTask.project.id = null;
    }
  }

  /******************************************** on blur ******************************************/
  public onvalidCustomer() {
    if (this.addTask.customer == null) {
      //  this.customerName=null
      this.selectedCustomer = null;
    }
  }

  /******************************************** display customer table ******************************************/
  public displayCustomerTable() {
    this.showCustomerTable = true;
  }

  /******************************************** hide customer table ******************************************/
  public hideCustomerTable() {
    this.showCustomerTable = false;
  }

  /******************************************** on customer select ******************************************/
  public onSelectValue(value: any) {
    this.addTask.customer = value.id;
    this.addTask.customerName = value;

    this.addTaskFullForm.patchValue({ customer: value.name });

    this.selectedCustomer = value;
    this.getCustomerDetailById()
    this.showCustomerTable = false;
    this.customers = [];

    this.hideCustomerTable();
  }
/********************************************************** Get Customer Detail *****************************************************/
getCustomerDetailById() {
  this.customersService.getCustomerById(this.addTask.customer)
    .subscribe((customerDetailsResponse: any) => {
      console.log(customerDetailsResponse);
      this.addTask.customerName = customerDetailsResponse;
      this.getCustomerBillingAddress();
      });
}

  // - check??
  public getCustomerBillingAddress() {
    if (
      this.addTask.customer &&
      this.addTask.customer !== null &&
      this.addTask.customer.id != ""
    ) {
      
      console.log("Call Jo", this.addTask.customer);

     // if (!this.addTask.location) {
        console.log(this.addTask.customerName);
        console.log(this.addTask.location, "====>>>>>");
        if (this.addTask.customerName.billingLatitude !== null && this.addTask.customerName.billingLongitude !== null && this.addTask.customerName.billingLatitude != "" && this.addTask.customerName.billingLongitude != "") {
          this.addTask.location = "";
          this.addTask.locationFull = "";
          if (this.addTask.customerName.billingStreet != "")
            this.addTask.location += this.addTask.customerName.billingStreet;
             this.addTask.locationFull += this.addTask.customerName.billingStreet;
          
          if (this.addTask.customerName.billingCity != "")
            this.addTask.location += ", " + this.addTask.customerName.billingCity;
          this.addTask.locationFull +=
            ", " + this.addTask.customerName.billingCity;
          if (this.addTask.customerName.billingState != "")
            this.addTask.location +=
              ", " + this.addTask.customerName.billingState;
          this.addTask.locationFull +=
            ", " + this.addTask.customerName.billingState;
          if (this.addTask.customerName.billingPostcode != "")
            this.addTask.location +=
              ", " + this.addTask.customerName.billingPostcode;
          this.addTask.locationFull +=
            ", " + this.addTask.customerName.billingPostcode;
          if (this.addTask.customerName.billingCountry != "")
            this.addTask.location +=
              ", " + this.addTask.customerName.billingCountry;
          this.addTask.locationFull +=
            ", " + this.addTask.customerName.billingCountry;

          this.addTask.latitude = this.addTask.customerName.billingLatitude;
          this.addTask.longitude = this.addTask.customerName.billingLongitude;
          console.log(this.addTask);
        }




      }
    ///}
  }

  /******************************************** on select autocomplete location ******************************************/
  public manualSearch($event: any) {
    console.log(
      "MANUAL SEARCH",
      this.addTask,
      this.homePlaceChanged,
      $event
    );

    // setTimeout(() => {
    // if (!this.homePlaceChanged) {
    //   this.addTask.latitude = "";
    //   this.addTask.longitude = "";
    //   this.addTask.locationFull = "";
    //   this.addTask.placeId = "";
    //   let geocoder = new google.maps.Geocoder();
    //   let address = $event.target.value;
    //   geocoder.geocode({ address: address }, (results: any, status: any) => {
    //     console.log(results, status, address);

    //     if (status == google.maps.GeocoderStatus.OK) {
    //       if (results[0]) {
    //         this.addTask.latitude = results[0].geometry.location.lat();
    //         this.addTask.longitude = results[0].geometry.location.lng();
    //         this.addTask.locationFull = results[0].formatted_address;
    //         this.addTask.placeId = results[0].place_id;
    //       } else {
    //       }
    //     } else {
    //     }
    //   });
    // }
    // }, 1000);
  }

  public getPlaceApiCall(event: any) {
    let field = document.getElementById("input_TaskLocField");
    let val = event.target.value;

    if (val.length < 3) {
      google.maps.event.removeListener(this.autocompleteLsr);
      google.maps.event.clearInstanceListeners(field);

      if (this.autocomplete) {
        google.maps.event.clearInstanceListeners(this.autocomplete);
      }

      $(".pac-container").remove();
      this.flag = true;
    } else if (this.flag) {
      this.callGoogleAutocomplete();
      this.flag = false;
    }
  }

  /******************************************** call google auto complete ******************************************/
  private callGoogleAutocomplete() {
    //load Places Autocomplete(search time calling)
    if (this.autocomplete) {
      google.maps.event.clearInstanceListeners(this.autocomplete);
    }

    this.autocomplete = new google.maps.places.Autocomplete(
      this.homeAddressSearchElementRef.nativeElement,
      {
        // Specify only the fields you need
        fields: ['place_id', 'geometry', 'name', 'formatted_address','types'],
      }
    );

    this.initLisner();
  }

  private initLisner() {
    if (this.autocompleteLsr) {
      google.maps.event.removeListener(this.autocompleteLsr);
    }

    this.autocompleteLsr = this.autocomplete.addListener(
      "place_changed",
      () => {
        this.homePlaceChanged = true;
        this.addTask.latitude = "";
        this.addTask.longitude = "";
        this.addTask.placeId = "";
        this.addTask.location = "";
        this.addTask.locationName = "";
        this.addTask.locationFull = "";
        this.ngZone.run(() => {
          //get the place result
          let place = this.autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.addTask.latitude = place.geometry.location.lat();
          this.addTask.longitude = place.geometry.location.lng();
          this.addTask.placeId = place.place_id;
          this.addTask.location = place.formatted_address;
          if (place.types.includes("establishment")) {
            this.addTask.locationName = place.name;
            this.addTask.locationFull =
              place.name + ", " + place.formatted_address;
          } else {
            this.addTask.locationName = null;
            this.addTask.locationFull = place.formatted_address;
          }
        });
        setTimeout(() => {
          this.homePlaceChanged = false;
        }, 1000);
      }
    );
  }

  public setStartLocation(val: any) {
    var geocoder = new google.maps.Geocoder();
    if (val == "current") {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          var latitude = position.coords.latitude;
          var longitude = position.coords.longitude;
          //console.log(position);
          var latlng = { lat: latitude, lng: longitude };
          geocoder.geocode(
            { location: latlng },
            (results: any, status: any) => {
              if (status == google.maps.GeocoderStatus.OK) {
                if (results[0]) {
                  results = _.remove(results, function (n: any) {
                    return n.geometry.location_type === "APPROXIMATE";
                  });
    
                  this.addTask.locationName = null;
                  this.addTask.locationFull = results[0].formatted_address;
                  this.addTask.location = results[0].formatted_address;
                  this.addTask.placeId = results[0].place_id;
                  this.addTask.latitude = latitude;
                  this.addTask.longitude = longitude;
                } else {
                  //console.log('No results found');
                }
              } else {
                //console.log('Geocoder failed due to: ' + status);
              }
            }
          );
        });
      }
    } else if (val == "home") {
      var homeAddress: any = this.auth.getHomeAddress();
      if (homeAddress) {
        this.addTask.locationName = null;
        this.addTask.location = homeAddress.address;
        this.addTask.locationFull = homeAddress.address;
        this.addTask.placeId = homeAddress.placeId;
        this.addTask.latitude = homeAddress.lat;
        this.addTask.longitude = homeAddress.lng;
      } else {
        // this.toaster.warn("", "Please set your Home Address in your profile");
      }
    } else if (val == "office") {
      var officeAddress: any = this.auth.getOfficeAddress();
      console.log("---", officeAddress);
      if (officeAddress) {
        this.addTask.locationName = null;
        this.addTask.location = officeAddress.address;
        this.addTask.locationFull = officeAddress.address;
        this.addTask.placeId = officeAddress.placeId;
        this.addTask.latitude = officeAddress.lat;
        this.addTask.longitude = officeAddress.lng;
      } else {
        // this.toaster.warn("", "Please set your Office Address in your profile");
      }
    }
  }

  private getTeams() {
    this.push(
      this.teamService
        .getTeamsByUserIdForTask(this.auth.getUserId())
        .subscribe((teams: any) => {
          this.teams = teams;
        })
    );
  }

  public setJOFunction() {
    console.log(this.addTask.generateJobOrder, "=====");
    if (this.addTask.generateJobOrder) {
      this.addTask.selectJobOrder = false;
      this.addTaskFullForm.controls.jobOrder.disable();
    }
  }

  private selectJOFunction() {
    console.log(this.addTask.generateJobOrder, "===== Step 2 ", this.mode);
    if (this.mode === "add" || this.mode == "add&back") {
      console.log("===== Step 3");
      if (this.addTask.selectJobOrder) {
        console.log("===== Step 4");
        this.getJobOrdersByCustomer();
        this.addTask.generateJobOrder = false;
        this.addTaskFullForm.controls.jobOrder.enable();
      } else {
        this.addTaskFullForm.controls.jobOrder.disable();
      }
    } else {
      console.log("===== Step Last");
      this.getJobOrdersByCustomer();
      this.addTask.selectJobOrder = true;
      this.addTaskFullForm.controls.jobOrder.enable();
    }
  }

  public getJobOrdersByCustomer() {
    console.log(this.addTask.jobOrder, " && ", this.addTask.jobOrder.customer);
    if (this.addTask.jobOrder && this.addTask.jobOrder.customer) {
      console.log(
        this.addTask.customer,
        " && ",
        this.addTask.jobOrder.customer.id
      );
      if (this.addTask.customer !== this.addTask.jobOrder.customer.id) {
        setTimeout(() => {
          this.addTask.jobOrder = { id: null };
          this.addTask.joName = null;
          this.jobOrders = [];
        }, 0);
      }
    }
  }

  public onSubmit(submitType: any) {
    this.submitTouched = true;

    // validation form
    if (!this.addTaskFullForm.valid) {
      for (var i in this.addTaskFullForm.controls) {
        this.addTaskFullForm.controls[i].markAsTouched();
      }
      this.submitTouched = false;
      return false;
    }

    // validation for end time after start time
    const date = moment(
      this.addTask.date,
      this.dateConfig.dateInputFormat
    ).format("YYYY-MM-DD");

    let startTime = null;
    let endTime = null;

    if (
      typeof this.addTask.startTime === "string" &&
      typeof this.addTask.endTime === "string"
    ) {
      startTime = this.addTask.startTime;
      endTime = this.addTask.endTime;

      startTime = moment(startTime, "h:mm A").toISOString();
      endTime = moment(endTime, "h:mm A").toISOString();
    } else {
      startTime = moment(this.addTask.startTime).toISOString();
      endTime = moment(this.addTask.endTime).toISOString();
    }
    console.log(this.addTask.startTime, "====", this.addTask.endTime);
    console.log(startTime, endTime);

    const stime = moment(this.addTask.startTime, "h:mm A").format("H:mm");
    var startDateTime = moment(
      moment(this.addTask.date).format("YYYY-MM-DD") + " " + stime
    );
    var pickUpStartTimeData: any = moment.tz( startDateTime, this.auth.getUserTimezone());
    var finalStartTime1: any = pickUpStartTimeData.toDate();

    const etime = moment(this.addTask.endTime, "h:mm A").format("H:mm");
    var endDateTime = moment(
      moment(this.addTask.date).format("YYYY-MM-DD") + " " + etime
    );
    var pickUpStartTimeData: any = moment.tz( endDateTime, this.auth.getUserTimezone() );
    var finalEndTime1: any = pickUpStartTimeData.toDate();

    if (!moment(finalStartTime1).isBefore(moment(finalEndTime1)) && !this.addTask.adhoc  ) {
      this.errorUtil.setErrorMessage( 200,null, this.translate?.task.messages.endTimeAfterStartTime ,  "warn", 3000 );

      this.submitTouched = false;
      return false;
    }

    let tempSkills = this.addTask.userDynamicSkillList;

    if (submitType === "quick") {
      // let finalStartTime = moment(startTime);

      // let finalEndTime = moment(endTime);

      // var finalStartTime = this.util.timeFormatter(this.addTask.startTime, date);
      //var finalEndTime = this.util.timeFormatter(this.addTask.endTime, date);

      const stime = moment(this.addTask.startTime, "h:mm A").format("H:mm");
      var startDateTime = moment( moment(this.addTask.date).format("YYYY-MM-DD") + " " + stime);
      var pickUpStartTimeData: any = moment.tz( startDateTime, this.auth.getUserTimezone() );
      var finalStartTime2: any = pickUpStartTimeData.toDate();

      const etime = moment(this.addTask.endTime, "h:mm A").format("H:mm");
      var endDateTime = moment( moment(this.addTask.date).format("YYYY-MM-DD") + " " + etime );
      var pickUpStartTimeData: any = moment.tz(endDateTime,this.auth.getUserTimezone() );
      var finalEndTime2: any = pickUpStartTimeData.toDate();

      console.log(stime, "===", etime);
      var finalStartTime : any= this.util.timeFormatter(stime, date);
      var finalEndTime  : any= this.util.timeFormatter(etime, date);

      console.log(finalStartTime, "===", finalEndTime);

      if (this.mode === "edit" || this.mode === "edit&back") {
        let skillsString = "";
        for (let key in this.addTask.skills) {
          if (this.addTask.skills.hasOwnProperty(key)) {
            if (this.addTask.skills[key]) {
              if (key == "general") {
                skillsString += "GENERAL";
              } else if (key == "skill1") {
                skillsString += ",1";
              } else if (key == "skill2") {
                skillsString += ",2";
              } else if (key == "skill3") {
                skillsString += ",3";
              }
            }
          }
        }

        if (this.addTask.startBuffer == 0 && this.addTask.endBuffer == 0) {
          this.addTask.hasBuffer = false;
        } else if (
          this.addTask.startBuffer != 0 ||
          this.addTask.endBuffer != 0
        ) {
          this.addTask.hasBuffer = true;
        }

        if (!this.addTask.placeId) {
          this.addTask.location = this.addTask.locationFull;
        }

        this.formData = {
          assignStatus: this.addTask.assignStatus,
          description: this.addTask.description,
          endDateTime: finalEndTime,
          endDateTime2: finalEndTime2,
          fixed: this.addTask.fixed,
          hasBuffer: this.addTask.hasBuffer,
          location: this.addTask.location,
          locationName: this.addTask.locationName,
          latitude: this.addTask.latitude,
          longitude: this.addTask.longitude,
          priority: this.addTask.priority,
          startDateTime: finalStartTime,
          startDateTime2: finalStartTime2,
          taskName: this.addTask.taskName,
          taskStatus: this.addTask.taskStatus,
          requiredSkill: skillsString,
          startBuffer: this.addTask.startBuffer,
          endBuffer: this.addTask.endBuffer,
          placeId: this.addTask.placeId,
          taskType: this.addTask.taskType,
          assignedUsersList: this.addTask.assignedUsersList,
          userDynamicSkillList: tempSkills,
          repeatFrequency: this.addTask.repeatFrequency,
          generateJobOrder: this.addTask.generateJobOrder,
          selectJobOrder: this.addTask.selectJobOrder,
          showServiceChecklist: this.addTask.showServiceChecklist,
          serviceChecklistRequired: this.addTask.serviceChecklistRequired,
          showCustomerServiceReport: this.addTask.showCustomerServiceReport,
          customerServiceReportRequired:
            this.addTask.customerServiceReportRequired,
          showHandoverCertificate: this.addTask.showHandoverCertificate,
          handoverCertificateRequired: this.addTask.handoverCertificateRequired,
          comingFrom: this.comingFrom,
          site: { id: this.addTask?.site?.id ?? null },
          project: { id: this.addTask?.project?.id ?? null },
          checklistRecordId:this.addTask.checklistRecordId ?? null,
          pmvId:this.addTask.pmvId ?? null,
          taskNumber: this.addTask.taskNumber,
          csrLogNo: this.addTask.csrLogNo,
          csrSalesOrder: this.addTask.csrSalesOrder
        };


        if (this.addTask && this.addTask.site && this.addTask.site.id) {
          this.formData = Object.assign(this.formData, {
            site: { id: this.addTask.site.id },
          });
        } else {
          this.formData.site = null;
        }
  
        if (this.addTask && this.addTask.project && this.addTask.project.id) {
          this.formData = Object.assign(this.formData, {
            project: { id: this.addTask.project.id },
          });
        } else {
          this.formData.project = null;
        }  

      } else if (this.mode === "add" || this.mode == "add&back") {
        let skillsString = "";
        for (let key in this.addTask.skills) {
          if (this.addTask.skills.hasOwnProperty(key)) {
            if (this.addTask.skills[key]) {
              if (this.addTask.skills[key]) {
                if (key == "general") {
                  skillsString += "GENERAL";
                } else if (key == "skill1") {
                  skillsString += ",1";
                } else if (key == "skill2") {
                  skillsString += ",2";
                } else if (key == "skill3") {
                  skillsString += ",3";
                }
              }
            }
          }
        }

        // check if got buffer
        if (this.addTask.startBuffer == 0 && this.addTask.endBuffer == 0) {
          this.addTask.hasBuffer = false;
        } else if (
          this.addTask.startBuffer != 0 ||
          this.addTask.endBuffer != 0
        ) {
          this.addTask.hasBuffer = true;
        }

        // set location
        if (!this.addTask.placeId) {
          this.addTask.location = this.addTask.locationFull;
        }
        if (this.addTask.description != null) {
          this.addTask.description =
            this.addTask.description == "" ? null : this.addTask.description;
        }
        this.formData = {
          description: this.addTask.description,
          endDateTime: finalEndTime,
          endDateTime2: finalEndTime2,
          fixed: this.addTask.fixed,
          hasBuffer: this.addTask.hasBuffer,
          location: this.addTask.location,
          locationName: this.addTask.locationName,
          latitude: this.addTask.latitude,
          longitude: this.addTask.longitude,
          priority: this.addTask.priority,
          startDateTime: finalStartTime,
          startDateTime2: finalStartTime2,
          taskName: this.addTask.taskName,
          requiredSkill: skillsString,
          taskStatus: "OPEN",
          startBuffer: this.addTask.startBuffer,
          endBuffer: this.addTask.endBuffer,
          placeId: this.addTask.placeId,
          taskType: this.addTask.taskType,
          assignedUsersList: [
            { owner: true, assignedTo: { id: this.auth.getUserId() } },
          ],
          userDynamicSkillList: tempSkills,
          repeatFrequency: this.addTask.repeatFrequency,
          generateJobOrder: this.addTask.generateJobOrder,
          selectJobOrder: this.addTask.selectJobOrder,
          showServiceChecklist: this.addTask.showServiceChecklist,
          serviceChecklistRequired: this.addTask.serviceChecklistRequired,
          showCustomerServiceReport: this.addTask.showCustomerServiceReport,
          customerServiceReportRequired:
            this.addTask.customerServiceReportRequired,
          showHandoverCertificate: this.addTask.showHandoverCertificate,
          handoverCertificateRequired: this.addTask.handoverCertificateRequired,
          comingFrom: this.comingFrom,
          site: { id: this.addTask?.site?.id ?? null },
          project: { id: this.addTask?.project?.id ?? null },
          checklistRecordId:this.addTask.checklistRecordId ?? null,
          pmvId:this.addTask.pmvId ?? null,
          csrLogNo: this.addTask.csrLogNo,
          csrSalesOrder: this.addTask.csrSalesOrder
        };

        if (this.addTask && this.addTask.site && this.addTask.site.id) {
          this.formData = Object.assign(this.formData, {
            site: { id: this.addTask.site.id },
          });
        } else {
          this.formData.site = null;
        }
  
        if (this.addTask && this.addTask.project && this.addTask.project.id) {
          this.formData = Object.assign(this.formData, {
            project: { id: this.addTask.project.id },
          });
        } else {
          this.formData.project = null;
        }  
      }

      // set customer
      if (this.addTask.customer && this.addTask.customer !== null) {
        this.formData.customer = { id: this.addTask.customer };
      }
debugger
      // set team
      if (this.addTask.team && this.addTask.team.id) {
        this.formData.team = { id: this.addTask.team.id };
        let teamName: any = _.filter(this.teams, (o) => {
          return o.id == this.addTask.team.id;
        });
        teamName.length > 0 ? this.formData.displayTeamName = teamName[0].name : this.formData.displayTeamName = null;
      }

      if (this.addTask.jobOrder && this.addTask.jobOrder.id !== null) {
        this.formData.jobOrder = { id: this.addTask.jobOrder.id };
        this.formData.joName = this.addTask.joName;
      }
      if (this.addTask.repeatTillDate) {
        this.formData.repeatTillDate = null;
      }

      if (this.addTask && this.addTask.site && this.addTask.site.id) {
        this.formData = Object.assign(this.formData, {
          site: { id: this.addTask.site.id },
        });
      } else {
        this.formData.site = null;
      }

      if (this.addTask && this.addTask.project && this.addTask.project.id) {
        this.formData = Object.assign(this.formData, {
          project: { id: this.addTask.project.id },
        });
      } else {
        this.formData.project = null;
      }

      if (
        this.addTask.description &&
        (this.addTask.description === "" ||
          this.addTask.description.length === 0)
      ) {
        this.addTask.description = null;
      }
      if(this.addTask.tmplTaskId){
        this.formData.tmplTaskId = this.addTask.tmplTaskId;
      }

      if (this.mode === "edit" || this.mode === "edit&back") {
        this.formData.id = this.data.id;
        this.formData.taskNumber=this.data.taskNumber

        this.tasksService.editTask(this.formData).subscribe((taskRes: any) => {
          //console.log("Response ", res);
          if (taskRes.status === 200 || taskRes.status === 201) {
            let req = {
              routeDate: date,
              isOptimized: false,
              user: {
                id: this.auth.getUserId(),
              },
            };

            this.tasksService.createRouteOptimization(req).subscribe((res: any) => {
                //console.log("Response ", res);
                if (res.status === 200 || res.status === 201) {
                  this.modalRef.hide();
                  this.errorUtil.setErrorMessage(200, null, this.translate?.general.messages.updatedSuccessfully,"success", 3000);  
                } else {

                  var errorTitle = res.error.title
                  if (res.error.type == 'dynamic-lang-error') {
                    var data = res.error.instance + ".validationBE." + res.error.detail;
                    var message = this.languageTranslateService.getNestedValue(this.translate, data);
                    errorTitle = message
                  }
        
                  this.errorUtil.setErrorMessage(
                    res.status,
                    null,
                    errorTitle,
                    "error",
                    2000
                  );
                }
              },(err) => {
                //this.spinner.hide()
               const title = this.errorUtil.processErrorTitle(err.error,this.translate)
               this.errorUtil.setErrorMessage(err.status, null ,  title, 'error',3000);
               this.submitTouched = false;
            });
            }
            else {

              var errorTitle = taskRes.error.title
              if (taskRes.error.type == 'dynamic-lang-error') {
                var data = taskRes.error.instance + ".validationBE." + taskRes.error.detail;
                var message = this.languageTranslateService.getNestedValue(this.translate, data);
                errorTitle = message
              }
    
              this.errorUtil.setErrorMessage(
                taskRes.status,
                null,
                errorTitle,
                "error",
                2000
              );
            }
            
          }, (err) => {
           // this.spinner.hide()
           const title = this.errorUtil.processErrorTitle(err.error,this.translate)
           this.errorUtil.setErrorMessage(err.status, null ,  title, 'error',3000);
           this.submitTouched = false;
        });

      } else if (this.mode === "add" || this.mode == "add&back") {
        console.log('QUICK ', this.formData,"====",this.addTask);
////debugger
        this.tasksService.createTask(this.formData).subscribe((taskRes: any) => {
            console.log("Add Response ", taskRes);
            if (taskRes.status === 200 || taskRes.status === 201) {
              let req = {
                routeDate: date,
                isOptimized: false,
                user: {
                  id: this.auth.getUserId(),
                },
              };

              this.tasksService
                .createRouteOptimization(req)
                .subscribe((res: any) => {
                  //console.log("Response ", res);
                  if (res.status === 200 || res.status === 201) {
                    this.modalRef.hide();

                    this.errorUtil.setErrorMessage(
                      200,
                      null,
                       this.translate?.general.messages.savedSuccessfully,
                      "success",
                      3000
                    );

                    this.submitTouched = false;

                    this.util.openTaskVIewPage(taskRes.body.id);
                  } else {

                    var errorTitle = res.error.title
                    if (res.error.type == 'dynamic-lang-error') {
                      var data = res.error.instance + ".validationBE." + res.error.detail;
                      var message = this.languageTranslateService.getNestedValue(this.translate, data);
                      errorTitle = message
                    }
          
                    this.errorUtil.setErrorMessage(
                      res.status,
                      null,
                      errorTitle,
                      "error",
                      2000
                    );
                  }
                },(err) => {
                  //this.spinner.hide()
                 const title = this.errorUtil.processErrorTitle(err.error,this.translate)
                 this.errorUtil.setErrorMessage(err.status, null ,  title, 'error',3000);
                 //this.submitTouched = false;
              });
            } else {

              var errorTitle = taskRes.error.title
              if (taskRes.error.type == 'dynamic-lang-error') {
                var data = taskRes.error.instance + ".validationBE." + taskRes.error.detail;
                var message = this.languageTranslateService.getNestedValue(this.translate, data);
                errorTitle = message
              }
    
              this.errorUtil.setErrorMessage(
                taskRes.status,
                null,
                errorTitle,
                "error",
                2000
              );
            }
          }, (err) => {
            //this.spinner.hide()
           const title = this.errorUtil.processErrorTitle(err.error,this.translate)
           this.errorUtil.setErrorMessage(err.status, null ,  title, 'error',3000);
           //this.submitTouched = false;
        });
      }

      // for smart assign
    } else if (submitType === "smart-assign") {
      // let finalStartTime = moment(startTime);

      //let finalEndTime = moment(endTime);
      const date = moment(this.addTask.date).format('YYYY-MM-DD');

       const stime = moment(this.addTask.startTime, "h:mm A").format("H:mm");
      var startDateTime = moment( moment(this.addTask.date).format("YYYY-MM-DD") + " " + stime  );
      var pickUpStartTimeData: any = moment.tz( startDateTime, this.auth.getUserTimezone()  );
      var finalStartTime2: any = pickUpStartTimeData.toDate();

      const etime = moment(this.addTask.endTime, "h:mm A").format("H:mm");
      var endDateTime = moment( moment(this.addTask.date).format("YYYY-MM-DD") + " " + etime );
      var pickUpStartTimeData: any = moment.tz( endDateTime, this.auth.getUserTimezone() );
      var finalEndTime2: any = pickUpStartTimeData.toDate();
      console.log(stime, "===", etime);
      var finalStartTime : any= this.util.timeFormatter(stime, date);
      var finalEndTime  : any= this.util.timeFormatter(etime, date);

      console.log(finalStartTime, "===", finalEndTime);

      let skillsString = "";

      for (let key in this.addTask.skills) {
        if (this.addTask.skills.hasOwnProperty(key)) {
          if (this.addTask.skills[key]) {
            if (key == "general") {
              skillsString += "GENERAL";
            } else if (key == "skill1") {
              skillsString += ",1";
            } else if (key == "skill2") {
              skillsString += ",2";
            } else if (key == "skill3") {
              skillsString += ",3";
            }
          }
        }
      }

      if (this.addTask.startBuffer == 0 && this.addTask.endBuffer == 0) {
        this.addTask.hasBuffer = false;
      } else if (this.addTask.startBuffer != 0 || this.addTask.endBuffer != 0) {
        this.addTask.hasBuffer = true;
      }

      if (!this.addTask.placeId) {
        this.addTask.location = this.addTask.locationFull;
      }

      this.formData = {
        endDateTime: finalEndTime,
        endDateTime2: finalEndTime2,
        location: this.addTask.location,
        locationName: this.addTask.locationName,
        latitude: this.addTask.latitude,
        longitude: this.addTask.longitude,
        startDateTime: finalStartTime,
        startDateTime2: finalStartTime2,
        taskName: this.addTask.taskName,
        taskStatus: "OPEN",
        description: this.addTask.description,
        skills: this.addTask.skills,
        hasBuffer: this.addTask.hasBuffer,
        priority: this.addTask.priority,
        fixed: this.addTask.fixed,
        requiredSkill: skillsString,
        startBuffer: this.addTask.startBuffer,
        endBuffer: this.addTask.endBuffer,
        placeId: this.addTask.placeId,
        adhoc: this.addTask.adhoc,
        duration: this.addTask.duration,
        taskType: this.addTask.taskType,
        assignedUsersList: this.addTask.assignedUsersList,
        userDynamicSkillList: tempSkills,
        repeatFrequency: this.addTask.repeatFrequency,
        generateJobOrder: this.addTask.generateJobOrder,
        selectJobOrder: this.addTask.selectJobOrder,
        showServiceChecklist: this.addTask.showServiceChecklist,
        serviceChecklistRequired: this.addTask.serviceChecklistRequired,
        showCustomerServiceReport: this.addTask.showCustomerServiceReport,
        customerServiceReportRequired:
          this.addTask.customerServiceReportRequired,
        showHandoverCertificate: this.addTask.showHandoverCertificate,
        handoverCertificateRequired: this.addTask.handoverCertificateRequired,
        comingFrom: this.comingFrom,
        site: { id: this.addTask?.site?.id ?? null },
        project: { id: this.addTask?.project?.id ?? null },
        checklistRecordId:this.addTask.checklistRecordId ?? null,
        pmvId:this.addTask.pmvId ?? null,
        csrLogNo: this.addTask.csrLogNo,
        csrSalesOrder: this.addTask.csrSalesOrder
      };

      if (this.addTask.repeatTillDate) {
        this.formData.repeatTillDate = moment(
          this.addTask.repeatTillDate
        ).format("YYYY-MM-DD");
      }

      if (this.addTask.id) {
        this.formData.id = this.addTask.id;
      }

      if (this.addTask.customer && this.addTask.customer !== null) {
        this.formData.customer = { id: this.addTask.customer };
        this.formData.customerName = this.addTask.customerName;
      }

      if (this.addTask.team && this.addTask.team.id) {
        this.formData.team = { id: this.addTask.team.id };
        var teamName: any = _.filter(this.teams, (o) => {
          return o.id == this.addTask.team.id;
        });
        this.formData.displayTeamName = teamName[0].name;
        console.log(this.formData.displayTeamName);
      }

      if (this.addTask.jobOrder && this.addTask.jobOrder.id !== null) {
        this.formData.jobOrder = { id: this.addTask.jobOrder.id };
        this.formData.joName = this.addTask.joName;
      }

      if (this.addTask && this.addTask.site && this.addTask.site.id) {
        this.formData = Object.assign(this.formData, {
          site: { id: this.addTask.site.id },
        });
      } else {
        this.formData.site = null;
      }

      if (this.addTask && this.addTask.project && this.addTask.project.id) {
        this.formData = Object.assign(this.formData, {
          project: { id: this.addTask.project.id },
        });
      } else {
        this.formData.project = null;
      }

      this.formData.parentMode = this.mode;
      this.formData.showClose = this.showClose;
      this.modalRef.hide();
      this.openModal("smart-assign", this.formData);

      this.submitTouched = false;
    } if (submitType === "exact-assign") {
            // let finalStartTime = moment(startTime);

      // let finalEndTime = moment(endTime);

      // var finalStartTime = this.util.timeFormatter(this.addTask.startTime, date);
      //var finalEndTime = this.util.timeFormatter(this.addTask.endTime, date);

      const stime = moment(this.addTask.startTime, "h:mm A").format("H:mm");
      var startDateTime = moment( moment(this.addTask.date).format("YYYY-MM-DD") + " " + stime);
      var pickUpStartTimeData: any = moment.tz( startDateTime, this.auth.getUserTimezone() );
      var finalStartTime2: any = pickUpStartTimeData.toDate();

      const etime = moment(this.addTask.endTime, "h:mm A").format("H:mm");
      var endDateTime = moment( moment(this.addTask.date).format("YYYY-MM-DD") + " " + etime );
      var pickUpStartTimeData: any = moment.tz(endDateTime,this.auth.getUserTimezone() );
      var finalEndTime2: any = pickUpStartTimeData.toDate();

      console.log(stime, "===", etime);
      var finalStartTime : any= this.util.timeFormatter(stime, date);
      var finalEndTime  : any= this.util.timeFormatter(etime, date);

      console.log(finalStartTime, "===", finalEndTime);

      if (this.mode === "edit" || this.mode === "edit&back") {
        let skillsString = "";
        for (let key in this.addTask.skills) {
          if (this.addTask.skills.hasOwnProperty(key)) {
            if (this.addTask.skills[key]) {
              if (key == "general") {
                skillsString += "GENERAL";
              } else if (key == "skill1") {
                skillsString += ",1";
              } else if (key == "skill2") {
                skillsString += ",2";
              } else if (key == "skill3") {
                skillsString += ",3";
              }
            }
          }
        }

        if (this.addTask.startBuffer == 0 && this.addTask.endBuffer == 0) {
          this.addTask.hasBuffer = false;
        } else if (
          this.addTask.startBuffer != 0 ||
          this.addTask.endBuffer != 0
        ) {
          this.addTask.hasBuffer = true;
        }

        if (!this.addTask.placeId) {
          this.addTask.location = this.addTask.locationFull;
        }

        this.formData = {
          assignStatus: this.addTask.assignStatus,
          description: this.addTask.description,
          endDateTime: finalEndTime,
          endDateTime2: finalEndTime2,
          fixed: this.addTask.fixed,
          hasBuffer: this.addTask.hasBuffer,
          location: this.addTask.location,
          locationName: this.addTask.locationName,
          latitude: this.addTask.latitude,
          longitude: this.addTask.longitude,
          priority: this.addTask.priority,
          startDateTime: finalStartTime,
          startDateTime2: finalStartTime2,
          taskName: this.addTask.taskName,
          taskStatus: this.addTask.taskStatus,
          requiredSkill: skillsString,
          startBuffer: this.addTask.startBuffer,
          endBuffer: this.addTask.endBuffer,
          placeId: this.addTask.placeId,
          taskType: this.addTask.taskType,
          assignedUsersList: this.addTask.assignedUsersList,
          userDynamicSkillList: tempSkills,
          repeatFrequency: this.addTask.repeatFrequency,
          generateJobOrder: this.addTask.generateJobOrder,
          selectJobOrder: this.addTask.selectJobOrder,
          showServiceChecklist: this.addTask.showServiceChecklist,
          serviceChecklistRequired: this.addTask.serviceChecklistRequired,
          showCustomerServiceReport: this.addTask.showCustomerServiceReport,
          customerServiceReportRequired:
            this.addTask.customerServiceReportRequired,
          showHandoverCertificate: this.addTask.showHandoverCertificate,
          handoverCertificateRequired: this.addTask.handoverCertificateRequired,
          comingFrom: this.comingFrom,
          site: { id: this.addTask?.site?.id ?? null },
          project: { id: this.addTask?.project?.id ?? null },
          checklistRecordId:this.addTask.checklistRecordId ?? null,
          pmvId:this.addTask.pmvId ?? null,
          taskNumber: this.addTask.taskNumber,
          csrLogNo: this.addTask.csrLogNo,
          csrSalesOrder: this.addTask.csrSalesOrder
        };

        if (this.addTask && this.addTask.site && this.addTask.site.id) {
          this.formData = Object.assign(this.formData, {
            site: { id: this.addTask.site.id },
          });
        } else {
          this.formData.site = null;
        }
  
        if (this.addTask && this.addTask.project && this.addTask.project.id) {
          this.formData = Object.assign(this.formData, {
            project: { id: this.addTask.project.id },
          });
        } else {
          this.formData.project = null;
        }  

      } else if (this.mode === "add" || this.mode == "add&back") {
        let skillsString = "";
        for (let key in this.addTask.skills) {
          if (this.addTask.skills.hasOwnProperty(key)) {
            if (this.addTask.skills[key]) {
              if (this.addTask.skills[key]) {
                if (key == "general") {
                  skillsString += "GENERAL";
                } else if (key == "skill1") {
                  skillsString += ",1";
                } else if (key == "skill2") {
                  skillsString += ",2";
                } else if (key == "skill3") {
                  skillsString += ",3";
                }
              }
            }
          }
        }

        // check if got buffer
        if (this.addTask.startBuffer == 0 && this.addTask.endBuffer == 0) {
          this.addTask.hasBuffer = false;
        } else if (
          this.addTask.startBuffer != 0 ||
          this.addTask.endBuffer != 0
        ) {
          this.addTask.hasBuffer = true;
        }

        // set location
        if (!this.addTask.placeId) {
          this.addTask.location = this.addTask.locationFull;
        }
        if (this.addTask.description != null) {
          this.addTask.description =
            this.addTask.description == "" ? null : this.addTask.description;
        }
        this.formData = {
          description: this.addTask.description,
          endDateTime: finalEndTime,
          endDateTime2: finalEndTime2,
          fixed: this.addTask.fixed,
          hasBuffer: this.addTask.hasBuffer,
          location: this.addTask.location,
          locationName: this.addTask.locationName,
          latitude: this.addTask.latitude,
          longitude: this.addTask.longitude,
          priority: this.addTask.priority,
          startDateTime: finalStartTime,
          startDateTime2: finalStartTime2,
          taskName: this.addTask.taskName,
          requiredSkill: skillsString,
          taskStatus: "OPEN",
          startBuffer: this.addTask.startBuffer,
          endBuffer: this.addTask.endBuffer,
          placeId: this.addTask.placeId,
          taskType: this.addTask.taskType,
          assignedUsersList: [
            { owner: true, assignedTo: { id: this.auth.getUserId() } },
          ],
          userDynamicSkillList: tempSkills,
          repeatFrequency: this.addTask.repeatFrequency,
          generateJobOrder: this.addTask.generateJobOrder,
          selectJobOrder: this.addTask.selectJobOrder,
          showServiceChecklist: this.addTask.showServiceChecklist,
          serviceChecklistRequired: this.addTask.serviceChecklistRequired,
          showCustomerServiceReport: this.addTask.showCustomerServiceReport,
          customerServiceReportRequired:
            this.addTask.customerServiceReportRequired,
          showHandoverCertificate: this.addTask.showHandoverCertificate,
          handoverCertificateRequired: this.addTask.handoverCertificateRequired,
          comingFrom: this.comingFrom,
          site: { id: this.addTask?.site?.id ?? null },
          project: { id: this.addTask?.project?.id ?? null },
          checklistRecordId:this.addTask.checklistRecordId ?? null,
          pmvId:this.addTask.pmvId ?? null,
          csrLogNo: this.addTask.csrLogNo,
          csrSalesOrder: this.addTask.csrSalesOrder
        };

        if (this.addTask && this.addTask.site && this.addTask.site.id) {
          this.formData = Object.assign(this.formData, {
            site: { id: this.addTask.site.id },
          });
        } else {
          this.formData.site = null;
        }
  
        if (this.addTask && this.addTask.project && this.addTask.project.id) {
          this.formData = Object.assign(this.formData, {
            project: { id: this.addTask.project.id },
          });
        } else {
          this.formData.project = null;
        }  
      }

      // set customer
      if (this.addTask.customer && this.addTask.customer !== null) {
        this.formData.customer = { id: this.addTask.customer };
      }

      // set team
      if (this.addTask.team && this.addTask.team.id) {
        this.formData.team = { id: this.addTask.team.id };
        let teamName: any = _.filter(this.teams, (o) => {
          return o.id == this.addTask.team.id;
        });
        this.formData.displayTeamName = teamName[0].name;
      }

      if (this.addTask.jobOrder && this.addTask.jobOrder.id !== null) {
        this.formData.jobOrder = { id: this.addTask.jobOrder.id };
        this.formData.joName = this.addTask.joName;
      }
      if (this.addTask.repeatTillDate) {
        this.formData.repeatTillDate = null;
      }

      if (this.addTask && this.addTask.site && this.addTask.site.id) {
        this.formData = Object.assign(this.formData, {
          site: { id: this.addTask.site.id },
        });
      } else {
        this.formData.site = null;
      }

      if (this.addTask && this.addTask.project && this.addTask.project.id) {
        this.formData = Object.assign(this.formData, {
          project: { id: this.addTask.project.id },
        });
      } else {
        this.formData.project = null;
      }

      if (
        this.addTask.description &&
        (this.addTask.description === "" ||
          this.addTask.description.length === 0)
      ) {
        this.addTask.description = null;
      }
      
      this.formData.parentMode = this.mode;
      this.formData.showClose = this.showClose;
      this.modalRef.hide();
      this.openModal("exact-assign", this.formData);
    }
  }

  public openModal(modalView: string, data: any) {
    data.Id = this.jobOrderId;
    if (modalView == "addTaskQuick") {
      data.comingFrom = "JO";
    }

    console.log(data);
    this.modalService.openModal(modalView, data);
  }

  public getRolesByDepartment(dep: any) {
    const nullSelect = [
      {
        id: null,
        name: "Select",
        priority: "null",
      },
    ];

    this.skillsService.getSkillCategoriesById(dep.id).subscribe((res: any) => {
      this.roles = res;
      this.levels = [];
    });
  }

  public getLevelsByRole(role: any) {
    const nullSelect = [
      {
        id: null,
        name: "Select",
        priority: "null",
      },
    ];

    this.skillsService
      .getSkillLevelsByCategory(role.id)
      .subscribe((res: any) => {
        this.levels = res;
      });
  }

  public addSkill() {
    console.log(this.selectedDepartment, this.selectedRole);

    if (this.selectedDepartment && !this.selectedRole) {
      this.errorUtil.setErrorMessage(
        200,
        null,
        this.translate?.task.messages.pleaseSelectRole, 
        "warn",
        2000
      );
      return;
    }

    if (!this.selectedDepartment && !this.selectedRole) {
      this.errorUtil.setErrorMessage(
        200,
        null,
        this.translate?.task.messages.pleaseSelectDepartmentRole, 
        "warn",
        2000
      );

      return;
    }
    let string = "";
    let req = {
      skillType: null,
      skillCategory: null,
      skillLevel: null,
      label: "",
    };

    if (this.selectedDepartment && this.selectedRole) {
      string = `${this.selectedDepartment.name}: ${this.selectedRole.name}`;
      req.skillType = this.selectedDepartment;
      req.skillCategory = this.selectedRole;
    }

    if (!this.selectedDepartment && this.selectedRole) {
      string = `${this.selectedRole.name}`;
      req.skillCategory = this.selectedRole;
    }

    if (this.selectedRole && this.selectedLevel) {
      string += ` - ${this.selectedLevel.name}`;
      req.skillCategory = this.selectedRole;
      req.skillLevel = this.selectedLevel;
    }

    req.label = string;
    this.addTask.userDynamicSkillList.push(req);

    this.selectedDepartment = null;
    this.selectedRole = null;
    this.selectedLevel = null;

    this.levels = [];
  }

  public removeSkill(index: any) {
    this.addTask.userDynamicSkillList.splice(index, 1);
  }
  /********************************************************* Chubb Condition  ********************************************************/
  public showChubForm(submitType: any) {
    console.log(
      this.addTask.generateJobOrder,
      "=====",
      this.chubbAddonFeature,
      "_____",
      submitType
    );
    if (this.chubbAddonFeature == "CHUBB" && this.addTask.generateJobOrder) {
      this.showChubbFormDiv = "1";
      this.showChubbFormSubmitted = submitType;
    } else {
      this.onSubmit(submitType);
    }
  }

  public selectMandatory() {
    if (this.addTask.serviceChecklistRequired) {
      this.addTask.showServiceChecklist = true;
    }
  }

  public selectMandatory2() {
    if (this.addTask.customerServiceReportRequired) {
      this.addTask.showCustomerServiceReport = true;
    }
  }

  public selectMandatory3() {
    if (this.addTask.handoverCertificateRequired) {
      this.addTask.showHandoverCertificate = true;
    }
  }
  /********************************************************* Set Time  ********************************************************/
  public uncheck(label: any) {
    if (label == "showServiceChecklist") {
      if (!this.addTask.showServiceChecklist) {
        this.addTask.serviceChecklistRequired = false;
      }
    } else if (label == "customerServiceReportRequired") {
      if (!this.addTask.showCustomerServiceReport) {
        this.addTask.customerServiceReportRequired = false;
      }
    } else {
      if (this.addTask.showHandoverCertificate) {
        this.addTask.handoverCertificateRequired = false;
      }
    }
  }
  /********************************************************* get BufferLabel  ********************************************************/
  public getBufferLabel(buffer: any) {
    let label = "";

    label = this.allBuffers.find((buff) => buff.value === buffer)?.label!;

    return label;
  }
  /********************************************************* Set Time  ********************************************************/
  setStartTime() {
    if (!this.addTask.adhoc) {
      const start = moment();
      const remainder = 30 - (start.minute() % 30);
      // const startTime = moment(start).add(remainder, "minutes");
      // const endTime = moment(startTime).add(30, "minutes");
      // this.addTask.startTime = startTime.format("h:mm A");
      // this.addTask.endTime = endTime.format("h:mm A");

      const start2 = moment(start, "h:mm A").format("H:mm");
      const startTime = moment(
        moment().format("YYYY-MM-DD") + " " + start2
      ).add(remainder, "minutes");
      this.addTask.startTime = startTime.toDate();

      const endTime = moment(moment().format("YYYY-MM-DD") + " " + start2).add(
        60,
        "minutes"
      );
      this.addTask.endTime = endTime.toDate();
    } else {
      const start = moment();
      // const start = moment().add(1, 'h');
      const remainder = 5 - (start.minute() % 5);
      // const startTime = moment(start).add(remainder, "minutes");
      // const endTime = moment(startTime).add(60, "minutes");
      // this.addTask.startTime = startTime.format("h:mm A");
      // this.addTask.endTime = endTime.format("h:mm A");

      const start2 = moment(start, "h:mm A").format("H:mm");
      const startTime = moment(
        moment().format("YYYY-MM-DD") + " " + start2
      ).add(remainder, "minutes");
      this.addTask.startTime = startTime.toDate();

      const endTime = moment(moment().format("YYYY-MM-DD") + " " + start2).add(
        60,
        "minutes"
      );
      this.addTask.endTime = endTime.toDate();
      
    }
    if (this.data.comingFrom !== undefined && this.data.comingFrom == "JO" && this.addTask.startTime && this.taskDurationSuggestion) {
      this.getPredictedCompletionTime()
    }
    
    console.log(this.addTask.startTime, "====", this.addTask.endTime)
  }

  /******************************************** On Site Select Value ******************************************/
  public onSiteSelect(event: any) {
    

    this.selectedSite = event;

    if (this.addTask.site) {
      this.addTask.site.id = event.id;
    } else {
      this.addTask.site = { id: event.id };
    }

    this.closeTable("sites");
  }

  /******************************************** Get All Project By Query ******************************************/
  public getSite(event: any) {
    const query = event.query.replace(
      /[&\/\\#,+()$~%!.„'":*‚^_¤?<>|@ª{«»§}©®™ ]/g,
      ""
    );

    if (query && query.length > 2) {
      this.push(
        this.siteService.getSitePicklist(query).subscribe((sites: any) => {
          this.allSites = sites;
        })
      );
    }
  }

  /******************************************** Show Table Dynamic Picklist ******************************************/
  public showTable(what: string) {
    if (what === "sites") {
      this.showingTable.sites = true;
    }

    if (what === "project") {
      this.showingTable.project = true;
    }
  }

  /******************************************** Close Table Dynamic Picklist ******************************************/
  public closeTable(what: string) {
    if (what === "sites") {
      this.showingTable.sites = false;
    }

    if (what === "project") {
      this.showingTable.project = false;
    }
  }

  /******************************************** On Project Select Value ******************************************/
  public onProjectSelect(event: any) {
    this.selectedProject = event;

    if (this.addTask.project) {
      this.addTask.project.id = event.id;
    } else {
      this.addTask.project = { id: event.id };
    }


    this.closeTable("project");
  }

  /******************************************** Get All Project By Query ******************************************/
  public getProject(event: any) {
    const query = event.query.replace(
      /[&\/\\#,+()$~%!.„'":*‚^_¤?<>|@ª{«»§}©®™ ]/g,
      ""
    );

    if (query && query.length > 2) {
      this.push(
        this.projectService
          .getAllProjectPickList(query)
          .subscribe((projects: any) => {
            this.allProject = projects;
          })
      );
    }
  }
  backTaskPage() {
    this.showChubbFormDiv = 0;
  }

  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
    console.log("translate",this.translate)
    //this.mainLabel = this.translate?.site.label.plural
  }

  push(obs: any) {
    super.push(obs);
  }
  openCalendar() {
    this.calendar.inputfieldViewChild.nativeElement.click();
  }
  openStarTime() {
    this.starTime.inputfieldViewChild.nativeElement.click();
  }
  openEndTime() {
    this.endTime.inputfieldViewChild.nativeElement.click();
  }

  

  closePane(): void {
    this.isPaneVisible = false;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
  getPredictedCompletionTime() {
    if(this.addTask.startTime){
    var tenantId = this.auth.getTenantId();
   
      if (this.taskDurationSuggestion) {
        this.aiService.getPredictedCompletionTime(tenantId, this.data.jobOrder.id).subscribe((res: any) => {
          console.log(res.body,"Response ", this.addTask);
          if (res.status == 200) {
            if (res.body.status == true) {
              this.isPaneVisible = true;
              this.taskAITime.digitalTime = res.body.data.predicted_completion_time
              this.taskAITime.displayAITime = this.convertMinutesToHoursAndMinutes(res.body.data.predicted_completion_time)
              this.taskAITime.newTime = this.addMinutesToStartTime(res.body.data.predicted_completion_time)
              console.log(this.taskAITime); // Output: "2 hour(s) and 15 minute(s)"
            }else{
              this.isPaneVisible = false;
            }
          }

        })
      }
    }

  }
   //changes made here for suggested time
   setEndTime() {
    this.addTask.endTime = this.taskAITime.newTime;
     this.closePane();
 }
//*********changes made here for suggested time
  addMinutesToStartTime(minutesToAdd: number) {
    const start2 = moment(this.addTask.endTime, "h:mm A").format("H:mm");
    var minutesToAdd = minutesToAdd;
    const startTime = moment(moment().format("YYYY-MM-DD") + " " + start2).add(minutesToAdd, "minutes");
   return startTime.toDate();
    
  }
//*********changes made here for suggested time
convertMinutesToHoursAndMinutes(totalMinutes: number): string {
  const hours = Math.floor(totalMinutes / 60); // Get the hours
  const minutes = totalMinutes % 60; // Get the remaining minutes
  if (hours > 0) {
    return `${hours} hour(s) and ${minutes} minute(s)`;
  } else {
    return `${minutes} minute(s)`;
  }
}


   /******************************************** Auto populate template data  ******************************************/
  gettaskTemplate(taskTemplateId) {

    this.tasksService.getTaskTemplate(taskTemplateId).subscribe((res: any) => {
      console.log("TemplateIdddddd", res)

      const tamplateData = res.body
      this.addTask.tmplTaskId = taskTemplateId
      this.addTask.taskName = tamplateData.taskName
      this.addTask.priority =
        this.addTask.priority = this.allPriorities.find(
          (prio: any) => prio.value == tamplateData.priority
        ).value;

      this.selectedPriority = this.allPriorities.find(
        (prio: any) => prio.value == tamplateData.priority
      );
      debugger


      if (tamplateData.copyDateFromJobOrder && this.data.comingFrom === "JO") {
        this.addTask.date = new Date(this.data.jobOrder.installationDate);
        this.addTaskFullForm.get('date').disable();
    } else if (tamplateData.date >= 0) {
        const tomorrow = new Date();
        if (tamplateData.date >= 1) {
            tomorrow.setDate(tomorrow.getDate() + tamplateData.date);
        }
        this.addTask.date = tomorrow;
        this.addTaskFullForm.get('date').disable();
    }


    // 


    if (tamplateData.startTime) {
      const selectedDate = this.addTask.date;
      const today = new Date();
      
      if (tamplateData.copyDateFromJobOrder && 
          this.data.comingFrom === "JO" && 
          selectedDate.toDateString() === today.toDateString()) {
          // If it's today, set to current time + 1 hour
          const currentTime = new Date();
          currentTime.setHours(currentTime.getHours() + 1);
          this.addTask.startTime = currentTime;
      } else {
          // For future dates or non-JO cases, use template time
          const [hours, minutes, seconds] = tamplateData.startTime.split(':').map(Number);
          const date = new Date();
          date.setHours(hours);
          date.setMinutes(minutes);
          date.setSeconds(seconds);
          this.addTask.startTime = date;
      }
      
      this.addTaskFullForm.get('startTime').disable();
  }

    

     

      if (tamplateData.duration && tamplateData.duration > 1) {
        const newTime = moment(this.addTask.startTime).add(tamplateData.duration, 'minutes').toDate();
        this.addTask.endTime = newTime
        this.addTaskFullForm.get('endTime').disable();
      }
      if (tamplateData.team && tamplateData.team.id) {
        this.addTask.team = tamplateData.team
      }
      if (tamplateData.description) {
        this.addTask.description = tamplateData.description
      }
      if (tamplateData.site && tamplateData.site.id) {
        tamplateData.site.siteName = tamplateData.site.name
        this.selectedSite = tamplateData.site;
        this.addTask.site.id = tamplateData.site.id


      }
      if (tamplateData.project && tamplateData.project.id) {
        this.selectedProject = tamplateData.project;
        this.addTask.project.id = tamplateData.project.id;

      }
      if (tamplateData.taskStatus) {
        this.addTask.taskStatus = tamplateData.taskStatus;
      }



    });
  }
}
