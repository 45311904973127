<div class="left-right-space h-100-70 mt-2 mb-3">
  <!-- Title -->
  <div class="flex-between mt-1 ms-2 mb-2">
      <div class="text-20 font-semiBold" id="title_TaskMySchedule">
         {{mode == 'all' ? translate?.task.fields.all.singular :mode == 'today' ?  translate?.task.fields.today.singular :mode == 'upcoming' ? translate?.task.fields.upcoming.singular:translate?.task.fields.todaysTasks.singular}}&nbsp;{{translate?.task.label.plural}}
      </div>
    <!-- Filter and New -->
    <div class="custella-dashboard-top-right d-flex ms-auto">
      <button *ngIf="noTask" class="btn-list-icon ms-2" [pTooltip]="translate?.general.buttons.refresh.singular" tooltipPosition="bottom" id="btn_TaskMyScheRefresh" (click)="getFilter()">
          <i class="fa-regular fa-arrows-rotate"></i>
      </button>
      <button *ngIf="noTask" class="btn-list-icon ms-2" [pTooltip]="translate?.general.buttons.filter.singular" tooltipPosition="bottom" (click)="onFilteruttonClick($event)" id="btn_TaskMyScheFilter">
        <i class="fa-regular fa-filter-list"></i> 
      </button>
      <div class="p-inputgroup search ms-2">
          <span class="p-input-icon-right ">
            <input type="text" class="w-100" pInputText autocomplete="off" [placeholder]="translate?.general.buttons.search.singular" id="input_TaskMyScheSearchField" [(ngModel)]="searchValue" (keyup)="onSearchCheck()" (keydown.enter)="onSearch()"/>
            <i *ngIf="searchValueData" id="btn_TaskMyScheSearchClear" class="p-autocomplete-clear-icon pi pi-times" (click)="onClear()"></i>
          </span>   
          <button type="button" pButton icon="fa-regular fa-magnifying-glass" (click)="onSearch()" id="btn_TaskMyScheSearch"></button>
      </div>

      <p-button  [label]="translate?.general.buttons.new.singular" icon="fa-regular fa-plus" (onClick)="openModal('addEditTask', '')" id="btn_TaskMyScheNew" styleClass="text-nowrap ms-2 h-32" *ngIf="createTask"></p-button>
      <p-button [label]="translate?.general.buttons.clone.singular" icon="fa-regular fa-clone" (onClick)="clone()" styleClass="text-nowrap ms-2 h-32" id="btn_TaskMyScheClone" *ngIf="createTask"></p-button>
      <p-button [label]="translate?.general.buttons.optimize.singular" icon="fa-regular fa-map" (onClick)="optimize()" styleClass="text-nowrap ms-2 h-32" id="btn_TaskMyScheOptimize" *ngIf="createTask"></p-button>


    </div>
  </div>
  <div *ngIf="noTaskIcon" class="col-12 mt-5 py-4">
    <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
      <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound">
       <p class="text-6 my-1 font-weight-bold">{{translate?.general.messages.noRecordsFound}}</p>
    </div>
  </div>

  <!-- TabMenu -->
  <div [hidden]="!noTask" class="position-relative fw-normal display-table d-flex" style="height: calc(100% - 55px);">

    <div [hidden]="!noTask" class="table-space ag-grid-table-full" [ngClass]="{' ag-grid-table': filterClick}">
      <ag-grid-angular
       #agGrid style="height: 100%;" 
       class="ag-theme-balham" 
       [columnDefs]="columnDefs"
        [gridOptions]="gridOptions" 
        [defaultColDef]="defaultColDef" 
        [paginationPageSize]="25"
        [pagination]=false 
        [cacheOverflowSize]="2"
        [suppressDragLeaveHidesColumns]="true" 
        [maxConcurrentDatasourceRequests]="-1"
        [allowContextMenuWithControlKey]="false" 
        [frameworkComponents]="frameworkComponents"
        (cellClicked)="viewTask($event)" 
        (gridReady)="onGridReady($event)"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        [getContextMenuItems]="getContextMenuItems"></ag-grid-angular>

      <div class="col-12">
        <p class="ag-record-row-summary-panel padds text-end" id="label_TaskMyScheTableRec">{{totalRecord}}&nbsp;{{translate?.general.messages.records}}</p>
      </div>

    </div>
    <div *ngIf="noTask" class="custella-form-container-content-pane layout-filter"
    [ngClass]="{'layout-filter-active': filterActive}">
    <div class="custella-form-container-content-pane-title">
      <span class="text-14 font-medium" id="title_TaskMyScheFilterBy">{{translate?.general.messages.filterBy}}</span>
      <i class="fa-regular fa-arrow-right-from-line" [pTooltip]="translate?.general.buttons.closeFilter.singular" tooltipPosition="left" id="btn_TaskMyScheFilterClose" (click)="onFilteruttonClick($event)"></i>
    </div>
    <div class="custella-content" [formGroup]="myGroup">

      <div class="custella-content-input col-12" *ngIf="mode == 'all' || mode == 'completed'">
        <span class="custella-content-input-title" id="label_TaskMyScheTaskDate">{{translate?.task.fields.taskDate.singular}}</span>
        <span class="p-input-icon-right w-100">
          <i class="pi pi-calendar" style="z-index: 1;"></i>
          <p-calendar type="text" class="w-100" selectionMode="range" formControlName="taskDateRange" id="picklist_TaskMyScheTaskDateField"
            dateFormat="dd/mm/yy" [readonlyInput]="true" name="completion" (onSelect)="onCompletionDateChange($event)"
            [(ngModel)]="taskDateRange" placeholder="dd/mm/yyyy - dd/mm/yyyy" inputId="taskDateRange" [yearNavigator]="true" yearRange="2000:2030"
            class="custella-calendar"
            [showButtonBar]="false"
            appendTo="body"></p-calendar>


        </span>
      </div>
      <div class="custella-content-input col-12" *ngIf="mode != 'completed'">
        <span class="custella-content-input-title" id="label_TaskMyScheStatus"> {{translate?.task.fields.status.singular}}</span>
        <!-- <p-dropdown class="pe-2" *ngIf="noTask" (onChange)="getFilter()" formControlName="status" id="picklist_TaskMyScheStatusField"
          [(ngModel)]="filter.status" name="status" [options]="taskStatus" optionLabel="label"
          filterBy="label" optionValue="value" [placeholder]="translate?.general.messages.none"
          [filter]="true"
          [showClear]="filter.status ? true : false"
          ></p-dropdown> -->

          <p-multiSelect
          class="pe-2"
          [(ngModel)]="filter.status"
          name="status"
          [options]="taskStatus"
          optionLabel="label" filterBy="label"
          optionValue="value"
          [placeholder]="translate?.general.messages.none"
          (onChange)="getFilter()"
          formControlName="status" 
          id="picklist_TaskMyScheStatusField"
      >
      </p-multiSelect>
      </div>
      <div>
        <span href="#" class="clear" (click)="resetPaymentSearch()" id="subtitle_TaskMyScheClear">{{translate?.general.buttons.clearAll.singular}}</span>
      </div>
    </div>

  </div>

   
  </div>
</div>
