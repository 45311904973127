import { HttpClient, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Http, RequestOptions, Headers } from "@angular/http";
import { Subject, throwError, of as observableOf, } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../auth/authentication.service";

@Injectable({
  providedIn: "root",
})
export class LeaveService {
  public refreshList$: EventEmitter<any>;
  private ENDPOINT_CREATE_LEAVE = "/user-leaves/";
  private ENDPOINT_LEAVE_BY_ID = "/user-leaves/";
  private ENDPOINT_DELETE_LEAVE = "/user-leaves";
  private ENDPOINT_LIST_LEAVES = "/v4/user-leaves/filter";

  private ENDPOINT_LIST_LEAVES_SUPERVISOR = "/user-leaves/by-supervisor";
  private ENDPOINT_LIST_MS_LEAVES = ""
  private ENDPOINT_UPDATE_SINGLE_LEAVE_STATUS_APPROVED = ""
  private selectSubject = new Subject();
  
  constructor(private http: HttpClient,
    private auth: AuthenticationService,
    private httpOnly: Http,) {
    this.refreshList$ = new EventEmitter();
  }

  public select(val: any) {
    this.selectSubject.next(val);
  }
  public getSelectItem() {
    return this.selectSubject.asObservable();
  }

  public getLeaveByUser(id: any) {
    let params = new HttpParams().set("userId", id);
    return this.http
      .get(environment.base_url + this.ENDPOINT_LIST_LEAVES, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getLeaveById(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_LEAVE_BY_ID + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public createLeave(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_CREATE_LEAVE, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(body);

          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public updateLeave(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_CREATE_LEAVE, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(body);

          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getLeavesForApproval(id: any) {
    let params = new HttpParams().set("supervisorId", id);

    return this.http
      .get(environment.base_url + this.ENDPOINT_LIST_LEAVES_SUPERVISOR, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public cancelLeave(id: number) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_DELETE_LEAVE + "/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("");

          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getAllLeaves(body: any) {
    return this.http
      .put(environment.base_url + "/user-leaves/filter", body)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getAllLeavesv4MS(searchValue: any, parameters: any, apiParams: any) {
    console.log("apiparams",apiParams)
    let params = new HttpParams();
    params = params.set("sortOrder", apiParams.sortOrder)
    params = params.set("columnName", apiParams.columnName)
    params = params.set("search", searchValue)
    params = params.set("startFrom", apiParams.startFrom)
    params = params.set("page", 1);
    params = params.set("userId", apiParams.userId ?? "")
    params = params.set("leaveStatuses", apiParams.status ?? "")
    params = params.set("supervisorId", apiParams.supervisorId ?? "")

    if (apiParams.fromDate) {
      params = params.set('startDate', apiParams.fromDate);
    }
    if (apiParams.toDate) {
      params = params.set('endDate', apiParams.toDate);
    }
    if (apiParams.siteId) {
      params = params.set('siteId', apiParams.siteId);
    }

    if (parameters)
      params = params.set('startFrom', parameters.startRow)
        .set('perPage', '25')
        .set('columnName', parameters.sortModel[0].colId)
        .set('sortOrder', parameters.sortModel[0].sort);
    console.log("params 4", params)
    return this.http
      .get(`${environment.base_url}/v4-1/user-leaves/filter`, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }


    /************************************* Update Single Leaves  status *************************************************************************/
    public updateSingleLeave(body: any,) {

      // let params = new HttpParams();
      // params = params.set('id', id);
      // params = params.set('status', status)
      return this.http
        .put(environment.base_url + '/user-leaves/'+body.id+'/accept-leave', {
          observe: "response",
        })
        .pipe(
          map((resp: any) => {
            this.refreshList$.emit("Status Updated");
            return resp;
          }),
          catchError((error) => {
            return observableOf(error);
          })
        );
    }


    /************************************* Update Reject Single Leaves  status *************************************************************************/
    public rejectSingleLeave(id: any,body: any,) {

      // let params = new HttpParams();
      // params = params.set('id', id);
      // params = params.set('status', status)
      return this.http
        .put(environment.base_url + '/user-leaves/'+id+'/reject-leave',body, {
          observe: "response",
        })
        .pipe(
          map((resp: any) => {
            this.refreshList$.emit("Status Updated");
            return resp;
          }),
          catchError((error) => {
            return observableOf(error);
          })
        );
    }

      /************************************* Update Multiple Leaves  status *************************************************************************/
  public updateMultipleLeave(body: any,) {

    // let params = new HttpParams();
    // params = params.set('id', id);
    // params = params.set('status', status)
    return this.http
      .put(environment.base_url + "/user-leaves/bulk/accept-leave", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Status Updated");
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

     /************************************* Get Attendance History By ID *************************************************************************/
     public getLeaveHistoryById(userLeaveId: any) {
      return this.http
        .get(environment.base_url + '/leave-audit-event-histories?userLeaveId='+userLeaveId)
        .pipe(
          map((resp: any) => {
            return resp;
          }),
          catchError((error) => {
            ////console.log(error);
            return throwError(error);
          })
        );
    }

      /*****************************************Upload Customer Attchment *********************************************************************/
  public uploadAttachment(data: any, formData: any) {
    const headers = new Headers({});
    headers.append("Authorization", "Bearer " + this.auth.getToken());
    let options = new RequestOptions({ headers: headers });
    return this.httpOnly
      .post(
        environment.base_url + '/attachments/upload?parentType=LEAVE_ATTACHMENT&parentId='+data.id,
        formData,
        options
      )
      .pipe(
        map((resp: any) => {
         
          return resp.json();
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /************************************* Get Attendance History By ID *************************************************************************/
  public getLeaveAttachmentById(userLeaveId: any) {
    return this.http
      .get(environment.base_url + '/v4/attachments/user-leave?userLeaveId='+userLeaveId)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }

  public getAllOutTurnCodes() {
    return this.http.get(environment.base_url + '/leave-out-turn-codes', { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);
  }
  
}
