import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { I } from "@fullcalendar/core/internal-common";
import { NgxSpinnerService } from "ngx-spinner";
import { AiService } from "src/app/_services/admin/ai.service";
import { UsersService } from "src/app/_services/admin/users.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { AuthenticationService } from "src/app/auth/authentication.service";

@Component({
  selector: "app-ai-enable",
  templateUrl: "./ai-enable.component.html",
  styleUrls: ["./ai-enable.component.scss"],
})
export class AiEnableComponent
  extends SubscriptionUtil
  implements OnInit, OnChanges, OnDestroy
{
  @Input() comingFrom: any;
  @Input() profile: any;
  editForm: boolean = false;
  translate: any = {};
  divConfigs: Array<{ feature: string; label: string; value: string }> = []; // Dynamically display AI suggestions

  aiFeatures: any = {
    tenantId: null,
    dailyJobRundown: false,
    postJobSummary: false,
    taskDurationSuggestion: false,
    checklistGeneration: false,
    jobOrderNoteSuggestions: false,
    jobOrderSparePartSuggestions: false,
  };
  orgAIFeatures: any;

  constructor(
    private languageTranslateService: LanguageTranslateService,
    private aiService: AiService,
    private auth: AuthenticationService,
    private userService: UsersService,
    private errorUtil: ErrorUtil,
    private spinner: NgxSpinnerService
  ) {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.comingFrom) {
      this.comingFrom = changes.comingFrom.currentValue;
    }
    if (changes.profile) {
      this.profile = changes.profile.currentValue;
    }
    if (this.comingFrom == "User" && this.profile) {
      this.aiFeatures.dailyJobRundown = this.profile.dailyJobRundown;
      this.aiFeatures.postJobSummary = this.profile.postJobSummary;
      this.aiFeatures.taskDurationSuggestion = this.profile.taskDurationSuggestion;
      this.aiFeatures.checklistGeneration = this.profile.checklistGeneration;
      this.aiFeatures.jobOrderNoteSuggestions = this.profile.jobOrderNoteSuggestions;
      this.aiFeatures.jobOrderSparePartSuggestions = this.profile.jobOrderSparePartSuggestions;
    }
    console.log("aiFeatures", this.aiFeatures);
    console.log("profile", this.profile);
  }

  ngOnInit(): void {
    this.editForm = false;
    this.getLanguageData();
  }

  clickEdit() {
    this.editForm = !this.editForm;
  }

  getLanguageData() {
    this.push(
      this.languageTranslateService.wordSrc$.subscribe((data) => {
        if (Object.keys(data).length != 0) {
          this.translate = data;
          this.getAIOrganizationFeatures();
       
          this.divConfigs = [
            {
              feature: "dailyJobRundown",
              label: this.translate?.setup?.configuration?.artificialIntelligence.fields.dailyJobRundown.singular,
              value: this.translate?.setup?.configuration?.artificialIntelligence.messages.aCategorizedSummary,
            },
            {
              feature: "postJobSummary",
              label: this.translate?.setup?.configuration?.artificialIntelligence.fields.postJobSummary.singular,
              value: this.translate?.setup?.configuration?.artificialIntelligence.messages.conciseSummaryKeyDetails,
            },
            {
              feature: "taskDurationSuggestion",
              label: this.translate?.setup?.configuration?.artificialIntelligence.fields.taskDurationSuggestion.singular,
              value: this.translate?.setup?.configuration?.artificialIntelligence.messages.taskTimeSuggestions,
            },
            {
              feature: "checklistGeneration",
              label: this.translate?.setup?.configuration?.artificialIntelligence.fields.checklistGeneration.singular,
              value: this.translate?.setup?.configuration?.artificialIntelligence.messages.useAiToGenerateChecklist,
            },
            {
              feature: "jobOrderNoteSuggestions",
              label: this.translate?.setup?.configuration?.artificialIntelligence.fields.jobOrderNoteSuggestions.singular,
              value: this.translate?.setup?.configuration?.artificialIntelligence.messages.allowAiSuggestJob,
            },
            {
              feature: "jobOrderSparePartSuggestions",
              label: this.translate?.setup?.configuration?.artificialIntelligence.fields.jobOrderSparePartSuggestions.singular,
              value: this.translate?.setup?.configuration?.artificialIntelligence.messages.allowAiSuggestJobSpareParts,
            }
          ];
        }
      })
    );
    console.log("translate", this.translate);
    //this.mainLabel = this.translate?.site.label.plural
  }

  push(obs: any) {
    super.push(obs);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
  //**************************************  Dynamically Display AI Suggestions *************************************/
  isHidden(index: number): boolean {
    const feature = this.divConfigs[index].feature;
    return this.comingFrom === "User" && !this.orgAIFeatures[feature];
  }

  shouldAddPadding(index: number): boolean {
    if (index === 0) {
      return true;
    }
    return index > 0 && this.isHidden(index - 1);
  }
//**************************************  Get AI User Details *************************************/
getAIUserFeatures() {
  this.aiFeatures.dailyJobRundown = this.profile.dailyJobRundown;
  this.aiFeatures.postJobSummary = this.profile.postJobSummary;
  this.aiFeatures.taskDurationSuggestion = this.profile.taskDurationSuggestion;
  this.aiFeatures.checklistGeneration = this.profile.checklistGeneration;
  this.aiFeatures.jobOrderNoteSuggestions = this.profile.jobOrderNoteSuggestions;
  this.aiFeatures.jobOrderSparePartSuggestions = this.profile.jobOrderSparePartSuggestions;

}
  //**************************************  Get AI Organization Details *************************************/
  getAIOrganizationFeatures() {
    this.push(
      this.aiService.getAIOrganizationFeatures(this.auth.getTenantId()).subscribe((res: any) => {
       
        if (res.status === 200 || res.status === 201) {
          this.orgAIFeatures = res.body;
          if (this.comingFrom != "User" && !this.profile) {
            this.aiFeatures.dailyJobRundown = this.orgAIFeatures.dailyJobRundown ? this.orgAIFeatures.dailyJobRundown : false;
            this.aiFeatures.postJobSummary = this.orgAIFeatures.postJobSummary ? this.orgAIFeatures.postJobSummary : false;
            this.aiFeatures.taskDurationSuggestion = this.orgAIFeatures.taskDurationSuggestion ? this.orgAIFeatures.taskDurationSuggestion : false;
            this.aiFeatures.checklistGeneration = this.orgAIFeatures.checklistGeneration ? this.orgAIFeatures.checklistGeneration : false;
            this.aiFeatures.jobOrderNoteSuggestions = this.orgAIFeatures.jobOrderNoteSuggestions ? this.orgAIFeatures.jobOrderNoteSuggestions : false;
            this.aiFeatures.jobOrderSparePartSuggestions = this.orgAIFeatures.jobOrderSparePartSuggestions ? this.orgAIFeatures.jobOrderSparePartSuggestions : false;
          } else {
            this.getAIUserFeatures();
          }
         console.log(res,"resders", this.aiFeatures);
         
        } else {
          this.errorUtil.setErrorMessage(400, this.translate?.general.messages.noDataFound, null, "error", 3000);
        }
      }, (err) => {
        console.error('An error occurred:', err); // Avoid logging detailed error messages in production
        const title = this.errorUtil.processErrorTitle(err.error, this.translate);
        this.errorUtil.setErrorMessage(400, null, title, 'error', 3000);
      })
    );
  }

  clickSave() {
    this.spinner.show();

    if (this.comingFrom != "User" && !this.profile) {
      this.aiService.updateAIOrganizationFeatures(this.aiFeatures).subscribe(
        (res: any) => {
          console.log("res", res);
          this.getAIOrganizationFeatures();
          this.editForm = !this.editForm;
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          const title = this.errorUtil.processErrorTitle(
            err.error,
            this.translate
          );
          this.errorUtil.setErrorMessage(
            err.status,
            null,
            title,
            "error",
            3000
          );
        }
      );
    } else if (this.comingFrom == "User" && this.profile) {
      var formData = {
        ...this.profile,
        dailyJobRundown: this.aiFeatures.dailyJobRundown,
        postJobSummary: this.aiFeatures.postJobSummary,
        taskDurationSuggestion: this.aiFeatures.taskDurationSuggestion,
        checklistGeneration: this.aiFeatures.checklistGeneration,
        jobOrderNoteSuggestions: this.aiFeatures.jobOrderNoteSuggestions,
        jobOrderSparePartSuggestions: this.aiFeatures.jobOrderSparePartSuggestions,
      };
      if (this.profile.geoFence.id) {
        formData.geoFence = { id: this.profile.geoFence.id };
      } else {
        formData.geoFence = null;
      }
  
      if (this.profile.defaultDepot && this.profile.defaultDepot.hasOwnProperty('id') && this.profile.defaultDepot.id !== null) {
        formData.defaultDepot = { id: this.profile.defaultDepot.id };
      } else {
        formData.defaultDepot = null;
      }

      this.push(
        this.userService.editUserProfile(formData, this.profile.id).subscribe(
            (res: any) => {
              console.log("res", res);
              if (res.status === 200 || res.status === 201) {
                this.errorUtil.setErrorMessage( 200,this.translate?.general.messages.updatedSuccessfully, null, "success",1000 );
              }else {
                this.errorUtil.setErrorMessage(400, null ,  res.error.title, 'error',3000);;
              }
              this.spinner.hide();
            },
            (err) => {
              this.spinner.hide();
              const title = this.errorUtil.processErrorTitle(
                err.error,
                this.translate
              );
              this.errorUtil.setErrorMessage(
                err.status,
                null,
                title,
                "error",
                3000
              );
            }
          )
      );
      this.editForm = !this.editForm;
    }
  }
}
