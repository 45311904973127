<div class="h-100-70 mt-2">
  <!-- Title -->
  <div class="flex-between mt-1 mx-2 mb-2">
      <div class="text-20 font-semiBold" id="title_TaskTeamSchedule">
        {{mode == 'all' ? translate?.task.fields.all.singular :mode == 'today' ? translate?.task.fields.today.singular :mode == 'upcoming' ? translate?.task.fields.upcoming.singular:translate?.task.fields.completed.singular}}&nbsp;{{translate?.task.label.plural}}
      </div>
    <!-- Filter and New -->
    <div class="custella-dashboard-top-right d-flex ms-auto">
     
      <button *ngIf="toggle" class="btn-list-icon ms-2" [pTooltip]="translate?.general.buttons.tableView.singular" tooltipPosition="bottom" id="btn_TaskTeamScheTableView" (click)="openMapLocation()">
          <i class="fa-regular fa-table"></i>
      </button>

  <button *ngIf="!toggle"  class="btn-list-icon ms-2" [pTooltip]="translate?.general.buttons.mapView.singular" tooltipPosition="bottom"id="btn_TaskTeamScheCalendar"  (click)="openMapLocation()">
        <i class="fa-regular fa-calendar-lines"></i>
    </button>
      <button *ngIf="toggle"class="btn-list-icon ms-2" [pTooltip]="translate?.general.buttons.refresh.singular" tooltipPosition="bottom" (click)="getFilter()" id="btn_TaskTeamScheRefresh">  <i class="fa-regular fa-arrows-rotate"></i></button>
      <button   class="btn-list-icon ms-2" [pTooltip]="translate?.general.buttons.filter.singular" tooltipPosition="bottom" id="btn_TaskTeamScheFilter" (click)="onFilteruttonClick($event)"><i class="fa-regular fa-filter-list"></i> </button>
     
      <span class="p-input-icon-right w-100 ms-2">
          <i class="pi pi-calendar z-1"></i>
          <p-calendar type="text" class="w-100" selectionMode="single" dateFormat="dd/mm/yy" [readonlyInput]="true" id="picklist_TaskTeamScheDateField" name="completion" (onSelect)="onCompletionDateChange($event)"
            [(ngModel)]="taskDateRange" placeholder="dd/mm/yyyy" [yearNavigator]="true" yearRange="2000:2030"appendTo="body"></p-calendar>
      </span>
      <p-dropdown class="ms-2"  (onChange)="getFilter()" id="picklist_TaskTeamScheSelStatusField"
      [(ngModel)]="filterByView" name="status" [options]="filterDay" optionLabel="label"
      optionValue="value" placeholder="Select Status"></p-dropdown>
     
      <p-button  [label]="translate?.general.buttons.new.singular" icon="fa-regular fa-plus" id="btn_TaskTeamScheNew" (onClick)="openModal('addEditTask', '')" styleClass="ms-2 h-32" *ngIf="createTask && addonFeature.addonCHUBB == 'CHUBB'"></p-button>


 
    </div>
  </div>
  <!-- TabMenu -->
  <div class=" d-flex h-100-30px">
  <div class="position-relative fw-normal display-table d-flex ag-grid-table-full h-100 border-eb" [ngClass]="{' ag-grid-table': filterClick}">
    <div *ngIf="toggle" class="w-100">
      <bryntum-scheduler
      #scheduler
      [resources] = "users"
      [columns] = "schedulerConfig.columns"
      [events] = "events"
      [startDate] = "schedulerConfig.startDate!"
      [endDate] = "schedulerConfig.endDate!"
      [viewPreset] = "viewPreset"
      [rowHeight] = "schedulerConfig.rowHeight"
      [barMargin] = "schedulerConfig.barMargin"
      [treeFeature] = true
      [readOnly] = true
      [resourceTimeRangesFeature] = "schedulerConfig.features.resourceTimeRanges"
      [eventTooltipFeature] = "schedulerConfig.features.eventTooltip"
      (onCatchAll) = "onSchedulerEvents($event)"
      ></bryntum-scheduler>    
    </div>
    <div *ngIf="!toggle" class="w-100">
      <app-all-team-task [mode]="mode" [selectedTeam]="selectedTeam" [status]="status" [taskDateRange]="taskDateRange"></app-all-team-task>
    </div>
  </div>


  <div  class="custella-form-container-content-pane layout-filter-task" [ngClass]="{'layout-filter-active': filterActive}">
    <div class="custella-form-container-content-pane-title">
        <span class="text-14 font-medium" id="title_TaskTeamScheFilterBy">{{translate?.general.messages.filterBy}}</span>
        <i class="fa-regular fa-arrow-right-from-line" [pTooltip]="translate?.general.buttons.closeFilter.singular" tooltipPosition="left" id="btn_TaskTeamScheFilterClose" (click)="onFilteruttonClick($event)"></i>
    </div>
    <div class="custella-content my-2 px-2 py-2">
      
        <div class="custella-content-row-input col-12 mb-2">
            <span class="custella-content-row-input-title" id="label_TaskTeamScheTeams">{{ translate?.setup.users.teams.label.singular }}</span>
            <p-multiSelect display="chip" (onChange)="filterTeam($event)" id="picklist_TaskTeamScheTeamsField" [(ngModel)]="selectedTeam" name="status" [options]="teams" optionLabel="teamName"  optionValue="id" placeholder="Select Teams"></p-multiSelect>
      
        </div>

        <div class="custella-content-row-input col-12 mb-2">
                <span class="custella-content-row-input-title" id="label_TaskTeamScheStatus">{{ translate?.task.fields.status.singular }}</span>
                <p-dropdown
                    class="pe-2"
                    id="picklist_TaskTeamScheStatusField"
                     (onChange)="getFilter()"
                    [(ngModel)]="status"
                    name="status"
                    [options]="taskStatus"
                    optionLabel="label"
                    filterBy="label"
                    optionValue="value"
                    [placeholder]="translate?.general.messages.none"
                    [showClear]="true"
                    [filter]="true"
                ></p-dropdown>
            </div>

            <div class="custella-content-row-input col-12 mb-2 d-flex mt-4">
              <p-checkbox class="ms-2" [binary]="true" (onChange)="filterTaskByTeam(selectedTeam)" id="check_TaskTeamScheShowTeam"  [(ngModel)]="byTeamOnlyChk"  [ngModelOptions]="{ standalone: true }"></p-checkbox>
              <span class="custella-content-row-input-title ms-2 mb-0" id="label_TaskTeamScheShowTeam">{{ translate?.task.fields.showTeamTaskOnly.singular }}</span>
              
           </div>

        <div>
          <span href="#" class="clear" (click)="resetSearch()" id="subtitle_TaskTeamScheClear">{{translate?.general.buttons.clearAll.singular }}</span>
        </div>
    </div>
 </div>
</div>
</div>


<footer *ngIf="displayJobOrderTemplates" class="custella-form-container-footer hidePopup">
  <p-dialog [header]="translate?.general.commonComponent.sections.sec17.singular" [(visible)]="displayJobOrderTemplates" [resizable]="false" styleClass="h-100-205"
      showEffect="fade" [style]="{ width: '70vw'}" [modal]="true" [draggable]="false">
      <app-list-task-templates (closeTable)="closeTable()"></app-list-task-templates>
  </p-dialog>
</footer>