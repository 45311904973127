import { of as observableOf, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable()
export class DynamicFormSetupService {
  // EventEmitter to notify when the dynamic list needs to be refreshed
  public refreshDynamicList$: EventEmitter<any>;
  ENDPOINT_LIST_DF = '/dynamic-form-schema/';

  // EventEmitter to notify when a value has changed
  public valueChanged$: EventEmitter<any>;
  refreshSelectedSectionSettings$: EventEmitter<any>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private auth: AuthenticationService
  ) {
    this.refreshDynamicList$ = new EventEmitter();
    this.valueChanged$ = new EventEmitter();
    this.refreshSelectedSectionSettings$ = new EventEmitter();
  }

  /**
   * Returns the base URL for a given API server type.
   * @param apiServerType - The type of the API server.
   */
  public returnNodeurl(apiServerType: any) {
    if (apiServerType == 'magnum') {
      return environment.magnum_node_url;
    } else if (apiServerType == 'java') {
      return environment.base_url;
    } else if (apiServerType == 'tngo') {
      return environment.touchandgo_node_url;
    } else if (apiServerType == 'coffex') {
      return environment.coffex_node_url;
    } else if (apiServerType == 'multiTenantNode') {
      return environment.multiTenant_node_url;
    } else if (apiServerType == 'agribio') {
      return environment.agribio_node_url;
    } else {
      return environment.node_url;
    }
  }

  public getDynamicList(
    searchValue: any,
    parameters: any,
    filter: any
  ) {
    var url = this.returnNodeurl('multiTenantNode');
    let params = new HttpParams();
    if (filter.fromDate) {
      params = params.set('fromDate', filter.fromDate);
    }
    if (filter.toDate) {
      params = params.set('toDate', filter.toDate);
    }
    if (filter.mandatory !== '' && filter.mandatory != null) {
      params = params.set('mandatory', filter.mandatory);
    }
    if (filter.status !== '' && filter.status != null) {
      params = params.set('status', filter.status);
    }
    if (filter.jobOrderType !== '' && filter.jobOrderType != null) {
      params = params.set('jobOrderTypeId', filter.jobOrderType);
    }
    if (searchValue !== '' && searchValue != null) {
      params = params.set('search', searchValue);
    }
    var apiUrl = '/v1/dynamic-form/data/';
    
    if (parameters) {
      params = params
        .set('startFrom', parameters.startRow)
        .set('perPage', '25')
        .set('columnName', parameters.sortModel[0].colId || 'formLabel')
        .set('sortOrder', parameters.sortModel[0].sort);
    }
    return this.http
      .get(`${url}/dynamic-form-schema/page`, {
        observe: 'response',
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /************************************* Get DynamicForm By Id  *********************************/
  public getDynamicFormById(id : any, formType){
    var url = this.ENDPOINT_LIST_DF+'v2/'
    if(formType == 'subform'){
      url = '/subform-schema/'
    }
    return this.http.get( environment.multiTenant_node_url+url+id, {observe: "response"}).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }
/************************************* Get DynamicForm By Id  *********************************/
public getDynamicFormSummerycountById(id : any, formType:any){
  var url = this.ENDPOINT_LIST_DF
  if(formType == 'subform'){
    url = '/subform-schema/'
  }
  return this.http.get( environment.multiTenant_node_url+url+'count-summery/'+id, {observe: "response"}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}
  /************************************* Create DynamicForm By Id  *********************************/
public createDynamicForm(body:any,formType:any){
  var url = this.ENDPOINT_LIST_DF+'v2'
  if(formType == 'subform'){
    url = '/subform-schema'
  }
  return this.http.post( environment.multiTenant_node_url+url, body, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
      return throwError( error );
  }),);
}
/***************************************** Update DynamicForm By Id  *****************************/
public updateDynamicForm(body:any, formType:any){
  var url = this.ENDPOINT_LIST_DF+'v2'
  if(formType == 'subform'){
    url = '/subform-schema/v2'
  }
  return this.http.put( environment.multiTenant_node_url+url, body, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  
      return throwError( error );
  }),);
}
/************************************* Delete DynamicForm By Id  *****************************/
public deleteDynamicForm(id: any, formType:any){
  var url = this.ENDPOINT_LIST_DF
  if(formType == 'subform'){
    url = '/subform-schema/'
  }
  return this.http.delete( environment.multiTenant_node_url+url+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshDynamicList$.emit('Deleted Custome Form');
    return resp;
  }
),catchError( error => {
  
      return throwError( error );
  }),);
}

public getDynamicFormListFieldTypesSelected( parentFormId: any) {
  let params = new HttpParams();
  if (parentFormId) {
    params = params.set('parentFormId', parentFormId);
  }
  
  return this.http.get(environment.multiTenant_node_url+'/field-schema/picklist', {observe: "response", params: params })
    .pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return observableOf(error);
      })
    );
  }

public getDynamicFormFieldTypes( type: any) {
  let params = new HttpParams();
  if (type) {
    params = params.set('type', type);
  }
  
  return this.http.get(environment.multiTenant_node_url+'/field-schema/field-types', {observe: "response", params: params })
    .pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return observableOf(error);
      })
    );
  }
   /************************************* Create DynamicForm By Id  *********************************/
public createDynamicFormField(body:any){
  return this.http.post( environment.multiTenant_node_url+'/field-schema', body, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
      return throwError( error );
  }),);
}
/***************************************** Update DynamicForm By Id  *****************************/
public updateDynamicFormField(body:any){
  return this.http.put( environment.multiTenant_node_url+'/field-schema', body, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  
      return throwError( error );
  }),);
}
/***************************************** get Dynamic From Field By Id  *****************************/
public getDynamicFormFieldDataById( id: any) {
  let params = new HttpParams();
  
  return this.http.get(environment.multiTenant_node_url+'/field-schema/'+id, {observe: "response", params: params })
    .pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return observableOf(error);
      })
    );
  }

/************************************* Delete DynamicForm By Id  *****************************/
public deleteDynamicFormField(id: any){
  return this.http.delete( environment.multiTenant_node_url+'/field-schema/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshDynamicList$.emit('Deleted Custome Form Field');
    return resp;
  }
),catchError( error => {
  
      return throwError( error );
  }),);
}
/***************************************** get Dynamic From Lookup Module  *****************************/
public getDynamicFormlookupModule() {
  let params = new HttpParams();
  
  return this.http.get(environment.multiTenant_node_url+'/field-schema/fields/lookup/modules', {observe: "response", params: params })
    .pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return observableOf(error);
      })
    );
  }
/***************************************** get Dynamic From Lookup Module => Fields  *****************************/
public getDynamicFormlookupFields(moduleName: any) {
  let params = new HttpParams();
  params = params.set('moduleName', moduleName);
  return this.http.get(environment.multiTenant_node_url+'/field-schema/fields/lookup/module-fields', {observe: "response", params: params })
    .pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return observableOf(error);
      })
    );
  }
//
  public getDynamicSubFormList(
    searchValue: any,
    parameters: any,
    parentFormId: any
  ) {
    var url = this.returnNodeurl('multiTenantNode');
    let params = new HttpParams();
    params = params.set('parentFormId', parentFormId);
    if (searchValue !== '' && searchValue != null) {
      params = params.set('search', searchValue);
    }
    var apiUrl = '/v1/dynamic-form/data/';
    
    if (parameters) {
      params = params
        .set('startFrom', parameters.startRow)
        .set('perPage', '25')
        .set('columnName', parameters.sortModel[0].colId || 'formLabel')
        .set('sortOrder', parameters.sortModel[0].sort);
    }
    return this.http
      .get(`${url}/subform-schema/page`, {
        observe: 'response',
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  public getDynamicsunFomrTableSetup( formSchemaName: any) {
    var url = this.returnNodeurl('multiTenantNode');
    let params = new HttpParams();
    if (formSchemaName) {
      params = params.set('formSchemaName', formSchemaName);
    }

    return this.http
      .get(`${url}/dynamic-table-tmpl/dynamic-columns`, {  observe: 'response' , params: params})
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  getDynamicSubFomrTableSetupById( formSchemaName: any) {
    var url = this.returnNodeurl('multiTenantNode');
    let params = new HttpParams();
    if (formSchemaName) {
      params = params.set('formSchemaName', formSchemaName);
    }

    return this.http
      .get(`${url}/dynamic-table-tmpl/dynamic-columns`, {  observe: 'response' , params: params})
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }
  public getDynamicFormSteps( parentFormId: any,formType: any) {
    let params = new HttpParams();
    if (parentFormId) {
      params = params.set('includeSteps', true);
    }
    var url = this.ENDPOINT_LIST_DF+'v2/'
  if(formType == 'subform'){
    url = '/subform-schema/v2/'
  }
    
    return this.http.get(environment.multiTenant_node_url+url+parentFormId, {observe: "response", params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
    }

     /************************************* Create DynamicForm By Id  *********************************/
public arrangeDynamicFormField(body:any,formType: any){
  var url = this.ENDPOINT_LIST_DF+'steps'
  if(formType == 'subform'){
    url = '/subform-schema/steps'
  }
  return this.http.patch( environment.multiTenant_node_url+url, body, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
      return throwError( error );
  }),);
}

    public selectSectionSettings(event: any){
      this.refreshSelectedSectionSettings$.emit(event);
    } 

}
