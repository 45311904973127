<div class=""  *ngIf="translate">
    <div class="custella-modal d-flex flex-column">
        <div class="custella-modal-header">
            <div class="custella-modal-header-text" id="title_SetWorkflowNotificationPreviewEmail">
                <span>{{translate?.setup.configuration.customForm.sections.sec19.singular}}</span>
            </div>
            <i class="pi pi-times pt-1"  [pTooltip]="translate?.general.buttons.cancel.singular" tooltipPosition="left"  (click)="modalRef.hide()" id="btn_SetWorkflowNotificationPreviewEmailClose"></i>
        </div>
    </div>  

    <div class="custella-modal-body">
        <div class="custella-form-container box-none m-0">
            <div class="custella-form-container-title-two-grey fst-italic font-semiBold">
                <div class="text-12 font-medium">Example Field Name</div>
            </div>
            <div class="custella-form-container-title-two-grey-two fst-italic">
                <div class="text-12 font-medium">{{translate?.setup.configuration.customForm.messages.modifyFieldSettings}}</div>
            </div>
            <div class="custella-form-container-content my-3">
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-12">
                        <div class="flex-align-center">
                            <p-checkbox class="mx-2 mb-1" [binary]="true"></p-checkbox>
                            <span class="custella-content-row-input-title font-weight-bold" id="label_TaskFormAvail" translate>{{translate?.setup.configuration.customForm.fields.readOnly.singular}}
                            </span>
                        </div>
                        <div class="flex-align-center">
                            <p-checkbox class="mx-2 mb-1" [binary]="true"></p-checkbox>
                            <span class="custella-content-row-input-title font-weight-bold" id="label_TaskFormAvail" translate>{{ translate?.setup.configuration.customForm.fields.required.singular}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="custella-form-container-footer-two">
                <div class="d-flex align-items-center justify-content-end">
                    <button class="custella-btn-white text-12 ms-2" (click)="modalRef.hide()">{{
                        translate?.general.buttons.cancel.singular}}</button>
                    <p-button [label]="translate?.general.buttons.save.singular" styleClass="ms-2 h-32"></p-button>
                </div>

            </div>
        </div>
    </div>
</div>