import { of as observableOf, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { RequestOptions, Http, Headers } from '@angular/http';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable()
export class DynamicFormService {
  // EventEmitter to notify when the dynamic list needs to be refreshed
  public refreshDynamicList$: EventEmitter<any>;

  // EventEmitter to notify when a value has changed
  public valueChanged$: EventEmitter<any>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private httpOnly: Http,
    private auth: AuthenticationService
  ) {
    this.refreshDynamicList$ = new EventEmitter();
    this.valueChanged$ = new EventEmitter();
  }

  /**
   * Returns the base URL for a given API server type.
   * @param apiServerType - The type of the API server.
   */
  public returnNodeurl(apiServerType: any) {
    if (apiServerType == 'magnum') {
      return environment.magnum_node_url;
    } else if (apiServerType == 'java') {
      return environment.base_url;
    } else if (apiServerType == 'tngo') {
      return environment.touchandgo_node_url;
    } else if (apiServerType == 'coffex') {
      return environment.coffex_node_url;
    } else if (apiServerType == 'multiTenantNode') {
      return environment.multiTenant_node_url;
    } else if (apiServerType == 'agribio') {
      return environment.agribio_node_url;
    } else {
      return environment.node_url;
    }
  }

  /**
   * Fetches a dynamic form template based on various parameters.
   * @param parentId - The ID of the parent.
   * @param formSchemaName - The name of the form schema.
   * @param apiServerType - The type of the API server.
   * @param parentName - The name of the parent entity.
   * @param sectionName - Optional section name.
   */
  public getReadingDynamicForm(
    parentId: any,
    formSchemaName: any,
    apiServerType: any,
    parentName: any,
    sectionName: any = null
  ) {
    let params = new HttpParams();
    var url = this.returnNodeurl(apiServerType);
    params = params.set('formSchemaName', formSchemaName);
    params = params.set('parentName', parentName);
    params = params.set('parentId', parentId);
    if (sectionName != null) {
      params = params.set('sectionName', sectionName);
    }

    return this.http
      .get(url + '/v1/dynamic-form/schemas/template', {
        observe: 'response',
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /**
   * Fetches a list of dynamic forms.
   */
  public getReadingDynamicForm1() {
    let params = new HttpParams();
    var url = this.returnNodeurl('magnum');

    return this.http
      .get(url + '/v1/dynamic-form/schemas', {
        observe: 'response',
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /**
   * Fetches a dynamic form template for editing based on various parameters.
   * @param formSchemaName - The name of the form schema.
   * @param id - The ID of the form data.
   * @param apiServerType - The type of the API server.
   * @param apiUrl - Optional API URL.
   */
  public getEditReadingDynamicForm(
    formSchemaName: any,
    id: any,
    apiServerType: any,
    apiUrl: any = null
  ) {
    var url = this.returnNodeurl(apiServerType);

    if (apiUrl == null) {
      apiUrl = '/v1/dynamic-form/data/';
    }
    return this.http
      .get(url + apiUrl + formSchemaName + '/' + id, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          }
          if (resp.status == 403) {
            this.logOut();
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /**
   * Fetches data from the server based on a lookup URL and API server type.
   * @param lookupUrl - The URL to lookup data.
   * @param apiServerType - The type of the API server.
   */
  public getData(lookupUrl: any, apiServerType: any) {
    var url = this.returnNodeurl(apiServerType);
    return this.http
      .get(url + '/' + lookupUrl, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /**
   * Emits a value changed event.
   */
  public valueChanged() {
    this.valueChanged$.emit();
  }

  /**
   * Creates a new dynamic form data entry.
   * @param body - The data to be sent in the request body.
   * @param formAPIName - The API name of the form.
   * @param apiServerType - The type of the API server.
   * @param refresh - Optional refresh flag.
   */
  public createDynamicFormData(
    body: any,
    formAPIName: any,
    apiServerType: any,
    refresh: any
  ) {
    var url = this.returnNodeurl(apiServerType);
    return this.http
      .post(url + '/' + formAPIName, body, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          if (refresh != null) {
            this.refreshDynamicList$.emit(resp);
          }
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /**
   * Edits an existing dynamic form data entry.
   * @param body - The data to be sent in the request body.
   * @param formAPIName - The API name of the form.
   * @param apiServerType - The type of the API server.
   */
  public editDynamicFormData(
    body: any,
    formAPIName: any,
    apiServerType: any
  ) {
    var url = this.returnNodeurl(apiServerType);
    return this.http
      .put(url + '/' + formAPIName, body, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.refreshDynamicList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /**
   * Logs the user out and clears local storage.
   */
  logOut() {
    this.router.navigate(['login']);
    localStorage.clear();
    sessionStorage.clear();
  }

  /**
   * Fetches a list of dynamic data based on search parameters.
   * @param searchValue - The search value to filter the list.
   * @param parameters - Additional parameters for pagination and sorting.
   * @param parentName - The name of the parent entity.
   * @param parentId - The ID of the parent entity.
   * @param tableName - The name of the table.
   * @param apiServerType - The type of the API server.
   */
  public getDynamicList(
    searchValue: any,
    parameters: any,
    parentName: any,
    parentId: any,
    tableName: any,
    apiServerType: any
  ) {
    var url = this.returnNodeurl(apiServerType);
    let params = new HttpParams();
    params = params.set('parentName', parentName);
    params = params.set('parentId', parentId);

    if (searchValue !== '' && searchValue != null) {
      params = params.set('search', searchValue);
    }
    var apiUrl = '/v1/dynamic-form/data/';
    if (apiServerType == 'multiTenantNode' || apiServerType == 'agribio') {
      apiUrl = '/dynamic-form-schema-data/';
    }
    if (parameters) {
      params = params
        .set('startFrom', parameters.startRow)
        .set('perPage', '25')
        .set('columnName', parameters.sortModel[0].colId)
        .set('sortOrder', parameters.sortModel[0].sort);
    }
    return this.http
      .get(`${url}${apiUrl}${tableName}`, {
        observe: 'response',
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /**
   * Deletes a dynamic data entry.
   * @param id - The ID of the data entry to be deleted.
   * @param title - The title of the data entry.
   * @param formSchemaName - The name of the form schema.
   * @param apiServerType - The type of the API server.
   */
  public deleteDynamicData(
    id: any,
    title: any,
    formSchemaName: any,
    apiServerType: any
  ) {
    var url = this.returnNodeurl(apiServerType);
    return this.http
      .delete(url + '/' + formSchemaName + '/' + id, {
        observe: 'response',
      })
      .pipe(
        map((resp: any) => {
          this.refreshDynamicList$.emit('Deleted ' + title);
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /**
   * Fetches a single dynamic data entry by parent.
   * @param parentName - The name of the parent entity.
   * @param formSchemaName - The name of the form schema.
   * @param parentId - The ID of the parent entity.
   */
  public getSingleByParent(
    parentName: any,
    formSchemaName: any,
    parentId: any
  ) {
    let params = new HttpParams();
    params = params.set('parentName', parentName);
    params = params.set('parentId', parentId);

    return this.http
      .get(
        `${environment.magnum_node_url}/v1/dynamic-form/data/${formSchemaName}/first`,
        { observe: 'response', params: params }
      )
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /**
   * Fetches the table column setup based on the API module.
   * @param apiModule - The API module name.
   */
  public getTableColumn(apiModule: any) {
    return this.http
      .get(environment.base_url + '/table-column-setups/by-module?apiModule=' + apiModule)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /**
   * Performs a dynamic search based on various parameters.
   * @param lookupUrl - The lookup URL.
   * @param displayColumn - The column to display.
   * @param searchData - The search data.
   * @param lookupFilter - Additional lookup filter.
   */
  public getDynamicSearch(
    lookupUrl: any,
    displayColumn: any,
    searchData: any,
    lookupFilter: any
  ) {
    return this.http
      .get(
        environment.base_url +
          '/' +
          lookupUrl +
          '?startFrom=1&perPage=50&' +
          displayColumn +
          '=' +
          searchData +
          '&columnName=name&sortOrder=asc' +
          lookupFilter,
        { observe: 'response' }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /**
   * Fetches data by schema for PSS.
   * @param schemas - The schema name(s).
   * @param parentName - The name of the parent entity.
   * @param parentId - The ID of the parent entity.
   */
  public getDataBySchecmaForPSS(
    schemas: any,
    parentName: any,
    parentId: any
  ) {
    let params = new HttpParams();
    params = params.set('schemas', schemas);
    params = params.set('parentName', parentName);
    params = params.set('parentId', parentId);
    return this.http
      .get(environment.multiTenant_node_url + '/dynamic-form-schema-data/data/check', {
        observe: 'response',
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /**
   * Fetches a dynamic form template for reading in PSS.
   * @param parentId - The ID of the parent.
   * @param formSchemaName - The name of the form schema.
   * @param apiServerType - The type of the API server.
   * @param parentName - The name of the parent entity.
   * @param sectionName - Optional section name.
   */
  public getReadingDynamicFormPSS(
    parentId: any,
    formSchemaName: any,
    apiServerType: any,
    parentName: any,
    sectionName: any = null
  ) {
    let params = new HttpParams();
    var url = this.returnNodeurl(apiServerType);
    params = params.set('formSchemaName', formSchemaName);
    params = params.set('parentName', parentName);
    params = params.set('parentId', parentId);
    if (sectionName != null) {
      params = params.set('sectionName', sectionName);
    }

    return this.http
      .get(url + '/dynamic-form-schema/template', {
        observe: 'response',
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /**
   * Fetches a list of dynamic forms in PSS.
   */
  public getReadingDynamicForm1PSS() {
    let params = new HttpParams();
    var url = this.returnNodeurl('magnum');

    return this.http
      .get(url + '/dynamic-form-schema', {
        observe: 'response',
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /**
   * Fetches a dynamic form template for editing in PSS.
   * @param formSchemaName - The name of the form schema.
   * @param id - The ID of the form data.
   * @param apiServerType - The type of the API server.
   */
  public getEditReadingDynamicFormPSS(
    formSchemaName: any,
    id: any,
    apiServerType: any
  ) {
    var url = this.returnNodeurl(apiServerType);
    return this.http
      .get(url + '/dynamic-form-schema-data/' + formSchemaName + '/' + id, {
        observe: 'response',
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          }
          if (resp.status == 403) {
            this.logOut();
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /**
   * Fetches data based on a lookup URL and API server type in PSS.
   * @param lookupUrl - The URL to lookup data.
   * @param apiServerType - The type of the API server.
   */
  public getDataPSS(lookupUrl: any, apiServerType: any) {
    var url = this.returnNodeurl(apiServerType);
    return this.http
      .get(url + '/' + lookupUrl, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /**
   * Uploads an attachment by ID.
   * @param formData - The form data containing the attachment.
   * @param uploadUrl - The URL to upload the attachment to.
   */
  public uploadAttachmentById(formData: any, uploadUrl: any) {
    const headers = new Headers({});
    headers.append('Authorization', 'Bearer ' + this.auth.getToken());
    let options = new RequestOptions({ headers: headers });
    return this.httpOnly
      .post(uploadUrl, formData, options)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /**
   * Deletes an attachment by ID.
   * @param id - The ID of the attachment to be deleted.
   */
  public deleteAttachment(id: any) {
    return this.http
      .delete(environment.base_url + '/attachments/' + id, {
        observe: 'response',
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /**
   * Fetches data by schema for Sterra.
   * @param schemas - The schema name(s).
   * @param parentName - The name of the parent entity.
   * @param parentId - The ID of the parent entity.
   */
  public getDataBySchecmaForSterra(
    schemas: any,
    parentName: any,
    parentId: any
  ) {
    let params = new HttpParams();
    params = params.set('schemas', schemas);
    params = params.set('parentName', parentName);
    params = params.set('parentId', parentId);
    return this.http
      .get(environment.multiTenant_node_url + '/dynamic-form-schema-data/data/check', {
        observe: 'response',
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /**
   * Fetches dynamic form configurations based on job order type ID.
   * @param jobOrderTypeId - The ID of the job order type.
   */
  public getDataBySchecmaForAll(jobOrderTypeId: any) {
    let params = new HttpParams();
    if (jobOrderTypeId != null) {
      params = params.set('jobOrderTypeId', jobOrderTypeId);
    }
    return this.http
      .get(environment.base_url + '/dynamic-form-configs', {
        observe: 'response',
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /**
   * Deletes a dynamic data entry, with additional handling for specific parent entities.
   * @param id - The ID of the data entry to be deleted.
   * @param title - The title of the data entry.
   * @param formSchemaName - The name of the form schema.
   * @param apiServerType - The type of the API server.
   * @param parentName - The name of the parent entity.
   */
  public deleteDynamicData1(
    id: any,
    title: any,
    formSchemaName: any,
    apiServerType: any,
    parentName: any
  ) {
    var url = this.returnNodeurl(apiServerType);
    return this.http
      .delete(url + '/' + formSchemaName + '/' + id, {
        observe: 'response',
      })
      .pipe(
        map((resp: any) => {
          if (parentName == 'jobOrder') {
            this.refreshDynamicList$.emit('Deleted Form');
          } else {
            this.refreshDynamicList$.emit('Deleted Sub Form');
          }
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }
}
