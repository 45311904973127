import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TabView } from "primeng/tabview";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { PermissionService } from "src/app/auth/permission.service";
import {
  COMPONENT_CREATION,
  HELPDESK_APP_EVENTS,
} from "src/app/_models/global.data.model";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { HeaderTitleService } from "src/app/_services/header-title.service";
import { MenuService } from "src/app/_services/menu.service";
import { TicketsService } from "src/app/_services/tickets.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { HelpdeskToastComponent } from "../../helpdesk/helpdesk-toast/helpdesk-toast.component";
import { TranslateService } from "@ngx-translate/core";
import { DynamicLabelsService } from "src/app/_services/dynamic-labels.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
import * as _ from "lodash";
import { LanguageDataModelForTabs, TabDetailsResetter, ticketTabDetails } from "src/app/_models/tabs.data.model";
import { N } from "@fullcalendar/core/internal-common";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-view-ticket",
  templateUrl: "./view-ticket.component.html",
  styleUrls: ["./view-ticket.component.scss"],
})
export class ViewTicketComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy, AfterViewInit
{
  activeIndex: any;
  public loadDetail = false;
  public loadConversation = false;
  public loadJobOrder = false;
  public loadTicketHistory = false;
  public loadSLA = false;
  public loadTicketAttachment = false;

  public items: any[] = [];

  public id: any;

  public ticketData: any;

  // data will be add dynamically on dynamic ticket view - via helpdesk ticket click
  public genericData: any;

  public isLoadedDynamically = false;

  public isHeaderValue = false;
  public headerValue = "";
  public toggleEditVisibility = false;

  @ViewChild(TabView, { static: false }) tabView!: TabView;

  menuType: any;
  refreshMenuType!: Subscription;
  salePer: boolean = false;
  servicePer: boolean = false;
  jobsAccess: any;

  update: any;
  delete: any;

  canClaim = false;

  @ViewChild("componentContainer", { read: ViewContainerRef }) container: any;

  private componentRef!: ComponentRef<any>;
  translate: any;
  loadTabs:any[] = []
  contractLangData: any; // to load language translated data for
  tabMenu: any[];

  constructor(
    private breadCrumb: BreadcrumbService,
    private deleteRecordService: DeleteRecordService,
    private route: ActivatedRoute,
    private router: Router,
    private ticketService: TicketsService,
    private util: UtilServiceService,
    private headerTitleService: HeaderTitleService,
    private menuService: MenuService,
    private auth: AuthenticationService,
    private perm: PermissionService,
    private resolver: ComponentFactoryResolver,
    private languageTranslateService: LanguageTranslateService,
    private spinner: NgxSpinnerService
  ) {
    super();
    this.spinner.show();
    this.preInit();
  }

  ngOnInit(): void {
    this.init();
  }

  ngAfterViewInit(): void {
    this.afterInit();
  }

  ngOnDestroy(): void {
    TabDetailsResetter.resetHighlights();
    this.onDestory();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.refreshMenuType = this.headerTitleService.refreshMenuType$.subscribe(
      (res: any) => {
        this.menuType = res;
      }
    );

    this.delete = this.perm.capable("tickets", "remove");

 
    const sub = this.util.getData().subscribe((data: any) => {
      if (data) {
        if (data.action === HELPDESK_APP_EVENTS.CLEAR_HELPDESK_COMPONENT) {
          this.clearComponent();
        }

        if (data.action === HELPDESK_APP_EVENTS.ON_CLAIM_SUCCESS) {
          this.getData(this.id).then(() => {
            this.processClaim();
          })
        }
      }
    });
  }

  /******************************************** Init ******************************************/
  private init() {
    this.update = this.perm.capable("tickets", "update");

    let profileInfo = JSON.parse(this.auth.getProfileInfo());

    this.salePer = this.menuService.findMenuPermissionsSALES(
      profileInfo.tenantCategories
    );
    this.servicePer = this.menuService.findMenuPermissionsSERVICE(
      profileInfo.tenantCategories
    );

    this.jobsAccess = this.perm.capable("job_order", "read");

    this.id =
      this.route.snapshot.queryParamMap.get("id") ?? this.genericData.id;

    if (this.genericData) {
      this.isLoadedDynamically = true;
    }

    this.loadTabs = ticketTabDetails


    this.push(
      this.languageTranslateService.wordSrc$.subscribe(data => { 
        if(Object.keys(data).length != 0){
          this.translate = data
          //this.contractLangData= data?.contracts;
          this.loadTabs.forEach(element => {
            element.headerName =   this.languageTranslateService.getNestedValue(this.translate, element.translatePath)
          });
          
          this.getData(this.id).then(() => {
            if (this.isLoadedDynamically === false) {
              setTimeout(() => {
                this.breadCrumb.setItems([
                  {
                    label: this.translate?.ticket.label.plural,
                    routerLink: ["/dashboard/tickets"],
                    meta: { navigation: { root: true } },
                  },
                  {
                    label: this.ticketData.ticketNo,
                    meta: { navigation: { include: true } },
                  },
                ]);
              }, 400);
            }

            this.items = [
              {
                id: "btn_TixDelete",
                label: this.translate?.general.buttons.delete.singular,
                command: (e: any) => {
                  this.menuAction("delete");
                },
                visible: this.delete,
              },
            ];
        
      
            setTimeout(() => {
              this.processClaim();
            }, 500);
          });
          
        }
      })
    )


  

    //this.headerValue = "Details";

    this.activeIndex =
      localStorage.getItem("activeIndex") == undefined
        ? 0
        : Number(localStorage.getItem("activeIndex"));

    setTimeout(() => {
      this.loadData(this.activeIndex, this.tabView);
    }, 2000);
  }

  private afterInit() {
    this.menuType = this.headerTitleService.getCurrentMenuType();
  }

  /******************************************** Get Data ******************************************/
  private getData(id: any) {
    return new Promise<void>((resolve, reject) => {
      // get ticket data
      this.push(
        this.ticketService.getTicketById(this.id).subscribe((resp: any) => {
          this.ticketData = resp;
          resolve();
        })
      );
    });
  }

  /******************************************** Process Claim ******************************************/
  private processClaim() {
    if (this.ticketData) {
      if (this.ticketData.ticketOwner && this.ticketData.ticketOwner.id) {
        this.canClaim = false;
      } else {
        this.canClaim = true;
      }
    }
  }

  /******************************************** On Destroy ******************************************/
  private onDestory() {
    this.breadCrumb.setItems([]);

    if (this.refreshMenuType) {
      this.refreshMenuType.unsubscribe();
    }

    super.ngOnDestroy();
  }

  /******************************************** On Load Tab Header ******************************************/
  private loadData(e: any, tabView: TabView) {
    if (this.tabView.tabs[e]) {
      const headerValue = this.tabView.tabs[e].header;
      this.setTabHeader(headerValue);
    } else {
      const headerValue = this.tabView.tabs[0].header;
      this.setTabHeader(headerValue);
    }
  }

  /******************************************** On Tab Change ******************************************/
  handleChange(e: any, tabView: TabView) {
    console.log(e, "handle CHnge", e.index)
    let changedTabIndex;
    if (e.index >= 0) {
      changedTabIndex = e.index
    }
    else {
      changedTabIndex = e
    }
    const currentTabHeader = this.tabView.tabs[changedTabIndex].header; // Access untranslated header
    localStorage.setItem("activeIndexTicket", changedTabIndex);
    console.log(changedTabIndex, "====", currentTabHeader);
   
    this.activeIndex = changedTabIndex;
    const tabHeader = currentTabHeader
   // console.log(tabHeader, "====", this.activeIndex);
    this.setTabHeader(tabHeader);
    
  }

  setTabHeader(headerValue: any) {
    console.log(this.loadTabs,"====================",this.translate)
    this.getLanguageForMenu()
    var filteredModule = this.util.setTabHeaderData(headerValue , this.tabMenu, this.loadTabs, this.translate.ticket.sections);
    
    console.log("Match=====,",filteredModule,"====================",this.tabMenu)
    
    // get Tab header name and comapare with the module name
    this.loadTabs.forEach(element => {
      if(filteredModule.plural == element.headerName || filteredModule.details == element.headerName || filteredModule.history == element.headerName || filteredModule.selectedSubTab == element.headerName){ element.highlight =  true};
    });
    this.handleEditVisibility()
   // this.spinner.hide();
    console.log(filteredModule)
    
  }


  getLanguageForMenu() {
   
    var data: any = new LanguageDataModelForTabs(this.translate);
    this.tabMenu = [...data.moduleData];
      _.remove(this.tabMenu, (n: any) => n == undefined);
     console.log(this.tabMenu);
   
  }

  /******************************************** Handle Edit Visibility ******************************************/
  private handleEditVisibility() {
    if (this.headerValue === "Details") {
      this.toggleEditVisibility = true;
    } else {
      this.toggleEditVisibility = false;
    }
  }

  /******************************************** Three Dot Action ******************************************/
  public menuAction(action: string) {
    if (action === "delete") {
      const data = {
        deleteHeader : this.translate.ticket.label.singular,
        deleteType: "Ticket",
        id: this.id,
        message: this.translate?.general.messages.confirmDelete+" " + `${this.ticketData.ticketNo} - ${this.ticketData.subject}?`,
      };

      this.push(
        this.deleteRecordService.getItems(data).subscribe(() => {
          if (this.isLoadedDynamically) {
            this.util.sendData({
              action: HELPDESK_APP_EVENTS.ON_DELETE_TICKET,
              data: {
                id: data.id,
              },
            });
          } else {
            this.router.navigate(["dashboard/tickets"]);
          }
        })
      );
    }
  }

  /******************************************** Edit ******************************************/
  public editTicket() {
    this.router.navigate(["dashboard/tickets/update/" + this.id], {
      queryParams: { id: this.id },
    });
  }

  /******************************************** Claim Ticket ******************************************/
  public claimTicket($event: any) {
    console.log($event);

    const dataToast = {
      coordinates: { x: 5, y: 55 },
      assigneeUser: this.auth.getUserId(),
      action: "Claim",
      rowData: {
        data: { id: this.ticketData.id },
      },
    };

    this.clearComponent().then(() => {
      this.createComponent(COMPONENT_CREATION.HELPDESK_TOAST, dataToast);
    });
  }

  private clearComponent() {
    return new Promise<void>((resolve, reject) => {
      if (this.container) {
        this.container.clear();

        if (this.componentRef) {
          this.componentRef.destroy();
        }
      }

      resolve();
    });
  }

  // pass any data to insert into component
  private createComponent(createWhat: COMPONENT_CREATION, genericData: any) {
    let factory: ComponentFactory<any>;

    if (createWhat === COMPONENT_CREATION.HELPDESK_TOAST) {
      factory = this.resolver.resolveComponentFactory(HelpdeskToastComponent);
      this.componentRef = this.container.createComponent(factory);
      console.log(this.componentRef);
      this.componentRef.instance.genericData = genericData;
      this.componentRef.instance.asIsComponent = true;
    }
  }

  push(obs:any) {
    super.push(obs);
  }
}
