import { Component, OnInit , Output, EventEmitter, Input, ChangeDetectorRef, OnChanges, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import { AuthenticationService } from '../../../auth/authentication.service';
import { AppComponent } from '../../../app.component';
import { ErrorUtil } from 'src/app/_utilities/error';
import { DynamicFormService } from 'src/app/_services/dynamic-form.service';
import { Address } from 'src/app/shared/ngx-google-places-autocomplete/objects/address';
import { NgxSpinnerService } from 'ngx-spinner';
import * as FileSaver from 'file-saver';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css']
 })
export class DynamicFormComponent extends SubscriptionUtil implements OnInit, OnChanges, OnDestroy {
  @Output() submitForm: EventEmitter<any> = new EventEmitter<any>();

  @Input() data :any;
  @Input() _id :any;
  @Input() fields: any;
  @Input() mode:any
  @Input() columnDisplaystyle :any = '1';
  dynamicForm!: UntypedFormGroup;
  public defaultDate: Date = new Date();
  submitTouched: boolean = false;
  myGroup!: UntypedFormGroup
  ruleMap :any
  modifiedField: any = [];
  validateName: any;
  displayLookup: boolean = false
  dateConfig: any;
  selectData: any;
  dateFormat: any;
  value!: Date
  timezone: any
  translate: any;

  defaultCheckBoxValue: any =[
    {label:'Checked', value: true},
    {label:'UnChecked', value: false}

  ]

  constructor(
    private fb: UntypedFormBuilder,
    private errorUtil: ErrorUtil,
    private  changeDetectorRef: ChangeDetectorRef,
    private dynamicFormService : DynamicFormService,
    private auth : AuthenticationService,
    private spinner: NgxSpinnerService,
    private languageTranslateService: LanguageTranslateService

    ) {
      super()
      
      this.ruleMap = {
        minLength: Validators.minLength,
        maxLength: Validators.maxLength,
        required: Validators.required,
        email : Validators.pattern(AppComponent.emailPattern),
        phone : Validators.pattern(AppComponent.phonePattern)
      };

      this.validateName = {
        minLength: 'minlength',
        maxLength: 'maxlength',
        required: 'required',
        email : 'pattern',
        phone : 'pattern'
      };

     }

     ngOnChanges(): void {
      this.changeDetectorRef.detectChanges();
    }

  ngOnInit() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
    this.timezone =this.auth.getUserTimezone()
    this.dateFormat = localStorage.getItem('date_format')
    this.dynamicFormService.valueChanged = () => {
      
     this.onFormSubmit()
  };


    console.log(this.fields)
    if(this.fields){
     // this.dateConfig = { dateInputFormat: this.dateFormat.toUpperCase()};
          this.fields.data[0].steps.forEach((element :any) => {
            element.sections.forEach((fields :any) => {
              this.modifiedField.push(fields)
              
                });
              });
          this.dynamicForm = this.createControl();

/********************************************************* Add Mode *******************************************************/
          if(this.mode == "add"){
            this.displayLookup = true
  
            this.modifiedField.forEach((element : any )=> {
              if(element.fields){
                element.fields.forEach((column : any ) => {
  
                  if(column.readOnly){
                    this.dynamicForm.controls[column.name].disable()
                  }
                  if(column.componentType == 'Select' || column.componentType == 'PickList'){
                    column.placeholder = column.placeholder ? column.placeholder : 'Select '+column.label
                  }
  
                    this.createSelectData(column, column.defaultValue)
                      if(column.defaultValue != null){
                       
                        if(column.componentType == 'DateTimePicker'){

                          const tempTime = column.defaultValue;
                          const time = moment(tempTime).format("HH:mm:ss");

                          const date = moment(column.defaultValue).format(
                            "YYYY-MM-DD"
                          );

                          let isoTimestamp = `${date}T${time}`;
                          //column.value =  new Date(column.defaultValue)
                          column.value = moment(isoTimestamp).toDate();
                          
                        console.log(date,"----",time,"====",moment(isoTimestamp).toDate(),"=====>>>>",column.value)
                        }else if(column.componentType == 'DatePicker'){
                          
                          column.value=new Date( column.defaultValue)
                        }else if(column.componentType != 'DateTimePicker'){
                          console.log(column.componentType,"--->",column.defaultValue)
                          
                          column.value = column.defaultValue
  
                        }
  
                      }else {
  
                        if( column.componentType =='PlaceAutoComplete'){
                          column.value = { "description": null, "location": { "lat": null, "lng": null }, "place_id": null }
                        } else if( column.componentType =='CheckBox'){
                          column.value = false
                        }else{
                          if(column.componentType != 'Select'){
                            //////debugger
                             column.value = column.componentType != 'PickList' ? null : {value:null}
                          }
                        }
  
                      }
                      if(column.componentType == 'PickList'){
                          if(column.defaultValue){
                            column.value = column.defaultValue
                          }else{
                          if(column.parentField){
                            this.getPicklistOption(column)
                          }else{
                            if(column.multiSelect){
                              ////debugger
                              var newData: never[]=[]
                              column.value = newData
                            }
                            else{
                              column.value = {value: null}
                              ////debugger
                            }
                            console.log( column)
                          }
                        }
                      }
                    });
                }
            });
          }
        console.log(this.modifiedField)

          if(this.mode == "edit"){
            console.log( this.fields.data[0])
            ////debugger
            var apiUrl = '/v1/dynamic-form/data/'
            if(this.fields.data[0].formSchemaName == 'PSSServiceOrder' || this.fields.data[0].formSchemaName == 'PSSResource' || this.fields.data[0].formSchemaName == 'PCServiceReport' || this.fields.data[0].formSchemaName == 'PCPesticides' || this.fields.data[0].formSchemaName == 'SterraOnLoan' || this.fields.data[0].formSchemaName == 'SterraOnLoanUnit'
             || this.fields.data[0].formSchemaName == 'SterraRetrieval'  || this.fields.data[0].formSchemaName == 'SterraRetrievalUnit'  || this.fields.data[0].formSchemaName == 'SterraInstallation'  
             || this.fields.data[0].formSchemaName == 'SterraOnSiteRepair' || this.fields.data[0].formSchemaName == 'SterraOnSiteRepairUnit'
             || this.fields.data[0].formSchemaName == 'SterraOneToOneExchange' || this.fields.data[0].formSchemaName == 'SterraOneToOneExchangeUnit'
             || this.fields.data[0].formSchemaName == 'SterraReturnToCustomer' || this.fields.data[0].formSchemaName == 'SterraReturnToCustomerUnit'
             || this.fields.data[0].formSchemaName == 'SterraInstallationUnit' || this.fields.data[0].apiServerType == "multiTenantNode"){ 
              apiUrl = '/dynamic-form-schema-data/'
            }
            this.dynamicFormService.getEditReadingDynamicForm(this.fields.data[0].formSchemaName,this._id,this.fields.data[0].apiServerType, apiUrl).subscribe((res:any) => {
              console.log(res)

              const keys = Object.keys(res.data);
              for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                //console.log(key,"==============", res.data[key]);

                this.modifiedField.forEach((element  :any) => {
                  if(element.fields){
                      element.fields.forEach((column :any) => {

                          if(column.readOnly){
                            this.dynamicForm.controls[column.name].disable()
                          }

                            if(key == column.name){

                                column.value = res.data[key]
                                //////////////////////Conver Date Time value
                                  if(column.componentType == 'DateTimePicker'){
                                    column.time = null
                                      if(column.value != null){
                                    column.value =  new Date(column.value)
                                    column.time= moment(column.value).format('HH:mm');
                                    console.log(column.value,"----",column.time)
                                      }
                                    }
                                    //////////////////////Conver Date value
                                    if(column.componentType == 'DatePicker'){
                                      if(column.value != null){
                                        column.value =  new Date(column.value)
                                        }

                                    }
                                     if(column.componentType == 'TimePicker'){

                                      column.value =  new Date(column.value)
                                      //column.value= moment(column.value).format('HH:mm');
                                      console.log(column.value,"----",column.time)
                                      ////debugger
                                     }
                                  
                                    if(column.componentType == 'PickList'){
                                      console.log(column)
                                      ////debugger
                                      if(column.parentField){
                                        
                                        this.getPicklistOption(column)
                                        ////debugger
                                      }else{

                                        if(column.multiSelect){
                                          ////debugger
                                          var newData: any[]=[]
                                          column.value.forEach((element :any)=> {  newData.push(element.value)});
                                          column.value = newData
                                        }
                                        else if(column.value == null){
                                          column.value = {value: null}
                                          ////debugger
                                        }else{
                                          console.log( column)
                                          ////debugger
                                        }
                                        console.log( column)
                                      }
                                    }
                                    if(column.componentType == 'PickListButton'){
                                      console.log(column)
                                      
                                    
                                        if(column.multiSelect){
                                          
                                          var newData: any[]=[]

                                          column.options.forEach((element  : any)=> {
                                            element.check = false
                                            if(column.value != null){
                                            column.value.forEach((elemenPT  : any)=> {
                                              if(element.value == elemenPT.value){
                                                element.check = true
                                              }
                                             
                                            })
                                          }else{
                                            column.value=null
                                          }
                                          
                                          })

                                          
                                          //column.value.forEach((element :any)=> {  newData.push(element.value)});
                                          //column.value = newData
                                          console.log( column)
                                          
                                        }
                                        else if(column.value == null){
                                          column.value = {value: null}
                                          
                                        }else{
                                          console.log( column)
                                          
                                        }
                                        
                                      
                                    }
                              this.createSelectData(column,res.data[key])
                            }

                          });
                    }
                });

                }
                this.displayLookup = true
            });
          }

    }

  }
  changeIdBaseOnlookupServerType(defaultValue: any, lookupServerType: any){
    if(lookupServerType == "java"){
      return 'id'
    }else{
      return '_id'
    }
  }
  createSelectData(column: any, data:any = null){
    if(column.componentType == 'Select'){
      console.log(column,"^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^")
      //================== Setup value for drop down
        if(data == null){
          column.value = {id:null}
        }else{
          column.value = data
        }

      this.dynamicFormService.getData(column.lookupUrl,column.lookupServerType).subscribe((res:any)=>{
        column.selectData=res
        column.selectData.forEach((element :any) => {
          if(element.name == undefined){
            element.name = element.fullName
          }
        });
       });


    }
  }


  createControl() {
    const group = this.fb.group({});
    this.modifiedField.forEach((section :any)=> {
      if(section.fields){
              section.fields.forEach((field :any)=> {
                    if (field.validations !== undefined && field.validations != null) {

                      const control = this.fb.control(
                        field.name,
                        this.bindValidations(field.validations.rules || [])
                      );
                      group.addControl(field.name, control);
                    }else{
                      const control = this.fb.control(
                        field.name,
                        this.bindValidations([])
                      );
                      group.addControl(field.name, control);
                    }
                  });
            }
        });
    return group;
    
  }


  getPicklistOption(columnData : any){
    console.log(columnData)
    var optionsData=columnData.options
    columnData.options = null
    var newOption: any[] =[]
    this.modifiedField.forEach((element  : any)=> {
      //////debugger
      if(element.fields){
       // ////debugger
          element.fields.forEach((column  : any)=> {
            
            if(column.multi){
              column.value = []
              console.log(column)
              ////debugger
            }else{
                          if(columnData.parentField == column.name && column.value != null){
                            console.log(columnData.parentField,"==============",column.name,"====",column.value);
                            ////debugger
                            const keys = Object.keys(column.value);
                              for (let i = 0; i < keys.length; i++) {
                                const key = keys[i];
                                console.log(key,"^^^^^^^^",columnData.parentFieldColumn)
                                  if(key == columnData.parentFieldColumn){
                                    console.log(key,"=====",column.value[key])
                                    optionsData.forEach((elementOPT : any) => {
                                      elementOPT.parentOptions.forEach((elemenPT  : any)=> {
                                        if(elemenPT.toUpperCase() == column.value[key].toUpperCase()){
                                          newOption.push(elementOPT)
                                        }
                                       })

                                     })
                                     console.log(newOption)
                                     columnData.options = newOption
                                  }
                              }
                            } if(column.value == null && column.componentType == 'PickList'){

                              column.value = {value: null}
                              ////debugger
                            }
                          }


              });
        }
    });
    console.log(columnData)
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList:any = [];
      validations.forEach((valid :any) => {
        if(valid.name != 'nullable'){
            console.log(valid.name,"=================>>>>>>>")
            valid.validateName = this.validateName[valid.name]
          if(valid.name == "minLength" || valid.name == "maxLength"){

            validList.push(this.ruleMap[valid.name](valid.params[0]));
          }else{
            validList.push(this.ruleMap[valid.name]);
          }
        }

      });
      return Validators.compose(validList);
    }
    return null;
  }

  gotSelectedData(val :any){
    console.log(val,"************")
    if(val.data !=null){
    console.log(this.modifiedField[val.parent].fields[val.child].value,"=====",this.modifiedField[val.parent].fields[val.child].controllingFields,"====",this.modifiedField[val.parent].fields[val.child].controllingFields.length)
    if(this.modifiedField[val.parent].fields[val.child].controllingFields !=null && this.modifiedField[val.parent].fields[val.child].controllingFields.length !=0){
     /// this.modifiedField[val.parent].fields[val.child].value
     ////debugger
var controllingFields = this.modifiedField[val.parent].fields[val.child].controllingFields
     const keys = Object.keys(controllingFields);
     for (let i = 0; i < keys.length; i++) {
       const key = keys[i];
       console.log(key,"==============", controllingFields[key].fieldName);

       this.modifiedField.forEach((element :any) => {
         if(element.fields){
             element.fields.forEach((column :any) => {

                   if(controllingFields[key].fieldName == column.name){
                      //  console.log(column.value,"===>>>", val.controllingFields[key].valueColumn, "******",controllingFields[key].valueColumn)
                     column.value = val.data[controllingFields[key].valueColumn]
                     console.log(column.value,"===>>>")

                    }

                 });
           }
       });

       }

    }else{
     
    ///  this.modifiedField[val.parent].fields[val.child] = {...this.modifiedField[val.parent].fields[val.child],value: val.data }
     console.log("=====>>>>>",this.modifiedField[val.parent].fields[val.child])
   
    }
   
    }else{
        this.modifiedField[val.parent].fields[val.child] = {...this.modifiedField[val.parent].fields[val.child],value: null }
      console.log("=====>>>>>",this.modifiedField[val.parent].fields[val.child])
    
    }
    //console.log(this.modifiedField[val.parent].fields[val.child])
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control!.markAsTouched({ onlySelf: true });
    });
  }

  onFormSubmit() {
    //this.validateAllFormFields(this.dynamicForm);


    console.log(this.fields);
    this.submitTouched=true;
    if(!this.dynamicForm.valid){
      for (var i in this.dynamicForm.controls) {
        this.dynamicForm.controls[i].markAsTouched();
      }
      this.submitTouched = false;
      return false;
    }

    var jsonData: any = {};
    var isEmptyFileUpload : boolean =false;
    this.modifiedField.forEach((element :any) => {
      if(element.fields){
        element.fields.forEach((column  :any)=> {

                      var columnName = column.name;

                      console.log(column.componentType)
                      if(column.componentType == 'DatePicker'){
                          if(column.value != null){
                            console.log(this.dateFormat,"====")
                            //moment(this.payment.dueDate, this.dateFormat.toUpperCase()).utc().format();
                            jsonData[columnName] = moment(column.value, this.dateFormat.toUpperCase()).utc().format();

                          }else{
                            jsonData[columnName] = null;
                          }
                        }else if(column.componentType == 'DateTimePicker'){
                          if(column.value != null){
                           // console.log("**********************")
                          //  jsonData[columnName] = new Date(moment.tz(moment(column.value), "YYYY-MM-DDTHH:mm:ssZ", this.auth.getUserTimezone()).format('YYYY-MM-DDTHH:mm:ss'));

                          const tempTime = column.value
                          const time = moment(tempTime).format("HH:mm:ss");

                          const date = moment(column.value).format(
                            "YYYY-MM-DD"
                          );

                          let isoTimestamp = `${date}T${time}`;

                          column.value = moment(isoTimestamp).toDate();
                          
                            // var pickUpStartTime = moment(moment(column.value).format('YYYY-MM-DD') + ' ' + column.time);
                            // var  pickUpStartTimeData: any = moment.tz(pickUpStartTime, this.auth.getUserTimezone());
                            jsonData[columnName] = column.value

                          }else{
                            jsonData[columnName] = null;
                          }
                        }else if(column.componentType == 'Select'){
                          console.log(column.value,"*************************")
                          if(column.value == null || column.value.id == null){
                           jsonData[columnName] = null;
                          }else{
                            jsonData[columnName] = column.value
                          }
                        }else if(column.componentType == 'PickList'){
                          console.log(column.value)
                          ////debugger
                          if(column.multiSelect){
                            var vehicleTypeGroup: any[] =[]
                            console.log(column.value)
                            
                            if(column.value){
                                  column.value.forEach((element : any) => {


                                    let selectrdData = _.filter(column.options, (o) => {
                                      return o.value == element;
                                    });
                                    var temp={value:null,label:null}
                                    console.log(selectrdData[0],"============")
                                    //dataInfo.value = selectrdData[0]
                                    if(selectrdData[0] == undefined ){
                                      temp = {value:null,label:null}
                                      console.log(temp.value)
                                    ////debugger
                                    }else{
                                      temp = selectrdData[0].value == null ? {value:null,label:null} : {value:selectrdData[0].value,label:selectrdData[0].label} 
                                      console.log(temp)
                                    ////debugger
                                    }




                                    // var temp={value:element}
                                    vehicleTypeGroup.push(temp);
                                  });
                                  jsonData[columnName] = vehicleTypeGroup
                              }
                          }else{

                          if(column.value.value == null){
                            jsonData[columnName] = null;
                           }else{
                             jsonData[columnName] = column.value
                           }
                          }

                           console.log(column.value)
                          ////debugger
                      
                      }else if(column.componentType == 'FileUpload'){
                        console.log(column)
                        if(column.validations != null && column.validations.rules.length > 0 &&  column.validations.rules[0].validateName == "required" && column.value == null)
                        {
                          this.errorUtil.setErrorMessage(400, null ,  "Must Upload a File to " + column.label, 'error',3000);
                          isEmptyFileUpload = true;
                        }
                        else{
                          if(column.value == null){
                            jsonData[columnName] = null;
                           }else{
                             jsonData[columnName] = column.value
                           }
                        }
                        
                    
                    }else{
                        jsonData[columnName] = column.value;
                      }


            });
          }
    });
     console.log(jsonData)
     if(!isEmptyFileUpload){
      this.submitForm.emit(jsonData);
     }

  }

  onPickDateChange(newDate: Date,field :any) {
  // console.log(newDate)
}
getDataInfo(dataInfo :any,event :any){
  console.log(event,"====",dataInfo)
  let selectrdData = _.filter(dataInfo.selectData, (o) => {
    return o.id == event.value;
  });
  var dataInfoValue : any=null;

  console.log(selectrdData[0])
  if(selectrdData[0] == undefined ){
    dataInfoValue = {id:null,name:null}
  }else{
    dataInfoValue = selectrdData[0].id == null ? {id:null,name:null} : selectrdData[0]
    }
    dataInfo.value={...dataInfoValue}
console.log(dataInfo.value)

}

getDataPickListInfo(dataInfo :any,event :any){
  console.log(event,"====",dataInfo)
  let selectrdData = _.filter(dataInfo.options, (o) => {
    return o.value == event.value;
  });
  console.log(selectrdData[0],"============")
  //dataInfo.value = selectrdData[0]
  if(selectrdData[0] == undefined ){
    dataInfo.value = {value:null,label:null}
    console.log(dataInfo.value)
  ////debugger
  }else{
     dataInfo.value = selectrdData[0].value == null ? {value:null,label:null} : {value:selectrdData[0].value,label:selectrdData[0].label} 
   // dataInfo.value = selectrdData[0]
   console.log(dataInfo.value)
  ////debugger
  }
console.log(dataInfo.value)


}


/******** Picklist multiple ********/

getMultiDataPickListInfo(dataInfo : any,event : any){

  let vehicleTypeGroup = [];

  console.log(event[0],"====",dataInfo)

    // dataInfo.options.forEach((element) => {
    //   console.log(event[0].value,"====",element.value)
    //     if(element.value == event[0].value)
    //        console.log(element)
          //vehicleTypeGroup.push(event[0].value);
        //});

  //dataInfo.value = vehicleTypeGroup;
  console.log(dataInfo.value)

}


getAnswer(userAnswer: any,child: any,parent: any){
  console.log(userAnswer)

  this.modifiedField[parent].fields[child].value = userAnswer
  console.log(this.modifiedField[parent].fields[child].value)

}
getAnswerMuliSelect(userAnswer: any,child: any,parent: any){
  console.log(userAnswer)
  var newDataSelected: any[] =[] 
  if( this.modifiedField[parent].fields[child].value){

      var inde = _.findIndex(this.modifiedField[parent].fields[child].value, (row:any) => {
        return row.value == userAnswer.value;
      });
      console.log(inde)

      if(inde>-1){
        ////debugger
        userAnswer.check = false
        this.modifiedField[parent].fields[child].value.splice(inde, 1) 
      }else{
        userAnswer.check = true
        this.modifiedField[parent].fields[child].value.forEach((elemenPT  : any)=> {
          newDataSelected.push(elemenPT)
        })
        newDataSelected.push(userAnswer)
        this.modifiedField[parent].fields[child].value = newDataSelected
      }
  }else{
    userAnswer.check = true
    newDataSelected.push(userAnswer)
    this.modifiedField[parent].fields[child].value = newDataSelected
  }
  
  
  
  console.log(this.modifiedField[parent].fields[child].value)

}

resetAddress(child: any,parent: any){
  this.modifiedField[parent].fields[child].value.pickupAddress='';
  this.modifiedField[parent].fields[child].value.location.lat='',
  this.modifiedField[parent].fields[child].value.location.lat=''
  this.modifiedField[parent].fields[child].value.place_id = ''
}
public handleAddressChange(address: Address,child: any,parent: any) {
  // Do some stuff
  console.log("address changed ", address);
  this.modifiedField[parent].fields[child].value.description = address.formatted_address;
  if(address.types.includes('establishment')){
    this.modifiedField[parent].fields[child].value.description = address.name + ', ' + address.formatted_address;
  }
  else {
    this.modifiedField[parent].fields[child].value.description = address.formatted_address;
  }
  this.modifiedField[parent].fields[child].value.place_id = address.place_id;
  this.modifiedField[parent].fields[child].value.location.lat = address.geometry.location.lat();
  this.modifiedField[parent].fields[child].value.location.lng = address.geometry.location.lng();
  console.log(this.modifiedField[parent].fields[child].value)
}


onKeypressEvent(event: any,field: any){
  if(event.target.value.length == field.length) return false;
}



public uploadFile($event: any,type : any) {
      this.spinner.show();
    console.log(type);
      this.parse($event.target.files,type);
    }
openFileUploader(field : any){
  console.log(field)
  $("#"+field.name).click();

}
parse(files: FileList,field : any): void {
  this.spinner.show();
  console.log(field.value,"======")
  if(field.value== null){
    field.value = []
  }
  let file: any = files.item(0);
  let formData = new FormData();
  formData.append('file', file);
  this.dynamicFormService.uploadAttachmentById(formData,field.uploadUrl).subscribe((res:any)=>{
    var body = JSON.parse(res._body);
    body.fileName = file.name;
    console.log(body)
     field.value.push(body);
    $("#"+field.name).val('');
    this.spinner.hide();

  });
}

public downloadAttachment(attachment: any,field: any,ind: any) {
  this.spinner.show();
  let fileName = "";
  let fileType = "";
  let url = "";
  try {
    fileName = attachment.fileName;
    if (fileName)
      fileType = (attachment.fileName as string)
        ? (attachment.fileName as string).split(".")[
            (attachment.fileName as string).split(".").length - 1
          ]
        : "";
    url = attachment.attachmentUrl;

    if (fileName && fileType) {
      this.spinner.hide();
      FileSaver.saveAs(url, fileName);
    } else {
      throw new Error("Cannot find filename and filetype");
    }
  } catch (e) {
    url = attachment.attachmentUrl;
    fileName = (url as string)
      ? (url as string).split("/")[(url as string).split("/").length - 1]
      : "";
    fileType = (fileName as string)
      ? (fileName as string).split(".")[(url as string).split(".").length - 1]
      : "";

      this.spinner.hide();
      FileSaver.saveAs(url, fileName);
  }
}

deleteAttchment(attachment: any,field: any,ind: any): void {
  this.spinner.show();
  console.log(attachment)
  this.dynamicFormService.deleteAttachment(attachment.id).subscribe((res:any)=>{

    field.value.splice(ind,1)
     this.spinner.hide();

  });
}

/******************************************** change end time when start time adjusted ******************************************/
public changeEndTime(field: any) {
  
  console.log(field)
  //const start = moment(this.addTask.startTime, "h:mm A").format("H:mm");
 
//  this.addTask.endTime = endTime.toDate();


}

push(obs:any) {
  super.push(obs);
}

ngOnDestroy(): void {
  super.ngOnDestroy();
}

}

