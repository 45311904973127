import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EngLangData } from 'src/app/_models/global.data.model';
import { DynamicFormSetupService } from 'src/app/_services/dynamic-form-setup.service';

@Component({
  selector: 'app-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss']
})
export class PageLayoutComponent implements OnInit {

  formStep: any
  data:any
  translate: any;
  addFormSteps : UntypedFormGroup;
  
  constructor(
    public modalRef : BsModalRef,
    private route: ActivatedRoute,
    private dynamicFormSetupService: DynamicFormSetupService
  ) { 
    this.addFormSteps = new UntypedFormGroup({
      'label': new UntypedFormControl(null, [Validators.required,Validators.maxLength(255)]),  
    });
  }

  ngOnInit(): void {
    this.formStep = this.data.formStep;
    this.route.queryParams.subscribe(params => {
      this.translate = EngLangData;
      console.log(this.data)
    })
  }
  setFormLayoutType(layoutType:any){
    this.formStep.layoutType = layoutType;
  }
  saveData(){
    this.modalRef.hide()
   var newSectionData={
    formStep:this.formStep,
    pageIndex:this.data.pageIndex,
    mode : this.data.mode,
    sectionIndex:this.data.mode == 'add' ? null:this.data?.sectionIndex
   }
   
    this.dynamicFormSetupService.selectSectionSettings(newSectionData);
  }
}
