<div class="custella-task-body" *ngIf="task" [ngClass]="{'pt-2':showClose == 'yes'}">
    <div *ngIf="showClose == 'no'" class="d-flex button-box-top-right-two align-items-center">
        <!-- Close Button -->
        <button *ngIf="showClose == 'no'" class="h-30 no-border rounded-1 px-2" [pTooltip]="translate?.general.buttons.close.singular" id="btn_TaskClose" (click)="closeModule()"><i class="pi pi-times"></i></button>
    </div>

    <!-- Task View Header -->
    <div class="position-relative d-block rounded-2">
        <div class="d-flex">
            <!-- LEFT -->
            <div class="w-100">
                <div class="custella-form-container-two position-relative d-block rounded mx-3 border-y-dee border-e-dee box-none" [ngStyle]="{'border-left': 'solid 8px '+selectedPriority?.color }" #topElement>
                    <div class="p-3">
                        <div class="d-flex justify-content-between">
                            <div class="text-18 font-semiBold pe-4 col-7 text-break mt-4px" id="label_TaskName">{{task?.taskName}}</div>
                            <div class="d-flex align-items-center">
                                <button class="custella-btn-icon border rounded-1 px-2 py-1" id="btn_TaskRightNav" (click)="openRightNav()" [pTooltip]="translate?.task.sections.sec1.singular" tooltipPosition="top">
                                    <img src="assets\svg\table\info-circle.svg" alt="Additional Details" class="w-19px" id="img_TaskRightNav">
                                </button>
                                <!-- <p-sidebar [(visible)]="visibleSidebarTask" [fullScreen]="true" [baseZIndex]="10000">
                                    <h3>Full Screen Sidebar</h3>s
                                </p-sidebar> -->
                                

                                 <!-- Request Assistance Button -->
                                <div class="dropdown ms-2">
                                    <div data-bs-toggle="dropdown" [pTooltip]="translate?.task.tooltips.requestAssistance.singular" tooltipPosition="top">
                                        <button 
                                            [disabled]="(task?.assignedBy?.id == auth.getUserId() && task?.assignedTo?.id == auth.getUserId()) || assistancePerm || !update"
                                            class="custella-btn-icon border rounded-1 px-2 py-1" id="btn_TaskReqAssist"
                                        >
                                        <i class="fa-regular fa-hand"></i>
                                        <!-- <img src="assets\svg\table\hand.svg"  alt="Request Assitance" id="img_TaskReqAssist" class="w-19px"> -->
                                    </button>
                                    </div>
                                    <ul class="dropdown-menu dropdown-collaps p-0 w-25">
                                        <li class="flex-between border-bottom bg-header-rounded px-1 py-2">
                                            <div class="ms-2 me-3">
                                                <span class="ps-1 font-white font-medium text-12" id="label_TaskReqAssist" >{{translate?.task.fields.assistanceRequests.singular}}</span>
                                            </div>
                                        </li>
                                        <li class="border-bottom px-1 py-2" *ngFor="let requestAssistance of requestAssistances">
                                            <div class="d-flex align-items-center justify-content-start">
                                                <p-avatar [image]="photoUrl" styleClass="mx-2 mt-2" shape="circle"></p-avatar>
                                                <div class="text-left mx-2">
                                                    <span class="text-wrap text-12 font-black" id="value_TaskReqAssistFullName">{{requestAssistance?.createdBy?.fullName}}&nbsp;{{translate?.task.fields.sentAnAssistanceRequest.singular}}</span>
                                                    <span class="d-block color-var-500 text-10" id="value_TaskReqAssistDateTime">{{requestAssistance?.calendarTime}}, {{requestAssistance?.createdDateTime | momentDate :'hh:mm a' : timezone}}</span>
                                                </div>
                                            </div>
                                            <div class="d-block text-wrap ms-3p5rem">
                                                <span class="font-bold text-12 font-black h-50px overflow-scroll" id="value_TaskReqAssistDesc">{{requestAssistance?.description}}</span>
                                            </div>
                                        </li>
                                        <li class="flex-between border-bottom px-1 py-2">
                                            <div class="d-flex justify-content-start ms-2 me-3 col" *ngIf="auth.getUserId() != task?.assignedBy?.id">
                                                <button class="custella-btn-icon border rounded-1 px-2 py-1 me-1" id="btn_TaskPhoneNumber"><i class="pi pi-phone w-24px" [pTooltip]="translate?.task.tooltips.phoneNumber.singular" tooltipPosition="bottom"></i></button>
                                                <button class="custella-btn-icon border rounded-1 px-2 py-1 me-1" id="btn_TaskEmailUser" (click)="emailUser(requestAssistanceUser?.email)"><i class="pi pi-send w-24px" [pTooltip]="translate?.task.tooltips.emailAssignee.singular" tooltipPosition="bottom"></i></button>
                                                <!-- <span class="mx-1 font-grey-icon" (click)="messageUser(requestAssistanceUser?.id)"><i class="pi pi-comments w-24px" pTooltip="Message Assignee" tooltipPosition="bottom"></i></span> -->
                                                <button class="custella-btn-icon border rounded-1 px-2 py-1 me-1" (click)="messageUser(requestAssistanceUser?.id)" id="btn_TaskMsgAssignee" [pTooltip]="translate?.task.tooltips.messageAssignee.singular" tooltipPosition="bottom"> <i class="pi pi-comments w-24px"></i>  </button>
                                            </div>
                                            <div class="d-flex justify-content-end col" >
                                                <p-button
                                                [disabled]="(task?.assignedBy?.id == auth.getUserId() && task?.assignedTo?.id == auth.getUserId()) || assistancePerm || !update"
                                                [label]="translate?.task.fields.requestAssistance.singular"
                                                (onClick)="openModal('requestAssistance', task)"
                                                id="btn_TaskReqAssist2"
                                            ></p-button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                
                                <!------------------------------------- If status is accepted can able to change to other status -------------------->
                                <div class="dropdown ms-2" *ngIf="task?.taskOwner?.assignedStatus == 'ACCEPTED'">
                                   <div *ngIf="update && task.taskOwner?.assignedTo.id == auth.getUserId()">
                                        <div class="p-component p-dropdown-clearable" data-bs-toggle="dropdown">
                                            <div class="d-flex align-items-center border rounded-2 h-30">
                                                <span *ngIf="taskStatus === 'OPEN'" class="p-mr-2 p-tag p-component p-tag-open m-2px" id="tag_TaskAcceptOpen"><span class="p-tag-value text-dark">{{translate?.general.status.open.singular}}</span></span>
                                                <span *ngIf="taskStatus === 'COMPLETED'" class="p-mr-2 p-tag-completed p-tag p-component m-2px" id="tag_TaskAcceptComplete"><span class="p-tag-value">{{translate?.general.status.completed.singular}}</span></span>
                                                <span *ngIf="taskStatus === 'CANCELLED'" class="p-mr-2 p-tag-cancel p-tag p-component m-2px" id="tag_TaskAcceptCancel"><span class="p-tag-value text-dark">{{translate?.general.status.cancelled.singular}}</span></span>
                                                <span *ngIf="taskStatus === 'PENDING'" class="p-mr-2 p-tag-pending p-tag p-component m-2px" id="tag_TaskAcceptPending"><span class="p-tag-value text-dark">{{translate?.general.status.pending.singular}}</span></span>
                                                <span *ngIf="taskStatus === 'IN_PROGRESS'" class="p-mr-2 p-tag p-component p-tag-in-progress m-2px" id="tag_TaskAcceptProg"><span class="p-tag-value text-dark">{{translate?.general.status.inProgress.singular}}</span></span>
                                                <span *ngIf="taskStatus === 'IN_COMPLETED'" class="p-mr-2 p-tag p-component p-tag-incomplete m-2px" id="tag_TaskAcceptIncomplete"><span class="p-tag-value text-dark">{{translate?.general.status.incomplete.singularnslate}}</span></span>

                                                <div role="button" aria-haspopup="listbox" class="p-dropdown-trigger" >
                                                    <span class="p-dropdown-trigger-icon fa-regular fa-chevron-down ms-1 m-4px" id="btn_TaskAcceptDropdown"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <ul class="dropdown-menu dropdown-collaps p-0" >
                                            <li class="dropdown-item px-1 py-2" id="btn_TaskAcceptComplete" (click)="changeTaskStatus('COMPLETED', null)">
                                                <span class="text-12 px-3">{{translate?.general.status.completed.singular}}</span>
                                            </li>
                                            <li class="dropdown-item px-1 py-2" id="btn_TaskAcceptCancel" (click)="changeTaskStatus('CANCELLED', null)">
                                                <span class="text-12 px-3">{{translate?.general.status.cancelled.singular}}</span>
                                            </li>
                                            <li class="dropdown-item px-1 py-2" (click)="changeTaskStatus('PENDING', null)" id="btn_TaskAcceptPending" >
                                                <span class="text-12 px-3">{{translate?.general.status.pending.singular}}</span>
                                            </li>
                                            <li class="dropdown-item px-1 py-2" id="btn_TaskAcceptProg" (click)="changeTaskStatus('IN_PROGRESS', null)">
                                                <span class="text-12 px-3">{{translate?.general.status.inProgress.singular}}</span>
                                            </li>
                                            <li class="dropdown-item px-1 py-2" id="btn_TaskAcceptIncomplete" (click)="changeTaskStatus('IN_COMPLETED', null)"><span class="text-12 px-3">{{translate?.general.status.incomplete.singular}}</span></li>
                                        </ul>
                                    </div>
                                    <div *ngIf="!update || task.taskOwner?.assignedTo.id != auth.getUserId()">
                                        <div class="p-component p-dropdown-clearable">
                                            <div class="d-flex align-items-center border rounded-2 h-30">
                                                <span *ngIf="taskStatus === 'OPEN'" class="p-mr-2 p-tag p-component p-tag-open m-2px" id="tag_TaskAcceptOpen2"><span class="p-tag-value text-dark">{{translate?.general.status.open.singular}}</span></span>
                                                <span *ngIf="taskStatus === 'COMPLETED'" class="p-mr-2 p-tag-completed p-tag p-component m-2px" id="tag_TaskAcceptComplete2"><span class="p-tag-value">{{translate?.general.status.completed.singular}}</span></span>
                                                <span *ngIf="taskStatus === 'CANCELLED'" class="p-mr-2 p-tag-cancel p-tag p-component m-2px" id="tag_TaskAcceptCancel2"><span class="p-tag-value text-dark">{{translate?.general.status.cancelled.singular}}</span></span>
                                                <span *ngIf="taskStatus === 'PENDING'" class="p-mr-2 p-tag-pending p-tag p-component m-2px" id="tag_TaskAcceptPending2"><span class="p-tag-value text-dark">{{translate?.general.status.pending.singular}}</span></span>
                                                <span *ngIf="taskStatus === 'IN_PROGRESS'" class="p-mr-2 p-tag p-component p-tag-in-progress m-2px" id="tag_TaskAcceptProg2"><span class="p-tag-value text-dark">{{translate?.general.status.inProgress.singular}}</span></span>
                                                <span *ngIf="taskStatus === 'IN_COMPLETED'" class="p-mr-2 p-tag p-component p-tag-incomplete m-2px" id="tag_TaskAcceptIncomplete2"><span class="p-tag-value text-dark">{{translate?.general.status.incomplete.singular}}</span></span>
    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!------------------------------------- If status is ASSIGNED and user not match to assigne to then can not able to change to other status -------------------->
                                <div class="ms-2" *ngIf="task.assignStatus == 'ASSIGNED' && task.assignedTo.id != auth.getUserId()">
                                    <span *ngIf="taskStatus === 'OPEN'" class="p-tag p-component p-tag-open" id="tag_TaskAssignOpen"><span class="p-tag-value text-dark">{{translate?.general.status.open.singular}}</span></span>
                                    <span *ngIf="taskStatus === 'COMPLETED'" class="p-tag-completed p-tag p-component" id="tag_TaskAssignComplete"><span class="p-tag-value">{{translate?.general.status.completed.singular}}</span></span>
                                    <span *ngIf="taskStatus === 'CANCELLED'" class="p-tag-cancel p-tag p-component" id="tag_TaskAssignCancel"><span class="p-tag-value text-dark">{{translate?.general.status.cancelled.singular}}</span></span>
                                    <span *ngIf="taskStatus === 'PENDING'" class="p-tag-pending p-tag p-component" id="tag_TaskAssignPending"><span class="p-tag-value text-dark">{{translate?.general.status.pending.singular}}</span></span>
                                    <span *ngIf="taskStatus === 'IN_PROGRESS'" class="p-tag p-component p-tag-in-progress" id="tag_TaskAssignProg"><span class="p-tag-value text-dark">{{translate?.general.status.inProgress.singular}}</span></span>
                                    <span *ngIf="taskStatus === 'IN_COMPLETED'" class="p-tag p-component p-tag-incomplete" id="tag_TaskAssignIncomplete"><span class="p-tag-value text-dark">{{translate?.general.status.incomplete.singular}}</span></span>
                                </div>
                                <!------------------------------------- If status is Pending then accept or decline task -------------------->
                                <div *ngIf="userMatchForTask?.assignedStatus == 'PENDING'">
                                    <button pButton type="button" [label]="translate?.general.buttons.accept.singular" class="p-button-success p-mr-2 ms-2 p-mb-2 py-1 h-30" id="btn_TaskPendingAccept" (click)="openModal('acceptTask', task);$event.stopPropagation()"></button>
                                    <button pButton type="button" [label]="translate?.general.buttons.decline.singular" class="p-button-danger p-mr-2 p-mb-2 ms-2  py-1 h-30" id="btn_TaskPendingDecl" (click)="openModal('declineTask', task);$event.stopPropagation()"></button>
                                </div>
                                <!------------------------------------- If assignedStatus is accepted can able to change checkin or checkout -------------------->
                                <div  *ngIf="userMatchForTask?.assignedStatus == 'ACCEPTED' && task?.taskStatus !== 'COMPLETED'">
                                    <p-button
                                        [disabled]="!update || task?.isTaskJourneyStatusActive"
                                        type="button"
                                        (onClick)="doCheckIn(task, userMatchForTask)"
                                        *ngIf="!userMatchForTask?.checkedIn"
                                        styleClass="custella-btn-icon p-button-success ms-2 py-2 px-4 text-nowrap h-30"
                                        icon="pi pi-sign-in"
                                        [label]="translate?.general.buttons.checkIn.singular"
                                        id="btn_TaskAssignCheckIn"
                                    ></p-button>
                                    <p-button
                                        [disabled]="!update"
                                        type="button"
                                        (onClick)="doCheckOut(task, userMatchForTask)"
                                        *ngIf="userMatchForTask?.checkedIn"
                                        styleClass="custella-btn-icon p-button-danger ms-2 py-2 px-3 text-nowrap h-30"
                                        icon="pi pi-sign-in"
                                        [label]="translate?.general.buttons.checkOut.singular"
                                        id="btn_TaskAssignCheckOut"
                                    ></p-button>
                                </div>

                                <!-------------------------------------------- Edit Button -------------------------------------------->
                                <p-button [label]="translate?.general.buttons.edit.singular" [disabled]="!update" (onClick)="editTask('edit',task)" id="btn_TaskEdit" styleClass="h-30 ms-2 py-2 px-4 text-nowrap"></p-button>
                                <!------------------------------ Dropdown Three Dots ---------------------------------------------------->
                                <div class="dropdown ms-2">
                                    <div class="p-component p-dropdown-clearable" data-bs-toggle="dropdown">
                                        <button class="btn-view-icon ms-2"><i class="fa-regular fa-ellipsis-vertical" id="btn_TaskDropdown"></i></button>
                                    </div>
                                    <ul class="dropdown-menu dropdown-collaps p-0">
                                        <li class="dropdown-item px-3 py-3 cursor-pointer" *ngIf="buttonStatus && remove" id="btn_TaskDelete" (click)="editTask('delete',task)">{{translate?.general.buttons.delete.singular}}</li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 d-flex align-items-center justify-content-start">
                                <div class="me-2 d-flex align-items-center">
                                    <i class="fa-regular fa-clock cursor-default"></i>
                                    <span class="mx-2 text-12" id="value_TaskStartEndTime">{{task?.startDateTime | momentDate :'hh:mm a' : timezone}} - {{task?.endDateTime | momentDate :'hh:mm a' : timezone}}</span>
                                </div>

                                <div class="d-flex align-items-center">
                                    <i class="fa-regular fa-calendar cursor-default"></i>
                                    <span class="mx-2 text-12" id="value_TaskStartTime">{{task?.startDateTime | momentDate : dateFormat : timezone}}</span>
                                </div>
                                <div *ngIf="task?.fixed == true"  id="tooltip_TaskLock" [pTooltip]="translate?.task.tooltips.taskLocked.singular" tooltipPosition="top" class="masking-svg task-lock"></div>
                                <div *ngIf="task?.fixed !== true"  id="tooltip_TaskUnlock"[pTooltip]="translate?.task.tooltips.taskUnlocked.singular" tooltipPosition="top" class="masking-svg task-lock-key-open"></div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <!-- GENERAL DETAILS AND NOTES -->
                <div class="d-flex mx-3 h-100-72px overflow-hidden rounded-top mt-2 shadow">
                    <!-- General Details -->
                    <div class="border-end w-25-prcnt">
                        <div class="custella-form-container task-notes overflow-scroll rounded-0  m-0 p-0 box-none"  [ngStyle]="{'height' : bodyHeight + 192 +'px'}">
                            <!-- General Details -->
                            <div class="custella-form-container-title rounded-0 flex-between px-3">
                                <div class="font-medium text-14" id="title_TaskGenDet" >{{translate?.task.sections.sec0.singular}}</div>
                            </div>
                            <div class="overflow-x-auto pb-10px" [ngStyle]="{'height' : bodyHeight + 114 +'px'}">
                                <div class="custella-form-container-content-border-bottom px-3" >
                                    <div class="custella-content-row mt-3 mb-0">
                                        <div class="custella-content-row-input col-12 m-0">
                                            <span class="custella-content-row-input-title-two" id="label_TaskLoc" >{{ translate?.task.fields.location.singular }}</span>
                                            <span *ngIf="task?.locationName" class="custella-content-row-input-title-three-link mb-2" id="link_TaskNameLoc" (click)="showDirections(task)">{{ task?.locationName }}, {{ task?.location }}</span>
                                            <span *ngIf="!task?.locationName" class="custella-content-row-input-title-three-link mb-2" id="link_TaskLoc" (click)="showDirections(task)">{{ task?.location }}</span>
                                            <span *ngIf="task?.isTaskJourneyStatusActive" class="custella-content-row-input-title-three" id="value_TaskRoute" >{{translate?.task.messages.technicianOnRoute}}</span>
                                            <!-- <span *ngIf="!task?.locationName && !task?.isTaskJourneyStatusActive" class="custella-content-row-input-title-three">-</span> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="custella-form-container-content-border-bottom px-3" >
                                    <div class="custella-content-row mt-3 mb-0">
                                        <div class="custella-content-row-input col-12 m-0">
                                            <span class="custella-content-row-input-title-two" id="label_TaskJob" >{{translate?.jobOrder.label.singular}}</span>
                                            <div *ngIf="task?.jobOrder" class="custella-content-row-input-title-three" >
                                                <span *ngIf="!task?.jobOrder?.deleted" (click)="viewJobOrder(task?.customer?.id, task?.jobOrder?.id)">
                                                    <p class="mb-0" [ngClass]="{'custella-content-row-input-title-three-link' : read_Jo}" id="link_TaskJobNo">
                                                        {{task?.jobOrder?.jobOrderNumber}}
                                                    </p>
                                                </span>
                                                <span *ngIf="task?.jobOrder?.deleted">
                                                    <p class="mb-0 font-weight-bold" id="value_TaskJobDeleted">
                                                        {{task?.jobOrder?.jobOrderNumber}}
                                                        <strong class="color-948 fst-italic" id="label_TaskJobDeleted">({{translate?.general.status.deleted.singular}})</strong>
                                                    </p>
                                                </span>
                                                <span *ngIf="!task?.jobOrder">
                                                    <p class="mb-0 font-weight-bold" id="link_TaskJobNo">-</p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="custella-form-container-content-border-bottom px-3" >
                                    <div class="custella-content-row mt-3 mb-0">
                                        <div class="custella-content-row-input col-12 m-0">
                                            <span class="custella-content-row-input-title-two" id="label_TaskCust" >{{ translate?.customer.label.singular }}</span>
                                            <div class="div" *ngIf="task?.customer">
                                            <div class="flex-align-center" *ngIf="task?.customer?.isDeleted == 'Y'" > 
                                                <span class="custella-content-row-input-title-three"  id="value_JobDetCustomer">{{ task?.customer?.name + " " + "( " + translate?.general.status.deleted.singular+  " "  +translate?.customer.label.singular+ " )" }}</span>
                                            </div>
                                            <div  *ngIf="task?.customer?.isDeleted != 'Y'" class="flex-align-center">
                                                <span *ngIf="task?.customer" class="custella-content-row-input-title-three-link" id="link_TaskCust" (click)="viewCustomer(task?.customer?.id)"> {{ task?.customer?.name }}</span>
                                                
                                                <span *ngIf="task?.customer && read_Customer" class="ms-2" [pTooltip]="translate?.customer.tooltips.customerSummary.singular" id="btn_TaskCustSumm" tooltipPosition="right" (click)="openRightNavCustomer();  $event.stopPropagation()"><i class="fa-regular fa-square-arrow-up-right hvr"></i></span>

                                            </div>
                                            </div>
                                            <div class="div" *ngIf="!task?.customer">
                                                <span class="custella-content-row-input-title-three" id="link_TaskCust">-</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="custella-form-container-content-border-bottom px-3" >
                                    <div class="custella-content-row mt-3 mb-0">
                                        <div class="custella-content-row-input col-12 m-0">
                                            <span class="custella-content-row-input-title-two" >{{ "task_name" }}</span>
                                            <span class="custella-content-row-input-title-three" >
                                                {{task?.taskName}}
                                            </span>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="custella-form-container-content-border-bottom px-3" >
                                    <div class="custella-content-row mt-3 mb-0">
                                        <div class="custella-content-row-input col-12 m-0">
                                            <span class="custella-content-row-input-title-two" id="label_TaskDesc" >{{ translate?.task.fields.description.singular }}</span>
                                            <span *ngIf="task?.description" class="custella-content-row-input-title-three" id="value_TaskDesc"> {{ task?.description }}</span>
                                            <span *ngIf="!task?.description" class="custella-content-row-input-title-three" id="value_TaskDesc">-</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="custella-form-container-content-border-bottom px-3" >
                                    <div class="custella-content-row mt-3 mb-0">
                                        <div class="custella-content-row-input col-12 m-0">
                                            <span class="custella-content-row-input-title-two" >{{ "date" }}</span>
                                            <span class="custella-content-row-input-title-three" >
                                                {{task?.startDateTime | momentDate : dateFormat : timezone}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="custella-form-container-content-border-bottom px-3" >
                                    <div class="custella-content-row mt-3 mb-0">
                                        <div class="custella-content-row-input col-12 m-0">
                                            <span class="custella-content-row-input-title-two" >{{ "time" }}</span>
                                            <span class="custella-content-row-input-title-three" >{{task?.startDateTime | momentDate :'hh:mm a' : timezone}} - {{task?.endDateTime | momentDate :'hh:mm a' : timezone}}</span>
                                        </div>
                                    </div>
                                </div> -->
                                
                                <!-- <div class="custella-form-container-content-border-bottom px-3" >
                                    <div class="custella-content-row mt-3 mb-0">
                                        <div class="custella-content-row-input col-12 m-0">
                                            <span class="custella-content-row-input-title-two" >{{ "buffer_before_task" }}</span>
                                            <span *ngIf="task?.hasBuffer" class="custella-content-row-input-title-three" > {{task?.startBuffer}} {{'mins_before' }}</span>
                                            <span *ngIf="!task?.hasBuffer" class="custella-content-row-input-title-three"> - </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="custella-form-container-content-border-bottom px-3" >
                                    <div class="custella-content-row mt-3 mb-0">
                                        <div class="custella-content-row-input col-12 m-0">
                                            <span class="custella-content-row-input-title-two" >{{ "buffer_after_task" }}</span>
                                            <span *ngIf="task?.hasBuffer" class="custella-content-row-input-title-three" > {{task?.endBuffer}} {{'mins_after' }} </span>
                                            <span *ngIf="!task?.hasBuffer" class="custella-content-row-input-title-three"> - </span>
                                        </div>
                                    </div>
                                </div> -->
                                
                                <!-- <div class="custella-form-container-content-border-bottom px-3" >
                                <div class="custella-content-row mt-3 mb-0">
                                    <div class="custella-content-row-input col-12 m-0">
                                        <span class="custella-content-row-input-title-two" >{{ "skills" }}</span>
                                        <div>
                                            <div *ngIf="task?.userDynamicSkillList == null || task?.userDynamicSkillList.length < 1" class="py-1 normal-font f-grey d-flex align-items-center">-</div>
                                            <div *ngFor="let skill of task?.userDynamicSkillList;let i = index">
                                                <div class="rounded-5 bg-purple px-2 py-1 mx-1 mb-1">
                                                    <div class="d-flex align-items-center">
                                                        <span class="text-12 font-white text-wrap px-1">{{skill.label}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                                <!-- Time and Journey -->
                                <div class="custella-form-container-title-two flex-between px-3">
                                    <div class="font-medium text-14" id="title_TaskTimeJourney" >{{ translate?.task.sections.sec11.singular }}</div>
                                    <p-button [label]="translate?.general.buttons.view.singular" (onClick)="showJourney(task)" id="btn_TaskTimeJourney" styleClass="ms-1 text-nowrap py-0 px-3"></p-button>
                                </div>
                                <div class="custella-form-container-content-border-bottom px-3" >
                                    <div class="custella-content-row mt-3 mb-0">
                                        <div class="custella-content-row-input col-12 m-0">
                                            <span class="custella-content-row-input-title-two" id="label_TaskCheckInDuration" >{{ translate?.task.fields.checkInDuration.singular }}</span>
                                            <span class="custella-content-row-input-title-three" id="value_TaskCheckInDuration" >{{task?.durationTaken}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="custella-form-container-content-border-bottom px-3" >
                                    <div class="custella-content-row mt-3 mb-0">
                                        <div class="custella-content-row-input col-12 m-0">
                                            <span class="custella-content-row-input-title-two" id="label_TaskTotalDistTravel" >{{ translate?.task.fields.totalDistanceTravelled.singular }}</span>
                                            <span *ngIf="taskJourney.totalDistanceTravelled == 0" id="value_TaskTotalDistTravel" class="custella-content-row-input-title-three"> 0.0 KM</span>
                                            <span *ngIf="taskJourney.totalDistanceTravelled != 0" id="value_TaskTotalDistTravel" class="custella-content-row-input-title-three">{{taskJourney.totalDistanceTravelled}} KM</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="custella-form-container-content-border-bottom px-3" >
                                    <div class="custella-content-row mt-3 mb-0">
                                        <div class="custella-content-row-input col-12 m-0">
                                            <span class="custella-content-row-input-title-two" id="label_TaskTotalTimeSpent" >{{ translate?.task.fields.totalTimeSpent.singular }}</span>
                                            <span class="custella-content-row-input-title-three" id="value_TaskTotalTimeSpent" >
                                                {{taskJourney.totalTimeSpend}}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <!-- Additional Details -->
                                <div class="custella-form-container-title-two text-14 font-medium px-3" id="title_TaskSystInfo" translate *ngIf="addonChecklist && task?.checkListRecordSuperLite?.runningNo">
                                    {{ translate?.task.sections.sec1.singular }}
                                </div>
                                <!-- CheckList Record -->
                                <div class="custella-form-container-content-border-bottom px-3" *ngIf="addonChecklist && task?.checkListRecordSuperLite?.runningNo">
                                    <div class="custella-content-row mt-3 mb-0">
                                        <div class="custella-content-row-input col-12 m-0">
                                            <span class="custella-content-row-input-title-two" id="label_TaskReferenceChecklist" >{{ translate?.task.fields.referenceChecklist.singular }}</span>
                                            <span *ngIf="task?.checkListRecordSuperLite?.runningNo" class="custella-content-row-input-title-three-link" id="link_TaskCust" 
                                            (click)="viewChecklist(task?.checkListRecordSuperLite?.id)"> {{ task?.checkListRecordSuperLite?.runningNo ?? '-' }}</span>
                                            <span *ngIf="!task?.checkListRecordSuperLite?.runningNo" class="custella-content-row-input-title-three" id="link_TaskCust"> -</span>
                                        </div>
                                    </div>
                                </div>



                                <!-- System Information -->
                                <div class="custella-form-container-title-two text-14 font-medium px-3" id="title_TaskSystInfo" >
                                    {{translate?.general.systemInformation.sections.sec0.singular}}
                                </div>
                                <!-- Created by and last modified by -->
                                <div class="custella-form-container-content-border-bottom px-3" >
                                    <div class="custella-content-row mt-3 mb-0">
                                        <div class="custella-content-row-input col-12 m-0">
                                            <span class="custella-content-row-input-title-two" id="label_TaskCreatedBy" >{{translate?.general.systemInformation.fields.createdBy.singular}}</span>
                                            <div class="custella-vp-picture d-flex align-items-center">
                                                <p-avatar id="img_TaskCreatedByAvatar" *ngIf="task?.createdByUser?.imageUrl && task?.createdByUser?.imageUrl !== ''" [image]="task?.createdByUser?.imageUrl" styleClass="mr-2 mt-1" shape="circle"></p-avatar>
                                                <p-avatar id="img_TaskCreatedByAvatar" *ngIf="!task?.createdByUser?.imageUrl || task?.createdByUser?.imageUrl === ''" image="assets/svg/Avatar.svg" styleClass="mr-2 mt-2" shape="circle"></p-avatar>
                                                <div class="mx-2">
                                                    <span class="custella-content-row-input-title-three" id="value_TaskCreatedByFullName">{{task?.createdByUser?.fullName}},</span>
                                                    <span class="custella-content-row-input-title-three d-block text-nowrap" id="value_TaskCreatedByDateTime">{{task?.createdDate | momentDate : 'hh:mm a' : timezone}}, {{task?.createdDate | momentDate : dateFormat : timezone}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="custella-form-container-content-border-bottom px-3" >
                                    <div class="custella-content-row mt-3 mb-0">
                                        <div class="custella-content-row-input col-12 m-0">
                                            <span class="custella-content-row-input-title-two" id="label_TaskModifiedBy" >{{translate?.general.systemInformation.fields.lastModifiedBy.singular}}</span>
                                            <div class="custella-vp-picture d-flex align-items-center">
                                                <p-avatar id="img_TaskModifiedByAvatar" *ngIf="task?.lastModifiedByUser?.imageUrl && task?.lastModifiedByUser?.imageUrl !== ''" [image]="task?.lastModifiedByUser?.imageUrl" styleClass="mr-2 mt-1" shape="circle"></p-avatar>
                                                <p-avatar id="img_TaskModifiedByAvatar" *ngIf="!task?.lastModifiedByUser?.imageUrl || task?.lastModifiedByUser?.imageUrl === ''" image="assets/svg/Avatar.svg" styleClass="mr-2 mt-1" shape="circle"></p-avatar>
                                                <div class="mx-2">
                                                    <span class="custella-content-row-input-title-three" id="value_TaskModifiedByFullName">{{task?.lastModifiedByUser?.fullName}},</span>
                                                    <span class="custella-content-row-input-title-three d-block text-nowrap" id="value_TaskModifiedByDateTime">{{task?.lastModifiedDate | momentDate : ' hh:mm a' : timezone}}, {{task?.lastModifiedDate | momentDate : dateFormat : timezone}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Notes -->
                    <div class="w-50">
                        
                        <div class="custella-form-container position-relative d-block rounded-0 m-0 p-0 box-none">
                                <!-- General Details -->
                            <div class="custella-form-container-title rounded-0 flex-between px-3">
                                <div class="font-medium text-14" id="title_TaskNotes" >{{ translate?.task.sections.sec7.singular }}</div>
                            </div>
                            <div class="custella-form-container-content-border-bottom overflow-scroll px-2 pt-0 scroll-10px" [ngStyle]="{'height' : bodyHeight +'px'}" #taskNote>
                                
                                <div *ngIf="taskNotes?.length == 0" class="col-12">
                                    <div class="col-md-12 text-center add-note">
                                      <img src="assets/images/add-notes-bg.png" alt="Add Notes" id="img_TaskAddNotes" class="w-92px">
                                      <p class="text-6 my-1 font-weight-bold" id="label_TaskAddNotes">{{ translate?.task.fields.addTaskNote.singular }}</p>
                                     </div>
                                  </div>

                                <div *ngFor="let note of taskNotes;let j = index">
                                    <div class="px-2" [ngClass]="{'team-task-ms-owner':note.owner.id == loginUser,'team-task-ms-user':note.owner.id != loginUser}">
                                        <div class="custella-content-row m-0 pe-0">
                                            <div class="custella-content-row-input col-12 pt-2 m-0">
                                                <div class="custella-vp-picture d-flex align-items-center">
                                                        <p-avatar [image]="photoUrl" id="img_TaskNotesAvatar" styleClass="mr-2 mt-1" shape="circle"></p-avatar>
                                                        <div class="custella-content-row-input ms-2">
                                                            <span class="custella-content-row-input-title-three me-4" id="value_TaskNotesFullName" >{{note?.owner?.fullName}}</span>
                                                        </div>
                                                        <div class="dropdown ps-1 ms-auto d-flex">
                                                            <div class="custella-content-row-input ms-2">
                                                                <span class="text-11" id="value_TaskNotesDateTime">{{note.createdDate | momentDate :'hh:mm a' : timezone}}</span>
                                                            </div>
                                                            <div class="p-component p-dropdown-clearable" data-bs-toggle="dropdown">
                                                                <i class="fa-regular fa-ellipsis-vertical mt-4px" id="btn_TaskNotesDropdown"></i>
                                                            </div>
                                                            <ul class="dropdown-menu dropdown-collaps p-0">
                                                                <li class="dropdown-item px-3 py-2 cursor-pointer" id="btn_TaskNotesEdit" (click)="custellaOpenModal('editNotes', note);$event.stopPropagation()">
                                                                    {{translate?.general.buttons.edit.singular}}
                                                                </li>
                                                                <li class="dropdown-item px-3 py-2 cursor-pointer" id="btn_TaskNotesDelete" (click)="validateDeleteNote(note);$event.stopPropagation()">
                                                                    {{translate?.general.buttons.delete.singular}}
                                                                </li>
                                                            </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="custella-content-row m-0 pe-0">
                                            <div class="custella-content-row-input col-12 m-0">
                                                <div class="rounded-2 p-2">
                                                    <span class="px-0" id="value_TaskNotes"> {{note.note}} </span>
                                                    <div class="row my-3" *ngFor="let attachment of note.attachments">
                                                        <div class="d-flex" *ngIf="attachment.contentContentType.indexOf('application') >= 0 || attachment.contentContentType.indexOf('text') >= 0">
                                                            <img class="w-24px" [src]="attachment.icon" />
                                                            <a class="text-12 f-blue ms-2 mt-2 text-decoration-underline" id="value_TaskNotesFileName" [href]="attachment.attachmentUrl" target="_blank">
                                                                {{ attachment.fileName }}
                                                            </a>
                                                        </div>
                                                        <div *ngIf="attachment.contentContentType.indexOf('image') >= 0">
                                                            <a class="custella-link" [href]="attachment.attachmentUrl" id="link_TaskNotesFileName" target="_blank">
                                                                <img class="w-200px" [src]="attachment.attachmentUrl" id="img_TaskNotesFileName" />
                                                            </a>
                                                        </div>
                                                        <div *ngIf="attachment.contentContentType.indexOf('video') >= 0" class="video-container">
                                                            <video #media id="singleVideo" class="video-player w-300px" id="video_TaskNotesFileName" preload="auto" controls>
                                                                <source src="{{attachment?.attachmentUrl}}" type="{{attachment?.contentContentType}}" />
                                                            </video>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                     </div>
                                </div>
                            </div>
                            <div class="custella-form-container-content-border-bottom px-2">
                                
                                <!------------------------ End Attchment Preview -------------------------------------->
                                <div class="custella-content-row mb-3 p-0 ">
                                    <div class="custella-content-row-input col-12 m-0">
                                        <form (ngSubmit)="addNote(addNotes.note)" class="team-task-msg-body">
                                            <textarea class="textarea rounded-top" [placeholder]="translate?.task.messages.typeYourNoteHere" id="input_TaskNotesField" autocomplete="off" name="description" #addNotesInput [ngModelOptions]="{standalone: true}" [(ngModel)]="addNotes.note" [maxlength]="320000"></textarea>
                                            
                                            <!------------------------ Attchment Preview -------------------------------------->
                                                <div class="custella-content-row mb-3 p-0 mt-2" *ngIf="attachmentsArray.length > 0">
                                                    <div class="custella-content-row-input col-12 m-0">
                                                        <div class="preview col-12">
                                                            <div class="d-flex flex-wrap mb-2 pe-3">
                                                                <!-- IMAGE ATTACHMENT -->
                                                                <div class="me-1 mt-2" *ngFor="let attachment of attachmentsArray">
                                                                    <div class="d-flex">
                                                                        <img *ngIf="attachment.contentContentType.indexOf('image') >= 0" id="img_TaskNotesAttachments" [src]="attachment.attachmentUrl" alt="Attachment Preview" class="w-72px preview-attachments" />
                                                                        <!-- <img
                                                                            *ngIf="attachment.contentContentType.indexOf('application') >= 0 || attachment.contentContentType.indexOf('text') >= 0"
                                                                            src="../../../../assets/svg/fileTypes/general file.svg"
                                                                            alt="Attachment Preview"
                                                                            class="w-72px preview-attachments"
                                                                        /> -->
                                                                        <img
                                                                            *ngIf="attachment.contentContentType.indexOf('application') >= 0 || attachment.contentContentType.indexOf('text') >= 0"
                                                                            [src]="attachment.icon"
                                                                            alt="Attachment Preview"
                                                                            class="w-72px preview-attachments"
                                                                            id="img_TaskNotesAttachmentsIcon"
                                                                        />
                                                                        <img *ngIf="attachment.contentContentType.indexOf('video') >= 0" src="assets\svg\fileTypes\mov.svg" id="video_TaskNotesAttachments" alt="Attachment Preview" class="w-72px preview-attachments" />
                                                                        <i class="fa-regular fa-xmark preview-close" id="btn_TaskNotesAttachmentsDelete" (click)="deleteAttachment(attachment)"></i>
                                                                    </div>
                                                                </div>
                                                                <!-- VENDID ATTACHMENT -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            <div class="d-flex align-items-center justify-content-end m-0">
                                                <p-fileUpload #fileUpload mode="basic" [chooseLabel]="translate?.general.buttons.upload.singular" [chooseIcon]="'pi pi-upload'"
                                                (uploadHandler)="uploadFile($event)" [customUpload]="true" [auto]="true" [maxFileSize]="25000000"
                                                [invalidFileSizeMessageSummary]="translate?.attachments.messages.invalidFileSize"  [invalidFileSizeMessageDetail]="translate?.attachments.messages.allowedFileSize" 
                                                class="max-size" id="btn_TaskAttachmentUpload"></p-fileUpload>
                                                <!-- <p-button pTooltip="Attach a file" tooltipPosition="top" (onClick)="openFileUploader()" id="btn_TaskAttachFile"  icon="pi pi-paperclip" styleClass="h-30 ms-1 py-2 px-4 text-nowrap"></p-button>
                                                <input [hidden]="true" id="fileUploader" [ngModelOptions]="{standalone: true}" type="file" id="input_TaskAttachFileField" (change)="uploadFile($event)" [(ngModel)]="file" /> -->
                                                <p-button [pTooltip]="translate?.general.buttons.save.singular" tooltipPosition="top" (onClick)="addNote(addNotes.note)" id="btn_TaskSendFile" icon="pi pi-send" styleClass="h-30 ms-1 py-2 px-4 text-nowrap"></p-button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <!-- <div class="custella-form-container-footer pe-1 mb-3">
                                    <div class="d-flex align-items-center justify-content-end mb-0">
                                        <span class="me-2" (click)="openFileUploader()"><i class="pi pi-paperclip"></i></span>
                                        <input [hidden]="true" id="fileUploader" placeholder="{{'type_your_note_here' }}" type="file" (change)="uploadFile($event)" [(ngModel)]="file" />
                                        <p-button (click)="addNote(addNotes.note)" [disabled]="submitTouched" label="Add Note" styleClass="mx-1 text-nowrap h-35"></p-button>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                     <!-- RIGHT -->
                    <div class="border-start w-25-prcnt">
                        <div class="custella-form-container task-notes overflow-scroll rounded-0  m-0 p-0 box-none" [ngStyle]="{'height' : bodyHeight + 192 +'px'}">
                            <div class="custella-form-container-title rounded-0 flex-between px-3">
                                <div class="font-medium text-14" id="title_TaskAssignUsers" >{{ translate?.task.sections.sec24.singular }}</div>
                                    <p-button *ngIf="allowAssignTask" [label]="translate?.general.buttons.addMember.singular" id="btn_TaskAddMembers" (onClick)="openModal('addMember', task);$event.stopPropagation()" styleClass="ms-1 text-nowrap py-0 px-3" [disabled]="!update"></p-button>
                            </div>
                            <div class="overflow-x-auto pb-10px" [ngStyle]="{'height' : bodyHeight + 114 +'px'}">
                                
                                    <div class="custella-form-container-content-border-bottom px-3" >
                                        <div class="custella-content-row mb-2 pe-0">
                                            <div class="custella-content-row-input col-12 m-0">
                                                <span class="custella-content-row-input-title-two" id="label_TaskOwner" >{{ translate?.task.fields.taskOwner.singular }}</span>
                                                <div *ngFor="let owner of task.assignedUsersList">
                                                    <div class="d-flex justify-content-between" *ngIf="owner.owner">
                                                        <div class="d-flex align-items-center">
                                                            <p-avatar id="img_TaskOwnerAvatar" *ngIf="owner?.assignedTo?.photoUrl" [image]="owner?.assignedTo?.photoUrl" styleClass="me-2 mt-1" shape="circle"></p-avatar>
                                                            <p-avatar id="img_TaskOwnerAvatar" *ngIf="!owner?.assignedTo?.photoUrl" image="assets/svg/Avatar.svg" styleClass="me-2 mt-1" shape="circle"></p-avatar>
                                                            <div>
                                                                <a class="custella-content-row-input-title-three" id="value_TaskOwnerFullName">{{owner?.assignedTo?.fullName}}</a>
                                                                <div *ngIf="owner.assignedStatus == 'ACCEPTED'"class="flex-align-center">
                                                                    <i class="pi pi-check-circle me-2 font-green text-12 my-auto"></i><span class="text-10" id="label_TaskOwnerFullNameAccept">{{translate?.general.status.accepted.singular}}</span>
                                                                </div>
                                                                <div *ngIf="owner.assignedStatus == 'DECLINED'" class="flex-align-center">
                                                                    <div class="dropdown">
                                                                        <div class="p-component p-dropdown-clearable" data-bs-toggle="dropdown" id="btn_TaskOwnerDeclDropdown">
                                                                            <img src="assets\svg\sidebar\comments.svg" alt="Declined Reason" id="img_TaskOwnerDecline" class="w-19px cursor-pointer">
                                                                        </div>
                                                                        <ul class="dropdown-menu dropdown-collaps p-0 w-25">
                                                                            <li class="flex-between border-bottom bg-header-rounded px-1 py-2">
                                                                                <div class="ms-2 me-3">
                                                                                    <span class="ps-1 font-white font-medium text-12" id="label_TaskRejectReason" >{{ translate?.task.fields.rejectReason.singular }}</span>
                                                                                </div>
                                                                            </li>
                                                                            <li class="border-bottom px-1 py-2">
                                                                                <span class="text-12 text-wrap font-medium ms-11px" id="value_TaskRejectReason">{{owner?.deciinedReason}}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="flex-align-center pt-1">
                                                                        <i class="pi pi-times-circle mx-2 font-red text-12 my-auto"></i>
                                                                        <span class="text-10 pt-05" id="label_TaskDecline">{{translate?.general.status.decline.singular}}</span>
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="owner.assignedStatus == 'PENDING'" class="flex-align-center">
                                                                        <i class="pi pi-exclamation-circle me-2 font-orange2 text-12 my-auto"></i>
                                                                        <span class="text-10" id="label_TaskWaiting">{{translate?.general.status.waiting.singular}}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex align-items-center">
                                                            <div *ngIf="task?.taskOwner?.assignedStatus != 'PENDING'">
                                                                <div class="dropdown ps-2 cursor-pointer"
                                                                    *ngIf="(update && (task.taskOwner?.assignedTo.id == auth.getUserId() || task.assignedBy?.id == auth.getUserId() || owner?.assignedTo.id == auth.getUserId()))"
                                                                >
                                                                    <div class="p-component p-dropdown-clearable" data-bs-toggle="dropdown">
                                                                        <div class="d-flex align-items-center">
                                                                            <span *ngIf="owner.taskStatus === 'OPEN'" id="tag_TaskPendingAccept" class="cursor-pointer p-mr-2 p-tag p-component p-tag-open"><span class="p-tag-value text-dark">{{translate?.general.status.open.singular}}</span></span>
                                                                            <span *ngIf="owner.taskStatus === 'COMPLETED'" id="tag_TaskPendingComplete" class="cursor-pointer p-mr-2 p-tag-completed p-tag p-component"><span class="p-tag-value">{{translate?.general.status.completed.singular}}</span></span>
                                                                            <span *ngIf="owner.taskStatus === 'CANCELLED'" id="tag_TaskPendingCancel" class="cursor-pointer p-mr-2 p-tag-cancel p-tag p-component">
                                                                                <span class="p-tag-value text-dark">{{translate?.general.status.cancelled.singular}}</span>
                                                                            </span>
                                                                            <span *ngIf="owner.taskStatus === 'PENDING'" id="tag_TaskPendingPending" class="cursor-pointer p-mr-2 p-tag-pending p-tag p-component"><span class="p-tag-value text-dark">{{translate?.general.status.pending.singular}}</span></span>
                                                                            <span *ngIf="owner.taskStatus === 'IN_PROGRESS'" id="tag_TaskPendingProg" class="cursor-pointer p-mr-2 p-tag p-component p-tag-in-progress"><span class="p-tag-value text-dark">{{translate?.general.status.inProgress.singular}}</span></span>
                                                                            <span *ngIf="owner.taskStatus === 'IN_COMPLETED'" id="tag_TaskPendingIncomplete" class="cursor-pointer p-mr-2 p-tag p-component p-tag-incomplete"><span class="p-tag-value text-dark">{{translate?.general.status.incomplete.singular}}</span></span>
                                                                        </div>
                                                                    </div>
                                                                    <ul class="dropdown-menu dropdown-collaps p-0" *ngIf="(update && (task.taskOwner?.assignedTo.id == auth.getUserId() || task.assignedBy?.id == auth.getUserId()))">
                                                                        <li class="dropdown-item px-1 py-2" id="tag_TaskPendingComplete2" (click)="changeTaskStatus('COMPLETED', owner)"><span class="text-12 px-3">{{translate?.general.status.completed.singular}}</span></li>
                                                                        <li class="dropdown-item px-1 py-2" id="tag_TaskPendingCancel2" (click)="changeTaskStatus('CANCELLED', owner)"><span class="text-12 px-3">{{translate?.general.status.cancelled.singular}}</span></li>
                                                                        <li class="dropdown-item px-1 py-2" id="tag_TaskPendingPending2" (click)="changeTaskStatus('PENDING', owner)"><span class="text-12 px-3">{{translate?.general.status.pending.singular}}</span></li>
                                                                        <li class="dropdown-item px-1 py-2" id="tag_TaskPendingProg2" (click)="changeTaskStatus('IN_PROGRESS', owner)"><span class="text-12 px-3">{{translate?.general.status.inProgress.singular}}</span></li>
                                                                        <li class="dropdown-item px-1 py-2" id="tag_TaskPendingIncomplete2" (click)="changeTaskStatus('IN_COMPLETED', owner)"><span class="text-12 px-3">{{translate?.general.status.incomplete.singular}}</span></li>
                                                                    </ul>
                                                                </div>

                                                                <div class="dropdown ps-2"  *ngIf="!(update && (task.taskOwner?.assignedTo.id == auth.getUserId() || task.assignedBy?.id == auth.getUserId() || owner?.assignedTo.id == auth.getUserId()))" >
                                                                    <div class="p-component p-dropdown-clearable" data-bs-toggle="dropdown">
                                                                        <div class="d-flex align-items-center">
                                                                            <span *ngIf="owner.taskStatus === 'OPEN'" id="tag_TaskPendingOpen" class="cursor-pointer p-mr-2 p-tag p-component p-tag-open"><span class="p-tag-value text-dark">{{translate?.general.status.open.singular}}</span></span>
                                                                            <span *ngIf="owner.taskStatus === 'COMPLETED'" id="tag_TaskPendingComplete3" class="cursor-pointer p-mr-2 p-tag-completed p-tag p-component"><span class="p-tag-value">{{translate?.general.status.completed.singular}}</span></span>
                                                                            <span *ngIf="owner.taskStatus === 'CANCELLED'" id="tag_TaskPendingCancel3" class="cursor-pointer p-mr-2 p-tag-cancel p-tag p-component"> <span class="p-tag-value text-dark">{{translate?.general.status.cancelled.singular}}</span>  </span>
                                                                            <span *ngIf="owner.taskStatus === 'PENDING'" id="tag_TaskPendingPending3" class="cursor-pointer p-mr-2 p-tag-pending p-tag p-component"><span class="p-tag-value text-dark">{{translate?.general.status.pending.singular}}</span></span>
                                                                            <span *ngIf="owner.taskStatus === 'IN_PROGRESS'" id="tag_TaskPendingProg3" class="cursor-pointer p-mr-2 p-tag p-component p-tag-in-progress"><span class="p-tag-value text-dark">{{translate?.general.status.inProgress.singular}}</span></span>
                                                                            <span *ngIf="owner.taskStatus === 'IN_COMPLETED'" id="tag_TaskPendingIncomplete3" class="cursor-pointer p-mr-2 p-tag p-component p-tag-incomplete"><span class="p-tag-value text-dark">{{translate?.general.status.incomplete.singular}}</span></span>
                                                                        </div>
                                                                    </div>
                                                            </div>

                                                            </div>
                                                            
                                                            <div class="dropdown ps-1 text-end">
                                                                <div class="p-component p-dropdown-clearable" data-bs-toggle="dropdown">
                                                                    <i class="fa-regular fa-ellipsis-vertical color-var-500" id="btn_TaskPendingDropdown"></i>
                                                                </div>
                                                                <ul class="dropdown-menu dropdown-collaps p-0">
                                                                    <li class="dropdown-item px-3 py-3 cursor-pointer" id="btn_TaskPendingDelete" (click)="removeTaskUser(owner)">{{translate?.general.buttons.remove.singular}}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!------------------------------------- Other Setion -------------------------------------------------------------------------------------->
                                     <span class="custella-content-row-input-title-two pt-1 px-3 mb-0" id="label_TaskOtherMembers" >{{ translate?.task.fields.otherTaskMembers.singular }}</span>
                                   
                                    <div class="custella-form-container-content-border-bottom px-3" >
                                        
                                        <div class="custella-content-row mb-2 pe-0">
                                            <div class="custella-content-row-input col-12 m-0">
                                                <div class="" *ngFor="let user of task?.assignedUsersList;let i = index">
                                                    <div *ngIf="!user.owner" [ngClass]="{'border-top': i !=0 }">
                                                        <div class="flex-between py-1">
                                                            <div class="d-flex align-items-center">
                                                                <p-avatar id="img_TaskOtherMembersAvatar" *ngIf="user?.assignedTo?.photoUrl" [image]="user?.assignedTo?.photoUrl" styleClass="me-2 mt-1" shape="circle"></p-avatar>
                                                                <p-avatar id="img_TaskOtherMembersAvatar" *ngIf="!user?.assignedTo?.photoUrl" image="assets/svg/Avatar.svg" styleClass="me-2 mt-1" shape="circle"></p-avatar>  
                                                                <!-------------------------------------- Assigned Member Status (DECLINE, ACCEPT, Waiting) -------------------------------->
                                                                    <div>
                                                                    <a class="custella-content-row-input-title-three d-block" id="value_TaskOtherMembersFullName">{{user?.assignedTo?.fullName}}</a>
                                                                    <div *ngIf="user.assignedStatus == 'ACCEPTED'"class="flex-align-center">
                                                                        <i class="fa-regular fa-circle-check me-2 font-green text-12 my-auto" id="label_TaskOtherMembersFullNameAccept"></i><span class="text-10">{{translate?.general.status.accepted.singular}}</span>
                                                                    </div>
                                                                    <div *ngIf="user.assignedStatus == 'DECLINED'" class="flex-align-center">
                                                                        <div class="dropdown">
                                                                            <div class="p-component p-dropdown-clearable" data-bs-toggle="dropdown" id="btn_TaskOtherMemberDeclDropdown">
                                                                                <img src="assets\svg\sidebar\comments.svg" alt="Declined Reason" id="img_TaskOtherMemberDeclDropdown" class="w-19px cursor-pointer">
                                                                            </div>
                                                                            <ul class="dropdown-menu dropdown-collaps p-0 w-25">
                                                                                <li class="flex-between border-bottom bg-header-rounded px-1 py-2">
                                                                                    <div class="ms-2 me-3">
                                                                                        <span class="ps-1 font-white font-medium text-12" id="label_TaskOtherMemberRejectReason" >{{ translate?.task.fields.rejectReason.singular }}</span>
                                                                                    </div>
                                                                                </li>
                                                                                <li class="border-bottom ms-15px pe-1 py-2">
                                                                                    <span class="text-12 text-wrap font-medium" id="value_TaskOtherMemberRejectReason">{{user?.deciinedReason}}</span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div class="flex-align-center pt-1">
                                                                          <i class="pi pi-times-circle mx-2 font-red text-12 my-auto"></i> 
                                                                          <span class="text-10 pt-05" id="label_TaskDecline2">{{translate?.general.status.decline.singular}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="user.assignedStatus == 'PENDING'" class="flex-align-center">
                                                                            <i class="pi pi-exclamation-circle me-2 font-orange2 text-12 my-auto"></i>
                                                                            <span class="text-10" id="label_TaskWaiting2">{{translate?.general.status.waiting.singular}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="justify-content-end d-flex align-items-center">
                                                                <div *ngIf="user.assignedStatus != 'PENDING'">
                                                                    <div
                                                                        class="d-flex align-items-center"
                                                                        *ngIf="!(update && (task.taskOwner?.assignedTo.id == auth.getUserId() || task.assignedBy?.id == auth.getUserId() || user?.assignedTo.id == auth.getUserId()))"
                                                                    >
                                                                        <span *ngIf="user.taskStatus === 'OPEN'" id="tag_TaskOtherMemOpen" class="cursor-pointer p-mr-2 p-tag p-component p-tag-open"><span class="p-tag-value text-dark">{{translate?.general.status.open.singular}}</span></span>
                                                                        <span *ngIf="user.taskStatus === 'COMPLETED'"id="tag_TaskOtherMemComplete" class="cursor-pointer p-mr-2 p-tag-completed p-tag p-component"><span class="p-tag-value">{{translate?.general.status.completed.singular}}</span></span>
                                                                        <span *ngIf="user.taskStatus === 'CANCELLED'" id="tag_TaskOtherMemCancel" class="cursor-pointer p-mr-2 p-tag-cancel p-tag p-component"><span class="p-tag-value text-dark">{{translate?.general.status.cancelled.singular}}</span></span>
                                                                        <span *ngIf="user.taskStatus === 'PENDING'" id="tag_TaskOtherMemPending" class="cursor-pointer p-mr-2 p-tag-pending p-tag p-component"><span class="p-tag-value text-dark">{{translate?.general.status.pending.singular}}</span></span>
                                                                        <span *ngIf="user.taskStatus === 'IN_PROGRESS'" id="tag_TaskOtherMemProg" class="cursor-pointer p-mr-2 p-tag p-component p-tag-in-progress"><span class="p-tag-value text-dark">{{translate?.general.status.inProgress.singular}}</span></span>
                                                                        <span *ngIf="user.taskStatus === 'IN_COMPLETED'" id="tag_TaskOtherMemIncomplete" class="cursor-pointer p-mr-2 p-tag p-component p-tag-incomplete"><span class="p-tag-value text-dark">{{translate?.general.status.incomplete.singular}}</span></span>
                                                                    </div>
                                                                    <!-------------------------------------- Drop down Status -------------------------------->
                                                                    <div class="dropdown ps-2" *ngIf="(update && (task.taskOwner?.assignedTo.id == auth.getUserId() || task.assignedBy?.id == auth.getUserId() || user?.assignedTo.id == auth.getUserId()))">
                                                                        <div class="p-component p-dropdown-clearable" data-bs-toggle="dropdown">
                                                                            <div class="d-flex rounded-2 p-1">
                                                                                <span *ngIf="user.taskStatus === 'OPEN'" id="tag_TaskOtherMemOpen2" class="cursor-pointer p-mr-2 p-tag p-component p-tag-open"><span class="p-tag-value text-dark">{{translate?.general.status.open.singular}}</span></span>
                                                                                <span *ngIf="user.taskStatus === 'COMPLETED'" id="tag_TaskOtherMemComplete2" class="cursor-pointer p-mr-2 p-tag-completed p-tag p-component">
                                                                                    <span class="p-tag-value">{{translate?.general.status.completed.singular}}</span>
                                                                                </span>
                                                                                <span *ngIf="user.taskStatus === 'CANCELLED'" id="tag_TaskOtherMemCancel2" class="cursor-pointer p-mr-2 p-tag-cancel p-tag p-component">
                                                                                    <span class="p-tag-value text-dark">{{translate?.general.status.cancelled.singular}}</span>
                                                                                </span>
                                                                                <span *ngIf="user.taskStatus === 'PENDING'" id="tag_TaskOtherMemPending2" class="cursor-pointer p-mr-2 p-tag-pending p-tag p-component">
                                                                                    <span class="p-tag-value text-dark">{{translate?.general.status.pending.singular}}</span>
                                                                                </span>
                                                                                <span *ngIf="user.taskStatus === 'IN_PROGRESS'" id="tag_TaskOtherMemProg2" class="cursor-pointer p-mr-2 p-tag p-component p-tag-in-progress"><span class="p-tag-value text-dark">{{translate?.general.status.inProgress.singular}}</span></span>
                                                                                <span *ngIf="user.taskStatus === 'IN_COMPLETED'" id="tag_TaskOtherMemIncomplete2" class="cursor-pointer p-mr-2 p-tag p-component p-tag-incomplete"><span class="p-tag-value text-dark">{{translate?.general.status.incomplete.singular}}</span></span>
                                                                            </div>
                                                                        </div>
                                                                        <ul class="dropdown-menu dropdown-collaps p-0" *ngIf="(update && (task.taskOwner?.assignedTo.id == auth.getUserId() || task.assignedBy?.id == auth.getUserId()))">
                                                                            <li class="dropdown-item px-1 py-2" id="tag_TaskOtherMemOpen3" (click)="changeTaskStatus('COMPLETED', user)"><span class="text-12 px-3">{{translate?.general.status.completed.singular}}</span></li>
                                                                            <li class="dropdown-item px-1 py-2" id="tag_TaskOtherMemCancel3" (click)="changeTaskStatus('CANCELLED', user)"><span class="text-12 px-3">{{translate?.general.status.cancelled.singular}}</span></li>
                                                                            <li class="dropdown-item px-1 py-2" id="tag_TaskOtherMemPending3" (click)="changeTaskStatus('PENDING', user)"><span class="text-12 px-3">{{translate?.general.status.pending.singular}}</span></li>
                                                                            <li class="dropdown-item px-1 py-2" id="tag_TaskOtherMemProg3" (click)="changeTaskStatus('IN_PROGRESS', user)"><span class="text-12 px-3">{{translate?.general.status.inProgress.singular}}</span></li>
                                                                            <li class="dropdown-item px-1 py-2" id="tag_TaskOtherMemIncomplete3" (click)="changeTaskStatus('IN_COMPLETED', user)"><span class="text-12 px-3">{{translate?.general.status.incomplete.singular}}</span></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="dropdown ps-1 text-end">
                                                                    <div class="p-component p-dropdown-clearable" data-bs-toggle="dropdown" id="btn_OtherMemDropdown">
                                                                        <i class="fa-regular fa-ellipsis-vertical color-var-500"></i>
                                                                    </div>
                                                                    <ul class="dropdown-menu dropdown-collaps p-0">
                                                                        <li
                                                                            class="dropdown-item px-3 py-2 cursor-pointer"
                                                                            *ngIf="task?.taskOwner?.assignedTo?.id == auth.getUserId() || task?.assignedBy?.id == auth.getUserId() || buttonActive"
                                                                            (click)="makeTaskOwner(user)"
                                                                            id="btn_TaskOtherMemMakeOwner"
                                                                        >
                                                                        {{ translate?.general.buttons.makeOwner.singular }}
                                                                        </li>
                                                                        <li class="dropdown-item px-3 py-2 cursor-pointer" id="btn_TaskOtherMemRemove" (click)="removeTaskUser(user)">{{translate?.general.buttons.remove.singular}}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="custella-form-container-content-border-bottom px-3" >
                                        <div class="custella-content-row mt-3 mb-0">
                                            <div class="custella-content-row-input col-6">
                                                <span class="custella-content-row-input-title-two" id="label_TaskAssignBy" >{{ translate?.task.fields.assignedBy.singular }}</span>
                                                <span *ngIf="task?.assignedBy?.id !== task?.assignedTo?.id" id="value_TaskAssignBy" class="custella-content-row-input-title-three"> {{task?.assignedBy?.fullName}} </span>
                                                <span *ngIf="task?.assignedBy?.id == task?.assignedTo?.id" id="value_TaskAssignBy" class="custella-content-row-input-title-three">N/A</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="custella-form-container-content px-3 mt-0 mb-2" >
                                        <div class="custella-content-row mb-0">
                                            <div class="custella-content-row-input col-6">
                                                <span class="custella-content-row-input-title-two" id="label_TaskAssignTeam" >{{ translate?.task.fields.assignedTeam.singular }}</span>
                                                <span *ngIf="task?.team == null" class="custella-content-row-input-title-three" id="value_TaskAssignTeam">{{translate?.general.messages.notAvailable}}</span>
                                                <span *ngIf="task?.team != null" class="custella-content-row-input-title-three" id="value_TaskAssignTeam">{{task?.team?.teamName}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!------------------------------------------------- CHUBB FORMS  -------------------------------------------------->
                                    <div *ngIf="addonFeature.addonCHUBB == 'CHUBB' && task.take5Form" class="custella-form-container mt-0 mx-0 h-100px">
                                        <!-- Chubb Form -->
                                        <div class="custella-form-container-title flex-between px-3 rounded-0">
                                            <div class="font-medium text-14" id="title_TaskChubbForms" >{{ translate?.task.sections.sec25.singular }}</div>
                                        </div>
                                        <div class="custella-form-container-content">
                                            <div class="custella-content-row mt-3 mb-0">
                                                <div class="custella-content-row-input col-12 text-12 font-medium">
                                                    <span (click)="hazardControlClick(task)" id="link_TaskChubbTake5" class="custella-content-row-input-title-three-link" >{{translate?.task.messages.take5}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!---------------------------- Activity --------------------------->
                                    <div class="custella-form-container-title rounded-0 flex-between px-3">
                                        <div class="font-medium text-14" id="title_TaskActivity" >{{ translate?.task.sections.sec26.singular }}</div>
                                    </div>
                                
                                    <div class="custella-form-container-content px-3 m-0" >
                                        <div class="row m-0">
                                            <div class="custella-content-row-input col-12 mt-2 p-0"  *ngFor="let timeli of timeline">
                                                <div class="my-1 pb-1 border-bottom">
                                                    <span class="text-12 font-medium color-var-500" id="value_TaskActivityCreateDate">{{timeli.createdDate | momentDate: "MMM d yyyy":timezone | uppercase }}</span>
                                                </div>
                                                <div class="custella-vp-picture d-flex align-items-start border-bottom py-2" *ngFor="let activity of timeli.history;let first = first; let last = last ">
                                                    <p-avatar id="img_TaskActivityOwnerAvatar" *ngIf="activity?.activityOwner?.imageUrl" [image]="activity?.activityOwner?.imageUrl" styleClass="me-2" shape="circle" [pTooltip]="activity?.activityOwner?.fullName"></p-avatar>
                                                    <p-avatar id="img_TaskActivityOwnerAvatar" *ngIf="!activity?.activityOwner?.imageUrl" image="assets/svg/Avatar.svg" styleClass="me-2" shape="circle" [pTooltip]="activity?.activityOwner?.fullName"></p-avatar>
                                                    <div class="d-block ms-1">
                                                        <span class="custella-content-row-input-title-three" id="value_TaskActivityNotes">{{ activity?.note }}</span>
                                                        <span class="text-10 color-var-500" id="value_TaskActivityCreateTime">{{ activity?.createdDate | momentDate: "hh:mm a":timezone }}</span>
                                                    </div>
                                                    <button (click)="showViewMap(activity, task)" id="btn_TaskActivityLoc" *ngIf="activity.type == 'TASK_CHECKIN' || activity.type == 'TASK_CHECKOUT'" type="button" class="custella-btn-icon border rounded-1 p-1 ms-auto h-35">
                                                        <img alt="logo" src="assets/svg/sidebar/sites.svg" id="img_TaskActivityLoc" />
                                                    </button>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

                
            </div>

           
        </div>
    </div>

    <!-- View Check-in Details -->
    <div class="right-pane-fr-task">
        <div class="custella-form-container m-0 box-none">
            <!-- Additional Details -->
            <div class="custella-form-container-title-two flex-between">
                <div class="font-medium text-14" id="title_TaskAddDet" >{{ translate?.task.sections.sec1.singular }}</div>
                <i class="pi pi-times" id="btn_TaskAddDetClose" (click)="closeRightNav()"></i>
            </div>
            <div class="custella-form-container-content-border-bottom px-3" *ngIf="task?.completionDateTime">
                <div class="custella-content-row mt-3 mb-0">
                    <div class="custella-content-row-input col-12 m-0">
                        <span class="custella-content-row-input-title-two" id="label_TaskCompletionDateTime" >{{ translate?.task.fields.completionDateTime.singular }}</span>
                        <span *ngIf="task?.completionDateTime" class="custella-content-row-input-title-three" id="value_TaskCompletionDateTime"> {{ task?.completionDateTime | momentDate: "hh:mm a":timezone }} {{ task?.completionDateTime | momentDate: dateFormat:timezone }}</span>
                    </div>
                </div>
            </div>
            <div class="custella-form-container-content-border-bottom px-3" >
                <div class="custella-content-row mt-3 mb-0">
                    <div class="custella-content-row-input col-12 m-0">
                        <span class="custella-content-row-input-title-two" id="label_TaskBufferBeforeTask" >{{ translate?.task.fields.bufferBeforeTask.singular }}</span>
                        <span *ngIf="task?.hasBuffer" class="custella-content-row-input-title-three" id="value_TaskBufferBeforeTask" > {{task?.startBuffer}}&nbsp;{{ translate?.task.fields.minsBefore.singular }}</span>
                        <span *ngIf="!task?.hasBuffer" class="custella-content-row-input-title-three" id="value_TaskBufferBeforeTask"> - </span>
                    </div>
                </div>
            </div>
            <div class="custella-form-container-content-border-bottom px-3" >
                <div class="custella-content-row mt-3 mb-0">
                    <div class="custella-content-row-input col-12 m-0">
                        <span class="custella-content-row-input-title-two" id="label_TaskBufferAfterTask" >{{ translate?.task.fields.bufferAfterTask.singular }}</span>
                        <span *ngIf="task?.hasBuffer" class="custella-content-row-input-title-three" id="value_TaskBufferAfterTask" > {{task?.endBuffer}}&nbsp;{{ translate?.task.fields.minsAfter.singular }}</span>
                        <span *ngIf="!task?.hasBuffer" class="custella-content-row-input-title-three" id="value_TaskBufferAfterTask"> - </span>
                    </div>
                </div>
            </div>
            <div class="custella-form-container-content-border-bottom px-3" >
                <div class="custella-content-row mt-3 mb-0">
                    <div class="custella-content-row-input col-12 m-0">
                        <span class="custella-content-row-input-title-two" id="label_TaskSkillDynamic" >{{ translate?.task.fields.skills.singular }}</span>
                        <div>
                            <div *ngIf="task?.userDynamicSkillList == null || task?.userDynamicSkillList.length < 1" id="value_TaskSkillDynamic" class="py-1 normal-font f-grey d-flex align-items-center">-</div>
                            <div *ngFor="let skill of task?.userDynamicSkillList;let i = index">
                                <div class="rounded-5 bg-purple px-2 py-1 mx-1 mb-1 w-fit">
                                    <div class="d-flex align-items-center">
                                        <span class="text-12 font-white text-wrap px-1" id="value_TaskSkillDynamic">{{skill.label}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- added hyperlink for site and project -->
            <div class="custella-form-container-content-border-bottom px-3" *ngIf="siteRead">
                <div class="custella-content-row mt-3 mb-0">
                    <div class="custella-content-row-input col-12 m-0">
                        <span class="custella-content-row-input-title-two" id="label_TaskSite">{{ translate?.site.label.singular }}</span>
                        <a 
                            class="custella-content-row-input-title-three link-style" 
                            id="value_TaskSite" 
                            *ngIf="task?.site" 
                            (click)="viewSite(task.site.id)">
                            {{ task.site.siteName }}
                        </a>
                        <span class="custella-content-row-input-title-three" id="value_TaskSite" *ngIf="!task?.site"> - </span>
                    </div>
                </div>
            </div>
            
            <div class="custella-form-container-content-border-bottom px-3" *ngIf="projectRead && addonFeature.addonPROJECT === 'PROJECT'">
                <div class="custella-content-row mt-3 mb-0">
                    <div class="custella-content-row-input col-12 m-0">
                        <span class="custella-content-row-input-title-two" id="label_TaskProj">{{ translate?.project.label.singular }}</span>
                        <a 
                            class="custella-content-row-input-title-three link-style" 
                            id="value_TaskProj" 
                            *ngIf="task?.project" 
                            (click)="viewProject(task.project.id)">
                            {{ task.project.name }}
                        </a>
                        <span class="custella-content-row-input-title-three" id="value_TaskProj" *ngIf="!task?.project"> - </span>
                    </div>
                </div>
            </div>
            
            
            
            <!-- System Information -->
            <!-- <div class="custella-form-container-title-two text-14 font-medium" >
                {{'system_information'}}
            </div> -->
            <!-- Created by and last modified by -->
            <!-- <div class="custella-form-container-content-border-bottom px-3" >
                <div class="custella-content-row mt-3 mb-0">
                    <div class="custella-content-row-input col-12 m-0">
                        <span class="custella-content-row-input-title-two" >{{'created_by'}}</span>
                        <div class="custella-vp-picture d-flex align-items-center">
                            <p-avatar *ngIf="task?.createdByUser?.imageUrl && task?.createdByUser?.imageUrl !== ''" [image]="task?.createdByUser?.imageUrl" styleClass="mr-2 mt-1" shape="circle"></p-avatar>
                            <p-avatar *ngIf="!task?.createdByUser?.imageUrl || task?.createdByUser?.imageUrl === ''" image="assets/svg/Avatar.svg" styleClass="mr-2 mt-2" shape="circle"></p-avatar>
                            <div class="mx-2">
                                <a class="custella-content-row-input-title-three">{{task?.createdByUser?.fullName}},</a>
                                <span class="custella-content-row-input-title-three d-block text-nowrap">{{task?.createdDate | momentDate : 'hh:mm a' : timezone}}, {{task?.createdDate | momentDate : dateFormat : timezone}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="custella-form-container-content-border-bottom px-3" >
                <div class="custella-content-row mt-3 mb-0">
                    <div class="custella-content-row-input col-12 m-0">
                        <span class="custella-content-row-input-title-two" >{{'modified_by'}}</span>
                        <div class="custella-vp-picture d-flex align-items-center">
                            <p-avatar *ngIf="task?.lastModifiedByUser?.imageUrl && task?.lastModifiedByUser?.imageUrl !== ''" [image]="task?.lastModifiedByUser?.imageUrl" styleClass="mr-2 mt-1" shape="circle"></p-avatar>
                            <p-avatar *ngIf="!task?.lastModifiedByUser?.imageUrl || task?.lastModifiedByUser?.imageUrl === ''" image="assets/svg/Avatar.svg" styleClass="mr-2 mt-1" shape="circle"></p-avatar>
                            <div class="mx-2">
                                <span class="custella-content-row-input-title-three">{{task?.lastModifiedByUser?.fullName}},</span>
                                <span class="custella-content-row-input-title-three d-block text-nowrap">{{task?.lastModifiedDate | momentDate : ' hh:mm a' : timezone}}, {{task?.lastModifiedDate | momentDate : dateFormat : timezone}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>

<!-- Customer Summary -->
<div class="right-pane-fr-cus-summ-task">
    <app-customer-summery-slider (onClosetAsset)="closeCustomerRightNav()" [CustomerSummary]="customerSummery"></app-customer-summery-slider>
</div>