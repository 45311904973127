import { of as observableOf, Observable, throwError } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import * as moment from "moment-timezone";
import { AuthenticationService } from "../auth/authentication.service";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { Http, RequestOptions, Headers } from "@angular/http";
import { param } from "jquery";

@Injectable()
export class ChecklistService {
  public refreshList$: EventEmitter<any>;
  public refresQuestionList$: EventEmitter<any>;
  public refreshRedirectLink$: EventEmitter<any>;

  private ENDPOINT_CHECKLIST_TEMPLATE="/v1/tmpl-checklists";
  private ENDPOINT_CHECKLIST_QUESTION="/v1/tmpl-checklist-questions";
  private ENDPOINT_MACHINES_SPECIALIST="ms/v1/machine-specialists";

  private ENDPOINT_CHECKLIST_GLOBAL="/checklist-records";

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private router: Router,
    private httpOnly: Http
  ) {
    this.refreshList$ = new EventEmitter();
    this.refresQuestionList$ = new EventEmitter();
    this.refreshRedirectLink$ = new EventEmitter();
    
  }

/******************************************** List of Checklist Setup Templates ******************************************/
public getAllChecklistTemplates( search: any, parameters: any, serviceZoneId: any,assetTypeId: any,equipmentType: any) {
  let params = new HttpParams();
  if (search !== "" && search != null) {
    params = params.set("search", search);
  }
  if (serviceZoneId !== "" && serviceZoneId != null) {
    params = params.set("serviceZoneId", serviceZoneId);
  }

  if (assetTypeId !== "" && assetTypeId != null) {
    params = params.set("assetTypeId", assetTypeId);
  }

  if (equipmentType !== "" && equipmentType != null) {
    params = params.set("equipmentType", equipmentType);
  }
  if (parameters)
    //console.log(parameters)
    params = params
      .set("startFrom", parameters.startRow)
      .set("perPage", "25")
      .set("columnName", parameters.sortModel[0].colId)
      .set("sortOrder", parameters.sortModel[0].sort);


  return this.http .get(environment.ms_base_url + this.ENDPOINT_CHECKLIST_TEMPLATE+'/filter', { observe: "response", params: params}).pipe(map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
}

/******************************************** Get CheckList By ID ******************************************/
public getChecklistById(id: any) {
  return this.http.get(environment.ms_base_url + this.ENDPOINT_CHECKLIST_TEMPLATE+'/'+ id).pipe(
      map((resp: any) => {
        return resp || resp[0].id;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
}




/******************************************** create task Template ******************************************/
public createCheckList(body: any) {
  return this.http.post(environment.ms_base_url + this.ENDPOINT_CHECKLIST_TEMPLATE, body, { observe: "response" }).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
}
/******************************************** Update CheckList ******************************************/
public editCheckList(body: any) {
  return this.http.put(environment.ms_base_url + this.ENDPOINT_CHECKLIST_TEMPLATE, body, { observe: "response" }).pipe(
      map((resp: any) => {
       return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
}

/************************************************* delete CheckList **********************************************/
public deleteCheckList(id: any){
  return this.http.delete( environment.ms_base_url+this.ENDPOINT_CHECKLIST_TEMPLATE+'/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit('Deleted Checklist Setup');
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}


/******************************************** List of Checklist Setup : Question ******************************************/
public getAllChecklistQuestion( search: any, parameters: any, checklistId: any,) {
  let params = new HttpParams();
  if (search !== "" && search != null) {
    params = params.set("search", search);
  }
  if (checklistId !== "" && checklistId != null) {
    params = params.set("tmplChecklistId", checklistId);
  }

  if (parameters)
    //console.log(parameters)
    params = params
      .set("startFrom", parameters.startRow)
      .set("perPage", "25")
      .set("columnName", parameters.sortModel[0].colId)
      .set("sortOrder", parameters.sortModel[0].sort);


  return this.http .get(environment.ms_base_url + this.ENDPOINT_CHECKLIST_QUESTION+'/filter', { observe: "response", params: params}).pipe(map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
}


/******************************************** Get Question  ******************************************/
public getChecklistQuestionById(id: any) {
  return this.http.get(environment.ms_base_url + this.ENDPOINT_CHECKLIST_QUESTION+'/'+ id).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
}


/******************************************** create Question ******************************************/
public createCheckQuestionList(body: any) {
  return this.http.post(environment.ms_base_url + this.ENDPOINT_CHECKLIST_QUESTION, body, { observe: "response" }).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
}
/******************************************** Update Question ******************************************/
public editCheckListQuestion(body: any) {
  return this.http.put(environment.ms_base_url + this.ENDPOINT_CHECKLIST_QUESTION, body, { observe: "response" }).pipe(
      map((resp: any) => {
       return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
}

/************************************************* delete Question **********************************************/
public deleteCheckListQuestion(id: any){
  return this.http.delete( environment.ms_base_url+this.ENDPOINT_CHECKLIST_QUESTION+'/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresQuestionList$.emit('Deleted Checklist Question');
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

/******************************************** create Question answer Product ******************************************/
public createAnswerByProduct(body: any) {
  return this.http.post(environment.ms_base_url + '/v1/tmpl-checklist-ans-products', body, { observe: "response" }).pipe(
      map((resp: any) => {
        this.refresQuestionList$.emit(resp);
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
}


/************************************************* delete Question answer Product **********************************************/
public deleteAnswerByProduct(id: any){
  return this.http.delete( environment.ms_base_url+'/v1/tmpl-checklist-ans-products/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresQuestionList$.emit('Deleted Answer By Product');
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/******************************************** create Question answer Task ******************************************/
public createAnswerByTask(body: any) {
  return this.http.post(environment.ms_base_url + '/v1/tmpl-checklist-ans-tasks', body, { observe: "response" }).pipe(
      map((resp: any) => {
        this.refresQuestionList$.emit(resp);
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
}


/************************************************* delete Question answer Task **********************************************/
public deleteAnswerByTask(id: any){
  return this.http.delete( environment.ms_base_url+'/v1/tmpl-checklist-ans-tasks/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresQuestionList$.emit('Deleted Answer By Product');
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}


/******************************************** List of Machines Specialist ******************************************/
public getAllMachinesSpecialist( search: any, parameters: any, siteId: any,filterData: any) {
  let params = new HttpParams();
  if (search !== "" && search != null) {
    params = params.set("search", search);
  }
  if (siteId !== "" && siteId != null) {
    params = params.set("siteId", siteId);
  }
  if(filterData){
  if (filterData.userType) {
    params = params.set("userType", filterData.userType);
  }
  if (filterData.status != null) {
    params = params.set("status", filterData.status);
  }
}

  if (parameters)
    //console.log(parameters)
    params = params
      .set("startFrom", parameters.startRow)
      .set("perPage", "25")
      .set("columnName", parameters.sortModel[0].colId)
      .set("sortOrder", parameters.sortModel[0].sort);


  return this.http .get(environment.ms_node_base_url + this.ENDPOINT_MACHINES_SPECIALIST+'/filter', { observe: "response", params: params}).pipe(map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
}


/******************************************** Get  Machines Specialist  ******************************************/
public getMachinesSpecialistById(id: any) {
  return this.http.get(environment.ms_node_base_url + this.ENDPOINT_MACHINES_SPECIALIST+'/'+ id).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
}


/******************************************** create Machines Specialist ******************************************/
public createMachinesSpecialist(body: any) {
  return this.http.post(environment.ms_node_base_url + this.ENDPOINT_MACHINES_SPECIALIST, body, { observe: "response" }).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
}
/******************************************** Update Machines Specialist ******************************************/
public editMachinesSpecialist(body: any) {
  return this.http.put(environment.ms_node_base_url + this.ENDPOINT_MACHINES_SPECIALIST, body, { observe: "response" }).pipe(
      map((resp: any) => {
       return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
}

/************************************************* delete  Machines Specialist **********************************************/
public deleteMachinesSpecialistById(id: any){
  return this.http.delete( environment.ms_node_base_url+this.ENDPOINT_MACHINES_SPECIALIST+'/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit('Deleted Machines Specialist');
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}



/******************************************** List of Checklist Setup Templates ******************************************/
public getAllChecklistGlobal( search: any, parameters: any,filter: any) {
  let params = new HttpParams();
  if (search !== "" && search != null) {
    params = params.set("search", search);
  }
  if (filter !== "" && filter.serviceZoneId != null) {
    params = params.set("serviceZoneId", filter.serviceZoneId);
  }

  if (filter !== "" && filter.assetTypeId != null) {
    params = params.set("assetsTypeId", filter.assetTypeId);
  }

  if (filter.equipmentType !== "" && filter.equipmentType != null) {
    params = params.set("equipmentType", filter.equipmentType);
  }
  if (filter.status !== "" && filter.status != null) {
    const result = filter.status.join(',');
    params = params.set("checklistStatuses", result);
  }
  if (filter.fromDate) {
    params = params.set('fromDate', filter.fromDate);
  }
  if (filter.toDate) {
    params = params.set('toDate', filter.toDate);
  }
  if(filter.assetId)
    {
      params = params.set('assetsId', filter.assetId)
    }
    if(filter.userName)
      {
        params = params.set('createdBy', filter.userName)
      }
  if (parameters)
    //console.log(parameters)
    params = params
      .set("startFrom", parameters.startRow)
      .set("perPage", "25")
      .set("columnName", parameters.sortModel[0].colId)
      .set("sortOrder", parameters.sortModel[0].sort);


  return this.http .get(environment.base_url + this.ENDPOINT_CHECKLIST_GLOBAL+'/filter', { observe: "response", params: params}).pipe(map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
}

/******************************************** Get CheckList By ID ******************************************/
public getChecklistGlobalById(id: any) {
  return this.http.get(environment.base_url + '/checklist-records/'+ id).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
}


/******************************************** create task Template ******************************************/
public createCheckListGlobal(body: any) {
  return this.http.post(environment.base_url + '/checklist-questions', body, { observe: "response" }).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
}
/******************************************** Update CheckList ******************************************/
public editCheckListGlobal(body: any) {
  return this.http.put(environment.base_url + '/checklist-questions', body, { observe: "response" }).pipe(
      map((resp: any) => {
       return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
}

/******************************************** Add Asset Status ******************************************/
public updateEquipmentMeterReading(assetsId: any,equipmentMeterReading: any) {
  
 
  return this.http.put(environment.base_url + '/assets/update-equipment-meter-reading?assetsId='+assetsId+'&equipmentMeterReading='+equipmentMeterReading, { observe: "response"}).pipe(map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
}
/********************************************  Add Asset Status  ******************************************/
public addAssetsStatusForChecklist(assetsId: any,statusId: any) {
 

  return this.http.put(environment.base_url + '/assets/status?id='+assetsId+'&statusId='+statusId,{ observe: "response"}).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
}
/******************************************** Get CheckList By ID ******************************************/
public getChecklistByAssetType(equipmentType: any,assetId: any) {
  let params = new HttpParams();
  if (equipmentType !== "" && equipmentType != null) {
    params = params.set("equipmentType", equipmentType);
  }
  if (assetId !== "" && assetId != null) {
    params = params.set("assetId", assetId);
  }

  

  return this.http.get(environment.base_url +'/tmpl-checklists/picklist', { params: params}).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
}

/******************************************** Get CheckList QuestionBy ID ******************************************/
public getChecklistQuestion(id: any) {
  return this.http.get(environment.base_url +'/checklist-record-tmpl/'+ id).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
}
/******************************************** Get Question By ID ******************************************/
public getQuestionForm(id: any) {
  return this.http
    .get(environment.base_url + '/checklist-questions?checklistRecordId=' + id, { observe: "response" })
    .pipe(map(
      (resp: any) => {
        return resp.body;
      },
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    )
    );
}
/************************************************* delete CheckList **********************************************/
public deleteCheckListGlobal(id: any){
  ////debugger
  return this.http.delete( environment.base_url+'/checklist-records/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit('Deleted Checklist');
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
public refreshViewRoute(data: any){
  this.refreshRedirectLink$.emit(data);
}

/******************************************** Update checklist asset data before and after ******************************************/
public updateBeforeAndAfterData(body: any) {
  return this.http.put(environment.base_url + '/checklist-records/status-reading-update', body, { observe: "response" }).pipe(
    map((resp: any) => {
     return resp;
    }),
    catchError((error) => {
      //console.log(error);
      return observableOf(error);
    })
  );
}

/******************************************** Update PMV asset data before and after ******************************************/
public updateBeforeAndAfterDataMS(body: any) {
  return this.http.put(environment.ms_node_base_url + 'ms/v1/pmvs/status-reading-update', body, { observe: "response" }).pipe(
    map((resp: any) => {
     return resp;
    }),
    catchError((error) => {
      //console.log(error);
      return observableOf(error);
    })
  );
}
}
