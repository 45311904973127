import { Injectable } from '@angular/core';
import {  CanActivate,  ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { PermissionService } from './permission.service';
@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    public auth: AuthenticationService, 
    public permission:PermissionService,
    public router: Router) {}

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    var module:any=route.data['module'];
    var action:any=route.data['action'];

    //console.log(module, action, this.permission.capable(module,action), state);


    if(this.auth.isAuthenticated() && this.permission.capable(module,action)){
      return true;
    }else{

      if (module === 'manage_users') {
        const gotId = state?.root?.queryParams?.id;

        if (!gotId) {
          return true;
        }
    }
    
    if (state.url === '/dashboard/setup/allUser/view') {
      const gotId = state?.root?.queryParams?.id;

      if (!gotId) {
        return true;
      }
    }

      this.router.navigateByUrl("/dashboard/error", {state: { access: { module, action }}});
      return false;
    }
    
  }

  canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
    if(this.auth.isAuthenticated()){
      return true;
    }
    else
    { 
      this.router.navigateByUrl("/login");
      return false;
    }
  }

  

}