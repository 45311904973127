import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { JobOrdersTableComponent } from "./job-orders-table/job-orders-table.component";
import { SharedModule } from "src/app/shared/shared.module";
import { AgGridModule } from "ag-grid-angular";
import { SlaResponseComponent } from "src/app/shared/comman-component/sla-response/sla-response.component";
import { TasksTableComponent } from "./tasks-table/tasks-table.component";
import { SalesOrdersTableComponent } from "./sales-orders-table/sales-orders-table.component";
import { ContractsTableComponent } from "./contracts-table/contracts-table.component";
import { AssetsTableComponent } from "./assets-table/assets-table.component";
import { CommanComponentModule } from "src/app/shared/comman-component/comman-component.module";
import { ProjectsTableComponent } from "./projects-table/projects-table.component";
import { SitesTableComponent } from "./sites-table/sites-table.component";
import { ContactsTableComponent } from "./contacts-table/contacts-table.component";
import { AddEditContactComponent } from "./add-edit-contact/add-edit-contact.component";
import { ViewContactComponent } from "./view-contact/view-contact.component";
import { ListTicketTableComponent } from "./list-ticket-table/list-ticket-table.component";
import { AllProductsTableComponent } from './all-products-table/all-products-table.component';
import { ChecklistTableComponent } from './checklist-table/checklist-table.component';
import { UpdateStatusComponent } from './update-status/update-status.component';
import { ResetContactPasswordComponent } from "./reset-contact-password/reset-contact-password.component";
import { ListChecklistComponent } from "../checklist/list-checklist/list-checklist.component";
import { UsersComponent } from "../setup/users/users.component";

@NgModule({
  declarations: [
    JobOrdersTableComponent,
    SlaResponseComponent,
    TasksTableComponent,
    SalesOrdersTableComponent,
    ContractsTableComponent,
    AssetsTableComponent,
    ProjectsTableComponent,
    SitesTableComponent,
    ContactsTableComponent,
    AddEditContactComponent,
    ViewContactComponent,
    ListTicketTableComponent,
    AllProductsTableComponent,
    ChecklistTableComponent,
    UpdateStatusComponent,
    ResetContactPasswordComponent,
    ListChecklistComponent
  ],
  imports: [CommonModule, SharedModule, AgGridModule, CommanComponentModule],
  exports: [
    JobOrdersTableComponent,
    SlaResponseComponent,
    TasksTableComponent,
    SalesOrdersTableComponent,
    ContractsTableComponent,
    AssetsTableComponent,
    ProjectsTableComponent,
    SitesTableComponent,
    ContactsTableComponent,
    AddEditContactComponent,
    ViewContactComponent,
    ListTicketTableComponent,
    AllProductsTableComponent,
    ResetContactPasswordComponent,
    ListChecklistComponent
  ],
})
export class ComponentSharedModule {}
