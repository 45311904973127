import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { AgGridAngular } from "ag-grid-angular";
import {
  ColDef,
  GridOptions,
  IServerSideGetRowsParams,
} from "ag-grid-community";

import * as moment from "moment-timezone";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { PermissionService } from "src/app/auth/permission.service";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { StatusRendererComponent } from "src/app/renderer/status-renderer.component";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import {
  AppEvents,
  PERSISTANT_ITEM,
  ServerRequest,
} from "src/app/_models/global.data.model";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { JobOrdersService } from "src/app/_services/job-orders.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { CustomersService } from "src/app/_services/customers.service";
import { Subscription } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { Global } from "src/app/_utilities/global";
import { DynamicLabelsService } from "src/app/_services/dynamic-labels.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "app-job-orders-table",
  templateUrl: "./job-orders-table.component.html",
  styleUrls: ["./job-orders-table.component.scss"],
})
export class JobOrdersTableComponent
  extends SubscriptionUtil
  implements OnInit, OnChanges, OnDestroy {
  @Input() search: string = "";
  @Input() accessFrom = "";
  // pass in data object
  // {
  // possible
  // ticketId: 111,
  // salesOrderId: 1111,
  // assetId: 111
  // }
  @Input() data: any;
  @Input() extraData: any;

  @Output() noDataEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() isFilterClosed = new EventEmitter();
  noJobOrders: boolean = false;
  isFilterToggleOn: boolean = false;

  totalRecord: any;

  @ViewChild("agGrid") agGrid!: AgGridAngular;
  addonFeature!: any;
  selectedRange: any;
  dateRange!: any;
  filter: any = {
    status: null,
    customerId: null,
    salesOrderId: null,
    fromDate: "",
    toDate: "",
  };
  myGroup!: any;
  parameters: any;
  customers: any[] = [];
  filteredCustomers: any[] = [];
  dateConfig: any;
  //aggrid
  jobOrders: any[] = [];
  jobOrder = [];
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any = [];
  defaultColDef: any;
  frameworkComponents: any;
  pageSize: any = 25;
  startFrom: any;
  gridParams: any;
  public gridOptions!: Partial<GridOptions>;
  displayicon: boolean = false;
  overlayNoRowsTemplate: any;
  salePer: boolean = false;
  servicePer: boolean = false;
  fleetPer: boolean = false;
  actionData!: ColDef;
  statuses: any;
  dateFormateChange: any;
  statusesJS: any[] = [];
  currentRecordNumber: any;
  jobOrderIdSelected: any;

  private filterAPILoaded = false;

  public showingTable = {
    customer: false,
  };

  selectedCustomer: any;
  loadTable: any = 0;

  deleteSubscription!: Subscription;
  sortBy: any;
  sortOrder: any;
  @Input() translate: any;
  tableContent: any;


  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private perm: PermissionService,
    private jobOrdersService: JobOrdersService,
    private errorUtil: ErrorUtil,
    private util: UtilServiceService,
    private deleteRecordService: DeleteRecordService,
    private customerServices: CustomersService,
    private spinner: NgxSpinnerService,
  ) {
    super();

    this.preInit();

    this.myGroup = new UntypedFormGroup({
      status: new UntypedFormControl(''),
     
    });
  }

  ngOnInit() {
    this.init();
    this.initializeColumns();

  }
  /******************************************** ngOnChanges ******************************************/
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.search &&
      changes.search.previousValue !== changes.search.currentValue
    ) {
      this.onGridReady(this.gridParams);
    }

    if (
      changes &&
      changes.search &&
      changes.search.previousValue !== changes.search.currentValue &&
      changes.search.currentValue === ""
    ) {
      this.onGridReady(this.gridParams);
    }

    if (
      changes &&
      changes.accessFrom &&
      changes.accessFrom.previousValue !== changes.accessFrom.currentValue
    ) {
      this.onGridReady(this.gridParams);
    }
    /************************** Check translate object changed or not:  Language Translated Dynamically (Preeti) ************************************/
    if (
      changes &&
      changes.translate &&
      changes.translate.previousValue != undefined &&
      Object.keys(changes.translate.previousValue).length !== 0 &&
      changes.translate.previousValue !== changes.translate.currentValue
    ) {
      this.setOnlyColumn();
    }
  }
  /******************************************** ngOnDestroy ******************************************/
  ngOnDestroy() {
    this.onDestroy();
  }

  /******************************************** pre-load variables ******************************************/
  private preInit() {

    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      statusRenderer: StatusRendererComponent,
    };

    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params: any) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.defaultColDef = {
      enableRowGroup: true, //ag-Grid-Enterprise
      enablePivot: true, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };


  }

  private initializeColumns() {
    // get all job order status
    this.push(
      this.jobOrdersService.getJobOrderStatuses().subscribe((res: any) => {
        this.statuses = res.sort((elementA: any, elementB: any) => {
          if (elementA?.order > elementB?.order) {
            return 1;
          }

          if (elementA?.order < elementB?.order) {
            return -1;
          }

          return 0;
        });

        res.forEach((element: any) => {
          this.statusesJS.push(element);
        });

        localStorage.setItem("joStatuses", JSON.stringify(this.statuses));
      })
    );
    var profileInfo = JSON.parse(this.auth.getProfileInfo());
    this.findMenuPermissions(profileInfo.tenantCategories);
    this.defaultColDef = {
      enableRowGroup: true, //ag-Grid-Enterprise
      enablePivot: true, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };

    this.defaultColDef = {
      enableRowGroup: true, //ag-Grid-Enterprise
      enablePivot: true, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };

    this.overlayNoRowsTemplate =
      '<span style="padding: 10px; font-size: 14px;">' + this.translate?.general.messages.noRecordsFound + '</span>';

    this.actionData = {
      headerName: this.translate?.general.buttons.action.singular,
      filter: false,
      sortable: false,
      width: 0,
      headerTooltip: "#",
      headerClass: "marging-auto hide-action-border",
      cellStyle: { "text-align": "center" },
      cellRenderer: "buttonRenderer",
      cellRendererParams: {
        onClick: this.edit.bind(this),
        permissions: {
          update: this.perm.capable("job_order", "update"),
          remove: this.perm.capable("job_order", "remove"),
        },
        hidden: {
          update: !this.perm.capable("job_order", "update"),
          remove: !this.perm.capable("job_order", "remove"),
        },
      },
    };

    // get jo table column from BE
    this.jobOrdersService.getListColumn("job_order").subscribe((res: any) => {
      this.tableContent = res
      this.sortBy = res.sortBy
      this.sortOrder = res.sortOrder

      console.log("table content", this.sortBy)

      let columnDefs = this.gridOptions.columnDefs
      res.tableColumnSetups.forEach((element: any) => {
        const modifiedColumn = this.modifyColumnTable(element);

        columnDefs!.push(modifiedColumn);
        this.gridOptions.api!.setColumnDefs(columnDefs);
      });

      columnDefs!.push(this.actionData);

      this.gridOptions.api!.setColumnDefs(columnDefs);
      this.loadTable = 1;
      this.onGridReady(this.gridParams);
    });
  }
  getNestedValue(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }
  /******************************************** modify column to fit new ag-grid ******************************************/
  public modifyColumnTable(element: any) {
    const fromCustomer = this.data?.customerId;
    const fromAssets = this.data?.assetsId;
    var dateFormat: any = localStorage.getItem("date_format");
    var getUserTimezone: any = this.auth.getUserTimezone();

    // base
    if (element.translatePath) {
      element.headerName = this.getNestedValue(this.translate, element.translatePath);
    }
    const column: ColDef = {
      field: "jobOrder." + element.field,
      headerName: element.headerName,
      hide: element.hide,
      width: 30,
      colId: element.field,
      cellClass: element.cellClass,
      headerTooltip: "#",
    };

    if (element.field === "ticket.ticketNo") {
      // column.cellClass = function (params: any) {
      //   return ["table_default_color-top"];
      // };
      column.cellRenderer = function (params: any) {
        if (params && params.value && params.value != undefined) {
          return params.value
        } else {
          return '-'
        }
      }
    }

    else if (element.field == "jobOrderNumber") {
      ////  column.headerName = "Job Order No.";
      column.headerComponentParams = {
        template:
          '<div class="ag-cell-label-container" role="presentation" id="Tbl_HeadJoOrderbNo">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>'
      }
      column.cellClass = function (params: any) {
        return ["table_default_color-top"];
      };
      column.cellRenderer = function (params: any) {
        if (params && params.data && params.data.jobOrder) {
          const hasRedFlag = params.data.jobOrder.redFlag;

          const number = params.data.jobOrder.jobOrderNumber;

          let final = `${number}`;

          if (hasRedFlag) {
            final += '  <i class="pi pi-flag" style="color: red"></i>';
          }

          return '<p id="' + params.data.rowId + '">' + final + '</p>';
        }
      }
    }

    else if (element.field == "customer.name") {
      column.cellClass = function (params: any) {
        return ["table_default_color"];
      };
      column.headerComponentParams = {
        template:
          '<div class="ag-cell-label-container" role="presentation" id="Tbl_HeadJobOrderCustomer">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>'
      };
      column.cellRenderer = function (params: any) {

        if (params.data) {
          return '<p id="' + params.data.rowId2 + '">' + params.data && params.data.jobOrder.customer ? params.data.jobOrder.customer.name : "-" + '</p>';
        }
      };

      if (fromCustomer) {
        column.hide = true;
      }
    }

    else if (element.field == "assets.name") {
      element.headerComponentParams = Global.setHeaderComponentParams(element, 'Tbl_HeadJobOrderAssets'),
        element.cellRenderer = function (params: any) {
          if (params.data) {
            return Global.setCellRenderer(params, params.data.rowId3, params.data.customer?.name)
          }
        };


      if (fromAssets) {
        column.hide = true;
      }
    }

    else if (element.field == "installationDate" || element.field == "installationTime") {
      //  column.headerName = "Order Date & Time";
      column.cellRenderer = (params: any) => {
        if (params.data) {
          if (
            params.data.jobOrder.installationTime !== null &&
            params.data.jobOrder.installationTime !== undefined &&
            params.data.jobOrder.installationTime !== ""
          ) {
            return (
              moment(params.data.jobOrder.installationTime).format(
                localStorage.getItem("date_format")?.toUpperCase()
              ) +
              ", " +
              moment(params.data.jobOrder.installationTime).format("hh:mm A")
            );
          } else {
            return "-";
          }
        }
      };
    }

    else if (element.field == "completionDate") {
      column["cellRenderer"] = (params: any) => {
        if (params.data) {
          if (
            params.data.jobOrder.completionDate !== null &&
            params.data.jobOrder.completionDate !== undefined &&
            params.data.jobOrder.completionDate !== ""
          ) {
            return moment(params.data.jobOrder.completionDate).format(
              localStorage.getItem("date_format")!.toUpperCase()
            );
          } else {
            return "";
          }
        }
      };
    }

    else if (element.field == "completionDateTime") {
      column["cellRenderer"] = (params: any) => {
        if (params.data) {
          if (params.data.jobOrder.completionDateTime !== null && params.data.jobOrder.completionDateTime !== undefined && params.data.jobOrder.completionDateTime !== "") {
            return (
              moment(params.data.jobOrder.completionDateTime).format(
                localStorage.getItem("date_format")?.toUpperCase()
              ) +
              ", " +
              moment(params.data.jobOrder.completionDateTime).format("hh:mm A")
            );
          } else {
            return "-";
          }
        };
      }
    }

    else if (element.field == "status") {
      column.cellEditor = "agRichSelectCellEditor";
      column.editable = true;
      column.cellRenderer = "statusRenderer";

      column.cellEditorParams = {
        values: this.statusesJS,
        cellRenderer: "statusRenderer",
      };

      // column["cellRenderer"] = (params: any) => {
      //   let status: any;

      //   if (params.data) {
      //     if (params.data.jobOrder && params.data.jobOrder.status) {
      //       const currentStatus = params.data.jobOrder.status;

      //       let satusObj: any;

      //       satusObj = this.statusesJS.find(
      //         (status) => status.name === currentStatus
      //       );

      //       if (satusObj) {
      //         status =
      //           ' <span class="p-mr-2  p-tag p-component" style="background-color:' +
      //           satusObj.colorCode +
      //           "50 !important ;color:" +
      //           satusObj.colorCode +
      //           '"><span class="p-tag-value text-dark">' +
      //           satusObj.fieldLabel +
      //           "</span></span>";
      //       } else {
      //         status = "";
      //       }
      //     }
      //   }

      //   return status;
      // };

      // console.log(this.statusesJS);

      column.width = 20;
    }
    else if (element.dataType == 'date') {

      column.cellRenderer = function (params: any) {
        if (params && params.value) {

          return moment.tz(params.value, getUserTimezone).format(dateFormat.toUpperCase());
        } else {
          return '-'
        }
      };
    } else if (element.dataType == "text") {
      column.cellRenderer = function (params: any) {
        if (params && params.value && params.value != undefined) {
          return params.value
        } else {
          return '-'
        }
      }
    } else if (element.dataType == 'datetime') {
      column.cellRenderer = function (params: any) {
        console.log(params.value, "===>>>>", getUserTimezone, "===>>>>", dateFormat.toUpperCase())
        if (params && params.value) {
          return moment.tz(params.value, getUserTimezone).format("hh:mm A" + ", " + dateFormat.toUpperCase());

        } else {
          return '-'
        }
      };

    } else if (element.dataType == "boolean") {
      column.cellRenderer = function (params: any) {
        if (params && params.value) {
          return 'Yes'
        } else {
          return 'No'
        }
      }
    }
    return column;
  }

  /******************************************** init and load data ******************************************/
  private init() {
    this.dateFormateChange = this.auth.getUserTimezone();
    this.addonFeature = this.auth.addonFeature();

    this.dateConfig = {
      rangeInputFormat: localStorage.getItem("date_format")!.toUpperCase(),
    };

    // get customer for job order filter
    // this.push(
    //   this.jobOrdersService
    //     .getCustomersForJobOrderPicklist()
    //     .subscribe((res: any) => {
    //       this.customers = res;
    //       this.filteredCustomers = res;
    //     })
    // );

    // listen to refresh job order events
    this.push(
      this.util.getData().subscribe((data: any) => {
        if (data && data.action === AppEvents.REFRESH_JOB_ORDER_TABLE) {
          this.rerender();
        }
      })
    );
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** set permissions ******************************************/
  public findMenuPermissions(tenantCategories: any) {
    var saleIndex = tenantCategories.indexOf("SALES");
    if (saleIndex != -1) {
      this.salePer = true;
    }
    var serviceIndex = tenantCategories.indexOf("SERVICE");
    if (serviceIndex != -1) {
      this.servicePer = true;
    }
    var fleetIndex = tenantCategories.indexOf("FLEET");
    if (fleetIndex != -1) {
      this.fleetPer = true;
    }
  }

  /******************************************** reload table ******************************************/
  public rerender() {
    this.onGridReady(this.gridParams);
  }

  /******************************************** grid on ready ******************************************/
  public onGridReady(params: any) {
    if (params) {
      this.gridParams = params;

      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();

      //  var dataSource = null;

      var dataSource = {
        getRows: (params: IServerSideGetRowsParams) => {
          if (this.loadTable == 1) {
            this.getAllJobOrders(params);
          }
        },
      };
      // issue with ag-grid ticket number /CS-4758
      // setTimeout(() => {
      //   this.gridApi.sizeColumnsToFit();
      // }, 2000);

      this.gridApi.setDatasource(dataSource);
    }
  }

  /******************************************** get all jo order ******************************************/
  private getAllJobOrders(agGridGetRowsParams: IServerSideGetRowsParams) {
    // Show spinner when starting data loading
    this.spinner.show();

    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);
    var count: any = serverRequest.startRow;
    let startDate = "";
    let endDate = "";

    if (this.dateRange && this.dateRange[0] && this.dateRange[1]) {
      startDate = moment(this.dateRange[0]).format("YYYY-MM-DD");
      endDate = moment(this.dateRange[1]).format("YYYY-MM-DD");
    }

    const ticketId =
      this.data && this.data.hasOwnProperty("ticketId")
        ? this.data.ticketId
        : null;

    const status = this.filter.status;
    let customerId = null;

    if (this.filter.customerId) {
      customerId = this.filter.customerId;
    } else {
      customerId =
        this.data && this.data.hasOwnProperty("customerId")
          ? this.data.customerId
          : null;
    }

    const search =
      this.search !== "" && this.search !== null && this.search !== undefined
        ? this.search
        : null;

    const salesOrderId =
      this.data && this.data.hasOwnProperty("salesOrderId")
        ? this.data.salesOrderId
        : null;

    const origin = this.accessFrom !== "" ? this.accessFrom : "jo";

    const assetsId =
      this.data && this.data && this.data.hasOwnProperty("assetsId")
        ? this.data.assetsId
        : null;

    const siteId =
      this.data && this.data && this.data.hasOwnProperty("siteId")
        ? this.data.siteId
        : null;

    const projectId =
      this.data && this.data && this.data.hasOwnProperty("projectId")
        ? this.data.projectId
        : null;

    const apiParams = {
      fromDate: startDate,
      toDate: endDate,
      ticketId,
      status,
      customerId,
      search,
      salesOrderId,
      assetsId,
      origin,
      siteId,
      projectId,
    };


    this.push(
      this.jobOrdersService
        .getAllJobOrdersViaParams(apiParams, serverRequest)
        .subscribe(
          (data) => {
            console.log("get all jobs")
            this.spinner.hide()
            this.jobOrders = data.body.data;
            this.jobOrders.forEach((element: any) => {
              count++
              element.rowId = 'Tbl_RecJobOrderbNo' + count
              element.rowId2 = 'Tbl_RecJobOrderCustomer' + count
              element.rowId3 = 'Tbl_RecJobOrderAssets' + count
              element.buttonEdit = 'Btn_JobEdit' + count
              element.buttonDelete = 'Btn_JobDel' + count
            });

            this.totalRecord = data.body.total;

            this.setGridData(
              agGridGetRowsParams,
              this.jobOrders,
              this.totalRecord,
              data.body.status
            );

            this.spinner.hide();
          },
          (err) => {
            console.log(err)
            const title = this.errorUtil.processErrorTitle(err.error, this.translate)
            this.errorUtil.setErrorMessage(400, null, title, 'error', 3000);
            this.spinner.hide();
          }
        )
    );
  }

  /******************************************** set data to grid ******************************************/
  private setGridData(
    agGridGetRowsParams: IServerSideGetRowsParams,
    resultItems: any[],
    totalCount: number,
    status: string
  ) {
    if (status === "NO_DATA") {
      this.noJobOrders = true;
    } else {
      this.noJobOrders = false;
    }

    this.totalRecord = totalCount;

    if (this.jobOrders.length === 0) {
      this.gridParams.api.showNoRowsOverlay();
      agGridGetRowsParams.successCallback([], totalCount);
    } else {
      agGridGetRowsParams.successCallback(resultItems, totalCount);
    }

    this.noDataEvent.emit(this.noJobOrders);

    ///this.gridApi.sizeColumnsToFit(); ///CS-4758
  }

  /******************************************** get request params ******************************************/
  private getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
      this.parameters = { colId: this.sortBy, sort: this.sortOrder };

      agGridRequest.sortModel.push(this.parameters);
    }

    this.currentRecordNumber = agGridRequest.startRow;
    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,
    };
  }

  /******************************************** on cell clicked ******************************************/
  public viewData(e: any) {
    console.log(e.colDef.field, "====", e.data.jobOrder);

    this.util.resetTabView();
    localStorage.setItem("activeIndexJobOrder", "0");
    if (e.colDef.field === "jobOrder.jobOrderNumber") {
      this.router.navigate(["dashboard/jobOrders/view/" + e.data.jobOrder.id], {
        queryParams: { jobOrderId: e.data.jobOrder.id },
      });
    } else if (e.colDef.field === "jobOrder.uncompleatedJobOrder.jobOrderNumber") {
      this.router.navigate(['dashboard/jobOrders/view/' + e.data.jobOrder.uncompleatedJobOrder.id], { queryParams: { jobOrderId: e.data.jobOrder.uncompleatedJobOrder.id } });


    } else if (e.colDef.field === "jobOrder.customer.name" && e.data.jobOrder.customer) {
      this.router.navigate(
        ["dashboard/customers/view/" + e.data.jobOrder.customer.id],
        {
          queryParams: { id: e.data.jobOrder.customer.id },
        }
      );
    } else if (e.colDef.field === "jobOrder.salesOrder.orderName" && e.colDef.cellClass !== null && e.data.jobOrder.salesOrder) {
      this.router.navigate(
        ["dashboard/salesOrders/view/" + e.data.jobOrder.salesOrder.id],
        {
          queryParams: {
            // customerId: e.data.jobOrder.customer.id,
            salesOrderId: e.data.jobOrder.salesOrder.id,
          },
        }
      );
    } else if (e.colDef.field === "jobOrder.assets.name" && e.colDef.cellClass !== null && e.data.jobOrder.assets) {
      console.log(e.data);
      localStorage.setItem("activeIndexAssetView", "0");
      this.router.navigate(
        ["dashboard/assets/view/" + e.data.jobOrder.assets.id],
        {
          queryParams: { id: e.data.jobOrder.assets.id, serialNo: null },
        }
      );
    } else if (e.colDef.field === "assets.name" && e.colDef.cellClass !== null && e.data.jobOrder.assets) {
      console.log(e.data);
      this.router.navigate(
        ["dashboard/assets/view/" + e.data.jobOrder.assets.id],
        {
          queryParams: { id: e.data.jobOrder.assets.id, serialNo: null },
        }
      );
    } else if (e.colDef.field == "jobOrder.ticket.ticketNo" && e.colDef.cellClass !== null && e.data.jobOrder.ticket) {
      if (e.data.jobOrder.ticket) {
        this.router.navigate(
          ["dashboard/tickets/view/" + e.data.jobOrder.ticket.id],
          {
            queryParams: { id: e.data.jobOrder.ticket.id },
          }
        );
      }
    } else if (e.colDef.field == "jobOrder.site.siteName" && e.data.jobOrder.site) {
      if (e.data.jobOrder.site) {
        this.router.navigate(
          ["dashboard/sites/view/" + e.data.jobOrder.site.id],
          {
            queryParams: { id: e.data.jobOrder.site.id },
          }
        );
      }
    } else if (e.colDef.field == "jobOrder.project.name" && e.data.jobOrder.project) {
      if (e.data.jobOrder.project) {
        this.router.navigate(
          ["dashboard/projects/view/" + e.data.jobOrder.project.id],
          {
            queryParams: { id: e.data.jobOrder.project.id },
          }
        );
      }
    }
  }

  /******************************************** call table after filter ******************************************/
  private edit(e: any) {
    if (e.action === "edit") {
      this.jobOrderIdSelected = e.rowData.jobOrder.id;

      if (this.extraData && this.extraData.hasOwnProperty("salesOrderId")) {
        e.rowData = Object.assign(
          {},
          { salesOrderId: this.extraData.salesOrderId, from: "salesOrder" }
        );
      }

      this.util.setPersistantItem(PERSISTANT_ITEM.JOB_ORDER, e.rowData);

      this.router.navigate(
        ["/dashboard/jobOrders/update/" + this.jobOrderIdSelected],
        { queryParams: { id: this.jobOrderIdSelected } }
      );
    } else if (e.action === "delete") {
      let message = "";

      if (
        e.rowData &&
        e.rowData.jobOrder &&
        e.rowData.jobOrder.jobOrderName &&
        (e.rowData.jobOrder.jobOrderName !== "" ||
          e.rowData.jobOrder.jobOrderName !== null)
      ) {
        message = this.translate?.general.messages.confirmDelete + " " + `${e.rowData.jobOrder.jobOrderNumber} - ${e.rowData.jobOrder.jobOrderName}?`;
      } else {
        message = this.translate?.general.messages.confirmDelete + " " + `${e.rowData.jobOrder.jobOrderNumber}?`;
      }

      const item = {
        deleteHeader: this.translate.jobOrder.label.singular,
        deleteType: "Job Order",
        id: e.rowData.jobOrder.id,
        message: message,
      };

      if (!this.deleteSubscription) {
        this.deleteSubscription = this.deleteRecordService
          .getItems(item)
          .pipe()
          .subscribe((data) => {
            // refresh the table

            if (data.status === 200) {
              this.onGridReady(this.gridParams);
            }
          });
      } else {
        this.deleteRecordService.getItems(item);
      }
    }
  }

  /******************************************** call table after filter ******************************************/
  public filterResults($event: any) {
    // const result = $event.value.join(',');
    // this.filter.status = result
    // debugger
    // params = params.set("status", filter.status);
    // const result = filter.status.join(',');
    this.onGridReady(this.gridParams);
  }

  /******************************************** reset filter ******************************************/
  public resetSearch() {
    this.dateRange = undefined;
    this.myGroup.get("status").setValue(null);
    this.filter = {
      status: null,
      customerId: null,
      salesOrderId: null,
      fromDate: "",
      toDate: "",
    };

    this.selectedCustomer = null;

    this.onGridReady(this.gridParams);
  }

  /******************************************** redirect to ******************************************/
  private redirectToNewTab(data: any) {
    let url;
    console.log(data, "=====>>>>>", data.jobOrder)
    if (data.field === "jobOrderNumber") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(
          ["dashboard/jobOrders/view/" + data.jobOrder.id],
          {
            queryParams: { jobOrderId: data.jobOrder.id },
          }
        )
      );
    } else if (data.field === "uncompleatedJobOrder.jobOrderNumber") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(
          ["dashboard/jobOrders/view/" + data.jobOrder.uncompleatedJobOrder.id],
          {
            queryParams: { jobOrderId: data.jobOrder.uncompleatedJobOrder.id },
          }
        )
      );
    }

    else if (
      data.field === "jobOrder.customer.name" ||
      data.field === "customer.name" && data.jobOrder.customer
    ) {
      url = this.router.serializeUrl(
        this.router.createUrlTree(
          ["dashboard/customers/view/" + data.jobOrder.customer.id],
          {
            queryParams: { id: data.jobOrder.customer.id },
          }
        )
      );
    }

    else if (
      data.field === "jobOrder.salesOrder.salesOrderNumber" ||
      data.field === "salesOrder.orderName" && data.jobOrder.salesOrder
    ) {
      url = this.router.serializeUrl(
        this.router.createUrlTree(
          ["dashboard/salesOrders/view/" + data.jobOrder.salesOrder.id],
          {
            queryParams: {
              //customerId: data.jobOrder.customer.id,
              salesOrderId: data.jobOrder.salesOrder.id,
            },
          }
        )
      );
    }

    else if ((data.field === "jobOrder.assets.name" || data.field === "assets.name") && data.jobOrder.assets) {
      ////debugger
      url = this.router.serializeUrl(
        this.router.createUrlTree(
          ["dashboard/assets/view/" + data.jobOrder.assets.id],
          {
            queryParams: { id: data.jobOrder.assets.id, serialNo: null },
          }
        )
      );
    }

    else if (
      (data.field === "jobOrder.ticket.ticketNo" ||
        data.field === "ticket.ticketNo") && data.jobOrder.ticket
    ) {
      url = this.router.serializeUrl(
        this.router.createUrlTree(
          ["dashboard/tickets/view/" + data.jobOrder.ticket.id],
          {
            queryParams: { id: data.jobOrder.ticket.id },
          }
        )
      );
    }
    else if ((data.field === "jobOrder.site.siteName" || data.field == "site.siteName") && data.jobOrder.site) {
      url = this.router.serializeUrl(
        this.router.createUrlTree(
          ["dashboard/sites/view/" + data.jobOrder.site.id],
          {
            queryParams: { id: data.jobOrder.site.id },
          }
        )
      );
    }
    else if ((data.field === "jobOrder.project.name" || data.field === "project.name") && data.jobOrder.project) {
      url = this.router.serializeUrl(
        this.router.createUrlTree(
          ["dashboard/projects/view/" + data.jobOrder.project.id],
          {
            queryParams: { id: data.jobOrder.project.id },
          }
        )
      );
    }

    if (url) {
      url = url.replace("%23", "#");
      this.util.openItemToNewTab(url);
    }
  }

  /******************************************** right click cell context menu ******************************************/
  public getContextMenuItems = (params: any) => {
    params.node.data.field = params.column.colId;
    let url = "";
    let data = "";

    let results = [];
    console.log(params.column.colId, "===")
    if (params.column.colId == "jobOrderNumber") {
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });

      // results.push({
      //   name: "Export to Excel",
      //   action: () => this.onBtExport(data),
      // });

    } else if (params.column.colId == "uncompleatedJobOrder.jobOrderNumber") {
      const data = params.node.data;
      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });
    }

    if (
      (params.column.colId == "jobOrder.customer.name" ||
        params.column.colId == "customer.name") && params.node.data.jobOrder.customer
    ) {
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });
    }

    if (
      (params.column.colId == "jobOrder.salesOrder.salesOrderNumber" ||
        params.column.colId == "salesOrder.orderName") && params.node.data.jobOrder.salesOrder
    ) {
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });
    }

    if (
      (params.column.colId == "assets.name" ||
        params.column.field == "jobOrder.assets.name") && params.node.data.jobOrder.assets
    ) {
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });
    }

    if (
      (params.column.colId == "jobOrder.ticket.ticketNo" ||
        params.column.colId == "ticket.ticketNo") && params.node.data.jobOrder.ticket
    ) {
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });
    }


    if (
      (params.column.colId == "jobOrder.project.name" ||
        params.column.colId == "project.name") && params.node.data.jobOrder.project
    ) {
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });
    }


    if (
      (params.column.colId == "jobOrder.site.name" ||
        params.column.colId == "site.siteName") && params.node.data.jobOrder.site
    ) {
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });
    }

    return results;
  };

  /******************************************** export job order data ******************************************/
  public onBtExport(params: any) {
    if (params.field === "jobOrderNumber") {
      this.gridApi.exportDataAsExcel();
    }
  }

  /******************************************** load data and refresh table ******************************************/
  public loadData() {
    this.onGridReady(this.gridParams);
  }

  /******************************************** toggle the filter sidebar ******************************************/
  public filterToggle() {
    this.isFilterToggleOn = !this.isFilterToggleOn;
    this.isFilterClosed.emit(this.isFilterToggleOn)
    if (this.filterAPILoaded === false) {
      // APIS

      this.filterAPILoaded = true;
    }
  }

  /******************************************** find and filter customers ******************************************/
  public onFilter($event: any) {
    const filter = $event.filter;

    if (filter && filter !== "") {
      this.filteredCustomers = this.customers.filter((customer) =>
        customer.name.toLowerCase().includes(filter.toLowerCase())
      );
    } else {
      this.filteredCustomers = this.customers;
    }
  }

  /******************************************** on change date refresh table ******************************************/
  public onChangeDate() {
    if (this.dateRange && this.dateRange[0] && this.dateRange[1]) {
      this.onGridReady(this.gridParams);
    }
  }

  /******************************************** On Cell Change ******************************************/
  public onCellValueChanged(params: any) {
    const colId = params.column.getId();
    // console.log(colId);
    // console.log(params);

    if (colId === "status") {
      const id = params.data.jobOrder.id;
      const oldValue = params.oldValue;
      const newValue = params.data.jobOrder.status.name;

      if (oldValue !== newValue) {
        this.push(
          this.jobOrdersService
            .changeJOStatus(id, newValue)
            .subscribe((data: any) => {
              // console.log("Status Updated Successfully");
              console.log(data)
              if (data.status === 200 || data.status === 201) {
                console.log(data.body.alert)
                if (data.body.alert && data.body.alert.hasAlert == true) {
                  this.errorUtil.setErrorMessage(200, data.body.alert.message, null, "warn", 3000);
                } else {
                  this.errorUtil.setErrorMessage(200, this.translate?.general.messages.updatedSuccessfully, null, "success", 3000);

                }
              }

              params.api.redrawRows();
            })
        );
      }
    }
  }

  /******************************************** load data and refresh table ******************************************/
  public refresh() {

    this.onGridReady(this.gridParams);

  }

  /******************************************** On Customer Select Value ******************************************/
  public onCustomerSelect(event: any) {
    this.filter.customerId = event.id;
    this.selectedCustomer = event;

    this.refresh();

    this.closeTable("customer");
  }

  /******************************************** Show Table Dynamic Picklist ******************************************/
  public showTable(what: string) {
    if (what === "customer") {
      this.showingTable.customer = true;
    }
  }

  /******************************************** Close Table Dynamic Picklist ******************************************/
  public closeTable(what: string) {
    if (what === "customer") {
      this.showingTable.customer = false;
    }
  }

  /******************************************** Clear Value Dynamic Picklist ******************************************/
  public clearValue(what: string) {
    if (what === "customer") {
      this.filter.customerId = null;
      this.selectedCustomer = null;
    }

    this.refresh();
  }

  /******************************************** Get All Customer By Query ******************************************/
  public getCustomer(event: any) {
    const query = event.query.replace(
      /[&\/\\#,+()$~%!.„'":*‚^_¤?<>|@ª{«»§}©®™ ]/g,
      ""
    );

    if (query && query.length > 2) {
      this.push(
        this.customerServices
          .getAllCustomerByName(query)
          .subscribe((customers: any) => {
            this.customers = customers;
          })
      );
    }
  }
  /******************************************** Add ALl the Subscription ******************************************/
  push(obs: any) {
    super.push(obs);
  }

  /************************** Set Table Header: Language Translated Dynamically (Preeti) ************************************/
  private setOnlyColumn() {
    console.log(this.tableContent);

    // Clear the existing columnDefs array
    const columnDefs: ColDef[] = [];

    // Iterate through the table content and modify columns
    this.tableContent.tableColumnSetups.forEach((element: any) => {
      const modifiedColumn = this.modifyColumnTable(element);
      columnDefs.push(modifiedColumn);
    });
    this.actionData.headerName = this.translate?.general.buttons.action.singular;
    // Push the action column
    columnDefs.push(this.actionData);

    // Set the new column definitions
    this.gridOptions.api!.setColumnDefs(columnDefs);
    this.gridApi.sizeColumnsToFit();
  }
}
