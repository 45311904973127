import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function onlyWholeNumbersValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    // Allow empty values (use required validator separately if needed)
    if (value === null || value === '') {
      return null;
    }

    // Convert to string for regex check
    const strValue = String(value);

    // Check if value contains non-numeric characters, decimal points, or is negative
    if (!/^\d+$/.test(strValue)) {
      return { invalidNumber: true }; // Validation fails
    }

    return null; // Validation passes
  };
}