import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AiService } from 'src/app/_services/admin/ai.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { ProductService } from 'src/app/_services/product.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { AuthenticationService } from 'src/app/auth/authentication.service';

@Component({
  selector: 'app-smart-suggestions',
  templateUrl: './smart-suggestions.component.html',
  styleUrls: ['./smart-suggestions.component.scss']
})
export class SmartSuggestionsComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  translate: any;
  buttonLabel: string = "Add";
  data: any;
  suggstedProducts: any;
  createBulkProduct : any ={
    jobOrder: {id: null },
    products: []}
    products: any = [];

  singleProduct: any = {
      quantity: null,
      product: {
        id: null,
      },
     transferBinType: 'LOCATION_BIN',
    };
  constructor(
    public modalRef : BsModalRef,
    private languageTranslateService: LanguageTranslateService,
    private aiService: AiService,
    private errorUtil: ErrorUtil,
    private spinner: NgxSpinnerService,
    private auth: AuthenticationService,
    private util: UtilServiceService,
    private productService: ProductService
  ) {
    super();
  }

  ngOnInit(): void {
    console.log( this.data.jobOrderId,"data",this.data)
    if (this.data && this.data.data && Array.isArray(this.data.data.spare_part_ids)) {
      this.data.data.spare_part_ids.forEach((element: any) => {
        this.products.push({
          product: { id: element.spare_part_id, name: element.spare_part_name },
          quantity: element.quantity,
          transferBinType: 'LOCATION_BIN',
          saved:'No',
          jobOrder: { id: this.data.jobOrderId }
        });
      });
    } else {
      console.error('spare_part_ids is not defined or not an array');
    }
  
    console.log("products",this.products)
    this.getLanguageData()
  }

  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      
      }
    }))
    console.log("translate",this.translate)
    //this.mainLabel = this.translate?.site.label.plural
  }
  push(obs:any) {
    super.push(obs);
  }
  addSingleProduct(savedata : any) {
    this.spinner.show();  
      this.push(
        this.productService.createJobOrderProduct(savedata).subscribe( (res: any) => {
            this.spinner.hide();
            if (res.status === 200 || res.status === 201) {
              this.errorUtil.setErrorMessage(200,this.translate?.general.messages.savedSuccessfully, null, "success", 3000 );
              this.util.setTabActive("Products");
              savedata.saved = 'Yes';
              //this.modalRef.hide();
            }
          },
          (err) => {
            this.spinner.hide()
           const title = this.errorUtil.processErrorTitle(err.error,this.translate)
           this.errorUtil.setErrorMessage(400, null ,  title, 'error',3000);
        }
        )
      );
  }
  addBlukProduct() {
    this.spinner.show();  
    this.createBulkProduct.products = this.products.filter((product: any) => product.saved !== 'Yes');
    this.createBulkProduct.jobOrder.id = this.data.jobOrderId;
    console.log( this.createBulkProduct);
      this.push(
        this.productService.createJobOrderProductInBulkFromAi(this.createBulkProduct).subscribe( (res: any) => {
            this.spinner.hide();
            if (res.status === 200 || res.status === 201) {
              this.errorUtil.setErrorMessage(200,this.translate?.general.messages.savedSuccessfully, null, "success", 3000 );
              this.util.setTabActive("Products");
              //savedata.saved = 'Yes';
              this.modalRef.hide();
            }
          },
          (err) => {
            this.spinner.hide()
           const title = this.errorUtil.processErrorTitle(err.error,this.translate)
           this.errorUtil.setErrorMessage(400, null ,  title, 'error',3000);
        }
        )
      );
  }


  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
