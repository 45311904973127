import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ChecklistService } from "src/app/_services/checklist.service";
import { JobOrdersService } from "src/app/_services/job-orders.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
import { ProductService } from "src/app/_services/product.service";
import { TasksService } from "src/app/_services/tasks.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { AppComponent } from "src/app/app.component";
import { onlyWholeNumbersValidator } from "../../numberValiator";

@Component({
  selector: "app-common-product",
  templateUrl: "./common-product.component.html",
  styleUrls: ["./common-product.component.scss"],
})
export class CommonProductComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  buttonLabel: any;
  public products: any[] = [];
  // addProductForm!: UntypedFormGroup;
  public product: any = {
    product: {},
    quantity: 1,
  };

  selectedProduct: any = null;
  submitTouched!: boolean;
  dateConfig: any;
  data: any;
  translate: any;
  productsArray: any = { inventoryItemVMS: [] };
  addProductForm!: FormGroup;
  selectedIndex: any;
  displayProductTable: boolean;

  constructor(
    public modalRef: BsModalRef,
    private spinner: NgxSpinnerService,
    private productService: ProductService,
    private jobOrderServices: JobOrdersService,
    private taskService: TasksService,
    private errorUtil: ErrorUtil,
    private checklistService: ChecklistService,
    private languageTranslateService: LanguageTranslateService,
    private fb: FormBuilder
  ) {
    super();

    this.getLanguageData();
    this.addProductForm = this.fb.group({
      productRows: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    console.log(this.data);
    this.buttonLabel = this.translate?.general.buttons.save.singular;
    if(this.data.comingFrom == 'joTempProduct'){
      this.product.tmplJobOrder ={id: this.data.tmplJobOrderId}
     }
     if(this.data.comingFrom == 'taskTemplateProduct'){
       this.product.tmplTask ={id: this.data.tmplTaskId}
     }
    this.addProductForm = this.fb.group({
      productRows: this.fb.array([]),
    });

    this.addRow();
  }

  /******************************************** on get product via autocomplete select ******************************************/
  gotSelectedProduct(event: any, index: number) {
    const selectedProduct = event; // Assuming event contains the selected product data

    if (this.isProductExists(event, index)) {
      // Handle duplicate product
      this.errorUtil.setErrorMessage(200, "This item already exists in the list",  null, 'error',3000);
      this.displayProductTable = false;
      return;
    }

    this.productRows.at(index).patchValue({
      productName: selectedProduct.name,
      product: selectedProduct, // Assuming you have a form control named 'product' to store the product object
    });
    this.displayProductTable = false;
    console.log(this.productRows.at(index).value);
  }

  /******************************************** show product table ******************************************/
  showProductTable(index: any) {
    this.selectedIndex = index;
    console.log(this.selectedIndex);
    this.displayProductTable = !this.displayProductTable;
  }

  /******************************************** Add ALl the Subscription ******************************************/
  push(obs: any) {
    super.push(obs);
  }
  /******************************************** On Destroy ******************************************/
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /******************************************** submit data ******************************************/
  public onSubmit() {
    this.spinner.show();
    this.submitTouched = true;
    var productArray = this.productRows.value
    
    if (!this.addProductForm.valid) {
      console.log(this.addProductForm);
  
      // Mark all form controls as touched (including FormArray)
      this.addProductForm.markAllAsTouched();
  
      this.submitTouched = false;
      this.spinner.hide();
      return false;
  }

    productArray = productArray.map(item => ({
      ...item,
      tmplJobOrder: this.product.tmplJobOrder,
      tmplTask: this.product.tmplTask,
    }));

    console.log(productArray);
    if (this.data.comingFrom == "joTempProduct") {
      this.createJobOrderTemplateProduct(productArray);
    } 
    if (this.data.comingFrom == "taskTemplateProduct") {
     this.createTaskTemplateProduct(productArray);
    }
  }

  /******************************************** Save JO Template Products ******************************************/
 
  createJobOrderTemplateProduct(formData: any) {
    this.push(
      this.jobOrderServices.createJobOrderTemplateProduct(formData).subscribe(
        (res: any) => {
          this.spinner.hide();
          if (res.status === 200 || res.status === 201) {
            // debugger
            this.errorUtil.setErrorMessage(
              200,
              this.translate?.general.messages.savedSuccessfully,
              null,
              "success",
              3000
            );
            //this.jobOrderServices.refreshJOTemplateProductList$.emit(true)
            this.modalRef.hide();
          }
        },
        (err) => {
          this.spinner.hide();
          const title = this.errorUtil.processErrorTitle(
            err.error,
            this.translate
          );
          this.errorUtil.setErrorMessage(
            err.status,
            null,
            title,
            "error",
            3000
          );
          this.submitTouched = false;
        }
      )
    );
  }

  /******************************************** Save  Task Template Product ******************************************/
  createTaskTemplateProduct(formData: any) {
    this.push(
      this.taskService.createJobOrderTemplateProduct(formData).subscribe(
        (res: any) => {
          this.spinner.hide();
          if (res.status === 200 || res.status === 201) {
            this.errorUtil.setErrorMessage(
              200,
              this.translate?.general.messages.savedSuccessfully,
              null,
              "success",
              3000
            );
            this.modalRef.hide();
          }
        },
        (err) => {
          this.spinner.hide();
          const title = this.errorUtil.processErrorTitle(
            err.error,
            this.translate
          );
          this.errorUtil.setErrorMessage(
            err.status,
            null,
            title,
            "error",
            3000
          );
          this.submitTouched = false;
        }
      )
    );
  }

  /******************************************** Get Language Data ******************************************/
  
  getLanguageData() {
    this.push(
      this.languageTranslateService.wordSrc$.subscribe((data) => {
        if (Object.keys(data).length != 0) {
          this.translate = data;
        }
      })
    );
    console.log("translate", this.translate);
  }

  /******************************************** Get Form Array******************************************/
  
  get productRows(): FormArray {
    return this.addProductForm.get("productRows") as FormArray;
  }

  /******************************************** Add Row ******************************************/
  
  addRow(): void {
    const productGroup = this.fb.group({
      product: ["", Validators.required],
      quantity: [1, [Validators.required, Validators.min(1), onlyWholeNumbersValidator()]],
    });

    this.productRows.push(productGroup);
  }


  /******************************************** Delete Row ******************************************/  
  deleteRow(index: number): void {
    this.productRows.removeAt(index);
  }

  /******************************************** Get Product for Auto complete ******************************************/
  getProductIdsFirstWay(event: any, index: number): void {
    console.log(this.productRows.at(index).value.product);
    var k = event.key;
    console.log(k);
    if (k == "Backspace") {
      //this.clearProductValue(index);
    } else {
      if (this.productRows.at(index).value.product.length > 2) {
        this.productService
          .getProductPickListSearchable(
            this.productRows.at(index).value.product
          )
          .subscribe((data: any) => {
            this.products = data;
          });
        // Fetch products based on input event
      }
    }
  }

  /******************************************** Clear Product Value ******************************************/ 
  clearProductValue(index: number): void {
    this.productRows.at(index).patchValue({ product: "", productName: "" });
  }


  selectValueProduct(event: any, index: number): void {
    console.log(this.productRows);
    console.log(event);
    if (event) {
      debugger
    console.log(event);
    if (this.productRows.value.length > 1 && this.isProductExists(event, index)) {
        // Handle duplicate product
        this.errorUtil.setErrorMessage(200, "This item already exists in the list",  null, 'error',3000);
        this.productRows.at(index).patchValue({
          product: null // Store the whole object, not just event.id
      });
        return;
      }
      else{
        this.productRows.at(index).patchValue({
          product: event // Store the whole object, not just event.id
      });
      }
    
        
    }
}

    /******************************************** close product table ******************************************/
    public closeProductTable() {
      this.displayProductTable = false;
    }


    // private isProductExists(selectedProduct: any): boolean {
    //   return this.productRows.controls.some(control => {
       
    //     return control.get('product')?.value?.id === selectedProduct.id;
    //   });
    // }

    private isProductExists(selectedProduct: any, index: number): boolean {
      return this.productRows.controls.some((control, i) => {
        // Exclude the current index from the check
        return i !== index && control.get('product')?.value?.id === selectedProduct.id;
      });
    }
    


}
