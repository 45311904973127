import { Injectable, OnDestroy } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import * as _ from "lodash";
import { AuthenticationService } from "../auth/authentication.service";
import { AddConversationComponent } from "../dashboard/tickets/add-conversation/add-conversation.component";
import { ResetPasswordComponent } from "../dashboard/setup/users/reset-password/reset-password.component";
import { AddEditJobOrderComponent } from "../dashboard/job-orders/add-edit-job-order/add-edit-job-order.component";
import { CompletionFormComponent } from "../dashboard/job-orders/completion-form/completion-form.component";
import { VerificationRequisitionComponent } from "../dashboard/inventory/requisition/verification-requisition/verification-requisition.component";
import { AddEditTaskComponent } from "../dashboard/tasks/add-edit-task/add-edit-task.component";
import { ParentModalComponent } from "../shared/comman-component/parent-modal/parent-modal.component";
import { SmartAssignComponent } from "../dashboard/tasks/smart-assign/smart-assign.component";
import { ViewMapPinComponent } from "../shared/comman-component/view-map-pin/view-map-pin.component";
import { DownloadBarcodeComponent } from "../shared/comman-component/download-barcode/download-barcode.component";
import { AddEditSlaCriteriaComponent } from "../dashboard/setup/sla/sla-step/add-edit-sla-criteria/add-edit-sla-criteria.component";
import { AddEditHPFormComponent } from "../dashboard/job-orders/add-edit-hpform/add-edit-hpform.component";
import { ImportDataComponent } from "../dashboard/setup/import-export/import-data/import-data.component";
import { ExportDataComponent } from "../dashboard/setup/import-export/export-data/export-data.component";
import { ImportUploadFilesComponent } from "../dashboard/setup/import-export/import-upload-files/import-upload-files.component";
import { ImportDataListComponent } from "../dashboard/setup/import-export/import-data-list/import-data-list.component";
import { DownloadExportDataComponent } from "../dashboard/setup/import-export/download-export-data/download-export-data.component";
import { TicketFormatComponent } from "../dashboard/setup/tickets/ticket-format/ticket-format.component";
import { CheckAvailabilityComponent } from "../dashboard/tasks/check-availability/check-availability.component";
import { TaskCloneComponent } from "../dashboard/tasks/task-clone/task-clone.component";
import { SlaConditionComponent } from "../dashboard/setup/sla/sla-package/sla-condition/sla-condition.component";
import { UploadPhotoComponent } from "../dashboard/setup/users/upload-photo/upload-photo.component";
import { CloneAccessComponent } from "../dashboard/setup/access/clone-access/clone-access.component";
import { InfoGuideComponent } from "../shared/comman-component/info-guide/info-guide.component";
import { MagnumDashboardTableComponent } from "../dashboard/home/magnum/magnum-dashboard-table/magnum-dashboard-table.component";
import { AcceptDeclineTaskComponent } from "../dashboard/tasks/accept-decline-task/accept-decline-task.component";
import { AddMemberComponent } from "../dashboard/tasks/add-member/add-member.component";
import { OptimizeDistanceComponent } from '../dashboard/tasks/optimize-distance/optimize-distance.component';
import { AddEditNotesComponent } from '../dashboard/tasks/view-tasks/add-edit-notes/add-edit-notes.component';
import { RequestAssistanceComponent } from '../dashboard/tasks/request-assistance/request-assistance.component';
import { SalesOrderFormatComponent } from '../dashboard/setup/sales-order/sales-order-format/sales-order-format.component';
import { JobOrderFormatComponent } from '../dashboard/setup/job-orders/job-order-format/job-order-format.component';
import { ProjectFormatComponent } from '../dashboard/setup/projects/project-format/project-format.component';
import { SiteFormatComponent } from '../dashboard/setup/sites/site-format/site-format.component';
import { TaskJourneyComponent } from '../dashboard/tasks/task-journey/task-journey.component';
import { HazardControlComponent } from '../dashboard/tasks/hazard-control/hazard-control.component';
import { CustellaUpdateComponent } from '../dashboard/home/my-dashboard/custella-update/custella-update.component';
import { ExactAssignComponent } from '../dashboard/tasks/exact-assign/exact-assign.component';
import { ChecklistProductComponent } from '../shared/comman-component/checklist-product/checklist-product.component';
import { UpdateStatusComponent } from '../dashboard/shared/update-status/update-status.component';
import { AddJoTaskTemplateComponent } from '../dashboard/setup/job-orders/jo-templates/jo-task-template/add-jo-task-template/add-jo-task-template.component';
import { CustomerFormatComponent } from '../dashboard/setup/customer/customer-format/customer-format.component';
import { AssetFormatComponent } from '../dashboard/setup/assets/asset-format/asset-format.component';
import { BehaviorSubject } from 'rxjs';
import { CalendlyLinkComponent } from '../dashboard/job-orders/calendly-link/calendly-link.component';
import { SetUpEventsComponent } from '../dashboard/setup/integrations/set-up-events/set-up-events.component';
import { UpdateThemeComponent } from '../dashboard/setup/integrations/update-theme/update-theme.component';
import { DisconnectConfmationComponent } from '../public/google-calendar/disconnect-confmation/disconnect-confmation.component';
import { SalesforceIntegrationComponent } from '../dashboard/setup/integrations/salesforce-integration/salesforce-integration.component';
import { SurveyLinkComponent } from '../dashboard/job-orders/survey-link/survey-link.component';
import { ViewSurveyFormComponent } from '../dashboard/job-orders/view-survey-form/view-survey-form.component';
import { ResetContactPasswordComponent } from '../dashboard/shared/reset-contact-password/reset-contact-password.component';
import { AddEditTeamMembersComponent } from '../dashboard/teams/view-team-schedules/team-members/add-edit-team-members/add-edit-team-members.component';
import { AddImageEmailComponent } from '../dashboard/setup/emails/add-image-email/add-image-email.component';
import { PreviewEmailComponent } from '../dashboard/setup/notifications/preview-email/preview-email.component';
import { AddExistingNotificationComponent } from '../dashboard/setup/workflows/add-existing-notification/add-existing-notification.component';
import { CancelContractComponent } from "../dashboard/contracts/cancel-contract/cancel-contract.component";
import { SubscriptionUtil } from "../_utilities/subscription";
import { LanguageTranslateService } from "./language-translate.service";
import { HowToConditionComponent } from "../dashboard/setup/workflows/how-to-condition/how-to-condition.component";
import { HowToEmailComponent } from "../dashboard/setup/emails/how-to-email/how-to-email.component";
import { RunningNoFormatComponent } from '../dashboard/setup/custom-form/running-no-format/running-no-format.component';
import { FieldsStatesComponent } from '../dashboard/setup/custom-form/fields-states/fields-states.component';
import { PageLayoutComponent } from '../dashboard/setup/custom-form/page-layout/page-layout.component';
import { FormPreviewComponent } from '../dashboard/setup/custom-form/form-preview/form-preview.component';

@Injectable()
export class ModalServiceService extends SubscriptionUtil implements OnDestroy {
  selectedComponent: any;
  selectedConfig: any;
  modalState: any;
  modalRef!: BsModalRef;
  addonFeature: any;
  configMd: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "modal-md",
  };
  configMdCenter: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "modal-md modal-dialog-centered",
  };
  configSM: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "modal-sm",
  };
  configCustomLgNonScroll: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "custom-modal-lg",
  }
  configSMCenter: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "modal-sm modal-dialog-centered",
  };
  configCustomLg: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "custom-modal-lg modal-dialog-scrollable",
  };
  configCustomLgCenter: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "custom-modal-lg modal-dialog-scrollable modal-dialog-centered",
  };

  configCustomXl: any = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: true,
    class: "custom-modal-xl",
  };

  private allModalRefs: BsModalRef[] = [];
  translate: any;

  constructor(
    public modalService: BsModalService,
    private auth: AuthenticationService,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super()
    this.init();
  }

  private init() {

    this.push(this.languageTranslateService.wordSrc$.subscribe(data => {
      if(Object.keys(data).length != 0){
      this.translate = data
      }
      }))

    if (this.modalRef) {
      this.modalRef.onHide?.subscribe((eve) => {
        this.allModalRefs.forEach((mf) => {
          mf.hide();
        });
      });
    }
  }


  ngOnDestroy(): void {
    // Complete any observables that need manual cleanup
    console.log("destroyed");
    super.ngOnDestroy();
  }


  openModal(modalView: any, data2: any, config?: any) {
    if (config) {
      this.configMd = Object.assign(this.configMd, config);
    }

    this.addonFeature = this.auth.addonFeature();
    var data = _.cloneDeep(data2);
    //console.log(data)
    this.selectedConfig = this.configMd;
    if (modalView === "resetPassword") {
      this.modalState = {
        title: this.translate.resetPassword.label.singular ,
        data: data.login,
        type: "self",
      };
      this.selectedConfig = this.configSM;
      // this.selectedComponent = DeletePaymentComponent;
      this.selectedComponent = ResetPasswordComponent;
    }else if (modalView === "resetContactPassword") {
      this.modalState = {
        title: this.translate.resetPassword.label.singular ,
        data: data.login,
        data1: data,
        type: "self",
      };
      this.selectedConfig = this.configSM;
      // this.selectedComponent = DeletePaymentComponent;
      this.selectedComponent = ResetContactPasswordComponent;
    } else if (modalView == "addComment") {
      this.modalState = {
        title: this.translate.ticket.sections.sec7.singular ,
        mode: "add",
        data: data,
      };
      this.selectedComponent = AddConversationComponent;
    } else if (modalView === "completionForm") {
      this.modalState = {
        title: this.translate.jobOrder.fields.completionForm.singular ,
        data: data,
      };

      this.selectedComponent = CompletionFormComponent;
    } else if (modalView === "addEditTask") {
      this.modalState = {
        title: this.translate.task.sections.sec28.singular ,
        data: data,
        mode: data.mode,
      };

      this.selectedComponent = AddEditTaskComponent;
    } else if (modalView === "addEditTeamMembers") {
      this.modalState = {
        title: this.translate.setup.users.teams.sections.sec7.singular ,
        data: data,
        mode: "add",
      };
      this.selectedConfig = this.configSMCenter;
      this.selectedComponent = AddEditTeamMembersComponent;
    } else if (modalView == "welcomeUpdate") {
      this.modalState = {
        title: "",
        mode: "add",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = CustellaUpdateComponent;
    } else if (modalView === "editTeamMembers") {
      this.modalState = {
        title: this.translate.setup.users.teams.sections.sec9.singular ,
        data: data,
        mode: "edit",
      };
      this.selectedConfig = this.configSMCenter;
      this.selectedComponent = AddEditTeamMembersComponent;
    } else if (modalView === "smart-assign") {
      this.modalState = {
        title: "smart-assign",
        data: data,
        mode: "add",
      };

      this.selectedComponent = SmartAssignComponent;
    } else if (modalView === "confirmContactResetPassword") {
      this.modalState = {
        title: this.translate.resetPassword.label.singular ,
        data: data.login,
        type: "others",
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = ResetContactPasswordComponent;
    } else if (modalView === "confirmResetPassword") {
      this.modalState = {
        title: this.translate.resetPassword.label.singular ,
        data: data.login,
        data1:data,
        type: "others",
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = ResetPasswordComponent;
    } else if (modalView === "resendActivationEmail") {
      this.modalState = {
        title: this.translate.setup.users.users.sections.sec22.singular,
        data: data.id,
        type: "resendCustomerUserEmail",
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = ResetPasswordComponent;
    } else if (modalView === "resendActivationEmailUser") {
      this.modalState = {
        title: this.translate.setup.users.users.sections.sec22.singular,
        data: data.id,
        data1: data,
        type: "resendEmail",
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = ResetPasswordComponent;
    } else if (modalView == "verificationRequisition") {
      this.modalState = {
        title: this.translate.requisition.sections.sec9.singular,
        mode: "edit",
        data: data,
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = VerificationRequisitionComponent;
    } else if (modalView == "viewMapPin") {
      this.modalState = {
        title: this.translate.general.locationDetails.sections.sec7.singular,
        data: data,
      };
      this.selectedConfig = this.configMdCenter;
      this.selectedComponent = ViewMapPinComponent;
    } else if (modalView == "barcode") {
      this.modalState = {
        data: data,
      };
      this.selectedComponent = DownloadBarcodeComponent;
    } else if (modalView == "addEditCriteria") {
      this.modalState = {
        title: this.translate.setup.sla.steps.sections.sec14.singular,
        mode: "create",
        data: data,
      };
      // this.selectedConfig = this.configCustomLg;
      this.selectedComponent = AddEditSlaCriteriaComponent;
    } else if (modalView == "addProduct") {
      this.modalState = {
        title: this.translate.checklist.sections.sec13.singular,
        mode: "create",
        data: data,
      };
      this.selectedConfig = this.configMdCenter;
      this.selectedComponent = ChecklistProductComponent;
    } else if (modalView == "updateStatus") {
      this.modalState = {
        title: this.translate.general.status.messages.updateStatus,
        mode: "create",
        data: data,
      };
      this.selectedConfig = this.configCustomLgNonScroll;
      this.selectedComponent = UpdateStatusComponent;
    } else if (modalView == "createCondition") {
      this.modalState = {
        title: this.translate.setup.sla.packages.sections.sec9.singular,
        mode: "create",
        data: data,
      };
      // this.selectedConfig = this.configCustomLg;
      this.selectedComponent = SlaConditionComponent;
    } else if (modalView == "optimizeDistance") {
      this.modalState = {
        title: this.translate.task.sections.sec29.singular,
        mode: "create",
        data: data,
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = OptimizeDistanceComponent;
    } else if (modalView == "editNotes") {
      this.modalState = {
        title: this.translate.general.buttons.editNotes.singular,
        mode: "edit",
        data: data,
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = AddEditNotesComponent;
    }  else if (modalView === "addEditHpForm") {
      this.modalState = {
        title: this.translate.jobOrder.sections.sec44.singular,
        mode: "edit",
        data: data,
      };
      this.selectedComponent = AddEditHPFormComponent;
    } else if (modalView === "cancelContract") {
      this.modalState = {
        title:  this.translate.contracts.sections.sec10.singular,
        data: data,
      };
      this.selectedComponent = CancelContractComponent;
    } else if (modalView == "import") {
      this.modalState = {
        title: this.translate.setup.importExport.sections.sec2.singular,
        mode: "edit",
        data: data,
      };
      this.selectedConfig = this.configCustomXl;
      this.selectedComponent = ImportDataComponent;
    } else if (modalView == "importUpload") {
      this.modalState = {
        title: this.translate.setup.importExport.sections.sec2.singular,
        mode: "edit",
        data: data,
      };
      this.selectedConfig = this.configCustomXl;
      this.selectedComponent = ImportUploadFilesComponent;
    } else if (modalView == "listImports") {
      this.modalState = {
        title: this.translate.setup.importExport.sections.sec2.singular,
        mode: "edit",
        data: data,
      };
      this.selectedConfig = this.configCustomXl;
      this.selectedComponent = ImportDataListComponent;
    } else if (modalView == "export") {
      this.modalState = {
        title: this.translate.setup.importExport.sections.sec6.singular,
        mode: "edit",
        data: data,
      };
      this.selectedComponent = ExportDataComponent;
    } else if (modalView == "listExport") {
      this.modalState = {
        title: this.translate.setup.importExport.sections.sec6.singular,
        mode: "edit",
        data: data,
      };
      this.selectedComponent = DownloadExportDataComponent;

    } else if (modalView == "viewCustomerFormat") {
      this.modalState = {
        title: this.translate?.customer.label.singular +" "+ this.translate?.setup.modules.configuration.fields.format.singular,
        mode: "add",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = CustomerFormatComponent;
    } else if (modalView == "viewAssetFormat") {
      this.modalState = {
        title: this.translate?.asset.label.singular +" "+ this.translate?.setup.modules.configuration.fields.format.singular,
        mode: "add",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = AssetFormatComponent;
    } else if (modalView == "viewTicketFormat") {
      this.modalState = {
        title: this.translate.setup.modules.configuration.sections.sec5.singular,
        mode: "add",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = TicketFormatComponent;
    } else if (modalView == "viewSalesOrderFormat") {
      this.modalState = {
        title: this.translate?.salesOrder.label.singular +" "+ this.translate?.setup.modules.configuration.fields.format.singular,
        mode: "add",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = SalesOrderFormatComponent;
    } else if (modalView == "viewJobOrderFormat") {
      this.modalState = {
        title: this.translate?.jobOrder.label.singular +" "+ this.translate?.setup.modules.configuration.fields.format.singular,
        mode: "add",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = JobOrderFormatComponent;
    } else if (modalView == "checkAvailability") {
      this.modalState = {
        title: this.translate.contracts.sections.sec10.singular,
        mode: "add",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = CheckAvailabilityComponent;
    } else if (modalView == "taskClone") {
      this.modalState = {
        title: this.translate.task.sections.sec30.singular,
        mode: "add",
        data: data,
      };
      this.selectedComponent = TaskCloneComponent;
   } else if (modalView == "uploadPhoto") {
      this.modalState = {
        title: this.translate.general.buttons.uploadPhoto.singular,
        data: data,
        type: "user",
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = UploadPhotoComponent;
    } else if (modalView == "uploadTeamPhoto") {
      this.modalState = {
        title: this.translate.general.buttons.uploadPhoto.singular,
        data: data,
        type: "team",
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = UploadPhotoComponent;
    } else if (modalView == "infoGuide") {
      this.modalState = {
        title: "Guide",
        data,
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = InfoGuideComponent;
       } else if (modalView == "hierarchyHelp") {
      this.modalState = {
        title:  this.translate.setup.hierarchy.sections.sec4.singular,
        data,
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = InfoGuideComponent;
    }else if (modalView == "cloneAssign") {
      this.modalState = {
        title:  this.translate.setup.users.access.sections.sec16.singular,
        data,
      };
      this.selectedComponent = CloneAccessComponent;
    } else if (modalView == "magnumDashboardTable") {
      this.modalState = {
        title: data.title,
        data,
      };
      this.selectedComponent = MagnumDashboardTableComponent;
    } else if (modalView === "acceptTask") {
      console.log(data);
      let mode = "accept";
      if (data.groupId) {
        mode = "acceptGroup";
      }
      this.modalState = {
        mode: mode,
        data: data,
        userId: this.auth.getUserId(),
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = AcceptDeclineTaskComponent;
    } else if (modalView === "declineTask") {
      let mode = "decline";
      if (data.groupId) {
        mode = "declineGroup";
      }
      this.modalState = {
        mode: mode,
        data: data,
        userId: this.auth.getUserId(),
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = AcceptDeclineTaskComponent;
    } else if (modalView === "addMember") {
      this.modalState = {
        mode: "add",
        data: data,
        title: this.translate.task.sections.sec31.singular,
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = AddMemberComponent;
    } else if (modalView === "requestAssistance") {
      this.modalState = {
        title: this.translate.task.fields.requestAssistance.singular,
        mode: "add",
        data: data,
      };
      this.selectedComponent = RequestAssistanceComponent;
      this.selectedConfig = this.configSM;
    } else if (modalView == "viewProjectFormat") {
      this.modalState = {
        title: this.translate?.project.label.singular +" "+ this.translate?.setup.modules.configuration.fields.format.singular,
        mode: "add",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = ProjectFormatComponent;
    } else if (modalView == "viewSiteFormat") {
      this.modalState = {
        title: this.translate?.site.label.singular +" "+ this.translate?.setup.modules.configuration.fields.format.singular,
        mode: "add",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = SiteFormatComponent;
    } else if (modalView == "taskJourney") {
      this.modalState = {
        title: this.translate.task.sections.sec27.singular,
        mode: "add",
        data: data,
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = TaskJourneyComponent;
    } else if (modalView == "hazardControl") {
      this.modalState = {
        title: this.translate.task.sections.sec32.singular,
        mode: "add",
        data: data,
      };
      // this.selectedConfig = this.configCustomLg;
      this.selectedComponent = HazardControlComponent;
    } else if (modalView == "exact-assign") {
      this.modalState = {
        title: this.translate.general.buttons.assign.singular,
        mode: "add",
        data: data,
      };

      this.selectedComponent = ExactAssignComponent;
    }else if (modalView == "taskTrigger") {
        this.modalState = {
          title: this.translate.setup.modules.templates.sections.sec17.singular,
          mode: "add",
          data: data,
          fromPage: "taskTrigger",
        };
        // this.selectedConfig = this.configCustomLg;
        this.selectedComponent = AddJoTaskTemplateComponent;
    }else if (modalView == "sendCalendlyLink") {
      this.modalState = {
        title: this.translate?.jobOrder.fields.sendCalendlyLink.singular,
        mode: "add",
        data: data
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = CalendlyLinkComponent;
    }else if (modalView == "setUpEvents") {
      this.modalState = {
        title: this.translate.general.buttons.setUpEvents.singular,
        mode: "add",
        data: data
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = SetUpEventsComponent;
    }else if (modalView == "updateTheme") {
      this.modalState = {
        title: this.translate.general.buttons.updateTheme.singular,
        mode: "add",
        data: data
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = UpdateThemeComponent;
    }else if (modalView == "salesforceIntegrate") {
      this.modalState = {
        title: this.translate.setup.integrations.fields.salesforceIntegration.singular,
        mode: "add",
        data: data
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = SalesforceIntegrationComponent;

    } else if (modalView == "DisconnectGoogleCalenderConfmationComponent") {
      this.modalState = {
        title: this.translate.setup.integrations.sections.sec9.singular,
        data: data,
        page:'Google'
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = DisconnectConfmationComponent;
    } else if (modalView == "DisconnectMicrosoftCalenderConfmationComponent") {
      this.modalState = {
        title: this.translate.setup.integrations.sections.sec10.singular,
        data: data,
        page:'Microsoft'
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = DisconnectConfmationComponent;
    } else if (modalView == "DisconnectCalendlyConfmationComponent") {
      this.modalState = {
        title: this.translate.setup.integrations.sections.sec11.singular,
        data: data,
        page:'Calendly'
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = DisconnectConfmationComponent;
  
      //sendSurvey Link//
    } else if (modalView == "sendSurveyLink") {
      this.modalState = {
        title: this.translate?.jobOrder.fields.sendSurveyLink.singular,
        mode: "add",
        data: data,
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = SurveyLinkComponent;
    } else if (modalView === "viewSurveyForm") {
      this.modalState = {
        title: this.translate?.jobOrder.sections.sec47.singular,
        data: data,
        mode: data.mode,
      };

      this.selectedComponent = ViewSurveyFormComponent;
    }  else if (modalView == "uploadCalendlyLogo") {
      this.modalState = {
        title: this.translate?.setup.users.users.sections.sec23.singular,
        data: data,
        type: "CalendlyLogo",
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = UploadPhotoComponent;
    }  else if (modalView == "uploadHeaderFooter") {
      this.modalState = {
        title: this.translate.general.buttons.uploadImage.singular,
        data: data,
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = AddImageEmailComponent;
    }  else if (modalView == "previewEmail") {
      this.modalState = {
        title: this.translate?.setup.templates.email.sections.sec2.singular,
        data: data,
      };
      this.selectedConfig = this.configMdCenter;
      this.selectedComponent = PreviewEmailComponent;
    } else if (modalView === "addNotification") {
      this.modalState = {
        title:  this.translate?.setup.configuration.notifications.label.singular,
        data: data,
        mode: "add",
      };
      this.selectedConfig = this.configMdCenter;
      this.selectedComponent = AddExistingNotificationComponent;
    } else if (modalView === "howToCondition") {
      this.modalState = {
        // title:  this.translate?.setup.configuration.notifications.label.singular,
        data: data,
        mode: "add",
      };
      this.selectedConfig = this.configMdCenter;
      this.selectedComponent = HowToConditionComponent;
    } else if (modalView === "howToEmail") {
      this.modalState = {
        // title:  this.translate?.setup.configuration.notifications.label.singular,
        data: data,
        mode: "add",
      };
      this.selectedConfig = this.configMdCenter;
      this.selectedComponent = HowToEmailComponent;
    }else if (modalView == "runningNo") {
      this.modalState = {
        title: "Running No Prefix",
        mode: "add",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = RunningNoFormatComponent;
    }else if (modalView == "fieldState") {
      this.modalState = {
        title: "Field State",
        data: data,
      };
      this.selectedConfig = this.configSMCenter;
      this.selectedComponent = FieldsStatesComponent;
    }else if (modalView == "pageLayout") {
      this.modalState = {
        title: "Page Layout",
        data: data,
      };
      this.selectedConfig = this.configSMCenter;
      this.selectedComponent = PageLayoutComponent;
    }else if (modalView == "formPreview") {
      this.modalState = {
        title: "Form Preview",
        data: data,
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = FormPreviewComponent;
    }else {
      return;
    }

    this.selectedConfig.initialState = this.modalState;
    this.modalRef = this.modalService.show(
      this.selectedComponent,
      this.selectedConfig
    );

    this.allModalRefs.push(this.modalRef);

    return this.modalRef;
  }
}
