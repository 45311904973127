import { of as observableOf, Observable, throwError, Subject } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { AuthenticationService } from "../../auth/authentication.service";
import { PermissionService } from "../../auth/permission.service";

@Injectable()
export class UsersService {
  getConnectWithAI(arg0: string, arg1: string, arg2: string) {
    throw new Error('Method not implemented.');
  }
  refreshStatus$: EventEmitter<{}>;
  refreshList$ = new EventEmitter();
  refreshGoogleCalender$ = new EventEmitter();
  refreshMicrosoftCalender$ = new EventEmitter();
  refreshCalendlyCalender$ = new EventEmitter();
  private ENDPOINT_USERS = "/users";
  private ENDPOINT_LIST_USERS = "/v4/get-all-users";
  private ENDPOINT_GET_USER_LIST_V5 = '/v4-1/get-all-users'
  private ENDPOINT_CREATE_USER = "/create-user";
  private ENDPOINT_UPDATE_USER = "/update-user";
  private ENDPOINT_DELETE_USER = "/users/";
  private ENDPOINT_UPDATE_USER_ACCOUNT_STATUS = "/status/";
  private ENDPOINT_ASSIGN_ROLES = "/users/assign-role";
  private ENDPOINT_LIST_USERS_ROLES = "/users/get-all-by-role?roleAssigned=";
  private ENDPOINT_LIST_DOWNLINE_USERS = "/users/downline?userId=";
  private ENDPOINT_GET_USER_BY_ID = "/get-user/";
  private ENDPOINT_USER_SEARCH = "/users/search";
  private ENDPOINT_USER_NOTES = "/user-notes";
  private ENDPOINT_GET_USER_PROFILE = "/get-user-profile/";
  private ENDPOINT_DELETE_USER_PROFILE_PICTURE = "/user-profile-remove";
  private ENDPOINT_UPDATE_USER_PROFILE = "/update-user-profile/";
  private ENDPOINT_UPDATE_USER_ONLINE_STATUS =
    "/users/update-online-status?userId=";
  private ENDPOINT_USER_LOCATION = "/users/get-user-location?userId=";
  private ENDPOINT_ADJUST_PROFILE = "/users/adjust-profile/";
  private ENDPOINT_UPLOAD_USERS = "/csv/create-users";
  private ENDPOINT_USER_CALENDAR_COLOR = "/user-calender-colors";
  private ENDPOINT_UPDATE_USER_LOCATION = "/users/update-user-location";
  private ENDPOINT_MANAGED_USER_WITH_TASKS = "/users/managed-downline";
  private ENDPOINT_GOOGLE_CALENDER_AUTH = "/calender/authenticate";
  private ENDPOINT_GOOGLE_CALENDER_DISCONNECT = "/calender/disconnect";
  private ENDPOINT_MICROSOFT_CALENDER_AUTH = "/calender/microsoft/authenticate";
  private ENDPOINT_MICROSOFT_CALENDER_DISCONNECT = "/calender/microsoft/disconnect";

  private ENDPOINT_DELETE_CUSTOMER_USER = "/customer-users/";
  private ENPOINT_USERS_ACTIVE = "/v4/users/active";


  private ENDPOINT_LIST_USERS_EXCLUDE_DRIVERS =
    "/v4/get-all-users?excludeDriverUsers=true";
  private ENDPOINT_USER_LOCATION_DATA_ACTIVE_TASK =
    "/user-location-data/active-task";
  private ENDPOINT_GET_DOWNLINE_USERS_FOR_LEAVES = "/v4-1/get-all-users/?page=&perPage=1000&startFrom=0&sortOrder=desc&search=&status=ACTIVE&superVisorId="
// 
private ENDPOINT_CALENDLY_CALENDER_AUTH = "/calender/calendly/authenticate";
private ENDPOINT_CALENDLY_CALENDER_DISCONNECT = "/calender/calendly/disconnect";

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private httpOnly: HttpClient,
    private perm: PermissionService
  ) {
    this.refreshList$ = new EventEmitter();
    this.refreshStatus$ = new EventEmitter();
    this.refreshGoogleCalender$ = new EventEmitter();
    this.refreshMicrosoftCalender$ = new EventEmitter();
    this.refreshCalendlyCalender$ = new EventEmitter();
  }

  private userItemsSource = new Subject<any>();
  userItemsHandler = this.userItemsSource.asObservable();

  public updateUserLocation(id: string, lat: any, lng: any,code:any) {
    return this.http
      .put(
        `${environment.base_url + this.ENDPOINT_UPDATE_USER_LOCATION
        }?userId=${id}&lattitude=${lat}&longitude=${lng}`,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          if(code==1){
          this.refreshList$.emit(resp);
          }
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return observableOf(error);
        })
      );
  }

  public getUserProfile(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_GET_USER_PROFILE + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return observableOf(error);
        })
      );
  }
  /*************************************************** Get User Online/ Offline *******************************************************/
  public updateUserOnlineStatus(id: string, status: boolean) {
    return this.http
      .put(
        environment.base_url +
        this.ENDPOINT_UPDATE_USER_ONLINE_STATUS +
        id +
        "&isOnline=" +
        status,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          this.refreshStatus$.emit("Online Status Updated");
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return observableOf(error);
        })
      );
  }

  /*************************************************** Get User Detail By Id *******************************************************/
  public getUserById(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_GET_USER_BY_ID + id)
      .pipe(
        map((resp: any) => {
          // return <Admin>resp;
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return observableOf(error);
        })
      );
  }

    /*************************************************** Get User Detail By Id *******************************************************/
    public getDownLineUsersById(id: any) {
      return this.http
        .get(environment.base_url + this.ENDPOINT_GET_USER_BY_ID + id)
        .pipe(
          map((resp: any) => {
            // return <Admin>resp;
            return resp;
          }),
          catchError((error) => {
            ////console.log(error);
            return observableOf(error);
          })
        );
    }

  /*************************************************** Get User Cusrrent Location *******************************************************/
  public getUserLocationActive() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_USER_LOCATION_DATA_ACTIVE_TASK)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /*************************************************** Get All User Group *******************************************************/
  public getAlluserGroup(type: any = null){
    var type1=''
    if(type){ type1='&type='+type}
    return this.http.get(environment.base_url + "/user-groups?active=true"+type1).pipe(
      map((resp: any) => {
        //console.log(resp);
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
  }

    /*************************************************** Get All User Group *******************************************************/
    public getAllDownlineUsersForLeavesGroup(id: any) {
      return this.http.get(environment.base_url + this.ENDPOINT_GET_DOWNLINE_USERS_FOR_LEAVES+id).pipe(
        map((resp: any) => {
          //console.log(resp);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
    }

    /*************************************************** Get All Calendly User Group *******************************************************/
    public getAllDownlineCalendlyUsersForLeavesGroup(id: any) {
      return this.http.get(environment.base_url + this.ENDPOINT_GET_DOWNLINE_USERS_FOR_LEAVES+id+"&calendlyOnly=true").pipe(
        map((resp: any) => {
          //console.log(resp);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
    }
  /***************************************************Change Customer Contact status*******************************************************/
  public changeCustomerUserAccountStatus(status: string, id: any) {
    return this.http
      .put(
        environment.base_url +
        this.ENDPOINT_DELETE_CUSTOMER_USER +
        id +
        this.ENDPOINT_UPDATE_USER_ACCOUNT_STATUS +
        status,
        {},
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  public getAllDownlineUsersForJO(fullName: any, forBin: any = true) {
    //var binUser = this.perm.capable('bin user', 'create');
    //return this.http.get( environment.base_url+'/v2/users/active?forBin='+binUser).map( (resp:any) => {
    let params = new HttpParams();
    //if (fullName) { params = params.set("fullName", fullName); }
    params = params.set("forBin", forBin);
 

    return this.http.get(environment.base_url + this.ENPOINT_USERS_ACTIVE, {
      params: params
    }).pipe(
      map((resp: any) => {
        //console.log(resp);
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
  }

  public getAllDownlineUsersForInventryLocationAndZone(fullName: any, forBin: any = true) {
    //var binUser = this.perm.capable('bin user', 'create');
    //return this.http.get( environment.base_url+'/v2/users/active?forBin='+binUser).map( (resp:any) => {
    let params = new HttpParams();
    //if (fullName) { params = params.set("fullName", fullName); }
 

    return this.http.get(environment.base_url + this.ENPOINT_USERS_ACTIVE, {
      params: params
    }).pipe(
      map((resp: any) => {
        //console.log(resp);
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
  }

  /*************************************************** Get User by search query *******************************************************/
  public getUsersBySearch(searchParam: string) {
    let params = new HttpParams().set("search", searchParam);

    return this.http
      .get(environment.base_url + this.ENDPOINT_USER_SEARCH, { params: params })
      .pipe(
        map((resp: any) => {
          //console.log(resp);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }
  /*************************************************** Get User by Bin *******************************************************/
  public getAllDownlineUsersForBin(id: any) {
    return this.http.get(environment.base_url + '/v4/users/active?forBin=true').pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return observableOf(error);
    }),);
  }

  public getAllDownlineUsersForSO(id: any) {
    return this.http.get(environment.base_url + this.ENDPOINT_LIST_DOWNLINE_USERS + id + '&includeSelf=true').pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return observableOf(error);
    }),);
  }

  /*************************************************** Get User Excude Driver *******************************************************/
  public getAllUsersRegardlessOfStatus() {

    return this.http.get(environment.base_url + this.ENDPOINT_LIST_USERS + `?includeInactiveUsers=true&excludeDriverUsers=true`).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return observableOf(error);
    }),);
  }


  /*************************************************** Get All Users V5(4-1) *******************************************************/
  public getAllUsers4_1(searchValue: any, apiParams: any, parameters: any) {

    let params = new HttpParams();
  
    if(searchValue !== '' && searchValue != null){
      params = params.set('search', searchValue);
    }
    if(apiParams.status !== '' && apiParams.status != null){
      params = params.set('status', apiParams.status);
    }
    console.log(parameters)
    if(parameters)
  
    params = params.set('startFrom', parameters.startRow).set('perPage', parameters.pageSize).set('columnName', "superVisor").set('sortOrder', parameters.sortModel[0].sort);
    
    return this.http
      .get(environment.base_url + this.ENDPOINT_GET_USER_LIST_V5, {
        observe: 'response',
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /*************************************************** Get All User  *******************************************************/

  public getAllUsers() {
    return this.http.get(environment.base_url + this.ENDPOINT_LIST_USERS).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return observableOf(error);
    }),);
  }
  /*************************************************** Create User  *******************************************************/

  public createUser(body: any) {
    return this.http.post(environment.base_url + this.ENDPOINT_CREATE_USER, body, { observe: 'response' }).pipe(map((resp: any) => {
      console.log(resp)

      return resp;
    }
    ), catchError(error => {
      return observableOf(error);
    }),);
  }
  /*************************************************** Update User  *******************************************************/
  public editUser(body: any) {
    return this.http.put(environment.base_url + this.ENDPOINT_UPDATE_USER + '/' + body.id, body, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return observableOf(error);
    }),);
  }
  /*************************************************** Update User Profile  *******************************************************/
  public editUserProfile(body: any, id: string) {
    return this.http.put(environment.base_url + this.ENDPOINT_UPDATE_USER_PROFILE + id, body, { observe: 'response' }).pipe(map((resp: any) => {
      this.refreshList$.emit(resp);
      return resp;
    }
    ), catchError(error => {
      return observableOf(error);
    }),);
  }
  /*************************************************** Get All Manager For Driver *******************************************************/

  public getAllManagerForDriver() {
    return this.http.get(environment.base_url + this.ENDPOINT_LIST_USERS_EXCLUDE_DRIVERS).pipe(map((resp: any) => {
      this.refreshList$.emit(resp);
      return resp;
    }
    ), catchError(error => {
      return observableOf(error);
    }),);
  }
  /*************************************************** Get All Role *******************************************************/

  public getAllUsersWithRole() {
    return this.http.get(environment.base_url + this.ENDPOINT_LIST_USERS_ROLES + 'YES').pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return observableOf(error);
    }),);
  }

  public getAllUsersWithoutRole() {
    return this.http.get(environment.base_url + this.ENDPOINT_LIST_USERS_ROLES + 'NO').pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return observableOf(error);
    }),);
  }
  /*************************************************** Active/deactive/suspend user account *******************************************************/


  public changeAccountStatus(status: string, id: any) {
    return this.http.put(environment.base_url + this.ENDPOINT_DELETE_USER + id + this.ENDPOINT_UPDATE_USER_ACCOUNT_STATUS + status, {}, { observe: 'response' }).pipe(map((resp: any) => {
      this.refreshList$.emit(resp);
      return resp;
    }
    ), catchError(error => {
      return observableOf(error);
    }),);
  }

  /*************************************************** Get All Managed User *******************************************************/
  public getAllManagedUsers() {
    let params = new HttpParams().set('userId', this.auth.getUserId())
    return this.http.get(environment.base_url + this.ENDPOINT_MANAGED_USER_WITH_TASKS, { params: params }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return observableOf(error);
    }),);
  }

  /*************************************************** Add/Edit user Note *******************************************************/
  public createUserNote(body: any) {
    return this.http.post(environment.base_url + this.ENDPOINT_USER_NOTES, body, { observe: 'response' }).pipe(map((resp: any) => {
      this.refreshList$.emit(body);
      return resp;
    }
    ), catchError(error => {
      return observableOf(error);
    }),);
  }

  public editUserNote(body: any) {
    return this.http.put(environment.base_url + this.ENDPOINT_USER_NOTES, body, { observe: 'response' }).pipe(map((resp: any) => {
      this.refreshList$.emit(body);
      return resp;
    }
    ), catchError(error => {
      return observableOf(error);
    }),);
  }

  public getActiveUsers() {
    return this.http.get(environment.base_url + '/v4/users/active').pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return observableOf(error);
    }),);
  }

  public validateActivationToken(token: any) {
    return this.httpOnly.get(`${environment.base_url}/open/users/validate/activation-key?activationKey=${token}`)
      .pipe(map((resp: any) => {
        console.log('RESP', resp);
        if (resp) {
          return resp;
        }
      }
      ), catchError(error => {
        return observableOf(error);
      }),);
  }

  public activateAccount(body: any) {
    return this.http.post(`${environment.base_url}/open/users/account-activation`, body)
      .pipe(map((resp: any) => {
        if (resp) {
          localStorage.setItem('jwt_token', resp.id_token);
          resp = Object.assign(resp, { status: 200 });
          return resp;
        }
      }
      ), catchError(error => {
        return observableOf(error);
      }),);
  }

  public adjustProfile(body: any) {
    return this.http.put(environment.base_url + this.ENDPOINT_ADJUST_PROFILE, body, { observe: 'response' }).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }
    ), catchError(error => {
      return observableOf(error);
    }),);
  }
  public advancedSearchSetupUsers(searchParam: any, isDownLine: any) {
    return this.http.get(environment.base_url + '/users/advance-search?name=' + searchParam + '&fromDownline=' + isDownLine).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }
    ), catchError(error => {
      return observableOf(error);
    }),);
  }

  public getWelcomeNotice(id: any) {
    return this.http.get(environment.base_url + '/system-notices/read?notice=V4&userId=' + id).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return observableOf(error);
    }),);
  }

  public updateWelcomeNotice(body: any) {
    return this.http.post(`${environment.base_url}/system-notices`, body)
      .pipe(map((resp: any) => {
        return resp;
      }
      ), catchError(error => {
        return observableOf(error);
      }),);
  }


/******************************************** List of Users for Machines Specialist ******************************************/
public getAllUserForMachinesSpecialist( search: any, parameters: any) {
  let params = new HttpParams();
  if (search !== "" && search != null) {
    params = params.set("search", search);
  }
 

  if (parameters)
    //console.log(parameters)
    params = params
      .set("startFrom", parameters.startRow)
      .set("perPage", "25")
      .set("columnName", parameters.sortModel[0].colId)
      .set("sortOrder", parameters.sortModel[0].sort);


  return this.http .get(environment.base_url +'/v4-1/get-all-users', { observe: "response", params: params}).pipe(map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return observableOf(error);
      })
    );
}

  /*************************************************** Get All User Sie by Userid *******************************************************/
  public getAllDownlineUsersSiteById(id: any) {
    return this.http.get(environment.ms_node_base_url + 'ms/v1/machine-specialists/user/'+id).pipe(
      map((resp: any) => {
        //console.log(resp);
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
  }

  public getGoogleCalenderConnectionUrl(){
    return this.http.get( environment.base_url+this.ENDPOINT_GOOGLE_CALENDER_AUTH).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }

  public getMicrosoftCalenderConnectionUrl(){
    return this.http.get( environment.base_url+this.ENDPOINT_MICROSOFT_CALENDER_AUTH).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }
  public disconnectGoogleCalender(){
    return this.http.get( environment.base_url+this.ENDPOINT_GOOGLE_CALENDER_DISCONNECT).pipe(map( (resp:any) => {
      this.refreshGoogleCalender$.emit();
      return resp;
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }
  public disconnectMicrosoftCalender(){
    return this.http.get( environment.base_url+this.ENDPOINT_MICROSOFT_CALENDER_DISCONNECT).pipe(map( (resp:any) => {
      this.refreshMicrosoftCalender$.emit();
      return resp;
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }

  public getCalendlyCalenderConnectionUrl(){
    return this.http.get( environment.base_url+this.ENDPOINT_CALENDLY_CALENDER_AUTH).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }
  public disconnectCalendlyCalender(){
    return this.http.get( environment.base_url+this.ENDPOINT_CALENDLY_CALENDER_DISCONNECT).pipe(map( (resp:any) => {
      this.refreshCalendlyCalender$.emit();
      return resp;
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }

  //*************************************************** moved to ai.services.ts  *******************************************************/

  // public getConnectWithAI(userId: any, fromDateTime:any, toDateTime: any){
  //   let params = new HttpParams();
  //   params = params.set("userId", userId);
  //   params = params.set("fromDateTime", fromDateTime);
  //   params = params.set("toDateTime", toDateTime);
  //   params = params.set("limit", '10');
  //   return this.http.get( environment.ai_node_url+'/v1/job-orders/summery', { observe: "response", params: params}).pipe(map( (resp:any) => {
  //     return resp;
  //   }
  // ),catchError( error => {
  //       return observableOf( error );
  //   }),);
  // }

  public setUser(customer: any) {
    this.userItemsSource.next(customer);
  }

  public getUser() {
    return this.userItemsSource;
  }


}
