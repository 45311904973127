import { Injectable, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthenticationService } from '../auth/authentication.service';
import { PermissionService } from '../auth/permission.service';
import { LanguageTranslateService } from './language-translate.service';
import { SubscriptionUtil } from '../_utilities/subscription';
import { map } from 'rxjs/operators';

@Injectable()
export class  MenuService extends SubscriptionUtil {

  private menuSource = new Subject<any>();
  menuSourceHandler = this.menuSource.asObservable();

  private resetSource = new Subject();
  resetSource$ = this.resetSource.asObservable();

  rootLevelNodes: any;
  company!: boolean;
  users!: boolean;
  // profiles!: boolean;
  // roles!: boolean;
  billing!: boolean;
  licenses!: boolean;
  // manageTickets!: boolean;
  // manageInventory!: boolean;
  manageImportExport!: boolean;
  // manageNotification!: boolean;
  // manageGeofence!: boolean;
  salePer: boolean = false;
  servicePer: boolean = false;
  fleetPer: boolean = false;
  addonFeature: any;
  // drivers!: boolean;
  menuType: any;
  // fleetSetup!: boolean;
  assets!: boolean;
  jobs!: boolean;
  tickets!: boolean;
  packages!: boolean;
  vehicles!: boolean;
  module!: boolean;
  workflow!: boolean;
  menuRename:any ={
    site:"Site",
    asset:"Asset",
    customer:"Customer"
  }
  calendlyIntegration!: boolean;
  customerSurveyLink!: boolean;
  teams!: boolean;
  site!: boolean;
  project!: boolean;
  translate: any;

  constructor(private perm: PermissionService,
    private auth: AuthenticationService,
    private languageTranslateService: LanguageTranslateService) {
      super()
      
     }


  onMenuStateChange(items: any) {
    this.menuSource.next(items);
  }

  reset() {
    this.resetSource.next();
  }
  initPermission() {

   

    this.company = this.perm.capable('manage_org_detail', 'read');
    // this.profiles = this.perm.capable('manage access', 'read');
    // this.roles = this.perm.capable('manage hierarchy', 'read');
    this.billing = this.perm.capable('manage_billing', 'read');
    this.licenses = this.perm.capable('manage_licenses', 'read');
    // this.manageTickets = this.perm.capable('manage tickets', 'read');
    // this.manageInventory = this.perm.capable('manage inventory', 'read');
    this.manageImportExport = this.perm.capable('manage_import_export', 'read');
    // this.manageGeofence = this.perm.capable('manage geo fence', 'read');
    // this.manageNotification = this.perm.capable('manage notification', 'read');
    // this.drivers = this.perm.capable('drivers', 'read');
    // this.fleetSetup = this.perm.capable('fleet setup', 'read');
    this.module = this.perm.capable('manage_modules', 'read');
    this.workflow = this.perm.capable('manage_workflow', 'read');

    this.calendlyIntegration = this.perm.capable('calendly_integration', 'read');

    this.customerSurveyLink = this.perm.capable('customer_survey_link', 'read');



    this.assets = this.perm.capable('assets', 'read');
    this.jobs = this.perm.capable('job_order', 'read');
    this.tickets = this.perm.capable('tickets', 'read');

    this.packages = this.perm.capable('packages', 'read');

    this.users = this.perm.capable("manage_users", "read");
    this.teams = this.perm.capable('teams', 'read');

    var profileInfo = JSON.parse(this.auth.getProfileInfo());
    this.addonFeature = this.auth.getAddonFeatureLists();
    this.salePer = this.findMenuPermissionsSALES(profileInfo.tenantCategories);
    this.servicePer = this.findMenuPermissionsSERVICE(profileInfo.tenantCategories);
    this.fleetPer = this.findMenuPermissionsFLEET(profileInfo.tenantCategories);

    this.site = this.perm.capable("site", "read");
    this.project = this.perm.capable("project", "read");

    var menuPerm: any = localStorage.getItem('menuPermissions')
    var menuPermissions = JSON.parse(menuPerm);
    this.menuType = sessionStorage.getItem('menuType') ? sessionStorage.getItem('menuType') : menuPermissions.defaultTenantCategory;
  }




  // getMenuNode() {

    
  //   console.log(this.translate)
  //   if(this.addonFeature.addonMS == 'MACHINE_SPECIALIST'){
  //     this.menuRename ={
  //       site:"Estate",
  //       asset:"Machine",
  //       customer:"SOU"
  //     }
  //   }
  //   return this.rootLevelNodes
  //   this.rootLevelNodes = [
  //     {
  //       name: this.translate?.general.label.singular, value: "general", hide: !this.company, data: [
  //         { name: this.translate?.setup.general.organizationDetails.label.singular, value: "organisationInformation", routerLink: "generalSettings" },
  //         // { name: "Default Formats", value: "defaultFormats", routerLink: "defaultFormats" },
  //         { name: this.translate?.setup.general.licenseBilling.label.singular, value: "LicenseBilling", routerLink: "licenseBilling" },
  //         { name: this.translate?.setup.general.workingHours.label.singular, value: "orgWideWorkingHours", routerLink: "orgWorkingHours" },
  //       ]
  //     },
  //     {
  //       name: "System Configuration", value: "systemConfiguration", data: [
  //         { name: "Labels", value: "labels", routerLink: "system/labels" },
  //         {
  //           name: "Notifications", value: "notificationsSettings", data: [], routerLink: "notifications/settings"
  //         },
  //       ]
  //     },
  //     {
  //       name: "Users", value: "users", hide: !this.users, data: [
  //         { name: "Access", value: "access", hide: !this.users, routerLink: "profiles" },
  //         { name: "Drivers", value: "driver", hide: this.users && this.menuType == 'fleet' ? false : true, routerLink: "drivers" },
  //         { name: "Hierarchy", value: "Hierarchy", hide: !this.users, routerLink: "hierarchy" },
  //         { name: "Skills", value: "skills", hide: !this.users || !(this.menuType == 'sales' || this.menuType == 'service'), routerLink: "skills" },
  //         { name: "Teams", value: "teams", hide: !this.users, routerLink: "teams" },
  //         { name: "Users", value: "user", hide: !this.users, routerLink: "allUser" },
  //         { name: "User Groups", value: "userGroups", hide: !this.users || !(this.menuType == 'sales' || this.menuType == 'service'), routerLink: "groups" },
  //       ]
  //     },
  //     {
  //       name: "Modules", value: "module", hide: !this.module, data: [
  //         // { name: "Accessories", value: "accessories", hide: !this.profiles, routerLink: "dashboard/setup/profiles" },
  //         {
  //           name: this.menuRename.asset, value: "assets", hide: this.module && this.addonFeature.addonINVENTORY == 'INVENTORY' && (this.menuType == 'sales' || this.menuType == 'service') ? false : true, data: [
  //             { name: translate.setup.modules.configuration.label.singular, value: "assetDetails", routerLink: "asset/details" },
  //             { name: translate.setup.modules.picklist.label.singular, value: "assetFields", routerLink: "asset/picklist" },
  //             { name: translate.setup.modules.listView.label.singular, value: "assetListView", routerLink: "asset/listView" },
  //           ]
  //         },
  //         { name: "Contract", value: "contracts", data: [
  //             { name: translate.setup.modules.listView.label.singular, value: "contractsListView", routerLink: "contracts/listView" },
  //           ], hide: this.module && this.addonFeature.addonINVENTORY == 'INVENTORY' && (this.menuType == 'sales' || this.menuType == 'service') ? false : true
  //         },
  //         {
  //           name: this.menuRename.customer, value: "customer", data: [
  //             { name: translate.setup.modules.configuration.label.singular, value: "customerConfiguration", routerLink: "customer/config" },
  //             { name: translate.setup.modules.picklist.label.singular, value: "customerPicklist", routerLink: "customer/picklist" },
  //             // { name: "Sector Mapping", value: "customerSectMap", routerLink: "customer/mapping" },
  //             { name: translate.setup.modules.listView.label.singular, value: "customerListView", routerLink: "customer/listView" },
  //           ]
  //         },
  //         {
  //           name: "Delivery Order", value: "deliveryOrder", hide: this.module && this.menuType == 'fleet' ? false : true, data: [
  //             { name: translate.setup.modules.configuration.label.singular, value: "deliveryOrderDetails", routerLink: "deliveryOrders/details" },
  //             { name: translate.setup.modules.picklist.label.singular, value: "deliveryOrderPicklist", routerLink: "deliveryOrders/picklist" },
  //             { name: "Default Loading Time", value: "deliveryOrderDefaultLoadingtime", routerLink: "deliveryOrders/loadingTime" },
  //             { name: "Verification Process", value: "deliveryOrderVerificationprocess", routerLink: "deliveryOrders/verification" },
  //             { name: "Validation Rules", value: "deliveryOrderValidationRules", routerLink: "deliveryOrders/validation" },
  //             { name: "Commission", value: "deliveryOrderCommission", routerLink: "deliveryOrders/commission" },

  //           ]
  //         },
  //         { name: "Depots", value: "depot", data: [], routerLink: "fleetSetup/depots", hide: this.module && this.menuType == 'fleet' ? false : true },
  //         { name: "Fleet Configuration", value: "fleetConfit", data: [], routerLink: "fleetSetup/fleetConfig", hide: this.module && this.menuType == 'fleet' ? false : true },
  //         {
  //           name: "General", value: "generalServiceZone", data: [
  //             { name: "Picklist", value: "picklist", routerLink: "general/picklist" },
  //           ]
  //         },
  //         { name: "Geofence", value: "geofencing", data: [], hide: !this.module, routerLink: "geofencing" },
  //         {
  //           name: "Inventory", value: "inventory", data: [
  //             { name: "Locations", value: "inventoryLocation", routerLink: "inventory/location" },
  //             { name: "Zones", value: "inventoryZone", routerLink: "inventory/zone" },
  //             { name: "Bins", value: "inventoryBin", routerLink: "inventory/bin" }
  //           ], hide: this.addonFeature.addonINVENTORY == 'INVENTORY' && this.module && this.menuType != 'fleet' ? false : true
  //         },
      
  //         {
  //           name: "Job Order", value: "jobOrder", hide: (this.menuType === 'service' && this.servicePer) || (this.menuType === 'sales' && this.servicePer) ? false : true, data: [
  //             { name: translate.setup.modules.configuration.label.singular, value: "jobOrderDetails", routerLink: "jobOrder/details" },
  //             { name: translate.setup.modules.picklist.label.singular, value: "jobOrderPicklist", routerLink: "jobOrder/picklist" },
  //             { name: translate.setup.modules.templates.label.singular, value: "jobOrderTemplates", routerLink: "jobOrder/templates", hide:this.workflow && this.menuType != 'fleet' && (this.addonFeature.addonMS == 'MACHINE_SPECIALIST' || this.addonFeature.addonINVENTORY == 'INVENTORY') ? false : true},
  //             { name: "Faults & Resolutions", value: "jobOrderFaultsResolution", routerLink: "jobOrder/faultresolution" },
  //             { name: translate.setup.modules.listView.label.singular, value: "jobOrderListView", routerLink: "jobOrder/listView" },
  //           ]
  //         },
  //         {
  //           name: "Package", value: "packages", hide: this.module && this.menuType == 'fleet' && this.packages ? false : true, data: [
  //             { name: translate.setup.modules.picklist.label.singular, value: "packagesPicklist", routerLink: "packages/picklist" },
  //           ]
  //         },
  //         { name: "Preventive Maintenance", value: "preventiveMaintenance", data: [
  //           { name: translate.setup.modules.configuration.label.singular, value: "preventiveMaintenanceDetails", routerLink: "preventiveMaintenance/configuration" },
  //           { name: translate.setup.modules.listView.label.singular, value: "preventiveMaintenanceListView", routerLink: "preventiveMaintenance/listView" },
  //         ], hide: !this.addonFeature?.addonDYNAMICMAINTENANCE || !(this.menuType == 'sales' || this.menuType == 'service'),
  //        },
  //         {
  //           name: "Product", value: "products", data: [
  //            // { name: translate.setup.modules.picklist.label.singular, value: "productsPicklist", routerLink: "products/picklist" },
  //             { name: translate.setup.modules.listView.label.singular, value: "productsListView", routerLink: "products/listView" },
  //           ]
  //         },
  //         {
  //           name: "Project", value: "projects", hide:  ((this.menuType == 'sales' || this.menuType == 'service')) && this.addonFeature.addonPROJECT == 'PROJECT' ? false : true, data: [
  //             { name: translate.setup.modules.configuration.label.singular, value: "projectDetails", routerLink: "projects/details" },
  //             { name: translate.setup.modules.picklist.label.singular, value: "projectsPicklist", routerLink: "projects/picklist" },
  //             { name: translate.setup.modules.listView.label.singular, value: "assetListView", routerLink: "projects/listView" },
  //           ]
  //         },
  //         {
  //           name: "Sales Order", value: "salesOrder", hide: this.salePer || this.servicePer ? false : true, data: [
  //             { name: translate.setup.modules.configuration.label.singular, value: "salesOrderDetails", routerLink: "salesOrder/details" },
  //             { name: translate.setup.modules.picklist.label.singular, value: "salesOrderPicklist", routerLink: "salesOrder/fields" },
  //             { name: translate.setup.modules.listView.label.singular, value: "salesListView", routerLink: "salesOrder/listView" },
  //           ]
  //         },
  //         {
  //           name: this.menuRename.site, value: "sites", hide: ((this.menuType == 'sales' || this.menuType == 'service')) ? false : true, data: [
  //             { name: translate.setup.modules.configuration.label.singular, value: "siteDetails", routerLink: "sites/details" },
  //             { name: translate.setup.modules.picklist.label.singular, value: "sitesPicklist", routerLink: "sites/picklist" },
  //             { name: translate.setup.modules.listView.label.singular, value: "assetListView", routerLink: "sites/listView" },
  //           ]
  //         },
  //         { name: "Subcontractor", value: "subContractor", data: [], routerLink: "fleetSetup/subContractors", hide: this.module && this.menuType == 'fleet' ? false : true },
  //         {
  //           name: "Task", value: "task", hide: (this.menuType === 'sales' || this.menuType === 'service') ? false : true, data: [
  //             { name: translate.setup.modules.configuration.label.singular, value: "taskConfiguration", routerLink: "tasks/config" },
  //             // { name: translate.setup.modules.picklist.label.singular, value: "taskPicklist", routerLink: "" },
  //             { name: translate.setup.modules.templates.label.singular, value: "taskTemplates", routerLink: "tasks/templates",hide: (this.menuType === 'sales' || this.menuType === 'service') && (this.addonFeature.addonMS == 'MACHINE_SPECIALIST' || this.addonFeature.addonINVENTORY == 'INVENTORY') ? false : true,},
  //             { name: translate.setup.modules.listView.label.singular, value: "assetListView", routerLink: "tasks/listView" },
  //           ]
  //         },
  //         {
  //           name: "Ticket", value: "ticket", hide:  this.addonFeature.addonSLA == 'SLA' && (this.menuType === 'sales' || this.menuType === 'service') ? false : true, data: [
  //             { name: translate.setup.modules.configuration.label.singular, value: "ticketDetails", routerLink: "ticket/details" },
  //             { name: translate.setup.modules.picklist.label.singular, value: "ticketFields", routerLink: "ticket/fields" },
  //             { name: translate.setup.modules.listView.label.singular, value: "ticketListView", routerLink: "ticket/listView" },
  //           ]
  //         },
            
  //       ]
  //     },
     
  //     {
  //       name: "Workflows", value: "workflows", hide: this.workflow && this.menuType !== 'fleet' ? false : true, data: [
  //      // name: "Workflows", value: "workflows",  data: [
  //         // {
  //         //   name: "Checklist", value: "checklist", data: [
  //         //     { name: "Task List", value: "taskList", routerLink: "checklist/taskList" },
  //         //     { name: "Equipment", value: "equipment", routerLink: "checklist/equipment" },
  //         //     { name: "Maintenance Tasks", value: "maintenanceTasks", routerLink: "checklist/maintenance" },
  //         //     { name: "Departments", value: "departments", routerLink: "checklist/departments" },
             
  //         //   ], hide: this.addonFeature.addonTTL == 'TTL_QC' && this.menuType != 'fleet' ? false : true
  //         // },
  //         {
  //           name: "Checklists", value: "checklists", data: [], hide:this.workflow && this.menuType != 'fleet' && (this.addonFeature.addonMS == 'MACHINE_SPECIALIST' || this.addonFeature.addonINVENTORY == 'INVENTORY') ? false : true,routerLink: "checklist"
  //         },
  //         { name: "Devices", value: "devices", data: [], hide: this.addonFeature.addonCHUBB == 'CHUBB' ? false : true, routerLink: "chubb/devices" },
  //         // { name: "Notifications", value: "notification", data: [], hide: this.addonFeature.addonMAGNUM == 'MAGNUM' && this.manageNotification ? false : true, routerLink: "dashboard/setup/notifications" },
  //         { name: "Workflows", value: "workflows", data: [], routerLink: "workflows" },
  //         { name: "Notifications", value: "notifications", data: [], routerLink: "action/notifications" },
  //         {
  //           name: "SLA", value: "sla", data: [
  //             { name: "SLA Packages", value: "inventoryLocation", routerLink: "sla/package" },
  //             { name: "SLA Steps", value: "inventoryLocation", routerLink: "sla/step" },
  //             { name: "SLA Business Hours", value: "inventoryLocation", routerLink: "sla/businessHours" },
            
  //           ], hide: this.addonFeature.addonSLA == 'SLA' && this.module && this.menuType != 'fleet' ? false : true
  //         }
  //       ]
  //     },
  //     { name: translate.setup.modules.templates.label.singular, value: "templates", data: [
  //         { name: "Emails", value: "emailTemplates", routerLink: "templates/email" },
  //       ], hide: !this.manageImportExport
  //     },
  //     { name: "Import/Export", value: "import", data: [], hide: !this.manageImportExport, routerLink: "dashboard/setup/import" },
  //     {
  //       name: "Integrations", value: "integrations",hide: this.calendlyIntegration ? false : true, data: [
  //         { name: translate.setup.modules.configuration.label.singular, value: "integrationConfig", routerLink: "integration/config" },
  //       ],
  //     },
  //     { name: "Customer Surveys", value: "survey", data: [], routerLink: "dashboard/setup/survey",hide: (this.customerSurveyLink && this.addonFeature.addonSURVEY == 'SURVEY') ? false : true, },
  //   ];
  //   return this.rootLevelNodes
  // }


// ------------------- Pass menu Items to Setup frame with translation Data
getMenuNodeWithData2(): Observable<any[]> {
  this.initPermission()
  return this.languageTranslateService.wordSrc$.pipe(
    map(data => {
      if (Object.keys(data).length === 0) {
        return []; // Return an empty array if no data is available
      }
      const translate = data;
      const rootLevelNodes = [
        {
          name: translate?.general.label.singular, value: "general", hide: !this.company, data: [
            { name: translate?.setup.general.organizationDetails.label.singular, value: "organisationInformation", routerLink: "generalSettings" },
            // { name: "Default Formats", value: "defaultFormats", routerLink: "defaultFormats" },
            { name: translate?.setup.general.licenseBilling.label.singular, value: "LicenseBilling", routerLink: "licenseBilling" },
            { name: translate?.setup.general.workingHours.label.singular, value: "orgWideWorkingHours", routerLink: "orgWorkingHours" },
          ]
        },
        {
          name: translate.setup.configuration.label.plural, value: "systemConfiguration", data: [
            { name: translate?.setup.configuration.systemLabel.label.plural, value: "labels", routerLink: "system/labels" ,hide: this.module && this.addonFeature.addonLABEL == 'LABEL' ? false : true},
            {
              name: translate?.setup.notifications.label.plural, value: "notificationsSettings", data: [], routerLink: "notifications/settings"
            },
            { name: "Custom Form", value: "customForm", routerLink: "system/customForm" },
            {
              name: "AI", value: "ai", data: [
                {name: translate.setup.configuration.artificialIntelligence.sections.sec0.singular, value: "aiSuggestions", routerLink: "ai/suggestions"},
                {name: translate.setup.configuration.artificialIntelligence.sections.sec6.singular, value: "aiTraining", routerLink: "ai/training"},
              ], hide: this.module && this.addonFeature.addonAI == 'AI' ? false : true
            },
          ]
        },
        {
              name: translate.setup.users.users.label.plural, value: "users", hide: !this.users, data: [
            { name: translate.setup.users.access.label.plural, value: "access", hide: !this.users, routerLink: "profiles" },
            { name: "Drivers", value: "driver", hide: this.users && this.menuType == 'fleet' ? false : true, routerLink: "drivers" },
            { name: translate.setup.users.hierarchy.label.singular,  value: "Hierarchy", hide: !this.users, routerLink: "hierarchy" },
            { name: translate.setup.users.skills.label.plural, value: "skills", hide: !this.users || !(this.menuType == 'sales' || this.menuType == 'service'), routerLink: "skills" },
            { name: translate.setup.users.teams.label.plural, value: "teams", hide: !this.users, routerLink: "teams" },
            { name: translate.setup.users.users.label.plural, value: "user", hide: !this.users, routerLink: "allUser" },
            { name: translate.setup.users.groups.label.plural, value: "userGroups", hide: !this.users || !(this.menuType == 'sales' || this.menuType == 'service'), routerLink: "groups" },
          ]
        },
        {
          name: translate.setup.modules.label.plural, value: "module", hide: !this.module, data: [
            // { name: "Accessories", value: "accessories", hide: !this.profiles, routerLink: "dashboard/setup/profiles" },
            {
              name: translate.asset.label.plural, value: "assets", hide: this.module && this.addonFeature.addonINVENTORY == 'INVENTORY' && (this.menuType == 'sales' || this.menuType == 'service') ? false : true, data: [
                { name: translate.setup.modules.configuration.label.singular, value: "assetDetails", routerLink: "asset/details" },
                { name: translate.setup.modules.picklist.label.plural, value: "assetFields", routerLink: "asset/picklist" },
                { name: translate.setup.modules.listView.label.plural, value: "assetListView", routerLink: "asset/listView" },
              ]
            },
            { name: translate.contracts.label.plural, value: "contracts", data: [
                { name: translate.setup.modules.listView.label.plural, value: "contractsListView", routerLink: "contracts/listView" },
              ], hide: this.module && this.addonFeature.addonINVENTORY == 'INVENTORY' && (this.menuType == 'sales' || this.menuType == 'service') ? false : true
            },
            {
              name: translate.customer.label.plural, value: "customer", data: [
                { name: translate.setup.modules.configuration.label.singular, value: "customerConfiguration", routerLink: "customer/config" },
                { name: translate.setup.modules.picklist.label.plural, value: "customerPicklist", routerLink: "customer/picklist" },
                // { name: "Sector Mapping", value: "customerSectMap", routerLink: "customer/mapping" },
                { name: translate.setup.modules.listView.label.plural, value: "customerListView", routerLink: "customer/listView" },
              ]
            },
            {
              name: "Delivery Order", value: "deliveryOrder", hide: this.module && this.menuType == 'fleet' ? false : true, data: [
                { name: translate.setup.modules.configuration.label.singular, value: "deliveryOrderDetails", routerLink: "deliveryOrders/details" },
                { name: translate.setup.modules.picklist.label.plural, value: "deliveryOrderPicklist", routerLink: "deliveryOrders/picklist" },
                { name: "Default Loading Time", value: "deliveryOrderDefaultLoadingtime", routerLink: "deliveryOrders/loadingTime" },
                { name: "Verification Process", value: "deliveryOrderVerificationprocess", routerLink: "deliveryOrders/verification" },
                { name: "Validation Rules", value: "deliveryOrderValidationRules", routerLink: "deliveryOrders/validation" },
                { name: "Commission", value: "deliveryOrderCommission", routerLink: "deliveryOrders/commission" },
  
              ]
            },
            { name: "Depots", value: "depot", data: [], routerLink: "fleetSetup/depots", hide: this.module && this.menuType == 'fleet' ? false : true },
            { name: "Fleet Configuration", value: "fleetConfit", data: [], routerLink: "fleetSetup/fleetConfig", hide: this.module && this.menuType == 'fleet' ? false : true },
            {
              name: translate?.general.label.singular, value: "generalServiceZone", data: [
                { name: translate.setup.modules.picklist.label.plural, value: "picklist", routerLink: "general/picklist" },
              ]
            },
            { name: translate.setup.geofence.label.plural, value: "geofencing", data: [], hide: !this.module, routerLink: "geofencing" },
            {
              name: translate.inventory.label.plural, value: "inventory", data: [
                { name: translate.setup.modules.inventory.location.label.plural, value: "inventoryLocation", routerLink: "inventory/location" },
                { name: translate.setup.modules.inventory.zones.label.plural, value: "inventoryZone", routerLink: "inventory/zone" },
                { name: translate.setup.modules.inventory.bins.label.plural, value: "inventoryBin", routerLink: "inventory/bin" }
              ], hide: this.addonFeature.addonINVENTORY == 'INVENTORY' && this.module && this.menuType != 'fleet' ? false : true
            },
        
            {
              name: translate.jobOrder.label.plural, value: "jobOrder", hide: (this.menuType === 'service' && this.servicePer) || (this.menuType === 'sales' && this.servicePer) ? false : true, data: [
                { name: translate.setup.modules.configuration.label.singular, value: "jobOrderDetails", routerLink: "jobOrder/details" },
                { name: translate.setup.modules.picklist.label.plural, value: "jobOrderPicklist", routerLink: "jobOrder/picklist" },
                { name: translate.setup.modules.templates.label.plural, value: "jobOrderTemplates", routerLink: "jobOrder/templates", hide:this.workflow && (this.addonFeature.addonMS == 'MACHINE_SPECIALIST' || this.addonFeature.addonJOTEMPLATE == 'JO_TEMPLATE') ? false : true},
                { name: translate.setup.modules.faultResolution.label.singular, value: "jobOrderFaultsResolution", routerLink: "jobOrder/faultresolution" },
                { name: translate.setup.modules.listView.label.plural, value: "jobOrderListView", routerLink: "jobOrder/listView" },
              ]
            },
            {
              name: "Package", value: "packages", hide: this.module && this.menuType == 'fleet' && this.packages ? false : true, data: [
                { name: translate.setup.modules.picklist.label.plural, value: "packagesPicklist", routerLink: "packages/picklist" },
              ]
            },
            { name: translate.preventiveMaintenance.label.plural, value: "preventiveMaintenance", data: [
              { name: translate.setup.modules.configuration.label.singular, value: "preventiveMaintenanceDetails", routerLink: "preventiveMaintenance/configuration" },
              { name: translate.setup.modules.listView.label.plural, value: "preventiveMaintenanceListView", routerLink: "preventiveMaintenance/listView" },
            ], hide: !this.addonFeature?.addonDYNAMICMAINTENANCE || !(this.menuType == 'sales' || this.menuType == 'service'),
           },
            {
              name: translate.product.label.plural, value: "products", data: [
               // { name: translate.setup.modules.picklist.label.plural, value: "productsPicklist", routerLink: "products/picklist" },
                { name: translate.setup.modules.listView.label.plural, value: "productsListView", routerLink: "products/listView" },
              ]
            },
            {
              name: translate.project.label.plural, value: "projects", hide:  ((this.menuType == 'sales' || this.menuType == 'service')) && this.addonFeature.addonPROJECT == 'PROJECT' ? false : true, data: [
                { name: translate.setup.modules.configuration.label.singular, value: "projectDetails", routerLink: "projects/details" },
                { name: translate.setup.modules.picklist.label.plural, value: "projectsPicklist", routerLink: "projects/picklist" },
                { name: translate.setup.modules.listView.label.plural, value: "assetListView", routerLink: "projects/listView" },
              ]
            },
            {
              name: translate.salesOrder.label.plural, value: "salesOrder", hide: this.salePer || this.servicePer ? false : true, data: [
                { name: translate.setup.modules.configuration.label.singular, value: "salesOrderDetails", routerLink: "salesOrder/details" },
                { name: translate.setup.modules.picklist.label.plural, value: "salesOrderPicklist", routerLink: "salesOrder/fields" },
                { name: translate.setup.modules.listView.label.plural, value: "salesListView", routerLink: "salesOrder/listView" },
              ]
            },
            {
              name: translate.site.label.plural, value: "sites", hide: ((this.menuType == 'sales' || this.menuType == 'service')) ? false : true, data: [
                { name: translate.setup.modules.configuration.label.singular, value: "siteDetails", routerLink: "sites/details" },
                { name: translate.setup.modules.picklist.label.plural, value: "sitesPicklist", routerLink: "sites/picklist" },
                { name: translate.setup.modules.listView.label.plural, value: "assetListView", routerLink: "sites/listView" },
              ]
            },
            { name: "Subcontractor", value: "subContractor", data: [], routerLink: "fleetSetup/subContractors", hide: this.module && this.menuType == 'fleet' ? false : true },
            {
              name:translate.task.label.plural, value: "task", hide: (this.menuType === 'sales' || this.menuType === 'service') ? false : true, data: [
                 { name: translate.setup.modules.configuration.label.singular, value: "taskConfiguration", routerLink: "tasks/config" }, // CS-6290
                // { name: translate.setup.modules.picklist.label.plural, value: "taskPicklist", routerLink: "" },
                { name: translate.setup.modules.templates.label.plural, value: "taskTemplates", routerLink: "tasks/templates",hide: (this.menuType === 'sales' || this.menuType === 'service') && (this.addonFeature.addonMS == 'MACHINE_SPECIALIST' || this.addonFeature.addonJOTEMPLATE == 'JO_TEMPLATE') ? false : true,},
                { name: translate.setup.modules.listView.label.plural, value: "assetListView", routerLink: "tasks/listView" },
              ]
            },
            {
              name: translate.ticket.label.plural, value: "ticket", hide:  this.addonFeature.addonSLA == 'SLA' && (this.menuType === 'sales' || this.menuType === 'service') ? false : true, data: [
                { name: translate.setup.modules.configuration.label.singular, value: "ticketDetails", routerLink: "ticket/details" },
                { name: translate.setup.modules.picklist.label.plural, value: "ticketFields", routerLink: "ticket/fields" },
                { name: translate.setup.modules.listView.label.plural, value: "ticketListView", routerLink: "ticket/listView" },
              ]
            },
              
          ]
        },
       
        {
          name: translate.setup.workflows.label.plural, value: "workflow", hide: this.workflow && this.menuType !== 'fleet' ? false : true, data: [
         // name: "Workflows", value: "workflows",  data: [
            // {
            //   name: "Checklist", value: "checklist", data: [
            //     { name: "Task List", value: "taskList", routerLink: "checklist/taskList" },
            //     { name: "Equipment", value: "equipment", routerLink: "checklist/equipment" },
            //     { name: "Maintenance Tasks", value: "maintenanceTasks", routerLink: "checklist/maintenance" },
            //     { name: "Departments", value: "departments", routerLink: "checklist/departments" },
               
            //   ], hide: this.addonFeature.addonTTL == 'TTL_QC' && this.menuType != 'fleet' ? false : true
            // },
            {
              name: translate.setup.checklist.label.plural, value: "checklists", data: [], hide:this.workflow && this.menuType != 'fleet' && (this.addonFeature.addonMS == 'MACHINE_SPECIALIST' || this.addonFeature.addonINVENTORY == 'INVENTORY') ? false : true,routerLink: "checklist"
            },
            { name: translate.setup.devices.label.plural, value: "devices", data: [], hide: this.addonFeature.addonCHUBB == 'CHUBB' ? false : true, routerLink: "chubb/devices" },
            // { name: "Notifications", value: "notification", data: [], hide: this.addonFeature.addonMAGNUM == 'MAGNUM' && this.manageNotification ? false : true, routerLink: "dashboard/setup/notifications" },
            { name: translate.setup.workflows.label.plural, value: "workflows", data: [], routerLink: "workflows",hide:this.addonFeature.addonWORKFLOW == 'WORKFLOW' ? false : true },
            { name: translate?.setup.notifications.label.plural, value: "notifications", data: [], routerLink: "action/notifications",hide:this.addonFeature.addonWORKFLOW == 'WORKFLOW' ? false : true },
            {
              name: translate.setup.sla.label.plural, value: "sla", data: [
                { name: translate.setup.sla.packages.label.plural, value: "inventoryLocation", routerLink: "sla/package" },
                { name: translate.setup.sla.steps.label.plural, value: "inventoryLocation", routerLink: "sla/step" },
                { name: translate.setup.sla.businessHours.label.singular, value: "inventoryLocation", routerLink: "sla/businessHours" },
              
              ], hide: this.addonFeature.addonSLA == 'SLA' && this.module && this.menuType != 'fleet' ? false : true
            }
          ]
        },
        { name: translate.setup.templates.label.plural, value: "templates", data: [
            { name: translate.setup.templates.email.label.plural, value: "emailTemplates", routerLink: "templates/email" },
          ], hide:this.addonFeature.addonWORKFLOW == 'WORKFLOW' ? false : true
        },
        { name: translate.setup.importExport.label.singular, value: "import", data: [], hide: !this.manageImportExport, routerLink: "dashboard/setup/import" },
        {
          name:  translate.setup.integrations.label.plural, value: "integrations",hide: this.calendlyIntegration ? false : true, data: [
            { name: translate.setup.modules.configuration.label.singular, value: "integrationConfig", routerLink: "integration/config" },
          ],
        },
        { name:  translate.setup.customerSurvey.label.singular, value: "survey", data: [], routerLink: "dashboard/setup/survey",hide: (this.customerSurveyLink && this.addonFeature.addonSURVEY == 'SURVEY') ? false : true, },
      ];

      return rootLevelNodes;
    })
  );
}


// getMenuTaskWithData(): Observable<any[]> {
//   //this.initPermission()
//   return this.languageTranslateService.wordSrc$.pipe(
//     map(data => {
//       if (Object.keys(data).length === 0) {
//         return []; // Return an empty array if no data is available
//       }
//       const translate = data;
//       const rootLevelNodes = [
//         { name: "Queue (10)", value: "queue", data: [], routerLink: "dashboard/setup/fleetSetup/fleetConfig" },
  
//         {
//           name: "My Task", value: "general", data: [
//             { name: "All Task (14)", value: "organisationInformation", routerLink: "generalSettings" },
//             { name: "Today (5)", value: "defaultFormats", routerLink: "defaultFormats" },
//             { name: "Upcoming (3)", value: "LicenseBilling", routerLink: "licenseBilling" },
//             { name: "Completed (11)", value: "orgWideWorkingHours", routerLink: "orgWorkingHours" },
//           ]
//         },
//         {
//           name: "Staf Task", value: "users", data: [
//             { name: "All Task (14)", value: "organisationInformation", routerLink: "generalSettings" },
//             { name: "Today (5)", value: "defaultFormats", routerLink: "defaultFormats" },
//             { name: "Upcoming (3)", value: "LicenseBilling", routerLink: "licenseBilling" },
//             { name: "Completed (11)", value: "orgWideWorkingHours", routerLink: "orgWorkingHours" },
//           ]
//         },
  
//         {
//           name: "Team Task", value: "sla", data: [
//             { name: "All Task (14)", value: "organisationInformation", routerLink: "generalSettings" },
//             { name: "Today (5)", value: "defaultFormats", routerLink: "defaultFormats" },
//             { name: "Upcoming (3)", value: "LicenseBilling", routerLink: "licenseBilling" },
//             { name: "Completed (11)", value: "orgWideWorkingHours", routerLink: "orgWorkingHours" },
//           ]
//         }
//       ]

//       return rootLevelNodes;
//     })
//   );
// }


  

  getMenuTask() {
    this.rootLevelNodes = [
      { name: "Queue (10)", value: "queue", data: [], routerLink: "dashboard/setup/fleetSetup/fleetConfig" },

      {
        name: "My Task", value: "general", data: [
          { name: "All Task (14)", value: "organisationInformation", routerLink: "generalSettings" },
          { name: "Today (5)", value: "defaultFormats", routerLink: "defaultFormats" },
          { name: "Upcoming (3)", value: "LicenseBilling", routerLink: "licenseBilling" },
          { name: "Completed (11)", value: "orgWideWorkingHours", routerLink: "orgWorkingHours" },
        ]
      },
      {
        name: "Staf Task", value: "users", data: [
          { name: "All Task (14)", value: "organisationInformation", routerLink: "generalSettings" },
          { name: "Today (5)", value: "defaultFormats", routerLink: "defaultFormats" },
          { name: "Upcoming (3)", value: "LicenseBilling", routerLink: "licenseBilling" },
          { name: "Completed (11)", value: "orgWideWorkingHours", routerLink: "orgWorkingHours" },
        ]
      },

      {
        name: "Team Task", value: "sla", data: [
          { name: "All Task (14)", value: "organisationInformation", routerLink: "generalSettings" },
          { name: "Today (5)", value: "defaultFormats", routerLink: "defaultFormats" },
          { name: "Upcoming (3)", value: "LicenseBilling", routerLink: "licenseBilling" },
          { name: "Completed (11)", value: "orgWideWorkingHours", routerLink: "orgWorkingHours" },
        ]
      }
    ]
    return this.rootLevelNodes
  }


  /************************************* Main Menu Permission Variable Diaply **********************/
  findMenuPermissionsSALES(tenantCategories: any) {
    var saleIndex = tenantCategories.indexOf("SALES");
    if (saleIndex != -1) {
      return true;
    } else {
      return false
    }

  }
  findMenuPermissionsSERVICE(tenantCategories: any) {

    var serviceIndex = tenantCategories.indexOf("SERVICE");
    if (serviceIndex != -1) {
      return true;
    } else {
      return false
    }

  }
  findMenuPermissionsFLEET(tenantCategories: any) {

    var fleetIndex = tenantCategories.indexOf("FLEET");
    if (fleetIndex != -1) {
      return true;
    } else {
      return false
    }
  }
}
