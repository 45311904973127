<div class="left-right-space flex-column-100">
    <!-- Title -->
    <div class="flex-between mb-2">
        <!-- Filter and New -->
        <div class="custella-dashboard-top-right d-flex ms-auto">
            <button  class="btn-list-icon ms-2" [pTooltip]="translate?.general.buttons.refresh.singular" tooltipPosition="bottom" id="btn_CheckJobRefresh"  (click)="onRefreshList()">
                <i class="fa-regular fa-arrows-rotate"></i>
            </button>
            <div class="p-inputgroup search ms-2">
                <span class="p-input-icon-right ">
                    <input type="text" class="w-100" pInputText autocomplete="off" [placeholder]="translate?.general.buttons.search.singular" id="input_CheckJobSearchField" [(ngModel)]="searchValue" (keyup)="onSearchCheck()" (keydown.enter)="onSearch()" />
                    <i *ngIf="searchValueData" class="p-autocomplete-clear-icon pi pi-times" (click)="onClear()"  id="btn_CheckJobSearchClear"></i>
                </span>   
                <button type="button" pButton icon="fa-regular fa-magnifying-glass"(click)="onSearch()" id="btn_CheckJobSearch"></button>
            </div>
            <!-- <p-button type="button" [label]="translate?.general.buttons.new.singular" (onClick)="createView()" icon="fa-regular fa-plus" styleClass="ms-2 h-32" id="btn_CheckJobNew"></p-button> -->
            <p-splitButton [label]="translate?.general.buttons.new.singular"  icon="fa-regular fa-plus" (onClick)="createView()" styleClass="ms-2 h-32 split"  id="btn_JobOrdersNew" [model]="items"></p-splitButton>
        </div>
    </div>

    <div *ngIf="!noChecklistJobOrders" class="col-12 mt-5 py-4">
        <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
            <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound" />
            <p class="text-6 my-1 font-weight-bold" >
                {{translate?.general.messages.noRecordsFound}}
            </p>
        </div>
    </div>

    <div [hidden]="!noChecklistJobOrders" class="position-relative h-100-70">
        <ag-grid-angular
        #agGrid
        class="ag-theme-balham h-100"
        [columnDefs]="columnDefs"
        [gridOptions]="gridOptions"
        [defaultColDef]="defaultColDef"
        [maxConcurrentDatasourceRequests]="-1"
        [suppressDragLeaveHidesColumns]="true"
        (gridReady)="onGridReady($event)"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        (cellClicked)="onCellClicked($event)"
        [getContextMenuItems]="getContextMenuItems"
        [frameworkComponents]="frameworkComponents" 
      ></ag-grid-angular>
      
      <div class="col-12"> <p class="ag-record-row-summary-panel padds text-end" id="label_CheckJobTableRec">
          {{ totalRecord }}&nbsp;{{translate?.general.messages.records}}
        </p>
      </div>
      
    </div>
</div>

<footer *ngIf="displayJobOrderTemplates" class="custella-form-container-footer hidePopup">
    <p-dialog header="Job Order Templates Selection" [(visible)]="displayJobOrderTemplates" [resizable]="false" styleClass="h-100-205"
        showEffect="fade" [style]="{ width: '70vw'}" [modal]="true" [draggable]="false">
        <app-list-jo-templates [checklistRecordId]="checklistRecordId" [pmvId]="pmvId"  [comingFrom]="'checklistJO'" (closeTable)="closeTable()"></app-list-jo-templates>
    </p-dialog>
</footer>
