import { TimezonePickerService } from 'src/app/_services/timezone-picker.service';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import {  ActivatedRoute, Router } from '@angular/router';
import { UsersService } from 'src/app/_services/admin/users.service';
import { ProfilesService } from 'src/app/_services/admin/profiles.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalServiceService } from 'src/app/_services/modal-service.service';
import * as moment from 'moment';
import { LoadingBayModel } from 'src/app/_models/global.data.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PermissionService } from 'src/app/auth/permission.service';
import { SettingService } from 'src/app/_services/admin/setting.service';
import { ProfileFeaturesService } from 'src/app/_services/profile-features.service';
import { RolesService } from 'src/app/_services/admin/roles.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { DynamicSkillsService } from 'src/app/_services/admin/dynamic-skills.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import * as _ from 'lodash';
import { AppComponent } from 'src/app/app.component';
import { BreadcrumbService } from 'src/app/_services/breadcrumb.service';
import { DeleteRecordService } from 'src/app/_services/delete-record.service';
import { WebSocketsService } from 'src/app/_services/web-sockets.service';
import { TabView } from 'primeng/tabview';
import { DynamicLabelsService } from 'src/app/_services/dynamic-labels.service';

import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { LanguageDataModelForTabs, userTabDetails } from 'src/app/_models/tabs.data.model';
import { languageOptions, languagePcklistData } from 'src/app/_models/language.data.model';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss']
})
export class ViewUserComponent extends SubscriptionUtil implements OnInit, OnDestroy,AfterViewInit {
  editForm: boolean = false;
  cancel!: boolean;
  refreshUser!: Subscription;
  params:any;
  isOpen: any = false;
  refreshPhoto!: Subscription;
  disabled: any = true;
  photoUrl: any;
  profile: any = {};
  customClass: string = "accordion-class";
  sharedVar: any;
  saveProfileBoolean: boolean = false;
  homeData : any = null
  officeData : any = null
  timezone: any;

  // access: any;
  // hierarchy: any;
  update: any;
  read: any;
  users: any;
  userTypes: any;
  roles: any;
  skillRoles : any;
  profiles: any;

  orgSettings: any;
  submitTouched: boolean = false;
  password : any = {};
  shiftBased : boolean = true;
  passwordForm!: UntypedFormGroup;
  
  geofences : any[] = [];
  salePer:boolean = false;
  servicePer:boolean = false;
  fleetPer:boolean = false;

  departments : any[] = [];
  categories : any[] = [];
  levels : any[] = [];
  allDepots: any[] = [];
  selectedDepartment: any = null;
  selectedRole : any = null;
  selectedLevel : any = null;
  tenantId : any
  dateformatList=[
    {label:'DD-MM-YYYY',value:'dd-MM-yyyy'},
    {label:'DD/MM/YYYY',value:'dd/MM/yyyy'},
    {label:'MM-DD-YYYY',value:'MM-dd-yyyy'},
    {label:'MM/DD/YYYY',value:'dd-MM-yyyy'},
    {label:'YYYY-MM-DD',value:'yyyy-MM-dd'},
    {label:'YYYY/MM/DD',value:'yyyy/MM/dd'}]
  timeZoneData: any[]=[]
  defaultDashboards =[
      {label:'Pending Job Order',value:'Pending Job Order'},
      {label:'Today Task',value:'Today Task'}]

  bufferMinutes =[{label:'No Buffer',value:0},
        {label:'5 min',value:5},
        {label:'10 min',value:10},
        {label:'15 min',value:15},
        {label:'20 min',value:20},
        {label:'25 min',value:25},
        {label:'30 min',value:30}]
   alertMinutes =[
        {label:'5 min',value:5},
        {label:'10 min',value:10},
        {label:'15 min',value:15},
        {label:'20 min',value:20},
        {label:'25 min',value:25},
        {label:'30 min',value:30}]
        maxShiftHourType =[
          {label:'Day',value:'DAY'},
          {label:'Week',value:'WEEK'}]

    canConnectGoogleCalender:boolean=false;
    googleCalenderConnected:boolean=false;
    microsoftCalenderConnected:boolean=false;
    calendlyCalenderConnected:boolean=false;

        
    googleCalenderBtnClicked:boolean=false;
    microsoftCalenderBtnClicked:boolean=false;
    calendlyCalenderBtnClicked:boolean=false;

    googleCalendereRefresher: Subscription;

  userAccessPermission: any;
  microsoftEnable: any;
  googleEnable: any;
  calendlyEnable: any;
  profileData: any;

  activeIndexAccess: any;
  @ViewChild(TabView, { static: false }) tabView!: TabView;
  loadData = {
    details: true,
    list: false,
    notification: false,  
  };
  translate: any;
  loadTabs:any[] = []
  contractLangData: any; // to load language translated data for
  tabMenu: any[];
  languageList: any;


  constructor(
    private modalService: BsModalService,
    private userService: UsersService,
    public auth: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private profileService: ProfileFeaturesService,
    private route: ActivatedRoute,
    private util: UtilServiceService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private modalService2: ModalServiceService,
    private perm : PermissionService,
    private settingService: SettingService,
    private profileService2 : ProfilesService,
    private rolesService : RolesService,
    private customerService : CustomersService,
    private skillsService : DynamicSkillsService,
    private errorUtil: ErrorUtil,
    public service: TimezonePickerService,
    private breadcrumbService: BreadcrumbService,
     private deleteRecordService: DeleteRecordService,
     private webSocket : WebSocketsService,
     private languageTranslateService: LanguageTranslateService,
   ) {
     super();

    this.breadcrumbService.setSetupItems([]);
    this.breadcrumbService.setItems([]);
    
    this.refreshUser = this.userService.refreshList$.subscribe((res: any) => {
      if (this.params.id !== this.auth.getUserId()) {
        this.loadUserProfile(this.params.id, "");
      } else {
        this.loadUserProfile(this.params.id, "myProfile");
      }
    });

    this.refreshPhoto = this.profileService2.refreshProfilePhoto$.subscribe(
      (res: any) => {
        if (this.params.id !== this.auth.getUserId()) {
          this.loadUserProfile(this.params.id, "");
        } else {
          this.loadUserProfile(this.params.id, "myProfile");
        }
      }
    );

    this.passwordForm = new UntypedFormGroup({
      'password': new UntypedFormControl(null, [Validators.required, Validators.pattern(AppComponent.passwordPattern)]),
      'cPassword': new UntypedFormControl(null, [Validators.required, Validators.pattern(AppComponent.passwordPattern)]),
    });
    this.tenantId = 'CU-'+(auth.getTenantId()+"").padStart(6,"0");

    this.googleCalendereRefresher=this.webSocket.refreshGCelenderMessages$.subscribe((res: any) => {
      // console.log(res);
      if(res.message == 'SUCCESS'){
        // console.log(res);
        this.errorUtil.setErrorMessage(200, this.translate?.setup.users.access.messages.calendarConnectedSuccessfully ,  null, 'success',3000);

         if(this.googleCalenderBtnClicked)
           this.googleCalenderConnected=true;
         else if(this.microsoftCalenderBtnClicked)
          this.microsoftCalenderConnected=true;
        else if(this.calendlyCalenderBtnClicked)
          this.calendlyCalenderConnected = true

         this.cdr.markForCheck();
      }else{
        // console.log(res);
        this.errorUtil.setErrorMessage(200, this.translate?.setup.users.access.messages.calendarConnectionNotSuccessful ,  null, 'success',3000);;
      }
    });

    this.userService.refreshGoogleCalender$.subscribe(res=>{
       console.log(res,'disconnected');

     if(this.googleCalenderBtnClicked)
           this.googleCalenderConnected=false;
     
      this.cdr.markForCheck();
    });
    /**************************** Calendly Connection *************************/
    this.userService.refreshMicrosoftCalender$.subscribe(res=>{
      console.log(res,'disconnected');
   if(this.microsoftCalenderBtnClicked){
         this.microsoftCalenderConnected=false;
   }else{
    this.microsoftCalenderConnected=true;
   }
    this.cdr.markForCheck();
   });
   /**************************** Calendly Connection *************************/
   this.userService.refreshCalendlyCalender$.subscribe(res=>{
    console.log(res,'disconnected');
   if(this.calendlyCalenderBtnClicked){
    this.calendlyCalenderConnected = false
   }else{
    this.calendlyCalenderConnected = true
   }
    this.cdr.markForCheck();
 });
    this.spinner.show();
  }
  

  ngOnInit(): void {
    this.loadTabs = userTabDetails
    this.languageList = languagePcklistData
    this.push(
      this.languageTranslateService.wordSrc$.subscribe(data => { 
        if(Object.keys(data).length != 0){
          
          this.translate = data
          this.loadTabs.forEach(element => {
            element.headerName =   this.languageTranslateService.getNestedValue(this.translate, element.translatePath)
            console.log(element.headerName)
            
           });
          this.init()
          // setTimeout(() => {
          //   this.loadBreadCrumb();
          // }, 600);
          //this.loadProfile(this.profileId);
          console.log(this.loadTabs);
          
        }
      })
    )

   
  }
  loadBreadCrumb(){
    if (this.params.id !== this.auth.getUserId()) {
        this.breadcrumbService.setSetupItems([
          { label: this.translate?.setup.users.users.label.plural , routerLink:["/dashboard/setup/allUser"], meta: {navigation: {root: true}}},
          { label: this.profile.firstName +" "+this.profile.lastName, meta: {navigation: {include: true}} }
      ]);
    }else{
      this.breadcrumbService.setSetupItems([
        { label:this.translate?.setup.users.users.label.plural, routerLink:["/dashboard/setup/allUser"], meta: {navigation: {root: true}}},
        { label: this.profile.firstName +" "+this.profile.lastName, meta: {navigation: {include: true}} }
       ]);
    }

    


  }

  init() {
    this.timezone= this.auth.getUserTimezone()
    this.update = this.perm.capable('manage_users', 'update');
    this.read = this.perm.capable('manage_users', 'update');

    // this.hierarchy = this.perm.capable('manage hierarchy', 'read');
    // this.access = this.perm.capable('manage access', 'read');
  

   

    
    this.userService.getAllUsers().subscribe(users => {
        this.users = users;
        this.users = _.remove(this.users, (o : any) => {
          return o.id != this.sharedVar.id;
        });
         this.users.forEach((element :any)=> {
          element.fullname = element.firstName+" "+ element.lastName
        });
       });
this.service.getZones().forEach((element: any) => {
      ////console.log(element)
        if(element.zones.length == 1){
          element.value= element.zones[0]
          element.label= this.service.iso2country(element.iso) +' '+ this.offsetOfTimezone(element.zones[0])
          this.timeZoneData.push(element)
      }
      if(element.zones.length > 1){
        //console.log(element.zones.length,"^^^^^",element.zones)
        for(var i =0 ; i<element.zones.length;i++){
          var labels=this.service.iso2country(element.iso) +' '+ this.formatTimezoneString(element.zones[i])+' '+ this.offsetOfTimezone(element.zones[i])
          //console.log(labels,"*******")
          element= {...element,label:labels,value:element.zones[i]}
          this.timeZoneData.push(element)
        }
        //console.log(element)
      }
     
    });
    this.skillsService.getAllDepartments().subscribe((res:any) => {
      this.departments = res;
    })

    this.skillsService.getAllCategories().subscribe((res:any) => {
      this.skillRoles = res;
    })

    this.settingService.getOrgSettings(1).subscribe((res:any)=>{
      ////console.log("Response ", res);
      this.orgSettings = res;
    });
    this.profileService2.getAllProfiles().subscribe(profiles => {
      this.profiles = profiles.filter((profile : any) => !((profile.name as string).toLowerCase().includes('driver')));
    });

    this.rolesService.getRoles().subscribe(roles => {
      this.roles = roles;
      ////console.log('Roles Api ', this.roles);
    });

    this.customerService.getDeport().subscribe((res: any) => {
      // //console.log('DEPOT', res);
      this.allDepots = res;
    });

    this.customerService.getGeofencePickList().subscribe((res:any) => {
      this.geofences = res;
    })
    var profileInfo = JSON.parse(this.auth.getProfileInfo());
    this.findMenuPermissions(profileInfo.tenantCategories);

    this.sharedVar = {};
   
    this.profile.homeLatitude = 3.100117;
    this.profile.homeLongitude = 101.59091899999999;
    this.profile.officeLatitude = 3.100117;
    this.profile.officeLongitude = 101.59091899999999;

    
    this.route.queryParams.subscribe((params: any) => {
      ////console.log(params,">>>>>>")
      if (params.id) {
        this.params = params;
        this.loadUserProfile(this.params.id, "");
        this.canConnectGoogleCalender=false;
      } else {
        this.params = { id: this.auth.getUserId() };
        this.canConnectGoogleCalender=true;
        this.loadUserProfile(this.params.id, "myProfile");
      }
      if (params.edit == "true" && !this.cancel) {
        this.openAll(true);
        this.disabled = false;
        this.cancel = false;
        this.editForm = true;
        

      }
    
    });
    

  }
  /************************************************** Menu Persmission *******************************************/
  findMenuPermissions(tenantCategories : any){
    var saleIndex = tenantCategories.indexOf("SALES");
    if(saleIndex != -1){
      this.salePer = true;
    }
    var serviceIndex = tenantCategories.indexOf("SERVICE");
    if(serviceIndex != -1){
      this.servicePer = true;
    }
    var fleetIndex = tenantCategories.indexOf("FLEET");
    if(fleetIndex != -1){
      this.fleetPer = true;
    }
  }
/************************************************** Time Zone Conversion *******************************************/
  formatTimezoneString(zone: any) {
    ////console.log(zone)
    const arr = zone.split('/');
    return arr[arr.length - 1].replace('_', ' ');
    
  }
  offsetOfTimezone(zone: string): string {
    let offset = moment.tz(zone).utcOffset();
    const neg = offset < 0;
    if (neg) {
      offset = -1 * offset;
    }
    const hours = Math.floor(offset / 60);
    const minutes = (offset / 60 - hours) * 60;
    return `(GMT${neg ? '-' : '+'}${this.rjust(
      hours.toString(),
      2
    )}:${this.rjust(minutes.toString(), 2)})`;
  }
  private rjust(string: string, width: number, padding = '0'): string {
    padding = padding || ' ';
    padding = padding.substr(0, 1);
    if (string.length < width) {
      return padding.repeat(width - string.length) + string;
    } else {
      return string;
    }
  }
/************************************************** Get Roles, Level *******************************************/
  getRolesByDepartment(dep : any){
    this.skillsService.getSkillCategoriesById(dep.id).subscribe((res:any) => {
      this.skillRoles = res;
      this.levels = [];
    })
  }

  getLevelsByRole(role : any){
    this.skillsService.getSkillLevelsByCategory(role.id).subscribe((res:any) => {
      this.levels = res;
    })
  }
/************************************************** Add and remove skill *******************************************/
  addSkill(){
    if(!this.selectedDepartment || !this.selectedRole || !this.selectedLevel){
      this.errorUtil.setErrorMessage(200, this.translate?.general.messages.selectAllFields ,  null, 'warn',1000);
      return;
    }

    var matchingDep = _.find(this.profile.userDynamicSkillList, (o) => {
      return o.skillType.id == this.selectedDepartment.id;
    });

    var matchingRole = _.find(this.profile.userDynamicSkillList, (o) => {
      return o.skillCategory.id == this.selectedRole.id;
    })

    var matchingLevel = _.find(this.profile.userDynamicSkillList, (o) => {
      return o.skillLevel.id == this.selectedLevel.id;
    })

    if(matchingDep && matchingRole && matchingLevel){
      this.errorUtil.setErrorMessage(200,  this.translate?.setup.users.users.messages.duplicateSkillAssignmentConflict,  null, 'warn',1000);;
      return
    }

    var string = '';
    var req = {skillType : null, skillCategory : null, skillLevel : null, label : ''};

    // if(this.selectedDepartment && this.selectedRole){
      string = `#${this.selectedDepartment.name}: ${this.selectedRole.name} - ${this.selectedLevel.name}`;
      req.skillType = this.selectedDepartment;
      req.skillCategory = this.selectedRole;
      req.skillLevel = this.selectedLevel;
  
    req.label = string;
    if(this.profile.userDynamicSkillList == null)
    {
      this.profile.userDynamicSkillList = []
    }
    this.profile.userDynamicSkillList.push(req);
    this.selectedDepartment = null;
    this.selectedRole = null;
    this.selectedLevel = null;
  }

  removeSkill(index : any){
    this.profile.userDynamicSkillList.splice(index, 1);
  }
  /************************************************** on change *******************************************/
  ngOnChanges(){
    if(this.profile){
      
      
      this.userService.getAllUsers().subscribe(users => {
        this.users = users;
        this.users = _.remove(this.users, (o : any) => {
          return o.id != this.sharedVar.id;
        });
        this.users.forEach((element :any)=> {
          element.fullname = element.firstName+" "+ element.lastName
        });
        this.cdr.detectChanges();
        ////console.log('Users Api ', this.users);
      });

      if(this.profile.geoFence){
        this.profile.geoFenceName = this.profile.geoFence.geoFenceName;
        this.profile.geoFenceId = this.profile.geoFence.id;
      }
      else
        this.sharedVar.geoFenceId = null;

      // if(this.sharedVar.id == this.auth.getUserId()){
      //   this.disableAccessOptions = true;
      // }
    }

   //// this.photoUrl = this.photo;

    // if(this.editForm){
    //   this.passwordForm.get('password').disable();
    //   this.passwordForm.get('cPassword').disable();
    // } else {
    //   this.passwordForm.get('password').enable();
    // }
    if(this.sharedVar){
      this.googleCalenderConnected=this.sharedVar.googleCalenderConnected;
      this.microsoftCalenderConnected=this.sharedVar.microsoftCalenderConnected;
      this.calendlyCalenderConnected=this.sharedVar.calendlyCalenderConnected;

    }

  }
  openAll(val: any) {
    this.isOpen = val;
    setTimeout(() => {
      this.util.refreshRightSideBarHeight();
    }, 1000);
  }
/************************************************** Get user profile *******************************************/
  loadUserProfile(id: any, type: any) {
    this.spinner.show();
    this.userService.getUserProfile(id).subscribe((profile: any) => {   
      this.profile = profile;
      this.loadBreadCrumb();
      if(this.profile.geoFence == null){
        this.profile.geoFence ={id : null}
      }

      this.SetHomeAddress(this.profile)
      this.SetOfficeAddress(this.profile)
      var homeAddress = {
        street_number: profile.homeStreet,
        locality: profile.homeCity,
        administrative_area_level_1: profile.homeState,
        country: profile.homeCountry,
        postal_code: profile.homePostcode,
      };
      var officeAddress = {
        street_number: profile.officeStreet,
        locality: profile.officeCity,
        administrative_area_level_1: profile.officeState,
        country: profile.officeCountry,
        postal_code: profile.officePostcode,
      };
      profile.homeAddress = homeAddress;
      profile.officeAddress = officeAddress;
      this.sharedVar = profile;

      if (profile.imageUrl && profile.imageUrl !== "") {
        this.photoUrl = profile.imageUrl;
      } else {
        this.photoUrl = "../../../../assets/svg/Avatar.svg";
      }

      if (this.saveProfileBoolean) {
        var homeAddressCheck = "";
        var officeAddressCheck = "";
        for (let key in profile) {
          if (
            key == "homeStreet" &&
            profile[key] !== null &&
            profile[key] !== ""
          ) {
            homeAddressCheck += profile[key] + ", ";
          }
          if (
            key == "homeCity" &&
            profile[key] !== null &&
            profile[key] !== ""
          ) {
            homeAddressCheck += profile[key] + ", ";
          }
          if (
            key == "homeState" &&
            profile[key] !== null &&
            profile[key] !== ""
          ) {
            homeAddressCheck += profile[key] + ", ";
          }
          if (
            key == "homePostcode" &&
            profile[key] !== null &&
            profile[key] !== ""
          ) {
            homeAddressCheck += profile[key] + ", ";
          }
          if (
            key == "homeCountry" &&
            profile[key] !== null &&
            profile[key] !== ""
          ) {
            homeAddressCheck += profile[key];
          }

          if (
            key == "officeStreet" &&
            profile[key] !== null &&
            profile[key] !== ""
          ) {
            officeAddressCheck += profile[key] + ", ";
          }
          if (
            key == "officeCity" &&
            profile[key] !== null &&
            profile[key] !== ""
          ) {
            officeAddressCheck += profile[key] + ", ";
          }
          if (
            key == "officeState" &&
            profile[key] !== null &&
            profile[key] !== ""
          ) {
            officeAddressCheck += profile[key] + ", ";
          }
          if (
            key == "officePostcode" &&
            profile[key] !== null &&
            profile[key] !== ""
          ) {
            officeAddressCheck += profile[key] + ", ";
          }
          if (
            key == "officeCountry" &&
            profile[key] !== null &&
            profile[key] !== ""
          ) {
            officeAddressCheck += profile[key];
          }
        }
        if (type == "myProfile") {
          if (officeAddressCheck != "") {
            var officeAdd = {
              address: officeAddressCheck,
              placeId: profile.officePlaceId,
              lat: profile.officeLatitude,
              lng: profile.officeLongitude,
            };
            localStorage.setItem("office_address", JSON.stringify(officeAdd));
          }
          if (homeAddressCheck != "") {
            var homeAdd = {
              address: homeAddressCheck,
              placeId: profile.homePlaceId,
              lat: profile.homeLatitude,
              lng: profile.homeLongitude,
            };
            localStorage.setItem("home_address", JSON.stringify(homeAdd));
          }
          localStorage.setItem(
            "buffer",
            JSON.stringify({
              start: profile.startBuffer,
              end: profile.endBuffer,
            })
          );
          localStorage.setItem("timezone", profile.timezone);
        }
        this.saveProfileBoolean = false;

        if (profile.imageUrl && profile.imageUrl !== "") {
          this.photoUrl = profile.imageUrl;
        } else {
          this.photoUrl = "../../../../assets/svg/Avatar.svg";
        }
      }
      this.spinner.hide();
      console.log(this.profile.userCalendar)
      
      // this.profile.userCalendar= {};
      if(this.profile.userCalendar != null){
      this.getCalendarData()
      }
      // default depot
      if (this.profile.defaultDepot === null) {
        this.profile.defaultDepot = { id: null };
      }
      this.googleCalenderConnected=this.profile.googleCalenderConnected;
      this.microsoftCalenderConnected=this.profile.microsoftCalenderConnected;
      this.calendlyCalenderConnected=this.profile.calendlyCalenderConnected;

      this.loadProfile(this.profile.profileId)

    
    });

  }
  /************************************************** Working Hours *******************************************/
  shiftBasedChange(value:any){
    this.profile.shiftWorking = value
  //this.profile.shiftWorking = value == true ? 1 : 0;
  if( value == 1){
    var calendar = new LoadingBayModel()
     calendar = {monday:false,tuesday:false,wednesday:false,thursday:false,friday:false,saturday:false,sunday:false,
      mondayAllDay:false,tuesdayAllDay:false,wednesdayAllDay:false,thursdayAllDay:false,fridayAllDay:false,saturdayAllDay:false,sundayAllDay:false,
      mondayStart:null,mondayEnd:null,tuesdayStart:null,tuesdayEnd:null,wednesdayStart:null,wednesdayEnd:null,
      thursdayStart:null,thursdayEnd:null,fridayStart:null,fridayEnd:null,saturdayStart:null,saturdayEnd:null,
      sundayStart:null,sundayEnd:null,
      };
      this.profile.calendar = calendar;
    this.profile.userCalendar = calendar
  }else{
    this.profile.calendar = null;
    this.profile.userCalendar = null
  }
  this.detectIsUserCalenderUpdated()
  }
  /************************************************** Working hours calander *******************************************/
  getCalendarData(){
    var calendar = new LoadingBayModel()
     calendar = {monday:false,tuesday:false,wednesday:false,thursday:false,friday:false,saturday:false,sunday:false,
      mondayAllDay:false,tuesdayAllDay:false,wednesdayAllDay:false,thursdayAllDay:false,fridayAllDay:false,saturdayAllDay:false,sundayAllDay:false,
      mondayStart:null,mondayEnd:null,tuesdayStart:null,tuesdayEnd:null,wednesdayStart:null,wednesdayEnd:null,
      thursdayStart:null,thursdayEnd:null,fridayStart:null,fridayEnd:null,saturdayStart:null,saturdayEnd:null,
      sundayStart:null,sundayEnd:null,
      };
    for(var j=0;j<=this.profile.userCalendar.length-1;j++){
      console.log(this.profile.userCalendar[j])
      var startWorkHour: any = null
      var endWorkHour = null
      if(this.profile.userCalendar[j].startWorkHour && this.profile.userCalendar[j].endWorkHour){
          var startMomentTime = moment(moment().format('MM-DD-YYYY') +' '+ this.profile.userCalendar[j].startWorkHour);
          var startMoment2 = moment.tz(startMomentTime, this.auth.getUserTimezone());
           startWorkHour = new Date(startMoment2.toDate());
        
          var pickUpstartWorkHour = moment(moment().format('MM-DD-YYYY') +' '+ this.profile.userCalendar[j].endWorkHour);
          var endMoment2 = moment.tz(pickUpstartWorkHour, this.auth.getUserTimezone());
           endWorkHour = new Date(pickUpstartWorkHour.toDate());
      }
     
      console.log(this.profile.userCalendar[j].startWorkHour,"==========>>>>",this.profile.userCalendar[j].endWorkHour)
       //console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^")

      if(this.profile.userCalendar[j].workDay=== "MONDAY"){
       
        calendar.mondayStart = startWorkHour  ;
        calendar.mondayEnd  = endWorkHour;
        if(!calendar.mondayStart || !calendar.mondayEnd){
          calendar.mondayAllDay = true;
        }
        calendar.monday = true;
      }else if(this.profile.userCalendar[j].workDay=== "TUESDAY"){
        calendar.tuesdayStart  = startWorkHour;
        calendar.tuesdayEnd = endWorkHour;
        if(!calendar.tuesdayStart || !calendar.tuesdayEnd){
          calendar.tuesdayAllDay = true;
        }
        calendar.tuesday = true;
      }else if(this.profile.userCalendar[j].workDay=== "WEDNESDAY"){
        calendar.wednesdayStart = startWorkHour;
        calendar.wednesdayEnd = endWorkHour;
        if(!calendar.wednesdayStart || !calendar.wednesdayEnd){
          calendar.wednesdayAllDay = true;
        }
        calendar.wednesday = true;
      }else if(this.profile.userCalendar[j].workDay=== "THURSDAY"){
        calendar.thursdayStart = startWorkHour;
        calendar.thursdayEnd = endWorkHour;
        if(!calendar.thursdayStart || !calendar.thursdayEnd){
          calendar.thursdayAllDay = true;
        }
        calendar.thursday = true;
      }else if(this.profile.userCalendar[j].workDay=== "FRIDAY"){
        calendar.fridayStart = startWorkHour;
        calendar.fridayEnd = endWorkHour;
        if(!calendar.fridayStart || !calendar.fridayEnd){
          calendar.fridayAllDay = true;
        }
        calendar.friday = true;
      }else if(this.profile.userCalendar[j].workDay=== "SATURDAY"){
        calendar.saturdayStart = startWorkHour;
        calendar.saturdayEnd = endWorkHour;
        if(!calendar.saturdayStart || !calendar.saturdayEnd){
          calendar.saturdayAllDay = true;
        }
        calendar.saturday = true;
      }else if(this.profile.userCalendar[j].workDay=== "SUNDAY"){
        calendar.sundayStart = startWorkHour;
        calendar.sundayEnd = endWorkHour;
        if(!calendar.sundayStart || !calendar.sundayEnd){
          calendar.sundayAllDay = true;
        }
        calendar.sunday = true;
      }
    }
    //console.log(calendar,"*******")
    this.profile.calendar = calendar;
    this.profile.userCalendar = calendar
    
  }
  nullify(day: any, val: any){
    // var startMoment = moment("09:00").format("hh:mm a");
    // var endMoment = moment("17:00").format("hh:mm a");

    var startMomentTime = moment(moment().format('MM-DD-YYYY') + ' 09:00');
    var startMoment2 = moment.tz(startMomentTime, this.auth.getUserTimezone());
    var startMoment = new Date(startMoment2.toDate());
  
    var pickUpstartWorkHour = moment(moment().format('MM-DD-YYYY') + ' 17:00');
    var endMoment2 = moment.tz(pickUpstartWorkHour, this.auth.getUserTimezone());
    var endMoment = new Date(endMoment2.toDate());


    if(val){
      this.profile.calendar[day+"Start"] = null;
      this.profile.calendar[day+"End"] = null;
    } else {
      this.profile.calendar[day+"Start"] = startMoment;
      this.profile.calendar[day+"End"] = endMoment;
    }
  }
  selectWorkingDays(workDay : any, val: any){
    // var startMoment = moment("09:00").format("hh:mm a");
    // var endMoment = moment( "17:00").format("hh:mm a");

    var startMomentTime = moment(moment().format('MM-DD-YYYY') + ' 09:00');
    var startMoment2 = moment.tz(startMomentTime, this.auth.getUserTimezone());
    var startMoment = new Date(startMoment2.toDate());
  
    var pickUpstartWorkHour = moment(moment().format('MM-DD-YYYY') + ' 17:00');
    var endMoment2 = moment.tz(pickUpstartWorkHour, this.auth.getUserTimezone());
    var endMoment = new Date(endMoment2.toDate());
    if(workDay == 'monday'){
      this.profile.calendar.monday = val;
      if(val){
        this.profile.calendar.mondayStart = startMoment;
        this.profile.calendar.mondayEnd = endMoment;
      } else {
        this.profile.calendar.mondayStart = null;
        this.profile.calendar.mondayEnd = null;
        this.profile.calendar.mondayAllDay= false;
      }
    } else if(workDay == 'tuesday'){
      this.profile.calendar.tuesday = val;
      if(val){
        this.profile.calendar.tuesdayStart = startMoment;
        this.profile.calendar.tuesdayEnd = endMoment;
      } else {
        this.profile.calendar.tuesdayStart = null;
        this.profile.calendar.tuesdayEnd = null;
        this.profile.calendar.tuesdayAllDay= false;
      }
    } else if(workDay == 'wednesday'){
      this.profile.calendar.wednesday = val;
      if(val){
        this.profile.calendar.wednesdayStart = startMoment;
        this.profile.calendar.wednesdayEnd = endMoment;
      } else {
        this.profile.calendar.wednesdayStart = null;
        this.profile.calendar.wednesdayEnd = null;
        this.profile.calendar.wednesdayAllDay= false;
      }
    } else if(workDay == 'thursday'){
      this.profile.calendar.thursday = val;
      if(val){
        this.profile.calendar.thursdayStart = startMoment;
        this.profile.calendar.thursdayEnd = endMoment;
      } else {
        this.profile.calendar.thursdayStart = null;
        this.profile.calendar.thursdayEnd = null;
        this.profile.calendar.thursdayAllDay= false;
      }
    } else if(workDay == 'friday'){
      this.profile.calendar.friday = val;
      if(val){
        this.profile.calendar.fridayStart = startMoment;
        this.profile.calendar.fridayEnd = endMoment;
      } else {
        this.profile.calendar.fridayStart = null;
        this.profile.calendar.fridayEnd = null;
        this.profile.calendar.fridayAllDay= false;
      }
    } else if(workDay == 'saturday'){
      this.profile.calendar.saturday = val;
      if(val){
        this.profile.calendar.saturdayStart = startMoment;
        this.profile.calendar.saturdayEnd = endMoment;
      } else {
        this.profile.calendar.saturdayStart = null;
        this.profile.calendar.saturdayEnd = null;
        this.profile.calendar.saturdayAllDay= false;
      }
    } else if(workDay == 'sunday'){
      //console.log(val)
      this.profile.calendar.sunday = val;
      if(val){
        this.profile.calendar.sundayStart = startMoment;
        this.profile.calendar.sundayEnd = endMoment;
      } else {
        this.profile.calendar.sundayStart = null;
        this.profile.calendar.sundayEnd = null;
        this.profile.calendar.sundayAllDay= false;
      }
    }
  }
  
/************************************************** Get and set Address *******************************************/

  gotSelectedAddress(data : any){
    this.profile.homeStreet = data.street;
    this.profile.homeCity = data.city;
    this.profile.homeState = data.state;
    this.profile.homePostcode = data.postCode;
    this.profile.homeCountry = data.country;
    this.profile.homeLatitude = data.locationLat;
    this.profile.homeLongitude = data.locationLong;
    this.profile.homePlaceId = data.locationLat;
    //this.locationLong = data.locationLong;
  }
  SetHomeAddress(data : any){
    var newData ={
    street :this.profile.homeStreet ? this.profile.homeStreet:null,
    city : this.profile.homeCity,
    state : this.profile.homeState,
    postCode : this.profile.homePostcode ,
    country : this.profile.homeCountry,
    locationLat : this.profile.homeLatitude,
    locationLong : this.profile.homeLongitude
    }
    this.homeData = newData
    //console.log(this.homeData)
   
  }
  gotSelectedOfficeAddress(data : any){
    this.profile.officeStreet = data.street;
    this.profile.officeCity = data.city;
    this.profile.officeState = data.state;
    this.profile.officePostcode = data.postCode;
    this.profile.officeCountry = data.country;
    this.profile.officeLatitude = data.locationLat;
    this.profile.officeLongitude = data.locationLong;
    this.profile.officePlaceId = data.locationLat;
    //this.locationLong = data.locationLong;
  }
  SetOfficeAddress(data : any){
    var newData ={
    street :this.profile.officeStreet ? this.profile.officeStreet:null,
    city : this.profile.officeCity,
    state : this.profile.officeState,
    postCode : this.profile.officePostcode ,
    country : this.profile.officeCountry,
    locationLat : this.profile.officeLatitude,
    locationLong : this.profile.officeLongitude
    }
    this.officeData = newData
    //console.log(this.officeData)
   
  }
  /************************************************** Mandatory Fields *******************************************/
   mandatoryFieldCheck(): boolean {
    let isValid = true;

        // alert to user even if BE doesnt specifically say it is mandatory
        if (this.profile.profileId === null) {
          this.errorUtil.setErrorMessage(200,this.translate?.setup.users.access.messages.selectAccessPermission  ,  null, 'error',3000);;
          isValid = false;
        }
    
        if (this.profile.roleId === null) {
          this.errorUtil.setErrorMessage(200, this.translate?.setup.users.access.messages.selectHierarchy ,  null, 'error',3000);;
          isValid = false;
        }

        if (this.profile.dateFormatString === null) {
          this.errorUtil.setErrorMessage(200, this.translate?.setup.users.access.messages.selectDateFormat,  null, 'error',3000);;
          isValid = false;
        }

        if (this.profile.defaultDashboard === null) {
          this.errorUtil.setErrorMessage(200, this.translate?.setup.users.access.messages.selectDefaultMobileView,  null, 'error',3000);;
          isValid = false;
        }


        if (!isValid) {
          this.spinner.hide();
        }

        return isValid;
  }
  /************************************************** Resend Email *******************************************/
  public resendEmail() {
    this.modalService2.openModal("resendActivationEmailUser", this.profile);
  }
  openModal(modalView : any, data: any){
    this.modalService2.openModal(modalView, data);
  }
/************************************************** Resend Password *******************************************/
  public resetPassword() {
    console.log(this.profile);
    if (this.profile.id == this.auth.getUserId()) {
      this.modalService2.openModal("resetPassword", this.profile);
    } else {
      this.modalService2.openModal( "confirmResetPassword", this.profile );
    }
  }

/************************************************** Cancel Edit *******************************************/
  clickEdit(){
    this.editForm = !this.editForm;
    if(!this.editForm){
      if (this.params.id) {
        this.loadUserProfile(this.params.id, "");
      } else {
        this.params = { id: this.auth.getUserId() };
        this.loadUserProfile(this.params.id, "myProfile");
      }
    }
  }
  
  /************************************************** Save Profile *******************************************/
  saveProfile() {
    this.spinner.show();

    if (!this.mandatoryFieldCheck()) {
      return;
    }
    console.log("Continur submit") 
          
        
     var userCalendar = [];
     if(this.profile.shiftWorking == '1'){
    if(this.profile.userCalendar.monday && ((this.profile.userCalendar.mondayStart && this.profile.userCalendar.mondayEnd) || this.profile.userCalendar.mondayAllDay)){
      var temp = {
        startWorkHour:  this.profile.userCalendar.mondayStart == null ? null: moment(this.profile.userCalendar.mondayStart).format("HH:mm") ,
        endWorkHour :  this.profile.userCalendar.mondayEnd == null ? null:   moment(this.profile.userCalendar.mondayEnd).format("HH:mm"),
        allDay : this.profile.userCalendar.mondayAllDay,
        workDay : "MONDAY"
      }
      //console.log(temp)
      
      userCalendar.push(temp);
    } 
    if(this.profile.userCalendar.tuesday && ((this.profile.userCalendar.tuesdayStart && this.profile.userCalendar.tuesdayEnd) || this.profile.userCalendar.tuesdayAllDay)){
      var temp = {
        startWorkHour:  this.profile.userCalendar.tuesdayStart == null ? null:moment( this.profile.userCalendar.tuesdayStart).format("HH:mm"),
        endWorkHour :  this.profile.userCalendar.tuesdayEnd == null ? null:moment( this.profile.userCalendar.tuesdayEnd).format("HH:mm"),
        allDay : this.profile.userCalendar.tuesdayAllDay,
        workDay : "TUESDAY"
      }
      userCalendar.push(temp);
    } 
    if(this.profile.userCalendar.wednesday && ((this.profile.userCalendar.wednesdayStart && this.profile.userCalendar.wednesdayEnd) || this.profile.userCalendar.wednesdayAllDay)){
      var temp = {
        startWorkHour: this.profile.userCalendar.wednesdayStart == null ? null: moment( this.profile.userCalendar.wednesdayStart).format("HH:mm"),
        endWorkHour :  this.profile.userCalendar.wednesdayEnd == null ? null: moment( this.profile.userCalendar.wednesdayEnd).format("HH:mm"),
        allDay : this.profile.userCalendar.wednesdayAllDay,
        workDay : "WEDNESDAY"
      }
      userCalendar.push(temp);
    } 
    if(this.profile.userCalendar.thursday && ((this.profile.userCalendar.thursdayStart && this.profile.userCalendar.thursdayEnd) || this.profile.userCalendar.thursdayAllDay)){
      var temp = {
        startWorkHour:  this.profile.userCalendar.thursdayStart == null ? null: moment( this.profile.userCalendar.thursdayStart).format("HH:mm"),
        endWorkHour :  this.profile.userCalendar.thursdayEnd == null ? null: moment( this.profile.userCalendar.thursdayEnd).format("HH:mm"),
        allDay : this.profile.userCalendar.thursdayAllDay,
        workDay : "THURSDAY"
      }
      userCalendar.push(temp);
    } 
    if(this.profile.userCalendar.friday && ((this.profile.userCalendar.fridayStart && this.profile.userCalendar.fridayEnd) || this.profile.userCalendar.fridayAllDay)){
      var temp = {
        startWorkHour: this.profile.userCalendar.fridayStart == null ? null:  moment( this.profile.userCalendar.fridayStart).format("HH:mm") ,
        endWorkHour :  this.profile.userCalendar.fridayEnd == null ? null: moment( this.profile.userCalendar.fridayEnd).format("HH:mm"),
        allDay : this.profile.userCalendar.fridayAllDay,
        workDay : "FRIDAY"
      }
      userCalendar.push(temp);
    } 
    if(this.profile.userCalendar.saturday && ((this.profile.userCalendar.saturdayStart && this.profile.userCalendar.saturdayEnd) || this.profile.userCalendar.saturdayAllDay)){
      var temp = {
        startWorkHour: this.profile.userCalendar.saturdayStart == null ? null: moment( this.profile.userCalendar.saturdayStart).format("HH:mm"),
        endWorkHour :  this.profile.userCalendar.saturdayEnd == null ? null: moment( this.profile.userCalendar.saturdayEnd).format("HH:mm"),
        allDay : this.profile.userCalendar.saturdayAllDay,
        workDay : "SATURDAY"
      }
      userCalendar.push(temp);
    } 
    if(this.profile.userCalendar.sunday && ((this.profile.userCalendar.sundayStart && this.profile.userCalendar.sundayEnd) || this.profile.userCalendar.sundayAllDay)){
      var temp = {
        startWorkHour: this.profile.userCalendar.sundayStart == null ? null:  moment( this.profile.userCalendar.sundayStart).format("HH:mm"),
        endWorkHour :   this.profile.userCalendar.sundayEnd == null ? null: moment( this.profile.userCalendar.sundayEnd).format("HH:mm"),
        allDay : this.profile.userCalendar.sundayAllDay,
        workDay : "SUNDAY"
      }
      userCalendar.push(temp);
    }
  }
    //console.log(userCalendar)
    // for(var j=0;j<=userCalendar.length-1; j++){
    //   if(parseFloat(userCalendar[j].startTime)>parseFloat(userCalendar[j].endTime)){
    //     this.errorUtil.setErrorMessage('400', null , (userCalendar[j].day).toLowerCase()+' end time should be greater than start time', 'warn',3000);
    //     return;
    //   }
    // }
    this.spinner.show();
        console.log(this.profile)
        //console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%")
  


   
    if (this.profile.superVisorId == "") {
      this.profile.superVisorId = null;
    }
    
if(this.profile.userDynamicSkillList){
    var tempSkills = this.profile.userDynamicSkillList;
    tempSkills.forEach((element : any) => {
      delete element.label;
    });
  }
    var formData: any = {
      id: this.profile.id,
      firstName: this.profile.firstName,
      lastName: this.profile.lastName,
      email: this.profile.email,
      phone: this.profile.phone,
      language: this.profile.language,
      skill: this.profile.skill,
      homeStreet: this.profile.homeStreet,
      homeCity: this.profile.homeCity,
      homeState: this.profile.homeState,
      homePostcode: this.profile.homePostcode,
      homeCountry: this.profile.homeCountry,
      homeLatitude: this.profile.homeLatitude,
      homeLongitude: this.profile.homeLongitude,
      homePlaceId: this.profile.homePlaceId,
      officeStreet: this.profile.officeStreet,
      officeCity: this.profile.officeCity,
      officeState: this.profile.officeState,
      officePostcode: this.profile.officePostcode,
      officeCountry: this.profile.officeCountry,
      officeLatitude: this.profile.officeLatitude,
      officeLongitude: this.profile.officeLongitude,
      officePlaceId: this.profile.officePlaceId,
      hasEmailNotification: this.profile.hasEmailNotification,
      hasSmsNotification: this.profile.hasSmsNotification,
      allDays: false,
      userCalendar: userCalendar,
      timezone: this.profile.timezone,
      startBuffer: this.profile.startBuffer,
      endBuffer: this.profile.endBuffer,
      superVisorId: this.profile.superVisorId,
      roleId: this.profile.roleId,
      profileId: this.profile.profileId,
      dateFormatString: this.profile.dateFormatString,
      userDynamicSkillList: tempSkills,
      alertForTaskTime: this.profile.alertForTaskTime,
      isTaskAlert: this.profile.isTaskAlert,
      shiftWorking: this.profile.shiftWorking,
      // userType: !this.profile.userType.id ? null : this.profile.userType,
      maxShiftHousrsPerDay: this.profile.maxShiftHousrsPerDay,
      maxShiftHourType: this.profile.maxShiftHourType,
      defaultDashboard: this.profile.defaultDashboard,
      employeeNo: this.profile.employeeNo,
      defaultDepot: this.profile.defaultDepot,
      isUserCalenderUpdated: this.profile.isUserCalenderUpdated
    };

    if (this.profile.geoFence.id) {
      formData.geoFence = { id: this.profile.geoFence.id };
    } else {
      formData.geoFence = null;
    }

    if (this.profile.defaultDepot && this.profile.defaultDepot.hasOwnProperty('id') && this.profile.defaultDepot.id !== null) {
      formData.defaultDepot = { id: this.profile.defaultDepot.id };
    } else {
      formData.defaultDepot = null;
    }

    this.userService
      .editUserProfile(formData, this.profile.id)
      .subscribe((res: any) => {
        if (res.status === 200 || res.status === 201) {
         this.errorUtil.setErrorMessage(200,  this.translate?.general.messages.updatedSuccessfully ,  null, 'success',1000);
          this.saveProfileBoolean = true;
          this.editForm = false;
          localStorage.setItem("shiftWorking", res.body.shiftWorking);
          if (this.profile.id == this.auth.getUserId())
            localStorage.setItem("date_format", res.body.dateFormatString);
        } else {
          this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);;
        }
        this.spinner.hide();
      });
  }
  ngOnDestroy() {
    this.refreshPhoto.unsubscribe();
    this.refreshUser.unsubscribe();
    super.ngOnDestroy();
  }
  openModal3(modalView : any, data: any){
    if(modalView === 'activateUser'){
      this.profile.deleteType='Activate User Profile'
      this.profile.deleteHeader = this.translate?.setup.users.users.messages.activateUserProfile
      this.profile.deleteIconType='confirm'
      this.profile.message= this.translate?.contacts.messages.confirmActivateUser
      console.log( this.profile)
      this.deleteRecordService.getItems(this.profile); 
  
    }else if(modalView === 'deactivateUser'){
        this.profile.deleteType='Deactivate User Profile'
        this.profile.deleteHeader = this.translate?.setup.users.users.messages.deactivateUserProfile
        this.profile.deleteIconType='confirm'
        this.profile.message= this.translate?.contacts.messages.confirmDeactivateUser
        console.log( this.profile)
        this.deleteRecordService.getItems(this.profile); 
    
    }else  if(modalView === 'suspendUser'){
      this.profile.deleteType='Suspend User Profile'
      this.profile.deleteHeader = this.translate?.setup.users.users.messages.suspendUserProfile
      this.profile.deleteIconType='confirm'
      this.profile.message=this.translate?.contacts.messages.confirmSuspendUser
      console.log( this.profile)
      this.deleteRecordService.getItems(this.profile); 
  
  }else if(modalView === 'removePhoto'){
    this.profile.deleteType='Profile picture'
    this.profile.deleteHeader = this.translate?.setup.users.users.messages.profilePicture
    console.log( this.profile)
    this.deleteRecordService.getItems(this.profile); 

  }
}

public navigateTo(where: string, value: any) {
  if (where === 'access') {
    this.router.navigate(['/dashboard/setup/profiles/view/' + value], {queryParams: {id: value}});
  }
}
/******************************* Connect And Disconnect Google *****************************/
connectGoogleCalender(){
  // console.log('connecting to google cal....');
  this.googleCalenderBtnClicked=true;
  this.microsoftCalenderBtnClicked=false;
  this.calendlyCalenderBtnClicked=false;
  this.userService.getGoogleCalenderConnectionUrl().subscribe(res=>{
      // console.log(res);
      window.open (res.webRedirectUrl,"google-calender","width=600,height=600,scrollbars=no,resizable=no,toolbar=no,location=no,directories=no,status=no,menubar=no,copyhistory=no,");
  });

}

disConnectGoogleCalender(){
  this.googleCalenderBtnClicked=true;
  this.microsoftCalenderBtnClicked=false;
  this.calendlyCalenderBtnClicked=false;
  this.modalService2.openModal('DisconnectGoogleCalenderConfmationComponent', '');
}
/******************************* Connect And Disconnect Microsoft *****************************/

connectMicrosoftCalender(){
  // console.log('connecting to Microsoft cal....');
  this.microsoftCalenderBtnClicked=true;
  this.googleCalenderBtnClicked=false;
  this.calendlyCalenderBtnClicked=false;
  this.userService.getMicrosoftCalenderConnectionUrl().subscribe(res=>{
      // console.log(res);
      window.open (res.webRedirectUrl,"Microsoft-calender","width=600,height=600,scrollbars=no,resizable=no,toolbar=no,location=no,directories=no,status=no,menubar=no,copyhistory=no,");
  });

}

disConnectMicrosoftCalender(){
  this.microsoftCalenderBtnClicked=true;
  this.googleCalenderBtnClicked=false;
  this.calendlyCalenderBtnClicked=false;
  this.modalService2.openModal('DisconnectMicrosoftCalenderConfmationComponent', '');
}

/******************************* Connect And Disconnect calendly *****************************/

connectCalendlyCalender(){
   console.log('connecting to Calendly cal....');
  this.calendlyCalenderBtnClicked=true;
  this.googleCalenderBtnClicked=false;
  this.microsoftCalenderBtnClicked=false;
  this.userService.getCalendlyCalenderConnectionUrl().subscribe(res=>{
      // console.log(res);
      window.open (res.webRedirectUrl,"Microsoft-calender","width=600,height=600,scrollbars=no,resizable=no,toolbar=no,location=no,directories=no,status=no,menubar=no,copyhistory=no,");
  });

}

disConnectCalendlyCalender(){
  this.calendlyCalenderBtnClicked=true;
  this.googleCalenderBtnClicked=false;
  this.microsoftCalenderBtnClicked=false;
  this.modalService2.openModal('DisconnectCalendlyConfmationComponent', '');
}

  /******************************************** Load Profile ******************************************/
  private loadProfile(id: any) {

      this.profileService2.getProfileById(id).subscribe((profileData: any) => {
        console.log("profile", profileData);
this.profileData = profileData
        profileData.profileIntegrationAccessList.forEach((element: any) => {
          if(element.tenantIntegrationConfig.integrationType == "GOOGLE_CALENDAR") {
            this.googleEnable  = element
          }
          if(element.tenantIntegrationConfig.integrationType  == "MICROSOFT_OUTLOOK"){ 
            this.microsoftEnable  = element
          }
          if(element.tenantIntegrationConfig.integrationType  == "CALENDLY"){
             this.calendlyEnable  = element
             console.log(this.calendlyEnable)
            }
         
    
      });
      if (!this.profile.defaultDashboard && this.defaultDashboards.length > 0) {
        this.profile.defaultDashboard = this.defaultDashboards[0].value;
      }

        this.spinner.hide();
      })
    
  }
  onClickIntegrations()
  {
    this.router.navigate(['dashboard/setup/integration/config']);
  }

  ngAfterViewInit() {
    
    console.log(this.tabView);
    this.activeIndexAccess =
      localStorage.getItem("activeIndexAccess") == undefined ? 0 : localStorage.getItem("activeIndexAccess");
      setTimeout(() => {       
        this.loafData(this.activeIndexAccess, this.tabView);
      }, 2000);
   
   // this.cdr.detectChanges();
  }
  loafData(e: any, tabView: TabView) {
    const headerValue = this.tabView.tabs[e].header;
    console.log(headerValue, "===",e);
    debugger
    this.setTabHeader(headerValue);
    
  }
  // handleChange(e: any, tabView: TabView) {
  //   localStorage.setItem("activeIndexAccess", e.index);
  //   this.activeIndexAccess = e.index;
  //   const headerValue = tabView.tabs[e.index].header;
  //   console.log(headerValue, "====", this.activeIndexAccess, "===", e.index);
  //   this.setTabHeader(headerValue);
  // }

  handleChange(e: any, tabView: TabView) {
    console.log(e, "handle CHnge", e.index)
    let changedTabIndex;
    if (e.index >= 0) {
      changedTabIndex = e.index
    }
    else {
      changedTabIndex = e
    }
    const currentTabHeader = this.tabView.tabs[changedTabIndex].header; // Access untranslated header
    localStorage.setItem("activeIndexAccess", changedTabIndex);
    console.log(changedTabIndex, "====", currentTabHeader);
   
    this.activeIndexAccess = changedTabIndex;
    const tabHeader = currentTabHeader
   console.log(tabHeader, "====", this.activeIndexAccess);
    this.setTabHeader(tabHeader);
    
  }

  setTabHeader(headerValue: any) {
    this.getLanguageForMenu()
    var filteredModule = this.util.setTabHeaderData(headerValue , this.tabMenu, this.loadTabs, null);
    console.log(this.loadTabs,"Match=====,",filteredModule,"====================",this.tabMenu)
    
    // get Tab header name and comapare with the module name
    this.loadTabs.forEach(element => {
      if(filteredModule.plural == element.headerName || filteredModule.details == element.headerName || filteredModule.history == element.headerName || filteredModule.selectedSubTab == element.headerName){ element.highlight= true }
    });
   // this.handleEditVisibility()
    console.log(filteredModule)
    
  }


  getLanguageForMenu() {
   
    var data: any = new LanguageDataModelForTabs(this.translate);
    this.tabMenu = [...data.moduleData];
      _.remove(this.tabMenu, (n: any) => n == undefined);
     console.log(this.tabMenu);
   
  }


  detectIsUserCalenderUpdated(){
    this.profile.isUserCalenderUpdated = true

  }


  getLanguageData() {
    // this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
    //   if(Object.keys(data).length != 0){
    //     this.translate = data
    //     this.loadBreadCrumb()
    //   }
    // }))
    // console.log("translate",this.translate)
  }
  
  // hasNestedKey<T extends object>(obj: T, keyChain: string): boolean {
  //   const keys = keyChain.split('.');
  //   let currentObject: any = obj;
  
  //   for (const key of keys) {
  //     if (currentObject === undefined || currentObject === null) {
  //       return false; // Early exit if any level is undefined or null
  //     }
  //     currentObject = currentObject[key]; // Move to the next level of the object
  //   }
  
  //   return typeof currentObject !== 'undefined'; // Check if the final value exists
  // }

  push(obs:any) {
    super.push(obs);
  }
  resetHighlights() {
    const tabDetailsArrays = [
      userTabDetails
    ];
  
    tabDetailsArrays.forEach(array => {
      array.forEach(tab => {
        tab.highlight = false;
      });
    });
  }
}
