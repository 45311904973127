import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  ColDef,
  GetContextMenuItemsParams,
  GridOptions,
  IServerSideGetRowsParams,
  MenuItemDef,
} from "ag-grid-community";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import {
  PERSISTANT_ITEM,
  ServerRequest,
} from "src/app/_models/global.data.model";
import { AssetService } from "src/app/_services/asset.service";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { DynamicFormService } from "src/app/_services/dynamic-form.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

var dateFormateChange: any;
import * as moment from "moment";
import { PermissionService } from "src/app/auth/permission.service";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { AssetStatusRendererComponent } from "src/app/renderer/asset-status-renderer.component";
import { DynamicLabelsService } from "src/app/_services/dynamic-labels.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";

@Component({
  selector: "app-assets-table",
  templateUrl: "./assets-table.component.html",
  styleUrls: ["./assets-table.component.scss"],
})
export class AssetsTableComponent
  extends SubscriptionUtil
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('myCalendar', { static: false })
  private myCalendar: any;

  @Input() searchValue: any;
  @Output() noData = new EventEmitter();
  @Input() data: any;
  @Output() isFilterClosed = new EventEmitter();
  @Input() accesFrom: any;
  public noDataFound = false;
  public filterActive = false;
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any = [];
  defaultColDef: any;
  frameworkComponents: any;
  gridParams: any;
  startFrom: any;
  product: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions: Partial<GridOptions>;
  overlayNoRowsTemplate: string;
  assets: any;
  actionData: any;
  totalRecord: any;
  currentRecordNumber: any;
  refresher!: Subscription;
  dateFormat: any;
  dateRange!: any;
  types: any;
  statuses: any;
  filter: any = {
    assetStatuses: null,
    assetTypes: null,
    fromDate: null,
    toDate: null,
    serviceZoneName: null,
  };
  private ids = {
    assetId: null,
    customerId: null,
    siteId: null,
  };
  private filterAPILoaded = false;
  private loadTable = 0;
  sortBy: any;
  sortOrder: any;
  statusesAsset: any[] = [];
  @Input() translate: any;
  tableContent: any;

  constructor(
    private assetService: AssetService,
    private auth: AuthenticationService,
    private dynamicFormService: DynamicFormService,
    private router: Router,
    private errorUtil: ErrorUtil,
    private spinner: NgxSpinnerService,
    private deleteRecordService: DeleteRecordService,
    private util: UtilServiceService,
    private perm: PermissionService,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();


    this.push(
      this.assetService.getStatuses().subscribe((res: any) => {
        console.log(res)
        this.statuses = res.body.sort((elementA: any, elementB: any) => {
          if (elementA?.order > elementB?.order) {
            return 1;
          }

          if (elementA?.order < elementB?.order) {
            return -1;
          }

          return 0;
        });

        res.body.forEach((element: any) => {
          this.statusesAsset.push(element);
        });

        localStorage.setItem("assetStatuses", JSON.stringify(this.statuses));
      })
    );

    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      statusRenderer: AssetStatusRendererComponent,
    };

    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.defaultColDef = {
      enableRowGroup: false, //ag-Grid-Enterprise
      enablePivot: false, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };


  }

  ngOnInit() {
    this.preInit();

  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.searchValue?.previousValue !==
      changes.searchValue?.currentValue &&
      changes.searchValue?.currentValue !== ""
    ) {
      this.onGridReady(this.gridParams);
    }

    if (
      changes.searchValue?.previousValue !==
      changes.searchValue?.currentValue &&
      changes.searchValue?.currentValue === ""
    ) {
      this.onGridReady(this.gridParams);
    }
    /************************** Check translate object changed or not:  Language Translated Dynamically (Preeti) ************************************/
    if (
      changes &&
      changes.translate && changes.translate.previousValue != undefined && Object.keys(changes.translate.previousValue).length !== 0 &&
      changes.translate.previousValue !== changes.translate.currentValue
    ) {
      this.setOnlyColumn();
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  /********************************** PreInit *****************************/
  private preInit() {

    this.dateFormat = localStorage.getItem("date_format");
    dateFormateChange = this.auth.getUserTimezone();

    this.dynamicFormService.getTableColumn("assets").subscribe((res: any) => {
      this.sortBy = res.sortBy
      this.sortOrder = res.sortOrder
      this.tableContent = res;
      var columnDefs: any = this.gridOptions.columnDefs;
      res.tableColumnSetups.forEach((element: any) => {
        this.cellRenderer(element);

        columnDefs.push(element);
        this.gridOptions.api!.setColumnDefs(columnDefs);
      });

      this.overlayNoRowsTemplate = "<div class='not-found'><span>" + this.translate?.general.messages.noRecordsFound + "</span> </div>";

      this.actionData = {
        headerName: this.translate?.general.buttons.action.singular,
        lockPinned: true,
        filter: false,
        sortable: false,
        width: 150,
        headerTooltip: "#",
        headerClass: "marging-auto hide-action-border",
        cellStyle: { "text-align": "center" },
        cellRenderer: "buttonRenderer",
        cellRendererParams: {
          onClick: this.edit.bind(this),
          permissions: {
            update: this.perm.capable("assets", "update"),
            remove: this.perm.capable("assets", "remove"),
          },
          hidden: {
            update: !this.perm.capable("assets", "update"),
            remove: !this.perm.capable("assets", "remove"),
          },
        },
      };

      columnDefs.push(this.actionData);
      this.loadTable = 1;
      this.gridOptions.api!.setColumnDefs(columnDefs);
      this.onGridReady(this.gridParams);
    });
  }

  /********************************** Render Cell *****************************/
  private cellRenderer(element: any) {
    console.log(element, "===", element.dataType)


    var dateFormateChange: any = localStorage.getItem("date_format");
    var authUserTimezone: any = this.auth.getUserTimezone();

    const fromCustomer = this.data?.from;
    if (element.translatePath) {
      element.headerName = this.languageTranslateService.getNestedValue(this.translate, element.translatePath);
    }
    element.headerTooltip = "#";

    if (element.field === "runningNo") {
      element.headerComponentParams = {
        template:
          '<div class="ag-cell-label-container" role="presentation" id="Tbl_HeadAssetNo">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>'
      },
        // element.headerName = "Asset No.";
        element.cellClass = function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color-top"];
        };
      element.cellRenderer = function (params: any) {
        if (params.data) {
          return '<p id="' + params.data.rowId + '">' + params.data.runningNo + '</p>';
        }
      };
    }

    else if (element.field === "customer.name") {
      // element.headerName = "Customer";
      element.headerComponentParams = {
        template:
          '<div class="ag-cell-label-container" role="presentation" id="Tbl_HeadAssetCus">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>'
      },
        element.cellClass = function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color-top"];
        };
      element.cellRenderer = function (params: any) {
        if (params.data) {
          if (params.data && params.data.customer != null) {
            return '<p id="' + params.data.rowId2 + '">' + params.data.customer?.name + '</p>';
          }
          // } else if(params.data && params.data.customer == null ) {
          //   return '<span class="p-tag-value text-dark"> ' + "-"+ '</span>';
          // }

        }
      };
    }

    // if (element.field == "nextMaintenanceDateTime") {
    //   element.valueFormatter = function (params: any) {
    //     if (params.data && params.data.nextMaintenanceDateTime) {
    //       return moment .tz(params.data.nextMaintenanceDateTime, dateFormateChange).format(dateFormateChange.toUpperCase());
    //     }
    //   };

    // }
    else if (element.field == "assetsStatus.name" || element.field == "assetsStatus.label") {

      element.cellEditor = "agRichSelectCellEditor";
      if (this.perm.capable("assets", "update")) {
        element.editable = true;
      } else {
        element.editable = false;
      }
      element.cellRenderer = "statusRenderer";

      element.cellEditorParams = {
        values: this.statusesAsset,
        cellRenderer: "statusRenderer",
      };

      element.width = 150;
    }
    else if (element.dataType == 'date') {

      element.cellRenderer = function (params: any) {
        //console.log(params,"===",dateFormateChange,"===",timezone)

        if (params && params.value) {

          return moment.tz(params.value, authUserTimezone).format(dateFormateChange.toUpperCase());
        } else {
          return '-'
        }
      };
    } else if (element.dataType == "text") {
      element.cellRenderer = function (params: any) {
        if (params && params.value && params.value != undefined) {
          return params.value
        } else {
          return '-'
        }
      }
    } else if (element.dataType == 'datetime') {
      element.cellRenderer = function (params: any) {
        if (params && params.value) {
          return moment.tz(params.value, authUserTimezone).format("hh:mm A" + ", " + dateFormateChange.toUpperCase());
        } else {
          return '-'
        }
      };

    } else if (element.dataType == "boolean") {
      element.cellRenderer = function (params: any) {
        if (params && params.value) {
          return 'Yes'
        } else {
          return 'No'
        }
      }
    }

    // if (element.field === "customer.name") {
    //   if (fromCustomer === "customer") {
    //     element.hide = true;
    //   }
    // }
  }
  /********************************** Click Cell Event *****************************/
  private edit(e: any) {
    if (e.action === "edit") {
      this.util.setPersistantItem(PERSISTANT_ITEM.ASSET, e.rowData);

      this.router.navigate(["dashboard/assets/updateAsset"], {
        queryParams: { id: e.rowData.id },
      });
    } else if (e.action === "delete") {
      e.rowData.deleteHeader = this.translate.asset.label.singular
      e.rowData.deleteType = "Asset";
      e.rowData.message =
        this.translate?.general.messages.confirmDelete + " " +
        e.rowData.id +
        " - " +
        e.rowData.name +
        "?";

      this.push(
        this.deleteRecordService.getItems(e.rowData).subscribe((data) => {

          this.onGridReady(this.gridParams);
        })
      );
    }
  }

  /********************************** Initilize Grid Table **************************/
  public onGridReady(params: any) {
    if (params) {
      this.gridParams = params;

      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();

      var dataSource = {
        getRows: (params: IServerSideGetRowsParams) => {
          if (this.loadTable === 1) { this.getServerSideData(params); }
        },
      };

      this.gridApi.sizeColumnsToFit();
      this.gridApi.setDatasource(dataSource);
    }
  }

  /********************************** Call Customer API to Display data **************************/
  private getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {

    // Show spinner when starting data loading
    this.spinner.show();
    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);
    var count: any = serverRequest.startRow;
    // customer id
    if (this.data && this.data.customerId) {
      this.ids.customerId = this.data.customerId;
    }
    // site id
    if (this.data && this.data.siteId) {
      this.ids.siteId = this.data.siteId;
    }
    let origin = null;

    if (this.data && this.data.from) {
      origin = this.data.from;
    }


    this.push(
      this.assetService
        .getAllAssets(
          this.filter.serviceZoneName,
          this.ids.assetId,
          this.ids.customerId,
          this.ids.siteId,
          this.searchValue,
          this.filter.assetTypes,
          this.filter.assetStatuses,
          this.filter.fromDate,
          this.filter.toDate,
          origin,
          serverRequest
        )
        .subscribe(
          (data: any) => {
            this.assets = data.body.data;
            this.assets.forEach((element: any) => {
              count++
              element.rowId = 'Tbl_RecAssetNo' + count
              element.rowId2 = 'Tbl_RecAssetCus' + count
              element.buttonEdit = 'Btn_AssetEdit' + count
              element.buttonDelete = 'Btn_AssetDel' + count
            });
            this.totalRecord = data.body.total;
            this.setGridData(
              agGridGetRowsParams,
              this.assets,
              data.body.total,
              data.body.status
            );

            this.spinner.hide();
          },
          (err) => {
            console.log(err)
            const title = this.errorUtil.processErrorTitle(err.error, this.translate)
            this.errorUtil.setErrorMessage(400, null, title, 'error', 3000);
            this.spinner.hide();
          }
        )
    );
  }

  /********************************** Set Data for Grid Table **************************/
  private setGridData(
    agGridGetRowsParams: IServerSideGetRowsParams,
    resultItems: any[],
    totalCount: number,
    status: string
  ) {
    if (status === "SEARCH_NO_RESULTS" || status === "NO_DATA") {
      this.noDataFound = true;
      console.log("filter", this.filterActive)
      //this.filterActive = false;
      console.log("filter", this.filterActive)
      this.gridApi.showNoRowsOverlay();
    } else {
      this.noDataFound = false;

      if (this.assets.length === 0) {
        this.gridApi.showNoRowsOverlay();
        agGridGetRowsParams.successCallback([], 0);
      } else {
        agGridGetRowsParams.successCallback(this.assets, totalCount);
        ///CS-4758 this.gridApi.sizeColumnsToFit();
      }
    }

    this.noData.emit(this.noDataFound);
  }
  /********************************** Request Parameter for api **************************/
  private getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
      this.parameters = { colId: this.sortBy, sort: this.sortOrder };
      agGridRequest.sortModel.push(this.parameters);
    }

    //console.log(agGridRequest)
    this.currentRecordNumber = agGridRequest.startRow;
    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,
    };
  }

  /********************************** View Singal Record **************************/
  public viewAsset(e: any) {
    console.log(e);

    this.util.resetTabView();
    // console.log(e.colDef, "===", this.filterData);
    if (e.colDef.field === "runningNo" && e.colDef.cellClass !== null) {
      this.router.navigate(["dashboard/assets/view/" + e.data.id], {
        queryParams: { id: e.data.id, serialNo: null },
      });
    }

    if (e.colDef.field === "customer.name" && e.colDef.cellClass !== null && e.data.customer) {
      this.router.navigate(["dashboard/customers/view/" + e.data.customer.id], {
        queryParams: { id: e.data.customer.id },
      });
    }
  }

  /******************************************** Table Context Menu ******************************************/
  public getContextMenuItems = (params: any) => {
    const col = params.column.colId;
    let url = "";
    let id = 0;
    let result = [
      {
        name: "Open link in new tab",
        action: () => this.util.openItemToNewTab(url),
      },
    ];

    if (col === "runningNo") {
      id = params.node.data.id;
      url = `/dashboard/assets/view/${id}?id=${id}`;
    }

    if (col === "customer.name") {
      id = params.node.data.customer.id;
      url = `/dashboard/customers/view/${id}?id=${id}`;
    }

    return result;
  };

  // /******************************************** Get Context Menu Items ******************************************/
  // public getContextMenuItems(
  //   params: GetContextMenuItemsParams
  // ): (string | MenuItemDef)[] {
  //   var tabData: any | null = params;

  //   let self = this;
  //   console.log(self, this);

  //   if (tabData.column.colId == "runningNo") {
  //     var result: (string | MenuItemDef)[] = [
  //       {
  //         // custom item
  //         name: "Open link in new tab",
  //         action: () => {
  //           var url: any = self.router.serializeUrl(
  //             self.router.createUrlTree(["/dashboard/assets/view/" + tabData.id], {
  //               queryParams: { id: tabData.id },
  //             })
  //           );
  //           url = url.replace("%23", "#");
  //           window.open(url, "_blank");
  //         },
  //         cssClasses: ["redFont", "bold"],
  //       },
  //     ];
  //     return result;
  //   } else if (tabData.column.colId == "customer.name") {
  //     var result: (string | MenuItemDef)[] = [
  //       {
  //         // custom item
  //         name: "Open link in new tab",
  //         action: () => {
  //           var url: any = self.router.serializeUrl(
  //             self.router.createUrlTree(["/dashboard/customers/view/" + tabData.customer.id], {
  //               queryParams: { id: tabData.customer.id },
  //             })
  //           );
  //           url = url.replace("%23", "#");
  //           window.open(url, "_blank");
  //         },
  //         cssClasses: ["redFont", "bold"],
  //       },
  //     ];
  //     return result;
  //   } else {
  //     return [];
  //   }
  // }

  /******************************************** On Data Change ******************************************/
  public onDateChange(event: any) {
    if (this.dateRange && this.dateRange[0] && this.dateRange[1]) {
      this.filter.fromDate = moment(this.dateRange[0]).format("YYYY-MM-DD");
      this.filter.toDate = moment(this.dateRange[1]).format("YYYY-MM-DD");
      this.myCalendar.hideOverlay();
      this.refresh();
    }

    if (event === null) {
      this.filter.fromDate = null;
      this.filter.toDate = null;

      this.refresh();
    }
  }

  /******************************************** Toggle Filter ******************************************/
  public toggleFilter() {
    this.filterActive = !this.filterActive;
    this.isFilterClosed.emit(this.filterActive)
    console.log("toggle fil", this.filterActive)
    if (this.filterAPILoaded === false) {
      // APIS
      this.push(
        this.assetService.getTypes(true).subscribe((res: any) => {
          this.types = res.body.sort((elementA: any, elementB: any) => {
            if (elementA.order > elementB.order) {
              return 1;
            }

            if (elementA.order < elementB.order) {
              return -1;
            }

            return 0;
          });
        })
      );

      this.push(
        this.assetService.getStatuses(true).subscribe((res: any) => {
          this.statuses = res.body.sort((elementA: any, elementB: any) => {
            if (elementA.order > elementB.order) {
              return 1;
            }

            if (elementA.order < elementB.order) {
              return -1;
            }

            return 0;
          });
        })
      );

      this.filterAPILoaded = true;
    }
  }

  /******************************************** Refresh ******************************************/
  public refresh() {

    this.onGridReady(this.gridParams);

  }

  /******************************************** Clear All ******************************************/
  public clearAll() {
    this.dateRange = null;
    this.filter = {
      assetStatuses: null,
      assetTypes: null,
      fromDate: null,
      toDate: null,
      serviceZoneName: null,
    };

    if (this.gridParams) {
      this.onGridReady(this.gridParams);
    }
  }
  /******************************************** Add ALl the Subscription ******************************************/
  push(obs: any) {
    super.push(obs);
  }

  /******************************************** On Cell Change ******************************************/
  public onCellValueChanged(params: any) {
    const colId = params.column.getId();
    // console.log(colId);
    console.log(params, "--", this.perm.capable("assets", "update"));

    if (colId == "assetsStatus.name" || colId == "assetsStatus.label") {
      const id = params.data.id;
      const oldValue = params.oldValue;
      const newValue = params.data.assetsStatus.name.id;

      if (oldValue !== newValue) {
        this.push(
          this.assetService.updateAssetsStatus(newValue, id).subscribe((data: any) => {
            // console.log("Status Updated Successfully");
            console.log(data)
            if (data.message == 'success') {
              //this.onGridReady(params);  
              this.errorUtil.setErrorMessage(200, this.translate?.general.messages.updatedSuccessfully, null, "success", 3000);


            }

            params.api.redrawRows();
          })
        );
      }
    }
  }


  /************************** Set Table Header: Language Translated Dynamically (Preeti) ************************************/
  private setOnlyColumn() {
    // Reset columnDefs to avoid duplication
    this.gridOptions.columnDefs = [];
    console.log(this.tableContent);
    var columnDefs: any = this.gridOptions.columnDefs;
    this.tableContent.tableColumnSetups.forEach((element: any) => {
      this.cellRenderer(element);
      columnDefs.push(element);
      this.gridOptions.api!.setColumnDefs(columnDefs);
    });
    this.actionData.headerName = this.translate?.general.buttons.action.singular;
    columnDefs.push(this.actionData);
    this.gridOptions.api!.setColumnDefs(columnDefs);
    setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 500);
  }

}
