<ng-container>
    <div class="scroll-child">
    <div class="left-right-space">
        <div class="flex-between my-2">
            <div class="main-list-header" id="title_Check">
                {{ translate?.checklist.label.singular }}
            </div>
          
            <!-- Filter and New -->
        <div    class="custella-dashboard-top-right d-flex">
            <button *ngIf="!noData" class="btn-list-icon ms-2" [pTooltip]="translate?.general.buttons.refresh.singular" tooltipPosition="bottom" id="btn_CheckRefresh" (click)="onRefreshList()">
                <i class="fa-regular fa-arrows-rotate"></i>
            </button>

                <button *ngIf="!noData" class="btn-list-icon ms-2" [pTooltip]="translate?.general.buttons.filter.singular" tooltipPosition="bottom" id="btn_CheckFilter" (click)="toggleFilterView()">
                    <i class="fa-regular fa-filter-list"></i>
                </button>
                <div *ngIf="!noData" class="p-inputgroup search ms-2">
                    <span class="p-input-icon-right ">
                        <input type="text" class="w-100" pInputText autocomplete="off" [placeholder]="translate?.general.buttons.search.singular" id="input_CheckSearchField" [(ngModel)]="searchValue" (keyup)="onSearchCheck()" (keydown.enter)="onSearch()"/>
                        <i *ngIf="searchValueData" class="p-autocomplete-clear-icon pi pi-times" (click)="onClear()" id="btn_CheckSearchClear"></i>
                    </span>   
                    <button type="button" pButton icon="fa-regular fa-magnifying-glass" id="btn_CheckSearch" (click)="onSearch()"></button>
                </div>
                <p-button *ngIf="create" type="button" [label]="translate?.general.buttons.new.singular" (onClick)="createView()" icon="fa-regular fa-plus" styleClass="ms-2 h-32" id="btn_CheckNew"></p-button>
            </div>
        </div>
    </div>

    

    <div  class="left-right-space position-relative fw-normal d-flex h-100-70">
        <div *ngIf="noDataIcon" class="col-12 mt-5 py-4">
            <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
                <img src="assets\svg\empty-state.svg" alt="" />
                <p class="text-6 my-1 font-weight-bold" >{{translate?.general.messages.noRecordsFound}}</p>
            </div>
        </div>
        <div [hidden]="noData" class="table-space ag-grid-table-full" style="height: 100%;" [ngClass]="{ 'ag-grid-table': filterClick }">
            <ag-grid-angular
                #agGrid
                style="height: 100%;"
                class="ag-theme-balham"
                [columnDefs]="columnDefs"
                [gridOptions]="gridOptions"
                [defaultColDef]="defaultColDef"
                [cacheOverflowSize]="2"
                [maxConcurrentDatasourceRequests]="-1"
                [suppressDragLeaveHidesColumns]="true"
                [allowContextMenuWithControlKey]="true"
                [frameworkComponents]="frameworkComponents"
                (cellClicked)="viewCell($event)" 
                (gridReady)="onGridReady($event)"
                [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                [suppressRowClickSelection]="true"
                [getContextMenuItems]="getContextMenuItems">
            </ag-grid-angular>

            <div class="col-12">
                <p class="ag-record-row-summary-panel padds text-end" id="label_CheckTableRec">
                    {{ totalRecord }}&nbsp;{{translate?.general.messages.records}}
                </p>
            </div>
        </div>

        <div class="custella-form-container-content-pane layout-filter"
            [ngClass]="{ 'layout-filter-active': filterActive }">
            <div class="custella-form-container-content-pane-title">
                <span class="text-14 font-medium" id="title_ChecksFilter">{{ translate?.general.messages.filterBy  }}</span>
                <i class="fa-regular fa-arrow-right-from-line" [pTooltip]="translate?.general.buttons.closeFilter.singular" tooltipPosition="left" id="btn_ChecksFilterClose" (click)="toggleFilterView()"></i>
            </div>

            <div class="custella-content" [formGroup]="myGroup" >
                <div class="custella-content-input col-12" >
                    <span class="custella-content-input-title" id="label_CheckDateRange">{{ translate?.checklist.fields.dateRange.singular
                        }}</span>
                    <span class="p-input-icon-right w-100">
                        <i class="pi pi-calendar" style="z-index: 1;"></i>
                        <!-- <p-calendar #dateFilter [hideOnDateTimeSelect]="hideOnDateTimeSelect" [readonlyInput]="true"
                            formControlName="dateRange" id="picklist_CheckDateRangeField"
                            placeholder="dd/mm/yyyy - dd/mm/yyyy" selectionMode="range" dateFormat="dd/mm/yy"
                            yearRange="2000:2030" [yearNavigator]="true" [showButtonBar]="false" [(ngModel)]="dateRange"
                            (onSelect)="onChangeDate()"appendTo="body"></p-calendar> -->
                            <p-calendar class="custella-calendar" #dateFilter [hideOnDateTimeSelect]="hideOnDateTimeSelect" [readonlyInput]="true"
                            formControlName="dateRange" id="picklist_CheckDateRangeField"
                            placeholder="dd/mm/yyyy - dd/mm/yyyy" selectionMode="range" dateFormat="dd/mm/yy"
                            yearRange="2000:2030" [yearNavigator]="true" [showButtonBar]="false" [(ngModel)]="dateRange"
                            (onSelect)="onChangeDate()"appendTo="body"></p-calendar>
                    </span>
                </div>

                <div class="custella-content-input col-12" *ngIf="!isFromAsset">
                    <span class="custella-content-input-title" id="label_CheckAssetType">{{ translate?.checklist.fields.assetType.singular
                        }}</span>
                        <p-dropdown [ngModelOptions]="{ standalone: true }" class="pe-2" name="status" id="picklist_CheckAssetTypeField"   (onChange)="onRefreshList()" [(ngModel)]="filter.assetTypeId"   [options]="assetsType" optionLabel="name" filterBy="label" optionValue="id" [placeholder]="translate?.general.messages.none"></p-dropdown>
                </div>
                
                <div class="custella-content-input col-12"  *ngIf="!isFromAsset">
                    <span class="custella-content-input-title" id="label_CheckEquipType">{{ translate?.checklist.fields.equipmentType.singular }}</span>
                    <p-dropdown [ngModelOptions]="{ standalone: true }" class="pe-2" name="status" id="picklist_CheckEquipTypeField" (onChange)="onRefreshList()" [(ngModel)]="filter.equipmentType"   [options]="equipmenntTypes" optionLabel="label" filterBy="label" optionValue="label" [placeholder]="translate?.general.messages.none"></p-dropdown>
                </div>
               
                <div class="custella-content-input col-12"  *ngIf="!isFromAsset">
                    <span class="custella-content-input-title" id="label_CheckSerZone">{{ translate?.setup.serviceZone.label.singular }}</span>
                    <p-dropdown [ngModelOptions]="{ standalone: true }" class="pe-2" name="status" id="picklist_CheckSerZoneField" (onChange)="onRefreshList()" [(ngModel)]="filter.serviceZoneId"   [options]="serviceZones" optionLabel="name" filterBy="label" optionValue="id" [placeholder]="translate?.general.messages.none"></p-dropdown>
                </div>
                <div class="custella-content-input col-12" >
                    <span class="custella-content-input-title" id="label_CheckStatus">{{ translate?.checklist.fields.status.singular }}</span>
                    <!-- <p-dropdown class="pe-2" name="status" id="picklist_CheckStatusField" (onChange)="onRefreshList()" [(ngModel)]="filter.status"   [options]="satatus"
                     optionLabel="label" filterBy="label" optionValue="value" [placeholder]="translate?.general.messages.none"></p-dropdown> -->
                    <p-multiSelect
                    class="pe-2"
                    [(ngModel)]="filter.status"
                    name="status"
                    [options]="satatus"
                    optionLabel="label" filterBy="label"
                    optionValue="value"
                    [placeholder]="translate?.general.messages.none"
                    (onChange)="onStatusChange($event)"
                    formControlName="status"
                    id="picklist_CheckStatusField"
                >
                </p-multiSelect>
                </div>
                <div class="custella-content-input col-12" *ngIf="isFromAsset">
                    <span class="custella-content-input-title" id="label_TixCust" >{{ translate?.setup.users.users.label.singular }}</span>
                    <div class="p-inputgroup search">
                        <span class="p-input-icon-right w-100">
                            <p-autoComplete
                                (onSelect)="onUserSelect($event)"
                                field="fullName"
                                [suggestions]="allUsers"
                                (completeMethod)="getUsers($event)"
                                [(ngModel)]="selectedUser"
                                [placeholder]="translate?.general.messages.none"
                                [ngModelOptions]="{ standalone: true }"
                                id="picklist_TixCustField"
                                styleClass="w-100"
                            >
                            </p-autoComplete>
                            <i *ngIf="selectedUser && selectedUser?.id" id="btn_TixCustClear" (click)="clearValue('customer')" class="p-autocomplete-clear-icon pi pi-times"></i>
                        </span>
                        <button type="button" pButton icon="fa-regular fa-magnifying-glass" [pTooltip]="translate?.customer.tooltips.customerLookup.singular" tooltipPosition="left"  id="btn_TixCustSearch" (click)="showTable('user')"></button>
                    </div>
                </div>
                <div>
                    <span href="#" class="clear" (click)="clearValue('all')" id="subtitle_CheckClear">{{ translate?.general.buttons.clearAll.singular }}</span>
                </div>
            </div>

        </div>
    </div>
    </div>

    <!-- FOR CENTER IN PAGE -->
<footer *ngIf="showingTable.user" class="custella-form-container-footer">
    <p-dialog [header]="translate?.customer.sections.sec5.singular" id="title_CustDet" [(visible)]="showingTable.user" showEffect="fade" [style]="{ width: '70vw' }" [modal]="true" [draggable]="false">
        <!-- <app-custella-customers (selectCustomerEvent)="onCustomerSelect($event)" (closeCustomerEvent)="closeTable('customer')"></app-custella-customers> -->
        <!-- <app-user-lookup (selectUserEvent)="onUserSelect" (closeUserEvent)="closeTable('user')"></app-user-lookup> -->
         <app-custella-users (itemSelector)="onUserSelect($event)" (closeView)="closeTable('user')" ></app-custella-users>
    </p-dialog>
</footer>
</ng-container>