import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MenuItem } from 'primeng/api';
import { EngLangData } from 'src/app/_models/global.data.model';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';

@Component({
  selector: 'app-form-preview',
  templateUrl: './form-preview.component.html',
  styleUrls: ['./form-preview.component.scss']
})
export class FormPreviewComponent implements OnInit {
  translate: any;
  items: MenuItem[];
  constructor(
    public modalRef : BsModalRef,
    private util: UtilServiceService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.translate = EngLangData;

    })


     /* DUMMY DATA FOR STEPS */
    this.items = [
      { label: 'Example Page 1' },
  ];
  }

  
  goBack(){
    this.util.goBack()
   }

}
