<div class="h-100">
    <div class="flex-between">
        <i class="fa-regular fa-xmark w-24px dialog-close-icon" id="btn_TaskTemplClose"
            [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left" (click)="closeAsset()"></i>
    </div>
    <div class="position-relative h-100">
        <div class="flex-between">
            <span class="title mt-0"
                id="label_TaskTemplSelectFromList">{{translate?.general.commonComponent.sections.sec18.singular}}</span>
            <!-- <button (click)="filterToggle()" class="btn-list-icon ms-2"
                [pTooltip]="translate?.general.buttons.filter.singular" tooltipPosition="bottom"
                id="btn_TaskTemplFilter">
                <i class="fa-regular fa-filter-list"></i>
              
            </button> -->
        </div>

        <div class="flex-align-baseline h-100-37 position-relative mt-2">
            <div class="ag-grid-table-full h-100" [ngClass]="{' ag-grid-table': filterActive}">
                <div class="flex-align-center">
                    <span class="p-input-icon-left w-100">
                        <i class="pi pi-search"></i>
                        <input type="text" class="w-100" pInputText autocomplete="off" id="input_SetJobTempSearchField" [placeholder]="translate?.general.buttons.search.singular" [(ngModel)]="searchValue" (keydown.enter)="quickSearch()" (keyup)="onSearchCheck()" />
                            <!-- <i *ngIf="searchValueData" id="btn_SetJobTempSearchClear" class="p-autocomplete-clear-icon pi pi-times" (click)="onClear()"></i> -->
                    </span>
                </div>
                
                <div [hidden]="!notaskTemplate" class="h-100-37 border mt-2">
                    <ag-grid-angular
                    #agGrid style="height: 100%;" 
                    class="ag-theme-balham" 
                     [columnDefs]="columnDefs"
                     [gridOptions]="gridOptions" 
                     [defaultColDef]="defaultColDef" 
                     [pagination]=false 
                     [cacheOverflowSize]="2"
                     [suppressDragLeaveHidesColumns]="true" 
                     [maxConcurrentDatasourceRequests]="-1"
                     [allowContextMenuWithControlKey]="false" 
                     [rowData]="DataDetails" 
                     [frameworkComponents]="frameworkComponents"
                     (gridReady)="onGridReady($event)"
                     [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                     (cellClicked)="viewTaskTemplate($event)"></ag-grid-angular>
            
                    <div class="col-12">
                        <p class="ag-record-row-summary-panel padds text-end" id="label_SetJobTempTableRec">{{totalRecord}}&nbsp;{{translate?.general.messages.records}}</p>
                    </div>
                </div>
               
                <div *ngIf="!notaskTemplate" class="col-12 mt-5 py-4">
                    <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
                        <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound" />
                        <p  class="text-6 my-1 font-weight-bold" >{{translate.general.messages.noRecordsFound}}</p>
                    </div>
                </div>
        	    
            </div>
            
            <div class="custella-form-container-content-pane layout-filter"[ngClass]="{ 'layout-filter-active': filterActive }">
                    <div class="custella-form-container-content-pane-title">
                        <span class="text-14 font-medium" id="label_TaskTemplFilter">{{translate?.general?.messages.filterBy}}</span>
                        <i class="fa-regular fa-arrow-right-from-line"[pTooltip]="translate?.general.buttons.closeFilter.singular" tooltipPosition="left"(click)="filterToggle()" id="btn_TaskTemplClose"></i>
                    </div>
                    <div class="custella-content">
                        <div class="custella-content-input col-12">
                            <span class="custella-content-input-title" id="label_SetTaskTempTeam">{{translate?.setup.users.teams.label.singular}}</span>
                            <p-dropdown class="pe-2" id="picklist_SetTaskTempTeamField"  [(ngModel)]="filter.teamId" name="status" [options]="teams" optionLabel="name"  optionValue="id" [placeholder]="translate?.general.messages.none" (onChange)="refresh()"></p-dropdown>
              
                        </div>
                        <div>
                            <span href="#" class="clear" (click)="resetSearch()" id="subtitle_SetJobTempFilterClear">{{translate?.general.buttons.clearAll.singular }}</span>
                        </div>
                    </div>
                </div>
                <div>
            </div>
        </div>
    </div>
</div>