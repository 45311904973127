<div class="non-scroll mt-2 rounded-2" *ngIf="orgAIFeatures">
    <div class="left-right-space mb-2">
        <div class="flex-between mb-2">
            <div class="text-20 font-semiBold" id="title_SetAiSuggestion">{{translate?.setup?.configuration?.artificialIntelligence.sections.sec0.singular}}</div>
            <div class="flex-align-center justify-content-end col-4">
                <p-button *ngIf="editForm" styleClass="ms-2 h-32" [label]="translate?.general?.buttons?.save.singular" (onClick)="clickSave()"  id="btn_SetAiSuggestionSubmit"></p-button>         
                <p-button *ngIf="!editForm" styleClass="ms-2 h-32" id="btn_SetAiSuggestionEdit" [label]="translate?.general?.buttons?.configure.singular"(onClick)="clickEdit()"></p-button>
                <button  *ngIf="editForm" class="custella-btn-white text-12 ms-2" id="btn_SetAiSuggestionCancel" (click)="clickEdit()">{{translate?.general?.buttons?.cancel.singular }}</button>
                <!-- <button pButton [ngClass]="{'p-button-secondary ms-2 h-32': editForm }" label="{{editForm ? 'Cancel':'Edit'}}" (click)="clickEdit()" ></button> -->
            
            </div>
        </div>
        <div class="lv-desc mb-2">
            <span class="text">{{translate?.setup?.configuration?.artificialIntelligence.messages.enableOrDisableSuggestions}}</span>
        </div>
    </div>
    
    <div class="custella-form-container left-right-space" >
        <div *ngFor="let config of divConfigs; let i = index" class="custella-form-container-content-border-bottom" [hidden]="isHidden(i)">
            <div class="custella-content-row" [ngClass]="{ 'padding-top-10px': shouldAddPadding(i) }">
                <div class="col-12 flex-between">
                    <div>
                        <span class="text-12 font-medium" id="label_JobDetOrderName">{{ config.label }}</span>
                        <p class="text-12 color-var-500 d-block" id="value_JobDetOrderName">{{ config.value }}</p>
                    </div>
                    <span class="ms-auto">
                        <i *ngIf="!editForm && aiFeatures[config.feature]" class="pi pi-check font-green"></i>
                        <i class="pi pi-times font-red" *ngIf="!editForm && !aiFeatures[config.feature]"></i>
                        <div class="p-field-checkbox" *ngIf="editForm"  >
                            <p-checkbox [binary]="true" [(ngModel)]="aiFeatures[config.feature]"></p-checkbox>
                        </div>
                    </span>
                </div>
            </div>
        </div>

      
    </div>
</div>