import { of as throwError, Subject, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
// import { HttpResponse } from 'selenium-webdriver/http';
// import * as moment from 'moment-timezone';
import { AuthenticationService } from "../auth/authentication.service";
import { Router } from "@angular/router";
import * as _ from "lodash";
import {
  Http,
  RequestOptions,
  Headers,
  ResponseContentType,
} from "@angular/http";
@Injectable()
export class     AssetService {
  refreshPhoto$: EventEmitter<{}>;
  public refreshContractList$: EventEmitter<any>;
  public refreshStockList$: EventEmitter<any>;
  public refreshContractItem$: EventEmitter<any>;
  public refreshMaintenanceList$: EventEmitter<any>;
  public refreshScheduledMaintenanceList$: EventEmitter<any>;
  public refreshAccessories$: EventEmitter<any>;

  public refreshAssetLogList$: EventEmitter<any>;
  public refreshConsignmentList$: EventEmitter<any>;
  public refreshConsignmentItemList$: EventEmitter<any>;
  public refreshRequisitionList$: EventEmitter<any>;
  public refreshRequisitionItemList$: EventEmitter<any>;
  public refreshButton$: EventEmitter<any>;

  public refresherAssetStatusList$: EventEmitter<any>;
  public refresherAssetTypeList$: EventEmitter<any>;

  private ENDPOINT_ASSET_BRANDS = "/asset-brands";
  private ENDPOINT_ASSET_BUILDING = "/asset-buildings"; // Not In Use(Checked On 7/oct/2024)
  private ENDPOINT_ASSET_FLOORS_BY_BUILDING = "/asset-floors-by-building/";
  private ENDPOINT_ASSET_ROOMS_BY_FLOOR = "/asset-rooms-by-floor/";
  private ENDPOINT_SAVE_ASSET_PUT = "/v4/assets";
  private ENDPOINT_SAVE_ASSET_POST = "/v4/assets";
  private ENDPOINT_EDIT_ASSET = "/v4/assets/";
  private ENDPOINT_DELETE_ASSET = "/assets/"
  private ENDPOINT_ASSETS_HISTORY_BY_ASSETS = "/assets-histories/by-assets/";
  private ENDPOINT_ASSETS_FILTER = "/v4-1/assets/filter";
  private ENDPOINT_ASSETS_TYPES = "/assets-types";
  private ENDPOINT_ASSETS_STATUSES = "/assets-statuses";
  private ENDPOINT_ATTACHMENTS_UPLOAD = "/attachments/upload?parentType=";
  private ENDPOINT_ATTACHMENTS_UPLOADV2 = "/v2/attachments/upload?parentType=";
  private ENDPOINT_GET_ALL_USERS = "/v4/get-all-users";
  private ENDPOINT_GET_ALL_SERVICE_ZONES = "/service-zones/pick-list";
  private ENDPOINT_ASSET_STATUS_UPDATE = "/assets/status";


  //stocks
  private ENDPOINT_SAVE_STOCK = "/stock-checks";
  private ENDPOINT_EDIT_GET_STOCK = "/stock-checks/"; //for edit,View
  private ENDPOINT_HISTORY_BY_STOCK = "/stock-histories/by-stock-check/"; //view asset list
  private ENDPOINT_ASSET_BY_SERIALNO = "/assets-by-serialNo/"; // Not In Use(Checked On 7/oct/2024)
  private ENDPOINT_STOCK_CHECK_FILTER = "/stock-checks/filter";

  //contracts
  private ENDPOINT_CONTRACTS_ASSETS = "/v4/contracts/filter";
  private ENDPOINT_CONTRACTS_SO =
    "/v2/sales-orders/pick-list/search?value&customerId=";
  private ENDPOINT_CONTRACTS_BY_ID = "/v4/contracts/";
  private ENDPOINT_CONTRACTS_DELETE = "/contracts/"
  private ENDPOINT_CONTRACTS_ASSETS_PICKLIST = "/v4/assets/pick-list";
  private ENDPOINT_CONTRACTS_BIN_PICKLIST =
    "/inventories/location/bin?assetsId=";
  // private ENDPOINT_CONTRACTS_ACCESSORIES_PICKLIST = "/assets/pick-list?assetsId=";
  private ENDPOINT_CONTRACTS_COMPONENTS_PICKLIST =
    "/v4/assets/components/pick-list?assetsId=";
  private ENDPOINT_CONTRACTS = "/v4/contracts";
  private ENDPOINT_CONTRACTS_ITEM_DELETE =
    "/contracts/remove/contract-item?contractItemId=";
  private ENDPOINT_CONTRACTS_ITEM_ADD = "/contracts/add/contract-item";
  private ENDPOINT_CONTRACTS_ITEM_BY_ID = "/contract-items/";
  private ENDPOINT_CONTRACTS_CANCEL = "/contracts/v2/cancel?contractId=";
  private ENDPOINT_CONTRACTS_BY_CUSTOMER_ID =
    "/v4/contacts";
  private ENDPOINT_ASSET_NAME_CODE_SERIALNO_PICKLIST =
    "/v4/assets/pick-list";

  private ENDPOINT_GET_CONTRACT_ASSETS_ITEMS = "/v4-1/contract-items/assets"

  //maintenance
  private ENDPOINT_MAINTENANCE_ASSETS = "/maintenances/assets";
  private ENDPOINT_CONTRACT_PICKLIST = "/v4/contracts/filter?assetsId=";
  private ENDPOINT_MAINTENANCE_UPDATE = "/maintenances";
  private ENDPOINT_MAINTENANCE_BY_ID = "/maintenances/";

  //Preventative maintenance
  private ENDPOINT_MAINTENANCE_ITEMS_MAINTENANCE = "/v4-1/maintenance-items/maintenance"; //get all maintenance items=> CS-4517
  private ENDPOINT_MAINTENANCE_ITEMS_ASSETS = "/v4-1/maintenance-items";
  private ENDPOINT_MAINTENANCE_PICKLIST = "/maintenances/pick-list";
  private ENDPOINT_MAINTENANCE_ITEMS = "/v4-1/maintenance-items";
  private ENDPOINT_MAINTENANCE_ITEMS_BY_ID = "/maintenance-items/";
  private ENDPOINT_JOBORDERS_PICKLIST = "/job-orders";
  private ENDPOINT_MAINTENANCE_BY_ASSETS =
    "/maintenances/assets-value?maintenanceId=";
  private ENDPOINT_MAINTENANCE_BY_ASSET_ID =
    "/maintenances/pick-list?assetsId=";

  //accessories
  private ENDPOINT_ACCESSORIES = "/asset-components";
  private ENDPOINT_ACCESSORIES_BY_ID = "/asset-components/";
  private ENDPOINT_GET_ACCESORIES_BY_ASSETS_ID = "/v4/accessories"
  //Consignment
  private ENDPOINT_LIST_USERS = "/v4/get-all-users";
  private ENDPOINT_SAVE_CONSIGNMENT = "/consignment-notes";
  private ENDPOINT_GET_BY_ID_CONSIGNMENT = "/consignment-notes/";
  private ENDPOINT_CONSIGNMENT_ATTACHMENTS_UPLOAD =
    "/attachments/upload?parentType=CONSIGNMENT_NOTE_SIGNED_COPY&parentId=";
  private ENDPOINT_CONSIGNMENT_SENT_EMAIL = "/consignment-notes/email-to/";
  private ENDPOINT_CANCEL_CONSIGNMENT = "/consignment-notes/cancel/";
  private ENDPOINT_REJECT_CONSIGNMENT = "/consignment-notes/reject/";
  private ENDPOINT_APPROVE_CONSIGNMENT = "/consignment-notes/acknowledge/";

  //Consignment Items
  private ENDPOINT_LIST_CONSIGNMENT_ITEMS = "/consignment-items";
  private ENDPOINT_ITEM_BY_ID_CONSIGNMENT_ITEMS = "/consignment-items/";

  //Requisition
  private ENDPOINT_REQUISION_ATTACHMENTS_UPLOAD =
    "/attachments/upload?parentType=REQUISITION_NOTE_SIGNED_COPY&parentId=";
  private ENDPOINT_CANCEL_REQUISION = "/requisition-notes/cancel/";


  //contract accessories and assets
  private ENDPOINT_CONTRACT_ACCESSORIES = "/v4-1/contract-items/accessories";
  private ENDPOINT_CONTRACT_ASSETS = "/v4-1/contract-items/assets";


  private ENDPOINT_MAINTENANCE_FREQUENCY_PICKLIST = "/preventative-maintenance-frequencies/pick-list";

  private ENDPOINT_ASSETS_SUMMERY = "/assets-summary/";
  private ENDPOINT_ASSETS_OVERVIEW = "/assets-overview";
  private ENDPOINT_ASSETS_TIMELINE = "/assets-audit-event-histories";
  private ENDPOINT_ASSETS_LOG = "/asset-logs";
  private ENDPOINT_ASSETS_LOG_MODIFIED_USERS = "/asset-logs/modified-users";
  private ENDPOINT_GET_ASSETS_ACCESORIES_BIN_DETAILS = "/inventories/location/bins"

  private assetItemsSource = new Subject<any>();
  assetitemsHandler = this.assetItemsSource.asObservable();

  private assetLogChangedSubject = new Subject<any>();
  isAssetLogDataChanged = this.assetLogChangedSubject.asObservable();

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private router: Router,
    private httpOnly: Http
  ) {
    this.refreshStockList$ = new EventEmitter();
    this.refreshContractItem$ = new EventEmitter();
    this.refreshPhoto$ = new EventEmitter();
    this.refreshContractList$ = new EventEmitter();
    this.refreshMaintenanceList$ = new EventEmitter();
    this.refreshScheduledMaintenanceList$ = new EventEmitter();
    this.refreshAccessories$ = new EventEmitter();
    this.refreshAssetLogList$ = new EventEmitter();
    this.refreshConsignmentList$ = new EventEmitter();
    this.refreshConsignmentItemList$ = new EventEmitter();
    this.refreshRequisitionList$ = new EventEmitter();
    this.refreshRequisitionItemList$ = new EventEmitter();
    this.refreshButton$ = new EventEmitter();

    this.refresherAssetStatusList$ = new EventEmitter();
    this.refresherAssetTypeList$ = new EventEmitter();

  }

  setItems(items: any) {
    this.assetItemsSource.next(items);
  }

  OnAssetLogDataChanged(message: any) {
    this.assetLogChangedSubject.next(message);
  }

  /************************************************ Asset types dd ************************************************/
  public getTypes(active: any = null) {
    var active1 = ''
    if (active) { active1 = '?isActive=true' }
    return this.http.get(environment.base_url + this.ENDPOINT_ASSETS_TYPES + active1, { observe: "response" })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public createType(body: any) {
    return this.http.post(environment.base_url + this.ENDPOINT_ASSETS_TYPES, body, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      //console.log(error);
      return throwError(error);
    }),);
  }

  public updateType(body: any) {
    return this.http.put(environment.base_url + this.ENDPOINT_ASSETS_TYPES, body, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      //console.log(error);
      return throwError(error);
    }),);
  }

  public updateAssetsStatus(statusId: any, assetId: any) {
    // let params = new HttpParams();
    // if (assetId) params = params.set("id", assetId);
    // if (statusId) params = params.set("statusId", statusId);
    return this.http.put(environment.base_url + this.ENDPOINT_ASSET_STATUS_UPDATE+"?id="+assetId+"&statusId="+statusId, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      //console.log(error);
      return throwError(error);
    }),);
  }


  public getTypeById(id: any) {
    return this.http.get(environment.base_url + this.ENDPOINT_ASSETS_TYPES + '/' + id).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      //console.log(error);
      return throwError(error);
    }),);
  }

  public getAssetsById(id: any) {
    return this.http.get(environment.base_url + this.ENDPOINT_DELETE_ASSET + id).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      //console.log(error);
      return throwError(error);
    }),);
  }

  public getAssetsLogById(id: any) {
    return this.http.get(environment.base_url + this.ENDPOINT_ASSETS_LOG + "/"+ id).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      //console.log(error);
      return throwError(error);
    }),);
  }

  public deleteAssetType(id: any) {
    return this.http.delete(environment.base_url + this.ENDPOINT_ASSETS_TYPES + '/' + id, { observe: 'response' }).pipe(map((resp: any) => {
      this.refresherAssetTypeList$.emit('Deleted Type');
      return resp;
    }
    ), catchError(error => {
      //console.log(error);
      return throwError(error);
    }),);
  }


  public getServiceZones() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_GET_ALL_SERVICE_ZONES, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp.body;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  public getEquipmenntTypes() {
    return this.http
      .get(environment.base_url +'/assets/eqp-types', {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp.body;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  /************************************************ Asset statuses dd ************************************************************************/
  public getStatuses(active: any = null) {
    var active1 = ''
    if (active) { active1 = '?isActive=true' }
    return this.http.get(environment.base_url + this.ENDPOINT_ASSETS_STATUSES + active1, { observe: "response" })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }


  public createStatus(body: any) {
    return this.http.post(environment.base_url + this.ENDPOINT_ASSETS_STATUSES, body, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      //console.log(error);
      return throwError(error);
    }),);
  }

  public updateStatus(body: any) {
    return this.http.put(environment.base_url + this.ENDPOINT_ASSETS_STATUSES, body, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      //console.log(error);
      return throwError(error);
    }),);
  }

  public getStatusById(id: any) {
    return this.http.get(environment.base_url + this.ENDPOINT_ASSETS_STATUSES + '/' + id).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      //console.log(error);
      return throwError(error);
    }),);
  }
  public deleteAssetStatus(id: any) {
    return this.http.delete(environment.base_url + this.ENDPOINT_ASSETS_STATUSES + '/' + id, { observe: 'response' }).pipe(map((resp: any) => {
      this.refresherAssetStatusList$.emit('Deleted Status');
      return resp;
    }
    ), catchError(error => {
      //console.log(error);
      return throwError(error);
    }),);
  }



  public getUsers() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_GET_ALL_USERS, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //magnum Dashboard - Workshop Spare Monitoring
  public getMagnumAllAssets(
    startFrom: any | number | boolean,
    perPage: any | number | boolean,
    search: any | number | boolean,
    assetTypes: any | number | boolean,
    assetStatuses: any | number | boolean,
    fromDate: any | number | boolean,
    toDate: any | number | boolean,
    roleId: any | number | boolean,
    assetTypeNames: any | number | boolean,
    columnName: any | number | boolean,
    sortOrder: any | number | boolean
  ) {
    let params = new HttpParams();
    if (startFrom) params = params.set("startFrom", startFrom);
    if (perPage) params = params.set("perPage", perPage);
    if (search) params = params.set("search", search);
    if (assetTypes) params = params.set("assetTypes", assetTypes);
    if (assetStatuses) params = params.set("assetStatuses", assetStatuses);
    if (fromDate !== "" && toDate !== "") {
      fromDate = fromDate + "T00:00:00Z";
      toDate = toDate + "T23:59:00Z";
      params = params
        .set("nextMaintenanceFromDateTime", fromDate)
        .set("nextMaintenanceToDateTime", toDate);
    }
    if (roleId) params = params.set("roleId", roleId);
    if (assetTypeNames) params = params.set("assetTypeNames", assetTypeNames);
    if (columnName) params = params.set("columnName", columnName);
    if (sortOrder) params = params.set("sortOrder", sortOrder);
    return this.http
      .get(environment.base_url + this.ENDPOINT_ASSETS_FILTER, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //stock check grid
  public getAllStockCheck(
    startFrom: any | number | boolean,
    perPage: any | number | boolean,
    search: any | number | boolean,
    customerId: any | number | boolean,
    status: any | number | boolean,
    columnName: any | number | boolean,
    sortOrder: any | number | boolean
  ) {
    let params = new HttpParams();
    if (startFrom) params = params.set("startFrom", startFrom);
    if (perPage) params = params.set("perPage", perPage);
    if (search) params = params.set("search", search);
    if (customerId) params = params.set("customerId", customerId);
    if (status) params = params.set("status", status);
    if (columnName) params = params.set("columnName", columnName);
    if (sortOrder) params = params.set("sortOrder", sortOrder);
    return this.http
      .get(environment.base_url + this.ENDPOINT_STOCK_CHECK_FILTER, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //from stock tab view to asset view // Not In Use(Checked On 7/oct/2024)
  public getAssetViewBySerialNo(serialNo: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_ASSET_BY_SERIALNO + serialNo, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //view asset list
  public getHistoryByStock(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_HISTORY_BY_STOCK + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //brand dd
  public getBrands() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_ASSET_BRANDS, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public uploadAttachment(parentType: any, formData: any) {
    const headers = new Headers({});
    headers.append("Authorization", "Bearer " + this.auth.getToken());
    let options = new RequestOptions({ headers: headers });

    console.log(formData);

    return this.httpOnly
      .post(
        environment.base_url + this.ENDPOINT_ATTACHMENTS_UPLOAD + parentType,
        formData,
        options
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public uploadAttachmentV2(parentType: any, formData: any) {
    const headers = new Headers({});
    headers.append("Authorization", "Bearer " + this.auth.getToken());
    let options = new RequestOptions({ headers: headers });

    console.log(formData);

    return this.httpOnly
      .post(
        environment.base_url + this.ENDPOINT_ATTACHMENTS_UPLOADV2 + parentType,
        formData,
        options
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //buildings dd
  // Not In Use(Checked On 7/oct/2024)
  public getAssetBuilding(customerId: any | number | boolean) {
    let params = new HttpParams();
    if (customerId) params = params.set("customerId", customerId);
    return this.http
      .get(environment.base_url + this.ENDPOINT_ASSET_BUILDING, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }



  //floor dd
  public getAssetFloorByBuilding(buildingId: any) {
    return this.http
      .get(
        environment.base_url +
        this.ENDPOINT_ASSET_FLOORS_BY_BUILDING +
        buildingId,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  //room dd
  public getAssetRoomsByFloor(floorId: any) {
    return this.http
      .get(
        environment.base_url + this.ENDPOINT_ASSET_ROOMS_BY_FLOOR + floorId,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //edit asset call
  public getEditObj(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_EDIT_ASSET + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //history call
  public getHistory(assetsId: any) {
    return this.http
      .get(
        environment.base_url +
        this.ENDPOINT_ASSETS_HISTORY_BY_ASSETS +
        assetsId,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //add save - stock
  public createStock(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_SAVE_STOCK, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshStockList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //edit save - stock
  public editStock(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_SAVE_STOCK, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshStockList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //edit stock data call
  public getEditStockObj(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_EDIT_GET_STOCK + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public deleteStock(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_EDIT_GET_STOCK + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshStockList$.emit("Deleted Stock");
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //grid call(assets - view -Contracts)
  public getAssetContractGrid(
    startFrom: any | number | boolean,
    perPage: any | number | boolean,
    search: any | number | boolean,
    assetsId: any | number | boolean,
    statuses: any | number | boolean,
    parameters: any
  ) {
    let params = new HttpParams();
    if (startFrom) params = params.set("startFrom", startFrom);
    if (perPage) params = params.set("perPage", perPage);
    if (search) params = params.set("search", search);
    if (assetsId) params = params.set("assetsId", assetsId);
    if (statuses) params = params.set("statuses", statuses);
    if (parameters)
      params = params
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);
    return this.http
      .get(environment.base_url + this.ENDPOINT_CONTRACTS_ASSETS, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  /////////////////////////////////////////////////// contracts/////////////////////////////////
  //grid call(Contracts)
  public getContractGrid(
    startFrom: any,
    perPage: any,
    search: any,
    statuses: any,
    parameters: any
  ) {
    let params = new HttpParams();
    if (startFrom) params = params.set("startFrom", startFrom);
    if (perPage) params = params.set("perPage", perPage);
    if (search) params = params.set("search", search);
    if (statuses) params = params.set("statuses", statuses);
    if (parameters)
      params = params
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);
    return this.http
      .get(environment.base_url + this.ENDPOINT_CONTRACTS_ASSETS, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getContractByCustomer(
    startFrom: any | number | boolean,
    perPage: any | number | boolean,
    search: any | number | boolean,
    statuses: any | number | boolean,
    parameters: any,
    customerId: any | number | boolean
  ) {
    let params = new HttpParams();
    params = params.set("customerId", customerId);
    if (startFrom) params = params.set("startFrom", startFrom);
    if (perPage) params = params.set("perPage", perPage);
    if (search) params = params.set("search", search);
    if (statuses) params = params.set("statuses", statuses);
    if (parameters)
      params = params
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);
    return this.http
      .get(environment.base_url + "/v4/contracts/filter", {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //SO dd
  public getSalesOrder(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_CONTRACTS_SO + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /*****************************************Get Contract ASSETS Items*********************************************************************/

  public getContractAssetsItems(id: any) {
    // let params = new HttpParams();
    // params = params.set("salesOrderId", id);
    return this.http
      .get(environment.base_url + this.ENDPOINT_GET_CONTRACT_ASSETS_ITEMS, {
        observe: "response",
        // params: params
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }



  public getViewContract(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_CONTRACTS_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getAssetsPicklist() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_CONTRACTS_ASSETS_PICKLIST, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  public getBinPicklist(assetId: any) {
    return this.http
      .get(
        environment.base_url + this.ENDPOINT_CONTRACTS_BIN_PICKLIST + assetId,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  public getAccessoriesPicklist(assetId: any) {
    return this.http
      .get(
        environment.base_url +
        this.ENDPOINT_CONTRACTS_COMPONENTS_PICKLIST +
        assetId,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getCustodians(id: any) {
    let params = new HttpParams();
    params = params.set("customerId", id);
    return this.http
      .get(environment.base_url + this.ENDPOINT_CONTRACTS_BY_CUSTOMER_ID, {
        observe: "response",
        params: params
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public createContract(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_CONTRACTS, body, { observe: "response" })
      .pipe(
        map((resp: any) => {
          this.refreshContractList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public editContract(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_CONTRACTS, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshContractList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public deleteContract(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_CONTRACTS_DELETE + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshContractList$.emit("Deleted Contract");
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  public cancelContract(body: any, contractId: any) {
    return this.http
      .put(
        environment.base_url + this.ENDPOINT_CONTRACTS_CANCEL + contractId,
        body,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          this.refreshContractList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getBins() {
    return this.http
      .get(environment.base_url + "/bins/combination/pick-list", {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  // {ip}}/api/bins/combination/pick-list?type=location

  ///////////////////////////////// contract Item tab///////////////////////////////////////////
  public deleteContractItem(id: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_CONTRACTS_ITEM_DELETE + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshContractItem$.emit("Deleted Contract Item");
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public createContractItem(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_CONTRACTS_ITEM_ADD, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshContractItem$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getContractItemById(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_CONTRACTS_ITEM_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getAssetsSummeryById(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_ASSETS_SUMMERY + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getAssetsOverviewById(assetId: any, date:any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_ASSETS_OVERVIEW+"?assetsId="+assetId+"&currentDateTime="+date, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getAssetsTimeLineById(assetId: any, status:any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_ASSETS_TIMELINE+"?assetsId="+assetId+"&fieldName="+status, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }



  public getDataByAsset(assetsId: any) {
    let params = new HttpParams();
    params = params.set("assetsId", assetsId);
    return this.http
      .get(
        environment.base_url +
        this.ENDPOINT_ASSET_NAME_CODE_SERIALNO_PICKLIST,
        {
          observe: "response",
          params: params
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  ///////////////////////////////////////////////  Maintenance  //////////////////////////////////

  public getMaintenanceGrid(
    startFrom: any,
    perPage: any,
    search: any,
    assetsId: any,
    columnName: any,
    sortOrder: any
  ) {
    let params = new HttpParams();
    if (startFrom) params = params.set("startFrom", startFrom);
    if (perPage) params = params.set("perPage", perPage);
    if (search) params = params.set("search", search);
    if (assetsId) params = params.set("assetsId", assetsId);
    if (columnName) params = params.set("columnName", columnName);
    if (sortOrder) params = params.set("sortOrder", sortOrder);
    return this.http
      .get(environment.base_url + this.ENDPOINT_MAINTENANCE_ASSETS, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }


  public getContractMaintenanceGrid(
    startFrom: any,
    perPage: any,
    search: any,
    contractId: any,
    columnName: any,
    sortOrder: any
  ) {
    let params = new HttpParams();
    if (startFrom) params = params.set("startFrom", startFrom);
    if (perPage) params = params.set("perPage", perPage);
    if (search) params = params.set("search", search);
    if (contractId) params = params.set("contractId", contractId);
    if (columnName) params = params.set("columnName", columnName);
    if (sortOrder) params = params.set("sortOrder", sortOrder);
    return this.http
      .get(environment.base_url + '/contract-maintenances', {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getContractsDd(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_CONTRACT_PICKLIST + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          console.log("errr", error)
          return throwError(error);
        })
      );
  }

  public createMaintenance(body: any) {
    return this.http
      .post(environment.base_url + "/maintenances", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshMaintenanceList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public editMaintenance(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_MAINTENANCE_UPDATE, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshMaintenanceList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public deleteMaintenance(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_MAINTENANCE_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshMaintenanceList$.emit("Deleted Maintenance");
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getViewMaintenance(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_MAINTENANCE_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////// scheduled  Maintenance  //////////////////////////////////
  //inside maintenance view grid
  public getScheduledMaintenanceGrid(
    startFrom: any | number | boolean,
    perPage: any | number | boolean,
    search: any | number | boolean,
    maintenanceId: any | number | boolean,
    statuses: any | number | boolean,
    types: any | number | boolean,
    fromDate: any | number | boolean,
    toDate: any | number | boolean,
    serviceZoneId: any | number | boolean,
    columnName: any | number | boolean,
    sortOrder: any | number | boolean
  ) {
    let params = new HttpParams();
    if (startFrom) params = params.set("startFrom", startFrom);
    if (perPage) params = params.set("perPage", perPage);
    if (search) params = params.set("search", search);
    if (maintenanceId) params = params.set("maintenanceId", maintenanceId);
    if (statuses) params = params.set("statuses", statuses);
    if (types) params = params.set("types", types);
    if (serviceZoneId) params = params.set("serviceZoneId", serviceZoneId);
    if (fromDate !== "" && toDate !== "") {
      fromDate = fromDate + "T00:00:00Z";
      toDate = toDate + "T00:00:00Z";
      params = params
        .set("fromScheduledDateTime", fromDate)
        .set("toScheduledDateTime", toDate);
    }
    if (columnName) params = params.set("columnName", columnName);
    if (sortOrder) params = params.set("sortOrder", sortOrder);
    return this.http
      .get(environment.base_url + this.ENDPOINT_MAINTENANCE_ITEMS_ASSETS, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  //magnum dashboard - PREVENTATIVE MAINTENANCE
  public getMagnumScheduledMaintenanceGridAll(
    startFrom: any | number | boolean,
    perPage: any | number | boolean,
    search: any | number | boolean,
    statuses: any | number | boolean,
    types: any | number | boolean,
    fromDate: any | number | boolean,
    toDate: any | number | boolean,
    serviceZoneId: any | number | boolean,
    roleId: any | number | boolean,
    columnName: any | number | boolean,
    sortOrder: any | number | boolean
  ) {
    let params = new HttpParams();
    if (startFrom) params = params.set("startFrom", startFrom);
    if (perPage) params = params.set("perPage", perPage);
    if (search) params = params.set("search", search);
    if (statuses) params = params.set("statuses", statuses);
    if (types) params = params.set("types", types);
    if (serviceZoneId) params = params.set("serviceZoneId", serviceZoneId);
    if (roleId) params = params.set("roleId", roleId);
    if (fromDate !== "" && toDate !== "") {
      //fromDate = fromDate +'T00:00:00Z';
      //toDate = toDate +'T23:59:00Z';
      toDate = toDate + "T15:59:00Z";

      params = params
        .set("fromScheduledDateTime", fromDate)
        .set("toScheduledDateTime", toDate);
    }
    if (columnName) params = params.set("columnName", columnName);
    if (sortOrder) params = params.set("sortOrder", sortOrder);
    return this.http
      .get(environment.base_url + this.ENDPOINT_MAINTENANCE_ITEMS, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  //outside grid
  public getScheduledMaintenanceGridAll(
    startFrom: any | number | boolean,
    perPage: any | number | boolean,
    search: any | number | boolean,
    statuses: any | number | boolean,
    types: any | number | boolean,
    fromDate: any | number | boolean,
    toDate: any | number | boolean,
    serviceZoneId: any | number | boolean,
    columnName: any | number | boolean,
    sortOrder: any | number | boolean,
    isDynamic: boolean
  ) {
    let params = new HttpParams();
    if (startFrom) params = params.set("startFrom", startFrom);
    if (perPage) params = params.set("perPage", perPage);
    if (search) params = params.set("search", search);
    if (statuses) params = params.set("statuses", statuses);

    if (isDynamic) {
      if (types && types !== null && types.length > 0) params = params.set("jobOrderType", types);
    } else {
      if (types && types !== null && types.length > 0) params = params.set("types", types);

    }

    if (serviceZoneId) params = params.set("serviceZoneId", serviceZoneId);
    if (fromDate !== "" && toDate !== "") {
      fromDate = fromDate + "T00:00:00Z";
      toDate = toDate + "T23:59:00Z";
      params = params
        .set("fromScheduledDateTime", fromDate)
        .set("toScheduledDateTime", toDate);
    }
    if (columnName) params = params.set("columnName", columnName);
    if (sortOrder) params = params.set("sortOrder", sortOrder);
    return this.http
      .get(environment.base_url + this.ENDPOINT_MAINTENANCE_ITEMS, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //inside assets view grid
  public getScheduledMaintenanceGridAssets(
    startFrom: any | number | boolean,
    perPage: any | number | boolean,
    search: any | number | boolean,
    assetsId: any | number | boolean,
    statuses: any | number | boolean,
    types: any | number | boolean,
    fromDate: any | number | boolean,
    toDate: any | number | boolean,
    serviceZoneId: any | number | boolean,
    columnName: any | number | boolean,
    sortOrder: any | number | boolean
  ) {
    let params = new HttpParams();
    if (startFrom) params = params.set("startFrom", startFrom);
    if (perPage) params = params.set("perPage", perPage);
    if (search) params = params.set("search", search);
    if (assetsId) params = params.set("assetsId", assetsId);
    if (statuses) params = params.set("statuses", statuses);
    if (types) params = params.set("types", types);
    if (serviceZoneId) params = params.set("serviceZoneId", serviceZoneId);
    if (fromDate !== "" && toDate !== "") {
      fromDate = fromDate + "T00:00:00Z";
      toDate = toDate + "T00:00:00Z";
      params = params
        .set("fromScheduledDateTime", fromDate)
        .set("toScheduledDateTime", toDate);
    }
    if (columnName) params = params.set("columnName", columnName);
    if (sortOrder) params = params.set("sortOrder", sortOrder);
    return this.http
      .get(environment.base_url + this.ENDPOINT_MAINTENANCE_ITEMS_ASSETS, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getMaintenancePicklist() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_MAINTENANCE_PICKLIST, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  public getMaintenanceByAssetIdPicklist(assetsId: any) {
    return this.http
      .get(
        environment.base_url + this.ENDPOINT_MAINTENANCE_BY_ASSET_ID + assetsId,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getActiveUsers() {
    return this.http.get(environment.base_url + "/v4/users/active").pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public createScheduledMaintenance(body: any) {
    return this.http
      .post(environment.base_url + '/maintenance-items', body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshScheduledMaintenanceList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public editScheduledMaintenance(body: any) {
    return this.http
      .put(environment.base_url + '/maintenance-items', body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshScheduledMaintenanceList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  public getViewScheduledMaintenance(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_MAINTENANCE_ITEMS_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public deleteScheduledMaintenance(id: any) {
    return this.http
      .delete(
        environment.base_url + this.ENDPOINT_MAINTENANCE_ITEMS_BY_ID + id,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          this.refreshScheduledMaintenanceList$.emit(
            "Deleted Preventative Maintenance"
          );
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getJOPicklist() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_JOBORDERS_PICKLIST, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getMaintenanceByAssets(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_MAINTENANCE_BY_ASSETS + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////// accessories //////////////////////////////////
  //grid call(assets - view - accessories)
  public getAssetAccessoriesGrid(
    startFrom: any | number | boolean,
    perPage: any | number | boolean,
    search: any | number | boolean,
    assetsId: any | number | boolean,
    statuses: any | number | boolean,
    parameters: any
  ) {
    let params = new HttpParams();
    if (startFrom) params = params.set("startFrom", startFrom);
    if (perPage) params = params.set("perPage", perPage);
    if (search) params = params.set("search", search);
    if (assetsId) params = params.set("assetsId", assetsId);
    if (statuses) params = params.set("statuses", statuses);
    if (parameters)
      params = params
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);
    return this.http
      .get(environment.base_url + this.ENDPOINT_ACCESSORIES_BY_ID, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public createAccessories(body: any) {
    return this.http
      .post(environment.base_url + "/asset-components", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshAccessories$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public editAccessories(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_ACCESSORIES, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshAccessories$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  public getAccessoriesById(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_ACCESSORIES_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public deleteAccessories(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_ACCESSORIES_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshAccessories$.emit("Deleted Accessories");
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //**================================== Consignment  =========================*/

  public getAllConsignments(
    status: any,
    company: any,
    searchValue: any,
    isSearch: any,
    attentionToId: any,
    parameters: any
  ) {
    let params = new HttpParams();
    if (status !== "" && status != null) {
      params = params.set("status", status);
    }
    if (company !== "" && company != null) {
      params = params.set("company", company);
    }
    if (searchValue !== "" && searchValue != null) {
      params = params.set("search", searchValue);
    }
    params = params.set("isSearch", isSearch);
    if (attentionToId !== "" && attentionToId != null) {
      params = params.set("attentionToId", attentionToId);
    }
    if (parameters) {
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25")
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);
    }
    return this.http
      .get(`${environment.base_url}/consignment-notes/filter`, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getAllUsers() {
    return this.http.get(environment.base_url + this.ENDPOINT_LIST_USERS).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public createConsignment(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_SAVE_CONSIGNMENT, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshConsignmentList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public editConsignment(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_SAVE_CONSIGNMENT, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshConsignmentList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getByIdConsignment(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_GET_BY_ID_CONSIGNMENT + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getAccesoriesBinDetaisl(ids: any) {
    // return this.http
    //   .get(environment.base_url + this.ENDPOINT_GET_ASSETS_ACCESORIES_BIN_DETAILS + ids)
    //   .pipe(
    //     map((resp: any) => {
    //       return resp;
    //     }),
    //     catchError((error) => {
    //       return throwError(error);
    //     })
    //   );

      let params = new HttpParams();
      if (ids) params = params.set("assetsIds", ids);
      return this.http
        .get(environment.base_url + this.ENDPOINT_GET_ASSETS_ACCESORIES_BIN_DETAILS, {
          observe: "response",
          params: params,
        })
        .pipe(
          map((resp: any) => {
            return resp;
          }),
          catchError((error) => {
            return throwError(error);
          })
        );
  }

  public deleteConsignment(id: any) {
    return this.http
      .delete(environment.base_url + "/consignment-notes/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshConsignmentList$.emit("Deleted Consignment");
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public generateConsignmentPDF(id: any) {
    const formData = new FormData();
    let h = new Headers();
    h.append("Authorization", `Bearer ${this.auth.getToken()}`);
    h.append("Content-Type", "application/json");
    let options = new RequestOptions({ headers: h });
    h.append("Accept", "application/pdf");
    options.responseType = ResponseContentType.Blob;

    return this.httpOnly
      .put(
        environment.base_url + "/consignment-notes/download-pdf/" + id,
        formData,
        options
      )
      .pipe(
        map((resp: any) => {
          this.refreshConsignmentList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public uploadConsignmentAttachment(parentId: any, formData: any) {
    const headers = new Headers({});
    headers.append("Authorization", "Bearer " + this.auth.getToken());
    let options = new RequestOptions({ headers: headers });
    return this.httpOnly
      .post(
        environment.base_url +
        this.ENDPOINT_CONSIGNMENT_ATTACHMENTS_UPLOAD +
        parentId,
        formData,
        options
      )
      .pipe(
        map((resp: any) => {
          this.refreshPhoto$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getSentEmail(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_CONSIGNMENT_SENT_EMAIL + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          //return this.http.get( environment.base_url+this.ENDPOINT_CONSIGNMENT_SENT_EMAIL+id).map( (resp:any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public cancelConsignment(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_CANCEL_CONSIGNMENT + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshConsignmentList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public rejectConsignment(id: any, comment: any) {
    return this.http
      .get(
        environment.base_url +
        this.ENDPOINT_REJECT_CONSIGNMENT +
        id +
        "?comment=" +
        comment,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          this.refreshConsignmentList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public approveConsignment(id: any, comment: any) {
    return this.http
      .get(
        environment.base_url +
        this.ENDPOINT_APPROVE_CONSIGNMENT +
        id +
        "?comment=" +
        comment,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          this.refreshConsignmentList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //**================================== Consignment  Items =========================*/

  public getAllConsignmentItems(id: any) {
    // return this.http.get( environment.base_url+this.ENDPOINT_LIST_CONSIGNMENT_ITEMS).map( (resp:any) => {
    return this.http
      .get(
        environment.base_url + "/consignment-items/by-consignment-note/" + id
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public createConsignmentItem(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_LIST_CONSIGNMENT_ITEMS, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshConsignmentItemList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public editConsignmentItem(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_LIST_CONSIGNMENT_ITEMS, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshConsignmentItemList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getConsignmentItemById(id: any) {
    return this.http
      .get(
        environment.base_url + this.ENDPOINT_ITEM_BY_ID_CONSIGNMENT_ITEMS + id
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public deleteConsignmentItem(id: any) {
    return this.http
      .delete(environment.base_url + "/consignment-items/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshConsignmentItemList$.emit("Deleted Consignment Item");
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //**================================== Requisition =========================*/

  public getAllRequisition(
    status: any,
    company: any,
    searchValue: any,
    isSearch: any | number | boolean,
    approvedByUserId: any,
    hodApprovedByUserId: any,
    parameters: any
  ) {
    let params = new HttpParams();
    if (status !== "" && status != null) {
      params = params.set("status", status);
    }
    if (company !== "" && company != null) {
      params = params.set("company", company);
    }
    if (searchValue !== "" && searchValue != null) {
      params = params.set("search", searchValue);
    }
    params = params.set("isSearch", isSearch);
    if (approvedByUserId !== "" && approvedByUserId != null) {
      params = params.set("approvedByUserId", approvedByUserId);
    }
    if (hodApprovedByUserId !== "" && hodApprovedByUserId != null) {
      params = params.set("hodApprovedByUserId", hodApprovedByUserId);
    }
    if (parameters) {
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25")
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);
    }
    return this.http
      .get(`${environment.base_url}/requisition-notes/filter`, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public createRequisition(body: any) {
    return this.http
      .post(environment.base_url + "/requisition-notes", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshRequisitionList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public editRequisition(body: any) {
    return this.http
      .put(environment.base_url + "/requisition-notes", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshRequisitionList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getByIdRequisition(id: any) {
    return this.http
      .get(environment.base_url + "/requisition-notes/" + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public deleteRequisition(id: any) {
    return this.http
      .delete(environment.base_url + "/requisition-notes/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshRequisitionList$.emit("Deleted Requisition");
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public uploadRequisitionAttachment(parentId: any, formData: any) {
    const headers = new Headers({});
    headers.append('Authorization', 'Bearer ' + this.auth.getToken());
    let options = new RequestOptions({ headers: headers })
    return this.httpOnly.post(environment.base_url + this.ENDPOINT_REQUISION_ATTACHMENTS_UPLOAD + parentId, formData, options)
      .pipe(
        map((resp: any) => {
          this.refreshPhoto$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getVerifiedApprove(
    id: any,
    comment: any,
    submitToHod: any,
    picToNotify: any
  ) {
    return this.http
      .get(
        environment.base_url +
        "/requisition-notes/approve-verification/" +
        id +
        "?comment=" +
        comment +
        "&submitToHod=" +
        submitToHod +
        "&picId=" +
        picToNotify,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          // return this.http.get( environment.base_url+'/requisition-notes/approve-verification/'+id+'?comment='+comment+'&submitToHod='+submitToHod).map( (resp:any) => {
          this.refreshRequisitionList$.emit(resp); //to refresh view page
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getVerifiedReject(id: any, comment: any, submitToHod: any) {
    return this.http
      .get(
        environment.base_url +
        "/requisition-notes/reject-verification/" +
        id +
        "?comment=" +
        comment +
        "&submitToHod=" +
        submitToHod,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          //return this.http.get( environment.base_url+'/requisition-notes/reject-verification/'+id+'?comment='+comment+'&submitToHod='+submitToHod).map( (resp:any) => {
          this.refreshRequisitionList$.emit(resp); //to refresh view page
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getHODApprove(id: any, comment: any) {
    return this.http
      .get(
        environment.base_url +
        "/requisition-notes/approve-hod-verification/" +
        id +
        "?comment=" +
        comment,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          //return this.http.get( environment.base_url+'/requisition-notes/approve-hod-verification/'+id+'?comment='+comment).map( (resp:any) => {
          this.refreshRequisitionList$.emit(resp); //to refresh view page
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getHODReject(id: any, comment: any) {
    return this.http
      .get(
        environment.base_url +
        "/requisition-notes/reject-hod-verification/" +
        id +
        "?comment=" +
        comment,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          //return this.http.get( environment.base_url+'/requisition-notes/reject-hod-verification/'+id+'?comment='+comment).map( (resp:any) => {
          this.refreshRequisitionList$.emit(resp); //to refresh view page
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getSubmitForVerification(id: any) {
    return this.http
      .get(
        environment.base_url +
        "/requisition-notes/submit-for-verification/" +
        id,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          //return this.http.get( environment.base_url+'/requisition-notes/submit-for-verification/'+id).map( (resp:any) => {
          this.refreshRequisitionList$.emit(resp); //to refresh view page
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public generateRequisitionPDF(id: any) {
    const formData = new FormData();
    let h = new HttpHeaders();
    h.append("Authorization", `Bearer ${this.auth.getToken()}`);
    h.append("Content-Type", "application/json");
    h.append("Accept", "application/pdf");
    let options = {
      headers: h,
      responseType: "blob" as "blob",
    };

    return this.http
      .put(
        environment.base_url + "/requisition-notes/download-pdf/" + id,
        formData,
        options
      )
      .pipe(
        map((resp: any) => {
          this.refreshConsignmentList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public cancelRequisition(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_CANCEL_REQUISION + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshRequisitionList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //**================================== Requisition Item =========================*/

  public getAllRequisitionItems(id: any) {
    //return this.http.get( environment.base_url+'/requisition-items').map( (resp:any) => {
    return this.http
      .get(
        environment.base_url + "/requisition-items/by-requisition-note/" + id
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  public createRequisitionItem(body: any) {
    return this.http
      .post(environment.base_url + "/requisition-items", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshRequisitionItemList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public editRequisitionItem(body: any) {
    return this.http
      .put(environment.base_url + "/requisition-items", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshRequisitionItemList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getRequisitionItemById(id: any) {
    return this.http
      .get(environment.base_url + "/requisition-items/" + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public deleteRequisitionItem(id: any) {
    return this.http
      .delete(environment.base_url + "/requisition-items/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshRequisitionItemList$.emit("Deleted Requisition Item");
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //**================================== asset History tab =========================*/
  public geAssetHistory(
    startFrom: any | number | boolean,
    perPage: any | number | boolean,
    search: any | number | boolean,
    assetsId: any | number | boolean,
    columnName: any | number | boolean,
    sortOrder: any | number | boolean
  ) {
    let params = new HttpParams();
    if (startFrom) params = params.set("startFrom", startFrom);
    if (perPage) params = params.set("perPage", perPage);
    if (search) params = params.set("search", search);
    if (columnName) params = params.set("columnName", columnName);
    if (sortOrder) params = params.set("sortOrder", sortOrder);
    if (assetsId) params = params.set("assetsId", assetsId);
    return this.http
      .get(environment.base_url + "/bin-inventory-histories", {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  enableButton(data: any) {
    this.refreshButton$.emit(data);
  }

  public getAssetByFilter(assetName: any,siteId:any = null) {
    let params = new HttpParams();

    if (assetName) params = params.set("search", assetName);
    if (siteId) params = params.set("siteId", siteId);
    return this.http
      .get(
        environment.base_url +
          "/v4-1/assets/filter?startFrom=1&perPage=50&columnName=name&sortOrder=asc",
        { observe: "response", params: params }
      )
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  /**================================== List Of Asset =========================*/
  public getAssetList(
    serviceZoneName: any,
    assetId: any,
    searchValue: any,
    assetTypes: any,
    assetStatuses: any,
    fromDate: any,
    toDate: any,
    parameters: any,
    siteId: any
  ) {
    let params = new HttpParams();
    if (serviceZoneName)
      params = params.set("serviceZoneName", serviceZoneName);
    if (assetId) params = params.set("assetId", assetId);
    if (searchValue) params = params.set("search", searchValue);

    if (siteId) params = params.set("siteId", siteId);

    if (assetTypes) params = params.set("assetTypes", assetTypes);
    if (assetStatuses) params = params.set("assetStatuses", assetStatuses);
    if (fromDate && toDate) {
      fromDate = fromDate + "T00:00:00Z";
      toDate = toDate + "T23:59:00Z";
      params = params
        .set("nextMaintenanceFromDateTime", fromDate)
        .set("nextMaintenanceToDateTime", toDate);
    }

    if (parameters)
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25")
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);

    return this.http
      .get(environment.base_url + "/v4-1/assets/filter", {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getAllAssets(
    serviceZoneName: any,
    assetId: any,
    customerId: any,
    siteId: any,
    searchValue: any,
    assetTypes: any,
    assetStatuses: any,
    fromDate: any,
    toDate: any,
    origin: any,
    parameters: any
  ) {
    let params = new HttpParams();
    if (serviceZoneName)
      params = params.set("serviceZoneName", serviceZoneName);
    if (assetId) params = params.set("assetId", assetId);
    if (customerId) params = params.set("customerId", customerId);
    if (siteId) params = params.set("siteId", siteId);

    if (searchValue) params = params.set("search", searchValue);

    if (assetTypes) params = params.set("assetTypes", assetTypes);
    if (assetStatuses) params = params.set("assetStatuses", assetStatuses);
    if (fromDate && toDate) {
      fromDate = fromDate + "T00:00:00Z";
      toDate = toDate + "T23:59:00Z";
      params = params
        .set("nextMaintenanceFromDateTime", fromDate)
        .set("nextMaintenanceToDateTime", toDate);
    }

    if (origin && origin !== null && origin !== '') params = params.set("origin", origin);

    if (parameters)
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25")
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);

    return this.http
      .get(environment.base_url + "/v4-1/assets/filter", {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getAssetsByName(search: any) {
    return this.http
      .get(
        environment.base_url +
        "/v4-1/assets/filter?startFrom=1&perPage=50&search=" +
        search +
        "&columnName=name&sortOrder=asc"
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  /******************************************** Search Asset By Customer Id ******************************************/
  public getAssets(custId: any = null, assetName: any = null) {
    let params = new HttpParams();

    if (custId !== "" && custId != null) {
      params = params.set("customerId", custId);
    }
    if (assetName !== "" && assetName != null) {
      params = params.set("name", assetName);
    }
    return this.http
      .get(`${environment.base_url}/v4/assets/pick-list`, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  /******************************************** delete asset by id ******************************************/
  public deleteAsset(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_DELETE_ASSET + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************** create asset ******************************************/
  public createAsset(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_SAVE_ASSET_POST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************** edit asset ******************************************/
  public editAsset(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_SAVE_ASSET_PUT, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  public getListColumn(apiModule: any) {
    return this.http.get(environment.base_url + '/table-column-setups/by-module?apiModule=' + apiModule).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  assetLogData(searchValue: any, parameters: any, apiParams: any) {
    let params = new HttpParams();

    if (searchValue !== '' && searchValue != null) {
      params = params.set('search', searchValue);
    }
    if (apiParams.fromDate) {
      params = params.set('fromDateTime', apiParams.fromDate);
    }
    if (apiParams.toDate) {
      params = params.set('toDateTime', apiParams.toDate);
    }
    if (apiParams.assetId) {
      params = params.set('assetId', apiParams.assetId);
    }
    if (apiParams.modifiedBy) {
      params = params.set('modifiedBy', apiParams.modifiedBy);
    }
    params = params.set('startFrom', parameters.startRow)
    .set('perPage', '25')
    .set('columnName', parameters.sortModel[0].colId)
    .set('sortOrder', parameters.sortModel[0].sort);
    return this.http.get(environment.base_url+this.ENDPOINT_ASSETS_LOG , { params: params,  }).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getReadingDynamicForm(assetId: any) {
    let params = new HttpParams();

    params = params.set('formSchemaName', 'HourMeter');
    params = params.set('parentName', 'assets');
    params = params.set('parentId', assetId);
    return this.http.get(environment.node_url + '/v1/dynamic-form/schemas/template', { observe: "response", params: params }).pipe(
      map((resp: any) => {
        if (resp.status == 204) {
          return false;
        } else {
          return resp.body;
        }
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  deleteReadingDynamic(id: any) {
    return this.http.delete(environment.node_url + '/v1/dynamic-form/data/HourMeter/' + id, { observe: 'response' }).pipe(
      map((resp: any) => {
        this.refreshAssetLogList$.emit('Deleted Reading');
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getMaintenanceFrequencyPicklist(maintenanceId: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_MAINTENANCE_FREQUENCY_PICKLIST + '?maintenanceId=' + maintenanceId, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }



  /////////////////////////////////////////////// accessories //////////////////////////////////
  //grid call(contracts - view - accessories)
  public getContractAccessoriesGrid(
    startFrom: any | number | boolean,
    perPage: any | number | boolean,
    search: any | number | boolean,
    contractId: any | number | boolean,
    statuses: any | number | boolean,
    parameters: any
  ) {
    let params = new HttpParams();
    if (startFrom) params = params.set("startFrom", startFrom);
    if (perPage) params = params.set("perPage", perPage);
    if (search) params = params.set("search", search);
    if (contractId) params = params.set("contractId", contractId);
    if (statuses) params = params.set("statuses", statuses);
    if (parameters)
      params = params
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);
    return this.http
      .get(environment.base_url + this.ENDPOINT_CONTRACT_ACCESSORIES, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getContractAssetsGrid(
    startFrom: any | number | boolean,
    perPage: any | number | boolean,
    search: any | number | boolean,
    contractId: any | number | boolean,
    statuses: any | number | boolean,
    parameters: any
  ) {
    let params = new HttpParams();
    if (startFrom) params = params.set("startFrom", startFrom);
    if (perPage) params = params.set("perPage", perPage);
    if (search) params = params.set("search", search);
    if (contractId) params = params.set("contractId", contractId);
    if (statuses) params = params.set("statuses", statuses);
    if (parameters)
      params = params
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);
    return this.http
      .get(environment.base_url + this.ENDPOINT_CONTRACT_ASSETS, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getContractMaintenanceById(id: any) {
    return this.http
      .get(environment.base_url + '/contract-maintenances/' + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  deleteContractMaintenance(id: any) {
    return this.http.delete(environment.node_url + '/contract-maintenances/' + id, { observe: 'response' }).pipe(
      map((resp: any) => {
        this.refreshAssetLogList$.emit('Deleted Reading');
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public createContractMaintenance(body: any) {
    return this.http
      .post(environment.base_url + "/contract-maintenances", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshMaintenanceList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  public contractAssets(id: any) {
    return this.http.put(environment.base_url + '/contracts/remove/contract-item?contractItemId=' + id, { observe: 'response' }).pipe(map((resp: any) => {
      this.refreshMaintenanceList$.emit('Deleted Contract Assets');
      return resp;
    }
    ), catchError(error => {
      //console.log(error);
      return throwError(error);
    }),);
  }


  public createAssetLog(body: any) {
    return this.http.post(environment.base_url + this.ENDPOINT_ASSETS_LOG, body, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      //console.log(error);
      return throwError(error);
    }),);
  }

  public updateAssetLog(body: any) {
    return this.http.put(environment.base_url + this.ENDPOINT_ASSETS_LOG, body, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      //console.log(error);
      return throwError(error);
    }),);
  }

  public getAssetsLogModifiedUsers() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_ASSETS_LOG_MODIFIED_USERS)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  deleteAssetLog(id: any) {
    return this.http.delete(environment.base_url + this.ENDPOINT_ASSETS_LOG + '/' + id, { observe: 'response' }).pipe(map((resp: any) => {
      this.refreshAssetLogList$.emit('Deleted Status');
      return resp;
    }
    ), catchError(error => {
      //console.log(error);
      return throwError(error);
    }),);
  }



}






