import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from 'src/app/_services/admin/users.service';
import { AiService } from 'src/app/_services/admin/ai.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { response } from 'express';
import { marked } from 'marked';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit {
  userMessage: string | undefined;
  chatInput!: HTMLTextAreaElement;
  sendChatBtn!: HTMLSpanElement;
  chatbox!: HTMLDivElement;
  showBotElement!: HTMLDivElement;
  chatToggle!: HTMLButtonElement;
  isExpanded: boolean = false;
  @ViewChild('chatbox') private chatBoxElement!: ElementRef;
  @ViewChild('layout-config') private layoutChat!: ElementRef;

  apidata: SafeHtml | null = null;
  active: boolean = false;
  outsideClickListener: any;
  chatboxWidth: number = 506;
  expansionIncrement: number = 150;
  isAiDataLoaded: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    private usersService: UsersService,
    private aiService: AiService,
    private errorUtil: ErrorUtil,
    private auth: AuthenticationService,
    private sanitizer: DomSanitizer,
    private el: ElementRef
  ) { }

  ngOnInit(): void {
    this.loadChatbot();
  }

  createChatLi(message: string, className: string): HTMLElement {
    const chatLi = document.createElement("li");
    chatLi.classList.add("chat", className);
  
    const messageElement = document.createElement("div");
    messageElement.classList.add("chat-message");
  
    if (className === "incoming") {
      messageElement.innerHTML = message;
    } else {
      const pElement = document.createElement("p");
      pElement.innerText = message;
      messageElement.appendChild(pElement);
    }
  
    chatLi.appendChild(messageElement);
    return chatLi;
  }

  handleChat(): void {
    if (!this.chatInput) return;

    this.userMessage = this.chatInput.value.trim();
    if (!this.userMessage) return;
  
    const outgoingMessage = this.createChatLi(this.userMessage, "outgoing");
    this.chatBoxElement.nativeElement.appendChild(outgoingMessage);
    this.chatInput.value = '';
    outgoingMessage.scrollIntoView({ behavior: 'smooth', block: 'end' });
  
    // setTimeout(() => {
    //   const incomingMessage = this.createChatLi("Hello Hari! You sound worried.", "incoming");
    //   this.chatBoxElement.nativeElement.appendChild(incomingMessage);
    //   incomingMessage.scrollIntoView({ behavior: 'smooth', block: 'end' });
    // }, 300);
  }

  scrollToBottom(): void {
    try {
      const chatbox = this.chatBoxElement.nativeElement;
      chatbox.scrollTop = chatbox.scrollHeight;
    } catch (err) {
      console.log('Error scrolling to bottom:', err);
    }
  }

  getConnectWithAI() {
    if (this.isAiDataLoaded) return;
    this.spinner.show();
    this.aiService.getConnectWithAI('2024-09-01T06:19:09Z', '2024-09-27T08:59:29Z').subscribe(
      //this.auth.getUserId()
      //2024-09-01T06:19:09Z
      //2024-09-27T08:59:29Z
      //fromDateTime
      //toDateTime
        (response: any) => {
            if (response && response.body && response.body.data) {
                var rawHtml: any = marked.parse(response.body.data);
                this.apidata = this.sanitizer.bypassSecurityTrustHtml(rawHtml);
                setTimeout(() => this.scrollToBottom(), 0);
                this.isAiDataLoaded = true;
            } else {
                console.error("Unexpected response structure:", response);
                this.apidata = this.sanitizer.bypassSecurityTrustHtml('<p>Something went wrong.</p>');
            }
            this.spinner.hide();
        },
        (error) => {
            this.spinner.hide();
            this.errorUtil.setErrorMessage(error);
            this.errorUtil.setErrorMessage(
                error?.error?.status,
                error?.error?.title,
                null,
                "error",
                2000
            );
        }
    );
}

  

  loadChatbot(): void {
    this.chatBoxElement = this.el.nativeElement.querySelector('.chatbox');
    this.chatInput = document.querySelector<HTMLTextAreaElement>(".chat-input textarea")!;
    this.sendChatBtn = document.querySelector<HTMLSpanElement>(".chat-input span")!;

    if (this.sendChatBtn) {
      this.sendChatBtn.addEventListener("click", this.handleChat.bind(this));
    } else {
      console.warn("Send button not found");
    }
  }

  isOutsideClicked(event) {
    return !(this.el.nativeElement.isSameNode(event.target) || this.el.nativeElement.contains(event.target));
  }

  bindOutsideClickListener() {
    if (!this.outsideClickListener) {
        this.outsideClickListener = (event) => {
            if (this.active && this.isOutsideClicked(event)) {
                this.active = false;
            }
        };
        document.addEventListener('click', this.outsideClickListener);
    }
  }

  unbindOutsideClickListener() {
    if (this.outsideClickListener) {
        document.removeEventListener('click', this.outsideClickListener);
        this.outsideClickListener = null;
    }
  }

  toggleConfigurator(event: Event) {
    this.active = !this.active;
    event.preventDefault();

    if (this.active) {
      this.bindOutsideClickListener();
      // Call getConnectWithAI only when the chatbot is first opened
      this.getConnectWithAI();
    } else {
      this.unbindOutsideClickListener();
    }
  }

  hideConfigurator(event) {
    this.active = false;
    this.unbindOutsideClickListener();
    event.preventDefault();
  }

  toggleChatboxWidth(): void {
    this.isExpanded = !this.isExpanded;
  }

  onChipClick(chipLabel: string): void {
    console.log(`${chipLabel} clicked`);
  }
}