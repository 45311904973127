<div class=""  *ngIf="translate">
    <div class="custella-modal d-flex flex-column">
        <div class="custella-modal-header">
            <div class="custella-modal-header-text" id="title_SetWorkflowNotificationPreviewEmail">
                <span>{{translate?.setup.configuration.customForm.sections.sec20.singular}}</span>
            </div>
            <i class="pi pi-times pt-1"  [pTooltip]="translate?.general.buttons.cancel.singular" tooltipPosition="left"  (click)="modalRef.hide()" id="btn_SetWorkflowNotificationPreviewEmailClose"></i>
        </div>
    </div>  
    <form  [formGroup]="addFormSteps">
    <div class="custella-modal-body">
        <div class="custella-form-container box-none m-0">
            <div class="custella-form-container-title-two-grey fst-italic">
                <div class="text-12 font-medium">{{translate?.setup.configuration.customForm.messages.selectLayout}}</div>
            </div>
            <div class="custella-form-container-content my-3">
                <div class="custella-content-row">
                    <div class="mb-3 col-12 flex-align-center"> 
                        <span class="text-12 me-3 col-2 text-nowrap" >{{translate?.setup.configuration.customForm.fields.sectionName.singular }}</span>
                        <input type="text" pInputText autocomplete="off" id="input_SetInvLocShortCodeField" maxlength="40" [(ngModel)]="formStep.label" formControlName="label"  name="label" id="label" />
                        <div *ngIf="addFormSteps.controls['label'].touched && addFormSteps.controls['label'].invalid">
                            <span class="text-10 p-error" *ngIf="addFormSteps.controls['label'].errors?.required" id="label_SetInvLocShortCodeReqField" >{{translate?.general.validationFE.requiredField }}</span>
                        </div>
                    </div>
                </div>
                <div class="custella-content-row justify-content-center">
                    <div class="custella-content-row-input">
                        <div class="flex-align-center gap-3 mb-2">
                            <div (click)="setFormLayoutType('oneColumn')" class="cursor-pointer">
                                <div class="box" [ngClass]="{'border-3':formStep.layoutType == 'oneColumn'}" ></div>
                                <button pButton type="button" class="rounded-default-button me-2" id="btn_SetOrgTaxNull"  [ngClass]="{'rounded-chosen-button':formStep.layoutType == 'oneColumn'}" >{{translate?.setup.configuration.customForm.fields.oneColumn.singular}}</button>
                            </div>
                            <div (click)="setFormLayoutType('twoColumn')" class="cursor-pointer">
                                <div class="container-box">
                                    <div class="box2" [ngClass]="{'border-3':formStep.layoutType == 'twoColumn'}"></div>
                                    <div class="box3" [ngClass]="{'border-3':formStep.layoutType == 'twoColumn'}"></div>
                                </div>
                                <button pButton type="button" class="rounded-default-button me-2" id="btn_SetOrgTaxNull"  [ngClass]="{'rounded-chosen-button':formStep.layoutType == 'twoColumn'}" >{{translate?.setup.configuration.customForm.fields.twoColumn.singular}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="custella-form-container-footer-two">
                <div class="d-flex align-items-center justify-content-end">
                    <button class="custella-btn-white text-12 ms-2" (click)="modalRef.hide()">{{translate?.general.buttons.cancel.singular}}</button>
                    <p-button [label]="translate?.general.buttons.save.singular" styleClass="ms-2 h-32" (onClick)="saveData()"></p-button>
                </div>

            </div>
        </div>
    </div>
    </form>
</div>