import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BsModalService, ModalModule } from "ngx-bootstrap/modal";
/* PrimeNg Modules */
import { TreeModule } from "primeng/tree";
import { TreeTableModule } from "primeng/treetable";

import { MegaMenuModule } from "primeng/megamenu";
import { MenubarModule } from "primeng/menubar";
import { InputTextModule } from "primeng/inputtext";
import { CheckboxModule } from "primeng/checkbox";
import { DropdownModule } from "primeng/dropdown";
import { SelectButtonModule } from "primeng/selectbutton";
import { MultiSelectModule } from "primeng/multiselect";
import { CalendarModule } from "primeng/calendar";
import { ButtonModule } from "primeng/button";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { TagModule } from "primeng/tag";
import { InputTextareaModule } from "primeng/inputtextarea";
import { ChipsModule } from "primeng/chips";
import { AvatarModule } from "primeng/avatar";
import { AvatarGroupModule } from "primeng/avatargroup";
import { GMapModule } from "primeng/gmap";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { DialogModule } from "primeng/dialog";
import { TabMenuModule } from "primeng/tabmenu";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { ToastModule } from "primeng/toast";
import { TooltipModule } from "primeng/tooltip";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { MenuModule } from "primeng/menu";
import { BadgeModule } from "primeng/badge";
import { SkeletonModule } from "primeng/skeleton";
import { NgxSpinnerModule } from "ngx-spinner";
import { TabViewModule } from "primeng/tabview";
import { AccordionModule } from "primeng/accordion";
import { SlideMenuModule } from "primeng/slidemenu";
import { TableModule } from "primeng/table";
import { ToggleButtonModule } from "primeng/togglebutton";
import { FileUploadModule } from "primeng/fileupload";
import { InputSwitchModule } from "primeng/inputswitch";
import { AutoCompleteModule } from "primeng/autocomplete";
import { ProgressBarModule } from "primeng/progressbar";
import { RadioButtonModule } from "primeng/radiobutton";
import { ColorPickerModule } from "primeng/colorpicker";
import { PickListModule } from "primeng/picklist";
import { CardModule } from "primeng/card";
import { DragDropModule } from "primeng/dragdrop";
import {ImageModule} from 'primeng/image';
import {SidebarModule} from 'primeng/sidebar';
import {SplitterModule} from 'primeng/splitter';
import {ListboxModule} from 'primeng/listbox';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {StepsModule} from 'primeng/steps';
import {MenuItem} from 'primeng/api';
import { ChipModule } from 'primeng/chip';
///////////////////////////////////////////////////
import { FileSaverModule } from "ngx-filesaver";
import { CurrencyMaskModule } from "ng2-currency-mask";
///////////////////////////////////////////////
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { SubscriptionUtil } from "../_utilities/subscription";
import { MessagesComponent } from "./messages/messages.component";
import { CommanComponentModule } from "./comman-component/comman-component.module";
import { ToastComponent } from "./toast/toast.component";
// import ngx-translate and the http loader
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MomentDatePipe } from "../_pipes/moment-date.pipe";
import { HttpModule } from "@angular/http";
import { FileTypePipe } from "../_pipes/file-type.pipe";
import { RendererModule } from "../renderer/renderer.module";
import { SystemInformationComponent } from "../dashboard/system-information/system-information.component";
import { SafeHtmlPipe } from "../_pipes/safe-html.pipe";
import { HighlightPipe } from "../_pipes/highlight.pipe";
import { CommonModule } from "@angular/common";
import { DurationFormatterPipe } from "../_pipes/duration-formatter.pipe";

import { GooglePlaceModule } from "./ngx-google-places-autocomplete/ngx-google-places-autocomplete";
import { DistanceToKmPipe } from "../_pipes/distance-to-km.pipe";
import { DistanceToKm2Pipe } from "../_pipes/distance-to-km2.pipe";
import { MinutesSecondsPipe } from "../_pipes/minutes-seconds.pipe";
import { PushNotificationService } from "./ngx-push-notifications-master/push-notification.service";

import { InfoGuideComponent } from "./comman-component/info-guide/info-guide.component";
import { ImageCropperModule } from 'ngx-image-cropper';

import { NgxChartsModule }from '@swimlane/ngx-charts';



import { BryntumSchedulerModule } from '@bryntum/scheduler-angular';
import { FullCalendarModule } from "./custella-fullcalendar/module";
import { FullCalendarModule as FCM } from '@fullcalendar/angular';
// import { NgxEditorModule } from 'ngx-editor';

import { AngularEditorModule } from '@kolkov/angular-editor';
import {NgPipesModule} from 'ngx-pipes';
import { DndModule } from "./dnd/src/lib/dnd.module";
import { SearchFilterPipe } from "../_pipes/search-filter.pipe";
import { GoogleMapsModule } from "@angular/google-maps";
import { TableHeaderToolTipComponent } from './table-header-tool-tip/table-header-tool-tip.component';
import { ViewTasksComponent } from "../dashboard/tasks/view-tasks/view-tasks.component";
import { NaviBarComponent } from './navi-bar/navi-bar.component';
// FullCalendarModule.registerPlugins([
//     dayGridPlugin,
//     timeGridPlugin,
//     interactionPlugin
// ]);
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SummarySliderComponent } from "./comman-component/summary-slider/summary-slider.component";
import { ThousandSeparatorPipe } from "../_pipes/thousandSeparatorPipe";
import { ModalViewMapComponent } from "./comman-component/modal-view-map/modal-view-map.component";
import { CustomerSummerySliderComponent } from "./comman-component/customer-summery-slider/customer-summery-slider.component";

import { CSVBoxAngularModule } from "@csvbox/angular";
import { ChatbotComponent } from './chatbot/chatbot.component';
import { UsersComponent } from "../dashboard/setup/users/users.component";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const SHARED_COMPONENTS_PIPES = [
    MessagesComponent,
    ToastComponent,
    MomentDatePipe,
    FileTypePipe,
    SafeHtmlPipe,
    SystemInformationComponent,
    HighlightPipe,
    DurationFormatterPipe,
    DistanceToKmPipe,
    DistanceToKm2Pipe,
    MinutesSecondsPipe,
    InfoGuideComponent,
    SearchFilterPipe,
    ViewTasksComponent,
    NaviBarComponent,
    SummarySliderComponent,
    CustomerSummerySliderComponent,
    ThousandSeparatorPipe,
    ChatbotComponent
]

@NgModule({
    declarations:[
        TableHeaderToolTipComponent,
        [...SHARED_COMPONENTS_PIPES]],
    imports: [
        CommonModule,
        HttpClientModule,
        HttpModule,
        FormsModule,
        MegaMenuModule,
        MenubarModule,
        InputTextModule,
        CheckboxModule,
        DropdownModule,
        SelectButtonModule,
        MultiSelectModule,
        CalendarModule,
        ButtonModule,
        BreadcrumbModule,
        TagModule,
        InputTextareaModule,
        ChipsModule,
        ImageModule,
        AvatarModule,
        AvatarGroupModule,
        GMapModule,
        OverlayPanelModule,
        DialogModule,
        TabMenuModule,
        MessagesModule,
        FormsModule,
        ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: "never" }),
        MessageModule,
        ToastModule,
        TooltipModule,
        ConfirmDialogModule,
        CommanComponentModule,
        MenuModule,
        ColorPickerModule,
        BadgeModule,
        SkeletonModule,
        NgxSpinnerModule,
        TabViewModule,
        AccordionModule,
        SlideMenuModule,
        ModalModule.forRoot(),
        TableModule,
        PickListModule,
        ToggleButtonModule,
        FileUploadModule,
        FileSaverModule,
        CurrencyMaskModule,
        InputSwitchModule,
        AutoCompleteModule,
        ProgressBarModule,
        RadioButtonModule,
        GooglePlaceModule,
        CardModule,
        ImageCropperModule,
        TreeModule,
        TreeTableModule,
        DragDropModule,
        BryntumSchedulerModule,
        NgxChartsModule,
        FullCalendarModule,
        FCM,
        AngularEditorModule,
        NgPipesModule,
        DndModule,
        GoogleMapsModule,
        SidebarModule,
        SplitterModule,
        ProgressSpinnerModule,
        CSVBoxAngularModule,
        ListboxModule,
        OrganizationChartModule,
        ListboxModule,
        StepsModule,
        ChipModule
    ],
    exports: [
        CommonModule,
        HttpModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        FormsModule,
        MegaMenuModule,
        MenubarModule,
        InputTextModule,
        CheckboxModule,
        DropdownModule,
        MultiSelectModule,
        ButtonModule,
        BreadcrumbModule,
        TagModule,
        ChipsModule,
        ImageModule,
        AvatarModule,
        AvatarGroupModule,
        GMapModule,
        OverlayPanelModule,
        DialogModule,
        TabMenuModule,
        MessagesModule,
        MessageModule,
        ToastModule,
        CommanComponentModule,
        ConfirmDialogModule,
        MenuModule,
        ColorPickerModule,
        BadgeModule,
        SkeletonModule,
        NgxSpinnerModule,
        TabViewModule,
        AccordionModule,
        SlideMenuModule,
        ModalModule,
        TableModule,
        PickListModule,
        ToggleButtonModule,
        FileUploadModule,
        RadioButtonModule,
        FileSaverModule,
        CurrencyMaskModule,
        InputSwitchModule,
        AutoCompleteModule,
        ProgressBarModule,
        
        GooglePlaceModule,
        CardModule,
        ImageCropperModule,
        TreeModule,
        TreeTableModule,
        DragDropModule,
        CalendarModule,
        SelectButtonModule,
        InputTextareaModule,
        BryntumSchedulerModule,
        NgxChartsModule,
        FullCalendarModule,
        FCM,
        AngularEditorModule,
        NgPipesModule,
        DndModule,
        GoogleMapsModule,
        SidebarModule,
        SplitterModule,
        ProgressSpinnerModule,
        SplitterModule,
        ModalViewMapComponent,
        CSVBoxAngularModule,
        ListboxModule,
        OrganizationChartModule,
        StepsModule,
        [...SHARED_COMPONENTS_PIPES],
        ChipModule
       
    ],
    providers: [
        SubscriptionUtil,
        TooltipModule,
        ConfirmDialogModule,
        PushNotificationService,
    ]
})
export class SharedModule {}
