import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EngLangData } from 'src/app/_models/global.data.model';

@Component({
  selector: 'app-fields-states',
  templateUrl: './fields-states.component.html',
  styleUrls: ['./fields-states.component.scss']
})
export class FieldsStatesComponent implements OnInit {

  translate: any;
  constructor(
    public modalRef : BsModalRef,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    
    this.route.queryParams.subscribe(params => {
      this.translate = EngLangData;

    })
  }

}
