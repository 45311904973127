import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GridOptions, IServerSideGetRowsParams } from 'ag-grid-community';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServerRequest } from 'src/app/_models/global.data.model';
import { BreadcrumbService } from 'src/app/_services/breadcrumb.service';
import { DeleteRecordService } from 'src/app/_services/delete-record.service';
import { JobOrdersService } from 'src/app/_services/job-orders.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { ServiceZoneService } from 'src/app/_services/service-zone.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { ButtonRendererComponent } from 'src/app/renderer/button-renderer.component';
import { TableHeaderToolTipComponent } from '../../table-header-tool-tip/table-header-tool-tip.component';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { Subscription, forkJoin } from 'rxjs';
import { ProjectService } from 'src/app/_services/project.service';
import { SiteService } from 'src/app/_services/site.service';
import { TasksService } from 'src/app/_services/tasks.service';
import { TeamService } from 'src/app/_services/team.service';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { ModalServiceService } from 'src/app/_services/modal-service.service';
var translateData: any
@Component({
  selector: 'app-list-task-templates',
  templateUrl: './list-task-templates.component.html',
  styleUrls: ['./list-task-templates.component.scss']
})
export class ListTaskTemplatesComponent extends SubscriptionUtil implements OnInit {

  @Output() closeTable = new EventEmitter();
  @Output() isFilterClosed = new EventEmitter();
  @Input() jobOrder: any;
  @Input() comingFrom: any;
  @Input() checklistRecordId: any;
  @Input() pmvId: any;

  
    gridApi: any;
    gridColumnApi: any;
    columnDefs: any;
    defaultColDef: any;
    frameworkComponents: any;
    gridParams: any;
    startFrom: any;
    product: any;
    pageSize: any = 25;
    parameters: any;
    gridOptions: GridOptions;
    overlayNoRowsTemplate: string;
    productsFamilies: any;
    productsType: any;
    searchValue: any
    currentRecordNumber: any;
    totalRecord: any;
  
    DataDetails: any[] = [];
    noJOData: boolean = false;
    noJODataIcon: boolean = false;
    joData: any;
    jobOrderTypes: any;
    serviceZones: any;
    systems: any;
  
    isFilterToggleOn: boolean = false;
    translate: any;
    searchValueData: boolean;
    filter: any = { typeId: null, systemId: null, faultId: null, isCreation: 1 };
    faults: any;
  filterActive: boolean =false
  filterClick: boolean = false

  private filterAPILoaded = false;
  teams: unknown;
  taskTemplateData: any;
  notaskTemplate: boolean;
  notaskTemplateIcon: boolean;
  
    constructor(
      private languageTranslateService: LanguageTranslateService,
      private router: Router,
      private spinner: NgxSpinnerService,
      private jobOrdersService: JobOrdersService,
      private errorUtil: ErrorUtil,
      private deleteRecordService: DeleteRecordService,
      private breadcrumbService: BreadcrumbService,
      private serviceZonesService: ServiceZoneService,
      private util: UtilServiceService,
      private teamService : TeamService,
      private tasksService: TasksService,
      private modalService2 : ModalServiceService,
    ) {
  
      super();
      this.frameworkComponents = {
        buttonRenderer: ButtonRendererComponent,
      }
      this.gridOptions = {
        cacheBlockSize: 25,
        rowModelType: "infinite",
        sortingOrder: ["desc", "asc"],
        onGridSizeChanged: (params) => {
          params.api.sizeColumnsToFit();
        },
        tooltipShowDelay: 100,
      };
  
      this.defaultColDef = {
        enableRowGroup: true,//ag-Grid-Enterprise
        enablePivot: true,//ag-Grid-Enterprise
        enableValue: true,//ag-Grid-Enterprise
        sortable: true,
        resizable: true,
        filter: false,
        tooltipComponent: TableHeaderToolTipComponent
      };
  
    }
  
    ngOnInit(): void {
      this.getLanguageData()
    }
  
    /******************************************** let job order table toggle filter ******************************************/
    public callFilterToggle($event: any) {
      // this.jobOrderTableComponent.filterToggle();
    }
    closeAsset() {
      this.closeTable.emit(false)
    }
  
    /******************************************** toggle the filter sidebar ******************************************/
    public filterToggle() {
      this.filterActive = !this.filterActive;
      this.isFilterClosed.emit(this.filterActive)
    }
  
    viewDets(e: any) {
      if (e.colDef.field === "templateName") {
  
        const data = {
          joTemplateId: e.data.id,
          joTemplateName: e.data.templateName,
        };
  
        // this.util.setPersistantItem(PERSISTANT_ITEM.JOB_ORDER_TEMPLATE, data);
         this.router.navigate(['dashboard/jobOrders/create'], { queryParams: { template: true, templateId: e.data.id } });
      }
    }
  
 /******************************************** Refresh Table ******************************************/
 public refresh() {
  this.onGridReady(this.gridParams);
}

    /********************************** Search Data **************************/
    quickSearch() {
      console.log(this.searchValue)
      if(this.searchValue){
      this.searchValueData = true
      this.onGridReady(this.gridParams);
      }
    }
    /********************************** Initilize Grid Table **************************/
    onGridReady(params: any) {
      //console.log(params)
      this.gridParams = params;
  
      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      var dataSource = {
        getRows: (params: IServerSideGetRowsParams) => {
          this.getServerSideData(params);
        },
      };
  
      this.gridApi.sizeColumnsToFit();
      this.gridApi.setDatasource(dataSource);
    }
  
  /********************************** Call Customer API to Display data *********************************/
  getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
    // this.spinner.show()
    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);
    console.log('Making a server request: ', serverRequest);

    this.push(this.tasksService.getAllTaskTemplates(this.searchValue, serverRequest,this.filter.siteId,this.filter.projectId,this.filter.teamId, null,"ACTIVE") .subscribe(
          (data: any) => {
            console.log(data.body);
            this.taskTemplateData = data.body.data;
            this.totalRecord = data.body.total;
            this.setGridData(
              agGridGetRowsParams,
              this.taskTemplateData,
              data.body.total,
              data.body.status
            );
            //this.spinner.hide()
          },
          (err) => {
            //console.log(err,"===>>>")
            this.errorUtil.setErrorMessage(err);
          }
        )
    );
  }
  /**************************************** Set Data for Grid Table ****************************************/
  private setGridData( agGridGetRowsParams: IServerSideGetRowsParams,resultItems: any[],totalCount: number, status: string ) {
    console.log(resultItems, "===");
    
   
      if (this.taskTemplateData.length === 0) {
        this.gridApi.showNoRowsOverlay();
        this.notaskTemplate = false;
        this.notaskTemplateIcon = true;
      } else {
        this.notaskTemplate = true;
      this.notaskTemplateIcon = false;
        agGridGetRowsParams.successCallback(this.taskTemplateData, totalCount);
      }
    
   
  }
  /********************************** Request Parameter for api ****************************************/

  getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
      this.parameters = { colId: "team.teamName", sort: "asc" };
      agGridRequest.sortModel.push(this.parameters);
    }

    //console.log(agGridRequest)
    this.currentRecordNumber = agGridRequest.startRow;
    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,
    };
  }
    resetSearch(){
      this.filter =  {teamId : null, siteId : null, projectId : null};
      this.onGridReady(this.gridParams);
       
    }
    push(obs: any) {
      super.push(obs);
    }
    /********************************** On Destroy *****************************/
    private onDestroy() {
      super.ngOnDestroy();
    }
    public onSearchCheck()
    {
      if(this.searchValue.length == 0)
      {
        this.searchValueData = false
        this.searchValue = ''
        this.onGridReady(this.gridParams);
      }
    }
  
    public onClear(){
      this.searchValueData = false
      this.searchValue = ''
      this.onGridReady(this.gridParams);
      
    }
  
    getLanguageData() {
      this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
          if(Object.keys(data).length != 0){
            this.translate = data
            translateData = data;
           //  this.getStatusData()
           this.getFilterList()
            this.initializeColumns();
          }
        }))
      console.log("translate",this.translate)
      //this.mainLabel = this.translate?.site.label.plural
    }
  
    initializeColumns() {
      this.columnDefs= []
      this.overlayNoRowsTemplate = "<div class='not-found'><span>"+this.translate?.general.messages.noRecordsFound+"</span> </div>";
      console.log("initiateCol", translateData, "=======", this.columnDefs)
      var newData =   [
        { headerName:  this.translate?.setup.modules.templates.fields.templateName.singular, field: 'templateName', width: 150, headerTooltip: "#",cellClass: function (params: any) { return ['table_default_color'];}},
        { headerName: this.translate?.task.fields.taskName.singular, field: 'taskName', width: 170,headerTooltip: "#" },
        // { headerName: this.translate?.setup.modules.templates.fields.type.singular, field: 'site.name', width: 80, headerTooltip: "#",},
        // { headerName: this.translate?.project?.label.singular, field: 'project.name', width: 100,headerTooltip: "#"},
        // { headerName: this.translate?.setup.users.teams.label.singular, field: 'team.name', width: 100,headerTooltip: "#"},
        { headerName: this.translate?.setup.modules.templates.fields.templateStatus.singular, field: 'active', width: 100, headerTooltip: "#",
          cellRenderer: function (params: any) {
            if(params.data){
              if(params.data.templateStatus == 'ACTIVE'){
                return '<span class="p-mr-2  p-tag p-component p-tag-success" ><span class="p-tag-value">'+translateData.general.status.active.singular+ '</span></span>';
              } else {
             return '<span class="p-mr-2  p-tag p-component p-tag-danger" ><span class="p-tag-value text-dark">'+translateData.general.status.inactive.singular+'</span></span>';
            }
            }
          }
        }
      ];
     
      this.columnDefs = newData;
      if (this.gridApi) {
        this.gridApi.setColumnDefs(this.columnDefs);
        setTimeout(() => { this.gridApi.sizeColumnsToFit(); },500);
      }
    }

    /**************************************** Click Cell Event ***********************************************/
   viewTaskTemplate(e: any){
    if (e.colDef.field === "templateName") {
  
      const data = {
        mode : 'add',
        taskTemplateId: e.data.id,
        taskTemplateName: e.data.templateName,
        comingFrom: this.comingFrom ? this.comingFrom : null,
        pmvId: this.pmvId ? this.pmvId : null,
        checklistRecordId: this.checklistRecordId ? this.checklistRecordId : null
      };
      
      
      let finalData ;
      if(this.jobOrder){
       finalData = Object.assign(data, this.jobOrder);
       this.modalService2.openModal('addEditTask', finalData);
      }
      else{
        this.modalService2.openModal('addEditTask', data);
      }
      // this.util.setPersistantItem(PERSISTANT_ITEM.JOB_ORDER_TEMPLATE, data);
      
      this.closeTable.emit(true)
    }
      //this.selectCustomer(e.data)
   }

   getFilterList(){
     

    const sources = [
      this.teamService.getAllTeamsPickList()];

    this.push(
      forkJoin(sources).subscribe(
        ([
          teamsResponce,
        ]) => {
          this.teams = teamsResponce;
        }
      )
    );

    }
  
  
}


