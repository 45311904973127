import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from 'src/app/_services/admin/users.service';
import { AiService } from 'src/app/_services/admin/ai.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { marked } from 'marked';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit {
  userMessage: string | undefined;
  chatInput!: HTMLTextAreaElement;
  sendChatBtn!: HTMLSpanElement;
  @ViewChild('chatbox') private chatBoxElement!: ElementRef;
  apidata: SafeHtml | null = null;

  active: boolean = false;
  isExpanded: boolean = false;
  isAiDataLoaded: boolean = false;
  outsideClickListener: any;

  constructor(
    private spinner: NgxSpinnerService,
    private aiService: AiService,
    private errorUtil: ErrorUtil,
    private auth: AuthenticationService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.loadChatbot();
  }

  getConnectWithAI() {
    if (this.isAiDataLoaded) return;

    this.isAiDataLoaded = false; // Show loading bubble/spinner
    // this.spinner.show();

    this.aiService.getConnectWithAI('2024-09-01T06:19:09Z', '2024-09-27T08:59:29Z').subscribe(
      (response: any) => {
        // this.spinner.hide();
        //fromDateTime
        //toDateTime
        //this.auth.getUserId()

        if (response && response.body && response.body.data) {
          const rawHtml: any = marked.parse(response.body.data);
          this.apidata = this.sanitizer.bypassSecurityTrustHtml(rawHtml);
          setTimeout(() => this.scrollToBottom(), 0);
        } else {
          console.error("Unexpected response structure:", response);
          this.apidata = this.sanitizer.bypassSecurityTrustHtml('<p>Something went wrong.</p>');
        }

        this.isAiDataLoaded = true; 
      },
      (error) => {
        // this.spinner.hide();
        console.error("Error loading AI data:", error);
        this.apidata = this.sanitizer.bypassSecurityTrustHtml('<p>Error loading data.</p>');
        this.isAiDataLoaded = true;
      }
    );
  }

  handleChat(): void {
    if (!this.chatInput) return;

    this.userMessage = this.chatInput.value.trim();
    if (!this.userMessage) return;

    const outgoingMessage = this.createChatLi(this.userMessage, "outgoing");
    this.chatBoxElement.nativeElement.appendChild(outgoingMessage);
    this.chatInput.value = '';
    outgoingMessage.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }

  createChatLi(message: string, className: string): HTMLElement {
    const chatLi = document.createElement("li");
    chatLi.classList.add("chat", className);

    const messageElement = document.createElement("div");
    messageElement.classList.add("chat-message");
    messageElement.innerHTML = message;

    chatLi.appendChild(messageElement);
    return chatLi;
  }

  scrollToBottom(): void {
    try {
      const chatbox = this.chatBoxElement.nativeElement;
      chatbox.scrollTop = chatbox.scrollHeight;
    } catch (err) {
      console.log('Error scrolling to bottom:', err);
    }
  }

  toggleConfigurator(event: Event) {
    this.active = !this.active;
    event.preventDefault();

    if (this.active) {
      this.bindOutsideClickListener();
      this.getConnectWithAI(); 
    } else {
      this.unbindOutsideClickListener();
    }
  }

  hideConfigurator(event: Event) {
    this.active = false;
    this.unbindOutsideClickListener();
    event.preventDefault();
  }

  toggleChatboxWidth(): void {
    this.isExpanded = !this.isExpanded;
  }

  loadChatbot(): void {
    this.chatInput = document.querySelector<HTMLTextAreaElement>(".chat-input textarea")!;
    this.sendChatBtn = document.querySelector<HTMLSpanElement>(".chat-input span")!;

    if (this.sendChatBtn) {
      this.sendChatBtn.addEventListener("click", this.handleChat.bind(this));
    } else {
      console.warn("Send button not found");
    }
  }

  bindOutsideClickListener() {
    if (!this.outsideClickListener) {
      this.outsideClickListener = (event) => {
        if (this.active && this.isOutsideClicked(event)) {
          this.active = false;
        }
      };
      document.addEventListener('click', this.outsideClickListener);
    }
  }

  unbindOutsideClickListener() {
    if (this.outsideClickListener) {
      document.removeEventListener('click', this.outsideClickListener);
      this.outsideClickListener = null;
    }
  }

  isOutsideClicked(event: any) {
    return !(event.target.closest('.layout-config'));
  }
}
