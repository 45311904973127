<div class=""  *ngIf="translate">
    <div class="custella-modal d-flex flex-column">
        <div class="custella-modal-header">
            <div class="custella-modal-header-text" id="title_SetWorkflowNotificationPreviewEmail">
                <span>Preview</span>
            </div>
            <i class="pi pi-times pt-1"  [pTooltip]="translate?.general.buttons.cancel.singular" tooltipPosition="left"  (click)="modalRef.hide()" id="btn_SetWorkflowNotificationPreviewEmailClose"></i>
        </div>
    </div>  

    
    <div class="custella-modal-body">
        <div class="custella-form-container box-none m-0">
            <div class="custella-form-container-content my-3">

                <p-tabView class="in-modal">
                    <!-------------- THIS IS FOR WEB PREVIEW  -------------------->
                    <p-tabPanel header="Web">
                        <div class="custella-form-container mx-0 my-2 pb-2">
                            <!-- Container Content -->
                            <div class="custella-form-container-title">Example Section Name 1</div>
                            <div class="custella-form-container-content" >
                                <!-- Tenant Hill -->
                                <div class="custella-content-row">
                                    <div class="custella-content-row-input col-6">
                                        <span class="custella-content-row-input-title mandatory" id="label_CusHospitalName">ACX Input</span>
                                        <input type="text" pInputText autocomplete="off"/>
                                    </div>
                                    <div class="custella-content-row-input col-6">
                                        <span class="custella-content-row-input-title mandatory" id="label_CusHospitalName">ACX File Upload</span>
                                        <input type="text" pInputText autocomplete="off"/>
                                    </div>
                                </div>
                            </div>
                            <div class="custella-form-container-title-two">Example Section Name 2</div>
                            <div class="custella-form-container-content" >
                                <!-- Tenant Hill -->
                                <div class="custella-content-row">
                                    <div class="custella-content-row-input col-6">
                                        <span class="custella-content-row-input-title mandatory" id="label_CusHospitalName">ACX Signature</span>
                                        <input type="text" pInputText autocomplete="off"/>
                                    </div>
                                    <div class="custella-content-row-input col-6">
                                        <span class="custella-content-row-input-title mandatory" id="label_CusHospitalName">ACX Email</span>
                                        <input type="text" pInputText autocomplete="off"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-tabPanel>
            
                    <p-tabPanel header="Mobile">

                        <div class="banner mb-2">
                            <div class="left-view">
                                <p-steps [model]="items" [readonly]="false"></p-steps>
                            </div>  
                        </div>
                   
                        <div class="custella-form-container mx-0 my-2 pb-2">
                            <!-- Container Content -->
                            <div class="custella-form-container-title">Example Section Name 1</div>
                            <div class="custella-form-container-content" >
                                <!-- Tenant Hill -->
                                <div class="custella-content-row">
                                    <div class="custella-content-row-input col-6">
                                        <span class="custella-content-row-input-title mandatory" id="label_CusHospitalName">ACX Phone Check</span>
                                        <input type="text" pInputText autocomplete="off"/>
                                    </div>
                                    <div class="custella-content-row-input col-6">
                                        <span class="custella-content-row-input-title mandatory" id="label_CusHospitalName">ACX Number Only</span>
                                        <input type="text" pInputText autocomplete="off"/>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </p-tabPanel>
                </p-tabView>



            </div>

            <div class="custella-form-container-footer-two">
                <div class="d-flex align-items-center justify-content-end">
                    <button class="custella-btn-white text-12 ms-2" (click)="modalRef.hide()">{{translate?.general.buttons.cancel.singular}}</button>
                </div>

            </div>
        </div>
    </div>
    
</div>