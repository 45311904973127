import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UsersService } from 'src/app/_services/admin/users.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';

@Component({
  selector: 'app-user-lookup',
  templateUrl: './user-lookup.component.html',
  styleUrls: ['./user-lookup.component.scss']
})
export class UserLookupComponent extends SubscriptionUtil implements OnInit {
  searchValue: string = "";

  @Output() closeUserEvent = new EventEmitter();
  @Output() selectUserEvent = new EventEmitter();
  @Input() lookupFilter: any =''
  translate: any;
  constructor(private languageTranslateService: LanguageTranslateService,
    private userService: UsersService,
  ) {
    super();
    this.languageTranslateService.menuSrc$.subscribe(data => {
      if(Object.keys(data).length != 0){ this.translate = data}
    })
    this.push(
      this.userService.getUser().subscribe((data) => {
        console.log("cus data", data)
        if (data) {
          this.selectCustomer(data);
        }
      })
    );
  }

  ngOnInit(): void {
  }

  selectCustomer(val: any) {
    this.selectUserEvent.emit(val);
  }

  closeCustomer() {
    this.closeUserEvent.emit(false);
  }
}

