export class ServerRequest {
  filterModel: any;
  sortModel: any;
  startRow: number | undefined;
  pageSize: number | undefined;
}
export class SortModel {
  colId: any;
  sort: any;
}

export class LoadingBayModel {
  monday: boolean = true;
  tuesday: boolean = true;
  wednesday: boolean = true;
  thursday: boolean = true;
  friday: boolean = true;
  saturday: boolean = false;
  sunday: boolean = false;

  mondayAllDay: boolean = false;
  tuesdayAllDay: boolean = false;
  wednesdayAllDay: boolean = false;
  thursdayAllDay: boolean = false;
  fridayAllDay: boolean = false;
  saturdayAllDay: boolean = false;
  sundayAllDay: boolean = false;

  mondayStart: any | null;
  mondayEnd: any | null;
  tuesdayStart: any | null;
  tuesdayEnd: any | null;
  wednesdayStart: any | null;
  wednesdayEnd: any | null;
  thursdayStart: any | null;
  thursdayEnd: any | null;
  fridayStart: any | null;
  fridayEnd: any | null;
  saturdayStart: any | null;
  saturdayEnd: any | null;
  sundayStart: any | null;
  sundayEnd: any | null;
}

export enum AppEvents {
  "ADD_CONVERSATION_MODAL_SUCCESS" = "ADD_CONVERSATION_MODAL_SUCCESS",
  "CREATE_JOB_ORDER_TICKET_SUCCESS" = "CREATE_JOB_ORDER_TICKET_SUCCESS",
  "REFRESH_JOB_ORDER_TABLE" = "REFRESH_JOB_ORDER_TABLE",
  "CREATE_JOB_ORDER_TICKET_ESCALATE_SUCCESS" = "CREATE_JOB_ORDER_TICKET_ESCALATE_SUCCESS",
  "PASS_JO_PRODUCT_TO_JO_PRODUCT_CREATE" = "PASS_JO_PRODUCT_TO_JO_PRODUCT_CREATE",
  "REFRESH_TASK_TABLE" = "REFRESH_TASK_TABLE",
  "ASSIGN_TASK" = "ASSIGN_TASK",
  "PASS_JO_DATA_TO_PART_ORDER_CREATE" = "PASS_JO_DATA_TO_PART_ORDER_CREATE",
  "PASS_TICKET_DATA" = "PASS_TICKET_DATA",
  "REFRESH_CONTRACT_DATA" = "REFRESH_CONTRACT_DATA",
  "REFRESH_SALES_ORDER_DETAILS" = "REFRESH_SALES_ORDER_DETAILS",
  "REFRESH_LAST_LOCATION" = "REFRESH_LAST_LOCATION",
  "UPDATE_ESTIMATE_DISTANCE" = "UPDATE_ESTIMATE_DISTANCE",
  "CLOSE_DO_NOTES_ATTACHMENTS" = "CLOSE_DO_NOTES_ATTACHMENTS",
  "REFRESH_DO" = "REFRESH_DO",
  "REFRESH_SALES_ORDER_PRODUCT" = "REFRESH_SALES_ORDER_PRODUCT",
  "REFRESH_VIEW_USER_GROUP" = "REFRESH_VIEW_USER_GROUP",
  "TERMINATE_FULLCALENDAR"= "TERMINATE_FULLCALENDAR"
}

export enum HELPDESK_APP_EVENTS {
  GET_TICKET_MENU = "GET_TICKET_MENU",
  ON_SEARCH_RESULT = "ON_SEARCH_RESULT",
  ON_HELPDESK_TICKET_ASSIGN = "ON_HELPDESK_TICKET_ASSIGN",
  ON_HELPDESK_TICKET_CLAIM = "ON_HELPDESK_TICKET_CLAIM",
  ON_HELPDESK_TICKET_ASSIGNEE = "ON_HELPDESK_TICKET_ASSIGNEE",
  ON_TAB_CHANGE = "ON_TAB_CHANGE",
  CLEAR_HELPDESK_COMPONENT = "CLEAR_HELPDESK_COMPONENT",
  REMOVE_HELPDESK_TABLE_ROW = "REMOVE_HELPDESK_TABLE_ROW",
  UNDO_TICKET = "UNDO_TICKET",
  ON_UPDATE_TICKET_MENU_COUNT = "ON_UPDATE_TICKET_MENU_COUNT",
  ON_HELPDESK_ROUTE_CHANGE = "ON_HELPDESK_ROUTE_CHANGE",
  ON_TICKET_CLICK = "ON_TICKET_CLICK",
  ON_VIEW_TICKET = "ON_VIEW_TICKET",
  ON_DELETE_TICKET = "ON_DELETE_TICKET",
  ON_CLOSE_TAB_TICKET = "ON_CLOSE_TAB_TICKET",

  // RELATED BUT FAR
  ON_CLAIM_SUCCESS = "ON_CLAIM_SUCCESS"
}

export enum COMPONENT_CREATION {
  "HELPDESK_ASSIGNEE_SELECTION" = "HELPDESK_ASSIGNEE_SELECTION",
  "HELPDESK_TOAST" = "HELPDESK_TOAST",
  "TICKET_VIEW" = "TICKET_VIEW",
}

export enum PERSISTANT_ITEM {
  "VISITS" = "VISITS",
  "PRODUCTIVITY" = "PRODUCTIVITY",
  "ATTENDANCE" = "ATTENDANCE",
  "OVERTIME" = "OVERTIME",
  "JOB_ORDER" = "JOB_ORDER",
  "ASSET" = "ASSET",
  "ASSET_ACCESSORIES" = "ASSET_ACCESSORIES",
  "CONTRACT_ACCESSORIES" = "CONTRACT_ACCESSORIES",
  "CONTRACT_ASSETS" = "CONTRACT_ASSETS",
  "CONTRACT_MAINTENANCE" = "CONTRACT_MAINTENANCE",
  "CONTRACTS" = "CONTRACTS",
  "CONTRACT_ITEM" = "CONTRACT_ITEM",
  "SCHEDULE_MAINTENANCE" = "SCHEDULE_MAINTENANCE",
  "MAINTENACE" = "MAINTENACE",
  "SALES_ORDER" = "SALES_ORDER",
  "SALES_ORDER_PRODUCT_1" = "SALES_ORDER_PRODUCT_1",
  "SALES_ORDER_PRODUCT_2" = "SALES_ORDER_PRODUCT_2",
  "PRODUCT" = "PRODUCT",
  "ADJUST_INVENTORY" = "ADJUST_INVENTORY",
  "CHECK_AVAILABILITY" = "CHECK_AVAILABILITY",
  "DO_STATUS" = "DO_STATUS",
  "TRAILER" = "TRAILER",
  "DELIVERY_ORDER" = "DELIVERY_ORDER",
  "PACKAGE_TYPE" = "PACKAGE_TYPE",
  "PACKAGE_SUB_TYPE" = "PACKAGE_SUB_TYPE",
  "ROLE" = "ROLE",
  "ACCESS" = "ACCESS",
  "SUBCONTRACTOR" = "SUBCONTRACTOR",
  "DEPOT" = "DEPOT",
  "USER_GROUPS" = "USER_GROUPS",
  "USER_SKILLS" = "USER_SKILLS",
  "LEAVE" = "LEAVE",
  "SHIFTS" = "SHIFTS",
  "VIEW_SHIFTS" = "VIEW_SHIFTS",
  "DEVICE" = "DEVICE",
  "PLANING_HUB" = "PLANING_HUB",
  "SITE_TYPE" = "SITE_TYPE",
  "SITE_AREA" = "SITE_AREA",
  "SITE_CATEGORY" = "SITE_CATEGORY",
  "SITE_SUB_CATEGORY" = "SITE_SUB_CATEGORY",
  "PROJECT_TYPE" = "PROJECT_TYPE",
  "PROJECT_CATEGORY" = "PROJECT_CATEGORY",
  "SITES" = "SITES",
  "PROJECTS" = "PROJECTS",
  "CONTACTS" = "CONTACTS",
  "TICKETS" = "TICKETS",
  "SERVICE_ZONES" = "SERVICE_ZONES",
  "JOB_ORDER_REFRESH" = "JOB_ORDER_REFRESH",
  "PAYMENT" = "PAYMENT",
  "JOB_ORDER_TEMPLATE"="JOB_ORDER_TEMPLATE",
  "MS_VISIT"="MS_VISIT",
  "CUSTOM_FORM"="CUSTOM_FORM",
 "SUB_CUSTOM_FORM"="SUB_CUSTOM_FORM",


}

export enum ChubbDashboardAppEvents {
  REFRESH_PANEL_ONE = "REFRESH_PANEL_ONE",
  REFRESH_PANEL_TWO = "REFRESH_PANEL_TWO",
  REFRESH_PANEL_THREE = "REFRESH_PANEL_THREE",
  REFRESH_TASK_BY_TECHNICIAN = "REFRESH_TASK_BY_TECHNICIAN",
  DOWNLOAD_TECHNICIAN_DATA = "DOWNLOAD_TECHNICIAN_DATA",

  DATA_PASS_BY = "DATA_PASS_BY"
};

export class faultRequest {
  id: any;
  name: any;
  selected: boolean = false;
  code : any;
}
export class markerData {
    latitude : any;
    longitude : any;
    lat : any;
    lng : any;
    icon : any;
    startTime : any;
    endTime : any;
    type: any;
    load: any;
    quantity: any;
    orderNumber: any;
    orderNumberDate : any;
    orderName: any;
    deliveryOrderWay: any;
    lineItemNumber: any;
    orderStatus : any;
    id: any;
    isOpen: any;
    lineItemId: any;
    customer: any;
    depot: any;
    label: any;
    contactName:any;
    contactNumber:any
    deliveryOrder: any;
    deliveryOrderLineItemVMs:any
}
export class DeliveryOrderStatus {
 
  public static statusList: any=[]
  public static orderWayList: any=[]
  public static orderTypeList: any=[]

}
export const  EngLangData: any = {
  "general":{
    "buttons":{
        "fieldCreation":{
            "original": "Field Creation",
            "singular": "Field Creation",
            "plural": "Field Creation",
            "setupEdit": true,
            "setupVisible": false
        },
        "pageArrangement":{
            "original": "Page Arrangement",
            "singular": "Page Arrangement",
            "plural": "Page Arrangement",
            "setupEdit": true,
            "setupVisible": false
        },
        "cancel":{
            "original": "Cancel",
            "plural": "",
            "setupVisible": false,
            "singular": "Cancel",
            "setupEdit": true
        },
        "save":{
            "original": "Save",
            "plural": "",
            "setupVisible": false,
            "singular": "Save",
            "setupEdit": true
        },
        "edit":{
                "original": "Edit",
                "plural": "",
                "setupVisible": false,
                "singular": "Edit",
                "setupEdit": true
            },
        "delete":{
            "original": "Delete",
            "plural": "",
            "setupVisible": false,
            "singular": "Delete",
            "setupEdit": true
        },
        "more":{
            "original": "More",
            "plural": "",
            "setupVisible": false,
            "singular": "More",
            "setupEdit": true
        },
        "subformSetup":{
            "original": "Subform Setup",
            "singular": "Subform Setup",
            "plural": "Subform Setup",
            "setupEdit": true,
            "setupVisible": false
        },
        "listViewSetup":{
            "original": "List View Setup",
            "singular": "List View Setup",
            "plural": "List View Setup",
            "setupEdit": true,
            "setupVisible": false
        },
        "addSection":{
            "original": "Add Section",
            "singular": "Add Section",
            "plural": "Add Section",
            "setupEdit": true,
            "setupVisible": false
        },
        "addAPage":{
            "original": "Add a Page",
            "singular": "Add a Page",
            "plural": "Add a Page",
            "setupEdit": true,
            "setupVisible": false
        },
        "removePage":{
            "original": "Remove Page",
            "singular": "Remove Page",
            "plural": "Remove Page",
            "setupEdit": true,
            "setupVisible": false
        },
        "configure":{
            "original": "Configure",
            "singular": "Configure",
            "plural": "Configure",
            "setupEdit": true,
            "setupVisible": false
        },
        "refresh": {
          "original": "Refresh",
          "plural": "",
          "setupVisible": false,
          "singular": "Refresh",
          "setupEdit": true
          },
          "filter": {
            "original": "Filter",
            "plural": "",
            "setupVisible": false,
            "singular": "Filter",
            "setupEdit": true
          },
        
          "search": {
              "original": "Search",
              "plural": "",
              "setupVisible": false,
              "singular": "Search",
              "setupEdit": true
          },
          "clear": {
              "original": "Clear",
              "plural": "",
              "setupVisible": false,
              "singular": "Clear",
              "setupEdit": true
          },
          "new": {
            "original": "New",
            "plural": "",
            "setupVisible": false,
            "singular": "New",
            "setupEdit": true
        },
        "action": {
          "original": "Action",
          "plural": "",
          "setupVisible": false,
          "singular": "Action",
          "setupEdit": true
      },
    },
    "messages":{
        "removedSuccessfully": "Removed successfully",
        "processingAndWait": "Processing, Please Wait..",
        "emailSentSuuccesfully": "Emai sent sucessfully",
        "noPermission": "No Permissions set/given...",
        "none": "None",
        "irreversibleAction": "This action is irreversible.",
        "mustUploadFile": "Must upload a file to",
        "noActiveContracts": "No active contracts",
        "acceptedSuccessfully": "Accepted successfully",
        "beforeCompletingTheTask": "before completing this task",
        "selectLocationBinFromList": "Select a Location Bin from the list",
        "addNewItems": "Add New Items",
        "selectAllFields": "Please select all fields",
        "allowFieldExPhoneCall": "Allow FieldEx to make & manage phone calls",
        "noRecordsFound": "No Records Found",
        "dragAndDropFileToUpload": "Drag and drop file to upload",
        "thereAreNoItemsToDisplay": "There are no items to display",
        "decline": "Decline",
        "searchHere": "Search here",
        "oneOrMoreInvalidEmailForCC": "One or more invalid Email for cc.",
        "noJobOrdersToDisplay": "No Job Orders to display",
        "selectMachineSpecialistFromList": "Select a Machine Specialist from the list",
        "itemRemovedSuccessfully": "Item has been removed successfully.",
        "pleaseAttachOneOrMorePhotoes": "Please attach one or more photos",
        "selectCustomer": "Select a Customer",
        "noRecordsToDisplay": "No records to display",
        "appNeedsCameraPermission": "App needs access to your camera",
        "fetchingCurrentLocation": "Fetching current location",
        "reasonOptional": "Reason (Optional)",
        "searchAddress": "Search Address",
        "fillMandatoryFields": "Please fill all mandatory fields to proceed",
        "selectJoOrderTemplateFromList": "Select Jo Order Template From List",
        "youDontHaveReportingManager": "You don't have a reporting manager, Please contact your admin",
        "selectChecklistFromList": "Select Checklist From List",
        "doYouWishToProceed": "Do you wish to proceed?",
        "openGallery": "Open Gallery",
        "confirmAction": "Are you sure?",
        "selectProductFromList": "Select a Spare Part from the list",
        "willBeApproved": "will now be APPROVED.",
        "createdSuccessfully": "Created successfully",
        "pageNotFound": "Page Not Found 404",
        "selectJobOrderFromList": "Select a Job Order from the list",
        "sentSuccessfully": "Sent successfully",
        "noLabelFieldFound": "No Label Field found",
        "notAvailable": "N/A",
        "pleaseSignBelow": "Please sign below",
        "radiusKM": "Radius (KM)",
        "offline": "Offline",
        "search": "Search",
        "noSalesOrdersFound": "No Sales Orders found",
        "updateNotSuccesful": "Update not successful",
        "locationNotFound": "Location not found",
        "noJobOrderFound": "No Job Order found",
        "pleaseMakeSureSignatureClear": "Please make sure the signature is clear as this action can't be reverted",
        "emailHasBeenSentSuccessfully": "Email has been sent successfully",
        "email": "Email",
        "currentAddressIsNotResolvedYet": "Current address is not resolved yet!",
        "step4": "Step 4",
        "step5": "Step 5",
        "attachmentRemoved": "Attachment removed successfully",
        "errorFetchingModalData": "Error occured while fetching model data.",
        "unsavedChanges": "Unsaved Changes",
        "selectItem": {
            "plural": "Select Items",
            "singular": "Select Item"
        },
        "areYouSureWantToExit": "Are you sure you want to exit?",
        "declinedSuccessfully": "Declined successfully",
        "takePhoto": "Take Photo",
        "step2": "Step 2",
        "step3": "Step 3",
        "noProductsToDisplay": "No Spare Parts to display",
        "noProductsFound": "No Spare Parts found",
        "step1": "Step 1",
        "confirm": "Confirm",
        "selectTicketFromList": "Select an ticket from the list",
        "imageUploaded": "Image uploaded successfully",
        "selectSalesOrderFromList": "Select a Sales Order from the list",
        "comingSoon": "Coming Soon",
        "submittedSuccessfully": "Submitted successfully",
        "savedSuccessfully": "Saved successfully",
        "information": "Information",
        "officeAddressIsNotSet": "Office Address is not set",
        "seeAllRecords": "See All Records",
        "pleaeTurnTheLocationOn": "Please turn on the location and try again",
        "deleteAttachment": "Delete Attachment",
        "priorityAndDistance": "Priority & Distance",
        "contactAdminForAccess": "Please contact your admin for access",
        "fillAllRequiredFields": "Please fill all required fields",
        "office": "Office",
        "homeAddressIsNotSet": "Home Address is not set",
        "noStatusToDisplay": "No Status to display",
        "camerNotAvailable": "Camera not available on device",
        "disconnectedSuccessfully": "Disconnected successfully",
        "copyText": "Copy Text",
        "details": "Details",
        "acknowledgedSuccessfully": "Acknowledged successfully",
        "seeAllResults": "See All Results",
        "radius": "Radius",
        "textCopied": "Text Copied",
        "statusUpdated": "Status Updated",
        "selected": "selected",
        "verification": "Verification",
        "cancelledSuccessfully": "Cancelled successfully",
        "permissionNotSatisfied": "Permission not satisfied",
        "noAttachmentsFound": "No Attachments Found",
        "enterTextHere": "Enter text here...",
        "selectOneOrMoreOptions": "Please select one or more options to complete",
        "noViewAccess": "You do not have access to view this record",
        "titleMissing": "Title Missing",
        "noMembersToDisplay": "No members to display",
        "selectAll": "selectAll",
        "phoneCalls": "Phone Calls",
        "fieldServiceOptimization": "Field Service Optimization",
        "permissionDenied": "Permission Denied",
        "updatedSuccessfully": "Updated successfully",
        "selectCustomerFromList": "Select a Customer from the list",
        "endDateExceeds": "End date should not exceed start date",
        "selectProjectFromList": "Select Project From List",
        "selectAssetFromList": "Select an Asset from the list",
        "distance": "Distance",
        "records": "Records",
        "linkHasBeenCopied": "Link has been copied",
        "noActivityYet": "No activity yet",
        "noHistoriesToDisplay": "No Histories to display",
        "pleaseSelMandFormsIn": "Please fill up the mandatory form(s) in",
        "addedSuccessfully": "Added successfully",
        "appCameraPermission": "App Camera Permission",
        "noLocationsToDisplay": "No locations to display",
        "rejectedSuccessfully": "Rejected successfully",
        "recordNotFound": "Record not found",
        "deletedSuccessfully": "Deleted successfully",
        "selectCustomerSurveyFromList": "Select an Customer Survey from the list",
        "noPositionToDisplay": "No position to display",
        "thereAreNoTasksWithLocation": "There are no tasks with location to display in map",
        "fieldEx": "FieldEx",
        "filterBy": "Filter By",
        "chooseVideo": "Choose Video",
        "emailAddressIsNotAvailable": "Email address is not available.",
        "confirmDelete": "Are you sure you want to delete",
        "currentLocation": "Current Location",
        "home": "Home",
        "noNumberAssigned": "No number assigned",
        "selectUserFromList": "Select a User from the list",
        "pageDoesNotExist": "The page does not exist...",
        "selectOneOrMoreFields": "Select one or more fields to proceed",
        "selectSiteFromList": "Select Site From List",
        "doYouWishToContinue": "Do you wish to continue?",
        "approve": "Approve",
        "pleaseCheckYourInternetConnection": "Please check your internet connection",
        "online": "Online",
        "errorFetchingModaScehma": "Error occured while fetching model schema.",
        "time": "Time",
        "selectBinLocation": "Select a Bin Location"
    },
    "validationFE":{
        "invalidEmailFormat": "Format E-mel Tidak Sah",
        "invalidRadius": "Radius Tidak Sah",
        "invalidFax": "Nombor Faks Tidak Sah",
        "invalidPrice": "Harga Tidak Sah",
        "invalidUsernameFormat": "Format Nama Pengguna Tidak Sah",
        "onlyAlphabetsAllowed": "Hanya abjad dibenarkan",
        "required": "Diperlukan",
        "charactersExceed": "Teks melebihi had aksara (255).",
        "invalidYearFormat": "Format Tahun Tidak Sah",
        "pleaseSignBeforeProceed": "Sila tandatangan sebelum meneruskan",
        "invalidPhoneNumber": "Nombor Telefon Tidak Sah",
        "invalidUsername": "Nama Pengguna Tidak Sah",
        "requiredField": "Medan Diperlukan",
        "invalid": "Tidak Sah",
        "invalidFaxFormat": "Format Faks Tidak Sah",
        "invalidQuantity": "Kuantiti Tidak Sah",
        "endDateThanStartDate": "Tarikh tamat mesti selepas tarikh mula",
        "invalidEmail": "E-mel Tidak Sah"
    }
     
},
"setup":{
    "configuration":{
    "setupVisible": false,
        "customForm":{
            "label":{
                "original": "Custom Forms",
                "singular": "Custom Form",
                "plural": "Custom Forms",
                "setupEdit": true,
                "setupVisible": false
            },
            "sections":{
                "sec0":{
                    "original": "Subforms",
                    "singular": "Subform",
                    "plural": "Subforms",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "sec1":{
                    "original": "Custom Form Details",
                    "singular": "Custom Form Details",
                    "plural": "Custom Form Details",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "sec2":{
                    "original": "Form Details",
                    "singular": "Form Details",
                    "plural": "Form Details",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "sec3":{
                    "original": "Form Setup",
                    "singular": "Form Setup",
                    "plural": "Form Setup",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "sec4":{
                    "original": "Subform Record Type Selection",
                    "singular": "Subform Record Type Selection",
                    "plural": "Subform Record Type Selection",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "sec5":{
                    "original": "New Subform - Record Type Selection",
                    "singular": "New Subform - Record Type Selection",
                    "plural": "New Subform - Record Type Selection",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "sec6":{
                    "original": "Subform Setup",
                    "singular": "Subform Setup",
                    "plural": "Subform Setup",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "sec7":{
                    "original": "Fields List",
                    "singular": "Fields List",
                    "plural": "Fields List",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "sec8":{
                    "original": "Component Type Selection",
                    "singular": "Component Type Selection",
                    "plural": "Component Type Selection",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "sec9":{
                    "original": "Field Details - Component Type Selection",
                    "singular": "Field Details - Component Type Selection",
                    "plural": "Field Details - Component Type Selection",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "sec10":{
                    "original": "Picklist Mapping",
                    "singular": "Picklist Mapping",
                    "plural": "Picklist Mapping",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "sec11":{
                    "original": "Parent Options",
                    "singular": "Parent Options",
                    "plural": "Parent Options",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "sec12":{
                    "original": "Child Options",
                    "singular": "Child Options",
                    "plural": "Child Options",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "sec13":{
                    "original": "Form Arrangement",
                    "singular": "Form Arrangement",
                    "plural": "Form Arrangement",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "sec14":{
                    "original": "Available Fields",
                    "singular": "Available Fields",
                    "plural": "Available Fields",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "sec15":{
                    "original": "Section",
                    "singular": "Section",
                    "plural": "Sections",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "sec16":{
                    "original": "Running No. Format",
                    "singular": "Running No. Format",
                    "plural": "Running No. Format",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "sec17":{
                    "original": "Field Details",
                    "singular": "Field Details",
                    "plural": "Field Details",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "sec18":{
                    "original": "Preview",
                    "singular": "Preview",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "sec19":{
                    "original": "Update Field Configuration",
                    "singular": "Update Field Configuration",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "sec20":{
                    "original": "Section Layout",
                    "singular": "Section Layout",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": false
                }
            },
            "fields":{
                "label":{
                    "original": "Label",
                    "singular": "Label",
                    "plural": "Labels",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "name":{
                    "original": "Name",
                    "singular": "Name",
                    "plural": "Name",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "runningNo":{
                    "original": "Running Number",
                    "singular": "Running Number",
                    "plural": "Running Number",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "runningNoPrefix":{
                    "original": "Running No. Prefix",
                    "singular": "Running No. Prefix",
                    "plural": "Running No. Prefix",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "status":{
                    "original": "Status",
                    "singular": "Status",
                    "plural": "Status",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "jobOrderType":{
                    "original": "Job Order Type",
                    "singular": "Job Order Type",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "isFormMandatory":{
                    "original": "Is form mandatory?",
                    "singular": "Is form mandatory?",
                    "plural": "Is form mandatory?",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "isSubformMandatory":{
                    "original": "Is subform mandatory?",
                    "singular": "Is subform mandatory?",
                    "plural": "Is subform mandatory?",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "recordType":{
                    "original": "Record Type",
                    "singular": "Record Type",
                    "plural": "Record Type",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "description":{
                    "original": "Description",
                    "singular": "Description",
                    "plural": "Description",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "listRecord":{
                    "original": "List Record",
                    "singular": "List Record",
                    "plural": "List Record",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "singleRecord":{
                    "original": "Single Record",
                    "singular": "Single Record",
                    "plural": "Single Record",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "form":{
                    "original": "Form",
                    "singular": "Form",
                    "plural": "Forms",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "subform":{
                    "original": "Subform",
                    "singular": "Subform",
                    "plural": "Subforms",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "subformType":{
                    "original": "Subform Type",
                    "singular": "Subform Type",
                    "plural": "Subforms Type",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "helpText":{
                    "original": "Help Text",
                    "singular": "Help Text",
                    "plural": "Help Text",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "readOnly":{
                    "original": "Read Only",
                    "singular": "Read Only",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "requiredField":{
                    "original": "Required Field",
                    "singular": "Required Field",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "defaultValue":{
                    "original": "Default Value",
                    "singular": "Default Value",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "placeholder":{
                    "original": "Placeholder",
                    "singular": "Placeholder",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "decimalPlaces":{
                    "original": "Decimal Places",
                    "singular": "Decimal Places",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "formatNumber":{
                    "original": "Format Number",
                    "singular": "Format Number",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "multiSelect":{
                    "original": "Multiselect",
                    "singular": "Multiselect",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "field":{
                    "original": "Fields",
                    "singular": "Field",
                    "plural": "Fields",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "parent":{
                    "original": "Parent",
                    "singular": "Parent",
                    "plural": "Parents",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "parentPicklist":{
                    "original": "Parent Picklist",
                    "singular": "Parent Picklist",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "lookupTo":{
                    "original": "Lookup To",
                    "singular": "Lookup To",
                    "plural": "Lookup To",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "lookupField":{
                    "original": "Lookup Field",
                    "singular": "Lookup Field",
                    "plural": "Lookup Field",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "section":{
                    "original": "Section",
                    "singular": "Section",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "componentType":{
                    "original": "Component Type",
                    "singular": "Component Type",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": false
                },
                "text": {
                    "original": "Text",
                    "singular": "Text",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": true
                  },
                  "number": {
                    "original": "Number",
                    "singular": "Number",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": true
                  },
                  "email": {
                    "original": "Email",
                    "singular": "Email",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": true
                  },
                  "phone": {
                    "original": "Phone",
                    "singular": "Phone",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": true
                  },
                  "timePicker": {
                    "original": "Time Picker",
                    "singular": "Time Picker",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": true
                  },
                  "textArea": {
                    "original": "Text Area",
                    "singular": "Text Area",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": true
                  },
                  "dateTimePicker": {
                    "original": "Date Time Picker",
                    "singular": "Date Time Picker",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": true
                  },
                  "checkbox": {
                    "original": "Checkbox",
                    "singular": "Checkbox",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": true
                  },
                  "fileUpload": {
                    "original": "File Upload",
                    "singular": "File Upload",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": true
                  },
                  "signature": {
                    "original": "Signature",
                    "singular": "Signature",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": true
                  },
                  "picklistButton": {
                    "original": "Picklist Button",
                    "singular": "Picklist Button",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": true
                  },
                  "lookup": {
                    "original": "Lookup",
                    "singular": "Lookup",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": true
                  },
                  "picklist": {
                    "original": "Picklist",
                    "singular": "Picklist",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": true
                  },
                  "multipicklist": {
                    "original": "Multipicklist",
                    "singular": "Multipicklist",
                    "plural": "",
                    "setupEdit": true,
                    "setupVisible": true
                  },
                  "mappedField": {
                    "original": "Mapped Fields",
                    "singular": "Mapped Field",
                    "plural": "Mapped Fields",
                    "setupEdit": true,
                    "setupVisible": true
                  },
                  "oneColumn": {
                    "original": "One Column",
                    "singular": "One Column",
                    "plural": "One Column",
                    "setupEdit": true,
                    "setupVisible": true
                  },
                  "twoColumn": {
                    "original": "Two Columns",
                    "singular": "Two Columns",
                    "plural": "Two Columns",
                    "setupEdit": true,
                    "setupVisible": true
                  },
                  "sectionName": {
                    "original": "Section Name",
                    "singular": "Section Name",
                    "plural": "Section Name",
                    "setupEdit": true,
                    "setupVisible": true
                  },
                  "required": {
                    "original": "Required",
                    "singular": "Required",
                    "plural": "Required",
                    "setupEdit": true,
                    "setupVisible": true
                  },
            },
            "messages":{
                "createManageCustomForms":"Create and manage custom forms which best fit your use case. Custom Forms allow you to get details regarding a certain scenario when needed",
                "createManageCustomSubForms":"Create and manage subforms which best fit your use case. Subforms act as forms within your forms allowing you to get additional details related to a certain scenario when needed. They can be set up to record single or multiple entries.",
                "setupForm":"Create fields that best fit your business requirements, arrange the form to your liking, and include subforms if required. You are required to create fields for this form before you can arrange them. Once you have created the fields for this form and arranged it, you can then set the form status to active.",
                "setupSubform":"Create fields that best fit your business requirements, arrange the subform to your liking, and include list views to your subform if required. You are required to create fields for this subform before you can arrange them. Once you have created the fields for this form and arranged it, you can then set the form status to active.",
                "manageFormFields": "Manage Fields which will be available for this form",
                "manageSubformFieldArrangement": "Manage the arrangement of fields within your subform",
                "manageSubforms": "Manage subforms for your form",
                "manageSubformListViews": "Manage list views for your subform",
                "selectSubformRecordType": "Select the record type of your subform.",
                "singleSubformRecord": "Will record only a single subform",
                "multipleSubformRecords": "Can record more than one subform",
                "pageArranged": "Page has been arranged",
                "pageNotArranged": "Page not arranged",
                "lastArrangedOn": "Last Arranged on",
                "lastModifiedOn": "Last Modified on",
                "createFieldsForForm": "Create fields which best fit your business requirements for your form. The fields that you create here will be available to be arranged for this form.",
                "removeFieldFromArrangement": "Remove this field from the arrangement page to delete this field.",
                "selectComponentTypeForField": "Select a component type that makes most sense for the field which you want to create.",
                "allowTextEntryLimited": "Allows users to enter text (limited to 255 characters)",
                "allowNumberEntry": "Allows users to enter any number",
                "allowEmailEntry": "Allows users to enter an email address",
                "allowPhoneNumberEntry": "Allows users to enter a phone number",
                "allowTimeSelection": "Allows users to select a time",
                "allowTextEntryExtended": "Allows users to enter text (user allowed to enter up to 32000 characters)",
                "allowDateTimeSelection": "Allows users to select a date with a specific time.",
                "allowTrueFalseSelection": "Allows users to select a true(Checked) or false(Unchecked) value",
                "allowFileUpload": "Allows users to upload one or more files",
                "allowSignatureEntry": "Allows users to enter their signature",
                "allowMultipleSelectionWithDependency": "Allows users to make one or more selections from a list of options (Has Parent-Child Dependency)",
                "allowMultipleSelectionListOptions": "Allows users to make one or more selections from a list of options",
                "allowLookupSelection": "Allows users to make a selection by looking up existing information within the system",
                "allowSingleSelectionWithDependency": "Allows users to make a single selection from a list of options (Has Parent-Child Dependency)",
                "allowSingleSelectionListOptions": "Allows users to make a single selection from a list of options",
                "allowMultipleSelection": "Allows users to make multiple selections from a list of options",
                "addPicklistFields": "Add the fields that should appear in this picklist. These fields will be available for users to choose from when interacting with the picklist.",
                "chooseFieldForLookup": "Choose the field you want to look up from the selected module. This will allow you to retrieve and link relevant data from that field.",
                "selectLookupModule": "Select the lookup module to link this field with data from another module. This allows you to pull in related information and establish connections between different modules.",
                "chooseParentPicklistForDependency": "Choose a parent picklist to define a dependency. This selection will link this picklist to a parent picklist, allowing you to map the parent picklist fields to the fields in this picklist within the mapping page. After mapping, selections made on a parent picklist within the form will then affect the options available for this picklist.",
                "selectParentAndChildOptions": "Select a Parent Option and choose its Child Options. If you need to change the Parent Option, first deselect the current one and then select a new Parent Option. To remove any mapped fields, navigate to the Preview tab and clear the selections there.",
                "arrangeFormFields": "Arrange the form to your liking by clicking on your desired field from the available fields box, then dragging and dropping it onto the canvas. You can rename the sections by clicking on the edit icons beside them. To remove a field or section, click on the 'x' button beside it.",
                "removeSectionAndFieldsWarning": "This section and the fields tied to it will be removed from the Form builder. This cannot be undone.",
                "cannotPerformActionChildPicklistPresent": "This action cannot be performed since the child picklist",
                "childPicklistIsPresent": "is present in form. Remove",
                "removeParentPicklist": "to remove this parent picklist.",
                "cannotPerformActionParentPicklistMissing": "This action cannot be performed since the parent picklist",                     
                "parentPicklistIsMissing": "is not present in form. Add",                     
                "addChildPicklist": "to add this child picklist.",
                "whatIsRunningNoFormat":"What is Running Number Format?",
                "runningNoDesc":"Running Number Format Allows you to customize the Auto Number Field for your forms. This includes substitution variables and any characters you wish to prefix or append",
                "mapPicklistFields":"Map picklist fields by selecting a Parent Field and then selecting the necessary Picklist Fields for it. You can view the Mapped Fields below.",
                "addDescriptionHere":"Add description here...",
                "modifyFieldSettings":"Modify the Read-Only and Required settings for this field. Changes will apply to this field's edit page",
                "selectLayout":"Select the layout that you would prefer for your section. Fields that you add to the section will be displayed according to your selected layout."
                }
        }
    }
}
}
