import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ChecklistTableComponent } from '../../shared/checklist-table/checklist-table.component';
import { Router } from '@angular/router';
import { GridOptions, IServerSideGetRowsParams } from 'ag-grid-community';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, forkJoin } from 'rxjs';
import { PERSISTANT_ITEM, ServerRequest } from 'src/app/_models/global.data.model';
import { BreadcrumbService } from 'src/app/_services/breadcrumb.service';
import { DeleteRecordService } from 'src/app/_services/delete-record.service';
import { JobOrdersService } from 'src/app/_services/job-orders.service';
import { ServiceZoneService } from 'src/app/_services/service-zone.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { ButtonRendererComponent } from 'src/app/renderer/button-renderer.component';
import { TableHeaderToolTipComponent } from 'src/app/shared/table-header-tool-tip/table-header-tool-tip.component';
import { ChecklistService } from 'src/app/_services/checklist.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AssetService } from 'src/app/_services/asset.service';
import * as moment from 'moment';
import { PermissionService } from 'src/app/auth/permission.service';
import { DynamicLabelsService } from 'src/app/_services/dynamic-labels.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { UsersService } from 'src/app/_services/admin/users.service';
var translateData: any;
@Component({
  selector: 'app-list-checklist',
  templateUrl: './list-checklist.component.html'
})
export class ListChecklistComponent extends SubscriptionUtil implements OnInit, OnDestroy, OnChanges {
  @Input() isFromAsset: boolean;
  @Input() assetId: boolean;

  filterActive: boolean = false;
  filterClick: boolean = false;


 
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  frameworkComponents: any;
  gridParams : any;
  startFrom: any;
  product: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions: GridOptions;
  overlayNoRowsTemplate: string;
  productsFamilies: any;
  productsType: any;
  searchValue: any
  currentRecordNumber: any;
  totalRecord: any;

  refreshTemplateList!: Subscription
  DataDetails: any[]=[];
  noData: boolean = false;
  noDataIcon: boolean= false;
  joData: any;
  jobOrderTypes: any;
  serviceZones: any;
  systems: any;

  filter: any = {
    fromDate: null,
    toDate: null,
    status: null,
    tmplCheckListId: null,
    serviceZoneId: null,
    assetsTypeId: null};
    userName: null

  myGroup!: any;
  hideOnDateTimeSelect: boolean = true;
  @ViewChild('dateFilter', { static: false }) 
  private dateFilter: any;
  filterAPILoaded: boolean= false;
  equipmenntTypes: any;
  assetsType: any;
  
  searchValueData: boolean = false;
  dateRange!: Date[];

  satatus:any;
  create!: boolean;
  translate: any;
  loadTable: number;
  selectedUser: any;

  public showingTable = {
    user: false,
  };
  allUsers: any;

  constructor(
    private router: Router,
    private spinner : NgxSpinnerService,
    private checklistService: ChecklistService,
    private errorUtil: ErrorUtil,
    private deleteRecordService: DeleteRecordService,
    private breadcrumbService: BreadcrumbService,
    private serviceZonesService: ServiceZoneService,
    private util: UtilServiceService,
    private assetService:AssetService,
    private perm: PermissionService,
    private languageTranslateService: LanguageTranslateService,
    private userService: UsersService
    ) {
      super();
      this.refreshTemplateList = checklistService.refreshList$.subscribe(item => {
        console.log(item,"=====")
      if(item == 'Deleted Checklist'){
        this.onRefreshList()
      }
      });
      this.frameworkComponents = {
        buttonRenderer: ButtonRendererComponent,
      }
      this.gridOptions = {
        cacheBlockSize: 25,
        rowModelType: "infinite",
        sortingOrder: ["desc", "asc"],
        onGridSizeChanged: (params) => {
          params.api.sizeColumnsToFit();
        },
        tooltipShowDelay: 100,
      };
      this.defaultColDef = {
        enableRowGroup: true,//ag-Grid-Enterprise
        enablePivot: true,//ag-Grid-Enterprise
        enableValue: true,//ag-Grid-Enterprise
        sortable: true,
        resizable: true,
        filter: false,
        tooltipComponent: TableHeaderToolTipComponent
      };
      //this.overlayNoRowsTemplate = "<div class='not-found'><span>{{'general.messages.noRecordsFound'}}</span> </div>";
      this.myGroup = new UntypedFormGroup({
        dateRange: new UntypedFormControl(""),
        status: new UntypedFormControl(''),
       
      });
    }
  ngOnChanges(changes: SimpleChanges): void {
   // throw new Error('Method not implemented.');
  }
    ngOnInit() {
      this.create = this.perm.capable("checklists", "create")
      this.getLanguageData()
       }
  /******************************************** Filter List ******************************************/
 /******************************************** Filter List ******************************************/
 getFilterList(){
  const sources = [
    this.assetService.getTypes(true),
    this.assetService.getServiceZones(),
    this.assetService.getEquipmenntTypes(),
  ];
  this.push(
    forkJoin(sources).subscribe(
      ([
        typeResponce,
        szResponce,
        etResponce,
      ]) => {
        this.assetsType = typeResponce.body.sort((elementA: any, elementB: any) => {
          if (elementA?.order > elementB?.order) {
            return 1;
          }
  
          if (elementA?.order < elementB?.order) {
            return -1;
          }
  
          return 0;
        });
        this.serviceZones = szResponce;
        var etData:any[]=[]
        etResponce.forEach((element: any) => {
          var newData ={label:element }
          etData.push(newData)
        });
        this.equipmenntTypes = etData;
      }
    )
  );
}

  public toggleFilterView() {
    this.filterActive = !this.filterActive;
    this.filterClick = !this.filterClick;
    if (this.filterAPILoaded === false) {
      this.getFilterList()
    }
    this.filterAPILoaded = true;
  }
  
    viewCell(e: any){
      if (e.colDef.field === "runningNo") {
        this.router.navigate(['dashboard/checklist/view'], { queryParams: { id: e.data.id}});
      }
     }
    
    onFilteruttonClick(event: any) {
      this.filterActive = !this.filterActive;
      this.filterClick = !this.filterClick;
    }

    
   /********************************** Click Cell Event *****************************/
   editStatus(e:any) {
    if (e.action === 'edit') {
     
      this.router.navigate(['dashboard/checklist/update'], { queryParams: { id: e.rowData.id}});
     }
    else if (e.action === 'delete') {
      e.rowData.deleteType='Checklist'
      e.rowData.deleteHeader = this.translate.checklist.label.singular
      e.rowData.message= this.translate?.general.messages.confirmDelete+" "+e.rowData.runningNo+"?" 
        
      console.log(e.rowData)
      this.deleteRecordService.getItems(e.rowData);    
  
    }
   }
   
   onRefreshList() {
    this.spinner.show()
    this.onGridReady(this.gridParams);
  }

  onStatusChange(event: any) {
    this.onGridReady(this.gridParams);
  }

  /********************************** Search Data **************************/
  onSearch() {
    if (this.searchValue && this.searchValue.length > 0) {
      this.searchValueData = true
      this.onRefreshList();
    }
   
  }
  /********************************** Initilize Grid Table **************************/
  onGridReady(params: any) {
    //console.log(params)
    this.gridParams = params;

    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    var dataSource = {
      getRows: (params: IServerSideGetRowsParams) => {
        this.getServerSideData(params);
      },
    };

    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDatasource(dataSource);
  }

  /********************************** Call Customer API to Display data **************************/
  getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
     this.spinner.show()
    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);
    console.log('Making a server request: ', this.filter);

    if(this.isFromAsset && this.assetId){
      this.filter.assetId = this.assetId
      this.filter.userName = this.selectedUser ? this.selectedUser.login : null
    }

    this.push(this.checklistService.getAllChecklistGlobal(this.searchValue, serverRequest,this.filter) .subscribe(
          (data: any) => {
            console.log(data.body);
            this.joData = data.body.data;
            this.totalRecord = data.body.total;
            this.joData.forEach((element: any) => {
              if(element.status ==  "COMPLETED" && this.perm.capable("checklists", "remove")){
                element.page = 'hieEdit';//for hide delete button
              }
            });
            
            this.setGridData(
              agGridGetRowsParams,
              this.joData,
              data.body.total,
              data.body.status
            );
            this.spinner.hide();
          },
          (err) => {
            console.log(err)
            const title = this.errorUtil.processErrorTitle(err.error, this.translate)
            this.errorUtil.setErrorMessage(400, null, title, 'error', 3000);
            this.spinner.hide();
          }
        )
        );
  }
  /********************************** Set Data for Grid Table **************************/
  private setGridData( agGridGetRowsParams: IServerSideGetRowsParams,resultItems: any[],totalCount: number, status: string ) {
    console.log(resultItems, "===");
  
      // if (this.joData.length === 0) {
      //   this.noData = false;
      //   this.noDataIcon = true;
      //   this.gridApi.showNoRowsOverlay();
      // } else {
      //   this.noData = true;
      //   this.noDataIcon = false;
  
      //   agGridGetRowsParams.successCallback(this.joData, totalCount);
      // }

      if (status === "NO_DATA" || status === "SEARCH_NO_RESULTS") {
        this.noData = true;
        this.noDataIcon = true;
        this.gridApi.showNoRowsOverlay();
      } else {
        this.noData = false;
        this.noDataIcon = false;
  
        if (resultItems.length === 0) {
          this.gridApi.showNoRowsOverlay();
        } else {
          console.log(totalCount);
          agGridGetRowsParams.successCallback(resultItems, totalCount);
        }
  
       ///CS-4758  this.gridApi.sizeColumnsToFit();
      }
    
   
  }
  /********************************** Request Parameter for api **************************/

  getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
      this.parameters = { colId: this.isFromAsset ? "createdDate" : "runningNo", sort: "desc" };
      agGridRequest.sortModel.push(this.parameters);
    }

    //console.log(agGridRequest)
    this.currentRecordNumber = agGridRequest.startRow;
    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,
    };
  }
  
  resetSearch(){
    this.filter =  {typeId : null, systemId : null, serviceZoneId : null};
    this.onGridReady(this.gridParams);
     
  }
  push(obs: any) {
    super.push(obs);
  }
   /********************************** On Destroy *****************************/
   private onDestroy() {
    this.refreshTemplateList.unsubscribe()
    super.ngOnDestroy();
  }
  

  createView(){
    if(this.isFromAsset && this.assetId){ 
      this.router.navigate(["dashboard/checklist/create"], {
        queryParams: { assetsId: this.assetId },
      });
    }
    else{
      this.router.navigate(['dashboard/checklist/create'])
    }
    
  }


   /******************************************** Clear Value Dynamic Picklist ******************************************/
   public clearValue(what: any) {

    if (what === "customer") {
      this.selectedUser = null;
      //this.customerId.setValue(null);
    }
    if(what === 'all')
    {
      this.selectedUser = null
    this.myGroup.get("dateRange").setValue(null);
    this.filter = {
      fromDate: null,
      toDate: null,
      status: null,
      tmplCheckListId: null,
      serviceZoneId: null,
      assetsTypeId: null
    };
    }
    this.onRefreshList();
  }


  /******************************************** Right Click Context Menu ******************************************/
  public getContextMenuItems = (params: any) => {
    params.node.data.field = params.column.colId;
    let url = "";
    let data = "";

    let results = [];

    if (params.column.colId == "runningNo") {
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });
    }

    return results;
  };


   /******************************************** Redirect To ******************************************/
   private redirectToNewTab(data: any) {
    let url;
    if (data.field === "runningNo") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(
          ['dashboard/checklist/view'], { queryParams: { id: data.id}}
        )
      );
     
    }

    if (url) {
      url = url.replace("%23", "#");
      this.util.openItemToNewTab(url);
    }
  }

  public onChangeDate() {
    
    if (this.dateRange[0] != null && this.dateRange[1] !== null) {
     let startDate = moment(this.dateRange[0]).format("YYYY-MM-DD");
     let endDate = moment(this.dateRange[1]).format("YYYY-MM-DD");
      this.filter.fromDate = this.util.timeFormatter( moment("00:00:00", "h:mm A").format("h:mm A"), moment(this.dateRange[0], "YYYY-MM-DD").format("YYYY-MM-DD")) .toISOString();
      this.filter.toDate = this.util .timeFormatter( moment("23:59:00", "h:mm A").format("h:mm A"),moment(this.dateRange[1], "YYYY-MM-DD").format("YYYY-MM-DD") ) .toISOString();

      this.dateFilter.hideOverlay()
      this.onRefreshList();
    }

  }


  public onSearchCheck()
  {
    if(this.searchValue.length == 0)
    {
      this.searchValueData = false
      this.searchValue = ''
      this.onRefreshList()
    }
  }
  public onClear(){
    this.searchValueData = false
    this.searchValue = ''
    this.onRefreshList()
    
  }

  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
        translateData = data
        this.initializeColumns()
        this.satatus = [
          { label: this.translate?.general.status.new.singular, value: "NEW" },
          { label: this.translate?.general.status.draft.singular, value: "DRAFT" },
          { label: this.translate?.general.status.completed.singular, value: "COMPLETED" },
          { label: this.translate?.general.status.cancelled.singular, value: "CANCELED" },
         ]
      }
    }))
    console.log("translate",this.translate)
    // this.mainLabel = this.translate?.contacts.label.singular
  }


  initializeColumns() {
    this.columnDefs = []
    this.overlayNoRowsTemplate = "<div class='not-found'><span>"+this.translate?.general.messages.noRecordsFound+"</span> </div>";
 //   console.log("initiateCol", translateData, "=======", this.columnDefs)
    var newData =  [
      { headerName: this.translate?.checklist.fields.checklistNo.singular, field: 'runningNo', width: 150, headerTooltip: "#",cellClass: function (params: any) { return ['table_default_color'];}},
      { headerName: this.translate?.checklist.fields.checklistName.singular, field: 'tmplChecklist.name', width: 150,headerTooltip: "#" },
      {  hide: this.isFromAsset == true ? true : false, headerName: this.translate?.asset.label.singular, field: 'assets.name', width: 80, headerTooltip: "#",},
      {  hide: this.isFromAsset == true ? true : false, headerName: this.translate?.checklist.fields.assetType.singular, field: 'assets.assetsType.name', width: 80, headerTooltip: "#",},
      { hide: this.isFromAsset == true ? true : false, headerName: this.translate?.checklist.fields.equipmentType.singular, field: 'assets.equipmentType', width: 80, headerTooltip: "#",},
      { hide: this.isFromAsset == true ? true : false, headerName: this.translate?.setup.serviceZone.label.singular, field: 'assets.serviceZone.name', width: 80, headerTooltip: "#",},
      { hide: this.isFromAsset == true ? false : true, headerName: this.translate?.general.systemInformation.fields.createdDateTime.singular, field: 'createdDate', width: 80, headerTooltip: "#",},
      { hide: this.isFromAsset == true ? false : true, headerName:  this.translate?.general.systemInformation.fields.createdBy.singular, field: 'createdBy', width: 80, headerTooltip: "#",},
      { headerName: this.translate?.checklist.fields.status.singular, field: 'active', width: 100, headerTooltip: "#",
        cellRenderer: function (params: any) {
          if(params.data){
            if(params.data.status == 'NEW' || params.data.status =='Pending'){
              return '<span class="p-mr-2  p-tag p-component p-checklist-tag-new" ><span class="p-tag-value text-dark">'+translateData.general.status.new.singular+'</span></span>';
            }else if(params.data.status == 'DRAFT'){
              return '<span class="p-mr-2  p-tag p-component p-checklist-tag-draft" ><span class="p-tag-value text-dark">'+translateData.general.status.draft.singular+'</span></span>';
            }else if(params.data.status == 'COMPLETED'){
              return '<span class="p-mr-2  p-tag p-component p-tag-completed" ><span class="p-tag-value text-dark">'+translateData.general.status.completed.singular+'</span></span>';
            }else if(params.data.status == 'CANCELED'){
              return '<span class="p-mr-2  p-tag p-component p-tag-cancel" ><span class="p-tag-value text-dark">'+translateData.general.status.cancelled.singular+'</span></span>';
            }
          }
        }
      },
      {  hide: this.isFromAsset == true ? true : false, headerName: this.translate?.general.buttons.action.singular, filter: false,sortable: false,headerClass: 'marging-auto hide-action-border', width: 150, cellStyle: { "text-align": "center" }, cellRenderer: 'buttonRenderer',
        headerTooltip: "#",  
        cellRendererParams: {
            onClick: this.editStatus.bind(this),
            permissions: {
              update: this.perm.capable("checklists", "update"),
              remove: this.perm.capable("checklists", "remove"),
            },
            hidden: {
              update: !this.perm.capable("checklists", "update"),
              remove: !this.perm.capable("checklists", "remove"),
            },
          
          }
      }
    ];

    this.columnDefs = newData;
    if (this.gridApi) {
      this.gridApi.setColumnDefs(this.columnDefs);
      setTimeout(() => { this.gridApi.sizeColumnsToFit(); },500);
    }
  }


    /******************************************** On Customer Select Value ******************************************/
    public onUserSelect(event: any) {
     // this.customerId.setValue(event.id);
      this.selectedUser = event;
  
      this.onRefreshList();
  
      this.closeTable("user");
    }


  /******************************************** Close Table Dynamic Picklist ******************************************/
  public closeTable(what: string) {
    if (what === "user") {
      this.showingTable.user = false;
    }
  }

    /******************************************** Get All Customer By Query ******************************************/
    public getUsers(event: any) {
      const query = event.query.replace(
        /[&\/\\#,+()$~%!.„'":*‚^_¤?<>|@ª{«»§}©®™ ]/g,
        ""
      );
  
      if (query && query.length > 2) {
        this.push(
          this.userService
            .advancedSearchSetupUsers(query, true)
            .subscribe((users: any) => {
              this.allUsers = users;
            })
        );
      }
    }

  /******************************************** Show Table Dynamic Picklist ******************************************/
  public showTable(what: string) {
    if (what === "user") {
      this.showingTable.user = true;
    }
  }

  
}
