<div class="position-relative d-flex h-100-28" >
    <div class="ag-grid-table-full h-100" [ngClass]="{ 'ag-grid-table': isFilterViewToggle }" [hidden]="noData === null || noData === true">
        <ag-grid-angular
            #agGrid
            class="ag-theme-balham h-100"
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
            [defaultColDef]="defaultColDef"
            [pagination]="false"
            [cacheOverflowSize]="2"
            [maxConcurrentDatasourceRequests]="-1"
            [suppressDragLeaveHidesColumns]="true"
            [allowContextMenuWithControlKey]="true"
            [frameworkComponents]="frameworkComponents"
            (cellClicked)="viewCell($event)"
            (gridReady)="onGridReady($event)"
            [getContextMenuItems]="getContextMenuItems"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        >
        </ag-grid-angular>

        <div class="col-12">
            <p class="ag-record-row-summary-panel padds text-end" id="label_ProjectsTableRec">
                {{ totalRecords }}&nbsp;{{translate?.general.messages.records}}
            </p>
        </div>
    </div>
    <div *ngIf="noData" class="col-12 mt-5 py-4">
        <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
            <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound" />
             <p class="text-6 my-1 font-weight-bold">{{translate?.general.messages.noRecordsFound}}</p>
        </div>
    </div>
    <!-- Filter -->
    <div class="custella-form-container-content-pane layout-filter" [ngClass]="{ 'layout-filter-active': isFilterViewToggle }" >
        <div class="custella-form-container-content-pane-title">
            <span class="text-14 font-medium" id="label_ProjectsFilter">{{ translate?.general.messages.filterBy }}</span>
            <i class="fa-regular fa-arrow-right-from-line" [pTooltip]="translate?.general.buttons.closeFilter.singular" tooltipPosition="left" (click)="toggleFilter()" id="btn_ProjectsFilterClose"></i>
        </div>

        <div class="custella-content">
            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_ProjectsType">{{ translate?.project.fields.type.singular }}</span>
                <p-dropdown [options]="allProjectType" id="picklist_ProjectsTypeField" [showClear]="filters.type ? true : false" [placeholder]="translate?.general.messages.none" optionLabel="label" optionValue="id" [(ngModel)]="filters.type" [filter]="true" (onChange)="refresh()"></p-dropdown>
            </div>

            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_ProjectsServiceZone">{{ translate?.setup.serviceZone.label.singular }}</span>
                <p-dropdown [options]="allServiceZones" id="picklist_ProjectsServiceZoneField" [showClear]="filters.service_zone ? true : false" [placeholder]="translate?.general.messages.none" optionLabel="name" optionValue="id" [(ngModel)]="filters.service_zone" [filter]="true" (onChange)="refresh()"></p-dropdown>
            </div>

            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_ProjectsCategoryType">{{ translate?.project.fields.categoryType.singular }}</span>
                <p-dropdown [options]="allProjectCategorys" id="picklist_ProjectsCategoryTypeField" [showClear]="filters.category_type ? true : false" [placeholder]="translate?.general.messages.none" optionLabel="label" optionValue="id" [(ngModel)]="filters.category_type" [filter]="true" (onChange)="refresh()"></p-dropdown>
            </div>

            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_ProjectsGeofence">{{ translate?.setup.geofence.label.singular }}</span>
                <p-dropdown [options]="allGeofences" id="picklist_ProjectsGeofenceField" [showClear]="filters.geofence ? true : false" [placeholder]="translate?.general.messages.none" optionLabel="geoFenceName" optionValue="id" [(ngModel)]="filters.geofence" [filter]="true" (onChange)="refresh()"></p-dropdown>
            </div>

            <div class="custella-content-input col-12 " *ngIf="!additionalData?.asInternal">
                <span class="custella-content-input-title" id="label_ProjectsSite">{{ translate?.site.label.singular }}</span>
                <p-dropdown [options]="allSites" id="picklist_ProjectsSiteField" [placeholder]="translate?.general.messages.none" optionLabel="siteName" optionValue="id" [(ngModel)]="filters.site" [filter]="true" (onChange)="refresh()" [showClear]="filters.site ? true : false"></p-dropdown>
            </div>

            <div>
                <span class="clear" id="subtitle_ProjectsClear" (click)="clearAll()">{{translate?.general.buttons.clearAll.singular }}</span>
            </div>
        </div>
    </div>
</div>
