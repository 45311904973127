import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PermissionService } from "src/app/auth/permission.service";
import { JobOrdersService } from "src/app/_services/job-orders.service";
import { ModalServiceService } from "src/app/_services/modal-service.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { TasksTableComponent } from "../../shared/tasks-table/tasks-table.component";
import { ColDef, GetContextMenuItemsParams, GridOptions, IServerSideGetRowsParams, MenuItemDef } from "ag-grid-community";
import * as moment from "moment";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { TasksService } from "src/app/_services/tasks.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { ServerRequest } from "src/app/_models/global.data.model";
import { ErrorUtil } from "src/app/_utilities/error";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs";
import { DynamicFormService } from "src/app/_services/dynamic-form.service";
import { DynamicTableColumnsService } from "src/app/_services/dynamic-table-column.service";
import { DynamicLabelsService } from "src/app/_services/dynamic-labels.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
import { MenuItem } from "primeng/api";
var dateFormateChange: any
var router: Router;
var translateData: any;
@Component({
  selector: "app-checklist-tasks",
  templateUrl: "./checklist-tasks.component.html",
  styleUrls: ["./checklist-tasks.component.scss"],
})
export class ChecklistTasksComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy {
  @Input() checklistRecordId: any = null
  @Input() pmvId: any = null
  searchValue: any;
  gridApi: any;
  gridColumnApi: any;
  public columnDefs: any = [];
  public defaultColDef: any;
  public frameworkComponents: any;
  gridParams: any;
  parameters: any;
  public gridOptions!: Partial<GridOptions>;
  public overlayNoRowsTemplate!: string;
  public allTasks: any = [];
  public totalRecord: any;
  public noTasks: boolean | null = null;


  @ViewChild(TasksTableComponent, { static: false })
  taskTable!: TasksTableComponent;
  create: any;
  taskData: any;
  refresher!: Subscription
  searchValueData: boolean = false;
  sortBy: any;
  sortOrder: any;
  private loadTable = 0;
  dateFormat: any
  translate: any;
  tableContent: any;
    displayJobOrderTemplates: boolean;
    items: MenuItem[]
  constructor(
    private util: UtilServiceService,
    private perm: PermissionService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private jobOrders: JobOrdersService,
    private deleteRecordService: DeleteRecordService,
    private modalService: ModalServiceService,
    private router: Router,
    private taskService: TasksService,
    private errorUtil: ErrorUtil,
    private modal: ModalServiceService,
    private spinner: NgxSpinnerService,
    private dynamicFormService: DynamicFormService,
    private dynamicTableColumnsService: DynamicTableColumnsService,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => {
      if (Object.keys(data).length != 0) {
        this.translate = data
        translateData = data;
        this.items = [
          {label: this.translate.general.buttons.createViaTemplate.singular, command: () => { this.showTable1() },
        }
      ]
        this.setOnlyColumn()
      }
    }))
    this.refresher = this.taskService.refreshList$.subscribe((o: any) => {
      this.onGridReady(this.gridParams);
    });
    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: 'infinite',
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    }
    // this.columnDefs = [
    //   { headerName: 'Task Name', field: 'taskName', width: 250, cellClass: function (params: any) { return ['table_default_color'];}, headerTooltip: "#", },
    //   {headerName: "Task Date",field: "startDateTime", width: 200,
    //   headerTooltip: "#",
    //     cellRenderer: function (params: any) {
    //       if (params.data && params.data.startDateTime) {
    //         return ( moment.tz(params.data.startDateTime, dateFormateChange).format(localStorage.getItem("date_format")!.toUpperCase())
    //         );
    //       }
    //     },
    //   },
    //   { headerName: 'Task Time', field: 'startDateTime', width: 250,filter: false,
    //   headerTooltip: "#",
    //   cellRenderer: function (params: any) {
    //     if(params.data){
    //       return  moment.tz( params.data.startDateTime, dateFormateChange).format("hh:mm A")+' - '+moment.tz( params.data.endDateTime, dateFormateChange).format("hh:mm A");
    //     }
    //   }
    // },
    // { headerName: 'Location', field: 'location', width: 220, headerTooltip: "#",},
    // { headerName: 'Assigned To', field: 'assignedTo.fullName', width: 200, headerTooltip: "#",},
    // { headerName: 'Assigned By', field: 'assignedBy.fullName', width: 200, headerTooltip: "#",},
    // { headerName: 'Team', field: 'team.teamName', width: 200, headerTooltip: "#",},
    // { headerName: "Task Status",field: "taskStatus", width: 200,headerClass: 'marging-auto hide-action-border-left',
    // headerTooltip: "#",
    //   cellRenderer: function (params: any) {
    //     if (params.data) {
    //       if (params.data.taskStatus == "OPEN") {
    //         return ('<span class="p-mr-2 p-tag p-component p-tag-open"><span class="p-tag-value text-dark">Open</span></span>' );
    //       } else if (params.data.taskStatus == "PENDING") {
    //         return (
    //           '<span class="p-mr-2  p-tag p-component p-tag-pending" ><span class="p-tag-value text-dark">Pending</span></span> ');
    //       } else if ( params.data.taskStatus == "IN PROGRESS" || params.data.taskStatus == "IN_PROGRESS") {
    //         return '<span class="p-mr-2  p-tag p-component p-tag-in-progress" ><span class="p-tag-value text-dark">In Progress</span></span>';

    //       } else if (params.data.taskStatus == "COMPLETED") {
    //         return ('<span class="p-mr-2  p-tag p-component p-tag-completed" ><span class="p-tag-value text-dark">Completed</span></span>');

    //       } else if ( params.data.taskStatus == "INCOMPLETE" ||  params.data.taskStatus == "IN_COMPLETED") {
    //         return '<span class="p-mr-2  p-tag p-component p-tag-incomplete" ><span class="p-tag-value text-dark">Incomplete</span></span>';
    //       } else if (params.data.taskStatus == "CANCELLED") {
    //         return ('<span class="p-mr-2  p-tag p-component p-tag-cancel" ><span class="p-tag-value text-dark">Incomplete</span></span>');
    //       }
    //     }
    //   },
    // },
    // ]
    this.defaultColDef = {
      enableRowGroup: true,//ag-Grid-Enterprise
      enablePivot: true,//ag-Grid-Enterprise
      enableValue: true,//ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent
    };
    //this.overlayNoRowsTemplate = "<div class='not-found'><span>{{'general.messages.noRecordsFound'}}</span> </div>";
    this.loadDynamicTable()
  }

  ngOnInit(): void {
    router = this.router;
    this.init();

  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  /******************************************** init ******************************************/
  private init() {
    this.create = this.perm.capable("tasks", "create");
    dateFormateChange = this.auth.getUserTimezone();
    // this.push(
    //   this.jobOrderService.getJobOrderById(this.id).subscribe((data) => {
    //     this.jobOrder = data;
    //   })
    // );
  }
  /******************************************** toggle child component to refresh ******************************************/
  public toggleRefresh() {
    this.taskTable.refresh();
  }

  /******************************************** open modal to create new task ******************************************/
  public new() {
    const data = {
      mode: "add",
      checklistRecordId: this.checklistRecordId,
      pmvId: this.pmvId
    };

    //let finalData = Object.assign(data,null);
    this.modal.openModal("addEditTask", data);
  }


  /******************************************** On Destroy ******************************************/
  public onDestroy() {
    super.ngOnDestroy();
  }
  push(obs: any) {
    super.push(obs);
  }

  /********************************** PreInit *****************************/
  private loadDynamicTable() {
    this.dateFormat = localStorage.getItem("date_format");
    dateFormateChange = this.auth.getUserTimezone();
    this.dynamicFormService.getTableColumn("task").subscribe((res: any) => {
      this.tableContent = res;
      this.sortBy = res.sortBy
      this.sortOrder = res.sortOrder
      var columnDefs: any = this.gridOptions.columnDefs;
      res.tableColumnSetups.forEach((element: any) => {
        this.cellRenderer(element);
        columnDefs.push(element);
        this.gridOptions.api!.setColumnDefs(columnDefs);
      });
      this.loadTable = 1;
      this.onGridReady(this.gridParams);
    });
  }
  getNestedValue(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }
  /********************************** Render Cell *****************************/
  private cellRenderer(element: any) {
    console.log(element, "===", element.dataType)
    var dateFormat: any = localStorage.getItem("date_format");
    if (element.translatePath) {
      element.headerName = this.getNestedValue(this.translate, element.translatePath);
    }
    element.headerTooltip = "#";
    if (element.field === "taskNumber") {
      element.headerComponentParams = {
        template:
          '<div class="ag-cell-label-container" role="presentation" id="Tbl_HeadAssetNo">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>'
      },
        // element.headerName = "Asset No.";
        element.cellClass = function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color-top"];
        };
      element.cellRenderer = function (params: any) {
        if (params.data) {
          return '<p id="' + params.data.rowId + '">' + params.data.taskNumber + '</p>';
        }
      };
    }

    else if (element.field === "customer.name") {
      // element.headerName = "Customer";
      element.headerComponentParams = {
        template:
          '<div class="ag-cell-label-container" role="presentation" id="Tbl_HeadAssetCus">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>'
      },
        element.cellClass = function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color-top"];
        };
      element.cellRenderer = function (params: any) {
        if (params.data) {
          if (params.data && params.data.customer != null) {
            return '<p id="' + params.data.rowId2 + '">' + params.data.customer?.name + '</p>';
          } else if (params.data && params.data.customer == null) {
            return '<span class="p-tag-value text-dark"> ' + "-" + '</span>';
          }

        }
      };
    }
    else if (element.field === "taskStatus") {
      element.cellRenderer = function (params: any) {
        if (params.data) {
          if (params.data.taskStatus == "OPEN") {
            return ('<span class="p-mr-2 p-tag p-component p-tag-open"><span class="p-tag-value text-dark">' + translateData?.general.status.open.singular + '</span></span>');
          } else if (params.data.taskStatus == "PENDING") {
            return (
              '<span class="p-mr-2  p-tag p-component p-tag-pending" ><span class="p-tag-value text-dark">' + translateData?.general.status.pending.singular + '</span></span> ');
          } else if (params.data.taskStatus == "IN PROGRESS" || params.data.taskStatus == "IN_PROGRESS") {
            return '<span class="p-mr-2  p-tag p-component p-tag-in-progress" ><span class="p-tag-value text-dark">' + translateData?.general.status.inProgress.singular + '</span></span>';

          } else if (params.data.taskStatus == "COMPLETED") {
            return ('<span class="p-mr-2  p-tag p-component p-tag-completed" ><span class="p-tag-value text-dark">' + translateData?.general.status.completed.singular + '</span></span>');

          } else if (params.data.taskStatus == "INCOMPLETE" || params.data.taskStatus == "IN_COMPLETED") {
            return '<span class="p-mr-2  p-tag p-component p-tag-incomplete" ><span class="p-tag-value text-dark">' + translateData?.general.status.incomplete.singular + '</span></span>';
          } else if (params.data.taskStatus == "CANCELLED") {
            return ('<span class="p-mr-2  p-tag p-component p-tag-cancel" ><span class="p-tag-value text-dark">' + translateData?.general.status.cancelled.singular + '</span></span>');
          }
        }
      };
    }
    else if (element.dataType == 'date') {
      element.valueFormatter = function (params: any) {
        if (params && params.value) {
          return moment.tz(params.value, dateFormateChange).format(localStorage.getItem("date_format")!.toUpperCase());
        }
      };

    }
    else if (element.dataType == 'datetime') {
      element.cellRenderer = function (params: any) {
        if (params && params.value) {
          return moment.tz(params.value, dateFormateChange).format("hh:mm A" + ", " + localStorage.getItem("date_format")!.toUpperCase());
        } else {
          return '-'
        }
      };
    }
    else if (element.field === "assignedTo") {
      element.cellRenderer = function (params: any) {
        if (params && params.data) {
          var assignedUsersList = "";
          if (params.data.assignedUsersList.length > 1) {
            let coun = params.data.assignedUsersList.length - 1
            assignedUsersList = '<div style="background: #26363e;height:20px;padding: 5px;margin-left: 4px; margin-top: 2.5px; border-radius: 5px" class="d-flex align-items-center"><span style="color: #fff">+' + coun + '</span></div>';
          }

          var nameData = params.data.assignedTo.firstName + " " + params.data.assignedTo.lastName
          return ("<div class='d-flex'>" + nameData + assignedUsersList + "</div>");
        }
      };
    }
    else if (element.field === "priority") {
      element.cellRenderer = function (params: any) {
        if (params.data) {
          if (params.data.priority == "Medium") {
            return ('<span class="p-mr-2 p-tag p-component p-tag-medium"><span class="p-tag-value text-dark">Medium</span></span>');

          } else if (params.data.priority == "Low") {
            return ('<span class="p-mr-2  p-tag p-component p-tag-low" ><span class="p-tag-value text-dark">Low</span></span> ');

          } else if (params.data.priority == "High") {
            return '<span class="p-mr-2  p-tag p-component p-tag-in-high" ><span class="p-tag-value text-dark">High</span></span>';

          }
        }
      };
    }
    else if (element.dataType == "text") {
      element.valueFormatter = function (params: any) {
        if (params && params.value) {
          return params.value
        } else {
          return '-'
        }
      };

    } else if (element.dataType == "boolean") {
      element.cellRenderer = function (params: any) {
        if (params && params.value) {
          return 'Yes'
        } else {
          return 'No'
        }
      }
    }
  }

  /******************************************** on grid ready ******************************************/
  public onGridReady(params: any) {
    if (params != undefined) {
      this.gridParams = params;

      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      var dataSource = {
        getRows: (params: IServerSideGetRowsParams) => {
          if (this.loadTable === 1) { this.getServerSideData(params); }
        },
      };
      this.gridApi.sizeColumnsToFit();
      this.gridApi.setDatasource(dataSource);
    }
  }

  /******************************************** get server side data ******************************************/
  private getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
    // Show spinner when starting data loading
    this.spinner.show();
    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);

    this.push(
      this.taskService.getAllStaffTask(this.searchValue, serverRequest, null, null, null, null, null, null, null, null, this.checklistRecordId, this.pmvId).subscribe((data: any) => {
        console.log(data)

        if (data.status === 200 || data.status === 201) {
          this.taskData = data.body.data;
          this.totalRecord = data.body.total
          this.setGridData(agGridGetRowsParams, this.taskData, data.body.total, data.body.status);

          this.spinner.hide();
        }
        (err) => {
          console.log(err)
          const title = this.errorUtil.processErrorTitle(err.error, this.translate)
          this.errorUtil.setErrorMessage(400, null, title, 'error', 3000);
          this.spinner.hide();
        }
      }))

  }

  /******************************************** set grid data ******************************************/
  private setGridData(
    agGridGetRowsParams: IServerSideGetRowsParams,
    resultItems: any[],
    totalCount: number,
    status: string
  ) {
    //debugger
    if (status === "NO_DATA" || status === "SEARCH_NO_RESULTS") {
      this.noTasks = false;
    } else {
      this.noTasks = true;
  
      if (resultItems.length === 0) {
        this.gridApi.showNoRowsOverlay();
      } else {
        agGridGetRowsParams.successCallback(resultItems, totalCount);
       ///CS-4758 this.gridApi.sizeColumnsToFit();
      }
    }
  }

  /******************************************** get server request ******************************************/
  private getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
      this.parameters = { colId: this.sortBy, sort: this.sortOrder }

      agGridRequest.sortModel.push(this.parameters);
    }

    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,
    };
  }

  /******************************************** refresh data ******************************************/
  public onRefreshList() {

    this.onGridReady(this.gridParams);

  }
  /********************************** Search Data **************************/
  onSearch() {
    if (this.searchValue && this.searchValue.length > 0) {
      this.searchValueData = true
      this.onRefreshList();
    }

  }
  public onSearchCheck() {
    if (this.searchValue.length == 0) {
      this.searchValueData = false
      this.searchValue = ''
      this.onRefreshList()
    }
  }
  public onClear() {
    this.searchValueData = false
    this.searchValue = ''
    this.onRefreshList()

  }

  /********************************** Get Context Menu **************************/
  public getContextMenuItems = (params: any) => {
    params.node.data.field = params.column.colId;

    return this.dynamicTableColumnsService.rightClick(params);
  };

  /******************************************** view tasks ******************************************/
  public viewTasks($event: any) {
    // console.log($event);
    const col = $event.colDef.field;
    let id = null;

    if (col === "taskName") {
      id = $event.data.id;

      // this.router.navigate(["/dashboard/tasks/view"], {
      //   queryParams: { id },
      // });
      this.util.openTaskVIewPage(id);
    }
  }

  /********************************** View Singal Record **************************/

  onCellClicked(e: any) {
    if (e.colDef.field === "taskName") {
      this.util.openTaskVIewPage(e.data.id)
    } else {
      this.dynamicTableColumnsService.redirectURL(e)
    }
  }

  /******************************************** edit delete action ******************************************/
  private editTask(e: any) {
    // console.log(e.rowData);
    const rowData = e.rowData;

    if (e.action === "edit") {
      let data = e.rowData;
      data.mode = "edit";

      this.modalService.openModal("addEditTask", data);
    } else if (e.action === "delete") {
      const data = {
        deleteHeader: this.translate.task.label.singular,
        deleteType: "Task",
        message: this.translate?.general.messages.confirmDelete + " " + `${rowData.taskName}?`,
        id: rowData.id,
      };

      this.push(
        this.deleteRecordService.getItems(data).subscribe((data) => {
          // refresh the table

          this.onRefreshList();
        })
      );
    }
  }
/************************** Set Table Header: Language Translated Dynamically (Preeti) ************************************/
private setOnlyColumn() {
  console.log(this.tableContent);
  if(this.tableContent){
    // Clear the existing columnDefs array
    const columnDefs: ColDef[] = [];
    this.overlayNoRowsTemplate = "<div class='not-found'><span>"+this.translate?.general.messages.noRecordsFound+"</span> </div>";
    // Iterate through the table content and modify columns
    this.tableContent.tableColumnSetups.forEach((element: any) => {
      this.cellRenderer(element);
      columnDefs.push(element);
    });


      // Set the new column definitions
      this.gridOptions.api!.setColumnDefs(columnDefs);
      this.gridApi.sizeColumnsToFit();
    }
  }



      /******************************************** close asset table ******************************************/
      public closeTable() {
        this.displayJobOrderTemplates = false;
      }
      /******************************************** show / hide asset table ******************************************/
      public showTable1() {
        this.displayJobOrderTemplates = !this.displayJobOrderTemplates;
      }
  
 
}
