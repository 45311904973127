import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { CustomersService } from 'src/app/_services/customers.service';
import { DeleteRecordService } from 'src/app/_services/delete-record.service';
import { TicketsService } from 'src/app/_services/tickets.service';
import { InventoryService } from 'src/app/_services/inventory.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { AttachmentService } from 'src/app/_services/attachment.service';
import { SignUpService } from 'src/app/_services/sign-up.service';
import { JobOrdersService } from 'src/app/_services/job-orders.service';
import { UsersService } from 'src/app/_services/admin/users.service';
import { AssetService } from 'src/app/_services/asset.service';
import { ProductService } from 'src/app/_services/product.service';
import { TasksService } from 'src/app/_services/tasks.service';
import { PartOrderService } from 'src/app/_services/part-order.service';
import { IqcFqcService } from 'src/app/_services/iqc-fqc.service';
import { SalesOrdersService } from 'src/app/_services/sales-orders.service';
import { DynamicFormService } from 'src/app/_services/dynamic-form.service';
import { ImportExportService } from 'src/app/_services/import-export.service';
import { PackagesService } from 'src/app/_services/packages.service';
import { RolesService } from 'src/app/_services/admin/roles.service';
import { ProfilesService } from 'src/app/_services/admin/profiles.service';
import { TeamService } from 'src/app/_services/team.service';
import { DevicesService } from 'src/app/_services/devices.service';
import { DynamicSkillsService } from 'src/app/_services/admin/dynamic-skills.service';
import { LeaveService } from 'src/app/_services/leave.service';
import { ShiftsService } from 'src/app/_services/shifts.service';
import { ProjectService } from 'src/app/_services/project.service';
import { SiteService } from 'src/app/_services/site.service';
import { ChubbServicesService } from 'src/app/_services/chubb-services.service';
import { ServiceZoneService } from 'src/app/_services/service-zone.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { NgxSpinnerService } from 'ngx-spinner';
import { VisitsService } from 'src/app/_services/visits.service';
import { ChecklistService } from 'src/app/_services/checklist.service';
import { AttendanceService } from 'src/app/_services/attendance.service';
import { OvertimeService } from 'src/app/_services/overtime.service';
import { ProductivityService } from 'src/app/_services/productivity.service';
import { PmvService } from 'src/app/_services/pmv.service';
import { CustomerSurveyService } from 'src/app/_services/customer-survey.service';
import { error } from 'console';
import { DynamicTableColumnsService } from 'src/app/_services/dynamic-table-column.service';
import { DynamicLabelsService } from 'src/app/_services/dynamic-labels.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { WorkflowService } from 'src/app/_services/workflow.service';
import { EmailTemplateService } from 'src/app/_services/email-template.service';
import { DynamicFormSetupService } from 'src/app/_services/dynamic-form-setup.service';
import { PriceRateCardBookService } from 'src/app/_services/price-rate-card-book.service';
import { AiService } from 'src/app/_services/admin/ai.service';

@Component({
  selector: 'app-comman-delete',
  templateUrl: './comman-delete.component.html',
  styleUrls: ['./comman-delete.component.scss'],
  providers: [ConfirmationService]
})
export class CommanDeleteComponent extends SubscriptionUtil implements OnInit, OnDestroy, OnChanges {

  @Input() data: any;
  message = "Are you sure?"
  translate: any;
deleteHeader: any;
  constructor(
    private confirmationService: ConfirmationService,
    private customerService: CustomersService,
    private ticketsService: TicketsService,
    private errorUtil: ErrorUtil,
    private deleteRecordService: DeleteRecordService,
    private inventoryService: InventoryService,
    private visitService: VisitsService,
    private attachmentService: AttachmentService,
    private signUpService: SignUpService,
    private jobOrderService: JobOrdersService,
    private userService: UsersService,
    private assetService: AssetService,
    private productService: ProductService,
    private taskService: TasksService,
    private partOrderService: PartOrderService,
    private iqcFqcService: IqcFqcService,
    private salesOrderService: SalesOrdersService,
    private dynamicFormService: DynamicFormService,
    private importExportService: ImportExportService,
    private packageServices: PackagesService,
    private roleServices: RolesService,
    private profileServices: ProfilesService,
    private teamService: TeamService,
    private devicesSevices: DevicesService,
    private skillsService: DynamicSkillsService,
    private leaveService: LeaveService,
    private shiftService: ShiftsService,
    private projectService: ProjectService,
    private siteService: SiteService,
    private chubbService: ChubbServicesService,
    private serviceZone: ServiceZoneService,
    private spinner: NgxSpinnerService,
    private checklistService: ChecklistService,
    private attendanceService: AttendanceService,
    private overtimeService: OvertimeService,
    private productivityService: ProductivityService,
    private pmvService: PmvService,
    private customerSurveyService: CustomerSurveyService,
    private dynamicTableColumnsService: DynamicTableColumnsService,
    private languageTranslateService: LanguageTranslateService,
    private workflowService: WorkflowService,
    private emailTemplateService: EmailTemplateService,
    private dynamicFormSetupService:DynamicFormSetupService,
    private priceRateCardBookService :PriceRateCardBookService,
    private aiService: AiService
  ) {
    super();
    
  }
  ngOnChanges(changes: SimpleChanges): void {
   
  }

  ngOnDestroy(): void {
    //super.ngOnDestroy();
  }
  ngOnInit(): void {
    this.getLanguageData()
    this.data.displayDeleteTitle = this.data.displayDeleteTitle ? true : false;
    this.deleteHeader = this.data.deleteHeader ? this.data.deleteHeader : this.data.deleteType;
    this.confirm1()
    
  }

  sliceAfterDelete(text: string): string {
    const phrase = this.translate?.general.messages.confirmDelete
    const index = text.indexOf(phrase);
  
    if (index !== -1) {
      // Slice the text after the phrase
      return text.slice(index + phrase.length);
    } else {
      // Return the original text if the phrase is not found
      return text;
    }
  }
  confirm1() {
    // const slicedMessage = this.sliceAfterDelete(this.data?.message).slice(0, -1);
    // console.log(slicedMessage); // Output: " <sdshdg"

    // let rawMessage = this.data?.message || this.message;
    //  var displayMessage = this.data.message

    // const specialCharRegex = /[^a-zA-Z0-9 ]/g;
    // if(this.data.message ){
    //   if (specialCharRegex.test(slicedMessage)) {
    //     displayMessage = this.translate?.general.messages.confirmDelete+"?"
    //   }
    // }else{
    //   displayMessage = this.message
    // }
    

    console.log(this.data)
    this.confirmationService.confirm({
      key: 'confirm1',
      message: this.data.message ? this.data.message : this.message,
      accept: () => {
        this.onSubmit()
        //Actual logic to perform a confirmation
      },
      reject: () => {
        this.reject()
      }
    });

  }
  reject() {
    this.spinner.hide();
    this.deleteRecordService.getItems();
  }

  onSubmit() {
   

    if (this.data.deleteType == 'Customer') {
      this.deleteCustomer()
    } else if (this.data.deleteType === 'Attachment') {
      this.deleteAttachment();

    } else if (this.data.deleteType == 'Payment') {
      this.deletePayment()

    } else if (this.data.deleteType == 'Purchase History') {
      this.deletePurchaseHistory()
    } else if (this.data.deleteType === 'Ticket') {
      // delete ticket
      this.deleteTicket();

    } else if (this.data.deleteType === 'Ticket Attachment') {
      this.deleteAttachment();
    }
    else if (this.data.deleteType === 'Job Order Attachment') {
      this.deleteJobOrderAttachment();
    }
    else if (this.data.deleteType == 'Contact') {
      this.deleteContact()

    } else if (this.data.deleteType == 'Inventory') {
      this.deleteInventory()

    } else if (this.data.deleteType == 'Inventory Bin') {
      this.deleteInventoryBin()
    } else if (this.data.deleteType == 'Resend Activation Email') {
      this.forgotPassword()

   } else if (this.data.deleteType === 'JobOrder' || this.data.deleteType === 'Job Order') {
      this.deleteJobOrder();

    } else if (this.data.deleteType === 'Activate User') {
      this.changeCustomerUserAccountStatus('ACTIVE');

    } else if (this.data.deleteType === 'Suspend User') {
      this.changeCustomerUserAccountStatus('SUSPENDED');

    } else if (this.data.deleteType === 'Deactivate User') {
      this.changeCustomerUserAccountStatus('DEACTIVE');

    } else if (this.data.deleteType === 'Consignment') {
      this.deleteConsignment();

    } else if (this.data.deleteType === 'Location Bin' || this.data.deleteType === 'User Bin') {
      this.deleteInventoryBinByLocationAndUser();

    } else if (this.data.deleteType === 'Consignment Item') {
      this.deleteConsignmentItem();
    } else if (this.data.deleteType === 'Job Order Product' || this.data.deleteType === 'Checklist Product' || this.data.deleteType === 'PMV Product') {
      this.deleteJOProduct();

    } else if (this.data.deleteType === 'Requisition') {
      this.deleteRequisition();

    } else if (this.data.deleteType === 'Requisition Item') {
      this.deleteRequisitionItem();
    }
    else if (this.data.deleteType === 'Setup Inventory Location') {
      this.deleteSetupLocation();
    } else if (this.data.deleteType === 'Setup Inventory Zone') {
      this.deleteSetupZone();
    } else if (this.data.deleteType === 'Setup Inventory Bin') {
      this.deleteSetupBin();
    } else if (this.data.deleteType === 'Asset') {
      this.deleteAsset();
    } else if (this.data.deleteType === 'Part Order') {
      this.deletePartOrder();
    } else if (this.data.deleteType === 'Contract') {
      this.deleteContract();
    } else if (this.data.deleteType === 'Asset Accessories') {
      this.deleteAssetAccesories();
    } else if (this.data.deleteType === 'Contract Item') {
      this.deleteContractItem();
    } else if (this.data.deleteType === 'Preventative Maintenance') {
      this.deleteScheduleMaintenace();
    } else if (this.data.deleteType === 'IQC') {
      this.deleteIQC();
    } else if (this.data.deleteType === 'FQC') {
      this.deleteFQC();

    } else if (this.data.deleteType === 'Geofence') {
      this.deleteGeofence();

    } else if (this.data.deleteType === 'Ticket Status') {
      this.deleteTicketStatus();

    } else if (this.data.deleteType === 'Ticket Type') {
      this.deleteTicketType();

    } else if (this.data.deleteType === 'Ticket Channel') {
      this.deleteTicketChannel();

    } else if (this.data.deleteType === 'Ticket Department') {
      this.deleteTicketDepartment();

    } else if (this.data.deleteType === 'Ticket Priority') {
      this.deleteTicketPriority();
    } else if (this.data.deleteType === 'Maintenance') {
      this.deleteMaintenance();

    } else if (this.data.deleteType === 'Ticket Resolution Type') {
      this.deleteTicketResolutionType();

    } else if (this.data.deleteType === 'Ticket Fault Type') {
      this.deleteTicketFaultType();
    } else if (this.data.deleteType === 'Task') {
      this.deleteTask();
    } else if (this.data.deleteType === 'Task Hazard') {
      this.hazardDeleteTask();
    } else if (this.data.deleteType === 'Sales Order') {
      this.deleteSalesOrder();
    } else if (this.data.deleteType === 'Sales Order Product') {
      this.deleteSOProduct();
    } else if (this.data.deleteType === 'SLA Business Hours') {
      this.deleteBusinessHour();
    } else if (this.data.deleteType === 'Checklist Task') {
      this.deleteTaskType();
    } else if (this.data.deleteType === 'SLA Package') {
      this.deletePackage();

    } else if (this.data.deleteType === 'SLA Package Step') {
      this.deletePackageStep();

    } else if (this.data.deleteType === 'SLA Step') {
      this.deleteSlaStep();

    } else if (this.data.deleteType === 'SLA Customer') {
      this.deleteSLACustomer();

    } else if (this.data.deleteType === 'SLA Step Notification') {
      this.deleteSlaStepNotification();

    } else if (this.data.deleteType === 'Product') {
      this.deleteProduct();

    } else if (this.data.deleteType === 'Fault Call' || this.data.deleteType === 'Fault Unit' || this.data.deleteType == 'Fault Module'
      || this.data.deleteType == 'Repair Services' || this.data.deleteType == 'Replace Module' || this.data.deleteType == 'Relocation' || this.data.deleteType == 'Installion Form' || this.data.deleteType == 'On Loan' || this.data.deleteType == 'On Loan Form' || this.data.deleteType == 'Preventive Maintenance'
      || this.data.deleteType == 'Miscellaneous' || this.data.deleteType == 'Retrieval' || this.data.deleteType == 'Retrieval Form' || this.data.deleteType == 'Service Order'
      || this.data.deleteType == 'PSS Resource' || this.data.deleteType == 'PCS Form' || this.data.deleteType == 'Pesticides' || this.data.deleteType == 'Van Log'
      || this.data.deleteType == 'Coffex Form'  || this.data.deleteType =='Installation Form' ||  this.data.deleteType =='On Loan Equipment' || this.data.deleteType =='Retrieval Equipment' || this.data.deleteType =='Installation Equipment'
      || this.data.deleteType =='On Site Repair Equipment' || this.data.deleteType =='One To One Exchange Equipment' || this.data.deleteType =='Return To Customer Equipment'
      || this.data.deleteType =='IPCP HACCP FSC') {

      this.deleteDynamicData();

    } else if (this.data.deleteType === 'Log Reading') {
      this.data.deleteType = "Meter reading"
      this.deleteHourMeter();
    }  else if (this.data.deleteType === 'Department') {
      this.deleteDepartment();

    } else if (this.data.deleteType === 'Checklist Maintenance') {
      this.deleteTtlMaintenance();

    } else if (this.data.deleteType === 'Maintenance Task') {
      this.deleteMaintenanceTask();

    } else if (this.data.deleteType === 'Equipment Type') {
      this.deleteEquipmentType();

    } else if (this.data.deleteType === 'Equipment IQC') {
      this.deleteIqcFqct();

    } else if (this.data.deleteType === 'Equipment FQC') {
      this.deleteIqcFqct();

    } else if (this.data.deleteType === 'Delete IQC Question') {
      this.deleteIqcFqcQt();

    } else if (this.data.deleteType === 'Delete FQC Question') {
      this.deleteIqcFqcQt();

    }  else if (this.data.deleteType === 'Import Export Log') {
      this.deleteLogItem();

    } else if (this.data.deleteType === 'Asset Status') {
      this.deleteAssetStatus();
    } else if (this.data.deleteType === 'Asset Type') {
      this.deleteAssetType();
    }
    else if (this.data.deleteType === 'Customer Category') {
      this.deleteCategoryType();
    } else if (this.data.deleteType === 'Customer Type') {
      this.deleteCustomerType();
    }
    else if (this.data.deleteType === 'Job Order Status') {
      this.deleteJOStatus();
    } else if (this.data.deleteType === 'Job Order Type') {
      this.deleteJobOrderTypes();
    } else if (this.data.deleteType === 'Job Order System') {
      this.deleteJobOrderSystem();
    } else if (this.data.deleteType === 'Job Order Fault') {
      this.deleteJobOrderFault();
    } else if (this.data.deleteType === 'Job Order Resolution') {
      this.deleteJobOrderResolution();
    }
    else if (this.data.deleteType === 'Sales Order Status') {
      this.deleteSOrderStatuses();
    } else if (this.data.deleteType === 'Sales Order Channel') {
      this.deleteSOChannel();
    } else if (this.data.deleteType === 'Sales Order Type') {
      this.deleteSOType();
    }
    else if (this.data.deleteType === 'Package Type') {
      this.deletePackageType();
    } else if (this.data.deleteType === 'Package') {
      this.deletePackageSubType();
    } else if (this.data.deleteType === 'Role') {
      this.deleteRole();
    } else if (this.data.deleteType === 'Access') {
      this.deleteAccess();
    }else if (this.data.deleteType === 'Profile picture') {
      this.deleteProfilePickture();

    } else if (this.data.deleteType === 'Suspend User Profile') {
      this.changeUserAccountStatus('SUSPENDED');

    } else if (this.data.deleteType === 'Deactivate User Profile') {
      this.changeUserAccountStatus('DEACTIVE');

    } else if (this.data.deleteType === 'Activate User') {
      this.changeUserAccountStatus('ACTIVE Profile');

    } else if (this.data.deleteType === 'Activate User Profile') {
      this.changeUserAccountStatus('ACTIVE');

    }  else if (this.data.deleteType === 'Team') {
      this.deleteTeam();
    } else if (this.data.deleteType === 'Team Member') {
      this.deleteTeamMember();
    } else if (this.data.deleteType === 'Task Journey') {
      this.deleteTaskJourney();
    } else if (this.data.deleteType === 'Team Profile Picture') {
      this.deleteTeamPicAttachment();
    } else if (this.data.deleteType === 'User Group') {
      this.deleteUserGroups();
    } else if (this.data.deleteType === 'Group User') {
      this.deleteUserGroupUser();
    } else if (this.data.deleteType === 'Task Note') {
      this.deleteTaskNote();
    } else if (this.data.deleteType === 'Skills Department') {
      this.deleteSkillsDepartment();
    } else if (this.data.deleteType === 'Leave') {
      this.deleteLeave();
    } else if (this.data.deleteType === 'Shift') {
      this.deleteShift();
    } else if (this.data.deleteType === 'Device') {
      this.deleteDevice();

    } else if (this.data.deleteType === 'Setup Preventative Maintenance') {
      this.deletePMSetup();

    } else if (this.data.deleteType === 'Preventative Maintenance Frequency') {
      this.deletePFrequencySetup();

  }else if (this.data.deleteType === 'Preventative Maintenance Part') {
    this.deleteSparePartSetup();
  } else if (this.data.deleteType === 'JMP') {
    this.deleteJMP();
  } else if (this.data.deleteType === 'Project') {
    this.deleteProject();
  } else if (this.data.deleteType === 'Site Type') {
    this.deleteSiteType();
  } else if (this.data.deleteType === 'Site Area') {
    this.deleteSiteArea();
  } else if (this.data.deleteType === 'Site Category') {
    this.deleteSiteCategory();
  } else if (this.data.deleteType === 'Site Sub Category') {
    this.deleteSiteSubCategory();
  } else if (this.data.deleteType === 'Project Category') {
    this.deleteProjectCategory();
  } else if (this.data.deleteType === 'Site') {
    this.deleteSite();
  } else if (this.data.deleteType === 'Site Attachment' || this.data.deleteType === 'Project Attachment') {
    this.deleteAttachment();
  } else if (this.data.deleteType === 'Project Type') {
    this.deleteProjectType();
  } else if (this.data.deleteType === 'Service Zone') {
    this.deleteServiceZone();
  }else if (this.data.deleteType === 'Contract Assets') {
    this.deleteContractAssets();
  }
  else if (this.data.deleteType === 'Customer Sector') {
    this.deleteCustomerSector();
  }
  else if (this.data.deleteType === 'Customer Sub Sector') {
    this.deleteCustomerSubSector();
  }
  else if (this.data.deleteType === 'Customer Industry') {
    this.deleteCustomerIndustry();
  }
  else if (this.data.deleteType === 'Customer Survey') {
    this.deleteCustomerSurvey();
  }
  else if (this.data.deleteType === 'Product Type') {
    this.deleteProductType();
  }
  else if (this.data.deleteType === 'Product Family') {
    this.deleteProductFamily();
  }
  else if (this.data.deleteType === 'Product Unit') {
    this.deleteProductUnit();
  }
  else if (this.data.deleteType === 'Task Template') {
    this.deleteTaskTemplate();
  } else if (this.data.deleteType === 'Job Order Template') {
    this.deleteJOTemplate();
  } else if (this.data.deleteType === 'Job Order Task Template') {
    this.deleteJOTaskTemplate();
  } else if (this.data.deleteType === 'Job Order Product Template') {
    this.deleteJOProductTemplate();
  } else if (this.data.deleteType === 'Visits') {
    this.deleteVisits();
  } else if (this.data.deleteType === 'Checklist Setup') {
    this.deleteChecklistSetup();
  } else if (this.data.deleteType === 'Checklist Question') {
    this.deleteCheckListQuestion();
  } else if (this.data.deleteType === 'Attendance') {
    this.deleteAttendance();
  } else if (this.data.deleteType === 'Machines Specialist') {
    this.deleteMachinesSpecialist();
  } else if (this.data.deleteType === 'Visit Attachment' || this.data.deleteType === 'Leave Attachment' || this.data.deleteType === 'Overtime Attachment' || this.data.deleteType === 'Productivity Attachment') {
    this.deleteAttachment();
  } else if (this.data.deleteType === 'Overtime') {
    this.deleteOvertime();
  } else if (this.data.deleteType === 'Productivity') {
    this.deleteProductivity();
  }
  else if (this.data.deleteType === 'Secondary Productivity') {
    this.deleteProductivitySecondary();
  }
  else if (this.data.deleteType === 'Productivity Primary') {
    this.deleteProductivityPrimary();
  }
  else if (this.data.deleteType === 'Checklist') {
    this.deleteCheckListGlobal();
  }else if (this.data.deleteType === 'PMV') {
    this.deletePMV();
  } else if (this.data.deleteType === 'Customer List View' || this.data.deleteType === 'Job Order List View' || this.data.deleteType === 'Site List View'
  || this.data.deleteType === 'Asset List View' || this.data.deleteType === 'Project List View' || this.data.deleteType === 'Product List View' 
  || this.data.deleteType === 'Preventive Maintenance List View' || this.data.deleteType === 'Contract List View' || this.data.deleteType === 'Ticket List View'
  || this.data.deleteType === 'Sales Order List View' || this.data.deleteType ==='Task List View' ) {
   
    this.deleteDynamicTableTemplate();
  }else if (this.data.deleteType === 'Workflow') {
    this.deleteWorkflow();

  }else if (this.data.deleteType === 'Notification') {
    this.deleteWorkFlowNotification();
 
  }else if (this.data.deleteType === 'Email Template') {
    this.deleteEmailTemplate();
  }else if (this.data.deleteType === 'Workflow Action') {
    this.deleteWorkflowAction();

  }else if (this.data.deleteType === 'Custom Form') {
    this.deleteCustomForm();
  }else if (this.data.deleteType === 'Custom Form Field') {
    this.deleteDynamicFormField();
  }else if (this.data.deleteType == 'Custom Sub Form') {
    this.deleteCustomSubForm();
  }else if (this.data.deleteType === 'Confirm Task remove from JO via template') {
    this.confirmProductRemoveFromJo();
  }else if(this.data.deleteType === "Confirm Product remove from JO via template"){
    this.confirmProductRemoveFromJo();
  }else if (this.data.deleteType === 'Price Book') {
    this.deletePriceBook();
  }else if (this.data.deleteType === 'Product Pricing') {
    this.deleteProductPricing();
  }else if (this.data.deleteType === 'Rate Card') {
    this.deleteRateCard();
  }
  else if (this.data.deleteType === 'Task Template Product') {
    this.deleteTaskProductTemplate();
  } else if (this.data.deleteType === 'AI Attachment') {
    this.deleteAIAttachment();

  }
  
}

  /************************************* Delete Payment ******************************/
  deletePayment() {
    
    this.customerService.deletePaymentHistory(this.data.id).subscribe((res: any) => {
      
      this.setMessage(res)
    });
  }

  /************************************* Delete Csutomer ******************************/
  deleteCustomer() {
    this.customerService.deleteCustomer(this.data.id).subscribe((res: any) => {
      console.log(res)
      this.setMessage(res)
    }, err => {
      
      this.setMessage({ status: err?.status, ...err });
    });
  }

  /************************************* Delete Purchase History ******************************/
  deletePurchaseHistory() {
    

    this.customerService.deletePurchaseHistory(this.data.id).subscribe((res: any) => {
      
      this.setMessage(res)
    }, error => {
      
    });
  }

  /************************************* Delete Cusomer Contact******************************/
  deleteContact() {
    this.customerService.deleteContact(this.data.id).subscribe((res: any) => {
      //console.log("Response ", res);
      console.log(res)
      this.afterDelete(res);
      this.setMessage(res)
    });
  }

  /************************************* Delete Inventory******************************/
  deleteInventory() {
    this.inventoryService.deleteInventory(this.data.id).subscribe((res: any) => {
      //console.log("Response ", res);
      console.log(res)
      this.afterDelete(res);
      this.setMessage(res)
    });
  }

  /************************************* Delete Inventory Bin ******************************/
  deleteInventoryBin() {
    this.inventoryService.deleteInventoryBin(this.data.id).subscribe((res: any) => {
      //console.log("Response ", res);
      console.log(res)
      this.setMessage(res)
    });
  }

  /************************************* Delete Ticket******************************/
  deleteTicket() {
    

    this.ticketsService.deleteTicket(this.data.id)
      .subscribe((res: any) => {
        
        this.afterDelete(this.data);
        this.setMessage(res);
      }, error => {
        
      });
  }

  /************************************* Delete ALl Attchment in custella ******************************/
  deleteAttachment() {
    this.attachmentService.deleteAttachment(this.data.id)
      .subscribe((res: any) => {
        console.log(this.data);
        this.afterDelete(res);
        this.setMessage(res);
      });
  }

  /************************************* Delete ALl Attchment in custella ******************************/
  forgotPassword() {
    ////debugger
    if (this.data.type == "reset" && this.data.type != "resendCustomerUserEmail") {
      var formData: any = {
        login: this.data
      }
      this.signUpService.forgotPassword(formData).subscribe((res: any) => {
        if (res.status === 200 || res.status === 201) {
          this.errorUtil.setErrorMessage(200, this.translate?.setup.users.users.messages.passwordResetEmailSent, null, 'success', 1000);
        } else {

          var body = JSON.parse(res._body);
          this.errorUtil.setErrorMessage(res.status, null, res.error.title, 'error', 3000);
        }
        this.reject()
      });
    }
    else if (this.data.type == "resendCustomerUserEmail") {
      var formData: any = this.data.id
      this.signUpService.resendCustomerUserEmail(formData).subscribe((res: any) => {
        if (res.status === 200 || res.status === 201) {

          this.errorUtil.setErrorMessage(200, this.translate?.setup.users.users.messages.accountActivationEmailSent, null, 'success', 1000);
        } else {

          var body = JSON.parse(res._body);
          this.errorUtil.setErrorMessage(res.status, null, res.error.title, 'error', 3000);
        }
        this.reject()
      });
    }
    else {
      var formData: any = this.data.id
      this.signUpService.resendEmail(formData).subscribe((res: any) => {
        if (res.status === 200 || res.status === 201) {
          this.errorUtil.setErrorMessage(200, this.translate?.setup.users.users.messages.accountActivationEmailSent, null, 'success', 1000);

        } else {

          var body = JSON.parse(res._body);
          this.errorUtil.setErrorMessage(res.status, null, res.error.title, 'error', 3000);
        }
        this.reject()
      });
    }
  }


  /************************************* Delete Job Order ******************************/
  deleteJobOrder() {
    this.jobOrderService.deleteJobOrder(this.data.id)
      .subscribe((res: any) => {
        this.afterDelete(res);
        this.setMessage(res);
      }, (error: any) => this.setMessage(error));
  }

  /************************************* Delete Job Order Attachment ******************************/
  deleteJobOrderAttachment() {
    this.jobOrderService.deleteAttachment(this.data.id).subscribe((res: any) => {
      console.log("=-=-",this.data, res)
      this.afterDelete(res);
      this.setMessage(res);
    }, (error: any) => this.setMessage(error));
  }

  /************************************* Delete Loading Bya ******************************/

  changeCustomerUserAccountStatus(status: any) {
    this.userService.changeCustomerUserAccountStatus(status, this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      console.log(res)
      if (res.status === 200 || res.status === 201) {
        this.errorUtil.setErrorMessage(200, res.body.message, null, 'success', 1000);
        this.reject()
      } else {

        this.errorUtil.setErrorMessage(400, null, res.error.title, 'error', 3000);
        this.reject()
      }
    });
  }
  /************************************* Delete ConSignment ******************************/

  deleteConsignment() {
    this.assetService.deleteConsignment(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }
  /************************************* Delete ConSignment Itme ******************************/

  deleteConsignmentItem() {
    this.assetService.deleteConsignmentItem(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }
  /************************************* Delete Loading Bya ******************************/

  deleteInventoryBinByLocationAndUser() {
    this.inventoryService.deleteInventoryBin(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }

  /************************************* Delete Jo Product ******************************/
  deleteJOProduct() {
    this.productService.deleteJobOrderProduct(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    });
  }

  /************************************* Delete Requisition ******************************/

  deleteRequisition() {
    this.assetService.deleteRequisition(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }
  /************************************* Delete Requisition Item ******************************/

  deleteRequisitionItem() {
    this.assetService.deleteRequisitionItem(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }

  /************************************* Delete Task ******************************/
  deleteTask() {
    this.taskService.deleteTaskById(this.data, false).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }
  /************************************* Delete Task ******************************/
  hazardDeleteTask() {
    this.taskService.hazardDeleteTask(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }
  /************************************* Delete Setup-> Inventory -> location ******************************/
  deleteSetupLocation() {
    this.inventoryService.deleteLocation(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }
  /************************************* Delete Visits ******************************/
  deleteVisits() {
    this.visitService.deleteVisit(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }
  /************************************* Delete Setup-> Inventory -> Zone ******************************/
  deleteSetupZone() {
    this.inventoryService.deleteZone(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }
  /************************************* Delete Setup-> Inventory -> Bin ******************************/
  deleteSetupBin() {
    this.inventoryService.deleteBin(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }

  /************************************* Delete Asset ******************************/
  deleteAsset() {
    this.assetService.deleteAsset(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    });
  }

  /************************************* Delete Part Order ******************************/
  deletePartOrder() {
    this.partOrderService.deletePartOrder(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }

  /************************************* Delete Contracts ******************************/
  deleteContract() {
    

    this.assetService.deleteContract(this.data.id).subscribe((res: any) => {
      

      this.afterDelete(res);
      this.setMessage(res);
    }, err => {
      
      this.afterDelete(err);
      this.reject();
    });
  }

  /************************************* Delete asset accessories ******************************/
  deleteAssetAccesories() {
    this.assetService.deleteAccessories(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    }, err => {
      this.afterDelete(err);
      this.reject();
    });
  }

  /************************************* Delete schedule maintenance ******************************/
  deleteScheduleMaintenace() {
    this.assetService.deleteScheduledMaintenance(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    });
  }

  /************************************* Delete asset accessories ******************************/
  deleteContractItem() {
    this.assetService.deleteContractItem(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);

      this.setMessage({
        status: 200,
      });
    }, err => {
      this.afterDelete(err);
      this.reject();
    });
  }

  /************************************* Delete IQC ******************************/
  deleteIQC() {
    this.iqcFqcService.deleteIqc(this.data.id).subscribe((res: any) => {
      console.log(res)
      this.setMessage(res)
    });
  }
  /************************************* Delete FQC ******************************/
  deleteFQC() {
    this.iqcFqcService.deleteFqc(this.data._id).subscribe((res: any) => {
      console.log(res)
      this.setMessage(res)
    });
  }

  /********************************************* Delete Geofene **********************************************/
  deleteGeofence() {
    this.customerService.deleteGeofence(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }

  /********************************************* Delete Ticket Status **********************************************/
  deleteTicketStatus() {
    this.ticketsService.deleteTicketStatus(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },err => {
      this.setMessage(err);
    });
  }
  /********************************************* Delete Ticket Status **********************************************/
  deleteTicketType() {
    this.ticketsService.deleteTicketType(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },err => {
      this.setMessage(err);
    });
  }
  /********************************************* Delete Ticket Status **********************************************/
  deleteTicketChannel() {
    this.ticketsService.deleteChannel(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },err => {
      this.setMessage(err);
    });
  }
  /********************************************* Delete Ticket Department **********************************************/
  deleteTicketDepartment() {
    this.ticketsService.deleteDepartment(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },err => {
      this.setMessage(err);
    });
  }
  /********************************************* Delete Ticket Department **********************************************/
  deleteTicketPriority() {
    this.ticketsService.deletePriority(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },err => {
      this.setMessage(err);
    });
  }
  /********************************************* Delete Ticket Resolution **********************************************/
  deleteTicketResolutionType() {
    this.ticketsService.deleteResolutionType(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },err => {
      this.setMessage(err);
    });
  }

  /********************************************* Delete SLA Business Hours **********************************************/
  deleteBusinessHour() {
    

    this.ticketsService.deleteBusinessHour(this.data.id).subscribe((res: any) => {
      
      this.afterDelete(this.data);
      this.setMessage(res);
    }, err => {
      
      this.setMessage({ status: err?.status, ...err });
    });
  }

  /************************************* Delete SLA Step Customer ******************************/
  deleteSLACustomer() {
    this.ticketsService.deleteCustomer(this.data.id).subscribe((res: any) => {
      console.log(res)
      this.setMessage(res)
    });
  }

  /********************************************* Delete Ticket Fault **********************************************/
  deleteTicketFaultType() {
    this.ticketsService.deleteFaultType(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },err => {
      this.setMessage(err);
    });
  }

  /********************************************* Delete Preventative Maintenance **********************************************/
  deleteScheduledMaintenance() {
    this.assetService.deleteScheduledMaintenance(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    });
  }

  /********************************************* Delete Preventative Maintenance **********************************************/
  deleteMaintenance() {
    this.assetService.deleteMaintenance(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    });
  }

  /********************************************* Delete Sales Order **********************************************/
  deleteSalesOrder() {
    

    this.salesOrderService.deleteSalesOrder(this.data.id).subscribe((res: any) => {
      

      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      

      this.afterDelete(err);
      this.reject();
    });
  }

  /************************************* Delete Sales Order Product ******************************/
  deleteSOProduct() {
    this.productService.deleteSalesOrderProduct(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    });
  }
  /********************************************* Delete Task Type **********************************************/
  deleteTaskType() {
    this.iqcFqcService.deleteTaskType(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }
  /************************************* Delete SLA Package ******************************/
  deletePackage() {
    
    console.log(this.data)
    this.ticketsService.deleteSlaPackage(this.data.id).subscribe((res: any) => {
      console.log(res);
      
      this.afterDelete(this.data);
      this.setMessage(res);
    }, err => {
      console.log(err);
      this.setMessage({ status: err?.status, ...err });

      
    });
  }
  /************************************* Delete SLA Package Step ******************************/
  deletePackageStep() {
    
    console.log(this.data)
    this.ticketsService.deleteLineItemSlaPackage(this.data.slaPackageId, this.data.id).subscribe((res: any) => {
      
      this.afterDelete(this.data);
      this.errorUtil.setErrorMessage(200, this.data.deleteType + ' ' + this.translate?.general.messages.removedSuccessfully, null, 'success', 1000);
      this.reject()
    }, err => {
      
      this.setMessage({ status: err?.status, ...err });
    });
  }
  /************************************* Delete SLA Package Step ******************************/
  deleteSlaStep() {
    
    console.log(this.data)
    this.ticketsService.deleteSlaStep(this.data.id).subscribe((res: any) => {
      

      this.afterDelete(this.data);
      this.setMessage(res);
    }, err => {
      
      this.setMessage({ status: err?.status, ...err });
    },);
  }
  /************************************* Delete SLA  Step Notification ******************************/
  deleteSlaStepNotification() {
    console.log(this.data)
    this.ticketsService.deleteNotification(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }
  /************************************* Delete Product ******************************/
  deleteProduct() {
    

    this.productService.deleteProduct(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);

      
    }, error => {
      this.afterDelete(this.data);
      this.setMessage(error);

      
    });
  }

  deleteDynamicData() {

    if (this.data.apiServerType == "magnum") {
      this.data.id = this.data._id

    } else if (this.data.apiServerType == "java") {
      this.data.id = this.data.id
    } else {
      this.data.id = this.data._id
    }
    console.log(this.data)
    this.dynamicFormService.deleteDynamicData(this.data.id, this.data.deleteType, this.data.formAPIName, this.data.apiServerType).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }
  /************************************* Delete SLA  Step Notification ******************************/
  deleteHourMeter() {
    console.log(this.data)
    this.assetService.deleteAssetLog(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }

 

  /********************************************* Delete ttl Department **********************************************/
  deleteDepartment() {
    this.iqcFqcService.deleteDepartment(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }

  /********************************************* Delete ttl Maintenance **********************************************/
  deleteTtlMaintenance() {
    this.iqcFqcService.deleteMaintenance(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }

  /********************************************* Delete ttl Maintenance Task**********************************************/
  deleteMaintenanceTask() {
    this.iqcFqcService.deleteMaintenanceTask(this.data.id, this.data.maintenanceId).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }

  /********************************************* Delete ttl Equipment Type **********************************************/
  deleteEquipmentType() {
    this.iqcFqcService.deleteEquipment(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }
  /********************************************* Delete ttl Equipment IQC **********************************************/
  deleteIqcFqct() {
    this.iqcFqcService.deleteIqcFqct(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }
  /********************************************* Delete ttl Equipment IQC Qt**********************************************/
  deleteIqcFqcQt() {
    this.iqcFqcService.deleteIqcFqcQuestion(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }

  /********************************************* Delete Log Item**********************************************/
  deleteLogItem() {
    this.importExportService.deleteLogItem(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    });
  }
 

  // after delete to which every component that called this to delete
  afterDelete(data: any) {
    this.deleteRecordService.triggerAfterDelete(data);
  }

  /********************************************* Delete Do Type**********************************************/
  deleteAssetStatus() {
    

    this.assetService.deleteAssetStatus(this.data.id).subscribe((res: any) => {
      

      this.afterDelete(this.data);
      this.setMessage(res);
    }, err => {
      this.setMessage(err);      

    });
  }
  /********************************************* Delete Asset Type**********************************************/
  deleteAssetType() {
    this.assetService.deleteAssetType(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },err => {
      this.setMessage(err);
    }) ;
  }
  /********************************************* Delete Customer Category **********************************************/
  deleteCategoryType() {
    this.customerService.deleteCategoryType(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },err => {
      this.setMessage(err);
    }) ;
  }
  /********************************************* Delete Customer Category **********************************************/
  deleteCustomerType() {
    this.customerService.deleteCustomerType(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },err => {
      this.setMessage(err);
    }) ;
  }
  /********************************************* Delete Jo Status **********************************************/
  deleteJOStatus() {
    this.jobOrderService.deleteJOStatus(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },err => {
      this.setMessage(err);
    });
  }
  /********************************************* Delete Jo Status **********************************************/
  deleteJobOrderTypes() {
    this.jobOrderService.deleteJobOrderTypes(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },(error: any) =>{
      this.setMessage(error);
    });
  }
  /********************************************* Delete Jo Syatem **********************************************/
  deleteJobOrderSystem() {
    this.jobOrderService.deleteJobOrderSystem(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },err => {
      this.setMessage(err);
    });
  }
  /********************************************* Delete Jo Fault **********************************************/
  deleteJobOrderFault() {
    this.jobOrderService.deleteJobOrderFault(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },err => {
      this.setMessage(err);
    });
  }
  /********************************************* Delete Jo Resolution **********************************************/
  deleteJobOrderResolution() {
    this.jobOrderService.deleteJobOrderResolution(this.data.id).subscribe((res: any) => {
      console.log(res)
      this.afterDelete(this.data);
      this.setMessage(res);
    },err => {
      this.setMessage(err);
    });
  }
  /********************************************* Delete Jo Resolution **********************************************/
  deleteSOrderStatuses() {
    
    this.salesOrderService.deleteSOrderStatuses(this.data.id).subscribe((res: any) => {
      

      this.afterDelete(this.data);
      this.setMessage(res);
    }, err => {
      
      this.setMessage({ status: err?.status, ...err });
    });
  }
  /********************************************* Delete So Channel **********************************************/
  deleteSOChannel() {
    
    this.salesOrderService.deleteSOChannel(this.data.id).subscribe((res: any) => {
      

      this.afterDelete(this.data);
      this.setMessage(res);
    }, err => {
      
      this.setMessage({ status: err?.status, ...err });
    });
  }
  /********************************************* Delete So Type **********************************************/
  deleteSOType() {
    
    this.salesOrderService.deleteSOType(this.data.id).subscribe((res: any) => {
      

      this.afterDelete(this.data);
      this.setMessage(res);
    }, err => {
      
      this.setMessage({ status: err?.status, ...err });
    });
  }
  


  /********************************************* Delete Package Type **********************************************/
  deletePackageType() {
    this.packageServices.deleteType(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      this.afterDelete(err);
      this.reject();
    });
  }

  /********************************************* Delete Package Sub Type **********************************************/
  deletePackageSubType() {
    this.packageServices.deleteSubType(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      this.afterDelete(err);
      this.reject();
    });
  }

  /********************************************* Delete Role **********************************************/
  deleteRole() {
    this.roleServices.deleteRole(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      this.afterDelete(err);
      this.reject();
    });
  }
  /********************************************* Delete Role **********************************************/
  deleteProfilePickture() {
    this.profileServices.deleteProfilePicture(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      this.afterDelete(err);
      this.reject();
    });
  }
  /************************************* Delete Loading Bya ******************************/

  changeUserAccountStatus(status: any) {
    this.userService.changeAccountStatus(status, this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      console.log(res)
      if (res.status === 200 || res.status === 201) {
        this.errorUtil.setErrorMessage(200, res.body.message, null, 'success', 1000);
        this.reject()
      } else {

        this.errorUtil.setErrorMessage(400, null, res.error.title, 'error', 3000);
        this.reject()
      }
    });
  }

  /********************************************* Delete Access **********************************************/
  deleteAccess() {
    this.profileServices.deleteProfile(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      this.afterDelete(err);
      this.setMessage(err);
      this.reject();
    });
  }


  /********************************************* Delete Team **********************************************/
  deleteTeam() {
    this.teamService.deleteTeam(this.data.id).subscribe((res: any) => {
      console.log("res team delete",res)
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      console.log("res team delete",err)
      this.setMessage(err);
      this.afterDelete(err);
      this.reject();
    });
  }
  /********************************************* Delete Team Member**********************************************/
  deleteTeamMember() {
    this.teamService.deleteTeamMember(this.data).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      this.setMessage(err);
      this.afterDelete(err);
      this.reject();
    });
  }
  /********************************************* Delete Team Member**********************************************/
  deleteTaskJourney() {
    this.taskService.deleteJourney(this.data.userLocationRouteTable.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      this.setMessage(err);
      this.afterDelete(err);
      this.reject();
    });
  }
  /************************************* Delete ALl Attchment in custella ******************************/
  deleteTeamPicAttachment() {
    this.teamService.deleteTeamProfilePic(this.data.id)
      .subscribe((res: any) => {
        console.log(this.data);
        this.afterDelete(this.data);
        this.setMessage(res);
      });
  }

  /********************************************* Delete User Groups **********************************************/
  deleteUserGroups() {
    this.devicesSevices.deleteGroup(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      
      this.afterDelete(err);
      this.setMessage(err);
      this.reject();
    });
  }

  /********************************************* Delete User Group User **********************************************/
  deleteUserGroupUser() {
    this.devicesSevices.editGroup(this.data.data).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      this.afterDelete(err);
      this.reject();
    });
  }

  /********************************************* Delete Task Note **********************************************/
  deleteTaskNote() {
    this.taskService.deleteTaskNotes(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      this.afterDelete(err);
      this.reject();
    });
  }

  /********************************************* Delete Skills **********************************************/
  deleteSkillsDepartment() {
    this.skillsService.deleteDepartment(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      this.afterDelete(err);
      this.reject();
    });
  }

  /********************************************* Delete (Cancel) Leave **********************************************/
  deleteLeave() {
    this.leaveService.cancelLeave(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      this.afterDelete(err);
      this.setMessage(err);
      this.reject();
    });
  }

  /********************************************* Delete Shift **********************************************/
  deleteShift() {
    this.shiftService.deleteShift(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      this.afterDelete(err);
      this.reject();
    });
  }
  /********************************************* Delete PM **********************************************/
  deletePMSetup() {
    this.iqcFqcService.deletePMSetup(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      this.afterDelete(err);
      this.reject();
    });
  }

  /********************************************* Delete PM Frequency **********************************************/
  deletePFrequencySetup() {
    this.iqcFqcService.deletePFrequencySetup(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      this.afterDelete(err);
      this.reject();
    });
  }

  /********************************************* Delete PM Spare Part **********************************************/
  deleteSparePartSetup() {
    this.iqcFqcService.deleteSparePartSetup(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      this.afterDelete(err);
      this.reject();
    });
  }

  /********************************************* Delete Device **********************************************/
  deleteDevice() {
    this.devicesSevices.deleteDrive(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      this.afterDelete(err);
      this.reject();
    });
  }

  /********************************************* Delete JMP **********************************************/
  deleteJMP() {
    this.chubbService.deleteJMP(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      this.afterDelete(err);
      this.reject();
    });
  }

  /********************************************* Delete Project **********************************************/
  deleteProject() {
    this.projectService.deleteProject(this.data.id).subscribe((res: any) => {
      

      this.afterDelete({ status: 200, ...res });
      this.setMessage({ title: res.message, status: 200 });
    }, (err: any) => {
      

      console.log(err);
      this.setMessage(err);
      this.afterDelete(err);
    });
  }

  /********************************************* Delete Site Type **********************************************/
  deleteSiteType() {
    

    this.siteService.deleteSiteType(this.data.id).subscribe((res: any) => {
      

      this.afterDelete({ status: 200, body: res });
      this.setMessage({ status: 200 });
    }, (err: any) => {
      

      this.afterDelete(err);
      this.setMessage({ status: err?.status, ...err });
    });
  }

  /********************************************* Delete Site Area **********************************************/
  deleteSiteArea() {
    
    this.siteService.deleteSiteArea(this.data.id).subscribe((res: any) => {
      

      this.afterDelete({ status: 200, body: res });
      this.setMessage({ status: 200 });
    }, (err: any) => {
      

      this.afterDelete(err);
      this.reject();
    });
  }

  /********************************************* Delete Site Category **********************************************/
  deleteSiteCategory() {
    
    this.siteService.deleteSiteCategory(this.data.id).subscribe((res: any) => {
      

      this.afterDelete({ status: 200, body: res });
      this.setMessage({ status: 200 });
    }, (err: any) => {
      

      this.afterDelete(err);
      this.reject();
    });
  }

  /********************************************* Delete Site Sub Category **********************************************/
  deleteSiteSubCategory() {
    

    this.siteService.deleteSiteSubCategory(this.data.id).subscribe((res: any) => {
      

      this.afterDelete({ status: 200, body: res });
      this.setMessage({ status: 200 });
    }, (err: any) => {
      

      this.afterDelete(err);
      this.setMessage({ status: err?.status, ...err });
    });
  }

  /********************************************* Delete Project Category **********************************************/
  deleteProjectCategory() {
    
    this.projectService.deleteProjectCategory(this.data.id).subscribe((res: any) => {
      

      this.afterDelete({ status: 200, body: res });
      this.setMessage({ status: 200 });
    }, (err: any) => {
      

      this.afterDelete(err);
      this.reject();
    });
  }

  /********************************************* Delete Site **********************************************/
  deleteSite() {
    

    this.siteService.deleteSite(this.data.id).subscribe((res: any) => {
      

      this.afterDelete({ status: 200, ...res });
      this.setMessage({ title: res.message, status: 200 });
    }, (err: any) => {
     
      // let errobj: any = null;

      // if (err.hasOwnProperty('error')) {
      //   errobj = {
      //     error: {
      //       title: err.error.message ?? err.error.title,
      //     },
      //     status: err.status
      //   };
      // }

      console.log(err);

      this.setMessage(err);
      this.afterDelete(err);
      this.reject();
    });
  }

  /********************************************* Delete Project Type **********************************************/
  deleteProjectType() {
    

    this.projectService.deleteProjectType(this.data.id).subscribe((res: any) => {
      

      this.afterDelete({ status: 200, body: res });
      this.setMessage({ status: 200 });
    }, (err: any) => {
      

      this.afterDelete(err);
      this.setMessage(err);
    });
  }
  /********************************************* Delete Task Template **********************************************/
  deleteTaskTemplate() {
    this.taskService.deleteTaskTemplate(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      this.afterDelete(err);
      this.setMessage(err);
    });
  }
  /********************************************* Delete Task Template **********************************************/
  deleteJOTemplate() {
    this.jobOrderService.deleteJobOrderTemplate(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      this.afterDelete(err);
      this.setMessage(err);
    });
  }
  /********************************************* Delete JO Task Template **********************************************/
  deleteJOTaskTemplate() {
    this.taskService.deleteJobOrderTaskTemplate(this.data.tmplJobOrderId, this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      this.afterDelete(err);
      this.setMessage(err);
    });
  }

  /********************************************* Delete JO Jo Template=> Product **********************************************/
  deleteJOProductTemplate() {
    this.jobOrderService.deleteJobOrderTemplateProduct(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      this.afterDelete(err);
      this.setMessage(err);
    });
  }
  /************************************* Delete Checklist Setup ******************************/
  deleteChecklistSetup() {
   this.checklistService.deleteCheckList(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },(err) => {
       this.afterDelete(this.data);
      this.setMessage(err);
    })
  }
  /************************************* Delete Checklist Setup=> Question ******************************/
  deleteCheckListQuestion() {
    this.checklistService.deleteCheckListQuestion(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },(err) => {
      this.afterDelete(this.data);
     this.setMessage(err);
   })
  }
  /********************************************* Delete Service Zone **********************************************/
  deleteServiceZone() {
    

    this.serviceZone.deleteServiceZone(this.data.id).subscribe((res: any) => {
      

      this.afterDelete({ status: 200, body: res });
      this.setMessage({ status: 200 });

      console.log(res);

    }, (err: any) => {
      console.log(err);
      this.setMessage({ status: err?.status, ...err });
      this.afterDelete(err);
      this.reject();
    });
  }
  /********************************************* Delete Access **********************************************/
  deleteContractAssets() {
    this.assetService.contractAssets(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      console.log(res)
      if (res.message == 'Success') {
        this.errorUtil.setErrorMessage(200, this.data.deleteType + ' '+ this.translate?.general.messages.removedSuccessfully, null, 'success', 1000);
        this.reject()
      } else {
        this.errorUtil.setErrorMessage(res.status, null, res.message, 'error', 3000);
        this.reject()
      }
    }, (err: any) => {
      this.afterDelete(err);
      this.reject();
    });
  }

  /********************************************* Delete Attendance **********************************************/
  deleteAttendance() {
    this.attendanceService.deleteAttendance(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },(err) => {
      this.afterDelete(this.data);
     this.setMessage(err);
   })
  }

  /********************************************* Delete overtime **********************************************/
  deleteOvertime() {
    this.overtimeService.deleteOvertime(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },(err) => {
      this.afterDelete(this.data);
     this.setMessage(err);
   })
  }

  /********************************************* Delete Productivity **********************************************/
  deleteProductivity() {
    this.productivityService.deleteProductivity(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },(err) => {
      this.afterDelete(this.data);
     this.setMessage(err);
   })
  }

  /********************************************* Delete Productivity Secondary **********************************************/
  deleteProductivitySecondary() {
    this.productivityService.deleteSecondary(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },(err) => {
      this.afterDelete(this.data);
     this.setMessage(err);
   })
  }

  /********************************************* Delete Productivity Secondary **********************************************/
  deleteProductivityPrimary() {
    this.productivityService.deletePrimary(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },(err) => {
      this.afterDelete(this.data);
     this.setMessage(err);
   })
  }


  /********************************************* Delete Machines Specialist **********************************************/
  deleteMachinesSpecialist() {
    this.checklistService.deleteMachinesSpecialistById(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },(err) => {
      this.afterDelete(this.data);
     this.setMessage(err);
   })
  }

/************************************* Delete ALl Attchment in custella ******************************/
deleteVisitAttachment() {
  this.visitService.deleteVisitAttachment(this.data.visitId,this.data.id)
  .subscribe((res: any) => {
    console.log(this.data);
    this.afterDelete(res);
    this.setMessage(res);
  },(err) => {
    this.afterDelete(this.data);
   this.setMessage(err);
 })
}


/********************************************* Delete PMV **********************************************/
deletePMV() {
  this.pmvService.deletePMV(this.data.id).subscribe((res: any) => {
    this.afterDelete(this.data);
    this.setMessage(res);
  },(err) => {
    this.afterDelete(this.data);
   this.setMessage(err);
 })
}
/************************************* Delete Job Order ******************************/
deleteCustomerSurvey() {
  this.customerSurveyService.deleteCS(this.data.id)
  .subscribe((res: any) => {
    this.afterDelete(res);
    this.setMessage(res);
  }, (error: any) => this.setMessage(error));
}
/************************************* Delete Job Order ******************************/
deleteDynamicTableTemplate() {
  this.dynamicTableColumnsService.deleteDTC(this.data.id)
  .subscribe((res: any) => {
    this.afterDelete(res);
    if (res.status === 200 || res.status === 201 || res.status === 204) {
      this.errorUtil.setErrorMessage(200, this.translate?.general.messages.deletedSuccessfully, null, 'success', 1000);
      this.reject()
    } else if (res.status === 400 || res.status === 404) {
      if (res.error.title ||  res.error.message) {
        this.errorUtil.setErrorMessage(res.status, null, res.error.title ?? res.error.message, 'warn', 3000);
      } else {
        this.errorUtil.setErrorMessage(res.status, null, res.error.error, 'warn', 3000);
      }

      this.reject()
    } else {
      this.errorUtil.setErrorMessage(res.status, null, res.error.title, 'error', 3000);
      this.reject()
    }
  }, (error: any) => {
    this.setMessage(error)
   });

  
}

/********************************************* Delete WorkFlow **********************************************/
deleteWorkflow() {
  this.workflowService.deleteWorkflow(this.data.id).subscribe((res: any) => {
    this.afterDelete(this.data);
    this.setMessage(res);
  },(err) => {
    this.afterDelete(this.data);
   this.setMessage(err);
 })
}
/********************************************* Delete WorkFlow => Notification**********************************************/
deleteWorkFlowNotification() {
  this.workflowService.deleteWorkFlowNotification(this.data.id).subscribe((res: any) => {
    this.afterDelete(this.data);
    this.setMessage(res);
  },(err) => {
    this.afterDelete(this.data);
   this.setMessage(err);
 })
}
/********************************************* Delete WorkFlow => Notification**********************************************/
deleteEmailTemplate() {
  this.emailTemplateService.deleteEmailTemplate(this.data.id).subscribe((res: any) => {
    this.afterDelete(this.data);
    this.setMessage(res);
  },(err) => {
    this.afterDelete(this.data);
   this.setMessage(err);
 })
}
/********************************************* Delete WorkFlow Action **********************************************/
deleteWorkflowAction() {
  this.workflowService.deleteNotificationIdWorkFlow(this.data.workFlowId,this.data.id).subscribe((res: any) => {
    console.log(res)
    this.afterDelete(this.data);
    if (res.message == 'success') {
      this.errorUtil.setErrorMessage(200, this.data.deleteType + ' '+ this.translate?.general.messages.removedSuccessfully, null, 'success', 1000);
      this.reject()
    } else {
      this.errorUtil.setErrorMessage(res.status, null, res.message, 'error', 3000);
      this.reject()
    }
  },(err) => {
    this.afterDelete(this.data);
   this.setMessage(err);
 })
}
/********************************************* Delete WorkFlow Action **********************************************/
deletePriceBook() {
  this.priceRateCardBookService.deletePriceBookById(this.data.id).subscribe((res: any) => {
    console.log(res)
    this.afterDelete(this.data);
    this.setMessage(res);
  },(err) => {
    this.afterDelete(this.data);
   this.setMessage(err);
 })
}
/********************************************* Delete Price Book => Product Pricing **********************************************/
deleteProductPricing() {
  this.priceRateCardBookService.deleteProductPriceById(this.data.id).subscribe((res: any) => {
    console.log(res)
    this.afterDelete(this.data);
    this.setMessage(res);
  },(err) => {
    this.afterDelete(this.data);
   this.setMessage(err);
 })
}
/********************************************* Delete Price Book => Product Pricing **********************************************/
deleteRateCard() {
  this.priceRateCardBookService.deleteRateCardById(this.data.id).subscribe((res: any) => {
    console.log(res)
    this.afterDelete(this.data);
    this.setMessage(res);
  },(err) => {
    this.afterDelete(this.data);
   this.setMessage(err);
 })
}
  /************************************* Set success or Error  Message ******************************/
  setMessage(res: any) {
    console.log(res)

    //this.data.deleteType
    if (res.status === 200 || res.status === 201 || res.status === 204) {
      this.errorUtil.setErrorMessage(200,  this.translate?.general.messages.removedSuccessfully, null, 'success', 1000);
      this.reject()
    } else if (res.status === 400 || res.status === 404) {
      if(res.error.type=='dynamic-lang-error'){
        var instance =res.error.instance == 'workflow' ? 'setup' : res.error.instance;
        var data =instance+".validationBE."+res.error.detail;
        console.log("data",data)
        //debugger
        var message = this.languageTranslateService.getNestedValue(this.translate, data);
        this.errorUtil.setErrorMessage( res.status,   null,
         message,
         "warn",
         2000
       );
       this.reject();
     }else{

      if (res.error.title ||  res.error.message) {
        this.errorUtil.setErrorMessage(res.status, null, res.error.title ?? res.error.message, 'warn', 3000);
      } else {
        this.errorUtil.setErrorMessage(res.status, null, res.error.error, 'warn', 3000);
      }
      this.reject()
   }  
    } else {
      this.errorUtil.setErrorMessage(res.status, null, res.error.title, 'error', 3000);
      this.reject()
    }

  }


deleteCustomerSector() {
  this.customerService.deleteCustomerSector(this.data.id).subscribe((res: any) => {
    this.afterDelete(this.data);
    this.setMessage(res);
  });
}

deleteCustomerSubSector() {
  this.customerService.deleteCustomerSubSector(this.data.id).subscribe((res: any) => {
    this.afterDelete(this.data);
    this.setMessage(res);
  });
}

deleteCustomerIndustry() {
  this.customerService.deleteCustomerIndustry(this.data.id).subscribe((res: any) => {
    this.afterDelete(this.data);
    this.setMessage(res);
  });
}
deleteProductType(){
  this.productService.deleteProductType(this.data.id).subscribe((res: any) => {
    this.afterDelete(this.data);
    this.setMessage(res);
  });
}

deleteProductFamily(){
  this.productService.deleteProductFamily(this.data.id).subscribe((res: any) => {
    this.afterDelete(this.data);
    this.setMessage(res);
  });
}

deleteProductUnit(){
  this.productService.deleteProductUnit(this.data.id).subscribe((res: any) => {
    this.afterDelete(this.data);
    this.setMessage(res);
  });
}
  /********************************************* Delete Custome Form Setup **********************************************/
  deleteCustomForm() {
    this.dynamicFormSetupService.deleteDynamicForm(this.data._id,'form').subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },(err) => {
      this.afterDelete(this.data);
     this.setMessage(err);
   })
  }

 /********************************************* Delete Custome Form Setup => Fields **********************************************/
 deleteDynamicFormField() {
  this.dynamicFormSetupService.deleteDynamicFormField(this.data._id).subscribe((res: any) => {
    this.afterDelete(this.data);
    this.setMessage(res);
  },(err) => {
    this.afterDelete(this.data);
   this.setMessage(err);
 })
}
  /********************************************* Delete Custome Sub Form Setup **********************************************/
  deleteCustomSubForm() {
    this.dynamicFormSetupService.deleteDynamicForm(this.data._id,'subform').subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },(err) => {
      this.afterDelete(this.data);
     this.setMessage(err);
   })
  }
  /********************************************* Delete JO Jo Template=> Product **********************************************/
  deleteTaskProductTemplate() {
    this.taskService.deleteTaskTemplateProduct(this.data.id).subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    }, (err: any) => {
      this.afterDelete(err);
      this.setMessage(err);
    });
  }

/************************************* Delete ALl Attchment in custella ******************************/
deleteAIAttachment() {
  this.aiService.deleteAIAttchments(this.data.tenantId, this.data.fileName)
    .subscribe((res: any) => {
      this.afterDelete(res);
      this.setMessage(res);
    });
}

  /********************************************* Delete Checklist **********************************************/
  deleteCheckListGlobal() {
    this.checklistService.deleteCheckListGlobal(this.data.id).subscribe((res: any) => {
      this.afterDelete(this.data);
      this.setMessage(res);
    },(err) => {
      this.afterDelete(this.data);
     this.setMessage(err);
   })
  }

  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
        this.message = this.translate?.general.messages.confirmDelete+"?" 
        console.log("translatecode",this.translate)
      }
    }))
    
    //this.mainLabel = this.translate?.site.label.plural
  }

  confirmProductRemoveFromJo()
  {
      this.afterDelete(true)
      this.reject()
  }

}