import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { GridOptions, IServerSideGetRowsParams } from "ag-grid-community";
import { PermissionService } from "src/app/auth/permission.service";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";

import * as moment from "moment-timezone";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { JobOrdersService } from "src/app/_services/job-orders.service";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { ModalServiceService } from "src/app/_services/modal-service.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { TasksService } from "src/app/_services/tasks.service";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { DynamicFormService } from "src/app/_services/dynamic-form.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { NgxSpinnerService } from "ngx-spinner";
import { ServerRequest } from "src/app/_models/global.data.model";
import { DynamicTableColumnsService } from "src/app/_services/dynamic-table-column.service";
import { Global } from "src/app/_utilities/global";
import { ConfirmationService } from "primeng/api";
var dateFormateChange: any;
var translateData: any;
var router: Router;
@Component({
  selector: "app-tasks-table",
  templateUrl: "./tasks-table.component.html",
  styleUrls: ["./tasks-table.component.scss"],
  providers: [ConfirmationService]
})
export class TasksTableComponent
  extends SubscriptionUtil
  implements OnInit, OnChanges, OnDestroy {
  @Input() search = "";
  @Output() noDataEvent = new EventEmitter();
  @Input() translate: any;
  gridApi: any;
  gridColumnApi: any;
  public columnDefs: any = [];
  public defaultColDef: any;
  public frameworkComponents: any;
  gridParams: any;
  parameters: any;
  gridOptions!: GridOptions;
  public overlayNoRowsTemplate!: string;
  dateFormateChange: any;
  private id: any;
  public allTasks: any = [];
  public totalRecord: any;
  public noTasks: boolean | null = null;
  sortBy: any;
  sortOrder: any;
  private loadTable = 0;
  dateFormat: any
  deleteAction: any;
  taskData: any;


  constructor(
    private util: UtilServiceService,
    private perm: PermissionService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private jobOrders: JobOrdersService,
    private deleteRecordService: DeleteRecordService,
    private modalService: ModalServiceService,
    private router: Router,
    private taskService: TasksService,
    private dynamicFormService: DynamicFormService,
    private errorUtil: ErrorUtil,
    private spinner: NgxSpinnerService,
    private dynamicTableColumnsService: DynamicTableColumnsService,
    private confirmationService: ConfirmationService,
  ) {
    super();
    this.preInit();
  }

  ngOnInit(): void {
    router = this.router;
    this.onotTable()

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.search && changes.search.currentValue !== changes.search.previousValue) {
      this.onGridReadyTask(this.gridParams);
    }
    if (
      changes &&
      changes.translate && changes.translate.previousValue != undefined && Object.keys(changes.translate.previousValue).length !== 0 &&
      changes.translate.previousValue !== changes.translate.currentValue
    ) {
      translateData = this.translate
      this.onotTable();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  /******************************************** pre init variables ******************************************/
  private preInit() {
    this.taskService.refreshList$.subscribe((data) => {
      if (data) {
        this.refresh();
      }
    });

    this.id = this.route.snapshot.queryParamMap.get("jobOrderId");

    this.dateFormateChange = this.auth.getUserTimezone();

    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    };

    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };
    this.gridColumnApi = {
      suppressDragLeaveHidesColumns: true,
      suppressMakeColumnVisibleAfterUnGroup: true,
      enableRangeSelection: true,
      popupParent: document.body,
      onGridSizeChanged: (params: any) => {
        params.api.sizeColumnsToFit();
      },

      tooltipShowDelay: 100,
    };

    this.defaultColDef = {
      enableRowGroup: false, //ag-Grid-Enterprise
      enablePivot: false, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };

    // this.columnDefs = [
    //   {
    //     headerName: "Task Name",
    //     field: "taskName",
    //     width: 200,
    //     headerTooltip: "#",
    //     cellClass: function (params: any) {
    //       return ["table_default_color"];
    //     },
    //   },

    //   {
    //     headerName: "Date",
    //     field: "startDateTime",
    //     width: 140,
    //     headerTooltip: "#",
    //     cellClass: function (params: any) {
    //       return ["d-flex align-items-center"];
    //     },
    //     valueFormatter: (params: any) => {
    //       if (
    //         params.data.startDateTime !== "" &&
    //         params.data.startDateTime !== null
    //       ) {
    //         return moment
    //           .tz(params.data.startDateTime, this.dateFormateChange)
    //           .format(localStorage.getItem("date_format")!.toUpperCase());
    //       }
    //     },
    //   },
    //   {
    //     headerName: "Time",
    //     field: "startDateTime",
    //     width: 140,
    //     headerTooltip: "#",
    //     cellClass: function (params: any) {
    //       return ["d-flex align-items-center"];
    //     },
    //     valueFormatter: (params: any) => {
    //       if (
    //         params.data.startDateTime !== "" &&
    //         params.data.endDateTime !== null
    //       ) {
    //         return (
    //           moment
    //             .tz(params.data.startDateTime, this.dateFormateChange)
    //             .format(" hh:mm a") +
    //           " - " +
    //           moment
    //             .tz(params.data.endDateTime, this.dateFormateChange)
    //             .format("hh:mm a")
    //         );
    //       }
    //     },
    //   },
    //   {
    //     headerName: "Assigned To--", field: "assignedTo.firstName", width: 150,
    //     headerTooltip: "#",
    //     cellRenderer: function (params: any) {
    //       if (params && params.data) {
    //         var assignedUsersList = "";
    //         if (params.data.assignedUsersList.length > 1) {
    //           let coun = params.data.assignedUsersList.length - 1
    //           assignedUsersList = '<div style="background: #26363e;height:20px;padding: 5px;margin-left: 4px; margin-top: 2.5px; border-radius: 5px" class="d-flex align-items-center"><span style="color: #fff">+' + coun + '</span></div>';
    //         }

    //         let nameData = params.data.assignedTo?.fullName ? params.data.assignedTo?.fullName : ""; 
    //         return ("<div class='d-flex'>" + nameData + assignedUsersList + "</div>");
    //       }
    //     },
    //   },
    //   {
    //     headerName: "Task Status",
    //     field: "taskStatus",
    //     width: 130,
    //     headerTooltip: "#",
    //     cellClass: function (params: any) {
    //       return ["d-flex align-items-center"];
    //     },
    //     cellRenderer: function (params: any) {
    //       if (params.data.taskStatus == "OPEN") {
    //         return `<span class="p-mr-2 p-tag p-component p-tag-open">
    //           <span class="p-tag-value text-dark">${params.data.taskStatus.charAt(0).toUpperCase() +
    //           params.data.taskStatus.slice(1).toLowerCase()
    //           }</span></span>`;
    //       } else if (params.data.taskStatus == "PENDING") {
    //         return `<span class="p-mr-2 p-tag p-component p-tag-pending">
    //         <span class="p-tag-value text-dark">${params.data.taskStatus.charAt(0).toUpperCase() +
    //           params.data.taskStatus.slice(1).toLowerCase()
    //           }</span></span>`;
    //       } else if (
    //         params.data.taskStatus == "IN PROGRESS" ||
    //         params.data.taskStatus == "IN_PROGRESS"
    //       ) {
    //         return `<span class="p-mr-2 p-tag p-component p-tag-in-progress">
    //         <span class="p-tag-value text-dark">In Progress</span></span>`;
    //       } else if (params.data.taskStatus == "COMPLETED") {
    //         return `<span class="p-mr-2 p-tag p-component p-tag-completed">
    //           <span class="p-tag-value text-dark">${params.data.taskStatus.charAt(0).toUpperCase() +
    //           params.data.taskStatus.slice(1).toLowerCase()
    //           }</span></span>`;
    //       } else if (
    //         params.data.taskStatus == "INCOMPLETE" ||
    //         params.data.taskStatus == "IN_COMPLETED"
    //       ) {
    //         return `<span class="p-mr-2 p-tag p-component p-tag-in-incomplete">
    //         <span class="p-tag-value text-dark">Incomplete</span></span>`;
    //       } else if (params.data.taskStatus == "CANCELLED") {
    //         return `<span class="p-mr-2 p-tag p-component p-tag-in-incomplete">
    //         <span class="p-tag-value text-dark">${params.data.taskStatus.charAt(0).toUpperCase() +
    //           params.data.taskStatus.slice(1).toLowerCase()
    //           }</span></span>`;
    //       }
    //     },
    //   },

    //   {
    //     headerName: "Action",
    //     sortable: false,
    //     filter: false,
    //     width: 110,
    //     headerTooltip: "#",
    //     headerClass: "marging-auto hide-action-border",
    //     cellStyle: { "text-align": "center" },
    //     cellRenderer: "buttonRenderer",
    //     cellRendererParams: {
    //       onClick: this.editTask.bind(this),
    //       permissions: {
    //         update: this.perm.capable("tasks", "update"),
    //         remove: this.perm.capable("tasks", "remove"),
    //       },
    //       hidden: {
    //         update: !this.perm.capable("tasks", "update"),
    //         remove: !this.perm.capable("tasks", "remove"),
    //       },
    //     },
    //   },
    // ];

    this.deleteAction = {
          headerName: "Action",
          sortable: false,
          filter: false,
          width: 110,
          headerTooltip: "#",
          headerClass: "marging-auto hide-action-border",
          cellStyle: { "text-align": "center" },
          cellRenderer: "buttonRenderer",
          cellRendererParams: {
            onClick: this.editTask.bind(this),
            permissions: {
              update: this.perm.capable("tasks", "update"),
              remove: this.perm.capable("tasks", "remove"),
            },
            hidden: {
              update: !this.perm.capable("tasks", "update"),
              remove: false,
            },
          },
        }
       // debugger
        console.log(this.perm.capable("tasks", "update"));
  }

  /********************************** PreInit *****************************/
  private onotTable() {
    translateData = this.translate
    this.dateFormat = localStorage.getItem("date_format");
    dateFormateChange = this.auth.getUserTimezone();

    this.dynamicFormService.getTableColumn("task").subscribe((res: any) => {
      this.sortBy = res.sortBy
      this.sortOrder = res.sortOrder

      var columnDefs: any = []
      res.tableColumnSetups.forEach((element: any) => {
        this.cellRenderer(element);

        columnDefs.push(element);
        this.gridOptions.api!.setColumnDefs(columnDefs);
      });
      columnDefs.push(this.deleteAction);
      this.gridOptions.api!.setColumnDefs(columnDefs);

      this.loadTable = 1;

      this.onGridReadyTask(this.gridParams);
    });
  }

  /************************************************************************************/
  getNestedValue(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }

  /********************************** Render Cell *****************************/
  private cellRenderer(element: any) {
    console.log(element, "===", element.dataType)

    var dateFormat: any = localStorage.getItem("date_format");


    element.headerTooltip = "#";
    if (element.translatePath) {
      element.headerName = this.getNestedValue(this.translate, element.translatePath);
    }

    if (element.field === "taskNumber") {
      element.headerComponentParams = Global.setHeaderComponentParams(element, 'Tbl_HeadJobTaskNo'),
        element.cellRenderer = function (params: any) {
          if (params.data) {
            return Global.setCellRenderer(params, params.data.rowId, params.data.taskNumber)
          }
        };

    }

    else if (element.field === "customer.name") {
      // element.headerName = "Customer";
      element.headerComponentParams = Global.setHeaderComponentParams(element, 'Tbl_HeadJobTaskCust'),
        element.cellRenderer = function (params: any) {
          if (params.data) {
            return Global.setCellRenderer(params, params.data.rowId2, params.data.customer?.name)
          }
        };
    }



    else if (element.field === "site.siteName") {
      element.headerComponentParams = Global.setHeaderComponentParams(element, 'Tbl_HeadeJobTaskSite'),
        element.cellRenderer = function (params: any) {
          if (params.data) {
            return Global.setCellRenderer(params, params.data.rowId3, params.data.site?.siteName)
          }
        };

    }
    else if (element.field === "project.name") {
      element.headerComponentParams = Global.setHeaderComponentParams(element, 'Tbl_HeadJobTaskProj'),
        element.cellRenderer = function (params: any) {
          if (params.data) {
            return Global.setCellRenderer(params, params.data.rowId4, params.data.project?.name)
          }
        };

    }


    else if (element.field === "taskStatus") {

      element.cellRenderer = function (params: any) {
        if (params.data) {
          if (params.data.taskStatus == "OPEN") {
            return ('<span class="p-mr-2 p-tag p-component p-tag-open"><span class="p-tag-value text-dark">' + translateData?.general.status.open.singular + '</span></span>');
          } else if (params.data.taskStatus == "PENDING") {
            return (
              '<span class="p-mr-2  p-tag p-component p-tag-pending" ><span class="p-tag-value text-dark">' + translateData?.general.status.pending.singular + '</span></span> ');
          } else if (params.data.taskStatus == "IN PROGRESS" || params.data.taskStatus == "IN_PROGRESS") {
            return '<span class="p-mr-2  p-tag p-component p-tag-in-progress" ><span class="p-tag-value text-dark">' + translateData?.general.status.inProgress.singular + '</span></span>';

          } else if (params.data.taskStatus == "COMPLETED") {
            return ('<span class="p-mr-2  p-tag p-component p-tag-completed" ><span class="p-tag-value text-dark">' + translateData?.general.status.completed.singular + '</span></span>');

          } else if (params.data.taskStatus == "INCOMPLETE" || params.data.taskStatus == "IN_COMPLETED") {
            return '<span class="p-mr-2  p-tag p-component p-tag-incomplete" ><span class="p-tag-value text-dark">' + translateData?.general.status.incomplete.singular + '</span></span>';
          } else if (params.data.taskStatus == "CANCELLED") {
            return ('<span class="p-mr-2  p-tag p-component p-tag-cancel" ><span class="p-tag-value text-dark">' + translateData?.general.status.cancelled.singular + '</span></span>');
          }
        }
      };
    }


    else if (element.dataType == 'date') {
      element.valueFormatter = function (params: any) {

        if (params && params.value) {
          return moment.tz(params.value, dateFormateChange).format(localStorage.getItem("date_format")!.toUpperCase());
        }
      };

    }
    else if (element.dataType == 'datetime') {
      element.cellRenderer = function (params: any) {
        if (params && params.value) {
          return moment.tz(params.value, dateFormateChange).format(localStorage.getItem("date_format")!.toUpperCase() + ", " + "hh:mm A");
        } else {
          return '-'
        }
      };
    }
    else if (element.field === "assignedTo") {
      element.cellRenderer = function (params: any) {
        if (params && params.data) {
          var assignedUsersList = "";
          if (params.data.assignedUsersList.length > 1) {
            let coun = params.data.assignedUsersList.length - 1
            assignedUsersList = '<div style="background: #26363e;height:20px;padding: 5px;margin-left: 4px; margin-top: 2.5px; border-radius: 5px" class="d-flex align-items-center"><span style="color: #fff">+' + coun + '</span></div>';
          }

          var nameData = params.data.assignedTo.firstName + " " + params.data.assignedTo.lastName
          return ("<div class='d-flex'>" + nameData + assignedUsersList + "</div>");
        }
      };
    }
    else if (element.field === "priority") {

      element.cellRenderer = function (params: any) {
        if (params.data) {
          if (params.data.priority == "Medium") {
            return ('<span class="p-mr-2 p-tag p-component p-tag-medium"><span class="p-tag-value text-dark">Medium</span></span>');

          } else if (params.data.priority == "Low") {
            return ('<span class="p-mr-2  p-tag p-component p-tag-low" ><span class="p-tag-value text-dark">Low</span></span> ');

          } else if (params.data.priority == "High") {
            return '<span class="p-mr-2  p-tag p-component p-tag-in-high" ><span class="p-tag-value text-dark">High</span></span>';

          }
        }
      };
    }
    else if (element.dataType == "text") {
      element.valueFormatter = function (params: any) {
        if (params && params.value) {
          return params.value
        } else {
          return '-'
        }
      };

    }
    else if (element.dataType == "boolean") {
      element.cellRenderer = function (params: any) {
        if (params && params.value) {
          return 'Yes'
        } else {
          return 'No'
        }
      }
    }
  }
  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /********************************** View Singal Record **************************/
  onGridReadyTask(params: any) {
    //console.log(params)
    if (params) {
      this.gridParams = params;

      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      var dataSource = {
        getRows: (params: IServerSideGetRowsParams) => {
          //this.getServerSideData(params);
          if (this.loadTable === 1) { this.getServerSideData(params); }
        },
      };

      this.gridApi.sizeColumnsToFit();
      this.gridApi.setDatasource(dataSource);
    }
  }
  /********************************** Call Customer API to Display data **************************/
  getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
    // Show spinner when starting data loading
    this.spinner.show();
    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);
    var count: any = serverRequest.startRow;
    console.log("Making a server request: ", serverRequest);

    this.push(
      this.taskService.getTaskByCustomerV2(this.search, serverRequest, null, this.id, null, null)
        .subscribe((data: any) => {
          console.log(data);
          this.spinner.hide();
          if (data.status === 200 || data.status === 201) {
            this.taskData = data.body.data;
            console.log(this.taskData)

            this.totalRecord = data.body.total;
            this.taskData.forEach((element: any) => {
              count++
              element.rowId = 'Tbl_RecJobTaskNo' + count
              element.rowId2 = 'Tbl_RecJobTaskCust' + count
              element.rowId3 = 'Tbl_RecJobTaskProj' + count
              element.rowId4 = 'Tbl_RecJobTaskCust' + count
              element.buttonEdit = 'Btn_SitEdit' + count
              element.buttonDelete = 'Btn_SitDel' + count
            });
            this.setGridData(
              agGridGetRowsParams,
              this.taskData,
              data.body.total,
              data.body.status
            );

            this.spinner.hide();

          } (err) => {
            console.log(err)
            const title = this.errorUtil.processErrorTitle(err.error, this.translate)
            this.errorUtil.setErrorMessage(400, null, title, 'error', 3000);
            this.spinner.hide();
          }
        })
    );
  }
  /********************************** Set Data for Grid Table **************************/
  private setGridData(
    agGridGetRowsParams: IServerSideGetRowsParams,
    resultItems: any[],
    totalCount: number,
    status: string
  ) {


    if (resultItems.length === 0) {
      this.gridParams.api.showNoRowsOverlay();
      this.noTasks = true;
    } else {
      this.noTasks = false;

      agGridGetRowsParams.successCallback(resultItems, totalCount);
    }

    this.noDataEvent.emit(this.noTasks);
  }
  /********************************** Request Parameter for api **************************/

  getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
      this.parameters = { colId: this.sortBy, sort: this.sortOrder }
      agGridRequest.sortModel.push(this.parameters);
    }

    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,
    };
  }

  /******************************************** refresh data ******************************************/
  public refresh() {

    this.onGridReadyTask(this.gridParams);

  }

  /******************************************** get context menu items ******************************************/
  // public getContextMenuItems = (params: any) => {
  //   params.node.data.field = params.column.colId;
  //   let result: { name: string; action: () => void }[] = [];
  //   if (params.column.colId == "taskName") {
  //     result = [
  //       {
  //         name: "Open link in new tab",
  //         action: () => this.util.openItemToNewTab(params.node.data),
  //       },
  //     ];
  //   }
  //   return result;
  // };
  public getContextMenuItems = (params: any) => {
    params.node.data.field = params.column.colId;
    return this.dynamicTableColumnsService.rightClick(params);
  };

  /******************************************** view tasks ******************************************/
  public viewTasks($event: any) {
    // console.log($event);
    const col = $event.colDef.field;
    let id = null;
    if (col == "taskNumber" && $event.colDef.cellClass) {
      id = $event.data.id;

      // this.router.navigate(["/dashboard/tasks/view"], {
      //   queryParams: { id },
      // });
      this.util.openTaskVIewPage(id);
    } else if (col == "taskName" && $event.colDef.cellClass) {
      this.util.openTaskVIewPage($event.data.id)
    } else {
      this.dynamicTableColumnsService.redirectURL($event)
    }
  }

  /******************************************** edit delete action ******************************************/
  private editTask(e: any) {
    // console.log(e.rowData);
   // debugger
    const rowData = e.rowData;

    
    if (e.action === "edit") {

      let data = e.rowData;
      data.mode = "edit";

      this.modalService.openModal("addEditTask", data);
    } else if (e.action === "delete") {
      var data = {
        deleteHeader : this.translate.task.label.singular ,
        deleteType: "Task",
        message: `Are you sure you want to delete ${rowData.taskName}?`,
        id: rowData.id,
        removeProductsLikedToJobOrder: false,
      };
      this.confirmationService.confirm({
        
        key: "confirm2",
        message:this.translate.jobOrder.messages.removeSparePartsLinkedTask,
        accept: () => {
         data.removeProductsLikedToJobOrder = true;
         this.push(
          this.deleteRecordService.getItems(data).subscribe((data) => {
            this.onGridReadyTask(this.gridParams);
          })
        );
        },
        reject: () => {
          this.push(
            this.deleteRecordService.getItems(data).subscribe((data) => {
              this.onGridReadyTask(this.gridParams);
            })
          );
        },
      });
      

    
    }
  }

  /******************************************** filter task ******************************************/
  private filterTasks() {
    if (this.gridApi) {
      this.gridApi.setQuickFilter(this.search);
      if (this.totalRecord == 0) {
        this.gridApi.showNoRowsOverlay();
        //this.noDataEvent.emit(true)
      }
      else if (this.totalRecord > 0) {
        this.gridApi.hideOverlay();
      }
    }
  }
  /******************************************** Add ALl the Subscription ******************************************/
  push(obs: any) {
    super.push(obs);
  }

  confirmDelete() {
   
  }
}
