import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { ModalServiceService } from "src/app/_services/modal-service.service";
import { TicketsService } from "src/app/_services/tickets.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ErrorUtil } from "src/app/_utilities/error";
import * as _ from "lodash";
import { AppEvents, PERSISTANT_ITEM } from "src/app/_models/global.data.model";
import { BsModalService } from "ngx-bootstrap/modal";
import { JobOrdersService } from "src/app/_services/job-orders.service";
import { PermissionService } from "src/app/auth/permission.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";

@Component({
  selector: "app-conversation",
  templateUrl: "./conversation.component.html",
  styleUrls: ["./conversation.component.scss"],
})
export class ConversationComponent implements OnInit {
  noConversation: boolean | null = null;
  conversationData: any[] = [];
  conversationDateFormat!: string;
  conversationDetails: any;

  statusDetail: any[] = [];

  @Input() ticketId: any;
  public ticketDetails: any;
  public timezone: any;

  // SLA STUFF
  public today: any = new Date();
  public atRiskDateTime: any;
  public violationSoonSlaEndTime: any;

  public create: any;
  public createJO: any;
  translate: any;

  constructor(
    private ticketsService: TicketsService,
    private domSanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private util: UtilServiceService,
    private spinner: NgxSpinnerService,
    private errorUtil: ErrorUtil,
    private modalService: ModalServiceService,
    private bsModalService: BsModalService,
    private router: Router,
    private jobOrderService: JobOrdersService,
    private perm: PermissionService,
    private languageTranslateService: LanguageTranslateService
  ) {
    this.languageTranslateService.menuSrc$.subscribe(data => {
      if(Object.keys(data).length != 0){ this.translate = data}
    })
  }

  ngOnInit(): void {
    this.init();
  }

  /******************************************** init ******************************************/
  private init() {
    this.create = this.perm.capable("tickets", "create");
    this.createJO = this.perm.capable("job_order", "create");

    if (!this.ticketId) {
      this.ticketId = this.route.snapshot.params["id"];
    }

    this.timezone = this.auth.getUserTimezone();

    this.conversationDateFormat =
      "hh:mm a" + ", " + localStorage.getItem("date_format");

    this.spinner.show();
    this.getAllConversations();
    this.getTicketData();
    this.getTicketStatus();
    this.getFirstResponse();
  }

  private getTicketData() {
    this.ticketsService.getTicketById(this.ticketId).subscribe((res: any) => {
      this.ticketDetails = res;
      this.ticketDetails.slaStatus = this.ticketDetails.slaStatus.replace(
        " ",
        "_"
      );

      if (this.ticketDetails.assets != null) {
        if (this.ticketDetails.violatingSoonSla.atRiskDateTime != undefined) {
          let atRiskDateTime: any = new Date(
            this.ticketDetails.violatingSoonSla.atRiskDateTime
          );
          var diffMs = atRiskDateTime - this.today; // milliseconds between now & Christmas
          var timeConversion = this.util.timeConversion(diffMs);
          var timeConverData = timeConversion.split("-");
          this.atRiskDateTime = timeConverData[1];
        }
      }

      let today: any = new Date();

      if (this.ticketDetails.violatingSoonSla.atRiskDateTime != undefined) {
        let atRiskDateTime: any = new Date(
          this.ticketDetails.violatingSoonSla.atRiskDateTime
        );
        var diffMs = atRiskDateTime - today; // milliseconds between now & Christmas
        var timeConversion = this.util.timeConversion(diffMs);
        var timeConverData = timeConversion.split("-");
        this.atRiskDateTime = timeConverData[1];
      }
    });
  }

  /******************************************** get all conversation ******************************************/
  private getAllConversations() {
    this.ticketsService.getAllConversations(this.ticketId).subscribe(
      (res: any) => {
        const sorted = res.sort((a: any, b: any) => {
          return (
            new Date(b.createdDate).getTime() -
            new Date(a.createdDate).getTime()
          );
        });

        this.conversationData = sorted;
        if (this.conversationData.length != 0) {
          const conversationDetials = this.conversationData[0];
          this.noConversation = false;

          setTimeout(() => {
            this.setConversationDetail(conversationDetials);

            this.spinner.hide();
          }, 500);
        } else {
          this.spinner.hide();

          this.noConversation = true;
        }
      },
      (error) => {
        this.spinner.hide();

        this.errorUtil.setErrorMessage(
          error.status,
          error,
          error.details,
          null,
          300000
        );
      }
    );
  }

  /******************************************** set current conversation ******************************************/
  public async setConversationDetail(conversation: any) {
    const theConvo = await this.ticketsService
      .getConvoById(conversation.id)
      .toPromise();

    this.conversationDetails = {};
    this.conversationDetails = { ...conversation, ...theConvo };

    const message =
      this.conversationDetails?.htmlMessage ?? this.conversationDetails.message;

    const standard = this.conversationDetails?.htmlMessage ? false : true;
    this.setMessage(standard, message);

    this.setActiveToConversationList(conversation);
  }

  /******************************************** return safe html email ******************************************/
  public returnHTML(htmlString: string) {
    return this.domSanitizer.bypassSecurityTrustHtml(htmlString);
  }

  /******************************************** add class active class to a conversation ******************************************/
  private setActiveToConversationList(conversation: any): void {
    const allLists = document.querySelectorAll(
      ".convelist"
    ) as NodeListOf<HTMLDivElement>;

    for (let al = 0; al < allLists.length; al++) {
      const id = Number(allLists[al].dataset.conversationId);

      if (conversation.id === id) {
        // set active
        allLists[al].setAttribute(
          "class",
          "convelist convoSelected convoSelected-active border-bottom border-secondary"
        );
      } else {
        allLists[al].setAttribute(
          "class",
          "convelist convoSelected border-bottom border-secondary"
        );
      }
    }
  }

  /******************************************** Set Message ******************************************/
  private setMessage(standard: boolean, message: string) {
    const messageBoxElem = document.querySelector("#value_TixMessage");
    if (standard) {
      // messageBoxElem!.textContent = message;
      messageBoxElem!.textContent = message;

    } else {
      messageBoxElem?.insertAdjacentHTML('afterbegin', this.parseImage(this.conversationDetails.htmlMessage));
    }
  }

  private parseImage(htmlMessage: string) {
    let parsedHtmlMessage = "";

    // console.log(htmlMessage);
    // console.log(this.htmlToElements(htmlMessage));

    const elements = this.htmlToElements(htmlMessage)

    // console.log(this.conversationDetails)

    for (let at = 0; at < this.conversationDetails.attachmentList.length; at++) {
      for (let e = 0; e < elements.length; e++) {
        if (elements[e].localName !== 'img') {
          this.traverseForImage(elements[e].children, this.conversationDetails?.attachmentList[at])
        } else {
          const image = elements[e] as HTMLImageElement;
          const attachment = this.conversationDetails?.attachmentList[at];

          if (image && image.alt === attachment.fileName) {
            image.src = attachment.attachmentUrl;
          }
        }
      }
    }

    // console.log('ALEMENTS AFTER', elements);
    // console.log(elements, Array.from(elements))

    Array.from(elements).forEach(((elem) => {
      parsedHtmlMessage += elem.outerHTML;
    }))

    // console.log(parsedHtmlMessage)
    

    return parsedHtmlMessage;
  }

  private traverseForImage(children: any, attachment: any) {
    for (let c = 0; c < children.length; c++) {
      if (children[c].localName !== 'img') {
        this.traverseForImage(children[c].children, attachment)
      } else {
        const image = children[c];

        if (image && image.alt === attachment.fileName) {
          image.src = attachment.attachmentUrl;
        }

      }
    }
  }

  htmlToElements(html: string) {
    var template = document.createElement('template');
    template.innerHTML = html;
    return template.content.children;
}

  /******************************************** get all ticket statuses ******************************************/
  private getTicketStatus() {
    this.ticketsService
      .getAllTicketStatus("active")
      .subscribe((statusResponse: any) => {
        this.statusDetail = _.filter(statusResponse, (o) => {
          return o.name == "Escalated";
        });
      });
  }

  /******************************************** down load the file ******************************************/
  public downloadFile(attachment: any) {
    this.util.downloadAttachment(attachment);
  }

  /******************************************** escalate ticket ******************************************/
  public openModal(modalView: string, data: any) {
    if (modalView == "addJo-Escalate") {
      data.ticketType = "Tickets";
      data.newJOStatus = "Escalate";
      data.ticketStatusID = { id: this.statusDetail[0].id };
      data.ticket = { id: this.ticketId.toString() };

      this.util.setPersistantItem(PERSISTANT_ITEM.JOB_ORDER, data);

      this.router.navigate(["/dashboard/jobOrders/create"]);
    }

    if (modalView === "addComment") {
      const modalRef = this.modalService.openModal(modalView, data, {
        class: "modal-l",
      });

      if (modalRef?.content?.event) {
        modalRef?.content.event.subscribe((data: any) => {
          if (
            data &&
            "action" in data &&
            data.action === AppEvents.ADD_CONVERSATION_MODAL_SUCCESS
          ) {
            this.getAllConversations();
          }
        });
      }

      if (modalRef?.content.modalEvent) {
        modalRef.content.modalEvent.subscribe((data: any) => {
          this.bsModalService.hide();
          const id = data.data.jobOrder.id;

          const url = `/dashboard/jobOrders/view/${id}?jobOrderId=${id}`;

          this.util.openItemToNewTab(url);

          this.util.sendData({
            action: AppEvents.REFRESH_JOB_ORDER_TABLE,
            data: null,
          });
        });
      }
    }
  }

  /******************************************** get sla ******************************************/
  private getFirstResponse() {
    let today: any = new Date();

    this.ticketsService
      .getAllFirstResponse(this.ticketId)
      .subscribe((res: any) => {
        const firstResponceData = res;

        firstResponceData.forEach((element: any) => {
          var slaStatusData = element.slaStatus.replace(" ", "_");
          element.slaStatusData = slaStatusData.toUpperCase();

          if (element.slaStatus == "Paused") {
            element.slaStatusData = "ON_HOLD";
          }

          let violationDateTime: any = new Date(element.violationDateTime);

          this.violationSoonSlaEndTime = violationDateTime;

          var diffMs = violationDateTime - today; // milliseconds between now & Christmas
          var diffDays = Math.floor(diffMs / 86400000); // days

          /*================================Change progress text ========================= */
          element.violationdays = diffDays;

          var timeConversion = this.util.timeConversion(diffMs);
          var timeConverData = timeConversion.split("-");
          element.timeConverType = timeConverData[0];
          element.diffMs = timeConverData[1];
          element.statusTest = "Before violation";

          if (diffDays < 0) {
            var diffMs = today - violationDateTime; // milliseconds between now & Christmas
            var diffDays = Math.floor(diffMs / 86400000); // days

            element.violationdays = diffDays;
            var timeConversion = this.util.timeConversion(diffMs);
            var timeConverData = timeConversion.split("-");
            element.timeConverType = timeConverData[0];
            element.diffMs = timeConverData[1];
            element.statusTest = "Since Violated";
          }

          /*================================Change progress % ========================= */
          let percentage = 100;
          let todayUnix = new Date().getTime();
          let initDateTimeUnix: any = new Date(element.initDateTime); // conver milliseconds

          let violationDurationInMinSec =
            element.slaStepViolationDuration * 60 * 1000;
          if (element.slaStatusData != "VIOLATED") {
            let durationBetWeenTodayToStart = todayUnix - initDateTimeUnix;
            percentage = Math.round(
              (durationBetWeenTodayToStart / violationDurationInMinSec) * 100
            );
          } else {
            percentage = 100;
          }

          /*================================Change progress color ========================= */
          element.percentage = percentage;
          if (element.slaStatusData == "IN_PROGRESS") {
            element.colorCode = "#38DAE1";
          } else if (element.slaStatusData == "ON_HOLD") {
            element.colorCode = "#FEB934";
            element.percentage = 100;
          } else if (element.slaStatusData == "VIOLATED") {
            element.colorCode = "#FD715B";
          } else if (element.slaStatusData == "AT_RISK") {
            element.colorCode = "#EF7224";
          }
        });
      });
  }
}
