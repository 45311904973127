<div class="custella-modal">
        <div class="custella-modal d-flex flex-column">
            <div class="custella-modal-header">
                <div class="custella-modal-header-text" id="title_SmartSuggestSparePart">{{translate?.setup?.configuration?.artificialIntelligence.sections.sec7.singular}}</div>
                <i class="pi pi-times pt-1" id="btn_SmartSuggestSparePartClose" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left" (click)="modalRef.hide()"></i>
            </div>
  
            <!-- ADD SUGGESTIONS HERE -->
            <div class="custella-modal-body d-grid gap-2" *ngFor="let prod of products">
                <div class="smart-suggest-items" [ngClass]="{'added': prod.saved == 'Yes'}">
                    <div class="text-nowrap">
                        <span class="text-12 font-medium" id="label_SetJobFrMapResol">{{prod.product?.name}}</span>
                        <span class="d-block text-12 color-var-500" id="label_SetJobFrMapFaultDesc">{{prod.quantity}}</span>
                    </div>
                    <i *ngIf="prod.saved == 'Yes'" class="fa-solid fa-circle-check fa-xl font-green"></i>
                    <div class="chip-container" *ngIf="prod.saved == 'No'" >
                        <p-chip class="chip-smart-add" icon="fa-regular fa-check" id="label_JobNoteSmartSuggestAdd" [label]="translate?.general.buttons.add.singular" (click)="addSingleProduct(prod)"></p-chip>
                    </div>
                </div>
                
            </div>
  
            <div class="custella-modal-footer d-flex justify-content-end">
                <button type="button" class="custella-btn-white text-12 ms-2" id="btn_SmartSuggestSparePartCancel" (click)="modalRef.hide()">{{translate?.general.buttons.cancel.singular}}</button>
                <p-button type="button" (onClick)="addBlukProduct()" styleClass="ms-2 h-32" [label]="buttonLabel" id="btn_SmartSuggestSparePartSubmit"></p-button>
            </div>
        </div>
</div>